import {
	IAugmentedJQuery,
	INgModelController,
	IPromise,
	IQService,
	ITimeoutService,
	IWindowService,
	element as angularElement
} from 'angular';

import {
	Component,
	Input,
	OnDestroy,
	OnInit
} from '@vbrick/angular-ts-decorators';
import { debounce } from 'underscore';

import { BootstrapContext } from 'rev-shared/bootstrap/BootstrapContext';

import { loadEditor } from './loadEditor';
import { DefaultToolbar } from './Constants';

import './htmlEditor.less';

const CSS_FOCUS_CLASS: string = 'has-focus theme-accent-border-important';

/**
 * wysiswyg html editor
 * Example:
 * <div vb-html-editor  ng-model="..."></div>
 */
@Component({
	selector: 'vb-html-editor',
	template: `
		<div class="editor-loading" ng-hide="::$ctrl.loaded">
			<vb-loading-spinner [block]="true" [size]="'medium'"></vb-loading-spinner>
		</div>
		<div class="editor"></div>`,
	require: {
		ngModel: 'ngModel'
	}
})
export class HtmlEditor implements OnInit, OnDestroy {
	@Input() public editorConfig: CKEDITOR.config;
	private ngModel: INgModelController;
	public loaded: boolean;
	private editor: CKEDITOR.editor;
	private isEditorReady: boolean;
	private editorReadyPromise: IPromise<void>;
	private destroyed: boolean = false;

	constructor(
		private $element: IAugmentedJQuery,
		private $q: IQService,
		private $timeout: ITimeoutService
	) {
		'ngInject';
	}

	public ngOnInit() {
		loadEditor()
			.then(() => this.initEditor())
			.then(() => this.loaded = true);
	}

	private initEditor() {
		if(this.destroyed) {
			return;
		}

		const editorElement = this.$element.find('.editor');
		const config = {
			language: BootstrapContext.ckEditorLocale,
			toolbar: DefaultToolbar,
			removeButtons: '',
			enterMode: CKEDITOR.ENTER_BR,
			ignoreEmptyParagraph: true,
			fillEmptyBlocks: false,
			extraAllowedContent: 'span(vb-emoji)',
			...this.editorConfig
		};

		const editor = this.editor = CKEDITOR.replace(editorElement[0] as any, config);
		this.editorReadyPromise = this.$q(resolve => editor.on('instanceReady', resolve));
		const render = () => {
			if(this.isEditorReady){
				editor.setData(this.ngModel.$viewValue);
			}
		};

		const updateModel = debounce(
			() =>
				this.$timeout(() => this.ngModel.$setViewValue(editor.getData())),
			200
		);

		editor.on('change', updateModel);
		editor.on('key', updateModel);
		editor.on('paste', updateModel);
		editor.on('dataReady', () => this.isEditorReady && this.$timeout());

		this.ngModel.$render = render;

		this.editorReadyPromise.then(() => {
			this.isEditorReady = true;
			render();

			const $iframe: IAugmentedJQuery = this.$element.find('iframe');
			const $iframeContentWindow: any = angularElement(($iframe[0] as HTMLIFrameElement).contentWindow);

			$iframeContentWindow
				.focus(() => $iframe.toggleClass(CSS_FOCUS_CLASS, true))
				.blur(() => $iframe.toggleClass(CSS_FOCUS_CLASS, false));
		});
	}

	public ngOnDestroy() {
		this.destroyed = true;
		if(this.editorReadyPromise) {
			this.editorReadyPromise.then(() => this.editor.destroy());
		}
	}
}
