import {
	AfterViewInit,
	Component,
	DoCheck,
	Input
} from '@vbrick/angular-ts-decorators';

import {
	IAttributes,
	IAugmentedJQuery,
	INgModelController,
	ITimeoutService
} from 'angular';

import { isDate } from 'rev-shared/util';
import { ThemeService } from 'rev-portal/branding/Theme.Service';

import styles from './date-picker.module.less';

/**
 * vb-ui-date-picker
 * name attribute is mandetory for this component.
 * provide accessibility-label parameter. This will be used on wrapper div which has role=group
 * This can be used as -
 * <vb-ui-date-picker
 * 		accessibility-label="'@Js.@Strings.ExpirationDate'"
 * 		name="datePicker"
 *		ng-model="$ctrl.date"
 *		required
 *		vb-min="$ctrl.tomorrow"
 *		vb-max="$ctrl.maxDate">
 * </vb-ui-date-picker>
 * Date format validation can be checked as
 * <<clienFormName>>.<<datePickerComponentName>>.$error.date
 */
@Component({
	selector: 'vb-ui-date-picker',
	require: {
		ngModelController: 'ngModel'
	},
	templateUrl: '/partials/ui/vb-ui-date-picker.html'
})
export class DatePickerComponent implements AfterViewInit, DoCheck {
	@Input() public accessibilityLabel: string;
	private date: any;
	private datePickerIsOpen: boolean;
	private ngModelController: INgModelController;

	private readonly options: any = {
		customClass: (data: any): string => this.getCSSClass(data),
		showWeeks: false
	};

	private readonly styles = styles;
	private readonly cssClss: string = 'datePickerSelectedDate';

	constructor(
		private $attrs: IAttributes,
		private $element: IAugmentedJQuery,
		private $timeout: ITimeoutService,
		private ThemeService: ThemeService
	) {
		'ngInject';
		this.date = {};
	}

	public ngAfterViewInit(): void {
		if (!this.$attrs.name) {
			throw new Error('vb-ui-date-picker: name attribute missing');
		}

		this.$element
			.addClass(styles.root)
			.focusin(() => this.$element.toggleClass(styles.hasFocus, true))
			.focusout(() => this.$element.toggleClass(styles.hasFocus, false));

		this.ngModelController.$isEmpty = value => this.isEmpty(value);

		this.ngModelController.$validators.date =
			(modelVal, viewVal): boolean => this.validateDatePicker(modelVal, viewVal);

		this.manageAccessibility();
	}

	public ngDoCheck(): void {
		this.date = {
			value: this.ngModelController.$viewValue
		};
	}

	private manageAccessibility(): void {
		if (!this.accessibilityLabel) {
			console.warn(' vb-ui-date-picker missing accessibility-label parameter');
		}
	}

	private validateDatePicker(modelVal, viewVal): boolean {
		const value = modelVal || viewVal;

		return this.isEmpty(value) ||
			isDate(value);
	}

	private toggleDatePicker(): void {
		if (!this.datePickerIsOpen) {
			this.$timeout(() => this.datePickerIsOpen = true, 100);
		}
	}

	private get accentTheme(): {fontColor; backgroundColor} {
		const themeSettings = this.ThemeService.brandingSettings.themeSettings;
		return {
			fontColor: themeSettings.accentFontColor,
			backgroundColor: themeSettings.accentColor
		};
	}

	private getCSSClass(data): string {
		if(!this.date || !this.date.value) {
			return;
		}
		let cssClass;
		switch(data.mode) {
			case 'day':
				cssClass = this.getDayClass(data.date);
				break;
			case 'month':
				cssClass = this.getMonthClass(data.date);
				break;
			case 'year':
				cssClass = this.getYearClass(data.date);
				break;
		}
		return cssClass;
	}

	private getDayClass(date: Date): string {
		const dayToCheck: number = new Date(date).setHours(0, 0, 0, 0);
		const selectedDate: number = new Date(this.date.value).setHours(0, 0, 0, 0);
		return dayToCheck === selectedDate ? this.cssClss : null;
	}

	private getMonthClass(date: Date): string {
		return date.getMonth() === this.date.value.getMonth() ? this.cssClss : null;
	}

	private getYearClass(date: Date): string {
		return date.getFullYear() === this.date.value.getFullYear() ? this.cssClss : null;
	}

	private isEmpty(value: any): boolean {
		return value === null; // ngModel set to null when the field is empty, undefined when bad value
	}

	private onDateChange(): void {
		this.ngModelController.$setViewValue(this.date.value);

		this.updateTouched();
	}

	private onBlur(): void {
		this.updateTouched();
	}

	private updateTouched(): void {
		this.ngModelController.$setTouched();

		this.$element.trigger('blur'); // helps when used in a composite component
	}
}
