import {
	Component,
	OnInit
} from '@vbrick/angular-ts-decorators';

import {
	IAugmentedJQuery,
} from 'angular';

import styles from './VbGridList.module.less';

@Component({
	selector: 'vb-grid-list-row',
})
export class VbGridListRowComponent implements OnInit {
	constructor(
		private $element: IAugmentedJQuery
	) {
		'ngInject';
	}

	public ngOnInit(): void {
		this.$element.addClass(styles.gridRow);
	}

}
