import { Component } from '@vbrick/angular-ts-decorators';

import { SearchHeaderComponent } from './SearchHeader.Component';

import './search-header-dropdown.less';

@Component({
	selector: 'search-header-dropdown',
	templateUrl: '/partials/portal/navigation/search/search-header-dropdown.html'
})

export class SearchHeaderDropdownComponent extends SearchHeaderComponent {}
