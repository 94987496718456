import { NgModule } from '@vbrick/angular-ts-decorators';

import DialogModule from 'rev-shared/ui/dialog/Dialog.Module';
import VBrickSharedUtilModule from 'rev-shared/util/Util.Module';
import { IDialogService } from 'rev-shared/ui/dialog/IDialog';
import { SimpleDialogController } from 'rev-shared/ui/dialog/SimpleDialogController.ts';
import { UserContextService } from 'rev-shared/security/UserContext.Service';

import { SystemMessageService } from './SystemMessage.Service';

@NgModule({
	id: 'Vbrick.Portal.SystemMessage',
	imports: [
		DialogModule.forChild([{
			name: 'SystemMessage',
			templateUrl: '/partials/system-message.html',
			controller:	SimpleDialogController
		}]),
		VBrickSharedUtilModule
	],
	providers: [
		SystemMessageService
	]
})
export default class SystemMessageModule {

	public static run(
		Dialog: IDialogService,
		SystemMessageService: SystemMessageService,
		UserContext: UserContextService
	): void {
		'ngInject';

		UserContext.userIdChanged$.subscribe(showMessage);
		showMessage();

		function showMessage(){
			if(!UserContext.isUserAuthenticated()){
				return;
			}

			SystemMessageService.loadUserMessage()
				.then(message => {
					if (message) {
						Dialog.getDialog('SystemMessage').open({
							message
						}, {
							backdrop: 'static'
						})
							.result
							.then(() => SystemMessageService.markMessageAsRead());
					}
				});
		}
	}
}
