import { NgModule } from '@vbrick/angular-ts-decorators';

import { VbrickUiInlineEditModule } from 'rev-shared/ui/inlineEdit/InlineEdit.Module';

import { SearchHeaderComponent } from './SearchHeader.Component';
import { SearchHeaderDropdownComponent } from './SearchHeaderDropdown.Component';

@NgModule({
	id: 'VBrick.Portal.Navigation.Search',
	declarations: [
		SearchHeaderComponent,
		SearchHeaderDropdownComponent
	],
	imports: [
		VbrickUiInlineEditModule
	]
})

export default class SearcHeaderhModule {}
