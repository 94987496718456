import { Component } from '@angular/core';

import { BaseReportKpiComponent } from 'rev-shared/ui/reports/BaseReportKpi.Component';

@Component({
	selector: 'quality-experience-report-kpis',
	templateUrl: './QualityExperienceReportKpis.Component.html'
})
export class QualityExperienceReportKpisComponent extends BaseReportKpiComponent {
}
