<div *ngIf="(webcast.currentRun && !webcast.currentRun.isStarted)
	|| webcast.webcastStatus.isScheduled
	|| webcast.webcastStatus.isCompleted" [ngClass]="[
		styles.eventNotStarted,
		!!webcast.backgroundImages ? 'theme-primary' : '']">

	<ng-container *ngIf="webcast.currentRun; else noCurrentRun;">
		<h2 [hidden]="!webcast.currentRun.isScheduled">{{ 'Event_EventHasNotStarted' | translate }}</h2>
		<h2 [hidden]="!webcast.currentRun.isEnded" [vbScheduleChangeDetection]="[webcast.endDate]">
			{{
				(webcast.isTimeToStart() ? 'Event_EventHasEndedOrUpdating' : 'Event_EventHasEnded') | translate
			}}
		</h2>
	</ng-container>
	<ng-template #noCurrentRun>
		<h2 [hidden]="!webcast.webcastStatus.isScheduled">{{ 'Event_EventHasNotStarted' | translate }}</h2>
		<h2 [hidden]="!webcast.webcastStatus.isCompleted">{{ 'Event_EventHasEnded' | translate }}</h2>
	</ng-template>

	{{webcast.startDate| vbDateTimeMedium}} -
	{{webcast.endDate| vbDateTimeMedium}}


	<div [hidden]="!webcast.description" [ngClass]="styles.eventDescriptionWrapper" class="text-align-left theme-primary-border" [innerHTML]="webcast.description | safeHtml">
	</div>
</div>
