import {
	Component,
	Input,
	OnInit
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { FEATURED_VIDEOS_PLAYLIST_ID, PlaylistService } from 'rev-shared/media/Playlist.Service';
import { UserContextService } from 'rev-shared/security/UserContext.Service';
import { VideoService } from 'rev-shared/media/Video.Service';

import { CarouselType } from 'rev-portal/branding/BrandingSettings.Contract';
import { SearchService } from 'rev-portal/search/Search.Service';
import { SortRecommended } from 'rev-portal/search/SearchConstants';

import baseStyles from '../dashboard-carousel.module.less';
import styles from './video-carousel.module.less';

@Component({
	selector: 'video-carousel',
	templateUrl: './VideoCarousel.Component.html'
})

export class VideoCarouselComponent implements OnInit {
	@Input() private carousel: any;

	public readonly styles = {
		...baseStyles,
		...styles
	};

	public headerLabel: string;
	public headerSref: string;
	public headerSrefParams: any;

	private defaultSearchParams = {
		count: 15,
		isActive: true,
		ready: true
	};

	private accountId: string;
	public videos: any[];

	constructor(
		private PlaylistService: PlaylistService,
		private SearchService: SearchService,
		private Translate: TranslateService,
		private UserContext: UserContextService,
		private VideoService: VideoService
	) { }

	public ngOnChanges(): void {
		this.updateHeaders();
	}

	public updateHeaders(): void {
		this.headerLabel = this.getHeaderLabel();
		[this.headerSref, this.headerSrefParams] = this.getHeaderSref();
	}

	public ngOnInit(): void {
		this.accountId = this.UserContext.getAccount().id;
		this.loadData().then(() => this.updateHeaders());
	}

	private loadData(): Promise<void> {
		switch (this.carousel.type) {
			case CarouselType.Category:
				return this.loadCategoryVideos();
			case CarouselType.LiveVideos:
				return this.loadLiveVideos();
			case CarouselType.Recommended:
				return this.loadRecommendedVideos();
			case CarouselType.RecentVideos:
				return this.loadRecentlyAddedVideos();
			case CarouselType.ContinueWatching:
				return this.loadRecentlyViewedVideos();
			case CarouselType.TeamVideos:
				return this.loadTeamVideos();
			case CarouselType.Playlist:
				return this.loadPlaylist();
		}
	}

	private loadCategoryVideos(): Promise<void> {
		return this.getVideos({
			...this.defaultSearchParams,
			accountId: this.accountId,
			category: this.carousel.linkId,
			sortField: SortRecommended,
			sortAscending: false
		});
	}

	private loadTeamVideos(): Promise<void> {
		return this.getVideos({
			...this.defaultSearchParams,
			accountId: this.accountId,
			teamId: this.carousel.linkId,
			sortField: SortRecommended,
			sortAscending: false
		});
	}

	private loadRecentlyAddedVideos(): Promise<void> {
		return this.getVideos({
			...this.defaultSearchParams,
			accountId: this.accountId,
			sortField: 'WhenUploaded',
			sortAscending: false
		});
	}

	private loadLiveVideos(): Promise<void> {
		return this.getVideos({
			...this.defaultSearchParams,
			accountId: this.accountId,
			isLive: true,
			sortField: SortRecommended,
			sortAscending: false
		});
	}

	private loadPlaylist(): Promise<void> {
		return this.PlaylistService.getPlaylist(this.carousel.linkId, true)
			.then(result => {
				this.videos = result.videos;
			});
	}

	private loadRecentlyViewedVideos(): Promise<void> {
		return this.VideoService.getRecentlyViewedVideos()
			.then(videos => this.videos = videos);
	}

	private loadRecommendedVideos(): Promise<void> {
		return this.getVideos({
			...this.defaultSearchParams,
			accountId: this.accountId,
			sortField: 'recommended',
			sortAscending: false
		});
	}

	private getVideos(params): Promise<any> {
		return Promise.resolve(this.SearchService.getVideos({ noScroll: true, ...params })
			.then(result => this.videos = result.videos));
	}

	private getHeaderLabel(): string {
		const type = this.carousel.type;
		if(type === CarouselType.Playlist
			&& this.carousel.linkId === FEATURED_VIDEOS_PLAYLIST_ID) {
			return 'FeaturedVideos';
		}

		switch(type) {
			case CarouselType.TeamVideos:
			case CarouselType.Category:
			case CarouselType.Playlist:
				return this.carousel.linkName;
			case CarouselType.RecentVideos:
				return this.Translate.instant('Dashboard_RecentlyAddedVideos');
			case CarouselType.Recommended:
				return this.Translate.instant('Media_RecommendedForYou');
			case CarouselType.LiveVideos:
				return this.Translate.instant('Dashboard_IPTV');
			case CarouselType.ContinueWatching:
				return this.Translate.instant('Dashboard_ContinueWatching');
		}
	}

	private getHeaderSref(): [string, any] {
		switch(this.carousel.type){
			case CarouselType.Category:
				return ['portal.media.category-detail', { categoryId: this.carousel.linkId }];
			case CarouselType.TeamVideos:
				return ['portal.media', { team: this.carousel.linkId }];
			case CarouselType.RecentVideos:
				return ['portal.media.all', { viewMode: 'table', sortField: 'whenUploaded' }];
			case CarouselType.Recommended:
				return ['portal.media.all', {}];
			case CarouselType.LiveVideos:
				return ['portal.media.search', { searchType: 'live' }];
			case CarouselType.Playlist:
				return this.videos?.length
					? ['portal.video-playlist-playback', { videoId: this.videos[0].id, playlistId: this.carousel.linkId }]
					: [] as any;
		}
		return [] as any;
	}
}
