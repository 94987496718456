<vb-title [title]="'Event_QuestionsAndAnswers_PageTitle' | translate : { '0': webcast.title }"></vb-title>
<ng-container *ngIf="questions">
	<div class="webcast-manage-questions-full-screen" layout="column" flex-wrap="false">

		<webcast-pre-prod-banner [webcast]="webcast">
		</webcast-pre-prod-banner>

		<header class="theme-primary-font-border-fade-50">
			<vb-toolbar>
				<div flex="fill">
					<h1>{{ 'Event_QuestionsAndAnswers' | translate }}</h1>
				</div>
				<div flex class="header-section">
					<!--Buttons to show a list that has been hidden by the user-->
					<div class="btn-group" role="group">
						<button type="button" [hidden]="!isInboxHidden" (click)="isInboxHidden = !isInboxHidden" vbUiBtnSecondary>
							{{ 'Event_QA_List_Inbox' | translate }}
							<span class="badge badge-question">{{ questionCounts.Inbox }}</span>
						</button>

						<button type="button" [hidden]="!isQueueHidden" (click)="isQueueHidden = !isQueueHidden" vbUiBtnSecondary>
							{{ 'Event_QA_List_Queue' | translate }}
							<span class="badge badge-question">{{ questionCounts.Queued }}</span>
						</button>

						<button type="button" [hidden]="!isClosedHidden" (click)="isClosedHidden = !isClosedHidden" vbUiBtnSecondary>
							{{ 'Event_QA_List_Closed' | translate }}
							<span class="badge badge-question">{{ questionCounts.Closed }}</span>
						</button>
					</div>
				</div>
				<div flex class="header-section">
					<form>
						<input #filterInput type="text" name="qTextFilter" class="form-control qtext-filter" [attr.placeholder]="'Event_QA_FilterQuestions' | translate" [attr.aria-label]="'Event_QA_FilterQuestions' | translate" [(ngModel)]="qTextFilter" (ngModelChange)="onQTextFilterChange()">
						<button type="button" [hidden]="!qTextFilter" (click)="qTextFilter=''; onQTextFilterChange(); filterInput.focus()" [attr.aria-label]="'Event_QA_FilterQuestionsReset' | translate" class="qtext-filter-reset">
							<span class="glyphicons remove_2"></span>
						</button>
					</form>
				</div>
			</vb-toolbar>
		</header>

		<!--Question status list views-->
		<main class="question-list-layout" flex="1" *ngIf="webcast.currentRun.isInProgress">

			<!--Inbox section-->
			<section class="inbox-view theme-primary-font-border-fade-50" [hidden]="isInboxHidden">
				<header class="theme-primary-font-border-fade-50">
					<vb-toolbar layout-padding="0 050" edge-padding>
						<div flex>
							<button type="button" class="btn-hide-question-list" (click)="isInboxHidden = !isInboxHidden"><span class="glyphicons remove"></span></button>
						</div>
						<div flex="fill">
							<h1>{{ 'Event_QA_List_Inbox' | translate }}</h1>
						</div>
						<div flex>
							<!--New Questions button for the Inbox (click shows them)-->
							<button *ngIf="selectedView === Question_View.Inbox && questionCounts.hidden" class="btn btn-primary btn-inbox-show-new-questions" (click)="showNewQuestions()" vbUiBtnPrimary>
								<span class="glyphicons refresh"></span>

								{{ questionCounts.hidden }}

								<span [ngPlural]="questionCounts.hidden">
									<ng-template ngPluralCase="1">{{ 'Event_QA_IncomingQuestionsCountSingular' | translate }}</ng-template>
									<ng-template ngPluralCase="other">{{ 'Event_QA_IncomingQuestionsCountPlural' | translate }}</ng-template>
								</span>
							</button>
						</div>
					</vb-toolbar>
				</header>

				<vb-webcast-questions-list [webcast]="webcast" [questions]="inboxQuestions">
				</vb-webcast-questions-list>
			</section>

			<!--Queue section-->
			<section class="queue-view theme-primary-font-border-fade-50" [hidden]="isQueueHidden">
				<header class="theme-primary-font-border-fade-50">
					<vb-toolbar layout-padding="0 050" edge-padding>
						<div flex>
							<button type="button" class="btn-hide-question-list" (click)="isQueueHidden = !isQueueHidden"><span class="glyphicons remove"></span></button>
						</div>
						<div flex="fill">
							<h1>{{ 'Event_QA_List_Queue' | translate }}</h1>
						</div>
					</vb-toolbar>
				</header>
				<vb-webcast-questions-list [webcast]="webcast" [questions]="queuedQuestions" [allowReordering]="true">
				</vb-webcast-questions-list>

			</section>

			<!--Closed section-->
			<section class="closed-view" [hidden]="isClosedHidden">
				<header class="theme-primary-font-border-fade-50">
					<vb-toolbar layout-padding="0 050" edge-padding>
						<div flex>
							<button type="button" class="btn-hide-question-list" (click)="isClosedHidden = !isClosedHidden"><span class="glyphicons remove"></span></button>
						</div>
						<div flex="fill">
							<h1>{{ 'Event_QA_List_Closed' | translate }}</h1>
						</div>
						<div flex dropdown class="position-relative">
							<button type="button" id="closedQuestionsFilterBtn" vbUiBtnSecondary dropdownToggle aria-controls="closedQuestionsFilterMenu" class="dropdown-toggle">
								{{ (closedQuestionsFilterLabel || 'All') | translate }}
								<span class="caret"></span>
							</button>

							<ul *dropdownMenu class="dropdown-menu" [ngClass]="styles.closedQuestionsFilterMenu" id="closedQuestionsFilterMenu" role="menu" aria-labelledby="closedQuestionsFilterBtn">
								<li><a (click)="filterClosedQuestions(QuestionAction.All, $event.target.innerText)">{{ 'All' | translate }}</a></li>
								<li><a (click)="filterClosedQuestions(QuestionAction.Answered, $event.target.innerText)">{{ 'Event_QA_Answered' | translate }}</a></li>
								<li><a (click)="filterClosedQuestions(QuestionAction.Declined, $event.target.innerText)">{{ 'Event_QA_Declined' | translate }}</a></li>
								<li><a (click)="filterClosedQuestions(QuestionAction.RepliedDirectly, $event.target.innerText)">{{ 'Event_QA_Closed_Replied' | translate }}</a></li>
								<li><a (click)="filterClosedQuestions(QuestionAction.FollowUp, $event.target.innerText)">{{ 'Event_QA_FollowUp' | translate }}</a></li>
								<li><a (click)="filterClosedQuestions(QuestionAction.Closed, $event.target.innerText)">{{ 'Event_QA_Closed' | translate }}</a></li>
							</ul>
						</div>
					</vb-toolbar>
				</header>

				<vb-webcast-questions-list [webcast]="webcast" [questions]="closedQuestions">
				</vb-webcast-questions-list>
			</section>
		</main>

		<!--Webcast Status Messages-->
		<div class="webcast-presentation-msg-container theme-primary" [hidden]="!webcast.currentRun.isEnded">
			<div class="ppt-loading-wrapper">
				<h3>{{ 'Event_EventHasEnded' | translate }}</h3>
			</div>
		</div>
	</div>
</ng-container>
