<section class="viewport-pane">
	<h4>{{ poll.question }}</h4>
	<div class="poll-responses-wrapper" *ngIf="poll.isOpen()">
		<vb-radio-group-btn *ngIf="!poll.multipleChoice" [ngModel]="poll.selectedAnswer">
			<vb-radio-btn class="poll-responses" *ngFor="let answer of poll.answers" [accessibilityLabel]="answer.text" [name]="poll.id" [optionValue]="answer.text" (changed)="onAnswerClick(answer)">
				{{ answer.text }}
			</vb-radio-btn>
		</vb-radio-group-btn>
		<div *ngIf="poll.multipleChoice">
			<vb-ui-checkbox class="poll-responses" *ngFor="let answer of poll.answers" [accessibilityLabel]="answer.text" [(ngModel)]="answer.checked" (ngModelChange)="onAnswerClick(answer)">
				{{ answer.text }}
			</vb-ui-checkbox>
		</div>
	</div>

	<div *ngIf="poll.isPublished()">
		<div class="poll-responses" *ngFor="let answer of poll.answers">
			<p><strong>{{ answer.text }}</strong></p>
			<div class="vote-bar theme-accent-bg" [ngClass]="answer.count ? 'hasVoteCount theme-accent-font-border' : ''" [ngStyle]="{'width': 80 * answer.count / poll.totalResponses  + '%', 'margin-right':(answer.count > 0) ? '3px' : '0'}">
			</div>
			<span>{{answer.count}}</span>
		</div>
		<p [ngClass]="styles.pollSubFooter">{{ 'Event_Responses' | translate }}: {{poll.totalResponses}}</p>
	</div>
	<footer *ngIf="poll.isOpen()" class="text-align-right">
		<button type="button" vbUiBtnPrimaryNgx (click)="vote()" [disabled]="isFirstVote || poll.responseSubmitted">
				{{ 'UI_Submit' | translate }}
		</button>
		<aside class="poll-question-submitted" [hidden]="!poll.responseSubmitted">
				{{ 'Event_Response_Submitted' | translate }}
		</aside>
	</footer>

</section>
