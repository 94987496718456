import { NgModule } from '@vbrick/angular-ts-decorators';
import UibButtonsModule from 'angular-ui-bootstrap/src/buttons';

import { VbBtnSecondaryDirective } from './VbBtnSecondary.Directive';
import { VbToggleButtonComponent } from './VbToggleButton.Component';
import { VbUiBtnDangerDirective } from './VbUiBtnDanger.Directive';
import { VbUiBtnPrimaryDirective } from './VbUiBtnPrimary.Directive';
import { VbUiRadioBtnDirective } from './VbUiRadioBtn.Directive';
import { VbUiRadioBtnGroupDirective } from './VbUiRadioBtnGroup.Directive';

@NgModule({
	id: 'VBrick.UI.Buttons',
	declarations: [
		VbBtnSecondaryDirective,
		VbToggleButtonComponent,
		VbUiBtnDangerDirective,
		VbUiBtnPrimaryDirective,
		VbUiRadioBtnDirective,
		VbUiRadioBtnGroupDirective
	],
	imports: [
		UibButtonsModule
	]
})
export default class VBrickUIButtonsModule {}
