import {
	Component, Input
} from '@vbrick/angular-ts-decorators';

import { BaseInlineEditComponent } from './BaseInlineEditComponent';

@Component({
	selector: 'vb-ui-inline-edit-boolean',
	templateUrl: '/partials/shared/components/inline-edit-boolean.html',
})
export class VbUiInlineEditBooleanComponent extends BaseInlineEditComponent<boolean> {
	@Input() public trueLabel: string;
	@Input() public falseLabel: string;

}
