<vb-confirmation-dialog [title]="'Delete' | translate" [message]="'Event_Webcast_DeleteSlidesConfirmation' | translate" [cancelText]="'Cancel' | translate" [actionText]="'Ok' | translate">
</vb-confirmation-dialog>

<ng-container *ngIf="(context$ | async) as context">
	<div layout-gt-md="column" flex="fill" flex-wrap="false">
		<div flex class="presentation-slides-toolbar-wrap">
			<div layout="row" navbar-padding="100" flex-align="left" class="webcast-toolbar theme-primary theme-primary-font-border-fade-50">

				<div flex="shrink">{{ 'Event_Webcast_Region_Slides' | translate }}</div>
				<form name="presentationUploadForm" class="form-horizontal form-upload-files" method="post" enctype="multipart/form-data" flex="shrink" [singleFileUploads]="true" fileUpload (onAdd)="uploadPresentation($event.file)" [hidden]="!context.canEditSlides">
					<div class="btn-file-wrapper">
						<div vbUiBtnPrimaryNgx>
							<span [hidden]="webcast.presentation.hasPresentation">{{ 'Upload' | translate }}</span>
							<span [hidden]="!webcast.presentation.hasPresentation">{{ 'Replace' | translate }}</span>
							<input type="file" name="presentationFile" [attr.aria-label]="(webcast.presentation.hasPresentation ? 'Upload' : 'Replace') | translate">
						</div>
					</div>
				</form>
				<div flex="fill" [hidden]="!webcast.presentation.hasPresentation || !context.canEditSlides">
					<button type="button" vbUiBtnPrimaryNgx (click)="removePresentation()">{{ 'Delete' | translate }}</button>
				</div>
			</div>
		</div>
		<div flex="fill" class="slides-box-ct">
			<div class="slides-box" [vbScrollItemX]="webcast.presentation.currentSlideIndex" [selector]="'img'">
				<img [attr.alt]="'PresentationSlide' | translate : { '0':  indexOfelement + 1 }" *ngFor="let slide of webcast.presentation.slides; index as indexOfelement;" [ngClass]="{'theme-primary-font-border-fade-30': slide === webcast.presentation.currentSlide}" (click)="webcast.presentation.moveSlide(indexOfelement)" [src]="slide.thumbnailUri" vbImageRetry>
			</div>
		</div>
	</div>
</ng-container>
