import { AfterViewInit, Component, Input, DoCheck, OnInit } from '@vbrick/angular-ts-decorators';
import { IAugmentedJQuery, IFormController, INgModelController } from 'angular';

import { IInsightOptions } from '@vbrick/angular-insight/src/Insight.Contract';

import { IRules } from 'rev-shared/ui/css/CssRules.Contract';

import { ThemeService } from 'rev-portal/branding/Theme.Service';

import styles from './insight-control.module.less';

@Component({
	selector: 'vb-insight',
	templateUrl: '/partials/shared/insight/insight.html',
	require: {
		ngModelCtrl: 'ngModel'
	}
})
export class VbInsightComponent implements AfterViewInit, OnInit, DoCheck {
	@Input() public insightOptions: IInsightOptions;
	@Input() public templateUrl: string;
	@Input() public optionTemplateUrl: string;
	@Input() public assignedOptionTemplateUrl: string;

	private readonly insightForm: IFormController & { insight: INgModelController };
	private readonly ngModelCtrl: INgModelController;

	private readonly styles = styles;
	private cssRules: IRules;

	constructor(
		private ThemeService: ThemeService,
		private $element: IAugmentedJQuery
	) {
		'ngInject';
	}

	public ngOnInit(): void {
		this.initCssRules();

		this.ngModelCtrl.$isEmpty = (value: []) => !value || !value.length;
	}

	public ngAfterViewInit(): void {
		//adding setTimeout because the component is not ready yet.
		setTimeout(() => this.initFocusHandler(), 100);
	}

	public ngDoCheck(): void {
		if(this.insightForm) {
			this.syncNgModel();
		}
	}

	public initFocusHandler(): void {
		const borderEl = this.$element.find(`.${styles.vbUiInsight}`);
		const toggleFocusBorder = (isVisible: boolean) =>
			borderEl.toggleClass('theme-accent-border', isVisible);

		this.$element
			.focusin(() => toggleFocusBorder(true))
			.focusout(() => toggleFocusBorder(false));
	}

	private syncNgModel(): void {
		const innerNgModel = this.insightForm.insight;
		const ngModel = this.ngModelCtrl;

		if(innerNgModel.$dirty !== ngModel.$dirty) {
			if(innerNgModel.$dirty){
				ngModel.$setDirty();
			}
			else {
				ngModel.$setPristine();
			}
		}

		if(innerNgModel.$touched !== ngModel.$touched) {
			if(innerNgModel.$touched){
				ngModel.$setTouched();
			}
			else {
				ngModel.$setUntouched();
			}
		}

	}

	private initCssRules(): void {
		this.cssRules = {
			[`
			.insight .search-field-wrapper .search-field .icon,
			.insight .search-field-wrapper .search-field.focus .icon,
			.insight .search-field-wrapper .search-field .btn-close,

			.insight .widget-options .message,

			.assigned .insight-option-row,
			.assigned .insight-option-row .btn-assigned,
			.insight-option-row .btn-edit-access.active,
			.insight-option-row .btn-edit-access.active .ok_2,
			.insight-option-row .access-assignment-popup .access-wrapper .access-item.active
			`]: {
				color: `${this.ThemeService.brandingSettings.themeSettings.accentFontColor} !important`,
				background: `${this.ThemeService.brandingSettings.themeSettings.accentColor} !important`
			}
		};
	}

	private updateValue(): void {
		this.ngModelCtrl.$setViewValue(this.ngModelCtrl.$viewValue);
	}
}
