<div class="resize-fill" layout="column" flex-wrap="false">
	<div flex *ngIf="!webcast.layout.isFullscreenVideo" class="hidden-xs theme-primary-bg">
		<div layout="row" navbar-padding="100" flex-justify="center" flex-align="center" [ngClass]="styles.header" class="theme-primary-txt theme-primary-font-border-fade-50">
			<div flex="fill">{{ 'Event_Webcast_Region_Video' | translate }}</div>
		</div>
	</div>

	<live-stream-status *ngIf="(webcast.startingVideoSource || webcast.waitingForVideoSource)
			&& webcast.enableLiveStreamStatusDisplay" [webcast]="webcast">
	</live-stream-status>

	<div class="video-height-container" flex-justify="center" flex-align="stretch" layout="column" layout-padding="100" vbWatchDimensions (dimensionsChanged)="height=$event.height;width=$event.width" *ngIf="!(webcast.startingVideoSource || webcast.waitingForVideoSource || disposePlayer)">
		<div class="width-full height-full" flex="fill" flex-align="center" flex-justify="center" layout>

			<div class="fixed-ratio-wrapper webcast-video-not-supported" [hidden]="webcast.playbacks?.length" [ngClass]="[
					webcast.playbacks?.length ? 'mobile-hide-for-real' : styles.videoWrapper
				]" [ngStyle]="{
					'height': (height) + 'px',
					'width': (width) + 'px'
				}">
				<div class="webcast-video fixed-ratio">

					<div class="text-center" [hidden]="webcast.isUpdatingPlaybacks">
						{{ 'Video_Playback_BrowserNotSupported' | translate }}
					</div>
					<vb-loading-spinner [hidden]="!(webcast.isUpdatingPlaybacks)" [block]="true" [size]="'large'">
					</vb-loading-spinner>
				</div>

			</div>

			<div [ngClass]="styles.videoWrapper" class="height-full" flex-align="center" [hidden]="!(webcast.playbacks?.length)">
				<div [ngStyle]="{
						'height': (height) + 'px',
						'width': (width) + 'px'
					}" vb-webcast-player [playbacks]="webcast.playbacks" [webcastId]="webcast.id" [runNumber]="webcast.currentRun.runNumber" [webcastSessionId]="webcast.currentUser.sessionId" [heartbeatIntervalSecs]="webcast.videoHeartbeatInterval" (onPlayerReady)="onPlayerReady($event.vgAPI)" (onWmvFullscreenToggled)="onFullscreenToggled($event.isFullscreen)" [autoPlay]="webcast.autoplay" [isVcSource]="!!webcast.vcSipAddress || !!webcast.vcMicrosoftTeamsMeetingUrl" [isCaptionsAvailable]="webcast.closedCaptionsEnabled" [muteBtnOnLeft]="webcast.isWebexLiveStreamType" slideDelay [activeSource]="vgAPI?.activeSource" [canControlSlides]="webcast.currentUser.canControlPresentationSlides" [currentState]="vgAPI?.currentState" [presentation]="webcast.presentation" [webcastPlaybacks]="webcast.playbacks">
				</div>
			</div>

		</div>
	</div>
</div>
