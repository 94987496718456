import {
	Component,
	Input,
	OnInit,
	Output
} from '@vbrick/angular-ts-decorators';
import {
	IOnChangesObject,
	IFilterDate
} from 'angular';

import { formatIsoDate } from 'rev-shared/date/DateFormatters';
import { DateUtil } from 'rev-shared/date/DateUtil';

import {
	IExpirationDetails,
	IExpirationSelected
} from './ExpirationOptions.Interface';

enum ExpirationOptions {
	NONE = 'None',
	DATE = 'Date',
	RULE = 'Rule'
}

enum RuleTypes {
	DAYS_AFTER_UPLOAD = 'DaysAfterUpload',
	DAYS_WITHOUT_VIEW = 'DaysWithoutViews',
	NONE = 'None'
}

interface IExpirationConfig {
	hideLabel: boolean;
	hideExpirationOption: ExpirationOptions;
}

import './video-expiration-options.less';

@Component({
	selector: 'video-expiration-options',
	templateUrl: '/partials/media/videos/component/video-expiration-options/video-expiration-options.html'
})

export class VideoExpirationOptionsComponent implements OnInit {
	@Input() private config: IExpirationConfig;
	@Input() private expirationDetails: IExpirationDetails;
	@Input() private expirationRuleEnabled: boolean;
	@Input() private expirationRules: any;
	@Input() private hideNoneOption: boolean;
	@Input() private isLarge: boolean;
	@Input() private whenUploaded: Date;
	@Input() private lastViewed: Date;

	@Output() private onExpirationChange;

	private expirationOptions: typeof ExpirationOptions;
	private ruleTypes: typeof RuleTypes;

	private tomorrow: Date;
	private midnight: string;
	private expirationRule: IExpirationSelected;
	private expirationOption: string;

	constructor(
		private dateFilter: IFilterDate
	) {
		'ngInject';
	}

	public ngOnInit(): void {
		this.expirationOptions = ExpirationOptions;
		this.ruleTypes = RuleTypes;
		this.tomorrow = DateUtil.addDays(DateUtil.getToday(), 1);
		this.midnight = this.dateFilter(DateUtil.getToday(), 'shortTime');

		const ruleType = this.expirationDetails.expiryRuleType;

		this.expirationOption = (ruleType === ExpirationOptions.DATE ||
			ruleType === ExpirationOptions.NONE && !!this.expirationDetails.expirationDate) ?
			ExpirationOptions.DATE :
			ruleType && ruleType !== RuleTypes.NONE ?
				ExpirationOptions.RULE :
				ExpirationOptions.NONE;
	}

	public ngOnChanges(changes: IOnChangesObject): void {
		this.updateAssignedExpirationRule();
	}

	public onExpiryRuleChange(selectedRule: any): void {
		this.expirationRule.expiryRuleDate = selectedRule.numberOfDays ?
			this.getExpirationDate(selectedRule.ruleType, selectedRule.numberOfDays) :
			undefined;
		this.onExpirationUpdate();
	}

	public onExpirationUpdate(): void {
		const updatedExpiration = this.retrieveUpdatedExpiration();

		if(this.onExpirationChange) {
			this.onExpirationChange({ expiration: updatedExpiration });
		}
	}

	private retrieveUpdatedExpiration(): IExpirationDetails {
		switch(this.expirationOption) {
			case this.expirationOptions.DATE:
				return this.onExpirationUpdateTypeDate();
			case this.expirationOptions.RULE:
				return this.onExpirationUpdateTypeRule();
			default:
				return this.onExpirationUpdateTypeNone();

		}
	}

	private onExpirationUpdateTypeDate(): IExpirationDetails {
		return {
			ruleId: null,
			expiryRuleType: this.ruleTypes.NONE,
			expirationDate: this.expirationDetails.expirationDate,
			deleteOnExpiration: !!this.expirationDetails.deleteOnExpiration,
			numberOfDays: 0
		};
	}

	private onExpirationUpdateTypeRule(): IExpirationDetails {
		if (!this.expirationRule) {
			return {
				ruleId: null,
				expiryRuleType: null,
				expirationDate: null,
				deleteOnExpiration: null,
				numberOfDays: null
			};
		}

		return {
			ruleId: this.expirationRule.id,
			expiryRuleType: this.expirationRule.ruleType,
			expirationDate: DateUtil.addDays(DateUtil.getToday(), this.expirationRule.numberOfDays),
			deleteOnExpiration: this.expirationRule.deleteOnExpire,
			numberOfDays: this.expirationRule.numberOfDays
		};
	}

	private onExpirationUpdateTypeNone(): IExpirationDetails {
		return {
			deleteOnExpiration: false,
			expirationDate: null,
			expiryRuleType: this.ruleTypes.NONE,
			numberOfDays: 0,
			ruleId: null
		};
	}

	private updateAssignedExpirationRule(): void {
		if (this.expirationDetails) {
			this.expirationRule = this.expirationRules.rules.find(item => item.id === this.expirationDetails.ruleId);

			if (this.expirationDetails.ruleId && this.expirationRule) {
				this.expirationRule.expiryRuleDate = this.getExpirationDate(this.expirationRule.ruleType, this.expirationRule.numberOfDays);
			}
		}
	}

	private getExpirationDate(ruleType: string, numberOfDays: number): string {
		return formatIsoDate(DateUtil.addDays(
			ruleType == RuleTypes.DAYS_WITHOUT_VIEW && this.lastViewed || this.whenUploaded || DateUtil.getToday(),
			numberOfDays));
	}
}
