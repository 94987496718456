import {
	Component,
	Input,
	OnInit
} from '@angular/core';

import { ISortedEvent } from 'rev-shared/ui/sortable/VbUiSortable.Directive';

import { Question } from './Question';
import { WebcastModel } from 'rev-portal/scheduledEvents/webcast/model/WebcastModel';

import { WebcastViewQuestionsService } from './WebcastViewQuestions.Service';

import { QuestionContext } from './VbWebcastQuestionCard.Component';
import './VbWebcastQuestionsList.Component.less';

@Component({
	selector: 'vb-webcast-questions-list',
	templateUrl: './VbWebcastQuestionsList.Component.html'
})
export class VbWebcastQuestionsListComponent implements OnInit {
	@Input() public questions: Question[];
	@Input() public webcast: WebcastModel;
	@Input() public questionContext: QuestionContext;
	@Input() public allowReordering: boolean;
	public canModerate: boolean;

	public readonly sortableOptions = {
		handle: '.drag-handle'
	};

	constructor(
		private WebcastViewQuestionsService: WebcastViewQuestionsService
	){}

	public ngOnInit(): void {
		this.canModerate = this.webcast.currentUser.canManageQuestions;
	}

	public onSort(event: ISortedEvent): void {
		const fromItem = this.questions[event.oldIndex];
		const toItem = this.questions[event.newIndex];

		this.WebcastViewQuestionsService.reorderQuestions(this.webcast.id, fromItem, toItem);
	}

	public trackByQuestionNumber(_, question: Question): number {
		return question.questionNumber;
	}
}
