import { DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable } from 'rxjs';
import { throttle } from 'underscore';
import { IComment } from './Contract';

const throttleTime = 1000;

export class ChatDataSource extends DataSource<IComment> {
	private comments$ = new BehaviorSubject<IComment[]>([]);

	private next = throttle((comments: IComment[]) => this.comments$.next(comments), throttleTime);

	public connect(): Observable<IComment[]> {
		return this.comments$.asObservable();
	}

	public disconnect(): void {
		this.comments$.complete();
	}

	public setComments(comments: IComment[]): void {
		this.comments$.next(comments);
	}

	public append(comment: IComment): void {
		const comments = this.comments$.getValue();
		comments.push(comment);
		this.next(comments);
	}
}
