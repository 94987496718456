import {
	Component,
	Input,
	OnInit
} from '@vbrick/angular-ts-decorators';

import './SpeechSearchResultsList.less';

@Component({
	selector: 'speech-search-results-list',
	templateUrl: '/partials/media/search/speech-search-results-list.html'
})
export class SpeechSearchResultsListComponent implements OnInit {
	@Input() public isOnVideoPage: boolean;
	@Input() public query: string;
	@Input() public results: any[];
	@Input() public videoId: string;

	private readonly maxShownByDefault: number = 3;

	private isExpanded: boolean;

	public ngOnInit(): void {
		this.isExpanded = false;
	}

	private toggleExpansion(): void {
		this.isExpanded = !this.isExpanded;
	}
}
