import { NgModule } from '@vbrick/angular-ts-decorators';

import BrandingModule from 'rev-portal/branding/Branding.Module';

import { VbCheckboxComponent } from './VbCheckbox.Component';

@NgModule({
	id: 'VBrick.UI.Checkbox',
	declarations: [
		VbCheckboxComponent
	],
	imports: [
		BrandingModule
	]
})
export default class VBrickUICheckboxModule {}
