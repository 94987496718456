import { IDialogProvider } from './IDialog';

export function DialogConfig(DialogProvider: IDialogProvider) {

	DialogProvider.when('ConfirmationDialog', {
		backdrop: 'static',
		keyboard: false,
		component: 'confirmationDialog'
	});
}
