
<ng-template id="featured-styles" [vbCssRules]="cssRules$ | async"></ng-template>

<div class="featured-videos-wrap" layout="vertical" [ngClass]="{
		'transparent-header': brandingSettings?.headerSettings?.useTransparentHeader
	}">
	<div class="centered-text-block video-playback-status-msg" [hidden]="mediaViewingAllowed">
		<div class="centered-text-block-content video-playback-error-msg">
			<h3>
				<span class="glyphicons warning_sign"></span>
				{{ 'Video_Playback_ViewingHoursNotAvailable' | translate }}
			</h3>
		</div>
	</div>

	<section class="featured-wrap" flex="1" flex-align-self="center" *ngIf="mediaViewingAllowed" [ngSwitch]="featuredCarouselType">

		<featured-video-thumbnail-carousel *ngSwitchCase="'Thumbnail'" [videos]="videos" [brandingSettings]="brandingSettings">
		</featured-video-thumbnail-carousel>

		<featured-video-thumbnail-grid *ngSwitchCase="'Grid'" [videos]="videos">
		</featured-video-thumbnail-grid>

		<featured-video-player-carousel *ngSwitchDefault [videos]="videos" [brandingSettings]="brandingSettings">
		</featured-video-player-carousel>

	</section>

	<div class="bottom-nav">
		<a class="down-link theme-accent-link" href="#portal-dashboard-content" [attr.aria-label]="'UI_ScrollDownToNext' | translate" vbAnchorLink="#portal-dashboard-content" [vbAnchorLinkOffset]="-MainHeaderPosition.height">
			<span class="glyphicon glyphicon-chevron-right"></span>
		</a>
	</div>
</div>
