import { Component, OnInit, Input, Output } from '@vbrick/angular-ts-decorators';
import {
	IAugmentedJQuery
} from 'angular';

import { ComponentCallback } from 'rev-shared/ts-utils/ComponentCallback';
import { ComponentCallbackEvent } from 'rev-shared/ts-utils/ComponentCallbackEvent';

import styles from './VbGridList.module.less';

@Component({
	selector: 'vb-grid-list-reorder',
	template: `
		<button type="button" class="btn btn-small btn-transparent btn-svg-caret" ng-click="$ctrl.itemMove(true)">
			<svg viewBox="0 0 400 400" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="1.414">
				<path d="M331.607 247.906l-.432-.432-128.52-128.545c-.693-.695-1.634-1.085-2.616-1.085-.983 0-1.924.39-2.618 1.083L68.97 247.374l-.677.678c-3.694 3.747-5.913 8.877-5.913 14.522 0 11.653 9.46 21.113 21.113 21.113 5.644 0 10.774-2.22 14.45-5.867l.794-.79 98.686-98.55c1.445-1.442 3.787-1.442 5.23.003l98.753 98.76.432.432c3.894 3.793 9.024 6.013 14.668 6.013 11.653 0 21.113-9.46 21.113-21.113 0-5.645-2.22-10.775-6.014-14.67"/>
			</svg>
		</button>
		<button type="button" class="btn btn-small btn-transparent btn-svg-caret" ng-click="$ctrl.itemMove(false)">
			<svg viewBox="0 0 400 400" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="1.414">
				<path d="M331.607 152.094l-.432.432-128.52 128.545c-.693.695-1.634 1.085-2.616 1.085-.983 0-1.924-.39-2.618-1.083L68.97 152.626l-.677-.678c-3.694-3.747-5.913-8.877-5.913-14.522 0-11.653 9.46-21.113 21.113-21.113 5.644 0 10.774 2.22 14.45 5.867l.794.79 98.686 98.55c1.445 1.442 3.787 1.442 5.23-.003l98.753-98.76.432-.432c3.894-3.793 9.024-6.013 14.668-6.013 11.653 0 21.113 9.46 21.113 21.113 0 5.645-2.22 10.775-6.014 14.67"/>
			</svg>
		</button>
	`
})

export class VbGridListReorderComponent implements OnInit {
	@Output() public onItemMove: ComponentCallback;

	constructor(
		private $element: IAugmentedJQuery
	) {
		'ngInject';
	}

	public ngOnInit(): void {
		this.$element.addClass(styles.reorderRow);
		this.$element.addClass(styles.gridColumn);
	}

	public itemMove(isUp: boolean): void {
		this.onItemMove(new ComponentCallbackEvent({
			isUp
		}));
	}
}
