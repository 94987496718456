/**
 * Adds the theme accent class to each of the items in the autocomplete list
 * Meant to be used in conjunction with ngTags input
 * 	Example:
 * 		<auto-complete vb-ui-tags-input-auto-complete...></auto-complete>
 */

import {
	Directive,
	OnInit
} from '@vbrick/angular-ts-decorators';
import { IAugmentedJQuery } from 'angular';

@Directive({
	selector: '[vb-ui-tags-input-auto-complete]'
})

export class VbUITagsInputAutoCompleteDirective implements OnInit {
	constructor(
		private $element: IAugmentedJQuery
	) {
		'ngInject';
	}

	public ngOnInit(): void {
		(this.$element.isolateScope() as any).matchClass = locals => locals.$selected ? 'theme-accent' : null;
	}
}
