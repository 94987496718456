import { Pipe as TsPipe, PipeTransform as TsPipeTransform } from '@vbrick/angular-ts-decorators';
import { Pipe, PipeTransform } from '@vbrick/angular-ts-decorators';
import { FileUtil } from 'rev-shared/util/FileUtil';

@TsPipe({ name: 'fileSize' })
export class FileSizePipe implements TsPipeTransform {
	public transform = transform;
}

@Pipe({ name: 'fileSize' })
export class FileSizeAngularPipe implements PipeTransform {
	public transform = transform;
}

function transform(size: string|number, places: number, sizeInputUnit?: string) {
	return FileUtil.formatFileSize(size, places, sizeInputUnit);
}
