import {
	Component,
	Output
} from '@vbrick/angular-ts-decorators';

import './rev-create-download-dialog.less';

@Component({
	selector: 'rev-create-download-dialog',
	templateUrl: '/partials/media/import/rev-create-download-dialog.html'
})
export class RevCreateDownloadDialogComponent {
	@Output() public close: () => void;
}
