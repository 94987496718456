import { Component, Input } from '@angular/core';

import { deepMergeObjects } from 'rev-shared/util/index';

import { VB_UI_BAR_CHART_HOST_CLASS, VB_UI_BAR_CHART_TEMPLATE, VbUiBarChartComponent } from './VbUiBarChart.Component';

const AXIS_FONT_SIZE: string = '10px';
const AXIS_LINE: any = {
	stroke: '#c5c5c5',
	strokeOpacity: 1,
	strokeWidth: 1
};

@Component({
	selector: 'vb-ui-bar-chart-small',
	host: {
		'[class]': VB_UI_BAR_CHART_HOST_CLASS
	},
	template: VB_UI_BAR_CHART_TEMPLATE
})
export class VbUiBarChartSmallComponent extends VbUiBarChartComponent {
	@Input() public alwaysShowCategoryLabels: boolean = false;

	protected initChartConfig(): void {
		this.hideLegend = true;
		super.initChartConfig();

		this.chartConfig = deepMergeObjects(this.chartConfig, {
			paddingBottom: 4,
			paddingLeft: -4,
			paddingRight: -20,
			paddingTop: 4,
			percentWidth: 100
		});
	}

	protected getCategoryAxis(): any {
		return deepMergeObjects(super.getCategoryAxis(), {
			renderer: {
				dx: -20,
				dy: -8,
				fontSize: AXIS_FONT_SIZE,
				height: 10,
				ignoreAxisWidth: true,
				line: {
					...AXIS_LINE,
					dx: -20
				},
				minGridDistance: this.alwaysShowCategoryLabels ? 1 : 30
			}
		});
	}

	protected getColumnSeries(): any {
		return deepMergeObjects(super.getColumnSeries(), {
			columns: {
				tooltipText: this.tooltipText ||
					`{${this.getCategoryDataField()}}: [bold]{valueY.formatNumber("#,###.##")}[/]`
			},
			dx: -20
		});
	}

	protected getValueAxis(): any {
		return deepMergeObjects(super.getValueAxis(), {
			dx: -20,
			numberFormatter: {
				numberFormat: '#a'
			},
			renderer: {
				fontSize: AXIS_FONT_SIZE,
				grid: {
					template: {
						paddingLeft: -20
					}
				},
				labels: {
					template: {
						paddingLeft: 12 // wiggle room to prevent test from being cut off
					}
				},
				line: AXIS_LINE,
				minGridDistance: 20,
				minLabelPosition: 0.7,
				paddingTop: 1 // wiggle room to prevent text from being cut off
			},
			title: {
				adapter: {
					valign: () => 'bottom' // needed to work around AxisRendererY overriding the normal user config
				},
				dx: 20 + 4,
				fontSize: AXIS_FONT_SIZE,
				tooltip: {
					adapter: {
						pointerOrientation: () => 'left' // gets calculated incorrectly, so forcing it
					}
				},
				tooltipText: this.valueAxisTitleLabel,
				truncate: true,
				width: 68
			},
			width: 48 // helps prevent layout wiggle and occaisional miscalculations
		});
	}
}
