import {
	Component,
	Input
} from '@vbrick/angular-ts-decorators';
import { INgModelController } from 'angular';

import { ThemeService } from 'rev-portal/branding/Theme.Service';

import styles from './vb-ui-radio-button.module.less';

@Component({
	selector: 'vb-ui-radio-button',
	templateUrl: '/partials/ui/vb-ui-radio-button.html',
	require: {
		ngModelController: 'ngModel'
	}
})

export class VbUIRadioButtonComponent {
	@Input() public label: string;
	@Input() public name: string; // Group of buttons should share the same name. Used by the underlying native radio component for nav within the group.
	@Input() public ngModel: any;
	@Input() public value: string;

	private readonly styles = styles;

	private ngModelController: INgModelController;

	constructor(
		private ThemeService: ThemeService
	) {
		'ngInject';
	}

	private onChangeInternal(): void {
		this.ngModelController.$setViewValue(this.ngModel);
	}

	private get accentColor(): string {
		return this.ThemeService.brandingSettings.themeSettings.accentColor;
	}

	private get isChecked(): boolean {
		return this.ngModel === this.value;
	}
}
