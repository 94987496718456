<vb-loading-spinner *ngIf="status.loading" [block]="true" [size]="'medium'">
</vb-loading-spinner>

<header class="right-sidebar-content-header" *ngIf="legacyStyle">
	{{'Event_GeneralChat' | translate }}
</header>

<div layout="column" layout-wrap="false" class="height-full">
	<cdk-virtual-scroll-viewport [ngClass]="styles.commentScroll" flex="1 1 0">
		<div *cdkVirtualFor="let comment of comments" class="comment">
			<span>
				<vb-profile-picture [profileUrl]="comment.userProfileImageUri">
				</vb-profile-picture>
				<span class="type-bold">{{ comment.name }}</span>
			</span>
			<span class="type-italic"> - {{ comment.date | vbTimeShort }} </span>
			<p [innerHTML]="comment.comment | safeHtml"></p>
		</div>
	</cdk-virtual-scroll-viewport>

	<form *ngIf="status.active" #form="ngForm" role="group" [ngClass]="styles.chatForm" class="theme-primary-font-border-fade-50" [attr.aria-label]="'Event_Chat_EnterMessage' | translate" (submit)="postComment()" autocomplete="off">

		<vb-ui-html-editor [attr.aria-label]="'Event_Chat_MessageText' | translate " name="commentText" [(ngModel)]="commentText" [editorConfig]="editorConfig" [singleLineMode]="true" (submit)="postComment()" uiControl>
		</vb-ui-html-editor>

		<button type="submit" [ngClass]="styles.chatSubmitBtn" vbUiBtnPrimaryNgx>
			<span class="glyphicons comments"></span> {{ 'UI_Submit' | translate }}
		</button>
	</form>
</div>
