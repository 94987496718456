<div *ngIf="!!attendee" [ngClass]="[attendee.Removed ? styles.removed : '', styles.row]">

	<vb-profile-picture [ngClass]="styles.imgComp" [altTxt]="'User_ProfilePicture' | translate : { '0': attendee.FullName }" [profileUrl]="attendee.ProfileImageUri">
	</vb-profile-picture>

	<div [ngClass]="styles.personalInfo">
		<span [ngClass]="styles.name" [hidden]="!attendee.Removed" title="{{ 'Event_AttendeeRemoved' | translate : { '0': attendee.FullName } }}">
			{{ 'Event_AttendeeRemoved' | translate : { '0': attendee.FullName } }}
		</span>

		<span [ngClass]="styles.name" [hidden]="attendee.Removed" [title]="attendee.FullName">{{ attendee.FullName }}
		</span>
		<span [ngClass]="styles.email" [title]="attendee.Email">
			{{ attendee.Email }}
		</span>
	</div>

	<button [hidden]="!allowRemove()" (click)="openRemoveAttendeeDialog()" vbUiBtnSecondaryNgx [ngClass]="styles.removeRestoreBtn" type="button" [attr.aria-label]="'Event_AttendeeRemove' | translate" [title]="'Remove' | translate">
		<span class="glyphicons remove_2"></span>
	</button>

	<button [hidden]="!allowRestore()" (click)="restoreAttendee(attendee)" vbUiBtnSecondaryNgx type="button" [attr.aria-label]="'Event_AttendeeRestore' | translate" [ngClass]="styles.removeRestoreBtn" [title]="'Restore' | translate">
		<span class="glyphicons undo"></span>
	</button>

	<vb-confirmation-dialog [title]="'Warning' | translate" [message]="'Event_RemoveWebcastAttendee' | translate : { '0': attendee.FullName }" [actionText]="'Ok' | translate" [cancelText]="'Cancel' | translate" [themed]="true">
	</vb-confirmation-dialog>
</div>
