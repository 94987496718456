import { Component } from '@angular/core';

import { getKeysFromObjects } from 'rev-shared/util';

import { VbUiBarChartComponent, VB_UI_BAR_CHART_TEMPLATE, VB_UI_BAR_CHART_HOST_CLASS } from '../VbUiBarChart.Component';

import styles from '../VbUiBarChart.Component.module.less';

@Component({
	selector: 'vb-ui-stacked-bar-chart',
	host: {
		'[class]': VB_UI_BAR_CHART_HOST_CLASS
	},
	template: VB_UI_BAR_CHART_TEMPLATE
})
export class VbUiStackedBarChartComponent extends VbUiBarChartComponent {
	protected initChartConfig(): void {
		super.initChartConfig();

		this.chartConfig = Object.assign(this.chartConfig, {
			cursor: {
				disabled: true,
				type: 'XYCursor'
			},
			series: this.getColumnSeries()
		});
	}

	protected getColumnSeries(): any[] {
		return getKeysFromObjects(this.data)
			.map(key => key !== this.categoryField && this.createSeries(key))
			.filter(Boolean);
	}

	// Create series
	private createSeries(field: string): any {
		const categoryDataField: string = this.getCategoryDataField();
		const valueDataField: string = this.getValueDataField();

		return {
			type: 'ColumnSeries',
			name: field,
			columns: {
				events: {
					hit: event => this.onBarHitInternal(event)
				},
				tooltipText: this.tooltipText ||
					`[bold]{name}[/]\n{${categoryDataField}}: {${valueDataField}}`
			},
			dataFields: {
				[categoryDataField]: this.categoryField,
				[valueDataField]: field
			},
			sequencedInterpolation: true,
			stacked: true
		};
	}

}
