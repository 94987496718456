import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';
import { StateService } from '@uirouter/angular';

import { AuthenticationSourceType } from 'rev-shared/security/AuthenticationSourceType';
import { FileWrapper } from 'rev-shared/ui/fileUpload/FileWrapper';
import { IImageComponentMetadata, IImageData } from 'rev-shared/ui/image-selector/ImageSelector.Contract.ts';
import { INamedConstant } from 'rev-shared/constants/INamedConstant';
import { LanguageCodes } from 'rev-shared/util/LanguageCodes.Service';
import { FileUtil } from 'rev-shared/util/FileUtil';
import { IAccountLicense } from 'rev-shared/security/IAccountLicense';
import { IMediaFeatures } from 'rev-shared/media/IMediaFeatures';

import { DASHBOARD_STATE } from 'rev-portal/dashboard/Dashboard.StateConfig';

import { UserProfileService } from './UserProfile.Service';
import styles from './UserProfile.module.less';

const MaxProfileSize = 5 * FileUtil.Mb;

@Component({
	selector: 'user-profile',
	templateUrl: './UserProfile.Component.html',
	host: {
		'class': 'theme-primary box-block'
	}
})
export class UserProfileComponent implements OnInit {
	@Input() private accountLicense: IAccountLicense;
	@Input() private backTransition: { name: string; params: any };
	@Input() public user: any;
	@Input() private features: IMediaFeatures;

	@ViewChild('email') private email: NgModel;

	public readonly styles = styles;
	public readonly languageCodes: INamedConstant[] = this.LanguageCodes.languageCodes;

	public imageCfg: IImageComponentMetadata;
	public imageData: IImageData;
	private originalLanguage: string;
	public saving: boolean;

	public get isLdapUser(): boolean {
		return this.user && this.user.sourceType == AuthenticationSourceType.LDAP;
	}


	constructor(
		private $state: StateService,
		private LanguageCodes: LanguageCodes,
		private UserProfileService: UserProfileService
	) {
	}

	public ngOnInit(): void {
		this.imageData = {
			url: this.user.profileImageUri
		};

		this.originalLanguage = this.user.language = this.user.language || '';

		this.imageCfg = {
			requiredValidation: false,
			showDeleteButton: true,
			readOnly: this.user.isLdapProfileImage,
			sizeValidation: true,
			maxSize: MaxProfileSize
		};
	}

	public close(): void {
		const name = this.backTransition.name || DASHBOARD_STATE;

		if(this.user.language !== this.originalLanguage) {
			//if language changes, force page refresh
			window.location.href = this.$state.href(name, {});
			return window.location.reload();
		}

		this.$state.transitionTo(name, this.backTransition.params);
	}

	public submit(): void {
		this.saving = true;
		if(!this.imageData) {
			this.user.profileImageId = null;
		}

		this.UserProfileService.saveProfile({
			...this.user,
			language: this.user.language || null
		},
		this.imageData?.fileWrapper)
			.then(() => this.close())
			.catch(e => {
				if(e.hasIssue && e.hasIssue('EmailInUse')) {
					this.email.control.setErrors({ emailInUse: true });
				}
				console.error('unable to save profile', e);
				this.saving = false;
			});
	}

	public showFacialRecognitionOptOut(): boolean {
		return this.user.profileImageId && this.accountLicense.aiHoursValid && this.features.enableFacialRecognition;
	}
}
