import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Poll } from 'rev-portal/scheduledEvents/polls/Poll';

import styles from './VbWebcastPollsView.Component.module.less';

@Component({
	selector: 'vb-webcast-polls-manage-view',
	templateUrl: './VbWebcastPollsManageView.Component.html'
})
export class VbWebcastPollsManageViewComponent {
	@Input() public poll: Poll;

	@Output() public pollEdited: EventEmitter<{ isNew?: boolean }> = new EventEmitter<{ isNew?: boolean }>();
	@Output() public pollDeleted: EventEmitter<void> = new EventEmitter<void>();

	public readonly styles = styles;

	public onCreateNewPoll(): void {
		this.pollEdited.emit({ isNew: true });
	}

	public onEditPoll(): void {
		this.pollEdited.emit();
	}
}
