import { Component, Input } from '@angular/core';

import { IPollAnswer } from 'rev-portal/scheduledEvents/polls/Polls.Contract';
import { Poll } from 'rev-portal/scheduledEvents/polls/Poll';

import styles from './VbWebcastPollsView.Component.module.less';

@Component({
	selector: 'vb-webcast-polls-attendee-view',
	templateUrl: './VbWebcastPollsAttendeeView.Component.html',
	host: {
		class: 'box-block padding-bottom-20 margin-bottom-20'
	}
})
export class VbWebcastPollsAttendeeViewComponent {
	@Input() public poll: Poll;

	public isFirstVote: boolean = true;

	public readonly styles = styles;

	public vote(): void {
		const answers = (this.poll.answers || []).map(answer => !!answer.checked);

		this.poll.answer(answers);
		this.poll.responseSubmitted = true;
	}

	public onAnswerClick(answer: IPollAnswer): void {
		this.isFirstVote = false;

		if (!this.poll.multipleChoice) {
			(this.poll.answers || []).forEach(a => a.checked = a === answer);
			this.poll.selectedAnswer = answer.text;
		}

		this.poll.responseSubmitted = false;
	}
}
