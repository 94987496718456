import {
	Component,
	Input,
	OnInit
} from '@vbrick/angular-ts-decorators';

import {
	IAugmentedJQuery,
	ITranscludeFunction
} from 'angular';

import { VbMediaTableComponent } from './VbMediaTable.Component';

@Component({
	selector: 'vb-media-table-column',
	transclude: true,
	require: {
		parent: '^^vbMediaTable'
	}
})
export class VbMediaTableColumnComponent implements OnInit {
	@Input() public field: string;
	@Input() public isMobileHidden: boolean;
	@Input() public mobileAlign: string;

	private parent: VbMediaTableComponent;

	constructor(
		private $element: IAugmentedJQuery,
		private $transclude: ITranscludeFunction
	) {
		'ngInject';
	}

	public ngOnInit(): void {
		this.$element
			.toggleClass('ng-hide', !(this.parent.columns[this.field] >= 0)) //may be undefined
			.addClass('table-cell')
			.toggleClass('mobile-hidden', !!this.isMobileHidden)
			.css({
				order: this.parent.columns[this.field]
			});

		if (this.mobileAlign) {
			this.$element.addClass(`mobile-align-${this.mobileAlign}`);
		}

		if (this.$transclude) {
			this.$transclude(clone => this.$element.append(clone));
		}
	}
}
