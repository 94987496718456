import { Observable, empty } from 'rxjs';
import { switchMap, filter, startWith } from 'rxjs/operators';

import { LoginRedirectService } from 'rev-shared/security/LoginRedirect.Service';
import { PushBus } from 'rev-shared/push/PushBus.Service';
import { UserContextService } from 'rev-shared/security/UserContext.Service';

const UserRoute = 'User';

export function PortalMessageHandlers(LoginRedirectService: LoginRedirectService, PushBus: PushBus, UserContext: UserContextService) {

	UserContext.userIdChanged$.pipe(
		startWith(null),
		switchMap(() => {
			if(!UserContext.isUserAuthenticated()) {
				return empty();
			}

			const user = UserContext.getUser();
			return PushBus.getObservable(user.id, UserRoute, {
				LoggedOff: logOut,
				LoggedOffAutomatically: logOut,
				LockedOut: logOut,
				UserSuspended: logOut,
				UserDeleted: logOut,

				UserProfileUpdated: userInfo => {
					UserContext.updateUserInfo(userInfo);
					return empty();
				},

				ApiUserProfileImageUpdated: userInfo => {
					UserContext.updateProfileImageUri(userInfo.profileImageUri);
					return empty();
				}
			});
		})
	)
		.subscribe();

	function logOut(): Observable<void> {
		UserContext.logOutUser(true);
		LoginRedirectService.redirectToLogout();
		return empty();
	}
}
