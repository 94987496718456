import { Component, Input } from '@angular/core';

import { WebcastModel } from 'rev-portal/scheduledEvents/webcast/model/WebcastModel';
import { ShortcutService } from 'rev-portal/scheduledEvents/webcast/Shortcut.Service';

import { IWebcastViewContext } from './IWebcastViewContext';
import styles from './VbWebcastInfoSidebar.module.less';

@Component({
	selector: 'vb-webcast-info-sidebar',
	templateUrl: './VbWebcastInfoSidebar.Component.html',
	host: {
		'[class]': 'styles.root'
	}
})
export class VbWebcastInfoSidebarComponent {
	@Input() public webcast: WebcastModel;
	@Input() public webcastViewContext: IWebcastViewContext;

	public eventUrl: string;
	public readonly styles = styles;
	constructor(
		private ShortcutService: ShortcutService
	){}

	public ngOnInit(): void {
		this.eventUrl = this.ShortcutService.getWebcastUrl(this.webcast);
	}
}
