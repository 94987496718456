import { NgModule } from '@angular/core';

import { UploadMenuModule } from 'rev-portal/navigation/uploadMenu/UploadMenu.Module';
import { NotificationsMenuModule } from './notificationsMenu/NotificationsMenuAngular.Module';

@NgModule({
	imports: [
		NotificationsMenuModule,
		UploadMenuModule
	]
})

export class NavigationAngularModule {}
