import { NgModule } from '@angular/core';
import { WebcastBackgroundAngularDirective } from './WebcastBackground.AngularDirective';

const exports = [
	WebcastBackgroundAngularDirective
];

@NgModule({
	declarations: exports,
	exports
})
export class ScheduledEventsCommonModule {}
