import { Component } from '@angular/core';

import type { ICellRendererAngularComp } from '@ag-grid-community/angular';
import type { ICellRendererParams } from '@ag-grid-community/core';
import { getFilterText } from 'rev-shared/ui/dataGrid/cellRenderers/HighlightCellRenderer.Component';

import { DeviceHealthStatus } from 'rev-shared/device/SharedDeviceContract';

import styles from './RealTimeAnalyticsStatusHealthMetricsRenderer.module.less';

const errorStyle = { class: styles.error, icon: 'vb-icon-warning ' + styles.vbIcon };
const warningStyle = { class: styles.caution, icon: 'glyphicons circle_exclamation_mark' };
const normalStyle = { class: styles.normal, icon: 'glyphicons circle_ok' };
const unavailableStyle = { class: styles.unavailable, icon: 'vb-icon-warning ' + styles.vbIcon };

export const overallStatusInfo = {
	Alert: {
		...errorStyle,
		label: DeviceHealthStatus.Alert
	},
	Caution: {
		...warningStyle,
		label:  DeviceHealthStatus.Caution
	},
	Unavailable: {
		...unavailableStyle,
		label:   DeviceHealthStatus.Unavailable
	},
	Normal: {
		...normalStyle,
		label: DeviceHealthStatus.Normal
	}
};

export const healthMetricStyles = {
	Unavailable: errorStyle,
	Normal: normalStyle,
	Caution: warningStyle,
	Alert: errorStyle
};

interface IStatusRendererParams extends ICellRendererParams{
	getStatusDetails: (data) => IStatusDetails;
}

interface IStatusDetails {
	overallStatus: DeviceHealthStatus;
	healthMetrics: any[];
}

@Component({
	selector: 'real-time-analytics-device-status-renderer',
	templateUrl: './RealTimeAnalyticsStatusHealthMetricsRenderer.Component.html'
})
export class RealTimeAnalyticsStatusHealthMetricsRendererComponent implements ICellRendererAngularComp {
	public statusDetails: IStatusDetails;
	public overallStatus: any = overallStatusInfo.Unavailable;
	public healthMetrics: any[];
	public highlightText: string;

	public readonly styles = styles;

	public agInit(params: IStatusRendererParams): void {
		this.updateCell(params);
	}

	public refresh(params: IStatusRendererParams): boolean {
		this.updateCell(params);

		return true;
	}

	private updateCell(params: IStatusRendererParams): void {
		this.statusDetails = params.getStatusDetails(params.data || {});
		this.overallStatus = overallStatusInfo[this.statusDetails.overallStatus] || overallStatusInfo.Unavailable;
		this.healthMetrics = this.statusDetails.healthMetrics?.length
			? this.getHealthMetricsWithIcon(this.statusDetails.healthMetrics)
			: undefined;
		this.highlightText = getFilterText(params);
	}

	private getHealthMetricsWithIcon(itemStatuses: any[]): any[] {
		return itemStatuses.map(item => ({
			...item,
			...healthMetricStyles[item.status]
		}));
	}
}
