<button [attr.aria-label]="'Event_Toggle' | translate : {'0':  ('Event_Webcast_Region_Video' | translate)}" type="button" [ngClass]="styles.button" (click)="webcast.layout.isVideoVisible = !webcast.layout.isVideoVisible" vbUiBtnSecondaryNgx [inactive]="!webcast.layout.isVideoVisible">
	<span class="glyphicons facetime_video"></span>
	<span [ngClass]="styles.label">{{ 'Event_Webcast_Region_Video' | translate }}</span>
</button>

<button *ngIf="isVolumeControlSupported" [attr.aria-label]="'Event_Toggle' | translate : {'0':  ('Audio' | translate)}" type="button" [ngClass]="styles.button" (click)="toggleSound()" vbUiBtnSecondaryNgx [inactive]="isMuted">

	<span class="glyphicons" [ngClass]="{
			mute: isMuted,
			volume_up: !isMuted
		}">
	</span>
	<span [ngClass]="styles.label">{{ 'Audio' | translate }}</span>
</button>

<button [attr.aria-label]="'Event_Toggle' | translate : {'0':  ('Event_Webcast_Region_Presentation' | translate)}" type="button" [ngClass]="styles.button" *ngIf="webcast.presentation.hasPresentation" (click)="webcast.layout.isPresentationVisible = !webcast.layout.isPresentationVisible" vbUiBtnSecondaryNgx [inactive]="!webcast.layout.isPresentationVisible">
	<span class="glyphicons vb-icon vb-icon-chapters"></span>
	<span [ngClass]="styles.label">{{ 'Event_Webcast_Region_Presentation' | translate }}</span>
</button>

<button [attr.aria-label]="'Event_Toggle' | translate : {'0':  ('Event_Webcast_Region_Slides' | translate)}" type="button" *ngIf="webcast.currentUser.canControlPresentationSlides" [ngClass]="[
		styles.button,
		styles.slides
	]" (click)="webcast.layout.isPresentationSlidesVisible = !webcast.layout.isPresentationSlidesVisible" vbUiBtnSecondaryNgx [inactive]="!webcast.layout.isPresentationSlidesVisible">
	<span class="glyphicons sort"></span>
	<span [ngClass]="styles.label">{{ 'Event_Webcast_Region_Slides' | translate }}</span>
</button>
