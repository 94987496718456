import {
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output
} from '@angular/core';

import type { Column } from '@amcharts/amcharts4/.internal/charts/elements/Column';
import type { Component as AmChartsComponent } from '@amcharts/amcharts4/core';
import type { XYChart } from '@amcharts/amcharts4/.internal/charts/types/XYChart';

import { IOnChartReadyData } from '../amChartsCore/VbUiAmChartsCore.Component';
import { getCommonLegendConfig } from '../amChartsCore/LegendConfig';

import styles from './VbUiBarChart.Component.module.less';

export interface IOnBarHitData {
	data: any;
}

export const VB_UI_BAR_CHART_HOST_CLASS: string = 'styles.barChart';

export const VB_UI_BAR_CHART_TEMPLATE: string = `
	<vb-ui-am-charts-core
		[ngClass]="styles.core"
		[chartConfig]="chartConfig"
		[chartType]="chartType"
		[data]="data"
		[updateEnabled]="updateEnabled"
		(onChartReady)="onChartReadyInternal($event)">
	</vb-ui-am-charts-core>
`;

@Component({
	selector: 'vb-ui-bar-chart',
	host: {
		'[class]': VB_UI_BAR_CHART_HOST_CLASS
	},
	template: VB_UI_BAR_CHART_TEMPLATE
})
export class VbUiBarChartComponent implements OnInit {
	@Input() public categoryAxisTitleLabel: string;
	@Input() public categoryField: string;
	@Input() public data: any;
	@Input() public hideCategoryAxisLabel: boolean;
	@Input() public hideLegend: boolean;
	@Input() public horizontalBars: boolean;
	@Input() public legendLabel: string;
	@Input() public tooltipText: string;
	@Input() public updateEnabled: boolean;
	@Input() public valueAxisTitleLabel: string;
	@Input() public valueField: string;

	@Output() public onBarHit = new EventEmitter<IOnBarHitData>();
	@Output() public onChartReady = new EventEmitter<IOnChartReadyData>();

	protected chart: XYChart;
	public chartConfig: any;
	public chartType: XYChart;

	public readonly styles = styles;

	public ngOnInit(): void {
		Promise.all([
			import(/* webpackChunkName: "amChartsXYChart" */ '@amcharts/amcharts4/.internal/charts/types/XYChart'),
			import(/* webpackChunkName: "amChartsXYCursor" */ '@amcharts/amcharts4/.internal/charts/cursors/XYCursor'),
			import(/* webpackChunkName: "amChartsColumnSeries" */ '@amcharts/amcharts4/.internal/charts/series/ColumnSeries')
		])
			.then(([xyChartExports]) => {
				this.chartType = (xyChartExports as any).XYChart; // TODO: TypeScript 3.7.3 bug workaround
				this.initChartConfig();
			});
	}

	protected initChartConfig(): void {
		const categoryAxis = [this.getCategoryAxis()];

		const valueAxis = [this.getValueAxis()];

		this.chartConfig = {
			cursor: {
				lineX: {
					disabled: true
				},
				lineY: {
					disabled: true
				},
				type: 'XYCursor'
			},
			series: [this.getColumnSeries()],
			xAxes: this.horizontalBars ? valueAxis : categoryAxis,
			yAxes: this.horizontalBars ? categoryAxis : valueAxis,
			legend: this.getLegend(),
			zoomOutButton: { // appears briefly during data update transition
				disabled: true
			}
		};
	}

	protected getCategoryAxis(): any {
		return {
			type: 'CategoryAxis',
			dataFields: {
				category: this.categoryField
			},
			renderer: {
				fontSize: this.horizontalBars ? '.75rem' : undefined,
				grid: {
					disabled: true
				},
				inside: this.horizontalBars,
				inversed: this.horizontalBars,
				minGridDistance: this.horizontalBars ? 1 : 50,
				labels: {
					hidden: this.hideCategoryAxisLabel
				}
			},
			strictMinMax: true,
			title: {
				text: this.categoryAxisTitleLabel
			},
			tooltip: {
				disabled: true
			}
		};
	}

	protected getCategoryDataField(): string {
		const axis: string = this.horizontalBars ? 'Y' : 'X';

		return `category${axis}`;
	}

	protected getColumnSeries(): any {
		const categoryDataField: string = this.getCategoryDataField();
		const valueDataField: string = this.getValueDataField();

		return {
			type: 'ColumnSeries',
			columns: {
				events: {
					hit: event => this.onBarHitInternal(event)
				},
				tooltipText: this.tooltipText ||
					`{${categoryDataField}}: [bold]{${valueDataField}}[/]`,
				adapter: {
					tooltipText: (text, target) => {
						return target.dataItem[categoryDataField]?.trim() ? text : '';
					}
				}
			},
			dataFields: {
				[categoryDataField]: this.categoryField,
				[valueDataField]: this.valueField
			},
			interactionsEnabled: !this.horizontalBars
		};
	}

	protected getValueAxis(): any {
		return {
			type: 'ValueAxis',
			cursorTooltipEnabled: false,
			maxPrecision: 0,
			min: 0,
			strictMinMax: true,
			title: {
				text: this.valueAxisTitleLabel
			}
		};
	}

	protected getValueDataField(): string {
		const axis: string = this.horizontalBars ? 'X' : 'Y';

		return `value${axis}`;
	}

	protected onBarHitInternal(event: { target: Column }): void {
		if (this.onBarHit) {
			this.onBarHit.emit({
				data: event.target.dataItem.dataContext
			});
		}
	}

	protected getLegend(): any {
		if (this.hideLegend) {
			return;
		}
		return {
			...getCommonLegendConfig(),
			labels: {
				text: this.legendLabel || '{name}'
			}
		};
	}

	public onChartReadyInternal(event: IOnChartReadyData): void {
		this.chart = event.chart as XYChart;

		this.onChartReady.emit({
			chart: this.chart
		});
	}

}
