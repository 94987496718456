import { NgModule } from '@vbrick/angular-ts-decorators';
import { StateProvider } from '@uirouter/angularjs';
import { downgradeInjectables } from 'rev-shared/util/AngularHybridUtils';

import SecurityModule from 'rev-shared/security/Security.Module';
import SharedTeamModule from 'rev-shared/team/SharedTeam.Module';

import { StateConfig } from './StateConfig';
import { TeamListingsService } from './TeamListings.Service';
import { TeamsListComponent } from './TeamsList.Component';

import './team.less';

@NgModule({
	id: 'VBrick.Portal.Media.Teams',
	declarations: [
		TeamsListComponent
	],
	imports: [
		SharedTeamModule,
		SecurityModule
	],

})
export default class TeamsModule {
	public static config(
		$stateProvider: StateProvider
	): void {
		'ngInject';

		StateConfig($stateProvider);
	}
}

downgradeInjectables(TeamsModule, [{
	name: 'TeamListingsService',
	injectable: TeamListingsService
}]);
