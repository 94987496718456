import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

import { IOnChartReadyData } from 'rev-shared/ui/dataVisualization/amChartsCore/VbUiAmChartsCore.Component';
import { SecondMs } from 'rev-shared/date/Time.Constant';

import { WebcastModel } from '../webcast/model/WebcastModel';

import { RealTimeAnalyticsKpiService } from './RealTimeAnalyticsKpi.Service';
import { RealTimeAnalyticsGridStateService } from './RealTimeAnalyticsGridState.Service';

import styles from './RealTimeAnalytics.Component.module.less';

@Component({
	selector: 'real-time-analytics',
	templateUrl: './RealTimeAnalytics.Component.html',
	host: {
		'[class]': 'hostClass',
		layout: 'column',
		'layout-wrap': 'false'
	},
	providers: [
		RealTimeAnalyticsGridStateService,
		RealTimeAnalyticsKpiService
	]
})
export class RealTimeAnalyticsComponent implements OnDestroy, OnInit {
	@Input() public kpiUpdateIntervalSecs: number;
	@Input() public tableUpdateIntervalSecs: number;
	@Input() public webcast: WebcastModel;

	public readonly styles = styles;
	public readonly hostClass: string = `theme-primary-bg ${this.styles.root}`;

	private webcastSubscription: Subscription;

	constructor(
		public kpiService: RealTimeAnalyticsKpiService
	) {}

	public ngOnInit(): void {
		this.kpiService.initialize(this.webcast, this.kpiUpdateIntervalSecs * SecondMs);

		this.webcastSubscription = this.webcast.webcast$.pipe(
			filter(webcast => !webcast.currentRun.isStarted),
			tap(() => this.kpiService.resetPollingWhen())
		).subscribe();
	}

	public onExperiencedRebufferingChartReady(event: IOnChartReadyData): void {
		// TODO
	}

	public onNumAttendeesChartReady(event: IOnChartReadyData): void {
		// TODO
	}
	public ngOnDestroy(): void {
		this.webcastSubscription?.unsubscribe();
	}
}
