import { StateProvider } from '@uirouter/angularjs';

import { IVBrickStateDeclaration } from 'rev-shared/ts-utils/IVBrickStateDeclaration';

export const MEDIA_SETTINGS_STATE = 'portal.admin.media-settings';
export const MEDIA_SETTINGS_CATEGORIES_STATE = `${MEDIA_SETTINGS_STATE}.categories`;
export const MEDIA_SETTINGS_TRANSCODING_STATE = `${MEDIA_SETTINGS_STATE}.transcoding`;
export const MEDIA_SETTINGS_FEATURES_STATE = `${MEDIA_SETTINGS_STATE}.features`;
export const MEDIA_SETTINGS_INTEGRATIONS_STATE = `${MEDIA_SETTINGS_STATE}.integrations`;
export const MEDIA_SETTINGS_WEBEX_SITE_STATE = `${MEDIA_SETTINGS_STATE}.webex-site`;
export const MEDIA_SETTINGS_APPROVAL_PROCESS_STATE = `${MEDIA_SETTINGS_STATE}.approval-processes`;

export function MediaSettingStatesConfig($stateProvider: StateProvider): void {
	$stateProvider.state(MEDIA_SETTINGS_STATE, {
		url: '/media-settings',
		component: 'mediaSettings',
		authorizationKey: 'admin.media',
		secureRedirects: [
			MEDIA_SETTINGS_CATEGORIES_STATE,
			MEDIA_SETTINGS_TRANSCODING_STATE,
			MEDIA_SETTINGS_FEATURES_STATE,
			MEDIA_SETTINGS_APPROVAL_PROCESS_STATE
		]
	} as IVBrickStateDeclaration);
}
