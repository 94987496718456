import { Component, Input, OnInit } from '@vbrick/angular-ts-decorators';
import { IDocumentService, IWindowService } from 'angular';
import { UtilService } from 'rev-shared/util/Util.Service';

import './share-embed.less';

@Component({
	selector: 'share-embed',
	templateUrl: '/partials/media/share-embed.html'
})
export class ShareEmbed implements OnInit {
	@Input() public defaultAutoplay: boolean;
	@Input() public defaultSizeName: string;
	@Input() public embedUrl: string;
	@Input() public sizes: EmbedSize[];

	protected embed: EmbedModel;
	protected embedCode: string;
	protected isCopyCmdSupported: boolean;
	protected showOptions: boolean = false;

	constructor(
		protected $document: IDocumentService,
		protected $window: IWindowService,
		Util: UtilService
	) {
		'ngInject';
		this.isCopyCmdSupported = Util.getCopyCommandSupported();
	}

	public ngOnInit(): void {
		const defaultSize: EmbedSize = this.sizes.find((size: EmbedSize) => size.name === this.defaultSizeName);
		this.embed = new EmbedModel(defaultSize.width, defaultSize.height, defaultSize.name, this.defaultAutoplay),
		this.constructEmbedCode();
	}

	public onAutoplayChange(): void {
		this.constructEmbedCode();
	}

	public toggleOptions(): void {
		this.showOptions = !this.showOptions;
	}

	protected onSizeMenuChange(): void {
		const isCustom: boolean = this.embed.sizeName === 'custom';
		const isResponsive: boolean = this.embed.sizeName === 'responsive';

		if (!isCustom && !isResponsive) {
			this.embed.width = this.getSelectedPresetSizeByName(this.embed.sizeName).width;
			this.updateHeight();
		}

		this.constructEmbedCode();
	}

	protected onEmbedWidthChange() {
		this.updateHeight();

		this.constructEmbedCode();
	}

	protected updateHeight(): void {
		const matchingPresetSize: EmbedSize = this.sizes.find((size: EmbedSize) => size.width === this.embed.width);

		if (matchingPresetSize) {
			this.embed.height = matchingPresetSize.height;
			this.embed.sizeName = matchingPresetSize.name;
		} else {
			this.embed.sizeName = 'custom';
			this.embed.height = Math.round(this.embed.width * 9 / 16);
		}
	}

	protected constructEmbedCode(): void {
		if(this.embed.sizeName === 'responsive') {
			this.embedCode = this.getResponsiveEmbedCode();
		} else {
			this.embedCode = this.getEmbedCode(this.embed.width, this.embed.height, this.embed.autoplay);
		}
	}

	protected getResponsiveEmbedCode(): string {
		const iframeHtml = this.getEmbedCode('100%', '100%', this.embed.autoplay, 'position: absolute; left: 0; top: 0;');

		return `<div style="position: relative; height: 0; padding-bottom: 56.25%;">${iframeHtml}</div>`;
	}

	protected getEmbedCode(width: number|string, height: number|string, autoplay: boolean, style: string = ''): string {
		const autoplayParam = autoplay ? '&autoplay' : '';
		const styleOutput = style || '';

		return `<iframe width="${width}" height="${height}" src="${this.embedUrl}${autoplayParam}" style="${styleOutput}" frameborder="0" allowfullscreen></iframe>`;
	}

	private getSelectedPresetSizeByName(sizeName: string): EmbedSize {
		return this.sizes.find((size: EmbedSize) => size.name === sizeName);
	}
}

export class EmbedSize {
	constructor(
		public width: number,
		public height: number,
		public name: string,
		public label: string
	) {}
}

class EmbedModel {
	constructor(
		public width: number,
		public height: number,
		public sizeName: string,
		public autoplay: boolean) {}
}
