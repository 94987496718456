import { NgModule } from '@vbrick/angular-ts-decorators';

import { downgradeComponents } from 'rev-shared/util/AngularHybridUtils';

import { VbProfilePictureComponent } from './VbProfilePicture.Component';

@NgModule({
	id: 'VBrick.UI.Profile'
})
export class ProfileModule {}

downgradeComponents(ProfileModule, [{
	name: 'vbProfilePicture',
	component: VbProfilePictureComponent
}]);
