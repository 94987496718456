import { AfterContentInit, AfterViewInit, Component, ContentChild, ElementRef, Input, OnDestroy, ViewChild, OnInit } from '@angular/core';
import { NgModel } from '@angular/forms';

import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';

import { IVbUiDataGridState } from 'rev-shared/ui/dataGrid/VbUiDataGrid.Component';
import { VbUiInfiniteScrollGridComponent } from 'rev-shared/ui/dataGrid/infiniteScroll/VbUiInfiniteScrollGrid.Component';
import { isString } from 'rev-shared/util';

import { WebcastModel } from 'rev-portal/scheduledEvents/webcast/model/WebcastModel';

import { RealTimeAnalyticsComponent } from './RealTimeAnalytics.Component';
import { RealTimeAnalyticsGridStateService } from './RealTimeAnalyticsGridState.Service';

import styles from './RealTimeAnalyticsDataGridWrapper.Component.module.less';

const GRID_PAGE_SIZE: number = 50;
const MIN_SEARCH_LENGTH: number = 2;
const SEARCH_DEBOUNCE_MS: number = 500;

@Component({
	selector: 'real-time-analytics-data-grid-wrapper',
	templateUrl: './RealTimeAnalyticsDataGridWrapper.Component.html',
	host: {
		class: 'height-full',
		layout: 'column',
		'layout-wrap': 'false'
	}
})
export class RealTimeAnalyticsDataGridWrapperComponent implements AfterContentInit, AfterViewInit, OnDestroy, OnInit {
	@Input() public header: string;
	@Input() public gridId: string;
	@Input() public webcast: WebcastModel;

	@ContentChild(VbUiInfiniteScrollGridComponent) public grid: VbUiInfiniteScrollGridComponent;
	@ContentChild(VbUiInfiniteScrollGridComponent, { read: ElementRef }) private gridElement: ElementRef<HTMLElement>;

	@ViewChild('searchInputNgModel') private searchInput: NgModel;

	public gridRestoreState: IVbUiDataGridState;
	public searchInputValue: string;
	public readonly styles = styles;

	private subscriptions: Subscription[];

	constructor(
		private gridStateSvc: RealTimeAnalyticsGridStateService,
		private rta: RealTimeAnalyticsComponent
	) {}

	public ngOnInit(): void {
		this.gridRestoreState = this.gridStateSvc.getState(this.gridId);
		this.searchInputValue = this.gridRestoreState?.quickFilter || '';
	}

	public ngAfterContentInit(): void {
		this.gridElement.nativeElement.setAttribute('flex', 'fill');
		this.gridElement.nativeElement.classList.add('height-full');

		this.grid.suppressMultiSort = true;
		this.grid.columnPickerEnabled = true;
		this.grid.context = {
			...this.grid.context,
			runNumber: this.webcast.currentRun.runNumber,
			webcastId: this.webcast.id
		};
		this.grid.defaultColDef = {
			...this.grid.defaultColDef,
			minWidth: 150,
			resizable: true
		};
		this.grid.pageSize = GRID_PAGE_SIZE;
		this.grid.pollingIntervalSecs = this.rta.tableUpdateIntervalSecs;
		this.grid.restoreState = this.gridRestoreState;
	}

	public ngAfterViewInit(): void {
		this.subscriptions = [
			this.initDataGridCurrentStateChangeSubscription(),
			this.initSearchInputSubscription()
		];
	}

	public ngOnDestroy(): void {
		this.subscriptions?.forEach(sub => sub.unsubscribe());
	}

	private initDataGridCurrentStateChangeSubscription(): Subscription {
		return this.grid.currentStateChange.subscribe(currentState => this.gridStateSvc.setState(this.gridId, currentState));
	}

	private initSearchInputSubscription(): Subscription {
		return this.searchInput.valueChanges
			.pipe(
				filter(text => isString(text)),
				map(text => text.trim()),
				filter(text => !text.length || text.length >= MIN_SEARCH_LENGTH),
				debounceTime(SEARCH_DEBOUNCE_MS),
				distinctUntilChanged()
			)
			.subscribe(value => this.grid.setQuickFilterText(value));
	}
}
