import { NgModule } from '@angular/core';
import { UIRouterModule, Transition } from '@uirouter/angular';

import { ng2StatesToHybridStates } from 'rev-shared/uiRouterUtils/HybridRouterUtils';
import { SpinnerAngularModule } from 'rev-shared/ui/spinner/SpinnerAngular.Module';
import { lazyLoadFromTransition } from 'rev-shared/util/AngularJsLazyLoadHelper.Service';

@NgModule({
	imports: [
		SpinnerAngularModule,
		UIRouterModule.forChild({
			states: ng2StatesToHybridStates([{
				name: 'portal.debug.**',
				url: '/debug',
				lazyLoad: (transition: Transition) => lazyLoadFromTransition(
					transition,
					import(/* webpackChunkName: "debugModule" */ 'rev-portal/debugModule/Debug.Module'),
					'DebugModule'
				)
			}])
		})
	]
})
export class DebugLoaderModule {
}
