import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';

import { DataGridModule } from 'rev-shared/ui/dataGrid/DataGrid.Module';

import { WebexSitesListGridComponent } from './WebexSitesListGrid.Component';

const components = [
	WebexSitesListGridComponent //downgraded component, needs to be loaded on root injector.
];

@NgModule({
	declarations: components,
	entryComponents: components,
	imports: [
		CommonModule,
		DataGridModule,
		TranslateModule
	]
})
export class MediaFeaturesPreLoadModule {
}
