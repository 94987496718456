import {
	Component,
	Input
} from '@vbrick/angular-ts-decorators';

import { IFormController, INgModelController } from 'angular';

import { BaseInlineEditComponent } from './BaseInlineEditComponent';
import { ButtonType } from './TypeaheadButtonType';
import { ITypeaheadCfg } from './ITypeaheadCfg';

const TypeaheadDelayMs = 200;
const TypeaheadMinLength = 1;

@Component({
	selector: 'vb-ui-typeahead',
	templateUrl: '/partials/shared/components/vb-ui-typeahead.html',
})
export class VbUiTypeaheadComponent extends BaseInlineEditComponent<any> {
	@Input() public typeaheadCfg: ITypeaheadCfg;

	private readonly TypeaheadDelayMs = TypeaheadDelayMs;

	private typeaheadLoading: boolean;
	private typeaheadNoResults: boolean;

	public form: IFormController & {
		editValue: INgModelController;
	};

	public get typeaheadMinLength(): number {
		return this.typeaheadCfg.minLength || TypeaheadMinLength;
	}

	private getTitleInternal(v: any): string {
		return v && this.typeaheadCfg.getTitle(v);
	}

	public get isSearchType(): boolean {
		return this.typeaheadCfg && this.typeaheadCfg.btnCfg
			&& this.typeaheadCfg.btnCfg.type === ButtonType.Search;
	}

	public get isOkCancelType(): boolean {
		return this.typeaheadCfg && this.typeaheadCfg.btnCfg
			&& this.typeaheadCfg.btnCfg.type === ButtonType.OkCancel;
	}

	public onSelect(): void {
		if(!this.typeaheadCfg.submitOnSelect) {
			return;
		}
		this.onSubmit();
	}

	public onTypeaheadSubmit(): void {
		this.onSubmit();
	}

	public onTypeaheadBlur(): void {
		this.typeaheadNoResults = false;

		if (this.typeaheadCfg.notifyWhenNoValue && !this.internalValue) {
			this.onSubmit();
		}
	}
}
