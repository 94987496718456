import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NgModule } from '@angular/core';
import { RatingModule } from 'ngx-bootstrap/rating';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { auto } from 'angular';

import { VbrickPlayerModule } from '@vbrick/vbrick-player';

import { GoogleAnalyticsModule } from 'rev-shared/ui/googleAnalytics/GoogleAnalytics.Module';
import { PushModule } from 'rev-shared/push/Push.Module';
import { SecurityAngularModule } from 'rev-shared/security/SecurityAngular.Module';
import { SecurityComponentsModule } from 'rev-shared/security/components/SecurityComponents.Module';
import { SpinnerRootModule } from 'rev-shared/ui/spinner/SpinnerRoot.Module';
import { VideoChapterModule } from 'rev-shared/media/videoChapter/VideoChapter.Module';
import { VideoPlaylistModule } from 'rev-shared/media/videoPlaylist/VideoPlaylist.Module';
import { VbrickSharedMediaModule } from 'rev-shared/media/Media.Module';

import { AdminLoaderModule } from './admin/AdminLoader.Module';
import { BrandingRootModule } from './branding/BrandingRoot.Module';
import { DashboardModule } from './dashboard/Dashboard.Module';
import { DebugLoaderModule } from './debugModule/DebugLoader.Module';
import { RootDialogAngularModule } from 'rev-shared/ui/dialog/DialogAngular.Module';
import { MediaAngularModule } from './media/MediaAngular.Module';
import { NavigationAngularModule } from './navigation/NavigationAngular.Module';
import { NotificationAngularModule } from './notifications/NotificationAngular.Module';
import { PortalStartup } from './PortalStartup.Provider';
import { ScheduledEventsAngularModule } from './scheduledEvents/ScheduledEventsAngular.Module';
import { ScheduledEventsLoaderModule } from './scheduledEvents/ScheduledEventsLoader.Module';
import { UserProfileModule } from './userProfile/UserProfile.Module';

@NgModule({
	imports: [
		AdminLoaderModule,
		BrandingRootModule,
		BsDatepickerModule.forRoot(),
		BsDropdownModule.forRoot(),
		DashboardModule,
		TabsModule.forRoot(),
		DebugLoaderModule,
		RootDialogAngularModule,
		GoogleAnalyticsModule,
		NavigationAngularModule,
		MediaAngularModule,
		NotificationAngularModule,
		PushModule.forRoot(),
		RatingModule.forRoot(),
		ScheduledEventsAngularModule,
		ScheduledEventsLoaderModule,
		SecurityAngularModule,
		SecurityComponentsModule,
		SpinnerRootModule,
		TooltipModule.forRoot(),
		TypeaheadModule,
		UserProfileModule,
		VbrickPlayerModule,
		VbrickSharedMediaModule,
		VideoChapterModule,
		VideoPlaylistModule
	],
	providers: [{
		provide: PortalStartup,
		deps: ['$injector'],
		useFactory: ($injector: auto.IInjectorService) => $injector.get(PortalStartup)

	}]
})
export class PortalAngularModule {}
