import { NgModule } from '@vbrick/angular-ts-decorators';
import { downgradeComponents } from 'rev-shared/util/AngularHybridUtils';
import { SignInBtnComponent } from './SignInBtn.Component';

@NgModule({
	id: 'Vbrick.UI.SignInBtnDowngrade'
})
export class SignInBtnDowngradeModule {}

downgradeComponents(SignInBtnDowngradeModule, [
	{
		component: SignInBtnComponent,
		name: 'signInBtn'
	}
]);
