import { NgModule } from '@vbrick/angular-ts-decorators';

import { downgradeComponents } from 'rev-shared/util/AngularHybridUtils';

import { CameraSpinnerComponent } from './CameraSpinner.Component';
import { ConnectingSpinnerComponent } from './ConnectingSpinner.Component';
import { InitializingSpinnerComponent } from './InitializingSpinner.Component';

@NgModule({
	id: 'Vbrick.Shared.StatusSpinners'
})
export default class StatusSpinnersModule {}

downgradeComponents(StatusSpinnersModule, [
	{
		component: CameraSpinnerComponent,
		name: 'cameraSpinner'
	},
	{
		component: ConnectingSpinnerComponent,
		name: 'connectingSpinner'
	},
	{
		component: InitializingSpinnerComponent,
		name: 'initializingSpinner'
	}
]);
