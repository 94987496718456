import { Component, Input } from '@angular/core';

import { AnalyticsService } from 'rev-shared/analytics/Analytics.Service';
import { IIconCellRendererParams } from 'rev-shared/ui/dataGrid/cellRenderers/IconCellRenderer.Component';
import { mediumDateTimeValueFormatter } from 'rev-shared/ui/dataGrid/valueFormatters/MediumDateTimeValueFormatter';
import { numberValueFormatter, twoDecimalValueFormatter } from 'rev-shared/ui/dataGrid/valueFormatters/NumberValueFormatter';

import { WebcastModel } from 'rev-portal/scheduledEvents/webcast/model/WebcastModel';

import { RealTimeAnalyticsAttendeesService } from './RealTimeAnalyticsAttendees.Service';

import styles from './RealTimeAnalyticsAttendeesTab.Component.module.less';

enum AttendeesColumnPickerGroup {
	METRICS,
	SESSION,
	SYSTEM,
	USER
}

const STATUS_ONLINE: string = 'Online';
const STATUS_CELL_RENDERER_PARAMS: IIconCellRendererParams = {
	getIconClass(cellValue) {
		return cellValue === STATUS_ONLINE ?
			styles.statusIconOnline :
			styles.statusIconOffline;
	}
};

@Component({
	selector: 'real-time-analytics-attendees-tab',
	templateUrl: './RealTimeAnalyticsAttendeesTab.Component.html',
	providers: [
		RealTimeAnalyticsAttendeesService
	]
})
export class RealTimeAnalyticsAttendeesTabComponent {
	@Input() public webcast: WebcastModel;

	public readonly AttendeesColumnPickerGroup = AttendeesColumnPickerGroup;
	public readonly STATUS_CELL_RENDERER_PARAMS = STATUS_CELL_RENDERER_PARAMS;
	public readonly mediumDateTimeValueFormatter = mediumDateTimeValueFormatter;
	public readonly numberValueFormatter = numberValueFormatter;
	public readonly twoDecimalValueFormatter = twoDecimalValueFormatter;

	constructor(
		public rtaAttendeesService: RealTimeAnalyticsAttendeesService,
		public AnalyticsService: AnalyticsService
	) {}
}
