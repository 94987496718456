import {
	Component,
	Input,
	OnInit
} from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { BroadcastStatus } from '../../model/BroadcastStatus';
import { WebcastModel } from '../../model/WebcastModel';
import { WebcastStatus } from '../../model/WebcastStatus';

import { DialogService } from 'rev-shared/ui/dialog/Dialog.Service';

import styles from '../WebcastNav.module.less';

@Component({
	selector: 'webcast-broadcast-button',
	templateUrl: './WebcastBroadcastButton.Component.html'
})
export class WebcastBroadcastButtonComponent implements OnInit {
	@Input() public webcast: WebcastModel;

	public broadcast: BroadcastStatus;
	public webcastStatus: WebcastStatus;
	public isStarting: boolean;

	public readonly styles = styles;

	constructor(
		private Dialog: DialogService,
		private TranslateService: TranslateService
	) {}

	public ngOnInit(): void {
		this.broadcast = this.webcast.broadcast;
		this.webcastStatus = this.webcast.webcastStatus;
	}

	public startBroadcast(){
		this.isStarting = true;
		this.broadcast.startBroadcast()
			.catch(() => this.openDialog('Event_UnableToStartBroadcasting', 'Error'))
			.finally(() => this.isStarting = false);
	}

	public stopBroadcast(){
		this.broadcast.stopBroadcast()
			.catch(() => this.openDialog('Event_UnableToStopBroadcasting', 'Error'));
	}

	private openDialog(messageKey: string, titleKey: string, actionKey: string = 'Ok'): void {
		const params = {
			message: this.TranslateService.instant(messageKey),
			title: this.TranslateService.instant(titleKey),
			actionText: this.TranslateService.instant(actionKey)
		};
		this.Dialog.openConfirmationDialog(params);
	}
}
