import { NgModule } from '@vbrick/angular-ts-decorators';
import AngularUiRouter from '@uirouter/angularjs';

import VBrickSharedUtilModule from 'rev-shared/util/Util.Module';

import { VbSrefToggleDirective } from './VbSrefToggle.Directive';
import { VbNavigationHandlerComponent } from './VbNavigationHandler.Component';


import { RxWindowLocationService } from './RxWindowLocation.Service';

@NgModule({
	id: 'VBrick.Shared.UiRouterUtils',
	declarations: [
		VbNavigationHandlerComponent,
		VbSrefToggleDirective
	],
	imports: [
		AngularUiRouter,
		VBrickSharedUtilModule
	],
	providers: [
		RxWindowLocationService
	]
})
export default class UiRouterUtilsModule {}
