<real-time-analytics-data-grid-wrapper [header]="'Event_RealTimeAnalytics_Tab_Devices' | translate" [webcast]="webcast" gridId="devices">
	<vb-ui-infinite-scroll-grid [getRows]="rtaDevicesService.getRows" [clearScrollId]="AnalyticsService.clearEventScrollId">

		<!--Column Picker Groups-->
		<vb-ui-column-picker-group [headerName]="'Reports_Column_Group_Metrics' | translate" [id]="DevicesColumnPickerGroup.METRICS" [isExpanded]="true">
		</vb-ui-column-picker-group>

		<vb-ui-column-picker-group [headerName]="'Reports_Column_Group_Session' | translate" [id]="DevicesColumnPickerGroup.SESSION" [isExpanded]="true">
		</vb-ui-column-picker-group>

		<vb-ui-column-picker-group [headerName]="'Reports_Column_Group_System' | translate" [id]="DevicesColumnPickerGroup.SYSTEM" [isExpanded]="true">
		</vb-ui-column-picker-group>

		<vb-ui-column-picker-group [headerName]="'Device' | translate" [id]="DevicesColumnPickerGroup.DEVICE" [isExpanded]="true">
		</vb-ui-column-picker-group>

		<!--Default Columns-->
		<vb-ui-data-grid-column [columnPickerGroup]="DevicesColumnPickerGroup.DEVICE" [headerName]="'Device' | translate" [cellRendererParams]="nameCellParams" [sortable]="true" cellRenderer="link" field="DeviceName" tooltipField="DeviceName">
		</vb-ui-data-grid-column>

		<vb-ui-data-grid-column [columnPickerGroup]="DevicesColumnPickerGroup.DEVICE" [cellRendererFramework]="RealTimeAnalyticsDeviceStatusRendererComponent" [cellRendererParams]="statusCellRendererParams" [field]="'Status'" [sortable]="true" [headerName]="'Status' | translate">
		</vb-ui-data-grid-column>

		<vb-ui-data-grid-column [columnPickerGroup]="DevicesColumnPickerGroup.SYSTEM" [cellRendererFramework]="RealTimeAnalyticsDeviceZoneRendererComponent" [field]="'AllZones'" [headerName]="'Reports_Column_Zone' | translate">
		</vb-ui-data-grid-column>

		<vb-ui-data-grid-column [columnPickerGroup]="DevicesColumnPickerGroup.SESSION" [field]="'AttendeeCount'" [filter]="false" [headerName]="'Viewers' | translate" [sortable]="true" [tooltipField]="'AttendeeCount'">
		</vb-ui-data-grid-column>

		<vb-ui-data-grid-column [columnPickerGroup]="DevicesColumnPickerGroup.SESSION" [field]="'AvgBitrateKbps'" [filter]="false" [headerName]="'Event_RealTimeAnalytics_AvgBitrate' | translate" [sortable]="true" [valueFormatter]="numberValueFormatter">
		</vb-ui-data-grid-column>

		<vb-ui-data-grid-column [columnPickerGroup]="DevicesColumnPickerGroup.METRICS" [field]="'AvgExperiencedBufferingDuration'" [filter]="false" [headerName]="'Event_RealTimeAnalytics_AvgRebufferDuration' | translate" [sortable]="true" [sortingOrderReversed]="true" [valueFormatter]="twoDecimalValueFormatter">
		</vb-ui-data-grid-column>

		<vb-ui-data-grid-column [columnPickerGroup]="DevicesColumnPickerGroup.METRICS" [field]="'ExperiencedErrors'" [filter]="false" [headerName]="'Event_RealTimeAnalytics_ExperiencedErrors' | translate" [sortable]="true" [sortingOrderReversed]="true" [valueFormatter]="numberValueFormatter">
		</vb-ui-data-grid-column>

		<vb-ui-data-grid-column [columnPickerGroup]="DevicesColumnPickerGroup.METRICS" [field]="'MulticastFailovers'" [filter]="false" [headerName]="'Event_RealTimeAnalytics_MulticastErrors' | translate" [sortable]="true" [sortingOrderReversed]="true" [valueFormatter]="numberValueFormatter">
		</vb-ui-data-grid-column>

		<!--Hidden Columns-->

		<vb-ui-data-grid-column [columnPickerGroup]="DevicesColumnPickerGroup.METRICS" [field]="'MulticastConnectionsCount'" [filter]="false" [headerName]="'Event_RealTimeAnalytics_MulticastConnections' | translate" [sortable]="true" [sortingOrderReversed]="true" [hide]="true" [valueFormatter]="numberValueFormatter">
		</vb-ui-data-grid-column>

		<vb-ui-data-grid-column [columnPickerGroup]="DevicesColumnPickerGroup.METRICS" [field]="'UnicastConnectionsCount'" [filter]="false" [headerName]="'Event_RealTimeAnalytics_UnicastConnections' | translate" [sortable]="true" [sortingOrderReversed]="true" [hide]="true" [valueFormatter]="numberValueFormatter">
		</vb-ui-data-grid-column>

	</vb-ui-infinite-scroll-grid>
</real-time-analytics-data-grid-wrapper>
