import { NgZone } from '@angular/core';
import { StateDeclaration, StateService, Transition } from '@uirouter/angular';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

import { IVbNg2StateDeclaration } from 'rev-shared/ts-utils/IVbNg2StateDeclaration';
import { VbWebcastChatSidebarComponent } from 'rev-shared/webcast/webcastView/chat/VbWebcastChatSidebar.Component';
import { PushBus } from 'rev-shared/push/PushBus.Service';
import { UserContextService } from 'rev-shared/security/UserContext.Service';

import { initializeWebcastTheme } from 'rev-portal/scheduledEvents/webcast/WebcastBranding';
import { PortalStartup, IPortalStartup } from 'rev-portal/PortalStartup.Provider';

import { WEBCAST_FULLSCREEN_STATE, WEBCAST_FULLSCREEN_PRESENTATION_STATE, WEBCAST_LANDING_STATE, WEBCAST_VIEW_STATE } from './Constants';
import { WebcastModel } from './model/WebcastModel';
import { WebcastPresentationComponent } from '../presentations/WebcastPresentation.Component';
import { WebcastModelService } from './model/WebcastModel.Service';
import { WebcastService } from './Webcast.Service';
import { initCurrentRunFullscreenNgxResolve } from '../webcast/Webcast.StateConfig';
import { ThemeService } from 'rev-portal/branding/Theme.Service';

const RequiredFeature = 'requiredFeature';

export const requiredSidebarFeature = isFeatureEnabledFn => ({
	token: RequiredFeature,
	deps: ['webcast', StateService],
	resolveFn: (webcast: WebcastModel, $state: StateService) =>
		webcast.webcast$.pipe(
			first(w => !isFeatureEnabledFn(w))
		).subscribe(() => {
			$state.go(WEBCAST_VIEW_STATE);
			webcast.layout.isRightSidebarOpen = false;
		})
});

export function onExitRequiredFeature(transition: Transition): void {
	transition.injector(undefined, 'exiting')
		.get(RequiredFeature)
		.unsubscribe();
}

export const states: IVbNg2StateDeclaration[] = [
	{
		name: `${WEBCAST_VIEW_STATE}.chat`,
		url: '/chat',
		allowRegisteredGuestAccess: true,
		resolve: [
			{
				token: 'legacyStyle',
				resolveFn: () => true
			},
			requiredSidebarFeature((w: WebcastModel) => w.chatEnabled)
		],

		onExit(transition: Transition): void {
			const sub: Subscription = transition.injector(undefined, 'exiting').get(RequiredFeature);
			sub.unsubscribe();
		},

		views: {
			[`right-sidebar-content@${WEBCAST_VIEW_STATE}`]: {
				component: VbWebcastChatSidebarComponent
			}
		}

	},
	{
		name: WEBCAST_FULLSCREEN_STATE,
		url: '/webcast/:webcastId',
		abstract: true,
		allowRegisteredGuestAccess: true,
		resolve: [
			{
				token: 'PortalStartupResolve',
				deps: [PortalStartup],
				resolveFn: (PortalStartup: IPortalStartup) => PortalStartup.$promise
			},
			{
				token: 'webcastId',
				deps: [Transition],
				resolveFn: (transition: Transition) => transition.params().webcastId
			},
			{
				token: 'webcast',
				deps: ['webcastId', WebcastService],
				resolveFn: (webcastId: string, WebcastService: WebcastService) => {
					return WebcastService.getWebcast(webcastId, true).catch(() => {
						return Promise.reject({ status: 401, reason: 'event' }); //denied
					});
				}
			},
			{
				token: 'initializeWebcastTheme',
				deps: ['webcast', ThemeService, NgZone],
				resolveFn: (webcast: WebcastModel, ThemeService: ThemeService, ngZone: NgZone) => {
					return initializeWebcastTheme(webcast, ThemeService, ngZone);
				}
			},
			{
				token: 'webcastPushHandlersUnsubscribe',
				deps: [WebcastModelService, 'webcast'],
				resolveFn: (WebcastModelService: WebcastModelService, webcast: WebcastModel) => {
					return webcast && WebcastModelService.registerWebcastPushHandlers(webcast);
				}
			},
			initCurrentRunFullscreenNgxResolve,
			{
				token: 'webcastAttendeeRemovedPushHandlerUnsubscribe',
				deps: [StateService, PushBus, UserContextService, 'webcast'],
				resolveFn: ($state: StateService, PushBus: PushBus, UserContext: UserContextService, webcast: WebcastModel) => {
					return PushBus.subscribe(UserContext.getUser().id, {
						WebcastAttendeeRemoved() {
							$state.go(WEBCAST_LANDING_STATE, { webcastId:webcast.id });
						}
					});
				}
			}

		],
		onExit(transition: Transition): void {
			const injector = transition.injector(undefined, 'exiting');
			injector.get('webcastPushHandlersUnsubscribe')?.();
			injector.get('webcastAttendeeRemovedPushHandlerUnsubscribe')?.();

			injector.get('initializeWebcastTheme')?.unsubscribe();

			const themeService = injector.get<ThemeService>(ThemeService);
			if (themeService.isCustomThemeActive) {
				themeService.endCustomThemeMode();
			}
		}
	},
	{
		name: WEBCAST_FULLSCREEN_PRESENTATION_STATE,
		url: '/presentation',
		allowRegisteredGuestAccess: true,
		component: WebcastPresentationComponent,
		resolve: [
			{
				token: 'presentationAuthCheck',
				deps: ['webcast'],
				resolveFn: (webcast: WebcastModel) => {
					//webcast must have a presentation AND user must be connected to the webcast to view it
					if (!webcast.presentation.hasPresentation) {
						return Promise.reject({ status: 401 });
					}
				}
			},
			{
				token: 'webcastPresentationHandlersUnsubscribe',
				deps: ['webcast'],
				resolveFn: (webcast: WebcastModel) => webcast && webcast.presentation.registerPushHandlers()
			},
			{
				token: 'isFullscreen',
				resolveFn: () => true
			}
		],
		onExit(transition: Transition): void {
			const injector = transition.injector(undefined, 'exiting');
			injector.get('webcastPresentationHandlersUnsubscribe')?.();
		}
	}
];

