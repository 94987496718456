<vb-title [title]="'UserProfile' | translate"></vb-title>

<form #form="ngForm" novalidate [ngClass]="styles.form" [hidden]="!user || saving" (submit)="submit()">

	<vb-ui-accent-header [headerText]="'UserProfile_MyProfile' | translate" headerWidth="35%" headerMobileWidth="50%" [hasActions]="true">
		<button type="button" (click)="close()" vbUiBtnSecondary>
			{{ 'Cancel' | translate }}
		</button>
		<button type="submit" class="margin-left-5" [disabled]="form.invalid" vbUiBtnPrimary>
			{{ 'Save' | translate }}
		</button>
	</vb-ui-accent-header>

	<section [ngClass]="styles.columnContainer">

		<section class="theme-primary-border" flex="50" [ngClass]="styles.columnLeft">

			<vb-ui-form-group>
				<label for="firstName" #uiLabel>
					{{ 'FirstName' | translate }}
				</label>
				<input type="text" name="firstName" id="firstName" [(ngModel)]="user.firstName" [disabled]="isLdapUser" uiControl vbUiTextInput trim="blur"/>
			</vb-ui-form-group>

			<vb-ui-form-group>
				<label for="lastName" #uiLabel>
					{{ 'LastName' | translate }}
				</label>
				<input #lastName="ngModel" type="text" name="lastName" id="lastName" vbRequired [(ngModel)]="user.lastName" [disabled]="isLdapUser" uiControl vbUiTextInput trim="blur"/>
				<div uiValidation>
					<div *ngIf="lastName.invalid" role="alert" class="margin-bottom-10" vbUiErrorMessage>
						<ng-container *ngIf="lastName.errors.required">
							{{ 'ThisFieldIsRequired' | translate }}
						</ng-container>
					</div>
				</div>
			</vb-ui-form-group>

			<vb-ui-form-group>
				<label for="email" #uiLabel>
					{{ 'ContactEmail' | translate }}
				</label>
				<input #email="ngModel" type="text" name="email" id="email" [(ngModel)]="user.email" [disabled]="isLdapUser" [vbClearOnChange]="'emailInUse'" vbEmail uiControl vbUiTextInput trim="blur"/>
				<div uiValidation>
					<div *ngIf="email.invalid" role="alert" class="margin-bottom-10" vbUiErrorMessage>
						<div *ngIf="email.errors.emailInUse">
							{{ 'EmailInUse' | translate }}
						</div>
						<div *ngIf="email.errors.vbEmail">
							{{ 'InvalidEmailError' | translate }}
						</div>
					</div>
				</div>
			</vb-ui-form-group>

			<vb-ui-form-group>
				<label for="profileTitle" #uiLabel>
					{{ 'ContactInfo_Title' | translate }}
				</label>
				<input type="text" name="title" id="profileTitle" [(ngModel)]="user.title" [disabled]="isLdapUser" uiControl vbUiTextInput trim="blur"/>
			</vb-ui-form-group>

			<vb-ui-form-group>
				<label for="phone" #uiLabel>
					{{ 'PhoneNumber' | translate }}
				</label>
				<input type="text" name="phone" id="phone" [(ngModel)]="user.phone" [disabled]="isLdapUser" uiControl vbUiTextInput trim="blur"/>
			</vb-ui-form-group>

			<vb-ui-form-group control-name="'language'">
				<label for="language" #uiLabel>
					{{ 'PreferredLanguage' | translate }}
				</label>
				<vb-ui-select uiControl>
					<select #selectElement [(ngModel)]="user.language" id="language" name="language">
						<option value="">{{ 'None' | translate }}</option>
						<option *ngFor="let language of languageCodes" [value]="language.code">
							{{ language.name }}
						</option>
					</select>
				</vb-ui-select>
			</vb-ui-form-group>

		</section>

		<!-- Right Hand Section -->
		<section flex="50" [ngClass]="styles.columnRight">

			<image-selector #imageModel="ngModel" name="profileImage" [ngClass]="styles.profilePicture" [imageComponentMetadata]="imageCfg" [(ngModel)]="imageData" [label]="'UserProfile_ProfilePicture' | translate">
			</image-selector>

			<vb-ui-form-group control-name="'disableFacialRecognition'" *ngIf="showFacialRecognitionOptOut()">
				<vb-ui-checkbox name="disableFacialRecognition" [(ngModel)]="user.disableFacialRecognition" uiControl>
					{{ 'UserProfile_DisableFacialRecognition' | translate }}
				</vb-ui-checkbox>
			</vb-ui-form-group>
		</section>
	</section>

	<div class="margin-bottom-10 text-align-right">
		<button type="button" (click)="close()" vbUiBtnSecondary>
			{{ 'Cancel' | translate }}
		</button>

		<button type="submit" class="margin-left-5" [disabled]="form.invalid" vbUiBtnPrimary>
			{{ 'Save' | translate }}
		</button>
	</div>
</form>

<vb-loading-spinner [ngClass]="styles.loadingSpinner" flex="fill" flex-align="center" layout="row" [size]="'large'" [hidden]="!(!user || saving)">
</vb-loading-spinner>
