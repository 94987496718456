import { of, empty } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

export function handleTableFetchError(error: HttpErrorResponse, tableType: string) {
	console.error(error);
	if (error.status === 404) {
		throw error;
	}
	//ensuring ag-grid does not fail..
	return of({
		[tableType]: [],
		scrollId: undefined,
		total: 0
	});
}

export function handleKpiFetchError(error: HttpErrorResponse) {
	console.error(error);
	return empty();
}
