import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { TranslateService } from '@ngx-translate/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, delay, filter, first, map, pluck, repeatWhen, shareReplay, skip, switchMap, take, tap } from 'rxjs/operators';

import { WebcastModel } from 'rev-portal/scheduledEvents/webcast/model/WebcastModel';

import { handleKpiFetchError } from './ErrorHandler';

interface IExperiencedErrorsByNumAttendees {
	errorCountRange: string;
	numAttendees: number;
}

interface IRealTimePerformanceApiErrorsBuckets {
	one: number;
	two: number;
	three: number;
	fourPlus: number;
}

interface IRealTimePerformanceApiAvgRebufferDurationBuckets {
	lessThan10s: number;
	between10And20s: number;
	between20And30s: number;
	moreThan30s: number;
}

interface IKeyValBucket {
	key: string;
	value: number;
}

interface IRealTimePerformanceApiResponse {
	attendees: {
		buckets: IKeyValBucket[];
		value: number;
	};
	avgRebufferDuration: {
		buckets: IRealTimePerformanceApiAvgRebufferDurationBuckets;
		value: string;
	};
	experiencedErrors: {
		value: string;
		buckets: IRealTimePerformanceApiErrorsBuckets;
	};
	experiencedRebuffering: {
		buckets: IKeyValBucket[];
		value: number;
	};
	multicastErrors: {
		value: string;
		buckets: IRealTimePerformanceApiErrorsBuckets;
	};
}

interface IRebufferDurationSecByNumRebuffers {
	durationRange: string;
	numRebuffers: number;
}

/**
 * NOTE: This is just a starting point to illustrate the general approach.
 */
@Injectable()
export class RealTimeAnalyticsKpiService {
	public avgExperiencedErrors$: Observable<number>;
	public avgMulticastErrors$: Observable<number>;
	public avgRebufferDurationSec$: Observable<number>;
	public initAttendees$: Observable<IKeyValBucket[]>;
	public initExperiencedRebuffering$: Observable<IKeyValBucket[]>;
	public incrementalAttendees$: Observable<IKeyValBucket[]>;
	public incrementalExperiencedRebuffering$: Observable<IKeyValBucket[]>;
	public experiencedErrorsByNumAttendees$: Observable<IExperiencedErrorsByNumAttendees[]>;
	public experiencedRebufferingAvgPercentage$: Observable<number>;
	public multicastErrorsByNumAttendees$: Observable<IExperiencedErrorsByNumAttendees[]>;
	public numAttendees$: Observable<number>;
	public rebufferDurationSecByNumRebuffers$: Observable<IRebufferDurationSecByNumRebuffers[]>;

	private kpiData$: Observable<any>;
	private pollingIntervalMs: number;
	private webcast$ = new BehaviorSubject<WebcastModel>(null);
	private lastPollingWhen: Date;

	constructor(
		private http: HttpClient,
		private translate: TranslateService
	) {
		this.kpiData$ = this.getKpiData$();

		this.avgExperiencedErrors$ = this.pluckKpiData('experiencedErrors', 'value');
		this.avgMulticastErrors$ = this.pluckKpiData('multicastErrors', 'value');
		this.avgRebufferDurationSec$ = this.pluckKpiData('avgRebufferDuration', 'value');
		this.experiencedErrorsByNumAttendees$ = this.getExperiencedErrorsByNumAttendees();
		this.experiencedRebufferingAvgPercentage$ = this.pluckKpiData('experiencedRebuffering', 'value');
		this.multicastErrorsByNumAttendees$ = this.getMulticastErrorsByNumAttendees();
		this.numAttendees$ = this.pluckKpiData('attendees', 'value');
		this.initAttendees$ = this.pluckKpiData('attendees', 'buckets').pipe(take(1));
		this.incrementalAttendees$ = this.pluckKpiData('attendees', 'buckets').pipe(skip(1));
		this.rebufferDurationSecByNumRebuffers$ = this.getRebufferDuartionSecByNumRebuffers();
		this.initExperiencedRebuffering$ = this.pluckKpiData('experiencedRebuffering', 'buckets').pipe(take(1));
		this.incrementalExperiencedRebuffering$ = this.pluckKpiData('experiencedRebuffering', 'buckets').pipe(skip(1));
	}

	public initialize(webcast: WebcastModel, pollingIntervalMs: number): void {
		this.pollingIntervalMs = pollingIntervalMs;
		this.webcast$.next(webcast);
	}

	public resetPollingWhen(): void {
		this.lastPollingWhen = undefined;
	}

	private fetchKpiData(webcast: WebcastModel): Observable<IRealTimePerformanceApiResponse> {
		return this.http.get<IRealTimePerformanceApiResponse>(`/analytics/events/${webcast.id}/${webcast.currentRun.runNumber}/real-time/performance`,
			{
				params: this.lastPollingWhen ? { fromWhen: this.lastPollingWhen.toISOString() } : null
			}
		).pipe(
			tap(() => this.lastPollingWhen = new Date())
		);
	}

	private getExperiencedErrorsByNumAttendees(): Observable<IExperiencedErrorsByNumAttendees[]> {
		return this.pluckKpiData('experiencedErrors', 'buckets').pipe(
			map<IRealTimePerformanceApiErrorsBuckets, IExperiencedErrorsByNumAttendees[]>(data => [
				{
					errorCountRange: '1',
					numAttendees: data.one
				},
				{
					errorCountRange: '2',
					numAttendees: data.two
				},
				{
					errorCountRange: '3',
					numAttendees: data.three
				},
				{
					errorCountRange: '4+',
					numAttendees: data.fourPlus
				}
			])
		);
	}

	private getMulticastErrorsByNumAttendees(): Observable<IExperiencedErrorsByNumAttendees[]> {
		return this.pluckKpiData('multicastErrors', 'buckets').pipe(
			map<IRealTimePerformanceApiErrorsBuckets, IExperiencedErrorsByNumAttendees[]>(data => [
				{
					errorCountRange: '1',
					numAttendees: data.one
				},
				{
					errorCountRange: '2',
					numAttendees: data.two
				},
				{
					errorCountRange: '3',
					numAttendees: data.three
				},
				{
					errorCountRange: '4+',
					numAttendees: data.fourPlus
				}
			])
		);
	}

	private getKpiData$() {
		return this.webcast$.pipe(
			first(webcast => !!webcast),
			switchMap(webcast => {
				return this.fetchKpiData(webcast)
					.pipe(
						catchError((error: HttpErrorResponse) => handleKpiFetchError(error))
					);
			}),
			repeatWhen(notifications => notifications.pipe(delay(this.pollingIntervalMs))), // repeat after a delay following the last notification
			shareReplay({ bufferSize: 1, refCount: true })
		);
	}

	private getRebufferDuartionSecByNumRebuffers(): Observable<IRebufferDurationSecByNumRebuffers[]> {
		return this.pluckKpiData('avgRebufferDuration', 'buckets').pipe(
			map<IRealTimePerformanceApiAvgRebufferDurationBuckets, IRebufferDurationSecByNumRebuffers[]>(data => [
				{
					durationRange: '< 10',
					numRebuffers: data.lessThan10s
				},
				{
					durationRange: '10-20',
					numRebuffers: data.between10And20s
				},
				{
					durationRange: '20-30',
					numRebuffers: data.between20And30s
				},
				{
					durationRange: '> 30',
					numRebuffers: data.moreThan30s
				}
			]
				.map(current => ({
					...current,
					durationRange: this.translate.instant('Format_Seconds_Abbreviation_Short', [current.durationRange])
				}))
			)
		);
	}

	private pluckKpiData(...fields: string[]): Observable<any> {
		return this.kpiData$.pipe(
			filter(data => !!data[fields[0]]),
			pluck(...fields)
		);
	}
}
