import { Component, Input } from '@angular/core';

import { WebcastModel } from 'rev-portal/scheduledEvents/webcast/model/WebcastModel';

import styles from './WebcastStartAndEndMessage.Component.module.less';

@Component({
	selector: 'webcast-start-and-end-message',
	templateUrl: './WebcastStartAndEndMessage.Component.html'
})
export class WebcastStartAndEndMessageComponent {
	@Input() public webcast: WebcastModel;
	public readonly styles = styles;
}
