import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { EditWebcastAngularModule } from './editWebcast/EditWebcastAngular.Module';
import { EventRegistrationModule } from './eventRegistration/EventRegistration.Module';
import { ScheduledEventsCommonModule } from './common/ScheduledEventsCommon.AngularModule';
import { WebcastAngularModule } from './webcast/WebcastAngular.Module';

import 'rev-less/events/webcast-main.less';

@NgModule({
	imports: [
		EditWebcastAngularModule,
		EventRegistrationModule,
		HttpClientModule,
		ScheduledEventsCommonModule,
		WebcastAngularModule
	]
})
export class ScheduledEventsAngularModule {}
