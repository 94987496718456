import AngularUiRouter from '@uirouter/angularjs';
import { ICompileProvider, IWindowService } from 'angular';
import { NgModule } from '@vbrick/angular-ts-decorators';
import { StateProvider, UrlRouterProvider, UIRouter } from '@uirouter/angularjs';
import { UIRouterRx } from '@uirouter/rx';

import { userAgentUtil } from 'vbrick-player-src/UserAgentUtil';

import BootstrapConfigModule from 'rev-shared/ui/uiBootstrap/UiBootstrap.Module';
import { BootstrapContext } from 'rev-shared/bootstrap/BootstrapContext';
import DialogModule from 'rev-shared/ui/dialog/Dialog.Module';
import { MultiselectModule } from 'rev-shared/ui/multiselect/Multiselect.Module';
import PipesModule from 'rev-shared/pipes/Pipes.Module';
import SharedConfigModule from 'rev-shared/config/Config.Module';
import { ToolbarModule } from 'rev-shared/ui/toolbar/Toolbar.Module';
import { TranslationsModule } from 'rev-shared/ui/translations/Translations.Module';
import VBrickDateModule from 'rev-shared/date/Date.Module';
import VBrickInsightModule from 'rev-shared/ui/insight/Insight.Module';
import VBrickPushModule from 'rev-shared/push/Push.downgrade.Module';
import VBrickSecurityModule from 'rev-shared/security/Security.Module';
import VBrickSharedMediaDowngradeModule from 'rev-shared/media/Media.Module.downgrade';
import VBrickUIFileUploadModule from 'rev-shared/ui/fileUpload/FileUpload.Module';
import VBrickUIHtmlEditorModule from 'rev-shared/htmlEditor/HtmlEditor.Module';
import VBrickUiSpinnerModule from 'rev-shared/ui/spinner/Spinner.Module';
import VBrickUtilModule from 'rev-shared/util/Util.Module';
import VBrickVideoPlayerModule from 'rev-shared/videoPlayer/VideoPlayer.Module';
import { WindowSizeModule } from 'rev-shared/ui/size/WindowSize.Module';
import { AngularJsLazyLoadHelperService } from 'rev-shared/util/AngularJsLazyLoadHelper.Service';
import { LoginRedirectService } from 'rev-shared/security/LoginRedirect.Service';
import { PushBus } from 'rev-shared/push/PushBus.Service';
import { UserContextService } from 'rev-shared/security/UserContext.Service';


import NavigationModule from './navigation/Navigation.Module';
import SystemMessageModule from './systemMessage/SystemMessage.Module';
import VBrickBrandingModule from './branding/Branding.Module';
import VBrickMediaModule from './media/Media.Module';
import VBrickSearchModule from './search/Search.Module';
import { AlertDialogComponent } from './AlertDialog.Component';
import { DownloadDetailsModule } from './downloads/DownloadDetails.Module';
import { LocationOriginPolyfill } from './LocationOriginPolyfill.Run';
import { PortalAppComponent } from './PortalApp.Component';
import { PortalComponent } from './Portal.Component';
import { PortalMessageHandlers } from './PortalMessageHandlers.Run';
import { VbPortalStylesDirective } from './VbPortalStyles.Directive';
import { configureStates } from './PortalState.Config';
import { dialogCfg } from './PortalDialog.Config';
import { getPortalStartup, PortalStartup } from './PortalStartup.Provider';

@NgModule({
	id: 'VBrick.Portal',
	declarations: [
		AlertDialogComponent,
		PortalComponent,
		VbPortalStylesDirective,
		PortalAppComponent
	],
	imports: [
		'ngAria',
		'ngResource',
		'ngAnimate',
		'ngSanitize',
		'ngMessages',
		AngularUiRouter,

		//shared
		BootstrapConfigModule,
		DialogModule.forRoot(dialogCfg),
		DownloadDetailsModule,
		MultiselectModule,
		NavigationModule,
		PipesModule,
		SharedConfigModule,
		SystemMessageModule,
		ToolbarModule,
		TranslationsModule,
		VBrickBrandingModule,
		VBrickDateModule,
		VBrickInsightModule,
		VBrickMediaModule,
		VBrickPushModule,
		VBrickSearchModule,
		VBrickSecurityModule,
		VBrickSharedMediaDowngradeModule,
		VBrickUIFileUploadModule,
		VBrickUIHtmlEditorModule,
		VBrickUiSpinnerModule,
		VBrickUtilModule,
		VBrickVideoPlayerModule,
		WindowSizeModule
	],
	providers: [
		{ provide: PortalStartup, useFactory: getPortalStartup }
	]
})
export default class VBrickPortalModule {
	public static config(
		$compileProvider: ICompileProvider,
		$stateProvider: StateProvider,
		$uiRouterProvider: UIRouter,
		$urlRouterProvider: UrlRouterProvider
	): void {
		'ngInject';
		configureStates($stateProvider, $urlRouterProvider);

		VBrickPortalModule.enablePreAssignBindings($compileProvider);
		VBrickPortalModule.configureRxjs($uiRouterProvider);
	}

	public static run(
		$window: IWindowService,
		AngularJsLazyLoadHelper: AngularJsLazyLoadHelperService,
		LoginRedirectService: LoginRedirectService,
		PushBus: PushBus,
		UserContext: UserContextService
	): void {
		'ngInject';

		LocationOriginPolyfill($window);
		PortalMessageHandlers(LoginRedirectService, PushBus, UserContext);
	}

	private static enablePreAssignBindings($compileProvider: ICompileProvider): void {
		//https://toddmotto.com/angular-1-6-is-here
		//allow for codebase upgrade without needing to use $onInit
		$compileProvider.preAssignBindingsEnabled(true);
	}

	private static configureRxjs($uiRouterProvider: UIRouter): void {
		$uiRouterProvider.plugin(UIRouterRx);

		if (!userAgentUtil.isIe && BootstrapContext.debug) { // slows down IE too much
			import(/* webpackChunkName: "rxjs-spy" */'rxjs-spy')
				.then(rxjsSpy => {
					const spy = rxjsSpy.create();
					spy.log();
				});
		}
	}
}
