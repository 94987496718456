<div class="modal-header util-uppercase">
	<h4>
		<span [innerHTML]="'Event_RealTimeAnalytics_ZoneListTitle' | translate : { '0': '<b>' + escape(deviceName) + '</b>' }">
		</span>
		<button class="close pull-right btn btn-transparent" type="button" (click)="bsModalRef.hide()">
			<span class="glyphicons remove_2"></span>
		</button>
	</h4>
</div>
<div class="modal-body modal-body-message">

	<input vbUiTextInput [(ngModel)]="filterTxt" [placeholder]="'Zone' | translate" [attr.aria-label]="'Zone' | translate" (ngModelChange)="onFilterChange()">

	<div [ngClass]="styles.zoneList">
		<div *ngFor="let zone of filteredZones" [ngClass]="styles.zone" [innerHTML]="zone | highlight : filterTxt : styles.highlight">
		</div>
	</div>

</div>
