<div class="height-full" [ngSwitch]="selectedButtonId">
	<div class="height-full padding-16" *ngSwitchCase="WebcastViewSidebarButton.ATTENDEES">
		<webcast-attendees-list [accountId]="accountId" [webcast]="webcast" [themed]="true">
		</webcast-attendees-list>
	</div>
	<div *ngSwitchCase="WebcastViewSidebarButton.INFO">
		<edit-webcast-upgrade class="box-block padding-16" *ngIf="inlineEdit; else info;" [webcastId]="webcast.id" [isSidebar]="true" [mediaFeatures]="mediaFeatures" (onSaved)="inlineEditCompleted.emit($event)" (onCancelled)="inlineEditCompleted.emit()">
		</edit-webcast-upgrade>
		<ng-template #info>
			<vb-webcast-info-sidebar [webcastViewContext]="webcastViewContext" [webcast]="(webcast.webcast$ | async)">
			</vb-webcast-info-sidebar>
		</ng-template>
	</div>
	<vb-webcast-chat-sidebar class="padding-bottom-16" *ngSwitchCase="WebcastViewSidebarButton.CHAT" [webcast]="(webcast.webcast$ | async)">
	</vb-webcast-chat-sidebar>
	<vb-webcast-questions-sidebar *ngSwitchCase="WebcastViewSidebarButton.QA" [webcast]="(webcast.webcast$ | async)" [sidebarNotifications]="sidebarNotifications">
	</vb-webcast-questions-sidebar>
	<vb-webcast-polls-view *ngSwitchCase="WebcastViewSidebarButton.POLLS" [polls]="(webcast.webcast$ | async).polls" [$polls]="(webcast.webcast$ | async).polls.$polls" [brandingSettings]="ThemeService.brandingSettings$ | async">
	</vb-webcast-polls-view>
</div>
