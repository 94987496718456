import {
	Component,
	Input,
	ViewChild
} from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DialogService } from 'rev-shared/ui/dialog/Dialog.Service';
import { ThemeService } from 'rev-portal/branding/Theme.Service';

import { VbConfirmationDialogComponent } from 'rev-shared/ui/dialog/VbConfirmationDialogAngular.Component';
import { IConfirmationDialogActionData } from 'rev-shared/ui/dialog/IConfirmationDialogActionData';

import { WebcastViewQuestionsService } from './WebcastViewQuestions.Service';

import { Question } from './Question';
import './VbWebcastQuestionCard.Component.less';

export enum QuestionContext {
	myQuestions = 'myQuestions',
	publicQuestions = 'publicQuestions'
}

@Component({
	selector: 'vb-webcast-question-card',
	templateUrl: './VbWebcastQuestionCard.Component.html',
})
export class VbWebcastQuestionCardComponent {
	@Input() public webcastId: string;
	@Input() public question: Question;
	@Input() public canModerate: boolean;
	@Input() public questionContext: string;

	@ViewChild(VbConfirmationDialogComponent)
	public replyInProgressActionConfirmation: VbConfirmationDialogComponent;

	public cssRules$: Observable<any>;
	public readonly QuestionContextType = QuestionContext;

	constructor(
		private readonly DialogService: DialogService,
		private WebcastViewQuestionsService: WebcastViewQuestionsService,
		public ThemeService: ThemeService
	) {
		this.assignCss$();
	}

	public addToQueue(): void {
		this.confirmReplyConflict()
			.then(() => {
				this.WebcastViewQuestionsService.addQuestionToQueue(this.webcastId, this.question);
			});
	}

	public close(): void {
		this.confirmReplyConflict()
			.then(() => {
				this.WebcastViewQuestionsService.closeQuestion(this.webcastId, this.question);
			});
	}

	public followUp(): void {
		this.confirmReplyConflict()
			.then(() => {
				this.WebcastViewQuestionsService.followUpQuestion(this.webcastId, this.question);
			});
	}

	public answered(): void {
		this.confirmReplyConflict()
			.then(() => {
				this.WebcastViewQuestionsService.answeredQuestion(this.webcastId, this.question);
			});
	}

	public decline(): void {
		this.confirmReplyConflict()
			.then(() => {
				this.WebcastViewQuestionsService.declineQuestion(this.webcastId, this.question);
			});
	}

	public directReply(): void {
		this.confirmReplyConflict()
			.then(() => {
				//signal to other moderaters that a reply is being written
				this.WebcastViewQuestionsService.startDirectReplyQuestion(this.webcastId, this.question)
					.then(() => {
						this.DialogService
							.getDialog('vbWebcastQuestionDirectReply')
							.open({
								initialState: {
									question: this.question,
									webcastId: this.webcastId
								}
							});
					});
			});
	}

	private confirmReplyConflict(): Promise<void | IConfirmationDialogActionData> {
		if (this.question.isDirectReplyInProgress) {
			return this.replyInProgressActionConfirmation.open().result;
		}
		return Promise.resolve();
	}

	private assignCss$(): void {
		this.cssRules$ = this.ThemeService.brandingSettings$.pipe(map(settings => {
			const themeSettings = settings?.themeSettings || {} as any;
			return {
				'vb-webcast-question-card .question-reply:before, vb-webcast-question-card .question-reply-by:before, vb-webcast-question-card .question-answered-by-speaker:before': {
					'color': themeSettings.accentColor + ' !important'
				}
			};
		}));
	}
}
