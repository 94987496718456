import {
	Component,
	Input,
	OnInit,
	Output
} from '@vbrick/angular-ts-decorators';
import { IIntervalService, IPromise } from 'angular';

import { ComponentCallback } from 'rev-shared/ts-utils/ComponentCallback';
import { SpinnerState } from 'rev-shared/ui/spinner/VbLoadingSpinner.Component';
import { UserContextService } from 'rev-shared/security/UserContext.Service';

import styles from './RecordingTimer.Component.module.less';

@Component({
	selector: 'recording-timer',
	templateUrl: '/partials/media/recording/recording-timer.html'
})
export class RecordingTimerComponent implements OnInit {
	@Input() private autoStart: boolean;
	@Input() private video: any;

	@Output() private onStop: ComponentCallback;

	private readonly SpinnerState = SpinnerState;
	private readonly styles = styles;

	private currentInterval: IPromise<any>;
	private recordingTime: number;

	constructor(
		private $interval: IIntervalService,
		private UserContext: UserContextService
	) {
		'ngInject';
	}

	public ngOnInit(): void {
		if (this.autoStart) {
			this.startTimer();
		}
	}

	private onInterval(): void {
		this.recordingTime++;
	}

	private get isVideoUploader(): boolean {
		return this.UserContext.getUser().id === this.video.uploaderUserId;
	}

	private startTimer(): void {
		const recordingStartedTime = this.video.videoConference.whenRecordingStarted;

		if (recordingStartedTime) {
			const dateDiff = new Date().getTime() - new Date(recordingStartedTime).getTime();
			this.recordingTime = Math.abs(dateDiff / 1000);
		} else {
			this.recordingTime = 0;
		}

		if (this.currentInterval) {
			this.$interval.cancel(this.currentInterval);
		}

		this.currentInterval = this.$interval(() => this.onInterval(), 1000);
	}

	private stopTimer(): void {
		this.$interval.cancel(this.currentInterval);
		this.onStop();
	}
}
