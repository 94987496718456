import {
	Component,
	Input
} from '@vbrick/angular-ts-decorators';

@Component({
	selector: 'teams-list',
	templateUrl: '/partials/media/teams/teams-list.html'
})
export class TeamsListComponent {
	@Input() public isCreateTeamAccess: boolean;
	@Input() public teams: any[];
}
