import {
	Component,
	OnInit
} from '@vbrick/angular-ts-decorators';

import { IAugmentedJQuery } from 'angular';

import './vb-caret.less';

@Component({
	selector: 'vb-caret'
})
export class VbCaretComponent implements OnInit {
	constructor(
		private $element: IAugmentedJQuery
	) {
		'ngInject';
	}

	public ngOnInit(): void {
		this.$element.addClass('glyphicons play');
	}
}
