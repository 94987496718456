import { Directive, ElementRef, Injector, Input, EventEmitter, Output } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

import { IMediaFeatures } from 'rev-shared/media/IMediaFeatures';

@Directive({
	selector: 'edit-webcast-upgrade',
})
export class EditWebcastNgxComponent extends UpgradeComponent {
	@Input() public webcastId: string;
	@Input() public defaultStartDate: Date;
	@Input() public cloneWebcastId: string;
	@Input() public isSidebar: boolean;
	@Input() public mediaFeatures: IMediaFeatures;

	@Output() public onSaved: EventEmitter<any> = new EventEmitter();
	@Output() public onCancelled: EventEmitter<void> = new EventEmitter();

	constructor(elementRef: ElementRef, injector: Injector) {
		super('editWebcastUpgrade', elementRef, injector);
	}
}
