import { NgModule } from '@vbrick/angular-ts-decorators';

import SecurityModule from 'rev-shared/security/Security.Module';
import SharedDateModule from 'rev-shared/date/Date.Module';

import { EditWebcastZoomInputComponent } from './EditWebcastZoomInput.Component';
import { ZoomService } from './Zoom.Service';
import { downgradeInjectables } from 'rev-shared/util/AngularHybridUtils';
import { VbZoomMeetingIdDirective } from './VbZoomMeetingId.Directive';

@NgModule({
	id: 'Vbrick.Portal.ScheduledEvents.Zoom',
	declarations: [
		EditWebcastZoomInputComponent,
		VbZoomMeetingIdDirective
	],
	imports: [
		SecurityModule,
		SharedDateModule
	]
})
export class ZoomModule {}

downgradeInjectables(ZoomModule, [
	{
		name: 'ZoomService',
		injectable: ZoomService
	}
]);
