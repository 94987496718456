import {
	Component,
	Input,
	OnInit
} from '@vbrick/angular-ts-decorators';

import {
	IAugmentedJQuery,
	ITranscludeFunction
} from 'angular';

import { VbMediaTableComponent } from './VbMediaTable.Component';
import { VideoSelectionModelService } from 'rev-portal/media/bulkEdit/VideoSelectionModel.Service';

@Component({
	selector: 'vb-media-table-select-column-header',
	require: {
		parent: '^^vbMediaTable'
	},
	template: `
		<button class="btn btn-checkbox" ng-click="$ctrl.videoSelectionModel.toggleSelectAll()" ng-class="{active: $ctrl.videoSelectionModel.isSelectAll}">
			<span class="glyphicons ok_2"></span>
		</button>`
})
export class VbMediaTableSelectColumnHeaderComponent implements OnInit {
	@Input() public field: string;
	@Input() public isMobileHidden: boolean;
	@Input() public mobileAlign: string;
	@Input() public videoSelectionModel: VideoSelectionModelService;

	private parent: VbMediaTableComponent;

	constructor(
		private $element: IAugmentedJQuery,
		private $transclude: ITranscludeFunction
	) {
		'ngInject';
	}

	public ngOnInit(): void {
		this.$element
			.addClass('file-list-header-col')
			.toggleClass('ng-hide', !(this.parent.columns[this.field] >= 0)) //may be undefined
			.addClass('table-cell')
			.toggleClass('mobile-hidden', !!this.isMobileHidden)
			.css({
				order: this.parent.columns[this.field]
			});

		if (this.mobileAlign) {
			this.$element.addClass(`mobile-align-${this.mobileAlign}`);
		}

		if (this.$transclude) {
			this.$transclude(clone => this.$element.append(clone));
		}
	}
}
