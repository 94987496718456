import { Injectable, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Subscription } from 'rxjs';

import { HlsUtilService } from 'vbrick-player-src/HlsUtil.Service';

import { DateParsersService } from 'rev-shared/date/DateParsers.Service';
import { HlsFileUtilService } from 'rev-shared/videoPlayer/hlsFileUtil/HlsFileUtil.Service';
import { IUnsubscribe } from 'rev-shared/push/IUnsubscribe';
import { MinuteMs, SecondMs } from 'rev-shared/date/Time.Constant';
import { PushBus } from 'rev-shared/push/PushBus.Service';
import { REFLECTION_STREAM_NAME_SUFFIX } from 'rev-shared/util/StreamNameUtil';
import { UserLocalIPService } from 'rev-shared/security/UserLocalIP.Service';
import { isNumber, noop } from 'rev-shared/util';
import { VideoService } from 'rev-shared/media/Video.Service';

import { hasCdnPushedPlayback, isAkamaiPushedPlaybackReady, isVbrickCdnPushedPlaybackReady } from '../CdnUtil';
import { updateWaitingStatusForWebexMeeting } from '../WebcastStatusUpdateHelper';
import { VC_STATUS } from './VcStatus';
import { WebcastConnectionService } from './WebcastConnection.Service';
import { WebcastModel } from './WebcastModel';
import { WebcastRunStatus, WebcastRunType, RunItem } from './RunItem';
import { WebcastStatus, WebcastVideoSource } from '../WebcastStatus';

const waitForHlsLiveStreamTimeoutMs = MinuteMs + 30000; //giving little extra time as MPH will be retrying for 1 min.
const waitForHlsLiveStreamCheckIntervalMs = 2 * SecondMs;
const VIDEO_FORMAT_HLS: string = 'HLS';

@Injectable({
	providedIn: 'root'
})
export class WebcastModelService {
	constructor(
		private DateParsers: DateParsersService,
		private HlsFileUtil: HlsFileUtilService,
		private HlsUtilService: HlsUtilService,
		private PushBus: PushBus,
		private UserLocalIPService: UserLocalIPService,
		private VideoService: VideoService,
		private WebcastConnectionService: WebcastConnectionService,
		private http: HttpClient,
		private zone: NgZone
	) {}

	private updateWebcastPlaybacks(webcast: WebcastModel): Promise<void> {
		webcast.isUpdatingPlaybacks = true;

		return this.getWebcastVideoUrl(webcast.id)
			.then(playbacks => {
				const firstPlayback = playbacks?.[0];
				const isFirstPlaybackCustomDevice = firstPlayback?.name?.endsWith(REFLECTION_STREAM_NAME_SUFFIX);
				const isFirstPlaybackHls: boolean = firstPlayback?.videoFormat === VIDEO_FORMAT_HLS;
				const isFirstPlaybackMulticastHls: boolean = firstPlayback && this.HlsUtilService.isMulticast(firstPlayback.url, null);
				const isFirstPlaybackVciHls = isFirstPlaybackHls && (!!webcast.vcSipAddress || !!webcast.vcMicrosoftTeamsMeetingUrl);

				const isFirstPlaybackHlsWithSubtitles = isFirstPlaybackHls
					&& webcast.liveSubtitles?.isLiveSubtiltesEnabled;

				const skipChildPlaylistCheck = isFirstPlaybackVciHls || isFirstPlaybackHlsWithSubtitles;

				const isTargetedSourceForTesting: boolean = !isFirstPlaybackMulticastHls && (
					isFirstPlaybackCustomDevice ||
					isFirstPlaybackVciHls ||
					isFirstPlaybackHlsWithSubtitles ||
					hasCdnPushedPlayback(playbacks)
				);
				const isStartupForEventAdmin: boolean = webcast.currentUser.isEventAdmin && !webcast.webcastStatus.isScheduled;
				const isUserEligibleForPlayback: boolean = isStartupForEventAdmin ||
				webcast.webcastStatus.isBroadcasting;

				if (isUserEligibleForPlayback && isTargetedSourceForTesting) {
					const timeoutPromise = new Promise<void>(resolve =>
						window.setTimeout(() => {
							webcast.waitingForVideoSource = true;

							if (isFirstPlaybackVciHls) {
								webcast.vcStatus.update(VC_STATUS.WaitingForStream, true, true);
							}

							resolve();
						})
					);

					return timeoutPromise
						.then(() => this.HlsFileUtil.waitForLivePlaybacks(playbacks, waitForHlsLiveStreamTimeoutMs, waitForHlsLiveStreamCheckIntervalMs, skipChildPlaylistCheck));
				}

				return playbacks;
			})
			.then(playbacks => {
				webcast.playbacks = playbacks;
				webcast.waitingForVideoSource = false;
				webcast.startingVideoSource = false;
				webcast.isUpdatingPlaybacks = false;
				webcast.vcStatus.update(VC_STATUS.Recording, true, true);
			});
	}

	private getWebcastVideoUrl(webcastId: string): Promise<any[]> {
		return this.UserLocalIPService.checkUserLocation()
			.then(() => this.http.get<any>(`/scheduled-events/${webcastId}/video`).toPromise())
			.then(result => result.playbackResults);
	}

	public updateLinkedVideo(webcast: WebcastModel): Promise<void> {
		if(!webcast.linkedVideoId){
			webcast.linkedVideo = null;
		}
		else if(!webcast.linkedVideo || webcast.linkedVideo.id !== webcast.linkedVideoId) {
			webcast.linkedVideo = null;
			return this.VideoService.getVideoBasicInfo(webcast.linkedVideoId)
				.then(v => {
					webcast.linkedVideo = v;
				});
		}

		return Promise.resolve();
	}

	private waitForVideoSource(webcast: WebcastModel): Promise<void> {
		return this.HlsFileUtil.waitForLivePlaybacks(webcast.playbacks, waitForHlsLiveStreamTimeoutMs, waitForHlsLiveStreamCheckIntervalMs)
			.then(playbacks => {
				window.setTimeout(() => {
					webcast.playbacks = playbacks;
					webcast.waitingForVideoSource = false;
					webcast.startingVideoSource = false;
				});
			});
	}

	public registerWebcastPushHandlers(webcast: WebcastModel): IUnsubscribe {
		const accountDevicesSubscription = this.PushBus.subscribe(webcast.accountId, 'ScheduledEventsDevices', {
			DeviceUpdated: ({ deviceId, isActive }) => { //TODO: move this message to deviceId route
				if(deviceId === webcast.presentationProfileDeviceId){
					webcast.presentationProfileDeviceActive = isActive;
				}
			}
		});

		const webcastSubscription = this.PushBus.subscribe(webcast.id, {
			WebcastDetailsSaved: ({ preProduction, nextPreProductionRunNumber, ...msgData }) => {

				if(preProduction &&
					isNumber(nextPreProductionRunNumber) && (
					!webcast.preProductionRun ||
					webcast.preProductionRun.runNumber !== nextPreProductionRunNumber
				)) {
					webcast.addRunItem({
						runNumber: nextPreProductionRunNumber,
						runType: WebcastRunType.PreProduction,
						status: WebcastRunStatus.Scheduled
					});
				}
				else if( !preProduction &&
					webcast.preProduction) {
					webcast.removePreProdRuns();
				}

				webcast.update({
					accessControl: msgData.accessControl,
					automatedWebcast: msgData.automatedWebcast,
					autoplay: msgData.autoplay,
					autoAssociateVod: msgData.autoAssociateVod,
					backgroundImages: msgData.backgroundImages,
					chatEnabled: msgData.chatEnabled,
					closedCaptionsEnabled: msgData.closedCaptionsEnabled,
					description: msgData.description,
					disableAutoRecording: msgData.disableAutoRecording,
					endDate: this.DateParsers.parseUTCDate(msgData.endDate),
					eventAdminIds: msgData.eventAdminIds,
					isBackgroundFill: msgData.isBackgroundFill,
					liveSubtitles: {
						isLiveSubtiltesEnabled: !!msgData.liveSubtitles,
						...msgData.liveSubtitles
					},
					lobbyTimeMinutes: msgData.lobbyTimeMinutes,
					moderatorIds: msgData.moderatorIds,
					password: msgData.password,
					pollsEnabled: msgData.pollsEnabled,
					preProduction: preProduction && {
						...preProduction,
						durationMs: this.DateParsers.parseTimespan(preProduction.duration)
					},
					presentationFileDownloadAllowed: msgData.presentationFileDownloadAllowed,
					presentationProfileDeviceId: msgData.presentationProfileDeviceId,
					presentationProfileId: msgData.presentationProfileId,
					questionAndAnswerEnabled: msgData.questionAndAnswerEnabled,
					questionOption: msgData.questionOption,
					redirectVod: msgData.redirectVod,
					rtmpSettings: msgData.rtmpSettings,
					shortcutName: msgData.shortcutName,
					startDate: this.DateParsers.parseUTCDate(msgData.startDate),
					title: msgData.title,
					unlisted: msgData.unlisted,
					userIdSlideControl: msgData.userIdSlideControl,
					vcSipAddress: msgData.vcSipAddress,
					vcMicrosoftTeamsMeetingUrl: msgData.vcMicrosoftTeamsMeetingUrl,
					webexTeam: msgData.webexTeam,
					categoryIds: msgData.categoryIds,
					customFields: msgData.customFields,
					enableCustomBranding: msgData.enableCustomBranding,
					webcastBrandingSettings: msgData.webcastBrandingSettings

				});
			},

			WebcastStarting: ({ runNumber }) => {
				webcast.vcStatus.reset();
				webcast.webcastStatus.reset();
				webcast.webcastStatus.isStarting = true;
				this.updateRunItem(webcast, runNumber, run => run.status = WebcastRunStatus.Starting);
			},

			WebcastStarted: ({ runItem, webcastStatus }) => {
				if (webcast.isAutomated) {
					webcast.startingVideoSource = false;
				}

				if (webcastStatus === WebcastStatus.VideoSourceStarting) {
					this.updateWebexMeetingEvent(webcast, runItem);
					return;
				}

				if (webcast.isAutomated ||
					webcast.currentUser.isEventAdmin
				) {
					this.updateWebcastPlaybacks(webcast)
						.finally(() => webcast.startingVideoSource = false);
				}

				webcast.webcastStatus.isInProgress = true;
				this.updateRunItem(
					webcast,
					runItem.runNumber,
					run => {
						run.status = WebcastRunStatus.InProgress;
						run.startDate = this.DateParsers.parseUTCDate(runItem.startDate);
					}
				);
				webcast.update(webcast);
			},
			WebexLiveStreamToWebcastStarting: ({ run }) => {
				if(webcast.webcastStatus.isInProgress || webcast.webcastStatus.isBroadcasting) {
					return;
				}

				this.updateWebexMeetingEvent(webcast, run);
			},

			WebcastVideoSourceStarted: msgData => {
				webcast.automatedWebcast = msgData.automatedWebcast;
				webcast.startingVideoSource = msgData.webcastVideoSource;
				webcast.deviceType = msgData.webcastDeviceType;
			},

			BroadcastStarted: () => {
				//incase of OEM or WebexLiveStream, BroadcastStarted does not guranteed that playback is available. So, Don't fetch playback.
				if(!webcast.currentUser.isEventAdminOrModerator && !webcast.vcStatus?.waitingForStream) {
					this.updateWebcastPlaybacks(webcast)
						.then(() => {
							webcast.webcastStatus.isBroadcasting = true;
							setTimeout(() => this.zone.run(noop));
						});
					return;
				}
				webcast.webcastStatus.isBroadcasting = true;
			},

			BroadcastStopped: () => {
				webcast.webcastStatus.isBroadcasting = false;
			},

			WebcastEnded: ({ runNumber, nextRun, endDate }) => {
				webcast.status = runNumber === webcast.productionRun.runNumber ? WebcastStatus.Completed : WebcastStatus.Scheduled;
				this.updateRunItem(webcast, runNumber, run => {
					run.status = WebcastRunStatus.Ended;
					run.endDate = this.DateParsers.parseUTCDate(endDate);
				});

				webcast.playbacks = null;

				if(nextRun) {
					webcast.addRunItem(nextRun);
				}
				webcast.update(webcast);
			},
			ScheduledEventStopped: () => {
				webcast.status = WebcastStatus.Scheduled;
				webcast.currentRun.status = WebcastRunStatus.Scheduled;
			},

			ScheduledAutomatedWebcastCancelled: () => {
				webcast.automatedWebcast = false;
			},

			WebcastFailed: ({ runNumber }) => {
				webcast.webcastStatus.isFailed = true;
				this.updateRunItem(webcast, runNumber, run => run.status = WebcastRunStatus.Scheduled);
			},

			StartWebcastCancelled: ({ runNumber, status }) => {
				webcast.automatedWebcast = false;
				webcast.status = status;
				webcast.vcStatus.isConnecting = false;
				this.updateRunItem(webcast, runNumber, run => run.status = WebcastRunStatus.Scheduled);

				const currentRunNumber = webcast.currentRun && webcast.currentRun.runNumber;
				if(runNumber === currentRunNumber) {
					webcast.webcastStatus.isCancelled = true;
				}
			},

			VideoSourceEnableLogged: () => {
				if (webcast.isAutomated || webcast.currentUser.isEventAdmin) {
					webcast.waitingForVideoSource = true;	// Show the waiting msg for now.  It will be reset upon WebcastStarted
				}
				webcast.startingVideoSource = false;
			},

			DmeDeviceStartAkamaiDistributionActionResultRecorded: e => {
				if (webcast.isUpdatingPlaybacks) {
					return;
				}

				if (isAkamaiPushedPlaybackReady(webcast.playbacks, e.deviceId, e.streamName)
					&& (webcast.currentUser.isEventAdmin || webcast.currentUser.isEventModerator)) {
					this.waitForVideoSource(webcast);
				} else {
					webcast.waitingForVideoSource = false;
				}
			},

			DmeDeviceStartAkamaiDistributionActionNotAdded: e => {
				if (webcast.isUpdatingPlaybacks) {
					return;
				}

				if (isAkamaiPushedPlaybackReady(webcast.playbacks, e.deviceId, e.streamName)
					&& (webcast.currentUser.isEventAdmin || webcast.currentUser.isEventModerator)) {
					this.waitForVideoSource(webcast);
				} else {
					webcast.waitingForVideoSource = false;
				}
			},

			DmeDeviceStartCdnPushActionResultRecorded: e => {
				if (webcast.isUpdatingPlaybacks) {
					return;
				}

				if (isVbrickCdnPushedPlaybackReady(webcast.playbacks, e.deviceId, e.streamName)
					&& (webcast.currentUser.isEventAdmin || webcast.currentUser.isEventModerator)) {
					this.waitForVideoSource(webcast);
				} else {
					webcast.waitingForVideoSource = false;
				}
			},

			DmeDeviceStartCdnPushActionNotAdded: e => {
				if (webcast.isUpdatingPlaybacks) {
					return;
				}

				if (isVbrickCdnPushedPlaybackReady(webcast.playbacks, e.deviceId, e.streamName)
					&& (webcast.currentUser.isEventAdmin || webcast.currentUser.isEventModerator)) {
					this.waitForVideoSource(webcast);
				} else {
					webcast.waitingForVideoSource = false;
				}
			},

			VcWebcastStatusUpdated: ({ vcStatus, isSuccessful, readyToPlay, numSegmentsPushed }) => {
				if (webcast.isWebexLiveStreamType || webcast.isRtmpStreamType) {
					this.updateWebexMeetingByVCStatus(vcStatus, webcast, numSegmentsPushed);
				}

				//temp fix - incase of webexlive/oem, keep waiting till ui gets numSegmentsPushed > 0.
				//even if response has vcStatus = 'recording' && readyToPlay = true, playback is still not available.
				//Not able to maintain all these statuses now. Backend needs to stream line all these statuses ASAP.
				if (webcast.isWebexLiveStreamType && vcStatus === VC_STATUS.Recording && readyToPlay && !numSegmentsPushed) {
					this.updateWebexMeetingByVCStatus(VC_STATUS.WaitingForStream, webcast);
				} else {
					webcast.vcStatus.update(vcStatus, isSuccessful, readyToPlay);
				}

				if (vcStatus === VC_STATUS.Starting && webcast.vcStatus.connectionFailed) {
					webcast.status = WebcastStatus.Scheduled;
					webcast.currentRun.status = WebcastRunStatus.Scheduled;
				}
			},

			LinkedVideoUpdated: e => {
				webcast.update({
					linkedVideoId: e.videoId,
					redirectVod: e.redirectVod
				});

				this.updateLinkedVideo(webcast);
			}
		});

		return this.PushBus.composeUnsubscribeFn([
			accountDevicesSubscription,
			webcastSubscription,
			webcast.broadcast.subscribePush(),
			webcast.recording.subscribePush(),
			webcast.presentation.registerPushHandlers()
		]);
	}

	public connect(webcast: WebcastModel): Promise<Subscription> {
		webcast.currentUser.sessionId = null;
		webcast.layout.reset();
		webcast.presentation.ignoreSlideChangeEvents(false);

		if(webcast.currentUser.isEventAdmin) {
			if(!webcast.isAutomated && !webcast.presentationProfileDeviceActive) {
				return Promise.reject('presentationProfileDeviceInactive');
			}

			if(webcast.presentation.ready){
				webcast.presentation.prefetchAllSlidesInSeries();
			}
		}

		return new Promise<Subscription>((resolve, reject) => {
			const sub = this.WebcastConnectionService.connect(webcast)
				.subscribe({
					next: () => resolve(sub),
					error: err => {
						console.error('Webcast connection error: ', err);
						reject(err);
					}
				});
		})
			.then(sub => {

				//very hot place. Think before touching this.
				const skipUpdate = webcast.webcastStatus.isStarting
					|| webcast.webcastStatus.isWaitingForStream
					|| webcast.webcastStatus.isVideoSourceStarting
					|| webcast.vcStatus?.waitingForStream;

				if (!skipUpdate) {
					this.updateWebcastPlaybacks(webcast);
				}
				webcast.polls.load();

				return sub;
			});
	}

	private updateRunItem(webcast: WebcastModel, runNumber: number, fn: (RunItem) => void): void {
		const run = webcast.getRunItem(runNumber);
		if(run){
			fn(run);
		}
	}

	private updateWebexMeetingEvent(webcast: WebcastModel, runItem: RunItem): void {
		webcast.webcastStatus.isVideoSourceStarting = true;
		updateWaitingStatusForWebexMeeting(webcast);
		this.updateRunItem(
			webcast,
			runItem.runNumber,
			run => {
				run.status = WebcastRunStatus.InProgress;
				run.startDate = this.DateParsers.parseUTCDate(runItem.startDate);
			}
		);
	}

	private updateWebexMeetingByVCStatus(vcStatus: VC_STATUS, webcast: WebcastModel, numSegmentsPushed?: number): void {
		if (vcStatus === VC_STATUS.WaitingForStream) {
			webcast.waitingForVideoSource = true;
			webcast.vcStatus.update(vcStatus);
		} else if (vcStatus === VC_STATUS.Recording && webcast.vcStatus.waitingForStream && numSegmentsPushed > 0) {
			this.updateWebcastPlaybacks(webcast)
				.finally(() => {
					webcast.startingVideoSource = false;
					webcast.waitingForVideoSource = false;
				});
		}
	}
}
