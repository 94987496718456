<real-time-analytics-data-grid-wrapper [header]="'Event_RealTimeAnalytics_Tab_Attendees' | translate" [gridId]="'attendees'" [webcast]="webcast">
	<vb-ui-infinite-scroll-grid [getRows]="rtaAttendeesService.getRows" [clearScrollId]="AnalyticsService.clearEventScrollId">

		<!--Column Picker Groups-->
		<vb-ui-column-picker-group [headerName]="'Reports_Column_Group_Metrics' | translate" [id]="AttendeesColumnPickerGroup.METRICS" [isExpanded]="true">
		</vb-ui-column-picker-group>

		<vb-ui-column-picker-group [headerName]="'Reports_Column_Group_Session' | translate" [id]="AttendeesColumnPickerGroup.SESSION" [isExpanded]="true">
		</vb-ui-column-picker-group>

		<vb-ui-column-picker-group [headerName]="'Reports_Column_Group_System' | translate" [id]="AttendeesColumnPickerGroup.SYSTEM" [isExpanded]="true">
		</vb-ui-column-picker-group>

		<vb-ui-column-picker-group [headerName]="'Reports_Attendees_Column_Group_User' | translate" [id]="AttendeesColumnPickerGroup.USER" [isExpanded]="true">
		</vb-ui-column-picker-group>

		<!--Default Columns-->
		<vb-ui-data-grid-column [cellRenderer]="'highlightText'" [columnPickerGroup]="AttendeesColumnPickerGroup.USER" [field]="'FullName'" [headerName]="'Reports_Attendees_Column_FullName' | translate" [sort]="'asc'" [sortable]="true" [tooltipField]="'FullName'">
		</vb-ui-data-grid-column>

		<vb-ui-data-grid-column [cellRenderer]="'highlightText'" [columnPickerGroup]="AttendeesColumnPickerGroup.USER" [field]="'Email'" [headerName]="'Reports_Attendees_Column_Email' | translate" [sortable]="true" [tooltipField]="'Email'">
		</vb-ui-data-grid-column>

		<vb-ui-data-grid-column [cellRenderer]="'highlightText'" [columnPickerGroup]="AttendeesColumnPickerGroup.SYSTEM" [field]="'ZoneName'" [headerName]="'Reports_Column_Zone' | translate" [sortable]="true" [tooltipField]="'ZoneName'">
		</vb-ui-data-grid-column>

		<vb-ui-data-grid-column [cellRenderer]="'icon'" [cellRendererParams]="STATUS_CELL_RENDERER_PARAMS" [columnPickerGroup]="AttendeesColumnPickerGroup.SESSION" [field]="'Status'" [filter]="false" [headerName]="'Event_RealTimeAnalytics_Attendees_Status' | translate" [sortable]="true" [tooltipField]="'Status'">
		</vb-ui-data-grid-column>

		<vb-ui-data-grid-column [cellRenderer]="'highlightText'" [columnPickerGroup]="AttendeesColumnPickerGroup.SESSION" [field]="'StreamType'" [headerName]="'Event_RealTimeAnalytics_Attendees_StreamType' | translate" [sortable]="true">
		</vb-ui-data-grid-column>

		<vb-ui-data-grid-column [cellRenderer]="'highlightText'" [columnPickerGroup]="AttendeesColumnPickerGroup.SYSTEM" [field]="'IpAddress'" [headerName]="'Reports_Attendees_Column_IpAddress' | translate" [sortable]="true">
		</vb-ui-data-grid-column>

		<vb-ui-data-grid-column [columnPickerGroup]="AttendeesColumnPickerGroup.METRICS" [field]="'AvgExperiencedBufferingDuration'" [filter]="false" [headerName]="'Event_RealTimeAnalytics_AvgRebufferDuration' | translate" [sortable]="true" [sortingOrderReversed]="true" [valueFormatter]="twoDecimalValueFormatter">
		</vb-ui-data-grid-column>

		<vb-ui-data-grid-column [columnPickerGroup]="AttendeesColumnPickerGroup.METRICS" [field]="'ExperiencedErrors'" [filter]="false" [headerName]="'Event_RealTimeAnalytics_ExperiencedErrors' | translate" [sortable]="true" [sortingOrderReversed]="true" [valueFormatter]="numberValueFormatter">
		</vb-ui-data-grid-column>

		<vb-ui-data-grid-column [columnPickerGroup]="AttendeesColumnPickerGroup.METRICS" [field]="'MulticastFailovers'" [filter]="false" [headerName]="'Event_RealTimeAnalytics_MulticastErrors' | translate" [sortable]="true" [sortingOrderReversed]="true" [valueFormatter]="numberValueFormatter">
		</vb-ui-data-grid-column>

		<!--Hidden Columns-->
		<vb-ui-data-grid-column [columnPickerGroup]="AttendeesColumnPickerGroup.METRICS" [field]="'ExperiencedBufferingCount'" [filter]="false" [headerName]="'Reports_Attendees_Column_ExperiencedRebufferingCount' | translate" [hide]="true" [sortable]="true" [sortingOrderReversed]="true" [valueFormatter]="numberValueFormatter">
		</vb-ui-data-grid-column>

		<vb-ui-data-grid-column [cellRenderer]="'highlightText'" [columnPickerGroup]="AttendeesColumnPickerGroup.SESSION" [field]="'StreamAccessed'" [headerName]="'Event_RealTimeAnalytics_Attendees_StreamUrl' | translate" [hide]="true" [sortable]="true" [tooltipField]="'StreamAccessed'">
		</vb-ui-data-grid-column>

		<vb-ui-data-grid-column [columnPickerGroup]="AttendeesColumnPickerGroup.SESSION" [field]="'AvgBitrateKbps'" [filter]="false" [headerName]="'Event_RealTimeAnalytics_AvgBitrate' | translate" [hide]="true" [sortable]="true" [valueFormatter]="numberValueFormatter">
		</vb-ui-data-grid-column>

		<vb-ui-data-grid-column [columnPickerGroup]="AttendeesColumnPickerGroup.SESSION" [field]="'AvgBandwidthMbps'" [filter]="false" [headerName]="'Event_RealTimeAnalytics_Attendees_AvgBandwidth' | translate" [hide]="true" [sortable]="true" [valueFormatter]="numberValueFormatter">
		</vb-ui-data-grid-column>

		<vb-ui-data-grid-column [columnPickerGroup]="AttendeesColumnPickerGroup.SESSION" [field]="'StartTime'" [filter]="false" [headerName]="'Reports_Attendees_Column_SessionStartTime' | translate" [hide]="true" [sortable]="true" [valueFormatter]="mediumDateTimeValueFormatter">
		</vb-ui-data-grid-column>

		<vb-ui-data-grid-column [columnPickerGroup]="AttendeesColumnPickerGroup.SESSION" [field]="'ViewingStartTime'" [filter]="false" [headerName]="'Reports_Attendees_Column_ViewingStartTime' | translate" [hide]="true" [sortable]="true" [valueFormatter]="mediumDateTimeValueFormatter">
		</vb-ui-data-grid-column>

		<vb-ui-data-grid-column [columnPickerGroup]="AttendeesColumnPickerGroup.SESSION" [field]="'EndTime'" [filter]="false" [headerName]="'Reports_Attendees_Column_ExitTime' | translate" [hide]="true" [sortable]="true" [valueFormatter]="mediumDateTimeValueFormatter">
		</vb-ui-data-grid-column>

		<vb-ui-data-grid-column [columnPickerGroup]="AttendeesColumnPickerGroup.SESSION" [field]="'Removed'" [filter]="false" [headerName]="'Reports_Attendees_Column_Removed' | translate" [hide]="true" [sortable]="true">
		</vb-ui-data-grid-column>

		<vb-ui-data-grid-column [cellRenderer]="'highlightText'" [columnPickerGroup]="AttendeesColumnPickerGroup.SYSTEM" [field]="'Browser'" [headerName]="'Reports_Attendees_Column_Browser' | translate" [hide]="true" [sortable]="true" [tooltipField]="'Browser'">
		</vb-ui-data-grid-column>

		<!-- We don't presently have this field
		<vb-ui-data-grid-column
			[columnPickerGroup]="AttendeesColumnPickerGroup.SYSTEM"
			[field]="'BrowserVersion'"
			[headerName]="'Event_RealTimeAnalytics_Attendees_BrowserVersion' | translate"
			[hide]="true"
			[tooltipField]="'BrowserVersion'">
		</vb-ui-data-grid-column>
		-->

		<vb-ui-data-grid-column [cellRenderer]="'highlightText'" [columnPickerGroup]="AttendeesColumnPickerGroup.SYSTEM" [field]="'PlayerDevice'" [headerName]="'Reports_Attendees_Column_DeviceType' | translate" [hide]="true" [sortable]="true">
		</vb-ui-data-grid-column>

		<vb-ui-data-grid-column [cellRenderer]="'highlightText'" [columnPickerGroup]="AttendeesColumnPickerGroup.SYSTEM" [field]="'OsFamily'" [headerName]="'Reports_Attendees_Column_Platform' | translate" [hide]="true" [sortable]="true" [tooltipField]="'OsFamily'">
		</vb-ui-data-grid-column>

		<vb-ui-data-grid-column [cellRenderer]="'highlightText'" [columnPickerGroup]="AttendeesColumnPickerGroup.SYSTEM" [field]="'OsName'" [headerName]="'Reports_Attendees_Column_PlatformVersion' | translate" [hide]="true" [sortable]="true" [tooltipField]="'OsName'">
		</vb-ui-data-grid-column>

		<vb-ui-data-grid-column [cellRenderer]="'highlightText'" [columnPickerGroup]="AttendeesColumnPickerGroup.USER" [field]="'UserType'" [headerName]="'Reports_Attendees_Column_UserType' | translate" [hide]="true" [sortable]="true" [tooltipField]="'UserType'"> <!--not translated-->
		</vb-ui-data-grid-column>

		<vb-ui-data-grid-column [cellRenderer]="'highlightText'" [columnPickerGroup]="AttendeesColumnPickerGroup.USER" [field]="'Username'" [headerName]="'Reports_Attendees_Column_UserName' | translate" [hide]="true" [sortable]="true" [tooltipField]="'Username'">
		</vb-ui-data-grid-column>

		<vb-ui-data-grid-column [cellRenderer]="'highlightText'" [columnPickerGroup]="AttendeesColumnPickerGroup.USER" [headerName]="'Reports_Attendees_Column_AttendeeType' | translate" [hide]="true" [field]="'AttendeeType'" [sortable]="true"> <!--not translated-->
		</vb-ui-data-grid-column>

	</vb-ui-infinite-scroll-grid>
</real-time-analytics-data-grid-wrapper>
