import {
	Directive,
	Component,
	Input,
	OnChanges,
	Output,
	SimpleChanges,
	AfterViewInit
} from '@vbrick/angular-ts-decorators';

import { IAugmentedJQuery, IPromise, IQService } from 'angular';

import { ComponentCallback } from 'rev-shared/ts-utils/ComponentCallback';
import { ComponentCallbackEvent } from 'rev-shared/ts-utils/ComponentCallbackEvent';
import { ThemeService } from 'rev-portal/branding/Theme.Service';

import styles from './InlineEdit.module.less';

@Directive({ selector: '' })
export class BaseInlineEditComponent<TValue> implements AfterViewInit, OnChanges {
	@Input() public required: boolean;
	@Input() public value: TValue;

	@Output() public onChange: ComponentCallback<any, IPromise<void>>;
	@Output() public onEndEdit: ComponentCallback<void>;

	private readonly styles = styles;

	private processing: boolean;
	protected internalValue: any;

	constructor(
		private $element: IAugmentedJQuery,
		private $q: IQService,
		private ThemeService: ThemeService
	) {
		'ngInject';
	}

	public ngAfterViewInit(): void {
		this.internalValue = this.value;
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if(changes.value) {
			this.internalValue = this.value;
		}
	}

	private endEdit(): void {
		this.onEndEdit();
	}

	protected onSubmit(): void {
		this.processing = true;
		this.$q.when(
			this.onChange(new ComponentCallbackEvent({
				value: this.internalValue
			}))
		)
			.then(() => this.endEdit())
			.finally(() => this.processing = false);
	}
}
