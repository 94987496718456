import {
	Component,
	Input,
	OnDestroy,
	OnInit,
	HostListener,
	NgZone
} from '@angular/core';

import { noop } from 'underscore';

import { WebcastModel } from '../webcast/model/WebcastModel';

const KEYDOWN_EVENT: string = 'keydown';

import styles from './WebcastPresentation.module.less';
import './webcast-presentation.less';
import { Subscription } from 'rxjs';
import { CountryCodes } from 'rev-shared/constants/CountryCodes';

@Component({
	selector: 'webcast-presentation',
	templateUrl: './WebcastPresentation.Component.html',
})
export class WebcastPresentationComponent implements OnInit, OnDestroy {
	@Input() public webcast: WebcastModel;
	@Input() public isFullscreen: boolean;

	private onKeyDownHandler: ($event) => void;
	private readonly styles = styles;
	private sub: Subscription;

	constructor(private zone: NgZone) {}

	public ngOnInit(): void {
		this.sub = this.webcast.presentation.update$.subscribe(() => this.zone.run(noop));
	}

	public ngOnDestroy(): void {
		this.sub?.unsubscribe();
	}

	@HostListener('document:keydown', ['$event'])
	public handleKeyDown(event: KeyboardEvent) {
		const target = event.target as HTMLElement;
		const code = event.code;

		if (['INPUT', 'TEXTAREA', 'SELECT', 'BUTTON'].includes(target.tagName)) {
			return;
		}
		if (this.webcast.currentUser.canControlPresentationSlides) {
			switch(code) {
				case 'ArrowLeft':
					this.webcast.presentation.movePreviousSlide();
					break;

				case 'ArrowRight':
				case 'Space':
					this.webcast.presentation.moveNextSlide();
					break;
			}
		}
	}
}
