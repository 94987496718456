import { NgModule } from '@vbrick/angular-ts-decorators';
import { ITemplateCacheService } from 'angular';
import UibTabsModule from 'angular-ui-bootstrap/src/tabs';
import UibTooltipModule from 'angular-ui-bootstrap/src/tooltip';

@NgModule({
	id: 'VBrick.UI.BootstrapCfg',
	imports: [
		UibTabsModule,
		UibTooltipModule
	]
})
export default class VBrickUiBootstrapModule {
	public static config(
		$uibTooltipProvider: import('angular-ui-bootstrap').ITooltipProvider
	): void {
		'ngInject';

		$uibTooltipProvider.options({
			popupDelay: 800
		});
	}

	public static run($templateCache: ITemplateCacheService): void {
		'ngInject';

		//ui bootstrap changed the tab template to use a div instead of a tag. This breaks our styling, and goes against usability principals.
		//Using this override to go back to the old template. The change may be reverted eventually.
		$templateCache.put('uib/template/tabs/tab.html',
			`<li ng-class="{active: active, disabled: disabled}" class="uib-tab">
				<a ng-click="select()" uib-tab-heading-transclude>{{heading}}</a>
			</li>`);
	}
}
