import {
	Injectable
} from '@vbrick/angular-ts-decorators';
import {
	IPromise,
	resource
} from 'angular';

import { SameSite, getCookie, setCookie } from 'rev-shared/util/CookieUtil';

const messageCookie = 'revSystemMessage';
const messageCookieExpiry = 7 * 24 * 3600000;

@Injectable('SystemMessageService')
export class SystemMessageService {
	private readonly userMessageResource: resource.IResourceClass<resource.IResource<any>>;

	constructor(
		$resource: resource.IResourceService
	) {
		'ngInject';

		this.userMessageResource = $resource('/system-message');
	}

	public loadUserMessage(): IPromise<any> {
		const cookie = getCookie(messageCookie);
		const params: any = {};
		if (cookie) {
			params.since = new Date(+cookie);
		}

		return this.userMessageResource.get(params).$promise
			.then(result => {
				return result.message;
			});
	}

	public markMessageAsRead(): void {
		const expires = new Date(Date.now() + messageCookieExpiry);
		setCookie(messageCookie, Date.now().toString(), { expires, sameSite: SameSite.Strict });
	}
}
