import { NgModule } from '@vbrick/angular-ts-decorators';
import UibDatePickerModule from 'angular-ui-bootstrap/src/datepickerPopup';

import BrandingModule from 'rev-portal/branding/Branding.Module';
import VBrickTextInputModule from 'rev-shared/ui/text-input/VbTextInput.Module';
import VBrickUIButtonsModule from 'rev-shared/ui/buttons/Buttons.Module';

import { DatePickerComponent } from './DatePicker.Component';

@NgModule({
	id: 'VBrick.UI.DatePicker',
	declarations: [
		DatePickerComponent
	],
	imports: [
		UibDatePickerModule,

		BrandingModule,
		VBrickUIButtonsModule,
		VBrickTextInputModule
	]
})
export default class VBrickUIDatePickerModule {}
