import {
	Component,
	Input,
	OnDestroy,
	OnInit,
	Output
} from '@vbrick/angular-ts-decorators';

import { IQService, IPromise } from 'angular';

import { retryUntilSuccess } from 'rev-shared/util/PromiseUtil';

import { SparkService } from './Spark.Service';
import { IUnsubscribe } from 'rev-shared/push/IUnsubscribe';

@Component({
	selector: 'video-share-spark',
	templateUrl: '/partials/media/videos/share/video-share-spark.html'
})
export class VideoShareSparkComponent implements OnDestroy, OnInit {
	@Input() public resolve: {
		dialogParams: {
			title: string;
			videoUrl: string;
		};
	};

	@Output() public close: () => void;

	private message: string;
	private rooms: any[];
	private selectedRooms: any[];
	private sparkAuthenticationRequired: boolean;
	private sparkOnAuthenticateUnsubscribe: IUnsubscribe;
	private status: any;

	constructor(
		private $q: IQService,
		private SparkService: SparkService
	) {
		'ngInject';
	}

	public ngOnInit(): void {
		this.selectedRooms = [];

		this.loadRooms();
	}

	public ngOnDestroy(): void {
		if (this.sparkOnAuthenticateUnsubscribe) {
			this.sparkOnAuthenticateUnsubscribe();
		}
	}

	private loadRooms(isRetry: boolean = false) {
		this.status = { processing: true };
		this.sparkAuthenticationRequired = false;

		return (isRetry ?
			retryUntilSuccess(() => Promise.resolve(this.SparkService.getRooms())) :
			Promise.resolve(this.SparkService.getRooms())
		)
			.then(rooms => {
				this.status = { active: true };
				this.rooms = rooms;
			})
			.catch(err => {
				if (err.authenticationRequired && !isRetry) {
					this.status = { active: true };
					this.sparkAuthenticationRequired = true;

					this.sparkOnAuthenticateUnsubscribe = this.SparkService.onAuthenticate(() => this.loadRooms(true));
				} else {
					this.status = {
						error: true,
						loadRoomsError: true
					};
				}
			});
	}

	private submit(): IPromise<any> {
		this.status = { processing: true };

		return this.SparkService.shareVideo({
			roomIds: this.selectedRooms.map(room => room.id),
			title: this.resolve.dialogParams.title,
			videoUrl: this.resolve.dialogParams.videoUrl,
			message: this.message
		})
			.then(() => this.close())
			.catch(() => {
				this.status = {
					error: true,
					saveError: true
				};
			});
	}
}
