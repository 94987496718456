import { NgModule } from '@vbrick/angular-ts-decorators';
import DropdownModule from 'angular-ui-bootstrap/src/dropdown';

import CaretModule from 'rev-shared/ui/caret/Caret.Module';
import SecurityModule from 'rev-shared/security/Security.Module';
import SharedTeamModule from 'rev-shared/team/SharedTeam.Module';
import VBrickSharedMediaDowngradeModule from 'rev-shared/media/Media.Module.downgrade';

import { MediaMenuComponent } from './MediaMenu.Component';
import { MediaMenuDropdownComponent } from './MediaMenuDropdown.Component';
import { MyTeamsMenuItemsComponent } from 'rev-portal/navigation/mediaMenu/MyTeamsMenuItems.Component';
import { MyVideosMenuItemsComponent } from 'rev-portal/navigation/mediaMenu/MyVideosMenuItems.Component';

import { MediaMenuDataManagerDirective } from 'rev-portal/navigation/mediaMenu/MediaMenuDataManager.Directive';


@NgModule({
	id: 'VBrick.Portal.Navigation.MediaMenu',
	declarations: [
		MediaMenuComponent,
		MediaMenuDataManagerDirective,
		MediaMenuDropdownComponent,
		MyTeamsMenuItemsComponent,
		MyVideosMenuItemsComponent
	],
	providers: [],
	imports: [
		DropdownModule,
		CaretModule,
		SecurityModule,
		SharedTeamModule,
		VBrickSharedMediaDowngradeModule
	]
})

export default class MediaMenuModule {}
