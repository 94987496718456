
import {
	Component,
	Input,
	OnInit
} from '@angular/core';

import { BrowserFeatureService } from 'rev-shared/util/BrowserFeature.Service';

import styles from './WebcastRegionVisibilityToggle.module.less';
import { WebcastModel } from '../../model/WebcastModel';

@Component({
	selector: 'webcast-region-visibility-toggle',
	templateUrl: './WebcastRegionVisibilityToggle.Component.html',
	host: {
		'[class]': 'styles.root'
	}
})
export class WebcastRegionVisibilityToggleComponent implements OnInit {
	@Input() public webcast: WebcastModel;

	public isVolumeControlSupported: boolean;
	public readonly styles = styles;

	constructor(
		private BrowserFeature: BrowserFeatureService
	) {}

	public ngOnInit(): void {
		this.isVolumeControlSupported = this.BrowserFeature.volumeControl();
	}

	//player should expose observable for volume.
	public get isMuted(): boolean {
		const webcast = this.webcast;
		const vgAPI = webcast && webcast.video.vgAPI;
		return !vgAPI || vgAPI.volume === 0;
	}

	public toggleSound(): void {
		const vgAPI = this.webcast.video.vgAPI;

		if (vgAPI) {
			// Mute toggle is not exposed through the videogular API.
			// It's buried in the vg-mute-button directive, so trigger its behavior.
			window.setTimeout(() => (vgAPI as any).toggleSound());
		}
	}
}
