import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { TranslateModule } from '@ngx-translate/core';

import { CssRulesAngularModule } from 'rev-shared/ui/css/Css.AngularModule';
import { DateAngularModule } from 'rev-shared/date/DateAngular.Module';
import { FlickityCarouselModule } from 'rev-shared/ui/flickityCarousel/FlickityCarousel.AngularModule';
import { Video360IndicatorModule } from 'rev-shared/media/video360Indicator/Video360Indicator.AngularModule';

import { TeamsAngularModule } from 'rev-portal/media/teams/Teams.AngularModule';

import { DashboardCarouselComponent } from './DashboardCarousel.Component';
import { EventsCarouselComponent } from './events/EventsCarousel.Component';
import { NoCarouselResultsComponent } from './noCarouselResults/NoCarouselResults.Component';
import { TeamsCarouselComponent } from './teams/TeamsCarousel.Component';
import { VideoCarouselComponent } from './video/VideoCarousel.Component';

@NgModule({
	imports: [
		CommonModule,
		CssRulesAngularModule,
		DateAngularModule,
		FlickityCarouselModule,
		TeamsAngularModule,
		TranslateModule,
		UIRouterModule.forChild(),
		Video360IndicatorModule
	],
	declarations: [
		DashboardCarouselComponent,
		EventsCarouselComponent,
		NoCarouselResultsComponent,
		TeamsCarouselComponent,
		VideoCarouselComponent
	],
	exports: [
		EventsCarouselComponent,
		TeamsCarouselComponent,
		VideoCarouselComponent
	]
})
export class CarouselsModule {}
