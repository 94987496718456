<vb-ui-data-grid [ngClass]="styles.webexMeetingsListWrapper" [quickFilterText]="quickFilter" [rowData]="data" (gridReady)="gridReady.emit($event)">

	<vb-ui-data-grid-column [cellRenderer]="'link'" [cellRendererParams]="nameLinkCellRendererParams" [field]="'name'" [headerName]="'Name' | translate" [filter]="true">
	</vb-ui-data-grid-column>

	<vb-ui-data-grid-column [cellRenderer]="'checkbox'" [field]="'isCiEnabled'" [headerName]="'Media_Integrations_WebexMeetings_CI_Enabled' | translate">
	</vb-ui-data-grid-column>

	<vb-ui-data-grid-column [field]="'enableAutoIngest'" [headerName]="'Media_Integrations_WebexMeetings_AutoImport' | translate" [valueFormatter]="yesNoValueFormatter">
	</vb-ui-data-grid-column>

	<vb-ui-data-grid-column [field]="'enableManualImport'" [headerName]="'Media_Integrations_WebexMeetings_ManualImport' | translate" [valueFormatter]="yesNoValueFormatter">
	</vb-ui-data-grid-column>

	<vb-ui-data-grid-column [field]="'enableWebexLiveStream'" [headerName]="'Media_Integrations_WebexMeetings_LiveStreaming' | translate" [valueFormatter]="yesNoValueFormatter">
	</vb-ui-data-grid-column>

	<vb-ui-data-grid-column [cellRenderer]="'icon'" [cellRendererParams]="ciLinkedCellRendererParams" [field]="'webexCiLinked'" [headerName]="'Status' | translate" [valueFormatter]="ciLinkStatusValueFormatter">
	</vb-ui-data-grid-column>

	<vb-ui-data-grid-column [cellRenderer]="'actionMenuButton'" [cellRendererParams]="menuBtnCellRendererParams" [headerName]="'Actions' | translate">
	</vb-ui-data-grid-column>

</vb-ui-data-grid>