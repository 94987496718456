import { Component } from '@vbrick/angular-ts-decorators';

import { StateService } from '@uirouter/angularjs';

import { VideoStatus } from 'rev-shared/media/VideoStatus';

import { VideoSearchResultsComponent } from './VideoSearchResults.Component';

@Component({
	selector: 'video-search-results-table',
	templateUrl: '/partials/media/search/video-search-results-table.html',
	require: {
		videoSearchResults: '^videoSearchResults'
	}
})
export class VideoSearchResultsTableComponent {
	private videoSearchResults: VideoSearchResultsComponent;

	constructor(
		private $state: StateService
	) {
		'ngInject';
	}

	public get baseStateName(): string {
		return this.$state.current.name?.replace(/\.[^\.]*-sidebar$/g, '');
	}

	private isProcessing(video: any): boolean {
		return video.status === VideoStatus.PROCESSING ||
			video.status === VideoStatus.UPLOADING_FINISHED;
	}

	private isUploading(video: any): boolean {
		return video.status === VideoStatus.NOT_UPLOADED ||
			video.status === VideoStatus.UPLOADING;
	}

	private processingFailed(video: any): boolean {
		return video.status === VideoStatus.UPLOAD_FAILED ||
			video.status === VideoStatus.READY_BUT_PROCESSING_FAILED ||
			video.status === VideoStatus.PROCESSING_FAILED;
	}
}
