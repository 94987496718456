import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { IGetInfiniteScrollRows } from 'rev-shared/ui/dataGrid/infiniteScroll/IGetInfiniteScrollRows';
import { IVbUiInfiniteScrollGridDataSourceResult } from 'rev-shared/ui/dataGrid/infiniteScroll/IVbUiInfiniteScrollGridDataSourceResult';
import { lastValueFrom } from 'rev-shared/rxjs/lastValueFrom';

import { PushService } from 'rev-shared/push/PushService';
@Injectable()
export class AttendeesListService {
	constructor(
		private http: HttpClient,
		private PushService: PushService
	) {}

	public initAttendeesView(webcastId: string, runNumber: number): void {
		this.PushService.dispatchCommand('scheduledEvents:ViewWebcastRealtimeAnalytics',
			{
				webcastId,
				runNumber
			}
		);
	}

	public getRows: IGetInfiniteScrollRows = params => {
		return this.fetch(
			params.context.webcast.id,
			params.context.webcast.currentRun.runNumber,
			{
				size: params.pageSize,
				search: params.query,
				scrollId: params.scrollId,
				previousScrollId: params.previousScrollId,
				sortDirection: params.isSortAscending ? 'asc' : 'desc',
				sortBy: params.sortField
			}
		).then<IVbUiInfiniteScrollGridDataSourceResult>(result => ({
			data: result.attendees,
			scrollId: result.scrollId,
			totalRows: result.total
		}));
	};

	public fetch(webcastId: string, runNumber: number, params: any): Promise<any> {
		return lastValueFrom(this.http.post<any>(`/analytics/events/${webcastId}/${runNumber}/attendee-list`, params)
			.pipe(
				catchError((error: HttpErrorResponse) => {
					console.error(error);
					if (error.status === 404) {
						throw error;
					}
					//ensuring ag-grid does not fail..
					return of({
						attendees: [],
						scrollId: undefined,
						total: 0
					});
				})
			)
		);
	}

	public getAttendeeCount(webcastId: string, runNumber: number): Promise<number> {
		return lastValueFrom(this.http.get<any>(`/analytics/events/${webcastId}/${runNumber}/attendee-count`)
			.pipe(
				map(result => result.total)
			)
		);
	}

	public removeAttendee(webcastId: string, runNumber: number, attendee: any): Promise<void> {
		return this.PushService.dispatchCommand('scheduledEvents:RemoveWebcastAttendee', {
			userId: attendee.UserId,
			runNumber,
			webcastId
		});
	}

	public restoreAttendee(webcastId: string, runNumber: number, attendee: any): Promise<void> {
		return this.PushService.dispatchCommand('scheduledEvents:RestoreWebcastAttendee', {
			userId: attendee.UserId,
			runNumber,
			webcastId
		}).catch(result => {
			if (!result.hasIssue('AlreadyRestored')) {
				return Promise.reject(result);
			}
		});
	}
}
