<div layout="row" [ngClass]="styles.kpiWrapper">
	<vb-ui-report-cell flex="1 1 0" [header]="'Reports_Attendees' | translate" [footers]="[
			{ title: 'Reports_UserType_Internal' | translate, data: (data.internalUserCount | number:0) },
			{ title: 'Reports_UserType_Public' | translate, data: (data.guestUserCount | number:0) }
		]">
		<vb-ui-kpi [value]="data.userCount">
		</vb-ui-kpi>
	</vb-ui-report-cell>

	<vb-ui-report-cell flex="1 1 0" [header]="'Reports_TotalAttendeeDuration' | translate" [footers]="[
			{
				title: 'Reports_UserType_Internal' | translate,
				data: (data.internalAttendeesDuration | vbTimespan:true | vbExcludeSecondsFromTimespan | vbFormatTimespanLargeHours)
			},
			{
				title: 'Reports_UserType_Public' | translate,
				data: (data.guestAttendeesDuration | vbTimespan:true | vbExcludeSecondsFromTimespan | vbFormatTimespanLargeHours)
			}
		]">
		<span [ngClass]="styles.kpiData">
			{{ data.attendeesDuration | vbTimespan:true | vbExcludeSecondsFromTimespan | vbFormatTimespanLargeHours }}
		</span>
	</vb-ui-report-cell>

	<vb-ui-report-cell flex="1 1 0" [header]="'Reports_AverageViewingTime' | translate">
		<span [ngClass]="styles.kpiData">
			{{ data.averageTime | vbTimespan:true | vbExcludeSecondsFromTimespan | vbFormatTimespanLargeHours }}
		</span>
	</vb-ui-report-cell>
</div>