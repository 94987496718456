import { Component, Input, Output, OnInit } from '@vbrick/angular-ts-decorators';
import { IDocumentService, IWindowService } from 'angular';
import { ComponentCallback } from 'rev-shared/ts-utils/ComponentCallback';
import { ComponentCallbackEvent } from 'rev-shared/ts-utils/ComponentCallbackEvent';
import { isChrome } from 'rev-shared/util/UserAgentUtil';
import { UtilService } from 'rev-shared/util/Util.Service';

import './share-link.less';

@Component({
	selector: 'share-link',
	templateUrl: '/partials/media/share-link.html',
	transclude: true
})
export class ShareLink implements OnInit {
	@Input() public emailBody: string;
	@Input() public emailSubject: string;
	@Input() public enableSpark: boolean;
	@Input() public linkUrl: string;

	@Output() public onShareToSpark: ComponentCallback;

	public isChrome: boolean;
	public isCopyCmdSupported: boolean;

	constructor(
		protected $document: IDocumentService,
		protected $window: IWindowService,
		protected Util: UtilService
	) {
		'ngInject';
	}

	public ngOnInit() {
		Object.assign(this, {
			isCopyCmdSupported: this.Util.getCopyCommandSupported(),
			isChrome: isChrome()
		});
	}

	public onEmailClick(): void {
		const mailTo: string = 'mailto:?' + this.Util.urlEncode({
			subject: this.emailSubject,
			body: this.emailBody
		});

		if (this.isChrome) { // chrome doesn't like mailto via window.open()
			this.mailToRedirect(mailTo);
		} else {
			this.mailToNewWindow(mailTo);
		}
	}

	protected shareToSparkInternal(): void {
		this.onShareToSpark(new ComponentCallbackEvent({}));
	}

	private mailToNewWindow(mailTo: string): void {
		const mailToWindow = this.$window.open(mailTo, 'shareMailToWindow');

		if (mailToWindow && mailToWindow.open && !mailToWindow.closed) {
			mailToWindow.close();
		}
	}

	private mailToRedirect(mailTo: string): void {
		this.$window.location.href = mailTo;
	}
}
