import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import type { GridReadyEvent } from '@ag-grid-community/core';

import { AnalyticsService } from 'rev-shared/analytics/Analytics.Service';
import { IGetInfiniteScrollRows } from 'rev-shared/ui/dataGrid/infiniteScroll/IGetInfiniteScrollRows';
import { mediumDateTimeValueFormatter } from 'rev-shared/ui/dataGrid/valueFormatters/MediumDateTimeValueFormatter';
import { numberValueFormatter, twoDecimalValueFormatter } from 'rev-shared/ui/dataGrid/valueFormatters/NumberValueFormatter';
import { VbUiInfiniteScrollGridComponent } from 'rev-shared/ui/dataGrid/infiniteScroll/VbUiInfiniteScrollGrid.Component';

import { COLUMN_GROUP } from './ColumnConfig';
import styles from './WebcastAttendeesGrid.module.less';

@Component({
	selector: 'webcast-attendees-grid',
	templateUrl: './WebcastAttendeesGrid.Component.html'
})
export class WebcastAttendeesGridComponent {
	@Input() public getRows: IGetInfiniteScrollRows;
	@Input() public filterText: string;
	@Input() public revConnectUseStatus: boolean;
	@Input() public webcastId: string;
	@Input() public runNumber: number;

	@ViewChild(VbUiInfiniteScrollGridComponent)
	public grid: VbUiInfiniteScrollGridComponent;

	@Output() public gridReady = new EventEmitter<GridReadyEvent>();

	public readonly COLUMN_GROUP = COLUMN_GROUP;
	public readonly mediumDateTimeValueFormatter = mediumDateTimeValueFormatter;
	public readonly numberValueFormatter = numberValueFormatter;
	public readonly twoDecimalValueFormatter = twoDecimalValueFormatter;
	public readonly pageSize: number = 100;
	public readonly styles = styles;

	constructor(
		public AnalyticsService: AnalyticsService
	) {}

	public ngAfterViewInit(): void {
		this.grid.suppressMultiSort = true;
		this.grid.columnPickerEnabled = true;

		this.grid.defaultColDef = {
			...this.grid.defaultColDef,
			minWidth: 100,
			resizable: true
		};

		this.grid.context = {
			...this.grid.context,
			webcastId: this.webcastId,
			runNumber: this.runNumber
		};
	}
}
