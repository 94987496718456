import { NgModule } from '@vbrick/angular-ts-decorators';

import VBrickSecurityModule from 'rev-shared/security/Security.Module';

import { MetadataService } from './Metadata.Service';

@NgModule({
	id: 'VBrick.Shared.Metadata',
	imports: [
		VBrickSecurityModule
	],
	providers: [
		MetadataService
	]
})
export class SharedMetadataModule {}
