import { NgModule } from '@vbrick/angular-ts-decorators';

import BrandingModule from 'rev-portal/branding/Branding.Module';

import { VbUIRadioButtonComponent } from './VbUIRadioButton.Component';

@NgModule({
	id: 'Vbrick.UI.RadioButton',
	declarations: [
		VbUIRadioButtonComponent
	],
	imports: [
		BrandingModule
	]
})

export default class VbUIRadioButtonModule {}
