import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

import { ButtonsAngularModule } from 'rev-shared/ui/buttons/ButtonsAngular.Module';
import { CheckboxAngularModule } from 'rev-shared/ui/checkbox/CheckboxAngular.Module';
import { CssRulesAngularModule } from 'rev-shared/ui/css/Css.AngularModule';
import { DirectivesAngularModule } from 'rev-shared/util/directives/DirectivesAngular.Module';
import { FlickityCarouselModule } from 'rev-shared/ui/flickityCarousel/FlickityCarousel.AngularModule';
import { FormGroupAngularModule } from 'rev-shared/ui/formGroup/FormGroupAngular.Module';
import { SpinnerAngularModule } from 'rev-shared/ui/spinner/SpinnerAngular.Module';
import { TextInputAngularModule } from 'rev-shared/ui/text-input/TextInputAngular.Module';
import { ValidationAngularModule } from 'rev-shared/ui/validation/ValidationAngular.Module';
import { VbRadioBtnModule } from 'rev-shared/ui/radio/VbRadioBtn.Module';

import { BrandingAngularModule } from 'rev-portal/branding/BrandingAngular.Module';

import { VbWebcastEditPollViewComponent } from './VbWebcastEditPollView.Component';
import { VbWebcastPollsViewCompoment } from './VbWebcastPollsView.Component';
import { VbWebcastPollsAttendeeViewComponent } from './VbWebcastPollsAttendeeView.Component';
import { VbWebcastPollsManageViewComponent } from './VbWebcastPollsManageView.Component';
const components = [
	VbWebcastEditPollViewComponent,
	VbWebcastPollsViewCompoment,
	VbWebcastPollsAttendeeViewComponent,
	VbWebcastPollsManageViewComponent
];

@NgModule({
	declarations: components,
	entryComponents: components,
	exports: components,
	imports: [
		BrandingAngularModule,
		ButtonsAngularModule,
		CheckboxAngularModule,
		CommonModule,
		CssRulesAngularModule,
		DirectivesAngularModule,
		FlickityCarouselModule,
		FormsModule,
		FormGroupAngularModule,
		SpinnerAngularModule,
		TextInputAngularModule,
		TranslateModule,
		ValidationAngularModule,
		VbRadioBtnModule
	]
})
export class VbWebcastPollsModule {

}
