import { Component } from '@angular/core';

import type { ICellRendererAngularComp } from '@ag-grid-community/angular';
import type { ICellRendererParams } from '@ag-grid-community/core';
import { DialogService } from 'rev-shared/ui/dialog/Dialog.Service';
import { getFilterText } from 'rev-shared/ui/dataGrid/cellRenderers/HighlightCellRenderer.Component';

import styles from './RealTimeAnalyticsDevicesTab.module.less';

const maxLength = 80;

@Component({
	selector: 'real-time-analytics-device-zone-renderer',
	template: `
		<div layout="row" flex-wrap="false">
			<span
				class="text-ellipsis"
				flex="1 1 0"
				[innerHTML]="zoneStr | highlight : highlightText : styles.highlight">
			</span>
			<span>
				<button class="glyphicons plus"
					[ngClass]="styles.iconBtn"
					type="button"
					*ngIf="zones.length > 1"
					[title]="'Zones' | translate"
					(click)="openZonesList()">
				</button>
			</span>
		</div>
	`
})
export class RealTimeAnalyticsDeviceZoneRendererComponent implements ICellRendererAngularComp {
	public deviceName: string;
	public zones: string[];
	public zoneStr: string;
	public highlightText: string;

	public readonly styles = styles;

	constructor(
		private readonly DialogService: DialogService
	) {
	}

	public agInit(params: ICellRendererParams): void {
		this.updateCell(params);
	}

	public refresh(params: ICellRendererParams): boolean {
		this.updateCell(params);

		return true;
	}

	private updateCell(params: ICellRendererParams): void {
		this.deviceName = params.data?.DeviceName;
		this.zones = params.data?.Zones
			?.map(z => z.ZoneName) || [];

		this.zoneStr = this.zones
			.join(', ')
			.substring(0, maxLength);

		this.highlightText = getFilterText(params);
	}

	public openZonesList(): void {
		this.DialogService.getDialog('ZonesList').open({
			initialState: {
				zones: this.zones,
				deviceName: this.deviceName
			}
		});
	}
}
