import { IAugmentedJQuery, element as angularElement } from 'angular';
import { AfterViewInit, Input, Directive, Output } from '@vbrick/angular-ts-decorators';

import { UserContextService } from 'rev-shared/security/UserContext.Service';
import { ComponentCallback } from 'rev-shared/ts-utils/ComponentCallback';
import { ComponentCallbackEvent } from 'rev-shared/ts-utils/ComponentCallbackEvent';
import { FileWrapper } from './FileWrapper';
import { CsrfTokenHeader } from 'rev-shared/security/Tokens';

/**
	File Upload Directive.  Wrapper around jquery fileupload plugin

	Example:
		<form method="post" enctype="multipart/form-data" action="/submit-url"
			file-upload
			on-add="onAdd(file, event)"
			on-progress="onProgress(loaded, total, bitrate, event)">

			<input file type="file" name="video"/>
			<input file type="file" name="thumbnail"/>
			<input type="text" name="fileName" ng-model="fileName"/>
			<input type="text" name="description" ng-model="description"/>

		</form>
**/
@Directive({
	selector: '[file-upload]',
	bindToController: true
})
export class FileUploadDirective implements AfterViewInit {
	@Input('<? singleFileUploads') public singleFileUploads: boolean;
	@Input('<? maxChunkSize') public chunkUploadSizeBytes: number;

	@Output() public onAdd: ComponentCallback;

	constructor(
		private $element: IAugmentedJQuery,
		private UserContext: UserContextService
	) {
		'ngInject';
	}

	public ngAfterViewInit(): void {
		(this.$element as any).fileupload({
			singleFileUploads: this.singleFileUploads,
			dataType: 'text',
			multipart: true,
			dropZone: angularElement('.file-upload-drop-zone', this.$element),
			pasteZone: this.$element,
			maxChunkSize: this.chunkUploadSizeBytes,
			formData: {}, //prevents other form inputs from being sent. This can be read from an input if needed.
			add: (event, data) => {
				const file = new FileWrapper(this.UserContext, data, this.$element);

				this.onAdd(new ComponentCallbackEvent({ file, event }));
				this.$element.scope().$apply();
			},
			beforeSend: xhr => {
				xhr.setRequestHeader(CsrfTokenHeader, this.UserContext.getCsrfToken());
			}
		});
	}
}
