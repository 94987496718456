import { Component, Input, EventEmitter, Output } from '@angular/core';

import { WebcastModel } from 'rev-portal/scheduledEvents/webcast/model/WebcastModel';
import { WebcastQuestionOption } from 'rev-portal/scheduledEvents/webcast/WebcastStatus';

import { WebcastViewQuestionsService } from './WebcastViewQuestions.Service';

import styles from './VbWebcastAskQuestion.Component.module.less';

@Component({
	selector: 'vb-webcast-ask-question',
	templateUrl: './VbWebcastAskQuestion.Component.html',
	host: {
		'[class]': 'styles.root'
	}
})
export class VbWebcastAskQuestionComponent {
	@Input() public webcast: WebcastModel;

	@Output() public onQuestionAsked: EventEmitter<any> = new EventEmitter();

	public readonly styles = styles;
	public readonly WebcastQuestionOption = WebcastQuestionOption;
	public showSubmitForm: boolean;
	public questionText: string;
	public isAnonymous: boolean;
	public isFormDisabled: boolean;

	constructor(
		private WebcastViewQuestionsService: WebcastViewQuestionsService
	) {}

	public ngOnInit(): void {
		this.reset();
	}

	public reset(): void {
		this.questionText = '';
		this.isAnonymous = this.webcast.questionOption === WebcastQuestionOption.ANONYMOUS,
		this.isFormDisabled = false,
		this.showSubmitForm = false;
	}
	public submitQuestion(): void {
		this.isFormDisabled = true;
		this.showSubmitForm = true;

		this.WebcastViewQuestionsService.askQuestion(this.webcast, this.questionText, this.isAnonymous)
			.then(question => {
				this.onQuestionAsked.emit({ question });
				this.reset();
			})
			.finally(() => {
				this.isFormDisabled = false;
				this.showSubmitForm = false;
			});
	}
}
