import { Component, Input, AfterViewInit, OnInit, ViewChild } from '@angular/core';
import { NgModel, NgForm, NgControl, AbstractControl } from '@angular/forms';
import { LoginRedirectService } from 'rev-shared/security/LoginRedirect.Service';
import { UserContextService } from 'rev-shared/security/UserContext.Service';

import { WebcastService } from '../webcast/Webcast.Service';

import styles from './EventRegistration.module.less';

@Component({
	selector: 'event-registration',
	templateUrl: './EventRegistration.Component.html',
	host: {
		'[class]': 'hostClass'
	}
})
export class EventRegistrationComponent implements AfterViewInit, OnInit {
	@Input() public webcastInfo: any;
	@Input() public webcastUrl: string;
	@Input() public isPreview: boolean;

	public readonly styles = styles;
	public status: {[key: string]: boolean} = { active: true };
	public error: string;
	public hostClass: any;

	@ViewChild('form') public form: NgForm;
	@ViewChild('gdpr') public gdpr: NgModel;

	public formData = {
		name: null,
		email: null,
		password: null,
		gdprConsent: false
	};

	constructor(
		private LoginRedirectService: LoginRedirectService,
		private WebcastService: WebcastService,
		private UserContext: UserContextService
	) {
	}

	public ngOnInit(): void {
		this.hostClass = {
			[this.styles.isPreview]: this.isPreview
		};
	}

	public ngAfterViewInit(): void {
		this.gdpr?.control.setValidators(({ value })=> !value ? { gdpr: true } : undefined);
	}

	public signInUser(): void {
		if(!this.isPreview) {
			this.LoginRedirectService.redirectToLogin(this.webcastUrl);
		}
	}

	public submit(): void {
		if(this.isPreview){
			return;
		}

		this.status = { processing: true };
		this.error = null;

		this.WebcastService.registerGuestUser(this.webcastInfo.webcastId, {
			name: this.formData.name,
			email: this.formData.email,
			password: this.formData.password,
			registrationFieldsAnswers: (this.webcastInfo.registrationFields || [])
				.filter(field => !!field.value)
				.map(field => ({ name: field.name, value: field.value }))
		})
			.then(user => {
				//establish the user as a registered guest
				this.UserContext.registerGuestUser(user);

				window.history.replaceState({}, '', '/#' + this.webcastUrl);
			})
			.catch(err => {

				if (err?.error?.attendeeRemoved) {
					this.error = 'AttendeeRemoved';
				}
				else {
					this.error = 'Unknown';
				}
				this.status = { active: true };
			});
	}

	public getFieldControl(index: number): AbstractControl {
		return this.form?.controls['customField' + index];
	}

	public showFieldError(index: number): boolean {
		const ctrl = this.getFieldControl(index);

		return ctrl?.touched && ctrl.invalid;
	}
}
