import { AnalyticsService } from 'rev-shared/analytics/Analytics.Service';
import { IAnalyticsSettings } from 'rev-shared/analytics/IAnalyticsSettings';
import { IVbNg2StateDeclaration } from 'rev-shared/ts-utils/IVbNg2StateDeclaration';

import { WebcastAttendeesListComponent } from './WebcastAttendeesList.Component';

const ANALYTICS_SETTINGS_TOKEN = 'analyticsSettings';
export const stateDeclarations: IVbNg2StateDeclaration[] = [
	{
		name: 'portal.scheduledEvents.webcast.view.attendees',
		url: '/attendees',
		views: {
			'right-sidebar-content@portal.scheduledEvents.webcast.view': {
				component: WebcastAttendeesListComponent
			}
		},
		resolve: [
			{
				token: ANALYTICS_SETTINGS_TOKEN,
				deps: [
					AnalyticsService
				],
				resolveFn(analyticsService: AnalyticsService) {
					return analyticsService.getSettings();
				}
			},
			{
				token: 'countPollingIntervalInSec',
				deps: [
					ANALYTICS_SETTINGS_TOKEN
				],
				resolveFn(analyticsSettings: IAnalyticsSettings) {
					return analyticsSettings.webcastRealtimeKpiUpdateInterval;
				}
			},
			{
				token: 'listPollingIntervalInSec',
				deps: [
					ANALYTICS_SETTINGS_TOKEN
				],
				resolveFn(analyticsSettings: IAnalyticsSettings) {
					return analyticsSettings.webcastRealtimeTableUpdateInterval;
				}
			}
		]
	}
];
