import { Directive, DoCheck, OnDestroy, OnInit, Output } from '@vbrick/angular-ts-decorators';
import {
	IAugmentedJQuery,
	ITimeoutService,
	IWindowService
} from 'angular';
import $ from 'jquery';
import { throttle } from 'underscore';

import { ComponentCallback } from 'rev-shared/ts-utils/ComponentCallback';
import { ComponentCallbackEvent } from 'rev-shared/ts-utils/ComponentCallbackEvent';

/**
 * Writes the element's height to the scope
 * 	Example:
 * 		<div vb-offset-height-model="toolbarHeight = $event.offsetHeight"
 */

@Directive({
	selector: '[vb-offset-height-model]'
})
export class OffsetHeightModelDirective implements OnInit, OnDestroy, DoCheck {
	@Output() public vbOffsetHeightModel: ComponentCallback;

	private height: number;
	private windowEl: any;

	constructor(
		private $element: IAugmentedJQuery,
		private $timeout: ITimeoutService,
		private $window: IWindowService
	) {
		'ngInject';
	}

	public ngOnInit(): void {
		this.windowEl = $(this.$window);
		this.updateHeight();
		this.windowEl.on('resize', this.updateHeight);
	}

	public ngDoCheck(): void {
		this.updateHeight();
	}

	public ngOnDestroy(): void {
		this.windowEl.off('resize', this.updateHeight);
	}

	private updateHeight = throttle((): void => {
		const offsetHeight: number = this.$element[0].offsetHeight;

		if(offsetHeight !== this.height) {
			this.height = offsetHeight;

			this.vbOffsetHeightModel(new ComponentCallbackEvent({ offsetHeight }));
			this.$timeout();
		}
	}, 100, { trailing: false });
}
