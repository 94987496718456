import { IWindowService } from 'angular';

/**
 * Polyfill for location.origin being undefined. Supported across all of our browsers,
 * but IE11 had a bug at some point where it doesn't get populated.
 */
export function LocationOriginPolyfill($window: IWindowService): void {
	const loc: Location = $window.location;

	if (!loc.origin) {
		(loc as any).origin = loc.protocol + '//' + loc.hostname + (loc.port ? ':' + loc.port : '');
	}
}
