import {
	Component,
	Input,
	OnDestroy,
	OnInit
} from '@vbrick/angular-ts-decorators';

import { StateService } from '@uirouter/angularjs';
import { IModalInstanceService } from 'rev-shared/ui/dialog/IDialog';

import {
	IAugmentedJQuery,
	IFilterDate,
	IPromise,
	IQService,
	ITimeoutService
} from 'angular';

import { AccessControl } from 'rev-shared/media/MediaConstants';
import { DateUtil } from 'rev-shared/date/DateUtil';
import { FieldType } from 'rev-shared/metadata/FieldType';
import { IDialogService, IDialog } from 'rev-shared/ui/dialog/IDialog';
import { formatIsoDate } from 'rev-shared/date/DateFormatters';

import { MediaStateService } from 'rev-portal/media/MediaState.Service';
import { VideoSearchResultsComponent } from 'rev-portal/media/search/VideoSearchResults.Component';
import { InsightSearchHelper } from 'rev-portal/search/InsightSearchHelper.Service';

import {
	IBulkEditOperation,
	BulkEditOperator,
	BulkEditFormStateService
} from './BulkEditFormState.Service';
import { BulkEditService } from './BulkEdit.Service';
import { VideoSelectionModelService } from './VideoSelectionModel.Service';

import './bulk-edit.less';
import 'rev-less/media/sidebar.less';

@Component({
	selector: 'bulk-edit-videos',
	templateUrl: '/partials/media/bulk/bulk-edit-videos.html',
	require: {
		videoSearchResults: '^videoSearchResults'
	}
})
export class BulkEditVideosComponent implements OnDestroy, OnInit {
	@Input() public accountId: string;
	@Input() public categories: any[];
	@Input() public expirationRules: any[];
	@Input() public mediaFeatures: any;
	@Input() public teams: any[];

	private readonly FieldType = FieldType;
	private fields: any;
	private readonly today: Date = DateUtil.getToday();
	private readonly midnight: string = this.dateFilter(this.today, 'shortTime');
	private readonly operations = BulkEditOperator;

	private accessControlInsightOptions: any;
	private userTagsInsightOptions: any;
	private accessOptions: AccessControl[];
	private cancelConfirmation: IDialog;
	private errorDialogOpts: {[key: string]: string};
	private expirationConfig: any;
	private preservedExpirationValue;
	private processingDialog: IDialog;
	private processingDialogInstance: IModalInstanceService;
	private processingDialogTimer: IPromise<any>;
	private removeReplaceOptions: BulkEditOperator[];
	private removeReplaceAppendOptions: BulkEditOperator[];
	private saveConfirmation: IDialog;
	private videoSearchResults: VideoSearchResultsComponent;

	constructor(
		private $element: IAugmentedJQuery,
		private $q: IQService,
		private $state: StateService,
		private $timeout: ITimeoutService,
		private dateFilter: IFilterDate,
		private BulkEditFormState: BulkEditFormStateService,
		private BulkEditService: BulkEditService,
		private Dialog: IDialogService,
		private MediaStateService: MediaStateService,
		private VideoSelectionModel: VideoSelectionModelService,
		private InsightSearchHelper: InsightSearchHelper
	) {
		'ngInject';
	}

	public ngOnInit(): void {
		this.$element.addClass('container-fluid');

		this.fields = this.BulkEditFormState.fields;

		//ng-options goes into infinite digest loop if these are declared in the template
		this.removeReplaceOptions = [BulkEditOperator.replace, BulkEditOperator.remove];
		this.removeReplaceAppendOptions = [BulkEditOperator.append, BulkEditOperator.replace, BulkEditOperator.remove];

		this.accessOptions = [
			this.mediaFeatures.enablePublicVideoAccess && AccessControl.Public,
			AccessControl.AllUsers,
			AccessControl.Private,
			(this.teams.length > 0) && AccessControl.Teams
		].filter(Boolean);

		this.accessControlInsightOptions = this.InsightSearchHelper.buildAccessEntityInsightOptions({
			accountId: this.accountId,
			users: true,
			groups: true,
			showEditAccessInput: true
		});

		this.userTagsInsightOptions = this.InsightSearchHelper.buildAccessEntityInsightOptions({
			accountId: this.accountId,
			users: true
		});

		this.expirationConfig = {
			expiryRuleType: 'Date'
		};
	}

	public ngOnDestroy(): void {
		this.closeProcessingDialog();
	}

	private get bulkOperations(): IBulkEditOperation[] {
		return this.BulkEditFormState.getBulkOperations();
	}

	private cancel(): void {
		if (this.bulkOperations.length) {
			this.cancelConfirmation
				.open()
				.result
				.then(() => this.onCancel());
		}

		this.onCancel();
	}

	private closeProcessingDialog(): void {
		this.$timeout.cancel(this.processingDialogTimer);

		if (this.processingDialogInstance) {
			this.processingDialogInstance.close();
		}

		this.processingDialogTimer = this.processingDialogInstance = null;
	}

	private ensureSelectionLoaded(): IPromise<any> {
		if (this.VideoSelectionModel.isSelectAll) {
			return this.videoSearchResults.forceLoadAllSearchResults();
		}

		return this.$q.when();
	}

	private filterCategories(query: string): any[] {
		query = query.toLowerCase();
		return this.categories.filter(category => category.name.toLowerCase().includes(query));
	}

	private filterCustomFieldOperations(isRequired: boolean): BulkEditOperator[] {
		return isRequired
			? [BulkEditOperator.replace]
			: this.removeReplaceOptions;
	}

	private filterTeams(query: string): any[] {
		query = query.toLowerCase();
		return this.teams.filter(team => team.name.toLowerCase().includes(query));
	}

	private onAccessControlChanged(): void {
		const accessControlField = this.fields.accessControl;
		const forceEditAccess: boolean = accessControlField.value && accessControlField.value !== AccessControl.Private;

		this.accessControlInsightOptions.forceEditAccess = forceEditAccess;

		if (forceEditAccess) {
			const aclField = this.fields.accessControlEntities;

			aclField.value = aclField.value.filter(entity => entity.canEdit);
		}

		if (accessControlField.value !== AccessControl.Teams) {
			this.fields.selectedTeams.value = [];
		} else {
			this.fields.accessControlEntities.value = [];
		}
	}

	private onAccessControlEntitiesOperationChanged(): void {
		this.fields.selectedTeams.operation = this.fields.accessControlEntities.operation;
	}

	private onCancel(): void {
		this.BulkEditFormState.init();
		this.$state.go('^');
	}

	private onExpirationChange(expirationValue: any): void {
		if (expirationValue && expirationValue.expirationDate) {
			expirationValue.expirationDate = formatIsoDate(expirationValue.expirationDate);
		}

		this.fields.expiration.value = expirationValue;
	}

	private onStatusChange(): void {
		const isActive: boolean = this.fields.isActive.value;

		if (!isActive && this.fields.expiration.value) {
			this.preserveExpirationValue();
		} else if (isActive && this.preservedExpirationValue) {
			this.restoreExpirationValue();
		}
	}

	private openProcessingDialog(): void {
		this.processingDialogTimer = this.$timeout(() => this.processingDialogInstance = this.processingDialog.open(), 1000);
	}

	private preserveExpirationValue(): void {
		this.preservedExpirationValue = this.fields.expiration.value;
		this.onExpirationChange(null);
	}

	private restoreExpirationValue(): void {
		this.onExpirationChange(this.preservedExpirationValue);
		this.preservedExpirationValue = null;
	}

	private saveVideos(): void {
		this.openProcessingDialog();

		this.ensureSelectionLoaded()
			.then(() => {
				const operations: IBulkEditOperation[] = this.bulkOperations;
				const videoIds: string[] = this.VideoSelectionModel.selectedVideos.map(video => video.id);

				return this.BulkEditService.updateVideos(videoIds, operations);
			})
			.then(({ message }) => {
				this.Dialog.getDialog('bulkEditSubmissionDialog')
					.open({
						legalHoldVideosCount: (message.legalHoldVideoIds || []).length,
						isEditing: true
					})
					.result
					.then(() => this.MediaStateService.navigateBack());
			})
			.catch(() => this.Dialog.getDialog('AlertDialog').open(this.errorDialogOpts))
			.finally(() => this.closeProcessingDialog());
	}

	private submit(): void {
		this.saveConfirmation
			.open()
			.result
			.then(() => this.saveVideos());
	}

	public get isActiveOnly(): boolean {
		return this.VideoSelectionModel.selectionCount > 0
			&& this.VideoSelectionModel.selectedVideos.every(video => video.isActive);
	}

	public get isInactiveOnly(): boolean {
		return this.VideoSelectionModel.selectionCount > 0
			&& this.VideoSelectionModel.selectedVideos.every(video => !video.isActive);
	}

	public get activeInactiveVideosSelected(): boolean {
		if(this.VideoSelectionModel.isSelectAll && this.VideoSelectionModel.selectedVideos.length < this.VideoSelectionModel.totalVideos) {
			return true; //in case of selectAll and not all videos loaded we are treating as both active and inactive true.
		}
		return this.VideoSelectionModel.selectionCount > 0 && !this.isActiveOnly && !this.isInactiveOnly;
	}


	public get activeVideoPublishDate(): boolean {
		return this.fields && this.fields.isActive && this.fields.isActive.value
			|| !this.inActiveVideoPublishDate
			&& (this.isActiveOnly
				|| this.activeInactiveVideosSelected
			);
	}

	public get inActiveVideoPublishDate(): boolean {
		return this.fields && this.fields.isActive && this.fields.isActive.value === false
			|| this.isInactiveOnly;
	}

	public get maxPublishDate(): Date | undefined {
		return this.activeVideoPublishDate ? this.today : undefined;
	}

	public get publishDateOperations(): BulkEditOperator[] {
		return this.activeVideoPublishDate ? [BulkEditOperator.replace] : this.removeReplaceOptions;
	}

	public get showImmediateActiveText(): boolean {
		return this.publishDateAvailable
			&& this.fields.publishDate.value <= this.today
			&& (this.inActiveVideoPublishDate
				|| this.activeInactiveVideosSelected
			);
	}

	public get showFutureActiveText(): boolean {
		return this.publishDateAvailable
			&& (!this.fields.publishDate.value
				|| this.fields.publishDate.value > this.today
			);
	}

	public get publishDateAvailable(): boolean {
		return this.fields && this.fields.publishDate;
	}
}
