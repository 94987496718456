<ul id="webcastEndReportsMenu" [ngClass]="styles.menuWrapper" role="menu" aria-labelledby="webcastEndReportsMenuBtn">
    <li *ngIf="!completedRunItems?.length" [ngClass]="styles.menuItemWrapper" role="none">
            <span [ngClass]="styles.menuItem" role="menuitem">
                {{ 'NoReportsAvailable' | translate }}
            </span>
    </li>
	<li [ngClass]="styles.menuItemWrapper" *ngFor="let runItem of completedRunItems" role="none">
	    <a [ngClass]="styles.menuItem" (click)="runItemClicked.emit({ runNumber: runItem.runNumber })" role="menuitem">
            {{ (runItem.isPreProduction ? 'PreProduction' : 'MainEvent') | translate}}
            {{ runItem.startDate | date:'shortTime' }} &ndash; {{ runItem.endDate | date:'shortTime'}}
        </a>
    </li>
</ul>
