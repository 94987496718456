import { NgModule } from '@vbrick/angular-ts-decorators';

import 'angular-ui-sortable'; // angular wrapper for jquery-ui sortable

import { SortableDirective } from './Sortable.Directive';

@NgModule({
	id: 'VBrick.UI.Sortable',
	declarations: [
		SortableDirective
	],
	imports: [
		'ui.sortable' // angular-ui-sortable
	]
})
export default class SortableModule {}
