import { Inject, Injectable } from '@angular/core';
import { tag } from 'rxjs-spy/operators';
import {
	Observable,
	defer,
	from, of
} from 'rxjs';
import {
	switchMap,
	tap,
	startWith,
	map,
	distinctUntilChanged,
	takeWhile
} from 'rxjs/operators';

import { DisconnectCallback, PushService } from 'rev-shared/push/PushService';
import { SignalRHubsConnection } from 'rev-shared/push/SignalRHubsConnection';
import { SignalRHubsConnectionToken } from 'rev-shared/push/SignalRHubsConnectionToken';
import { UserContextService } from 'rev-shared/security/UserContext.Service';
import { addTeardown } from 'rev-shared/rxjs/AddTeardownOperator';

import { updateWaitingStatusForWebexMeeting } from '../WebcastStatusUpdateHelper';
import { WebcastModel } from './WebcastModel';

@Injectable({
	providedIn: 'root'
})
export class WebcastConnectionService {
	private readonly accountId = this.UserContext.getAccount().id;
	private readonly connectionReestablished$ = this.SignalRHubsConnection.asRxObservable<void>('ConnectionReestablished');

	constructor(
		private PushService: PushService,
		@Inject(SignalRHubsConnectionToken) private SignalRHubsConnection: SignalRHubsConnection,
		private UserContext: UserContextService
	) {}

	public connect(webcast: WebcastModel): Observable<any> {
		return webcast.webcast$.pipe(
			map(w => w.currentUser.isEventAdmin),
			distinctUntilChanged(),
			switchMap(isEventAdmin => isEventAdmin ?
				this.getEventAdminConnection(webcast) :
				this.getAttendeeConnection(webcast)
			),
			tag('WebcastConnectionService.connect')
		);
	}

	private getAttendeeConnection(webcast: WebcastModel): Observable<any> {

		return this.getWebcastConnection(webcast,
			() => this.dispatchJoinWebcast(webcast),
			() => this.registerAttendeeDisconnectCommand(webcast),
			() => Promise.resolve(webcast.currentUser.sessionId ?
				this.disconnectWebcastAttendee(webcast) :
				null)
		);
	}

	private getEventAdminConnection(webcast: WebcastModel): Observable<any> {
		if(!webcast.isAutomated && !webcast.webcastStatus.isStarted && !webcast.isTrustedPublicWebcast) {
			return from(this.dispatchStartWebcast(webcast)
				.then(result => {
					if(result) {
						webcast.startingVideoSource = result.message.startingVideoSource;
						webcast.vcStatus.reset();
						webcast.vcStatus.isInitializing = webcast.isVCI && webcast.startingVideoSource;
						updateWaitingStatusForWebexMeeting(webcast);
					}
				}))
				.pipe(switchMap(() => this.getAttendeeConnection(webcast)));
		}
		return this.getAttendeeConnection(webcast);
	}

	private getWebcastConnection(
		webcast: WebcastModel,
		joinFn: () => Promise<void>,
		autoDisconnectFn: () => Promise<DisconnectCallback>,
		leaveFn: () => Promise<void>
	): Observable<any> {
		let disconnectFn: DisconnectCallback;

		const autoDisconnnect$ = defer(autoDisconnectFn).pipe(
			tap(result => disconnectFn = result)
		);

		const join$ = defer(joinFn).pipe(
			switchMap(() => autoDisconnnect$)
		);

		return this.connectionReestablished$.pipe(
			startWith<any, any>(undefined),
			takeWhile(() => !webcast.isPastEndDate(), true),
			switchMap(() => {
				if(webcast.isPastEndDate()) {
					return of(undefined);
				}

				if(!disconnectFn) {
					return join$;
				}

				return from(disconnectFn()).pipe(
					switchMap(success => success ?
						autoDisconnnect$ :
						join$)
				);
			}),
			addTeardown(() => {
				if(disconnectFn) {
					disconnectFn();
				}
				leaveFn();
			}),
			tag('WebcastConnection.getWebcastConnection')
		);
	}

	private dispatchJoinWebcast(webcast: WebcastModel): Promise<any>{
		const command = webcast.isTrustedPublicWebcast ? 'scheduledEvents:JoinTrustedPublicWebcast' : 'scheduledEvents:JoinWebcast';
		if(!webcast.currentUser.sessionId) {
			webcast.currentUser.sessionId = [webcast.id, webcast.currentRun.runNumber, webcast.currentUser.id, this.UserContext.getSessionStartTime()].join('_');
		}
		return this.PushService.dispatchCommand(command, {
			webcastId: webcast.id,
			sessionId: webcast.currentUser.sessionId,
			runNumber: webcast.currentRun.runNumber
		});
	}

	private dispatchStartWebcast(webcast: WebcastModel): Promise<any> {
		return this.PushService.dispatchCommand('scheduledEvents:StartWebcast', {
			webcastId: webcast.id,
			runNumber: webcast.currentRun.runNumber
		}, ['VBrickDeviceStartProgramActionAdded', 'LiveEnrichmentStarting', 'WebcastStarted', 'WebcastEventAdminReconnected', 'VcStarting', 'RtmpsLiveStreamToRevStarted']);
	}

	private registerAttendeeDisconnectCommand(webcast: WebcastModel): Promise<DisconnectCallback> {
		const webcastId = webcast.id;
		return this.PushService.registerDisconnectCommand('scheduledEvents:DisconnectWebcastAttendee', {
			webcastId,
			userId: webcast.currentUser.id,
			sessionId: webcast.currentUser.sessionId,
			accountId: this.accountId,
			runNumber: webcast.currentRun.runNumber
		});
	}

	private disconnectWebcastAttendee(webcast: WebcastModel): Promise<void> {

		return this.PushService.dispatchCommand('scheduledEvents:DisconnectWebcastAttendee', {
			webcastId: webcast.id,
			userId: webcast.currentUser.id,
			sessionId: webcast.currentUser.sessionId,
			runNumber: webcast.currentRun.runNumber
		});
	}
}
