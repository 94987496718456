import { NgModule } from '@angular/core';
import { UIRouterModule } from '@uirouter/angular';

import { ng2StatesToHybridStates } from 'rev-shared/uiRouterUtils/HybridRouterUtils';
import { buildLoaderStateDeclaration } from 'rev-shared/uiRouterUtils/LazyLoaderStateUtil';
import { SpinnerAngularModule } from 'rev-shared/ui/spinner/SpinnerAngular.Module';
import { lazyLoadHybridModule } from 'rev-shared/util/AngularJsLazyLoadHelper.Service';
import { MediaFeaturesPreLoadModule } from './mediaSettings/mediaFeatures/MediaSettingsPreLoad.Module';

@NgModule({
	imports: [
		MediaFeaturesPreLoadModule,
		SpinnerAngularModule,
		UIRouterModule.forChild({
			states: ng2StatesToHybridStates([{
				name: 'portal.admin.**',
				url: '/admin/:accountId',
				authorizationKey: 'admin',
				loadChildren() {
					return lazyLoadHybridModule(
						import(/* webpackChunkName: 'admin' */ 'rev-portal/admin/Admin.Module'),
						m => m.AdminAngularModule,
						m => m.AdminModule
					);
				}
			},
			buildLoaderStateDeclaration('portal.admin', {
				name: 'portal.load-admin',
				url: '/load-admin/:accountId',
				authorizationKey: 'admin',
			})])
		})
	]
})
export class AdminLoaderModule {
}
