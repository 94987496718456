<vb-confirmation-dialog [title]="'Event_EndWebcastConfirmationTitle' | translate" [message]="'Event_RecordingEndWebcast_Confirmation_Message' | translate" [actionText]="'Save' | translate" [secondaryActionText]="'No' | translate" [cancelText]="'Event_RecordingEndWebcast_Dismiss' | translate">
</vb-confirmation-dialog>

<div class="container-fluid" [hidden]="!status.error">
	<div class="row padding-top-10">
		<div class="col-xs-12" [ngClass]="styles.border">
			<div class="centered-copy">
				<div class="alert alert-danger">
					{{ 'UnhandledError' | translate }}
				</div>
			</div>
		</div>
	</div>
</div>

<vb-loading-spinner [hidden]="!status.processing" [block]="true" [size]="'large'">
</vb-loading-spinner>

<div class="container-fluid" [hidden]="!webcast.isPresentationProfileDeviceInactive">
	<div class="row padding-top-10">
		<div class="col-xs-12" [ngClass]="styles.border">
			<div class="centered-copy">
				<div class="alert alert-danger">
					<button type="button" class="close" (click)="closeWebcast()">&times;</button>
					<span class="glyphicons circle_exclamation_mark"></span>
					{{ 'Event_VideoSourceInactive' | translate }}
				</div>
			</div>
		</div>
	</div>
</div>

<div [hidden]="!status.active">
	<!--Starting Video Source indeterminate progress display for the event admin-->
	<div class="container-fluid" *ngIf="webcast.startingVideoSource
		&& webcast.currentUser.isEventAdmin
		&& !webcast.enableLiveStreamStatusDisplay">
		<div class="row padding-top-10">
			<div class="col-xs-12" [ngClass]="styles.border">
				<vb-loading-bar class="centered-copy" [isLoading]="true">
					<h3>{{ 'Event_EncoderStillStarting' | translate }}</h3>
				</vb-loading-bar>
			</div>
		</div>
	</div>

	<div class="container-fluid" *ngIf="!webcast.startingVideoSource
				&& !webcast.currentUser.isEventModerator
				&& !webcast.enableLiveStreamStatusDisplay
				&& webcast.waitingForVideoSource">
		<div class="row padding-top-10">
			<div class="col-xs-12" [ngClass]="styles.border">
				<vb-loading-bar class="centered-copy" [isLoading]="true">
					<h3>{{ 'Event_WaitingForVideoSource' | translate }}</h3>
				</vb-loading-bar>
			</div>
		</div>
	</div>

	<div class="webcast" layout="column" flex-wrap="false" *ngIf="(!webcast.startingVideoSource && !webcast.waitingForVideoSource) || webcast.enableLiveStreamStatusDisplay" [ngClass]="{
			'webcast-wrap': !webcast.layout.isFullscreenVideo,
			'webcast-wrap-fs': webcast.layout.isFullscreenVideo
		}" [ngStyle]="{
			height: height
		}">

		<webcast-pre-prod-banner [hidden]="webcast.layout.isFullscreenVideo" [webcast]="webcast">
		</webcast-pre-prod-banner>

		<!-- these are the status messages -->
		<div class="webcast-status-msg" [hidden]="!(!webcast.layout.isFullscreenVideo && webcast.currentUser.isEventAdmin)">
			<span class="event-message processing fade-it-in" [hidden]="!(webcast.webcastStatus.isCancelled && !webcast.broadcast.isStarting && !webcast.enableLiveStreamStatusDisplay)">
				{{ 'Event_UnableToStartWebcast' | translate }} {{ 'Event_UnableToStartVideoSource' | translate }} {{ 'Event_DeviceOffline' | translate }}
			</span>
			<span class="event-message processing fade-it-in" [hidden]="!(webcast.broadcast.isStarting)">
				{{ 'Event_StartingBroadcast' | translate }}
			</span>
			<span class="event-message processing fade-it-in" [hidden]="!(webcast.broadcast.isStopping)">
				{{ 'Event_StoppingBroadcast' | translate }}
			</span>
			<span class="event-message warning fade-it-in" [hidden]="!(!webcast.broadcast.isStarting && !webcast.broadcast.isStopping && !webcast.broadcast.isActive && !webcast.isAutomated)">
				{{ 'Event_AttendeesCannotSeeEvent' | translate }}
			</span>
			<span class="event-message warning fade-it-in" [hidden]="!(!webcast.broadcast.isStarting && !webcast.broadcast.isStopping && !webcast.broadcast.isActive && webcast.isAutomated && !webcast.webcastStatus.isEnded && !webcast.webcastStatus.isCancelled)">
				{{ 'Event_StartingBroadcast' | translate }}
			</span>
			<span class="event-message theme-accent theme-accent-font-border fade-it-in" [ngClass]="styles.bottomBorder" [hidden]="!(!webcast.broadcast.isStarting && !webcast.broadcast.isStopping && webcast.broadcast.isActive)">
				{{ 'Event_AttendeesCanSeeEvent' | translate }}
			</span>

			<span class="event-message warning fade-it-in" [hidden]="!(webcast.recording.isReconnecting)">
				{{ 'Event_RecordingReconnecting' | translate }}
			</span>
		</div>

		<!-- Navigation Bar -->
		<div>
			<webcast-nav *ngIf="viewContext.showNavBar && !webcast.layout.isFullscreenVideo" [webcast]="webcast" (onEndEvent)="leaveWebcast()">
			</webcast-nav>
		</div>

		<div class="position-relative" flex="fill" layout="column" [webcastBackground]="webcast.backgroundImages" [isBackgroundFill]="webcast.isBackgroundFill">
			<vb-ui-sidebar-layout class="webcast-body" *ngIf="sidebarConfig && webcast.currentRun.isStarted" [sidebarConfig]="sidebarConfig" [themed]="true" (onSidebarToggle)="onToggleSidebar($event)" [ngClass]="[
					styles.sidebarLayout,
					webcast.currentUser.isEventAdmin ? 'presenter no-status-bar' : '',
					isMobile && !!activeSidebarButtonId ? styles.sidebarOpenedInMobile : ''
				]" flex="1" layout="column" layout-gt-md="row" flex-wrap="false">


				<div layout="column" flex="fill" class="webcast-main-content theme-primary-font-border-fade-50" slot="mainContent" [ngClass]="{
						'vertical-layout': webcast.layout.isVertical,
						'no-presentation': !webcast.layout.isPresentationVisible,
						'no-video': !webcast.layout.isVideoVisible,
						'no-slides': !webcast.layout.isPresentationSlidesVisible,
						'no-right-sidebar': !webcast.layout.isRightSidebarOpen
					}">
					<div flex="fill" [ngClass]="[
							styles.videoAndPresentationWrapper,
							webcast.currentUser.canControlPresentationSlides && webcast.layout.isPresentationSlidesVisible ? styles.videoAndPresentationWrapperWithSlides : ''
						]" *ngIf="webcast.layout.isBroadcastContentRendered">
						<div class="resize-fill viewer-content" layout="column" layout-gt-md="row" [hidden]="!webcast.layout.isBroadcastContentVisible">
							<vb-webcast-video [webcast]="webcast" [isStopped]="!webcast.layout.isBroadcastContentVisible" layout-gt-md="column" flex="50" class="video-viewport" [ngClass]="{
									'webcast-video-hidden': !webcast.layout.isVideoVisible,
									'viewport-fs': webcast.layout.isFullscreenVideo
								}">
							</vb-webcast-video>

							<webcast-presentation [webcast]="webcast" layout-gt-md="column" flex="50" [ngClass]="styles.presentationViewport" *ngIf="viewContext.showPresentationSlides && webcast.presentation.hasPresentation && !webcast.layout.isFullscreenVideo" [hidden]="!webcast.layout.isPresentationVisible">
							</webcast-presentation>
						</div>
					</div>
					<div [ngClass]="[
							styles.eventNotStarted,
							!!webcast.backgroundImages ? 'theme-primary' : ''
						]" *ngIf="!webcast.layout.isBroadcastContentVisible">
						<div *ngIf="!webcast.broadcast.isActive">
							<h2>{{ 'Event_EventAdminNotBroadcasting' | translate }}</h2>

							{{webcast.startDate| vbDateTimeMedium}} -
							{{webcast.endDate| vbDateTimeMedium}}

							<div [hidden]="!(webcast.description)" [ngClass]="styles.eventDescriptionWrapper" class="text-align-left theme-primary-font-border-fade-50" [innerHTML]="webcast.description | safeHtml">
							</div>
						</div>
						<div [ngClass]="styles.spinnerWrap" *ngIf="webcast.broadcast.isActive">
						<vb-loading-spinner [block]="true" [size]="'large'">
						</vb-loading-spinner>
						</div>
					</div>
					<div flex="256px" class="presentation-slides-wrapper" *ngIf="viewContext.showPresentationSlides && webcast.currentUser.canControlPresentationSlides" [hidden]="!(webcast.layout.isPresentationSlidesVisible && !webcast.layout.isFullscreenVideo)">
						<div layout-gt-md="column" class="presentation-slides hidden-sm hidden-xs">
							<webcast-presentation-slides [webcast]="webcast"></webcast-presentation-slides>
						</div>
					</div>
				</div>
				<div slot="sidebarHeaderControls">
					<span [hidden]="activeSidebarButtonId !== WebcastViewSidebarButton.POLLS" [ngPlural]="webcast.polls?.$polls?.length">
						<ng-template ngPluralCase="1">{{ 'Event_PollCount_1' | translate }}</ng-template>
						<ng-template ngPluralCase="other">{{ 'Event_PollCount_n' | translate : { '0': webcast.polls?.$polls?.length } }}</ng-template>
					</span>
					<button type="button" class="padding-full-5" *ngIf="activeSidebarButtonId === WebcastViewSidebarButton.INFO && webcast.currentUser.canEdit" (click)="inlineEdit=true" vbUiBtnSecondaryNgx>
						<i class="glyphicon glyphicon-pencil"></i>
						{{ 'EditSettings' | translate }}
					</button>
					<a *ngIf="activeSidebarButtonId === WebcastViewSidebarButton.QA && webcast.currentUser.canManageQuestions" uiSref="webcast-fullscreen.manage-questions" [uiParams]="{webcastId: webcast.id}" target="_blank" vbUiBtnSecondaryNgx [attr.aria-label]="'Event_QA_OpenNewWindow' | translate">
						<span class="glyphicons new_window" [attr.title]="'Event_OpenInNewWindow' | translate"></span>
					</a>
				</div>
				<!-- SidebarComponent context -->
				<webcast-view-sidebar-panel slot="sidebarPanel" [accountId]="accountId" [webcast]="webcast" [sidebarNotifications]="sidebarNotifications" [webcastViewContext]="viewContext" [selectedButtonId]="activeSidebarButtonId" [inlineEdit]="inlineEdit" (inlineEditCompleted)="inlineEdit=false">
				</webcast-view-sidebar-panel>
			</vb-ui-sidebar-layout>
			<webcast-start-and-end-message [webcast]="webcast"></webcast-start-and-end-message>
		</div>
	</div>
</div>
