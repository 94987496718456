import { NgModule } from '@vbrick/angular-ts-decorators';

import PushDowngradeModule from 'rev-shared/push/Push.downgrade.Module';
import VBrickFormDirectivesModule from 'rev-shared/util/directives/form/FormDirectives.Module';
import VBrickSharedUtilModule from 'rev-shared/util/Util.Module';
import VBrickUIButtonsModule from 'rev-shared/ui/buttons/Buttons.Module';
import VBrickUiSpinnerModule from 'rev-shared/ui/spinner/Spinner.Module';
import { VbUiGridListModule } from 'rev-shared/ui/gridList/VbGridList.Module';
import { UISelectModule } from 'rev-shared/ui/ui-select/UISelect.Module';

import { ImportWebexDialogComponent } from './ImportWebexDialog.Component';
import { RevCreateDownloadDialogComponent } from './RevCreateDownloadDialog.Component';

import { RevCreateService } from './RevCreate.Service';
import { UploadService } from './Upload.Service';
import { WebExService } from './WebEx.Service';
import DialogModule from 'rev-shared/ui/dialog/Dialog.Module';
import { downgradeInjectables } from 'rev-shared/util/AngularHybridUtils';

@NgModule({
	id: 'VBrick.Portal.Media.Import',
	declarations: [
		ImportWebexDialogComponent,
		RevCreateDownloadDialogComponent
	],
	imports: [
		PushDowngradeModule,
		UISelectModule,
		VBrickFormDirectivesModule,
		VBrickSharedUtilModule,
		VBrickUIButtonsModule,
		VBrickUiSpinnerModule,
		VbUiGridListModule,
		DialogModule.forChild([{
			name: 'ImportWebExDialog',
			component: 'importWebexDialog'
		}, {
			name: 'RevCreateDownloadDialog',
			component: 'revCreateDownloadDialog'
		}])
	],
	providers: [
		WebExService
	]
})
export default class VbrickImportModule {}

downgradeInjectables(VbrickImportModule, [
	{
		name: 'UploadService',
		injectable: UploadService
	}
]);
