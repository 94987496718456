import { NgModule } from '@vbrick/angular-ts-decorators';

import { VideoTileComponent } from './VideoTile.Component';

import { downgradeComponents } from 'rev-shared/util/AngularHybridUtils';

@NgModule({
	id: 'VBrick.UI.VideoTile'
})

export default class VideoTileModule {}

downgradeComponents(VideoTileModule, [{
	name: 'videoTile',
	component: VideoTileComponent
}]);
