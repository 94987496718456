import { AccountLicenseService } from 'rev-shared/security/AccountLicense.Service';
import { PlaylistService } from 'rev-shared/media/Playlist.Service';
import { UserLocalIPService } from 'rev-shared/security/UserLocalIP.Service';
import { IVbNg2StateDeclaration } from 'rev-shared/ts-utils/IVbNg2StateDeclaration';

import { PortalDashboardComponent } from './PortalDashboard.Component';

const DEFAULT_AUDIO_THUMBNAIL: string = '/shared/img/audio-thumbnail.png';
const DEFAULT_VIDEO_THUMBNAIL: string = '/shared/img/default-thumbnail.png';

export const DASHBOARD_STATE = 'portal.dashboard';

export const states: IVbNg2StateDeclaration[] = [{
	name: DASHBOARD_STATE,
	url: '/',
	component: PortalDashboardComponent,
	resetZoom: true,
	resolve: [{
		token: 'featuredVideos',
		deps: [PlaylistService, UserLocalIPService, 'accountId'],
		resolveFn(
			PlaylistService: PlaylistService,
			UserLocalIPService: UserLocalIPService,
			accountId: string
		) {
			return UserLocalIPService.checkUserLocation()
				.then(() => PlaylistService.getFeaturedVideos(accountId))
				.then(playlist => (playlist.videos || [])
					.filter(video => video.isActive)
					.map(video => ({
						...video,
						heartbeatInterval: playlist.heartbeatInterval,
						thumbnailUri: video.thumbnailUri ||
							(video.hasAudioOnly ?
								DEFAULT_AUDIO_THUMBNAIL :
								DEFAULT_VIDEO_THUMBNAIL)
					}))
				);
		}
	}, {
		token: 'accountLicense',
		deps: [AccountLicenseService],
		resolveFn(
			accountLicense: AccountLicenseService
		) {
			return accountLicense.reload();
		}
	}]
}];
