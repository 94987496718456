import { Component, Input, Output, EventEmitter } from '@angular/core';

import { IMediaFeatures } from 'rev-shared/media/IMediaFeatures';
import { INotifications } from 'rev-portal/scheduledEvents/webcast/Webcast.Contract';
import { MediaFeaturesService } from 'rev-shared/media/MediaFeatures.Service';
import { ThemeService } from 'rev-portal/branding/Theme.Service';
import { WebcastModel } from 'rev-portal/scheduledEvents/webcast/model/WebcastModel';

import { WebcastViewSidebarButton } from './WebcastViewSidebarButtonModel';
import styles from './WebcastViewSidebarPanel.Component.module.less';
import { IWebcastViewContext } from './IWebcastViewContext';

@Component({
	selector: 'webcast-view-sidebar-panel',
	templateUrl: './WebcastViewSidebarPanel.Component.html',
	host: {
		'[class]': 'styles.root'
	}
})
export class WebcastViewSidebarPanelComponent {
	@Input() public readonly accountId: string;
	@Input() public webcast: WebcastModel;
	@Input() public sidebarNotifications: INotifications;
	@Input() public selectedButtonId: string;
	@Input() public webcastViewContext: IWebcastViewContext;
	@Input() public inlineEdit: boolean;

	@Output() public inlineEditCompleted: EventEmitter<any> = new EventEmitter();

	public mediaFeatures: IMediaFeatures;

	public readonly WebcastViewSidebarButton = WebcastViewSidebarButton;
	public readonly styles = styles;

	constructor(
		private MediaFeatures: MediaFeaturesService,
		public ThemeService: ThemeService
	) {
	}

	public ngOnInit(): void {
		this.MediaFeatures.getFeatures()
			.then(result => this.mediaFeatures = result);
	}
}
