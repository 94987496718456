import { NgModule } from '@vbrick/angular-ts-decorators';

import { VideoExpirationOptionsComponent } from './VideoExpirationOptions.Component';

import FormGroupModule from 'rev-shared/ui/formGroup/FormGroup.Module';
import SharedDateModule from 'rev-shared/date/Date.Module';
import VBrickUICheckboxModule from 'rev-shared/ui/checkbox/Checkbox.Module';
import VBrickUIDatePickerModule from 'rev-shared/ui/datePicker/DatePicker.Module';
import ValidationModule from 'rev-shared/ui/validation/Validation.Module';
import VbUIRadioButtonModule from 'rev-shared/ui/radio/VbUIRadioButton.Module';
import { UISelectModule } from 'rev-shared/ui/ui-select/UISelect.Module';

@NgModule({
	id: 'VBrick.Portal.Media.Videos.VideoExpirationOptions',
	declarations: [
		VideoExpirationOptionsComponent
	],
	imports: [
		FormGroupModule,
		SharedDateModule,
		UISelectModule,
		VBrickUICheckboxModule,
		VBrickUIDatePickerModule,
		ValidationModule,
		VbUIRadioButtonModule
	]
})

export default class VideoExpirationOptionsModule {}
