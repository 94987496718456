import { Directive, HostListener, Input } from '@angular/core';

//Polyfill for scroll-behavior: smooth;
import * as smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();

/**
 * Directive to allow #id linking behaviour
 * <a vb-anchor-link="css-selector">Link</a>
 */
@Directive({
	selector: '[vbAnchorLink]'
})
export class AnchorLinkDirective {
	@Input() public vbAnchorLink: string;
	@Input() public vbAnchorLinkOffset: number;

	@HostListener('click', ['$event'])
	public onClick(e: Event): void {
		e.preventDefault();
		const target = document.querySelector<HTMLElement>(this.vbAnchorLink);

		window.scrollTo({
			top: target.offsetTop + (+this.vbAnchorLinkOffset || 0),
			left: 0,
			behavior: 'smooth'
		});
	}

}
