import { NgModule } from '@vbrick/angular-ts-decorators';
import { StateProvider } from '@uirouter/angularjs';

import DateModule from 'rev-shared/date/Date.Module';
import DialogModule from 'rev-shared/ui/dialog/Dialog.Module';
import LegalHoldBulkEditModule from 'rev-portal/navigation/sidebar/legalHold/LegalHoldBulkEdit.Module';
import NgTagsInputImportModule from 'rev-shared/ui/ngTagsInput/NgTagsInput.Module';
import PushDowngradeModule from 'rev-shared/push/Push.downgrade.Module';
import SearchModule from 'rev-portal/search/Search.Module';
import UiRouterUtilsModule from 'rev-shared/uiRouterUtils/UiRouterUtils.Module';
import ValidationModule from 'rev-shared/ui/validation/Validation.Module';
import VBrickFormDirectivesModule from 'rev-shared/util/directives/form/FormDirectives.Module';
import VBrickInsightModule from 'rev-shared/ui/insight/Insight.Module';
import VBrickSharedMediaDowngradeModule from 'rev-shared/media/Media.Module.downgrade';
import VBrickSharedUtilModule from 'rev-shared/util/Util.Module';
import VideoExpirationOptionsModule from 'rev-portal/media/videos/videoExpirationOptions/VideoExpirationOptions.Module';
import { SharedMetadataModule } from 'rev-shared/metadata/Metadata.Module';
import { TranslationsModule } from 'rev-shared/ui/translations/Translations.Module';

import { dialogCfg } from './BulkEdit.StateConfig';
import { StateConfig } from './StateConfig';

import { BulkEditFormStateService } from './BulkEditFormState.Service';
import { BulkEditService } from './BulkEdit.Service';
import { VideoSelectionModelService } from './VideoSelectionModel.Service';

import { BulkEditSubmissionDialogComponent } from './BulkEditSubmissionDialog.Component';
import { BulkEditVideosComponent } from './BulkEditVideos.Component';
import { VbDeleteVideosButtonComponent } from './VbDeleteVideosButton.Component';

@NgModule({
	id: 'VBrick.Portal.Media.VideoBulkEdit',
	declarations: [
		BulkEditSubmissionDialogComponent,
		BulkEditVideosComponent,
		VbDeleteVideosButtonComponent
	],
	imports: [
		DateModule,
		DialogModule.forChild(dialogCfg),
		LegalHoldBulkEditModule,
		NgTagsInputImportModule,
		PushDowngradeModule,
		SearchModule,
		SharedMetadataModule,
		TranslationsModule,
		UiRouterUtilsModule,
		ValidationModule,
		VBrickFormDirectivesModule,
		VBrickInsightModule,
		VBrickSharedMediaDowngradeModule,
		VBrickSharedUtilModule,
		VideoExpirationOptionsModule
	],
	providers: [
		BulkEditService,
		BulkEditFormStateService,
		VideoSelectionModelService
	]
})
export class BulkEditModule {
	public static config(
		$stateProvider: StateProvider
	): void {
		'ngInject';

		StateConfig($stateProvider);
	}
}
