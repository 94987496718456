<vb-title [title]="webcastInfo.title"></vb-title>

<div [webcastBackground]="webcastInfo.backgroundImages" [isBackgroundFill]="webcastInfo.isBackgroundFill" [isBackgroundFixed]="true">
</div>

<vb-loading-spinner *ngIf="status.processing" [msg]="'Loading' | translate" [size]="'large'">
</vb-loading-spinner>

<div [ngClass]="styles.formContainer">
	<form name="form" #form="ngForm" [ngClass]="styles.form" [hidden]="!status.active" (submit)="submit()">

		<branding-logo [ngClass]="styles.logo" [hidden]="isPreview"></branding-logo>

		<ng-content></ng-content>

		<header>
			<h1 class="text-align-center">{{webcastInfo.title}}</h1>

			<div class="text-align-center" [ngClass]="styles.small">
				{{webcastInfo.startDate | vbDateTimeMedium}} - {{webcastInfo.endDate | vbDateTimeMedium}}
			</div>

			<vb-ui-toggle-content-display [minHeight]="85" class="padding-top-10" [ngClass]="styles.small">
				<div [hidden]="!(webcastInfo.description)" [innerHTML]="webcastInfo.description | safeHtml" #vbUiToggleItem>
				</div>
			</vb-ui-toggle-content-display>
		</header>

		<div [ngClass]="styles.main">
			<div [ngClass]="styles.left">
				<h2 class="util-uppercase">{{'Event_Registration_ExistingUsers' | translate}}</h2>
				<div>{{'Event_Registration_AlreadyRegistered' | translate}}</div>
				<button type="button" class="margin-top-10" vbUiBtnPrimary (click)="signInUser()">
					{{'SignIn' | translate}}
				</button>
			</div>

			<div [ngClass]="styles.right">
				<h2 class="util-uppercase">{{'Guests'}}</h2>

				{{ 'Event_Registration_GdprDisclaimer' | translate }}

				<div class="system-msg error-msg" *ngIf="error" [ngSwitch]="error">
					<ng-container *ngSwitchCase="'AttendeeRemoved'">
						<span class="glyphicons circle_exclamation_mark"></span>
						{{ 'Event_AttendeeRemovedFromWebcast' | translate }}
					</ng-container>
					<ng-container *ngSwitchCase="'Unknown'">
						<span class="glyphicons remove_2"></span>
						{{ 'Event_Registration_SignInError' | translate }}
						<span [hidden]="!(webcastInfo.isPasswordRequired)">{{ 'Event_Registration_SignInError_VerifyPassword' | translate }}</span>
					</ng-container>
				</div>

				<vb-ui-form-group>
					<input type="text" class="form-control input-with-icon" name="name" #name="ngModel" [placeholder]="'Event_Registration_Name' | translate " [attr.aria-label]="'Event_Registration_Name' | translate " autofocus vbRequired autocomplete="name" [(ngModel)]="formData.name" uiControl vbUiTextInput trim="blur"/>
					<div *ngIf="name.touched && name.invalid" class="margin-bottom-10" role="alert" vbUiErrorMessage uiValidation>
						<ng-container *ngIf="name.errors.required">
							{{ 'ThisFieldIsRequired' | translate }}
						</ng-container>
					</div>
				</vb-ui-form-group>

				<vb-ui-form-group>
					<input type="text" class="form-control input-with-icon" name="email" #email="ngModel" [placeholder]="'Event_Registration_Email' | translate " [attr.aria-label]="'Event_Registration_Email' | translate " vbRequired autocomplete="email" [(ngModel)]="formData.email" vbEmail uiControl vbUiTextInput trim="blur"/>
					<div *ngIf="email.touched && email.invalid" class="margin-bottom-10" role="alert" vbUiErrorMessage uiValidation>
						<ng-container *ngIf="email.errors.required">
							{{ 'ThisFieldIsRequired' | translate }}
						</ng-container>
						<ng-container *ngIf="email.errors.vbEmail">
							{{ 'Event_Registration_Email_Invalid' | translate }}
						</ng-container>
					</div>
				</vb-ui-form-group>

				<vb-ui-form-group *ngIf="webcastInfo.isPasswordRequired">
					<input type="text" class="form-control input-with-icon" name="password" #password="ngModel" [placeholder]="'Event_Registration_Password' | translate " [attr.aria-label]="'Event_Registration_Password' | translate " vbRequired autocomplete="new-password" [(ngModel)]="formData.password" uiControl vbUiTextInput trim="blur"/>

					<div *ngIf="password.touched && password.invalid" class="margin-bottom-10" role="alert" vbUiErrorMessage uiValidation>
						<ng-container *ngIf="password.errors.required">
							{{ 'ThisFieldIsRequired' | translate }}
						</ng-container>
					</div>
				</vb-ui-form-group>
				<ng-container *ngFor="let field of webcastInfo.registrationFields; index as index">
					<ng-container [ngSwitch]="field.fieldType">

						<vb-ui-form-group *ngSwitchCase="'Text'">
							<input type="text" class="form-control" [name]="'customField' + index" [(ngModel)]="field.value" [vbRequired]="field.required" [placeholder]="field.name " [attr.aria-label]="field.name " uiControl vbUiTextInput trim="blur"/>
								<div *ngIf="showFieldError(index)" class="margin-bottom-10" role="alert" vbUiErrorMessage uiValidation>
									{{ 'ThisFieldIsRequired' | translate }}
								</div>
						</vb-ui-form-group>
						<vb-ui-form-group *ngSwitchCase="'Select'">
							<select class="form-control" [name]="'customField' + index" [(ngModel)]="field.value" [required]="field.required" [attr.aria-label]="field.name" uiControl>
								<option selected="selected" value="">{{ field.name }}</option>
								<option *ngFor="let option of field.options" [value]="option">
									{{option}}
								</option>
							</select>
							<div *ngIf="showFieldError(index)" class="margin-bottom-10" role="alert" vbUiErrorMessage uiValidation>
								{{ 'ThisFieldIsRequired' | translate }}
							</div>
						</vb-ui-form-group>
					</ng-container>
				</ng-container>


				<div class="light-txt">
					<vb-ui-checkbox #gdpr="ngModel" name="gdprConsent" [(ngModel)]="formData.gdprConsent">
						{{ 'Event_Registration_GdprConsent' | translate }}
					</vb-ui-checkbox>
				</div>
				<div class="group">
					<button class="pull-right" type="submit" [disabled]="form.invalid" vbUiBtnPrimary>
						{{ 'Event_Registration_SignInAsGuest' | translate }}
					</button>
				</div>

			</div>
		</div>
	</form>
</div>
