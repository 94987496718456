import { Injectable } from '@vbrick/angular-ts-decorators';

import '@uirouter/angularjs/lib/legacy/resolveService';
import { UIRouterGlobals, Transition, UIInjector } from '@uirouter/angularjs';
import { isString, isFunction } from 'rev-shared/util';
@Injectable('UibResolve')
export class UibResolveService {
	constructor(
		private $resolve: any,
		private $uiRouterGlobals: UIRouterGlobals
	) {
		'ngInject';
	}

	public resolve(invocables: any, locals: any, parent: any): any {
		const transition: Transition = isFunction(invocables.$transition$) ?
			invocables.$transition$() : undefined;

		if (!transition) {
			return this.$resolve.resolve(invocables, locals, parent);
		}

		return transition.promise
			.then(() => {
				const injector: UIInjector = transition.injector();
				const inheritedResolves: any[] = transition.getResolveTokens()
					.reduce((resolvedValues: any, currentKey: any): any => {
						if (isString(currentKey)) {
							resolvedValues[currentKey] = injector.get(currentKey);
						}
						return resolvedValues;
					}, {});

				return this.$resolve.resolve(invocables, inheritedResolves, parent);
			});
	}
}
