import { Component, ViewChild } from '@angular/core';
import type { ICellRendererAngularComp } from '@ag-grid-community/angular';
import type { ICellRendererParams } from '@ag-grid-community/core';

import { VbConfirmationDialogComponent } from 'rev-shared/ui/dialog/VbConfirmationDialogAngular.Component';

import { WebcastModel } from '../model/WebcastModel';

import styles from './WebcastAttendeeRenderer.module.less';

@Component({
	selector: 'webcast-attendee-renderer',
	templateUrl: './WebcastAttendeeRenderer.Component.html'
})
export class WebcastAttendeeRendererComponent implements ICellRendererAngularComp {
	public attendee: any;
	public webcast: WebcastModel;
	public restoreAttendee: (attendee) => Promise<void>;
	public removeAttendee: (attendee) => Promise<void>;

	@ViewChild(VbConfirmationDialogComponent)
	public removeAttendeeDialog: VbConfirmationDialogComponent;

	public readonly styles = styles;

	public agInit(params: ICellRendererParams): void {
		this.updateCell(params);
	}

	public refresh(params: ICellRendererParams): boolean {
		this.updateCell(params);

		return true;
	}

	private updateCell(params: ICellRendererParams): void {
		this.attendee = params.data;
		this.webcast = params.context.webcast;
		this.restoreAttendee = params.context.restoreAttendee;
		this.removeAttendee = params.context.removeAttendee;
	}

	public openRemoveAttendeeDialog(): void {
		this.removeAttendeeDialog.open().result
			.then(() => this.removeAttendee(this.attendee))
			.then(() => this.attendee.Removed = true);
	}

	public allowRemove(): boolean
	{
		const hasPermission = (this.webcast.currentUser.isEventModerator && this.webcast.currentUser.removeAttendees)
			|| this.webcast.currentUser.isEventAdmin;

		return !this.attendee.Removed
		        && this.attendee.UserId != this.webcast.currentUser.id
				&& hasPermission
				&& !this.attendee.IsEventAdmin;
	}

	public allowRestore(): boolean
	{
		const hasPermission = !this.webcast.currentUser.isEventModerator || this.webcast.currentUser.removeAttendees;

		return this.attendee.Removed
		       && !this.attendee.IsGuest
			   && hasPermission;
	}
}
