import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { VbUiToggleContentDisplayComponent } from './VbUiToggleContentDisplay.AngularComponent';
import { ButtonsAngularModule } from '../buttons/ButtonsAngular.Module';

const exports = [
	VbUiToggleContentDisplayComponent
];

@NgModule({
	declarations: exports,
	exports,
	imports: [
		ButtonsAngularModule,
		CommonModule,
		TranslateModule
	]
})
export class VbUiToggleContentDisplayAngularModule {

}
