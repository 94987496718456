<div dropdown #notificationsDropdown="bs-dropdown" class="btn-group" *vbAuthorizationKey="'authenticated'" [ngClass]="dropdownCssClass">
	<button dropdownToggle aria-controls="notification-menu" class="btn main-nav-btn notifications dropdown-toggle" id="notifications-button" type="button" [ngClass]="{
			'open': notificationsDropdown.isOpen,
			'theme-accent-bg': unreadCount || notificationsDropdown.isOpen,
			'theme-accent-btn': !unreadCount,
			'high': isHigh
		}">
		<span class="glyphicons" [ngClass]="[
				!notificationsDropdown.isOpen || !showRemove ? 'bell' : '',
				notificationsDropdown.isOpen ? 'theme-accent-txt' : '',
				notificationsDropdown.isOpen && showRemove ? 'remove_2 rotate-360-once': '',
				glyphiconCssClass
			]" *ngIf="!unreadCount || alwaysShowIcon">
		</span>
		<span class="btn-notify notifications-count" [ngClass]="{ 'theme-accent-txt': !isHigh && (notificationsDropdown.isOpen || unreadCount) }" *ngIf="unreadCount">
			{{ notificationCount }}
		</span>
	</button>
	<div class="navdrop-menu notification-menu dropdown-menu" id="notification-menu" role="menu" aria-labelledby="notifications-button">
		<notifications-menu-content class="theme-accent" (close)="notificationsDropdown.isOpen = false">
		</notifications-menu-content>
	</div>
</div>

