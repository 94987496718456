import { WebExService } from './WebEx.Service';

import { IWebexSiteSettings } from 'rev-portal/admin/mediaSettings/mediaFeatures/IWebexSiteSettings';

import {
	Component,
	Input,
	OnInit,
	Output
} from '@vbrick/angular-ts-decorators';

import './import-webex-dialog.less';
import styles from './ImportWebex.module.less';

@Component({
	selector: 'import-webex-dialog',
	templateUrl: '/partials/media/import/import-webex-dialog.html'
})
export class ImportWebexDialogComponent implements OnInit {
	@Input() public resolve: { dialogParams: { webexSitesSettings: IWebexSiteSettings[]; teamId: string } };
	@Output() public close: () => void;

	private errorWebExGeneric: boolean;
	private errorWebExLogin: boolean;
	private errorWebExSso: boolean;
	private isLoading: boolean;
	private isLoggedIn: boolean;
	private noWebexRecordingsFound: boolean;
	private selectedWebExImports: any[];
	private selectionModel: { mode: string };
	private teamId: string;
	private webExPassword: string;
	private webExRecordings: any[];
	private webExUsername: string;

	public readonly styles = styles;

	public isSelectAll: boolean;
	public showWebexSitesList: boolean;
	public selectedSite: IWebexSiteSettings;
	public webexSites: IWebexSiteSettings[];

	constructor(
		private WebExService: WebExService
	) {
		'ngInject';
	}

	public ngOnInit(): void {
		this.errorWebExGeneric = false;
		this.errorWebExLogin = false;
		this.errorWebExSso = false;
		this.isLoading = false;
		this.isLoggedIn = false;
		this.noWebexRecordingsFound = false;
		this.selectedWebExImports = [];
		this.webexSites = this.resolve.dialogParams.webexSitesSettings || [];
		this.showWebexSitesList = this.webexSites.length > 1;
		this.selectedSite = this.webexSites.length === 1 ? this.webexSites[0] : undefined;
		this.selectionModel = {
			mode: 'multiple'
		};
		this.teamId = this.resolve.dialogParams.teamId;

		if (this.hideLoginView) {
			this.login();
		}
	}

	public get hideLoginView(): boolean {
		return this.selectedSite?.isSsoEnabled || this.selectedSite?.isCiEnabled;
	}

	public onRowSelectionChange(): void {
		this.isSelectAll = this.selectedWebExImports.length === this.webExRecordings.length;
	}

	public onSiteSelect(): void {
		this.showWebexSitesList = false;
		this.clearLoginInfo();
		this.clearError();

		if (this.hideLoginView) {
			this.login();
		}
	}

	public onBackToWebexSitesList(): void {
		this.showWebexSitesList = true;
	}

	private getImportedColumnIconClass(item: { isImported: boolean }): string {
		return item.isImported ? 'glyphicons circle_ok' : '';
	}

	private import(): void {
		//loading state to prevent dupes
		this.isLoading = true;

		//import the selected recordings
		this.selectedWebExImports.forEach(selectedImport =>
			this.WebExService.importRecording(this.selectedSite.id, selectedImport.id, selectedImport.recordingName, selectedImport.recordingFileUrl, this.teamId)
		);

		//close the modal
		this.close();
	}

	private login(): void {
		this.isLoading = true;
		this.errorWebExLogin = false;
		this.errorWebExGeneric = false;

		this.WebExService.getRecordings(this.selectedSite.id, this.webExUsername, this.webExPassword, this.teamId)
			.then(result => {
				this.isLoggedIn = true;
				this.webExRecordings = result;
			}).catch(error => { //handle login failure and potentially other error
				if (error.status === 401) {
					this.errorWebExLogin = true;
				} else if(error.data.code === 'NoWebexRecordingsFound') {
					this.noWebexRecordingsFound = true;
					this.errorWebExGeneric = true;
				} else if(error.data.code === 'InvalidWebexUser') {
					// SSO Login fails
					this.errorWebExSso = true;
				}
				else {
					this.errorWebExGeneric = true;
				}
			}).finally(() => {
				this.isLoading = false;
			});
	}

	private clearError(): void {
		this.errorWebExGeneric = false;
		this.errorWebExLogin = false;
		this.errorWebExSso = false;
		this.noWebexRecordingsFound = false;
	}

	private clearLoginInfo(): void {
		this.isLoggedIn = false;
		this.webExUsername = undefined;
		this.webExPassword = undefined;
	}
}
