import { NgModule } from '@vbrick/angular-ts-decorators';

import { UnlistedVideoIndicatorComponent } from './UnlistedVideoIndicator.Component';
import { downgradeComponents } from 'rev-shared/util/AngularHybridUtils';

@NgModule({
	id: 'VBrick.Shared.Media.UnlistedVideoIndicator'
})
export default class UnlistedVideoIndicatorModule {}

downgradeComponents(UnlistedVideoIndicatorModule, [{
	name: 'unlistedVideoIndicator',
	component: UnlistedVideoIndicatorComponent
}]);
