import {
	Component
} from '@vbrick/angular-ts-decorators';

import { MainHeaderPositionService } from './navigation/mainHeader/MainHeaderPosition.Service';

@Component({
	selector: 'portal',
	templateUrl: '/partials/portal/portal.html'
})
export class PortalComponent {
	constructor(
		private MainHeaderPosition: MainHeaderPositionService
	) {
		'ngInject';
	}
}
