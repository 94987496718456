import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ScrollingModule } from '@angular/cdk/scrolling';

import { TranslateModule } from '@ngx-translate/core';

import { ButtonsAngularModule } from 'rev-shared/ui/buttons/ButtonsAngular.Module';
import { DateAngularModule } from 'rev-shared/date/DateAngular.Module';
import { DirectivesAngularModule } from 'rev-shared/util/directives/DirectivesAngular.Module';
import { FormGroupAngularModule } from 'rev-shared/ui/formGroup/FormGroupAngular.Module';
import { PipesAngularModule } from 'rev-shared/pipes/Pipes.AngularModule';
import { SidebarLayoutModule } from 'rev-shared/ui/sidebarLayoutAngular/SidebarLayout.Module';
import { SpinnerAngularModule } from 'rev-shared/ui/spinner/SpinnerAngular.Module';
import { TextInputAngularModule } from 'rev-shared/ui/text-input/TextInputAngular.Module';
import { VbProfilePictureModule } from 'rev-shared/ui/profile/VbProfilePicture.Module';
import { VbTextAreaModule } from 'rev-shared/ui/textArea/VbTextArea.Module';
import { VbUiHtmlEditorModule } from 'rev-shared/htmlEditor/VbUiHtmlEditor.Module';

import { BrandingAngularModule } from 'rev-portal/branding/BrandingAngular.Module';
import { ScheduledEventsCommonModule } from 'rev-portal/scheduledEvents/common/ScheduledEventsCommon.AngularModule';
import { WebcastSharedComponentsModule } from 'rev-portal/scheduledEvents/webcast/sharedComponents/WebcastSharedComponents.Module';

import { VbWebcastChatSidebarComponent } from './VbWebcastChatSidebar.Component';

const components = [
	VbWebcastChatSidebarComponent,
];

@NgModule({
	declarations: components,
	entryComponents: components,
	exports: components,
	imports: [
		BrandingAngularModule,
		ButtonsAngularModule,
		CommonModule,
		DateAngularModule,
		DirectivesAngularModule,
		FormGroupAngularModule,
		FormsModule,
		VbUiHtmlEditorModule,
		PipesAngularModule,
		ScheduledEventsCommonModule,
		ScrollingModule,
		SidebarLayoutModule,
		SpinnerAngularModule,
		TextInputAngularModule,
		TranslateModule,
		VbProfilePictureModule,
		VbTextAreaModule,
		WebcastSharedComponentsModule
	]
})
export class WebcastChatModule {}
