import { StateContext } from './StateContext';
import { Directive, AfterViewInit } from '@vbrick/angular-ts-decorators';
import { IAttributes, IAugmentedJQuery } from 'angular';
import { StateService } from '@uirouter/angularjs';

/**
 * Allows a ui-sref directive to act as a toggle button.
 * Click once to navigate to the state, click again to go back to current state
 *
 * Ex:  <a ui-sref="sideview" vb-sref-toggle>...</a>
 */
@Directive({
	selector: '[vb-sref-toggle]',
	priority: 1 // forces click handler to run after ui-sref's
})
export class VbSrefToggleDirective implements AfterViewInit {
	constructor(
		private $attrs: IAttributes,
		private $element: IAugmentedJQuery,
		private $state: StateService
	) {
		'ngInject';
	}

	public ngAfterViewInit(): void {
		const toggleState: string = StateContext(this.$element).name;
		const mainState: string = this.parseSref(this.$attrs.uiSref);
		const srefOpts = this.$attrs.srefOpts;

		this.$element.click((e: JQuery.Event<HTMLElement>) => {
			if (this.$state.includes(mainState, null, { relative: toggleState })) {
				e.preventDefault();
				this.$state.go(toggleState, null, srefOpts);
			}
		});
	}

	private parseSref(sref: string): string {
		const match = sref.match(/^([^(]+?)\s*(\((.*)\))?$/);

		return match[1];
	}
}
