import { NgModule } from '@vbrick/angular-ts-decorators';

import { downgradeInjectables } from 'rev-shared/util/AngularHybridUtils';

import { ScheduledEventService } from './ScheduledEvent.Service';

@NgModule({
	id: 'shared.scheduledEvents'
})
export class SharedScheduledEventsModule {
}

downgradeInjectables(SharedScheduledEventsModule, [{
	name: 'ScheduledEventService',
	injectable: ScheduledEventService
}]);
