export const DualPlaybackLayoutOptions = [
	{
		id: 'speakerBottomRight',
		class: 'speaker-bottom-right-layout',
		iconClass: 'vb-icon vb-icon-speaker-bottom-right-layout'
	},
	{
		id: 'speakerTopRight',
		class: 'speaker-top-right-layout',
		iconClass: 'vb-icon vb-icon-speaker-top-right-layout'
	},
	{
		id: 'sideBySide',
		class: 'side-by-side-layout',
		iconClass: 'vb-icon vb-icon-side-by-side-layout'
	}
];
