import {
	Component,
	Input,
	OnChanges,
	OnInit,
	SimpleChanges
} from '@angular/core';


import { userAgentUtil } from 'vbrick-player-src/UserAgentUtil';
import { VideogularComponent } from 'vbrick-player-src/videogular/Videogular.Component';

import { isNumber } from 'rev-shared/util';
import { VbFlickityCarouselComponent } from 'rev-shared/ui/flickityCarousel/VbFlickityCarousel.AngularComponent';
import { IRules } from 'rev-shared/ui/css/CssRules.Contract';

import { BrandingSettings } from 'rev-portal/branding/BrandingSettings.Contract';

import './featured-video-player-carousel.less';

const flickityBaseConfig = {
	draggable: false, //breaks video player controls.
	lazyLoad: 1, // +1 adjacent
	wrapAround: true
};

@Component({
	selector: 'featured-video-player-carousel',
	templateUrl: './FeaturedVideoPlayerCarousel.Component.html'
})
export class FeaturedVideoPlayerCarouselComponent implements OnChanges, OnInit {
	@Input() public autoScroll: number;
	@Input() public brandingSettings: BrandingSettings;
	@Input() public videos: any[];

	private vgAPI: VideogularComponent;

	public isMobile: boolean;
	public preservedVolume: number;
	public carousel: VbFlickityCarouselComponent;
	public displayPlayer: number = -1;
	public flickityConfig: any = { ...flickityBaseConfig };
	public themeStyleOverrides: IRules;

	public get isPlayerLoaded(): boolean {
		return this.displayPlayer >= 0;
	}

	public ngOnInit(): void {
		this.isMobile = userAgentUtil.isMobile;
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if(changes.brandingSettings && this.brandingSettings) {
			const accentColor = this.brandingSettings.themeSettings.accentColor;
			const accentFontColor = this.brandingSettings.themeSettings.accentFontColor;
			this.themeStyleOverrides = {
				'featured-video-player-carousel .dot': {
					'border-color': accentFontColor
				},
				[`featured-video-player-carousel .dot.is-selected,
				featured-video-player-carousel  .flickity-prev-next-button:hover`]: {
					'background-color': accentColor
				},
				'featured-video-player-carousel .flickity-prev-next-button:hover .arrow': {
					fill: accentFontColor
				}
			};

			this.flickityConfig = {
				autoPlay: this.brandingSettings.featuredVideosSettings.autoscrollDelay * 1000,
				...flickityBaseConfig
			};
		}
	}

	public hidePlayer(): void {
		if (isNumber(this.vgAPI?.volume)) {
			this.preservedVolume = this.vgAPI.volume;
		}

		this.displayPlayer = -1;
	}

	public onPlayerReady(vgAPI: VideogularComponent): void {
		this.vgAPI = vgAPI;

		if (this.preservedVolume) {
			vgAPI.setVolume(this.preservedVolume);
		}
	}

	public onCarouselReady(carousel: VbFlickityCarouselComponent){
		this.carousel = carousel;
	}

	public onCarouselSelect(){
		if(this.isPlayerLoaded && this.displayPlayer !== this.carousel.selectedIndex){
			this.hidePlayer();
		}
	}

	public showPlayer(index: number): void {
		this.displayPlayer = index;

		if (this.isMobile) {
			this.vgAPI.play();
		}
	}
}
