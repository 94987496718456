import {
	Component,
	Input,
	OnChanges,
	SimpleChanges
} from '@angular/core';

import { VideogularComponent } from '@vbrick/vbrick-player/app/player/videogular/Videogular.Component';
import { isIe, isMobileSafari } from 'rev-shared/util/UserAgentUtil';

import { WebcastModel } from 'rev-portal/scheduledEvents/webcast/model/WebcastModel';

import styles from './VbWebcastVideo.Component.module.less';

@Component({
	selector: 'vb-webcast-video',
	templateUrl: './VbWebcastVideo.Component.html',
	host: {
		class: 'box-block'
	}
})
export class VbWebcastVideoComponent implements OnChanges {
	@Input() public webcast: WebcastModel;
	@Input() public isStopped: boolean;

	private isInitialized: boolean;
	private vgAPI: VideogularComponent;

	public readonly styles = styles;
	public disposePlayer: boolean;
	public height: number;
	public width: number;

	public browser = {
		msie: isIe(), //clean up later if not using anywhere.
		isIosSafari: isMobileSafari()
	};

	public ngOnInit(): void {
		setTimeout(() => this.isInitialized = true, 1000);
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if(changes.isStopped) {
			//In case of native playback, if video element is not removed from dom..It keeps fetching playlist
			// and show old video after broadcast enabled.
			this.disposePlayer = this.browser.isIosSafari && this.isStopped;

			//iOS autoplay not working as expected. It's making the video freeze.
			if (this.vgAPI && !this.browser.isIosSafari) {
				if(!this.isStopped &&
					this.isInitialized &&
					this.webcast.webcastStatus.hasBroadcasted) {
					setTimeout(() => this.vgAPI.play(), 200); //need a timeout to trigger a play after player is settled.
				}
				else {
					this.vgAPI.stop();
				}
			}
		}
	}

	public onPlayerReady(vgAPI: any): void {
		this.vgAPI = this.webcast.video.vgAPI = vgAPI;
	}

	public onFullscreenToggled(isFullscreen: boolean): void {
		this.webcast.layout.isFullscreenVideo = isFullscreen;
	}
}
