import {
	AfterViewInit,
	Component,
	Input,
	OnInit,
	ViewChild
} from '@angular/core';

import { AnalyticsService } from 'rev-shared/analytics/Analytics.Service';
import { SecondMs } from 'rev-shared/date/Time.Constant';
import { VbUiInfiniteScrollGridComponent } from 'rev-shared/ui/dataGrid/infiniteScroll/VbUiInfiniteScrollGrid.Component';

import { AttendeesListService } from './AttendeesList.Service';
import { WebcastModel } from '../model/WebcastModel';
import { WebcastAttendeeRendererComponent } from './WebcastAttendeeRenderer.Component';

import './webcast-attendees-list.less';

@Component({
	selector: 'webcast-attendees-list',
	templateUrl: './WebcastAttendeesList.Component.html'
})
export class WebcastAttendeesListComponent implements AfterViewInit, OnInit {
	@Input() public accountId: string;
	@Input() public countPollingIntervalInSec: number;
	@Input() public listPollingIntervalInSec: number;
	@Input() public webcast: WebcastModel;

	public readonly WebcastAttendeeRendererComponent = WebcastAttendeeRendererComponent;
	public readonly pageSize = 50;

	public attendeeCount: number;
	@ViewChild(VbUiInfiniteScrollGridComponent)
	public grid: VbUiInfiniteScrollGridComponent;
	public searchInput: string;
	public isGridReady: boolean;

	private attendeeCountIntervalRef: number;

	constructor(
		public AnalyticsService: AnalyticsService,
		public AttendeeService: AttendeesListService
	) {}

	public ngOnInit(): void {
		this.countPollingIntervalInSec = this.countPollingIntervalInSec || this.AnalyticsService.kpiUpdateIntervalInSecs;
		this.listPollingIntervalInSec = this.listPollingIntervalInSec || this.AnalyticsService.tableUpdateIntervalInSecs;

		this.AttendeeService.initAttendeesView(this.webcast.id, this.webcast.currentRun.runNumber);
		this.pollAttendeeCount();
	}

	public ngOnDestroy(): void {
		if (this.attendeeCountIntervalRef) {
			window.clearInterval(this.attendeeCountIntervalRef);
			this.attendeeCountIntervalRef = undefined;
		}
	}

	public ngAfterViewInit(): void {
		this.grid.rowHeight = 40;
		this.grid.pollingIntervalSecs = this.listPollingIntervalInSec;
		this.grid.pageSize = this.pageSize;
		this.grid.headerHeight = 0;
		this.grid.context = {
			...this.grid.context,
			webcast: this.webcast,
			restoreAttendee: attendee => this.handleRemoveAndRestoreAttendee(true, attendee),
			removeAttendee: attendee => this.handleRemoveAndRestoreAttendee(false, attendee)
		};
		this.grid.defaultColDef = {
			...this.grid.defaultColDef,
			cellRendererFramework: WebcastAttendeeRendererComponent
		};
	}

	public onGridReady(): void {
		this.isGridReady = true;
	}

	private handleRemoveAndRestoreAttendee(isRestore: boolean, attendee: any): void {
		this.grid.pausePolling();

		(isRestore ?
			this.AttendeeService.restoreAttendee(this.webcast.id, this.webcast.currentRun.runNumber, attendee) :
			this.AttendeeService.removeAttendee(this.webcast.id, this.webcast.currentRun.runNumber, attendee)
		)
			.then(() => attendee.Removed = !isRestore)
			.finally(() => this.resumeAttendeeListPolling());
	}

	private resumeAttendeeListPolling(): void {
		setTimeout(() => this.grid.resumePolling(), this.listPollingIntervalInSec * SecondMs);
	}

	private pollAttendeeCount(): void {
		this.loadAttendeeCount();
		this.attendeeCountIntervalRef = window.setInterval(() => this.loadAttendeeCount(), this.countPollingIntervalInSec * SecondMs);
	}

	private loadAttendeeCount(): void {
		this.AttendeeService.getAttendeeCount(this.webcast.id, this.webcast.currentRun.runNumber)
			.then(count => this.attendeeCount = count);
	}
}
