import { NgModule } from '@vbrick/angular-ts-decorators';

import SearchModule from 'rev-portal/search/Search.Module';
import { VbVideoPlayerComponent } from 'rev-shared/videoPlayer/VbVideoPlayer.Component';
import VBrickDateModule from 'rev-shared/date/Date.Module';
import VBrickPushModule from 'rev-shared/push/Push.downgrade.Module';
import VBrickSecurityModule from 'rev-shared/security/Security.Module';
import VBrickSharedVideoPlayerModule from 'rev-shared/videoPlayer/VideoPlayer.Module';
import VBrickVideoChapterModule from 'rev-shared/media/videoChapter/VideoChapter.Module.downgrade';

import { ApprovalProcessService } from './approvalProcess/ApprovalProcess.Service';
import { CategoryService } from './Category.Service';
import { ExpiryRuleSvc } from './ExpiryRule.Service';
import { ImageService } from 'rev-shared/media/Image.Service';
import { MediaFeaturesService } from './MediaFeatures.Service';
import { PlaylistService } from './Playlist.Service';
import { VideoService } from './Video.Service';
import { VodPlayerLoggingService } from './VodPlayerLogging.Service';

import { CategoryParentPathPipe } from 'rev-shared/media/CategoryParentPath.Pipe';
import { downgradeInjectables, downgradeComponents } from 'rev-shared/util/AngularHybridUtils';

@NgModule({
	id: 'VBrick.Shared.Media',
	declarations: [
		CategoryParentPathPipe
	],
	imports: [
		SearchModule,
		VBrickDateModule,
		VBrickPushModule,
		VBrickSecurityModule,
		VBrickSharedVideoPlayerModule,
		VBrickVideoChapterModule
	],
	providers: [
		ExpiryRuleSvc
	]
})
export default class VBrickSharedMediaDowngradeModule {}

downgradeComponents(VBrickSharedMediaDowngradeModule, [
	{
		component: VbVideoPlayerComponent,
		name: 'vbVideoPlayer'
	}
]);

downgradeInjectables(VBrickSharedMediaDowngradeModule, [
	{
		injectable: ApprovalProcessService,
		name: 'ApprovalProcessService'
	},
	{
		injectable: CategoryService,
		name: 'CategoryService'
	},
	{
		injectable: ImageService,
		name: 'ImageService'
	},
	{
		injectable: MediaFeaturesService,
		name: 'MediaFeatures'
	},
	{
		injectable: PlaylistService,
		name: 'PlaylistService'
	},
	{
		injectable: VideoService,
		name: 'VideoService'
	},
	{
		injectable: VodPlayerLoggingService,
		name: 'VodPlayerLogging'
	}
]);
