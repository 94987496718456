import { Component, Input } from '@angular/core';

import styles from './WebcastReports.module.less';

@Component({
	selector: 'webcast-engagement-report',
	templateUrl: './WebcastEngagementReport.Component.html'
})
export class WebcastEngagementReportComponent {
	@Input() public estimatedAttendees: number;
	@Input() public eventSummary: any;
	@Input() public pollResults: any;

	public readonly styles = styles;
}
