import {
	Directive,
	OnInit
} from '@vbrick/angular-ts-decorators';
import { IAugmentedJQuery } from 'angular';

import styles from './vb-text-input.module.less';

@Directive({
	selector: '[vb-text-input]'
})

export class VBrickTextInputDirective implements OnInit {

	constructor(
		private $element: IAugmentedJQuery,
	) {
		'ngInject';
	}

	public ngOnInit(): void {
		this.$element.addClass([
			styles.TextInput,
			styles.TextInputFocusBorder,
			'theme-accent-border-focus'
		].join(' '));
	}
}
