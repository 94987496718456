import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AmChartsCoreModule } from 'rev-shared/ui/dataVisualization/amChartsCore/AmChartsCore.Module';

import { VbUiBarChartComponent } from './VbUiBarChart.Component';
import { VbUiBarChartSmallComponent } from './VbUiBarChartSmall.Component';
import { VbUiStackedBarChartComponent } from './stackedBarChart/VbUiStackedBarChart.Component';

const components = [
	VbUiBarChartComponent,
	VbUiBarChartSmallComponent,
	VbUiStackedBarChartComponent
];

@NgModule({
	id: 'Vbrick.UI.DataVisualization.BarChart',
	declarations: components,
	entryComponents: components,
	exports: components,
	imports: [
		CommonModule,
		AmChartsCoreModule
	]
})
export class BarChartModule {}
