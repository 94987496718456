import { NgModule } from '@vbrick/angular-ts-decorators';
import 'angular-vs-repeat/dist/angular-vs-repeat.js';

import VBrickFormDirectivesModule from 'rev-shared/util/directives/form/FormDirectives.Module';

import { MultiselectComponent } from './Multiselect.Component';

@NgModule({
	id: 'VBrick.UI.Multiselect',
	imports: [
		'vs-repeat',
		VBrickFormDirectivesModule
	],
	declarations: [
		MultiselectComponent
	]
})
export class MultiselectModule {}
