<ng-container *ngIf="(attendeeQuestionsContext$ | async) as attendeeQuestions">
	<vb-ui-tab-panel flex="fill" [allowSelectedTabClick]="true" [verticalFill]="true" [themed]="true" (onSelect)="onTabSelect($event.tabId)">

		<vb-ui-tab #myQuestions [tabId]="myQuestionContext" [heading]="'Event_MyQuestionsAndAnswersWithCount' | translate : { '0': attendeeQuestions.myQACount }" [attr.aria-label]="'Event_MyQuestionsAndAnswersWithCount' | translate : { '0': attendeeQuestions.myQACount }">

			<vb-webcast-ask-question [webcast]="webcast" (onQuestionAsked)="onQuestionAsked($event.question)">
			</vb-webcast-ask-question>
			<div [hidden]="attendeeQuestions.questions.length" [ngClass]="styles.noQuestionAsked">{{ 'Event_QA_NoAsks' | translate }}</div>

			<vb-webcast-questions-list *ngIf="attendeeQuestions.questions.length" [webcast]="webcast" [questions]="attendeeQuestions.questions" [questionContext]="myQuestionContext">
			</vb-webcast-questions-list>

		</vb-ui-tab>

		<vb-ui-tab #groupQuestions [tabId]="groupQuestionContext" [heading]="'Event_GroupQuestionsAndAnswersWithCount' | translate : { '0': attendeeQuestions.groupQACount }" [attr.aria-label]="'Event_GroupQuestionsAndAnswersWithCount' | translate : { '0': attendeeQuestions.groupQACount }">

			<div [hidden]="attendeeQuestions.publicQuestions.length" [ngClass]="styles.noQuestionAsked">{{ 'Event_QA_NoAsksGroups' | translate }}</div>

			<vb-webcast-questions-list *ngIf="groupQuestions.isActive && attendeeQuestions.publicQuestions.length" [webcast]="webcast" [questions]="attendeeQuestions.publicQuestions" [questionContext]="groupQuestionContext">
			</vb-webcast-questions-list>

		</vb-ui-tab>
	</vb-ui-tab-panel>
</ng-container>

