import {
	Component,
	Input
} from '@vbrick/angular-ts-decorators';
import { IPromise } from 'angular';
import { StateService } from '@uirouter/angularjs';

import { MediaStateService, MediaViewMode } from 'rev-portal/media/MediaState.Service';
import { ThemeService } from 'rev-portal/branding/Theme.Service';
import { SearchService } from 'rev-portal/search/Search.Service';

import { isString } from 'rev-shared/util';
import { UserContextService } from 'rev-shared/security/UserContext.Service';

import './search-header.less';

const TypeaheadResultSize = 10;

@Component({
	selector: 'search-header',
	templateUrl: '/partials/portal/navigation/search/search-header.html'
})

export class SearchHeaderComponent {
	@Input() public showSearchText: boolean;
	@Input() public buttonCssClasses: string;

	private readonly TypeaheadMinLength = 1;
	private loadTypeaheadVideos = query => this.queryVideos(query);
	private getTitle = video => video.title;

	constructor(
		private $state: StateService,
		private MediaStateService: MediaStateService,
		private ThemeService: ThemeService,
		private SearchService: SearchService,
		private UserContext: UserContextService
	){
		'ngInject';
	}

	public submitMediaSearch(value: { title: string }): void {
		if(!value) {
			return;
		}
		const searchVal = isString(value) ? value : value.title;

		this.MediaStateService.setViewMode(MediaViewMode.TABLE);
		this.$state.go('portal.media.search', { q: searchVal }, { inherit: false, reload: true });
	}

	public queryVideos(query: string): IPromise<any> {
		return this.SearchService.getVideoSuggestions({
			query: query.toLowerCase(),
			accountId: this.UserContext.getAccount().id,
			count: TypeaheadResultSize,
			noScroll: true
		})
			.then(({ videos }) => videos);
	}

	public getButtonCssClasses(): string {
		return `btn main-nav-btn theme-accent-btn ${this.buttonCssClasses}`;
	}
}
