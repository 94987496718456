<dashboard-carousel [items]="events" [headerText]="'Dashboard_UpcomingEvents' | translate" headerSref="portal.scheduledEvents">

	<div class="carousel-cell" [ngClass]="styles.carouselCellItem" *ngFor="let item of events">

		<section [ngClass]="styles.carouselCell">

			<div [ngClass]="styles.imageWrapper">

				<div class="fixed-ratio-wrapper" [ngClass]="styles.fixedRatioWrapper">
					<div class="fixed-ratio">
						<a uiSref="portal.scheduledEvents.webcast" [uiParams]="{webcastId: item.id}" class="fixed-aspect-item">
							<img [alt]="'ThumbnailForAria' | translate : { '0': item.title }" class="preview-image anchored-thumbnail" [attr.data-flickity-lazyload]="item.backgroundImageUri || '/shared/img/calendar-thumbnail.png'">
						</a>
					</div>
				</div>

				<div [ngClass]="styles.infoContainer">
						<h4>
							<a [title]="item.title" uiSref="portal.scheduledEvents.webcast" [uiParams]="{webcastId: item.id}">
								{{item.title}}
							</a>
						</h4>
						<h5 [title]="(item.startDate | vbDateTimeMedium) + ' - ' + (item.endDate | vbTimeShort)">
							{{(item.startDate | vbDateTimeMedium) + ' - ' + (item.endDate | vbTimeShort)}}
						</h5>
				</div>
			</div>
		</section>
	</div>
</dashboard-carousel>
