import { Transition } from '@uirouter/angularjs';
import { IQService } from 'angular';

import { SecurityContextService } from 'rev-shared/security/SecurityContext.Service';

import { TeamService } from './Team.Service';

export default class TeamHandler {

	public static getSharedTeamState() {
		return {
			component: 'manageTeam',
			resolve: {
				teamId($transition$: Transition): string {
					'ngInject';

					return $transition$.params().teamId;
				},
				portalTeamAdminAccess(SecurityContext: SecurityContextService): boolean {
					'ngInject';
					return SecurityContext.checkAuthorization('admin.media.teams');
				},
				team(
					$transition$: Transition,
					$q: IQService,
					portalTeamAdminAccess: boolean,
					teamId: string,
					SecurityContext: SecurityContextService,
					TeamService: TeamService,
				): any {
					'ngInject';
					if (teamId) {
						return TeamService.loadTeam(teamId);
					}

					const isAuthorizedToAddTeam: boolean = portalTeamAdminAccess
						|| SecurityContext.checkAuthorization('teams.add');

					if (!isAuthorizedToAddTeam) {
						return $q.reject({ status: 401 });
					}
				}
			},
			onExit(TeamService: TeamService, teamId: string): void {
				'ngInject';

				TeamService.unloadTeam(teamId);
			}
		};
	}
}
