import { NgModule } from '@vbrick/angular-ts-decorators';
import CollapseModule from 'angular-ui-bootstrap/src/collapse';

import { CollapsableSectionComponent } from './CollapsableSection.Component';

@NgModule({
	id: 'VBrick.UI.CollapsableSection',
	declarations: [
		CollapsableSectionComponent
	],
	imports: [
		CollapseModule
	]
})
export class CollapsableSectionModule {}
