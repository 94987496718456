import { IModalService, IModalInstanceService } from 'angular-ui-bootstrap';
import { IDialogProvider, IDialogService, IDialog } from './IDialog';
import {
	constant as _constant
} from 'underscore';

export class DialogProvider implements IDialogProvider {
	public configs: any = {};

	public when(dialogName: string, config: any): IDialogProvider {
		if(this.configs[dialogName]) {
			throw new Error('Dialog ' + dialogName + ' already in use');
		}

		this.configs[dialogName] = Object.assign({
			windowClass: 'back-drop'
		}, config);

		return this;
	}

	public $get($uibModal: IModalService): IDialogService {
		'ngInject';

		return {
			getDialog: (name: string): IDialog => {
				const config: any = this.configs[name];
				if(!config) {
					throw new Error('Unknown Dialog Type: ' + name);
				}
				return {
					open: (dialogParams: any, options: any): IModalInstanceService => {
						dialogParams = dialogParams || {};
						options = options || {};
						options = Object.assign({}, config, options);
						options.resolve = Object.assign(options.resolve || {}, {
							dialogParams: _constant(dialogParams)
						});

						const dialog = $uibModal.open(options);

						dialog.opened.catch(err => {
							console.error('Error opening dialog', name, err, err && err.stack);
						});

						dialog.rendered.catch(err => {
							console.error('Error rendering dialog ', name, err, err && err.stack);
						});
						return dialog;
					}
				};
			}
		};
	}

}
