<div *ngIf="isFullscreen" [webcastBackground]="webcast.backgroundImages" [isBackgroundFill]="webcast.isBackgroundFill" class="presentation-webcast-background">

	<vb-title [title]="'Event_Webcast_Region_Presentation_Details' | translate : { '0': webcast.title }"></vb-title>

</div>

<div layout="column" flex-wrap="false" class="resize-fill presentation-column theme-primary-font-border-fade-50" [hidden]="!(webcast.presentation.ready &&
		(webcast.currentRun.isStarted || webcast.currentUser.isEventAdmin))" [ngClass]="{'presentation-is-full-screen': isFullscreen}">

	<webcast-pre-prod-banner *ngIf="isFullscreen" [webcast]="webcast">
	</webcast-pre-prod-banner>

	<div flex class="hidden-xs">
		<div layout="row" navbar-padding="100" flex-justify="center" flex-align="center" class="webcast-toolbar theme-primary theme-primary-font-border-fade-50">
			<div flex="fill">
				{{ 'Event_Webcast_Region_Presentation' | translate }} {{webcast.presentation.currentSlideIndex+1}} / {{webcast.presentation.slides.length}}
			</div>
			<div flex [hidden]="isFullscreen">
				<a uiSref="webcast-fullscreen.presentation" [uiParams]="{webcastId: webcast.id}" target="_blank" class="hidden-md hidden-sm hidden-xs" vbUiBtnSecondaryNgx>
					<span class="glyphicons new_window" [attr.title]="'Event_OpenInNewWindow' | translate " [attr.aria-label]="'Event_OpenInNewWindow' | translate">
					</span>
				</a>
			</div>
		</div>
	</div>

	<div flex-justify="center" flex-align="center" layout="column" layout-padding="100" class="video-height-container" [vbWatchDimensions]="[presentationSlideForgivingImage.img]" [naturalRatio]="true">
		<div style="width: 100%; position: relative"> <!-- IE style: 100%-->
			<!--Current slide-->
			<vb-ui-forgiving-image [alt]="'PresentationSlideInProgress' | translate" class="presentation-slide" #presentationSlideForgivingImage [retryButtonLabel]="'Retry' | translate" [src]="webcast.presentation.currentSlide?.uri">
			</vb-ui-forgiving-image>

			<!--Slide controls-->
			<div *ngIf="webcast.currentUser.canControlPresentationSlides" class="presentation-controls">
				<button class="btn-transparent icon-slide-prev" [hidden]="webcast.presentation.isFirstSlide()" (click)="webcast.presentation.movePreviousSlide()" [attr.title]="'Event_PreviousSlide' | translate" [disabled]="webcast.presentation.isMoving">
						<span class="glyphicons chevron-left"></span>
				</button>

				<button class="btn-transparent icon-slide-next" [hidden]="webcast.presentation.isLastSlide()" (click)="webcast.presentation.moveNextSlide()" [attr.title]="'Event_NextSlide' | translate" [disabled]="webcast.presentation.isMoving">
						<span class="glyphicons chevron-right"></span>
				</button>
			</div>
		</div>
	</div>
</div>

<!--Presentation region when either presentation not ready or not exposed to user-->
<div layout="column" flex-wrap="false" class="resize-fill presentation-column theme-primary-font-border-fade-50" *ngIf="!webcast.presentation.ready || !webcast.currentRun.isStarted" [ngClass]="{'presentation-is-full-screen': !fullScreenUiSref}">

	<div flex class="hidden-xs">
		<div layout="row" navbar-padding="100" flex-justify="center" flex-align="center" class="webcast-toolbar theme-primary theme-primary-font-border-fade-50">
			<div flex="fill">
				{{ 'Event_Webcast_Region_Presentation' | translate }}
			</div>
		</div>
	</div>

	<div flex-justify="center" flex-align="center">
		<!--Presentation Status / Errors-->
		<div class="webcast-presentation-msg-container theme-primary" *ngIf="webcast.currentUser.canControlPresentationSlides">
			<div class="ppt-loading-wrapper" [hidden]="!webcast.presentation.processingImagesFailed">
				<h3>{{ 'Event_UnableToProcessPresentation' | translate }}</h3>
			</div>

			<div class="ppt-loading-wrapper" [hidden]="!webcast.presentation.uploadFailed">
				<h3>{{ 'Event_PresentationUploadFailed' | translate }}</h3>
			</div>
		</div>

		<div [hidden]="!webcast.presentation.processing">
			<div class="ppt-loading-wrapper">
				<vb-loading-spinner [msg]="'Event_PresentationFileProcessing' | translate" [size]="'medium'">
				</vb-loading-spinner>
			</div>
		</div>

		<!--Webcast Status Messages-->
		<div class="webcast-presentation-msg-container theme-primary" [hidden]="!webcast.currentRun.isEnded">
			<div class="ppt-loading-wrapper">
				<h3>{{ 'Event_EventHasEnded' | translate }}</h3>
			</div>
		</div>
	</div>
</div>
