import {
	Component,
	Input,
	OnDestroy,
	OnInit,
	ViewChild
} from '@angular/core';
import { NgForm } from '@angular/forms';

import { BsModalService } from 'ngx-bootstrap/modal';

import { Question } from './Question';
import { WebcastViewQuestionsService } from './WebcastViewQuestions.Service';

import styles from './VbWebcastQuestionDirectReply.Component.module.less';

@Component({
	selector: 'vb-webcast-question-direct-reply',
	templateUrl: './VbWebcastQuestionDirectReply.Component.html',
	host: {
		'class': 'box-block theme-primary'
	}
})
export class VbWebcastQuestionDirectReplyComponent implements OnDestroy, OnInit {
	@Input() public question: Question;
	@Input() public webcastId: string;

	@ViewChild(NgForm) public form: NgForm;

	public replyText: string;
	public isPublic: boolean;
	public readonly styles = styles;

	constructor(
		private modalService: BsModalService,
		private WebcastViewQuestionsService: WebcastViewQuestionsService
	) {}

	public ngOnInit(): void {
		this.replyText = this.question.replyText;
	}

	public ngOnDestroy(): void {
		if (!this.form.submitted) {
			this.WebcastViewQuestionsService.cancelDirectReplyQuestion(this.webcastId, this.question);
		}
	}

	public submit(): void {
		this.WebcastViewQuestionsService.finalizeDirectReplyQuestion(this.webcastId, this.question, this.replyText, this.isPublic);

		this.modalService.hide();
	}

	public cancel(): void {
		this.modalService.hide();
	}
}
