
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { TeamListingsService } from './TeamListings.Service';

@NgModule({
	imports: [
		HttpClientModule
	],
	providers: [
		TeamListingsService
	]
})
export class TeamsAngularModule {}
