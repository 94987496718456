import {
	AfterViewInit,
	Component,
	Input,
	OnInit,
	Output
} from '@vbrick/angular-ts-decorators';

import {
	IAttributes,
	IAugmentedJQuery
} from 'angular';

import { IModalInstanceService } from 'rev-shared/ui/dialog/IDialog';

import { ComponentCallback } from 'rev-shared/ts-utils/ComponentCallback';
import { ComponentCallbackEvent } from 'rev-shared/ts-utils/ComponentCallbackEvent';

import { IConfirmationDialogParams } from './IConfirmationDialogParams';
import { IDialogService } from './IDialog';

/**
 * Confirmation dialog service that wraps the Angular Bootstrap Modal.
 * Example usage:
 * In your template:
 * <vb-confirmation-dialog
 * 		ng-ref="$ctrl.warningDialog"
 * 		title="Warning"
 * 		message="Are you sure?"
 * 		cancel-text="Cancel"
 * 		action-text="Ok"
 * 		themed="true"
 * >
 * </vb-confirmation-dialog>
 * In component:
 * 		this.warningDialog.open().result
 * 		.then(function () {
 * 			//dialog was accepted
 * 		})
 * 		.catch(function () {
 * 			//dialog was dismissed
 * 		})
 */

@Component({
	selector: 'vb-confirmation-dialog'
})
export class VbConfirmationDialogComponent implements OnInit, AfterViewInit {
	@Input() public themed: boolean;
	@Output() public onReady: ComponentCallback;

	constructor(
		private $attrs: IAttributes,
		private $element: IAugmentedJQuery,
		private Dialog: IDialogService
	) {
		'ngInject';
	}

	public ngOnInit(): void {
		this.$element.css('display', 'none');
	}

	public ngAfterViewInit(): void {
		this.onReady(new ComponentCallbackEvent());
	}

	public open(): IModalInstanceService {
		return this.Dialog.getDialog('ConfirmationDialog')
			.open({
				actionText: this.$attrs.actionText,
				cancelText: this.$attrs.cancelText,
				headerIconClass: this.$attrs.headerIconClass,
				headerIconType: this.$attrs.headerIconType,
				htmlMessage: this.$element.html().trim(),
				message: this.$attrs.message,
				rememberText: this.$attrs.rememberText,
				secondaryActionText: this.$attrs.secondaryActionText,
				themed: this.themed,
				title: this.$attrs.title
			} as IConfirmationDialogParams);
	}
}
