import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { UIRouterModule } from '@uirouter/angular';

import { ButtonsAngularModule } from 'rev-shared/ui/buttons/ButtonsAngular.Module';
import { CheckboxAngularModule } from 'rev-shared/ui/checkbox/CheckboxAngular.Module';
import { CssRulesAngularModule } from 'rev-shared/ui/css/Css.AngularModule';
import { DateAngularModule } from 'rev-shared/date/DateAngular.Module';
import { DialogAngularModule } from 'rev-shared/ui/dialog/DialogAngular.Module';
import { DirectivesAngularModule } from 'rev-shared/util/directives/DirectivesAngular.Module';
import { FormGroupAngularModule } from 'rev-shared/ui/formGroup/FormGroupAngular.Module';
import { PipesAngularModule } from 'rev-shared/pipes/Pipes.AngularModule';
import { SidebarLayoutModule } from 'rev-shared/ui/sidebarLayoutAngular/SidebarLayout.Module';
import { SpinnerAngularModule } from 'rev-shared/ui/spinner/SpinnerAngular.Module';
import { TextInputAngularModule } from 'rev-shared/ui/text-input/TextInputAngular.Module';
import { VbProfilePictureModule } from 'rev-shared/ui/profile/VbProfilePicture.Module';
import { VbTextAreaModule } from 'rev-shared/ui/textArea/VbTextArea.Module';

import { BrandingAngularModule } from 'rev-portal/branding/BrandingAngular.Module';
import { EditWebcastAngularModule } from 'rev-portal/scheduledEvents/editWebcast/EditWebcastAngular.Module';
import { PresentationAngularModule } from 'rev-portal/scheduledEvents/presentations/PresentationAngular.Module';
import { ScheduledEventsCommonModule } from 'rev-portal/scheduledEvents/common/ScheduledEventsCommon.AngularModule';
import { WebcastAttendeesModule } from 'rev-portal/scheduledEvents/webcast/attendees/WebcastAttendees.Module';
import { WebcastNavModule } from 'rev-portal/scheduledEvents/webcast/nav/WebcastNav.Module';
import { WebcastSharedComponentsModule } from 'rev-portal/scheduledEvents/webcast/sharedComponents/WebcastSharedComponents.Module';

import { VbWebcastInfoSidebarComponent } from './VbWebcastInfoSidebar.Component';
import { VbWebcastPlayerModule } from '../webcastPlayer/VbWebcastPlayer.Module';
import { VbWebcastPollsModule } from './polls/VbwebcastPolls.Module';
import { VbWebcastQuestionsModule } from './questions/VbWebcastQuestions.Module';
import { VbWebcastVideoComponent } from './VbWebcastVideo.Component';
import { VbWebcastViewComponent } from './VbWebcastView.Component';
import { WebcastChatModule } from './chat/WebcastChat.Module';
import { WebcastStartAndEndMessageModule } from './startAndEndMessage/WebcastStartAndEndMessage.Module';
import { WebcastViewSidebarPanelComponent } from './WebcastViewSidebarPanel.Component';

const components = [
	VbWebcastInfoSidebarComponent,
	VbWebcastVideoComponent,
	VbWebcastViewComponent,
	WebcastViewSidebarPanelComponent
];

@NgModule({
	declarations: components,
	entryComponents: components,
	exports: components,
	imports: [
		BrandingAngularModule,
		BsDropdownModule,
		ButtonsAngularModule,
		CheckboxAngularModule,
		CommonModule,
		CssRulesAngularModule,
		DateAngularModule,
		DialogAngularModule,
		DirectivesAngularModule,
		EditWebcastAngularModule,
		FormGroupAngularModule,
		FormsModule,
		PipesAngularModule,
		PresentationAngularModule,
		ScheduledEventsCommonModule,
		SidebarLayoutModule,
		SpinnerAngularModule,
		TextInputAngularModule,
		TooltipModule,
		TranslateModule,
		UIRouterModule,
		VbProfilePictureModule,
		VbTextAreaModule,
		VbWebcastPlayerModule,
		VbWebcastPollsModule,
		VbWebcastQuestionsModule,
		WebcastAttendeesModule,
		WebcastChatModule,
		WebcastNavModule,
		WebcastSharedComponentsModule,
		WebcastStartAndEndMessageModule
	]
})
export class VbWebcastViewModule {}
