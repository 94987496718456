import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

import { handleTableFetchError } from './ErrorHandler';

import { IGetInfiniteScrollRows } from 'rev-shared/ui/dataGrid/infiniteScroll/IGetInfiniteScrollRows';
import { IVbUiInfiniteScrollGridDataSourceResult } from 'rev-shared/ui/dataGrid/infiniteScroll/IVbUiInfiniteScrollGridDataSourceResult';
import { lastValueFrom } from 'rev-shared/rxjs/lastValueFrom';

@Injectable()
export class RealTimeAnalyticsZonesService {
	constructor(
		private http: HttpClient
	) {}

	public getRows: IGetInfiniteScrollRows = params => {
		return this.fetch(
			params.context.webcastId,
			params.context.runNumber,
			{
				size: params.pageSize,
				search: params.query,
				fields: params.filterFields,
				scrollId: params.scrollId,
				previousScrollId: params.previousScrollId,
				sortDirection: params.isSortAscending ? 'asc' : 'desc',
				sortBy: params.sortField
			}
		).then<IVbUiInfiniteScrollGridDataSourceResult>(result => ({
			data: result.zones,
			scrollId: result.scrollId,
			totalRows: result.total
		}));
	};

	private fetch(webcastId: string, runNumber: number, params: any) {
		return lastValueFrom(this.http.post<any>(`/analytics/events/${webcastId}/${runNumber}/real-time/zones`, params)
			.pipe(
				catchError((error: HttpErrorResponse) => handleTableFetchError(error, 'zones'))
			));
	}
}
