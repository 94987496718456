<branding-logo flex="1" [ngClass]="styles.logo">
</branding-logo>

<div class="theme-accent-border theme-accent-link" [ngClass]="styles.realTimeDashboard">
	<div>
		{{ 'Event_RealTimeAnalytics_RealTime' | translate }}
	</div>

	<div class="type-bold">
		{{ 'Event_RealTimeAnalytics_DashboardHeader' | translate }}
	</div>
</div>

<div flex="75" [ngClass]="styles.webcastTitleScheduleContainer">
	<div class="text-ellipsis" [ngClass]="styles.webcastTitle" [tooltip]="webcast.title" [placement]="'top left'">
		{{ webcast.title }}
	</div>

	<div class="text-ellipsis" [ngClass]="styles.webcastSchedule" [tooltip]="(webcast.startDate | vbDateTimeMedium) + ' - ' + (webcast.endDate | vbDateTimeMedium)" [placement]="'top left'">
		{{ webcast.startDate | vbDateTimeMedium }} - {{ webcast.endDate | vbDateTimeMedium }}
	</div>
</div>