<section layout="column" layout-wrap="false">
	<reports-service-down-msg *ngIf="!isReportsServiceUp"></reports-service-down-msg>
	<div *ngIf="isReportsServiceUp" layout="column" layout-wrap="false" [ngClass]="styles.tabWrapper" class="position-relative">
		<vb-ui-tab-panel flex="fill" [ngClass]="styles.tabPanel" [verticalFill]="false" [tabPanelInfoTemplate]="tabInfoTemplate">

			<vb-ui-tab #engagementTab [heading]="'Admin_Reports_Tab_Engagement' | translate" [headerIconClass]="styles.userEngagedIcon + ' vb-icon vb-icon-user-engaged'">
				<webcast-engagement-report *ngIf="engagementTab.isActive" [eventSummary]="eventSummary" [pollResults]="pollResults">
				</webcast-engagement-report>
			</vb-ui-tab>

			<vb-ui-tab #qualityExpTab [heading]="'Admin_Reports_Tab_Experience' | translate" [headerIconClass]="'glyphicons facetime_video'">
				<webcast-quality-experience-report *ngIf="qualityExpTab.isActive" [performanceData]="videoPerformanceReport" [videoPlaybackData]="videoReport">
				</webcast-quality-experience-report>
			</vb-ui-tab>

			<vb-ui-tab #attendeesTab [heading]="'Admin_Reports_Tab_Users' | translate" [headerIconClass]="'glyphicons group'">
				<webcast-attendees-grid-wrapper [shouldInitialize]="attendeesTab.isActive" [revConnectUseStatus]="revConnectUseStatus" [runNumber]="runNumber" [webcastId]="webcastId">
				</webcast-attendees-grid-wrapper>
			</vb-ui-tab>
		</vb-ui-tab-panel>
		<ng-template #tabInfoTemplate>
			<div layout="row" flex="fill" class="margin-left-15">
				<vb-dropdown-menu *ngIf="webcast.currentUser.canViewDownloadButton" class="box-inline-block" [alignMenu]="'right'" [buttonTitle]="'Download' | translate" [cssClass]="styles.menuDropDownContent" [icon]="'download_alt'">
					<ul [ngClass]="styles.menuDropdown">
						<li *ngIf="webcast.currentUser.canDownloadChat" [ngClass]="styles.menuItem">
							<a [ngClass]="styles.menuItemLink" [href]="commentsDownloadUrl" [download]="'chat.txt'">
								<span class="glyphicons chat"></span>
								{{ 'Event_DownloadChat' | translate }}
							</a>
						</li>
						<li *ngIf="webcast.currentUser.canDownloadQuestions" [ngClass]="styles.menuItem">
							<a [ngClass]="styles.menuItemLink" [href]="questionsDownloadUrl" download="questions.csv">
								<span class="vb-icon vb-icon-q-and-a"></span>
								{{ 'Event_DownloadQuestions' | translate }}
							</a>
						</li>
						<li *ngIf="webcast.currentUser.canDownloadPolls" [ngClass]="styles.menuItem">
							<a [ngClass]="styles.menuItemLink" [href]="pollsDownloadUrl" download>
								<span class="glyphicons charts"></span>
								{{ 'Event_PollsCsv' | translate }}
							</a>
						</li>
						<li *ngIf="webcast.currentUser.canDownloadAttendees" [ngClass]="styles.menuItem">
							<a [ngClass]="styles.menuItemLink" [href]="attendeesDownloadByRunNumberUrl" download>
								<span class="glyphicons group"></span>
								{{ (runItem.isPreProduction ? 'Event_AttendeesCsv' : 'Event_AttendeesCsv_MainEvent') | translate }}
							</a>
						</li>
						<li *ngIf="showDownloadAttendeesWithPreProd" [ngClass]="styles.menuItem">
							<a [ngClass]="styles.menuItemLink" [href]="attendeesDownloadUrl" download>
								<span class="glyphicons group"></span>
								{{ 'Event_AttendeesCsv_AllEvents' | translate }}
							</a>
						</li>
						<li *ngIf="revConnectUseStatus && webcast.currentUser.canDownloadAttendees" [ngClass]="styles.menuItem">
							<a [ngClass]="styles.menuItemLink" [href]="revConnectAttendeesDownloadUrl" download>
								<span class="glyphicons group"></span>
								{{ 'Event_RevConnect_Csv' | translate }}
							</a>
						</li>
					</ul>
				</vb-dropdown-menu>
				<div class="theme-primary-txt" [ngClass]="styles.runInfo">
					{{ (runItem.isPreProduction ? 'PreProduction' : 'MainEvent') | translate }}
					<span [hidden]="!(runItem.startDate)" [ngClass]="styles.runtime">
						{{ runItem.startDate | date:'shortTime' }} &ndash; {{ runItem.endDate | date:'shortTime'}}
					</span>
				</div>
			</div>
		</ng-template>
	</div>
</section>
