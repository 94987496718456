import {
	Directive,
	HostListener,
	Input,
	OnDestroy,
	OnInit
} from '@vbrick/angular-ts-decorators';

import { IAttributes, IAugmentedJQuery, INgModelController } from 'angular';

import { StateService, TransitionService } from '@uirouter/angularjs';

import styles from './vb-ui-radio-btn-group.module.less';
import secondaryBtnStyles from './vb-btn-secondary.module.less';

@Directive({
	selector: '[vb-ui-radio-btn]',
	bindToController: true,
	require: {
		ngModel: '?ngModel'
	}
})
export class VbUiRadioBtnDirective implements OnInit, OnDestroy {
	@Input() public activeClass: string;
	@Input() public vbUiRadioBtn: any; // ngModel value this button corresponds with

	private ngModel: INgModelController;
	private unsubscribeTransitionListener: any;

	constructor(
		private $attrs: IAttributes,
		private $element: IAugmentedJQuery,
		private $state: StateService,
		private $transitions: TransitionService
	) {
		'ngInject';
	}

	public ngOnInit(): void {
		this.$element.addClass(secondaryBtnStyles.vbSecondaryBtn);
		this.addAccessibilityAttributes();

		if (this.ngModel) {
			this.ngModel.$render = () => this.onNgModelChange(); // external change
			this.ngModel.$viewChangeListeners.push(() => this.onNgModelChange()); // internal change
		}

		if (this.$attrs.uiSref) {
			this.unsubscribeTransitionListener = this.$transitions.onSuccess(null, () => this.onNgModelChange());
			this.onNgModelChange();
		}
	}

	public ngOnDestroy(): void {
		if (this.unsubscribeTransitionListener) {
			this.unsubscribeTransitionListener();
		}
	}

	private isNgModelActive(): boolean {
		return this.ngModel.$viewValue === this.vbUiRadioBtn;
	}

	private isUiSrefActiveEq(): boolean {
		return this.$state.is(this.$attrs.uiSref);
	}

	@HostListener('click')
	private onClick(): void {
		if (this.ngModel) {
			this.ngModel.$setViewValue(this.vbUiRadioBtn);
		}
	}

	private onNgModelChange(): void {
		let isActive: boolean = false;

		if (this.ngModel) {
			isActive = this.isNgModelActive();
		} else if (this.$attrs.uiSref) {
			isActive = this.isUiSrefActiveEq();
		}
		this.updateAccessibilityAttrValue(isActive);
		this.$element.toggleClass(`theme-accent ${styles.activeBtn} ${this.activeClass || ''}`, isActive);
	}

	private addAccessibilityAttributes(): void {
		this.$element.attr('role', 'radio');
	}

	private updateAccessibilityAttrValue(value: boolean): void {
		this.$element.attr('aria-checked', (!!value).toString());
	}
}
