import { Component, Input, OnInit } from '@angular/core';

import { RunItem } from 'rev-portal/scheduledEvents/webcast/model/RunItem';
import { WebcastModel } from 'rev-portal/scheduledEvents/webcast/model/WebcastModel';

import styles from './WebcastReports.module.less';

@Component({
	selector: 'webcast-report-parent',
	templateUrl: './WebcastReportParent.Component.html'
})

export class WebcastReportParentComponent implements OnInit {
	@Input() public estimatedAttendees: number;
	@Input() public eventSummary: any;
	@Input() public isReportsServiceUp: boolean;
	@Input() public pollResults: any;
	@Input() public revConnectUseStatus: boolean;
	@Input() public runItem: RunItem;
	@Input() public runNumber: number;
	@Input() public videoPerformanceReport: any;
	@Input() public videoReport: any;
	@Input() public webcast: WebcastModel;
	@Input() public webcastId: string;

	public readonly styles = styles;

	public attendeesDownloadByRunNumberUrl: string;
	public attendeesDownloadUrl: string;
	public commentsDownloadUrl: string;
	public pollsDownloadUrl: string;
	public questionsDownloadUrl: string;
	public revConnectAttendeesDownloadUrl: string;

	public ngOnInit(): void {
		const { runNumber } = this.runItem;
		this.attendeesDownloadByRunNumberUrl = `/analytics/events/${this.webcast.id}/${runNumber}/summary/csv`;
		this.revConnectAttendeesDownloadUrl = `/analytics/events/${this.webcast.id}/${runNumber}/rev-connect/csv`;
		this.attendeesDownloadUrl = `/analytics/events/${this.webcast.id}/summary/csv`;
		this.commentsDownloadUrl = `/scheduled-events/${this.webcast.id}/${runNumber}/comments/export`;
		this.pollsDownloadUrl = `/scheduled-events/${this.webcast.id}/${runNumber}/poll-results/csv`;
		this.questionsDownloadUrl = `/analytics/events/${this.webcast.id}/${runNumber}/questions/csv`;
	}

	public get showDownloadAttendeesWithPreProd(): boolean {
		return this.webcast && this.webcast.currentUser.canDownloadAttendees
			&& !this.runItem.isPreProduction
			&& (this.webcast.runItems || []).some(run => run.isPreProduction && !!run.startDate && !!run.endDate );
	}
}
