import 'rev-shared/bootstrap/SharedImports';
import './PortalSharedLib';

import { bootstrapAngularApp } from 'rev-shared/bootstrap/AngularAppBootstrapUtils';
import { triggerAppBootstrapListeners } from 'rev-shared/bootstrap/HybridAppBootstrapUtils';
import { PortalHybridRootModule } from './PortalHybrid.Module';

window.addEventListener('load', () => {
	bootstrapAngularApp(PortalHybridRootModule)
		.then(triggerAppBootstrapListeners);
});
