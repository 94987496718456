import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	Output,
} from '@angular/core';

import { RunItem } from '../webcast/model/RunItem';

import styles from './ReportsMenu.module.less';

@Component({
	selector: 'reports-menu',
	templateUrl: './ReportsMenu.Component.html'
})
export class ReportsMenuComponent implements OnChanges {
	@Input() public runItems: RunItem[];

	@Output() public runItemClicked: EventEmitter<any> = new EventEmitter();

	public readonly styles = styles;
	public completedRunItems: RunItem[];

	public ngOnChanges(): void {
		this.completedRunItems = (this.runItems || []).map(run =>
			this.checkRunItemFinished(run) ? run : undefined
		).filter(Boolean);
	}

	private checkRunItemFinished(runItem: RunItem): boolean {
		if (runItem) {
			return !!runItem.startDate && !!runItem.endDate;
		}
	}
}
