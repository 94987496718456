import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { UIRouterModule } from '@uirouter/angular';
import { TranslateModule } from '@ngx-translate/core';

import { AnalyticsModule } from 'rev-shared/analytics/Analytics.Module';
import { ButtonsAngularModule } from 'rev-shared/ui/buttons/ButtonsAngular.Module';
import { DataGridModule } from 'rev-shared/ui/dataGrid/DataGrid.Module';
import { DialogAngularModule } from 'rev-shared/ui/dialog/DialogAngular.Module';
import { ng2StatesToHybridStates } from 'rev-shared/uiRouterUtils/HybridRouterUtils';
import { PushModule } from 'rev-shared/push/Push.Module';
import { SearchInputModule } from 'rev-shared/ui/searchInputAngular/SearchInput.Module';
import { VbProfilePictureModule } from 'rev-shared/ui/profile/VbProfilePicture.Module';

import { BrandingAngularModule } from 'rev-portal/branding/BrandingAngular.Module';

import { stateDeclarations } from './StateDeclaration';
import { WebcastAttendeesListComponent } from './WebcastAttendeesList.Component';
import { AttendeesListService } from './AttendeesList.Service';
import { WebcastAttendeeRendererComponent } from './WebcastAttendeeRenderer.Component';

const components = [
	WebcastAttendeesListComponent,
	WebcastAttendeeRendererComponent
];

@NgModule({
	declarations: components,
	entryComponents: components,
	exports: [
		WebcastAttendeesListComponent
	],
	imports: [
		AnalyticsModule,
		BrandingAngularModule,
		ButtonsAngularModule,
		CommonModule,
		DataGridModule,
		DialogAngularModule,
		FormsModule,
		HttpClientModule,
		PushModule,
		SearchInputModule,
		TranslateModule,
		UIRouterModule.forChild({
			states: ng2StatesToHybridStates(stateDeclarations)
		}),
		VbProfilePictureModule
	],
	providers:[
		AttendeesListService
	]
})
export class WebcastAttendeesModule {
}
