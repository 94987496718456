import _ from 'underscore';

import {
	Component,
	OnDestroy,
	OnInit
} from '@vbrick/angular-ts-decorators';

import {
	auto,
	ITimeoutService
} from 'angular';

import { Subscription } from 'rxjs';

import { MultiValueFilter } from './SearchFilterTypes';
import { SearchFilterStateService } from './SearchFilterState.Service';

import './VbSearchFilterList.less';

@Component({
	selector: 'vb-search-filter-list',
	templateUrl: '/partials/media/search/search-filter-list.html'
})
export class VbSearchFilterListComponent implements OnDestroy, OnInit {
	private filters: Array<{
		clear: () => void;
		format: any;
		name: string;
	}>;
	private filterTranslations: { [key: string]: string };
	private searchFilterStateSub: Subscription;
	private showClearAllButton: boolean;

	constructor(
		private $injector: auto.IInjectorService,
		private $timeout: ITimeoutService,
		private SearchFilterState: SearchFilterStateService
	) {
		'ngInject';
	}

	public ngOnInit(): void {
		this.searchFilterStateSub = this.SearchFilterState.change$.subscribe(() => this.initializeFilters());

		this.$timeout(() => this.initializeFilters());
	}

	public ngOnDestroy(): void {
		this.searchFilterStateSub.unsubscribe();
	}

	private clearAll(): void {
		this.SearchFilterState.clear();
	}

	private getFormatter(filter){
		if(filter.formatterFactory){
			return this.$injector.invoke(filter.formatterFactory, filter, {
				filterTranslations: this.filterTranslations
			});
		}

		return (filter.formatter || _.identity).bind(filter);
	}

	private initializeFilters(): void {
		this.filters = Object.keys(this.SearchFilterState.filters)
			.map(name => {
				const filter = this.SearchFilterState.filters[name];

				const hasEditableOverride: boolean = filter.hasValueOverride && !filter.blockUserUpdates;

				if (!filter.hasUserEnteredValue && !hasEditableOverride) {
					return;
				}

				const formatter = this.getFormatter(filter);

				if (filter.isSingleValue) {
					return {
						name,
						format: () => formatter(filter.value),
						clear: () => {
							filter.clear();
							this.SearchFilterState.update();
						}
					};
				}

				//multivalue
				return filter.value.map((value, i) => ({
					name,
					format: () => formatter(value),
					clear: () => {
						(filter as MultiValueFilter).removeSingleValue(i);
						this.SearchFilterState.update();
					}
				}));
			})
			.reduce((a, b) => a.concat(b), [])
			.filter(Boolean);

		this.showClearAllButton = !!this.filters.length;
	}
}
