import { NgModule } from '@vbrick/angular-ts-decorators';

import { VbUiErrorMessageDirective } from './VbUiErrorMessage.Directive';

@NgModule({
	id: 'VBrick.UI.Validation',
	declarations: [
		VbUiErrorMessageDirective
	]
})
export default class ValidationModule {}
