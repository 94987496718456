import { Injectable } from '@vbrick/angular-ts-decorators';
import { IHttpService } from 'angular';
import { param } from 'jquery';

import { PushService } from 'rev-shared/push/PushService';

import { UploadService } from './Upload.Service';

const IMPORT_RESPONSE_DOWNLOADING_FINISHED: string = 'DownloadingVideoFinished';

@Injectable('WebExService')
export class WebExService {
	constructor(
		private $http: IHttpService,
		private PushService: PushService,
		private UploadService: UploadService
	) {
		'ngInject';
	}

	public getRecordings(webexSiteSettingsId: string, webExHostId: string, password: string, teamId: string) {
		var url = teamId && teamId.length > 0 ?
			`/media/videos/webex/${webexSiteSettingsId}/get-recordings?teamId=${teamId}` :
			`/media/videos/webex/${webexSiteSettingsId}/get-recordings`;
		return this.$http.post(url, param({
			password,
			webExHostId
		}),	{
			headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
		})
			.then(({ data }) => {
				data = data || [];
				return (data as any[])
					.map(recording => ({
						...recording,
						durationInMs: recording.durationInSeconds * 1000
					}));
			});
	}

	public importRecording(WebexSiteSettingsId: string, recordingId: string, title: string, recordingFileUrl: string, teamId?: string) {
		//create status entry
		const entry = this.UploadService.createImportStatusEntry(title, teamId);

		this.PushService.dispatchCommand('media:AddWebexVideoToAccount', {
			WebexSiteSettingsId,
			recordingId,
			title,
			teamId,
			recordingFileUrl
		}, [
			'DownloadingVideoFailed',
			'OriginalVideoInstanceStoringFailed',
			'VideoProcessingFailed',
			IMPORT_RESPONSE_DOWNLOADING_FINISHED
		])
			.then(result => {
				// update status
				if (result.eventType === IMPORT_RESPONSE_DOWNLOADING_FINISHED) {
					entry.status.complete = true;
					entry.id = result.message ? result.message.videoId : null;
					entry.uploadDate = result.message ? result.message.when : null;
				}
				else {
					entry.status.error = true;
				}
			}).catch(() => {
				entry.status.error = true;
			});
	}
}
