import { IMessageDispatcher } from '@vbrick/vbrick-logging-client/src';

import { PlaybackUpdatedSubType } from 'rev-shared/videoPlayer/analytics/PlaybackUpdatedSubType';
import { RevLoggingClientBase } from 'rev-shared/logging/RevLoggingClientBase';
import { UserContextService } from 'rev-shared/security/UserContext.Service';
import { VideoHeartbeatErrorSubType } from 'rev-shared/videoPlayer/analytics/VideoHeartbeatErrorSubType';
import { VideoHeartbeatEventType } from 'rev-shared/videoPlayer/analytics/VideoHeartbeatEventType';
import { getBufferingSubType } from 'rev-shared/videoPlayer/analytics/BufferingSubType';
import { SpinnerType } from 'rev-shared/videoPlayer/analytics/SpinnerType';
import { UserLocalIPService } from 'rev-shared/security/UserLocalIP.Service';

import { WebcastAttendeeType } from './model/WebcastAttendeeType';

const WEBCAST_PLAYER_MSG_TYPE: string = 'WebcastUserEvent';

export interface IPlaybackUpdatedData {
	attendeeType: WebcastAttendeeType;
	deviceId: string;
	deviceName: string;
	failoverFromUrl: string;
	revConnect: boolean;
	sessionId: string;
	streamAccessed: string;
	subType: PlaybackUpdatedSubType;
	videoFormat: string;
	videoPlayer: string; //HTML5 or Flash
	zoneId: string;
	zoneName: string;
}

interface IHeartbeatData {
	bandwidth: number;
	bitrate: number;
	volume: number;
}

export class WebcastPlayerLoggingClient extends RevLoggingClientBase {
	private deviceId: string;
	private deviceName: string;
	private sessionId: string;
	private streamAccessed: string;
	private zoneName: string;
	private zoneId: string;

	constructor(
		UserContext: UserContextService,
		UserLocalIPService: UserLocalIPService,
		private webcastId: string,
		private runNumber: number,
		messageDispatcher: IMessageDispatcher
	) {
		super(UserContext, UserLocalIPService, webcastId, WEBCAST_PLAYER_MSG_TYPE, true, messageDispatcher);
	}

	public onBufferingStart(isInitial: boolean): void {
		this.logVideoHeartbeat(VideoHeartbeatEventType.BUFFERING_START, {
			subType: getBufferingSubType(isInitial),
		});
	}

	public onBufferingStop(isInitial: boolean, durationMs: number): void {
		this.logVideoHeartbeat(VideoHeartbeatEventType.BUFFERING_STOP, {
			duration: durationMs,
			subType: getBufferingSubType(isInitial)
		});
	}

	public onError(data: { isFatal: boolean; subType: VideoHeartbeatErrorSubType; zoneId?: any }): void {
		this.logVideoHeartbeat(VideoHeartbeatEventType.ERROR, {
			isFatal: data.isFatal,
			subType: data.subType,
			zoneId: data.zoneId
		});
	}

	public onHeartbeat(data: IHeartbeatData): void {
		this.logVideoHeartbeat(VideoHeartbeatEventType.HEARTBEAT, {
			bandwidth: data.bandwidth,
			bitRate: data.bitrate,
			volume: data.volume
		});
	}

	public onPlay(isInitial: boolean): void {
		this.logVideoHeartbeat(VideoHeartbeatEventType.PLAY, {
			isInitial
		});
	}

	public onPlaybackStarted(data: IPlaybackUpdatedData): void {
		this.logPlaybackEvent(VideoHeartbeatEventType.PLAYBACK_STARTED, data);
	}

	public onPlaybackUpdated(data: IPlaybackUpdatedData): void {
		this.logPlaybackEvent(VideoHeartbeatEventType.PLAYBACK_UPDATED, data);
	}

	public onBufferingSpinner(subType: SpinnerType, durationMs: number): void {
		this.logVideoHeartbeat(VideoHeartbeatEventType.SPINNER, {
			duration: durationMs,
			subType
		});
	}

	public onStop(): void {
		this.logVideoHeartbeat(VideoHeartbeatEventType.STOP, {});
	}

	protected logPlaybackEvent(eventType: VideoHeartbeatEventType, data: IPlaybackUpdatedData): void {
		const user = this.UserContext.getUser();

		// capture current playback state
		this.deviceId = data.deviceId;
		this.sessionId = data.sessionId;
		this.streamAccessed = data.streamAccessed;
		this.deviceName = data.deviceName;
		this.zoneId = data.zoneId;
		this.zoneName = data.zoneName;

		this.logVideoHeartbeat(eventType, {
			attendeeType: data.attendeeType,
			failOverFromUrl: data.failoverFromUrl,
			email: user.email,
			fullName: user.fullName,
			revConnect: data.revConnect,
			subType: data.subType,
			username: user.username,
			userType: this.getUserType(),
			videoFormat: data.videoFormat,
			videoPlayer: data.videoPlayer
		});
	}

	protected logVideoHeartbeat(eventType: VideoHeartbeatEventType, message: any): void {
		this.log({
			...message,
			deviceId: this.deviceId,
			eventType,
			runNumber: this.runNumber,
			sessionId: this.sessionId,
			streamAccessed: this.streamAccessed,
			streamDevice: this.deviceName,
			webcastId: this.webcastId,
			when: new Date(),
			zoneId: this.zoneId,
			zoneName: this.zoneName
		});
	}
}
