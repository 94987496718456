import {
	Directive,
	OnInit
} from '@vbrick/angular-ts-decorators';
import { IAugmentedJQuery } from 'angular';

import { applyRoleAndValidateAriaLabel } from 'rev-shared/accessibilityUtils/AccessibilityUtils';

import styles from './vb-ui-radio-btn-group.module.less';

/**
 * usage -
 * 	<label id="id1">Test Group</label>
 * 	<div vb-ui-radio-btn-group aria-labelledby="id1">
 *		<button name="name1"
 *			type="button"
 *			ng-model="value"
 *			vb-ui-radio-btn="true">
 *			Enabled
 *		</button>
 *		<button name="name1"
 *			type="button"
 *			ng-model="value"
 *			vb-ui-radio-btn="false">
 *			Disabled
 *		</button>
 *	</div>
 */

@Directive({
	selector: '[vb-ui-radio-btn-group]'
})
export class VbUiRadioBtnGroupDirective implements OnInit {

	constructor(
		private $element: IAugmentedJQuery
	) {
		'ngInject';
	}

	public ngOnInit(): void {
		this.modifyStyles();
		this.manageAccessibilityAttributes();
	}

	private modifyStyles(): void {
		this.$element.addClass(styles.btnToggleWrapper);
	}

	private manageAccessibilityAttributes(): void {
		applyRoleAndValidateAriaLabel(this.$element, 'radiogroup');
	}
}
