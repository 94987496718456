import { Component, Input, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';
import { Observable } from 'rxjs';
import { filter, distinctUntilChanged, debounceTime } from 'rxjs/operators';

import { IGetInfiniteScrollRows } from 'rev-shared/ui/dataGrid/infiniteScroll/IGetInfiniteScrollRows';
import { IVbUiInfiniteScrollGridDataSourceResult } from 'rev-shared/ui/dataGrid/infiniteScroll/IVbUiInfiniteScrollGridDataSourceResult';
import { isString } from 'rev-shared/util';

import { WebcastAttendeesService } from './WebcastAttendees.Service';

import styles from './WebcastAttendeesGrid.module.less';

const SEARCH_KEY_LENGTH: number = 2;

@Component({
	selector: 'webcast-attendees-grid-wrapper',
	templateUrl: './WebcastAttendeesGridWrapper.Component.html'
})
export class WebcastAttendeesGridWrapperComponent {
	@Input() public revConnectUseStatus: boolean;
	@Input() public runNumber: number;
	@Input() public shouldInitialize: boolean;
	@Input() public webcastId: string;

	@ViewChild(NgModel) public searchValue: NgModel;

	public searchText$: Observable<string>;
	public readonly styles = styles;

	constructor(
		public WebcastAttendees: WebcastAttendeesService
	) {}

	public ngAfterViewInit(): void {
		this.searchText$ = this.searchValue.valueChanges
			.pipe(
				filter(text => isString(text) && (!text || text.length >= SEARCH_KEY_LENGTH)),
				debounceTime(500),
				distinctUntilChanged()
			);
	}

	public attendeesGetRow: IGetInfiniteScrollRows = params => this.WebcastAttendees.fetchAttendees(
		this.webcastId,
		this.runNumber,
		{
			size: params.pageSize,
			searchQuery: params.query,
			searchFields: params.query ? Object.keys(params.filterModel) : undefined,
			scrollId: params.scrollId,
			sortDirection: params.isSortAscending ? 'asc' : 'desc',
			sortBy: params.sortField
		}
	).toPromise().then<IVbUiInfiniteScrollGridDataSourceResult>(result => ({
		data: result.sessions,
		scrollId: result.scrollId,
		totalRows: result.total
	}));
}
