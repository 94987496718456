import { findWhere } from 'underscore';

import {
	Component,
	Input,
	OnDestroy,
	OnInit,
	Output
} from '@vbrick/angular-ts-decorators';

import { IUnsubscribe } from 'rev-shared/push/IUnsubscribe';
import { retryUntilSuccess } from 'rev-shared/util/PromiseUtil';

import { SparkService } from './Spark.Service';

@Component({
	selector: 'subscribe-spark-category',
	templateUrl: '/partials/media/search/subscribe-spark-category.html'
})
export class SubscribeSparkCategoryComponent implements OnInit, OnDestroy {
	@Input() public resolve: { dialogParams: { category: any } };

	@Output() public close: () => void;

	private sparkAuthenticationRequired: boolean;
	private sparkOnAuthenticateUnsubscribe: IUnsubscribe;
	private roomId: string;
	private rooms: any;
	private status: any;

	constructor(
		private SparkService: SparkService
	) {
		'ngInject';
	}

	public ngOnInit(): void {
		this.loadRooms();
	}

	public ngOnDestroy(): void {
		if (this.sparkOnAuthenticateUnsubscribe) {
			this.sparkOnAuthenticateUnsubscribe();
		}
	}

	private loadRooms(isRetry: boolean = false) {
		this.status = { processing: true };
		this.sparkAuthenticationRequired = false;

		return (isRetry ?
			retryUntilSuccess(() => Promise.resolve(this.SparkService.getRooms())) :
			Promise.resolve(this.SparkService.getRooms())
		)
			.then(rooms => {
				this.status = { active: true };
				this.rooms = rooms;
			})
			.catch(err => {
				if (err.authenticationRequired && !isRetry) {
					this.status = { active: true };
					this.sparkAuthenticationRequired = true;

					this.sparkOnAuthenticateUnsubscribe = this.SparkService.onAuthenticate(() => this.loadRooms(true));
				} else {
					this.status = {
						error: true,
						loadRoomsError: true
					};
				}
			});
	}

	private submit() {
		this.status = { processing: true };

		const room = findWhere(this.rooms, { id: this.roomId });

		this.SparkService.createCategorySubscription(room, this.resolve.dialogParams.category.id)
			.then(() => this.close())
			.catch(() => {
				this.status = {
					error: true,
					saveError: true
				};
			});
	}
}
