<webcast-pre-prod-banner [webcast]="webcast">
</webcast-pre-prod-banner>

<real-time-analytics-header [webcast]="webcast">
</real-time-analytics-header>

<div *ngIf="webcast.currentRun.isStarted" flex-justify="space" layout="row" [ngClass]="styles.kpiContainer">

	<vb-ui-report-cell flex="1 1 0" [header]="'Event_RealTimeAnalytics_NoOfCurrentAttendees' | translate" [size]="'medium'">

		<vb-ui-kpi [value]="kpiService.numAttendees$ | async">
		</vb-ui-kpi>

		<vb-ui-line-chart-small flex="fill" [dateField]="'key'" [data]="kpiService.initAttendees$ | async" [incrementalData]="kpiService.incrementalAttendees$ | async" [fillEnabled]="true" [inputDateFormat]="'i'" [yAxisLabel]="'Reports_Attendees' | translate" [valueField]="'value'" (onChartReady)="onNumAttendeesChartReady($event)">
		</vb-ui-line-chart-small>
	</vb-ui-report-cell>

	<vb-ui-report-cell flex="1 1 0" [header]="'Event_RealTimeAnalytics_ExperiencedRebuffering' | translate" [size]="'medium'">

		<vb-ui-kpi [decimalPlaces]="2" [reverseColors]="true" [suffix]="'%'" [value]="kpiService.experiencedRebufferingAvgPercentage$ | async">
		</vb-ui-kpi>

		<vb-ui-line-chart-small flex="fill" [dateField]="'key'" [data]="kpiService.initExperiencedRebuffering$ | async" [incrementalData]="kpiService.incrementalExperiencedRebuffering$ | async" [fillEnabled]="true" [inputDateFormat]="'i'" [yAxisLabel]="'Reports_Video_Rebuffering' | translate" [valueField]="'value'" [valueAxisFormat]="'#.00'" (onChartReady)="onExperiencedRebufferingChartReady($event)">
		</vb-ui-line-chart-small>
	</vb-ui-report-cell>

	<vb-ui-report-cell flex="1 1 0" [header]="'Event_RealTimeAnalytics_AvgRebufferDuration' | translate" [size]="'medium'">

		<vb-ui-kpi [decimalPlaces]="1" [reverseColors]="true" [suffix]="'s'" [value]="kpiService.avgRebufferDurationSec$ | async">
		</vb-ui-kpi>

		<vb-ui-bar-chart-small flex="fill" [categoryField]="'durationRange'" [data]="kpiService.rebufferDurationSecByNumRebuffers$ | async" [updateEnabled]="true" [valueAxisTitleLabel]="'Event_RealTimeAnalytics_Rebuffers' | translate" [valueField]="'numRebuffers'">
		</vb-ui-bar-chart-small>
	</vb-ui-report-cell>


	<vb-ui-report-cell flex="1 1 0" [header]="'Event_RealTimeAnalytics_ExperiencedErrors' | translate" [subheader]="'Event_RealTimeAnalytics_PerTotalAttendees' | translate" [size]="'medium'">

		<vb-ui-kpi [decimalPlaces]="2" [reverseColors]="true" [value]="kpiService.avgExperiencedErrors$ | async">
		</vb-ui-kpi>

		<vb-ui-bar-chart-small flex="fill" [alwaysShowCategoryLabels]="true" [categoryField]="'errorCountRange'" [data]="kpiService.experiencedErrorsByNumAttendees$ | async" [updateEnabled]="true" [valueAxisTitleLabel]="'Reports_Attendees' | translate" [valueField]="'numAttendees'">
		</vb-ui-bar-chart-small>
	</vb-ui-report-cell>

	<vb-ui-report-cell flex="1 1 0" [header]="'Event_RealTimeAnalytics_MulticastErrors' | translate" [subheader]="'Event_RealTimeAnalytics_PerTotalAttendees' | translate" [size]="'medium'">

		<vb-ui-kpi [decimalPlaces]="2" [reverseColors]="true" [value]="kpiService.avgMulticastErrors$ | async">
		</vb-ui-kpi>

		<vb-ui-bar-chart-small flex="fill" [alwaysShowCategoryLabels]="true" [categoryField]="'errorCountRange'" [data]="kpiService.multicastErrorsByNumAttendees$ | async" [updateEnabled]="true" [valueAxisTitleLabel]="'Reports_Attendees' | translate" [valueField]="'numAttendees'">
		</vb-ui-bar-chart-small>
	</vb-ui-report-cell>

</div>

<vb-ui-tab-panel *ngIf="webcast.currentRun.isStarted" flex="fill" [ngClass]="styles.tabPanel" [verticalFill]="true">

	<vb-ui-tab #zonesTab [heading]="'Event_RealTimeAnalytics_Tab_Zones' | translate">

		<real-time-analytics-zones-tab *ngIf="zonesTab.isActive" [webcast]="webcast">
		</real-time-analytics-zones-tab>

	</vb-ui-tab>

	<vb-ui-tab #devicesTab [heading]="'Event_RealTimeAnalytics_Tab_Devices' | translate">

		<real-time-analytics-devices-tab *ngIf="devicesTab.isActive" [webcast]="webcast">
		</real-time-analytics-devices-tab>
	</vb-ui-tab>

	<vb-ui-tab #attendeesTab [heading]="'Event_RealTimeAnalytics_Tab_Attendees' | translate">

		<real-time-analytics-attendees-tab *ngIf="attendeesTab.isActive" [webcast]="webcast">
		</real-time-analytics-attendees-tab>

	</vb-ui-tab>

</vb-ui-tab-panel>

<div *ngIf="!webcast.currentRun.isStarted" class="theme-primary-txt" flex="fill" flex-align="center" flex-align-self="center" layout [ngClass]="styles.eventEnded">
	{{ 'Event_RealTimeAnalytics_WebcastEndMessage' | translate }}
</div>
