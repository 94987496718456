import {
	Component,
	Input,
	OnChanges,
	OnInit,
	SimpleChanges
} from '@vbrick/angular-ts-decorators';
import { StateService } from '@uirouter/angularjs';

import { BrandingHeaderType } from 'rev-portal/branding/BrandingHeaderType';
import {
	HeaderSettings,
	ThemeSettings
} from 'rev-portal/branding/BrandingSettings.Contract';
import { IRules } from 'rev-shared/ui/css/CssRules.Contract';
import { MediaMenuDataManagerDirective } from 'rev-portal/navigation/mediaMenu/MediaMenuDataManager.Directive';
import { MobileSubMenu } from './MobileSubMenu.Constant';

import { LoginRedirectService } from 'rev-shared/security/LoginRedirect.Service';
import { UserContextService, IUserContextAccount } from 'rev-shared/security/UserContext.Service';

import './mobile-menu.less';

@Component({
	selector: 'mobile-menu',
	templateUrl: '/partials/portal/navigation/mobile-menu.html',
	require: {
		mediaMenuDataManager: 'mediaMenuDataManager'
	}
})

export default class MobileMenuComponent implements OnInit, OnChanges {
	@Input() private theme: BrandingHeaderType;
	@Input() private headerSettings: HeaderSettings;
	@Input() private themeSettings: ThemeSettings;

	private accountId: string;
	private mediaMenuDataManager: MediaMenuDataManagerDirective;
	private scopedCSSRules: IRules;
	private subMenu: string;

	constructor(
		private $state: StateService,
		private LoginRedirectService: LoginRedirectService,
		private UserContext: UserContextService
	) {
		'ngInject';
	}

	public ngOnInit(): void {
		this.accountId = this.UserContext.getAccount().id;
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.headerSettings || changes.themeSettings) {
			this.scopedCSSRules = {
				'.theme-Nettrix mobile-menu .mobile-menu-list-item.isOpen, .theme-Nettrix .mobile-menu-list-item:hover, .theme-Nettrix my-videos-menu-items li.active': {
					'background-color': this.themeSettings.accentColor,
					'color': this.themeSettings.accentFontColor
				},
				'.theme-Nettrix mobile-menu .mobile-menu': {
					'border-right-color': `${this.headerSettings.fontColor} !important`
				}
			};
		}
	}

	public get isNettrixTheme(): boolean {
		return this.theme === BrandingHeaderType.Nettrix;
	}

	public get showVideoMenu(): boolean {
		return this.subMenu === MobileSubMenu.Video;
	}

	public get showHelpMenu(): boolean {
		return this.subMenu === MobileSubMenu.Help;
	}

	public loginRedirect(): void {
		this.LoginRedirectService.redirectToLogin();
	}

	public onVideoMenuClick(): void {
		this.subMenu = this.subMenu === MobileSubMenu.Video ? undefined : MobileSubMenu.Video;
	}

	public onToggle(open: boolean): void {
		if (open) {
			return open && this.mediaMenuDataManager.fetchAllData();
		}
	}

	public get account(): IUserContextAccount {
		return this.UserContext.getAccount();
	}
}
