import { Component, Input, OnInit, AfterViewInit, OnDestroy } from '@vbrick/angular-ts-decorators';
import { copy, IDocumentService } from 'angular';
import { StateService } from '@uirouter/angularjs';
import { sortBy } from 'underscore';

import { ApprovalStatus } from 'rev-shared/media/MediaConstants';
import { PlaylistService } from 'rev-shared/media/Playlist.Service';
import { UtilService } from 'rev-shared/util/Util.Service';

import { MediaStateService } from '../MediaState.Service';

import './playlist-detail.less';
@Component({
	selector: 'playlist-detail',
	templateUrl: '/partials/media/playlist-detail.html'
})
export class PlaylistDetailComponent implements OnInit, AfterViewInit, OnDestroy {
	@Input() public canEdit: boolean;
	@Input() public hasMediaEditAuth: boolean;
	@Input() public mediaFeatures: any;
	@Input() public playlist: any;
	@Input() public userId: string;

	private all: boolean;
	private canRename: boolean;
	private canShare: boolean;
	private onKeydownHandler: any;
	private previousSort: any;
	private removeVideosConfirmation: any;
	private selectedVideos: any[];
	private sort: any;
	private translations: any;
	private approvalStatusOptions: typeof ApprovalStatus;

	private readonly bodyTemplateUrl = this.MediaStateService.isTableViewMode ?
		'/partials/media/playlist-detail-table.html' :
		'/partials/media/playlist-detail-tiles.html';

	constructor(
		private $document: IDocumentService,
		private $state: StateService,
		private MediaStateService: MediaStateService,
		private PlaylistService: PlaylistService,
		private Util: UtilService) {
		'ngInject';
	}

	public ngOnInit(): void {
		Object.assign(this, {
			all: false,
			canRename: this.canEdit && !this.playlist.featured,
			canShare: this.canEdit,
			selectedVideos: [],
			sort: {
				property: 'playlistIndex',
				ascending: true
			},
			translations: {},
			approvalStatusOptions: ApprovalStatus
		});

		this.onKeydownHandler = (event: any) => this.onKeydown(event);
		this.$document.on('keydown', this.onKeydownHandler);
		this.MediaStateService.searchResultsState.getSelectedCount = () => this.selectedVideos?.length;
	}

	public ngAfterViewInit(): void {
		if (this.playlist.featured) {
			this.playlist.name = this.translations.featuredVideos;
		}
	}

	public ngOnDestroy(): void {
		this.$document.off('keydown', this.onKeydownHandler);
	}

	public hasEditVideoAuth(video: any) {
		return video && (video.editAcl || []).includes(this.userId);
	}

	public removeSelectedVideos(): void {
		const playlist = this.playlist;
		const videos = playlist.videos;
		const videosToKeep = videos.filter((video: any) => !video.selected);

		if(videosToKeep.length === videos.length) {
			return;
		}

		this.removeVideosConfirmation.open().result
			.then(() => {
				playlist.videos = videosToKeep;
				this.updateSelectedVideos();

				this.savePlaylist({
					id: playlist.id,
					name: playlist.name,
					videos: videosToKeep
				})
					.catch(() => {
						videos.forEach((video: any) => {
							if(video.selected) {
								video.selected = false;
								video.error = true;
							}
						});
						this.previousSort = null;
						playlist.videos = videos;
						this.updateSelectedVideos();
					});
			});
	}

	public savePlaylistOrder(event: any, ui: any): void {
		const previousVideos: any[] = copy(this.playlist.videos);

		this.playlist.videos.forEach((v: any, index: number) => {
			index = this.sort.ascending ? index : this.playlist.videos.length - 1 - index;
			v.playlistIndex = index;
		});

		this.savePlaylist(this.playlist)
			.catch(() => {
				this.previousSort = null;
				this.playlist.videos = previousVideos;
			});
	}

	public get sharePlaylistEmailUri(): string {
		return 'mailto:?' + this.Util.urlEncode({
			subject: this.translations.shareEmailSubject,
			body:
				this.playlist.name + '\n' +
				this.$state.href('.', {}, { absolute: true })
		});
	}

	public sortPlaylists(sortProperty: string): void {
		if (this.sort.property === sortProperty) {
			this.sort.ascending = !this.sort.ascending;
		}

		this.sort.property = sortProperty;
	}

	public toggleAll(): void {
		this.all = !this.all;

		this.playlist.videos.forEach((video: any) => video.selected = this.all);

		this.updateSelectedVideos();
	}

	public toggleSelection(video: any): void {
		if (!this.canEdit) {
			return;
		}

		video.selected = !video.selected;

		this.updateSelectedVideos();
	}

	private onKeydown(event: any): void {
		if(event.which === 46) {
			this.removeSelectedVideos();
		}
	}

	private savePlaylist(playlist: any): any {
		const sortedVideoIds: string[] = sortBy(playlist.videos, 'playlistIndex').map((v: any) => v.id);

		if (this.playlist.isFeatured) {
			return this.PlaylistService.modifyFeaturedVideos(sortedVideoIds);
		}

		return this.PlaylistService.modifyPlaylist({
			playlistId: playlist.id,
			name: playlist.name,
			videoIds: sortedVideoIds
		});
	}

	private updateSelectedVideos(): void {
		this.selectedVideos = this.playlist.videos.filter((video: any) => video.selected);
	}
}
