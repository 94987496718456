<form name="form" #form="ngForm" (submit)="submit()">

	<div layout edge-padding class="modal-header" [ngClass]="styles.header">
		<h1 class="theme-primary-txt-important">{{ 'Event_QA_Action_Reply' | translate }}</h1>
	</div>

	<div class="modal-body">
		<p class="selected-question" [ngClass]="styles.questionWrapper"><strong>{{ 'Event_QA_ReplyingToQuestion' | translate }}:</strong> {{ question.questionText }}</p>
		<textarea class="theme-primary-font-border-fade-50" vbUiTextArea [attr.aria-label]="'Event_QA_ReplyingToQuestion' | translate" [(ngModel)]="replyText" name="replyText" required autocomplete="off" autofocus rows="8">
		</textarea>
	</div>

	<div class="modal-footer">
		<div layout>
			<vb-ui-checkbox name="isPublicChkBox" [(ngModel)]="isPublic" [tooltip]="'Event_QuestionReplyPublishTooltip' | translate" [placement]="'top left'" triggers="click" [containerClass]="styles.tooltipContainer">
				{{ 'Event_QuestionReplyPublishToAll' | translate }}
			</vb-ui-checkbox>
		</div>
		<button type="button" vbUiBtnSecondaryNgx (click)="cancel()" class="margin-right-10">{{ 'Cancel' | translate }}</button>
		<button type="submit" vbUiBtnPrimaryNgx [disabled]="form.$invalid">{{ 'Save' | translate }}</button>
	</div>
</form>
