import { StateService } from '@uirouter/angular';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { DateUtil } from 'rev-shared/date/DateUtil';
import { DateParsersService } from 'rev-shared/date/DateParsers.Service';
import { EditWebcastModel } from 'rev-portal/scheduledEvents/editWebcast/EditWebcastModel';
import { MinuteMs } from 'rev-shared/date/Time.Constant';
import { isTimeToStart } from 'rev-shared/webcast/WebcastTimeCalculation';
import { lastValueFrom } from 'rev-shared/rxjs/lastValueFrom';

import { WEBCAST_LANDING_STATE, WEBCAST_SHORTCUT_STATE } from './Constants';

import { WebcastModel } from './model/WebcastModel';
import { WebcastStatus } from './WebcastStatus';

export interface IWebcastInfo {
	id: string;
	title: string;
	startDate: Date;
	endDate: Date;
	lobbyTimeMinutes?: number;
	canJoin: boolean;
	status: WebcastStatus;
	conflict?: boolean;
	preProductionDuration: string;
	preProductionDurationMs: number;
}
type TWebcast = IWebcastInfo | EditWebcastModel | WebcastModel;

@Injectable({
	'providedIn': 'root'
})
export class ShortcutService {
	constructor(
		private http: HttpClient,
		private $state: StateService,
		private DateParsers: DateParsersService
	) {}

	public getWebcasts(shortcutName: string, fromDate?: Date, toDate?: Date, includeEventHostName?: boolean): Promise<IWebcastInfo[]> {
		return lastValueFrom(this.http.get(`scheduled-events/shortcuts/${shortcutName}/events`, {
			params: {
				fromDate: fromDate?.toISOString(),
				toDate: toDate?.toISOString(),
				includeEventHostName: includeEventHostName ? 'true' : 'false'
			}
		}))
			.then(({ events }: { events: IWebcastInfo[] }) => {
				events.forEach(e => {
					e.startDate = this.DateParsers.parseUTCDate(e.startDate);
					e.endDate = this.DateParsers.parseUTCDate(e.endDate);
					e.preProductionDurationMs = this.DateParsers.parseTimespan(e.preProductionDuration);
				});

				events.sort((a, b) => b.startDate.getTime() - a.startDate.getTime());
				return events;
			});
	}

	public getWebcastShortcutSchedule(webcast: EditWebcastModel): Promise<IWebcastInfo[] & { hasConflict: boolean }> {

		const { shortcutName, startDate, endDate, lobbyTimeMinutes } = webcast;
		let fromDate = DateUtil.addMinutes(startDate, -lobbyTimeMinutes || 0);
		fromDate = DateUtil.addDays(fromDate, -1);
		const toDate = DateUtil.addDays(endDate, 1);

		return this.getWebcasts(shortcutName, fromDate, toDate, true)
			.then(shortcutEvents => {
				return shortcutEvents.reduce((result: any, e) => {
					if(e.id !== webcast.id ) {
						e.conflict = this.isShortcutConflict(webcast, e);
						result.hasConflict = result.hasConflict || e.conflict;
						result.push(e);
					}
					return result;
				}, []);
			});
	}

	public selectDefaultEvent(events: IWebcastInfo[]): IWebcastInfo {
		return events
			.filter(e => e.canJoin)
			.sort(compareEvents)[0];

		function compareEvents(a: IWebcastInfo, b: IWebcastInfo): number {
			const tA = a.startDate.getTime();
			const tB = b.startDate.getTime();
			const t = Date.now();
			const aFuture = tA > t;
			const bFuture = tB > t;
			const sortDateAsc = aFuture ? 1 : -1; //Future events sorted asc, past events desc

			return compare(isRunning(b.status), isRunning(a.status)) ||
				compare(isEligibleToRunNow(b), isEligibleToRunNow(a)) ||
				compare(bFuture, aFuture) ||
				(tA - tB) * sortDateAsc;
		}

		function isRunning(s: WebcastStatus): boolean {
			return s !== WebcastStatus.Scheduled
				&& s !== WebcastStatus.Deleted
				&& s !== WebcastStatus.Completed;
		}

		function isEligibleToRunNow(event: IWebcastInfo): boolean {
			return (event.status === WebcastStatus.Scheduled || event.status === WebcastStatus.Completed)
				&& isTimeToStart(event.startDate, event.endDate, event.lobbyTimeMinutes);
		}

		function compare(a: boolean, b: boolean): number {
			return +a - +b;
		}
	}

	public getShortcutUrl(webcast: { shortcutName: string }): string {
		if(!webcast || !webcast.shortcutName) {
			return;
		}

		const shortcutName = webcast.shortcutName.toLowerCase();
		return this.$state.href(WEBCAST_SHORTCUT_STATE, { shortcutName }, { absolute: true });
	}

	public getWebcastUrl(webcast: EditWebcastModel | WebcastModel): string {
		return this.getShortcutUrl(webcast)
			|| this.getAbsoluteUrl(webcast);
	}

	public getAbsoluteUrl({ id: webcastId }: EditWebcastModel | WebcastModel): string {
		return this.$state.href(WEBCAST_LANDING_STATE, { webcastId }, { absolute: true });
	}

	private isShortcutConflict(e1: TWebcast, e2: TWebcast): boolean {

		return DateUtil.isDateRangeOverlap(
			this.calculateStartDate(e1), e1.endDate,
			this.calculateStartDate(e2), e2.endDate);
	}

	public calculateStartDate(e: TWebcast): Date {
		function isWebcastModel(e: TWebcast): e is WebcastModel|EditWebcastModel {
			return !!(e as WebcastModel|EditWebcastModel).preProduction;
		}
		const preProdDurationMs = (isWebcastModel(e) ? e.preProduction.durationMs : e.preProductionDurationMs) || 0;
		const lobbyTimeMs = (e.lobbyTimeMinutes || 0) * MinuteMs;
		const startOffset = Math.max(preProdDurationMs, lobbyTimeMs);

		return new Date(e.startDate.getTime() - startOffset);
	}
}
