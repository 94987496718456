import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { DataGridModule } from 'rev-shared/ui/dataGrid/DataGrid.Module';
import { SearchInputModule } from 'rev-shared/ui/searchInputAngular/SearchInput.Module';
import { SpinnerAngularModule } from 'rev-shared/ui/spinner/SpinnerAngular.Module';
import { DirectivesAngularModule } from 'rev-shared/util/directives/DirectivesAngular.Module';

import { WebcastAttendeesGridComponent } from './WebcastAttendeesGrid.Component';
import { WebcastAttendeesGridWrapperComponent } from './WebcastAttendeesGridWrapper.Component';
import { WebcastAttendeesService } from './WebcastAttendees.Service';
import { AnalyticsModule } from 'rev-shared/analytics/Analytics.Module';

const components = [
	WebcastAttendeesGridComponent,
	WebcastAttendeesGridWrapperComponent
];

@NgModule({
	declarations: components,
	entryComponents: components,
	exports: components,
	imports: [
		AnalyticsModule,
		CommonModule,
		DataGridModule,
		DirectivesAngularModule,
		FormsModule,
		SearchInputModule,
		SpinnerAngularModule,
		TranslateModule
	],
	providers: [
		WebcastAttendeesService
	]
})
export class WebcastAttendeesGridModule {}
