import { Component, Input } from '@angular/core';

import { INotifications } from 'rev-portal/scheduledEvents/webcast/Webcast.Contract';
import { WebcastModel } from 'rev-portal/scheduledEvents/webcast/model/WebcastModel';

@Component({
	selector: 'vb-webcast-questions-sidebar',
	template: `
		<vb-webcast-manage-question-sidebar
			*ngIf="webcast.currentUser.canManageQuestions; else attendeeQuestions"
			[webcast]="(webcast.webcast$ | async)">
		</vb-webcast-manage-question-sidebar>
		<ng-template #attendeeQuestions>
			<vb-webcast-attendee-question-sidebar
				[webcast]="(webcast.webcast$ | async)"
				[sidebarNotifications]="sidebarNotifications">
			</vb-webcast-attendee-question-sidebar>
		</ng-template>
	`,
	host: {
		class: 'box-block height-full'
	}
})
export class VbWebcastQuestionsSidebarComponent {
	@Input() public webcast: WebcastModel;
	@Input() public sidebarNotifications: INotifications;
}
