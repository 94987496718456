import { NgZone } from '@angular/core';
import { Subscription } from 'rxjs';
import { map, distinctUntilChanged, pluck, switchMap } from 'rxjs/operators';

import { objectShallowEqual } from 'rev-shared/util';
import { FileWrapper } from 'rev-shared/ui/fileUpload/FileWrapper';
import { ThemeService } from 'rev-portal/branding/Theme.Service';
import { WebcastModel } from 'rev-portal/scheduledEvents/webcast/model/WebcastModel';

import { isUndefined, isNull, omit } from 'underscore';

export interface IWebcastBrandingSettings {
	headerColor?: string;
	headerFontColor?: string;
	primaryFontColor?: string;
	accentColor?: string;
	accentFontColor?: string;
	primaryColor?: string;
	logoImageId?: string;
	logoFile?: FileWrapper;
	logoUri?: string;
}


export function shapeWebcastToAccountBranding(webcastTheme: IWebcastBrandingSettings): any {
	return omit({
		headerSettings: omit({
			backgroundColor: webcastTheme.headerColor,
			fontColor: webcastTheme.headerFontColor
		}, (value, _key, _object) => isUndefined(value) || isNull(value)),
		themeSettings:  omit({
			accentColor: webcastTheme.accentColor,
			accentFontColor: webcastTheme.accentFontColor,
			primaryColor: webcastTheme.primaryColor,
			primaryFontColor: webcastTheme.primaryFontColor,
			logoImageId: webcastTheme.logoImageId,
			logoFile: webcastTheme.logoFile,
			logoUri: webcastTheme.logoUri
		}, (value, _key, _object) => isUndefined(value) || isNull(value))
	}, (value, _key, _object) => isUndefined(value) || isNull(value));
}

export function initializeWebcastTheme(webcast: WebcastModel, ThemeService: ThemeService, ngZone?: NgZone): Subscription {
	return webcast.webcast$
		.pipe(
			map((webcast: WebcastModel) => (
				{
					enableCustomBranding: webcast.enableCustomBranding,
					webcastBrandingSettings: webcast.webcastBrandingSettings
				}
			)),
			distinctUntilChanged((oldVal, newVal) => {
				return oldVal.enableCustomBranding === newVal.enableCustomBranding
					&& objectShallowEqual(oldVal.webcastBrandingSettings, newVal.webcastBrandingSettings);
			})
		)
		.subscribe(branding => {
			if (!branding.enableCustomBranding) {
				if (ThemeService.isCustomThemeActive) {
					ThemeService.endCustomThemeMode();
				}
				return;
			}

			ThemeService.setCustomThemeMode(shapeWebcastToAccountBranding(branding.webcastBrandingSettings), true);
		});

}
