<div class="width-full">
	<section [ngClass]="styles.sectionWrapper" class="theme-primary-font-border-fade-50">
		<h2>{{webcast.title}}</h2>
		<h4>{{webcast.startDate| vbDateTimeMedium}} -- {{webcast.endDate| vbDateTimeMedium}}</h4>
		<h4 *ngIf="webcastViewContext.infoSidebar.showHostName">{{ 'Host' | translate }}: {{ webcast.eventHostFullName }}</h4>
	</section>

	<section *ngIf="webcastViewContext.infoSidebar.showSharingUrl && !webcast.hideShareUrl" [ngClass]="styles.sectionWrapper" class="theme-primary-font-border-fade-50">
		<dl [ngClass]="styles.linkInfo">
			<dt>{{ 'Event_Webcast_InvitationJoinMsg' | translate }}</dt>
			<dd>
				<a class="theme-primary-txt" [href]="eventUrl">{{ eventUrl }}</a>
			</dd>

			<dt [hidden]="!webcast.password">{{ 'Password' | translate }}:</dt>
			<dd [hidden]="!webcast.password">{{ webcast.password }}</dd>
		</dl>
	</section>
	<section *ngIf="webcastViewContext.infoSidebar.showPPDownload && webcast.canDownloadPresentationFile" [ngClass]="styles.sectionWrapper" class="theme-primary-font-border-fade-50">
		<dl>
			<dt>{{ 'Event_PresentationFileDownloadLink' | translate }}</dt>
			<dd>
				<a [ngClass]="styles.supplementalContentItem" class="theme-primary-txt" download layout layout-wrap="false" [href]="webcast.presentation.downloadUrl">
						<span class="glyphicons filetype ppt theme-primary-txt" [ngClass]="styles.fileIcon">
						</span>
						<div flex="fill">
							<span class="supplemental-content-title">{{webcast.presentation.name}}</span>
						</div>
					</a>
			</dd>
		</dl>
	</section>

	<section [ngClass]="styles.sectionWrapper" class="theme-primary-font-border-fade-50" [hidden]="!(webcast.description)">
		<dl>
			<dt>{{ 'Description' | translate }}:</dt>
			<dd class="user-content" [innerHTML]="webcast.description | safeHtml"></dd>
		</dl>
	</section>
</div>
