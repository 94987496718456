import { Component, Input } from '@angular/core';
import { DecimalPipe } from '@angular/common';

import { TranslateService } from '@ngx-translate/core';

import { AnalyticsService } from 'rev-shared/analytics/Analytics.Service';
import { ILinkCellRendererParams } from 'rev-shared/ui/dataGrid/cellRenderers/LinkCellRenderer.Component';
import { mediumDateTimeValueFormatter } from 'rev-shared/ui/dataGrid/valueFormatters/MediumDateTimeValueFormatter';
import { numberValueFormatter, twoDecimalValueFormatter } from 'rev-shared/ui/dataGrid/valueFormatters/NumberValueFormatter';

import { WebcastModel } from 'rev-portal/scheduledEvents/webcast/model/WebcastModel';

import { RealTimeAnalyticsDevicesService } from './RealTimeAnalyticsDevices.Service';
import { RealTimeAnalyticsStatusHealthMetricsRendererComponent } from './RealTimeAnalyticsStatusHealthMetricsRenderer.Component';
import { RealTimeAnalyticsDeviceZoneRendererComponent } from './RealTimeAnalyticsDeviceZoneRenderer.component';

enum DevicesColumnPickerGroup {
	METRICS,
	SESSION,
	SYSTEM,
	DEVICE
}

@Component({
	selector: 'real-time-analytics-devices-tab',
	templateUrl: './RealTimeAnalyticsDevicesTab.Component.html',
	providers: [
		DecimalPipe,
		RealTimeAnalyticsDevicesService
	]
})
export class RealTimeAnalyticsDevicesTabComponent {
	@Input() public webcast: WebcastModel;

	public readonly DevicesColumnPickerGroup = DevicesColumnPickerGroup;
	public readonly RealTimeAnalyticsDeviceStatusRendererComponent = RealTimeAnalyticsStatusHealthMetricsRendererComponent;
	public readonly RealTimeAnalyticsDeviceZoneRendererComponent = RealTimeAnalyticsDeviceZoneRendererComponent;
	public readonly mediumDateTimeValueFormatter = mediumDateTimeValueFormatter;
	public readonly numberValueFormatter = numberValueFormatter;
	public readonly twoDecimalValueFormatter = twoDecimalValueFormatter;

	public readonly nameCellParams: ILinkCellRendererParams<any> = {
		getCellCfg: device => ({
			href: device.VbAdminUri,
			cssClass: 'util-no-underline',
			iconClass: 'glyphicons new_window',
			target: '_blank',
			isIconLink: true
		})
	};

	public readonly statusCellRendererParams = {
		getStatusDetails: data => ({
			overallStatus: data.Status,
			healthMetrics: data.RealTimeDmeStatus ? this.getDeviceHealthMetrics(data.RealTimeDmeStatus) : []
		})
	};

	constructor(
		private readonly numberPipe: DecimalPipe,
		private readonly translate: TranslateService,

		public rtaDevicesService: RealTimeAnalyticsDevicesService,
		public AnalyticsService: AnalyticsService
	) {}

	private getDeviceHealthMetrics(status: any): any[] {
		return [
			['Admin_Devices_Reports_CPU', status.CpuUsagePercent, status.CpuHealth],
			['Admin_Devices_Reports_Memory', status.MemoryUsagePercent, status.MemoryHealth],
			['Admin_Devices_Reports_Disk', status.DiskUsagePercent, status.DiskHealth],
			['Admin_Devices_Reports_Throughput', status.MpsThroughputPercent, status.ThroughputHealth]
		].map(([label, value, status]) => ({
			label: this.translate.instant(label),
			value: this.numberPipe.transform(value) + '%',
			status
		}));
	}

}
