import {
	Component,
	EventEmitter,
	Input,
	Output,
	ViewChild
} from '@angular/core';

import { VbConfirmationDialogComponent } from 'rev-shared/ui/dialog/VbConfirmationDialogAngular.Component';

import { WebcastModel } from '../model/WebcastModel';
import { WebcastService } from '../Webcast.Service';

import styles from './WebcastNav.module.less';

@Component({
	selector: 'webcast-nav',
	templateUrl: './WebcastNav.Component.html',
	host: {
		'[class]': 'hostClass'
	}
})
export class WebcastNavComponent {
	@Input() public webcast: WebcastModel;

	@Output() public onEndEvent: EventEmitter<any> = new EventEmitter();

	@ViewChild(VbConfirmationDialogComponent)
	public manualControlError: VbConfirmationDialogComponent;

	public readonly styles = styles;
	public hostClass = `${this.styles.root} theme-primary theme-primary-font-border-fade-50`;

	constructor(
		private WebcastService: WebcastService
	) {}

	public cancelAutomatedWebcast(): void {
		this.WebcastService.cancelScheduledAutomatedWebcast(this.webcast.id, this.webcast.currentRun.runNumber)
			.catch(() => this.manualControlError.open());
	}

	public endEvent(): void {
		this.onEndEvent.emit();
	}

	public toggleLayout(): void {
		this.webcast.layout.isVertical = !this.webcast.layout.isVertical;
	}
}
