import { Directive, OnDestroy, OnInit } from '@vbrick/angular-ts-decorators';
import {
	IAugmentedJQuery,
	IDocumentService,
	IRootScopeService,
	ITimeoutService,
	IWindowService
} from 'angular';
import { debounce } from 'underscore';

/**
 * Resize attribute for content that does not repaint - i.e graphs
 * TODO: probably eliminate once we move to amCharts. Don't want the $rootScope usage.
 */

@Directive({
	selector: '[vb-resize-window]'
})
export class ResizeWindowDirective implements OnInit, OnDestroy {
	private debounceResizeMethod: () => void;
	private viewContentLoadEvent: () => void;

	constructor(
		private $document: IDocumentService,
		private $rootScope: IRootScopeService,
		private $window: IWindowService,
		private $timeout: ITimeoutService,
		private $element: IAugmentedJQuery
	) {
		'ngInject';
	}

	public ngOnInit(): void {
		this.debounceResizeMethod = debounce(() => {
			this.fireResize();
		}, 0);

		/* When the DOM has completely finished being loaded, this will fire */
		this.viewContentLoadEvent = this.$rootScope.$on('$viewContentLoaded', this.debounceResizeMethod);

		/* This is needed for graphs that are used inside of Bootstrap tabs.
		 *	https://github.com/krispo/angular-nvd3/issues/40
		 */
		this.$element.filter('.uib-tab').on('click',
			() => this.$timeout(() => this.debounceResizeMethod(), 100));
	}

	public ngOnDestroy(): void {
		this.viewContentLoadEvent();
	}

	private fireResize(): void {
		this.$timeout(() => {
			let event;

			if (typeof(this.$window.Event) === 'function') { // modern browsers
				event = new this.$window.Event('resize');
			} else { // IE (deprecated)
				event = this.$document[0].createEvent('UIEvents');
				event.initUIEvent('resize', true, false, this.$window, 0);
			}
			this.$window.dispatchEvent(event);
		}, 1000);
	}
}
