import {
	Component,
	Input,
	Output
} from '@vbrick/angular-ts-decorators';

import { ComponentCallback } from 'rev-shared/ts-utils/ComponentCallback';

import './vbMediaTable.less';

@Component({
	selector: 'vb-media-table',
	transclude: true,
	template: `<div ng-transclude></div>`
})
export class VbMediaTableComponent {
	@Input() public columns: any[];
	@Input() public sortField: string;
	@Input() public sortAscending: boolean;
	@Input() public isSortDisabled: boolean;

	@Output() public onColumnHeaderClick: ComponentCallback;
}
