import { NgModule } from '@angular/core';

import { VbrickPlayerModule } from '@vbrick/vbrick-player';

import { HlsFileUtilService } from './HlsFileUtil.Service';

@NgModule({
	imports: [
		VbrickPlayerModule
	],
	providers: [
		HlsFileUtilService
	]
})
export class HlsFileUtilModule {}
