import { NgModule } from '@vbrick/angular-ts-decorators';

import MobileMenuComponent from './MobileMenu.Component';

@NgModule({
	id: 'VBrick.Portal.Navigation.MobileMenu',
	declarations: [
		MobileMenuComponent
	]
})

export default class MobileMenuModule {}
