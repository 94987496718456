import { Component, Input } from '@angular/core';

import { WebcastModel } from '../model/WebcastModel';

@Component({
	selector: 'webcast-pre-prod-banner',
	templateUrl: './WebcastPreProdBanner.Component.html'
})
export class WebcastPreProdBannerComponent {
	@Input() public webcast: WebcastModel;
}
