import { Component, Input, OnInit, SimpleChanges } from '@angular/core';

import { IRules } from 'rev-shared/ui/css/CssRules.Contract';
import { VbFlickityCarouselComponent } from 'rev-shared/ui/flickityCarousel/VbFlickityCarousel.AngularComponent';

import { BrandingSettings } from 'rev-portal/branding/BrandingSettings.Contract';
import { IPollAnswer } from 'rev-portal/scheduledEvents/polls/Polls.Contract';
import { Poll } from 'rev-portal/scheduledEvents/polls/Poll';
import { Polls, POLL_ANSWERS_DEFAULT } from 'rev-portal/scheduledEvents/polls/Polls';
import { WebcastModel } from 'rev-portal/scheduledEvents/webcast/model/WebcastModel';

enum POLLS_VIEW {
	List = 'list',
	Edit = 'edit'
}

type TPollData = Poll & { active: boolean };

//Todo - convert to module.less later
import './vb-webcast-polls-view.less';
import styles from './VbWebcastPollsView.Component.module.less';

@Component({
	selector: 'vb-webcast-polls-view',
	templateUrl: './VbWebcastPollsView.Component.html',
	host: {
		'[class]': 'styles.root'
	}
})
export class VbWebcastPollsViewCompoment implements OnInit {
	@Input() public polls: Polls;
	@Input() public $polls: any[]; //workaround to get notifyed when polls collection changed. will work on observable later.
	@Input() public brandingSettings: BrandingSettings;

	public pollToEdit: TPollData;
	public status: {[key: string]: boolean};
	public themeStyleOverrides: IRules;
	public view = POLLS_VIEW.List;
	public webcast: WebcastModel;
	public flickityCarousel: VbFlickityCarouselComponent;
	public flickitySelectedIndex: number;
	public flickityConfig: any;
	public prevPollsLength: number;

	public readonly POLLS_VIEW = POLLS_VIEW;
	public readonly styles = styles;

	public ngOnInit(): void {
		this.webcast = this.polls.webcast;
		this.status = { 'loaded': true };
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if(changes.brandingSettings && this.brandingSettings) {
			this.applyCssRules();
		}
		if (changes.$polls && this.$polls) {
			this.onChangePollsCollection();
		}

	}

	public onSelectFlickity(): void {
		this.flickitySelectedIndex = this.flickityCarousel?.selectedIndex;
	}

	public onFlickityReady(carousel: VbFlickityCarouselComponent): void {
		this.flickityCarousel = carousel;
	}

	public onAssignFlickityConfig(pollsLength: number): void {
		this.flickityConfig = {
			cellAlign: 'left',
			draggable: false,
			pageDots: true,
			wrapAround: false,
			initialIndex: this.flickitySelectedIndex || 0,
			prevNextButtons: pollsLength > 1
		};
	}

	private applyCssRules(): void {
		const accentColor = this.brandingSettings.themeSettings.accentColor;
		const accentFontColor = this.brandingSettings.themeSettings.accentFontColor;
		const primaryFontColor = this.brandingSettings.themeSettings.primaryFontColor;

		this.themeStyleOverrides = {
			'vb-webcast-polls-view .dot': {
				'border-color': primaryFontColor,
				'background-color': ` ${primaryFontColor} !important`
			},
			'vb-webcast-polls-view .dot.is-selected': {
				'background-color': ` ${accentColor} !important`,
				'border': `1px solid ${accentFontColor}`
			}
		};
	}

	private onChangePollsCollection(): void {
		this.polls.onPollsCollectionChanged();

		//This is to rebuild flickty once polls collection length changed.
		const pollsLen = this.$polls?.length;
		if (this.prevPollsLength !== pollsLen) {
			this.status = { 'isLoading': true };
			this.prevPollsLength = pollsLen;
			setTimeout(() => {
				this.onAssignFlickityConfig(pollsLen);
				this.status = { 'loaded': true };
			}, 100);
		}
	}

	public setEditView(poll: Poll): void {
		this.pollToEdit = this.getPollToEdit(poll);
		this.view = POLLS_VIEW.Edit;
	}

	public setCreateView(): void {
		this.pollToEdit = this.getPollToEdit();
		this.view = POLLS_VIEW.Edit;
	}

	public deletePoll(poll: Poll): void {
		this.polls.delete(poll);
	}

	public savePollForm(poll: Poll): void {
		this.pollToEdit = null;
		this.stripEmptyAnswers(poll);
		poll.active = true;

		if (poll.isNew) {
			poll.isNew = false;
			poll.active = true;
			this.polls.add(poll);
		} else {
			const pollToUpdate = (this.polls.$polls || []).find(p => p.id === poll.id);
			Object.assign(pollToUpdate, poll);
			this.polls.update(pollToUpdate);
		}
		this.setListView();
	}

	private setListView(): void {
		this.view = POLLS_VIEW.List;
	}

	private getPollToEdit(poll?: Poll): TPollData {
		const answersRangeCount = poll?.answers?.length ? Math.max(poll.answers.length, POLL_ANSWERS_DEFAULT) : POLL_ANSWERS_DEFAULT;
		const answersRange = Array.from({ length: answersRangeCount }, (_, index) => index);

		return Object.assign({
			isNew: !poll,
			active: false,
			multipleChoice: poll?.multipleChoice ?? false
		}, poll, {
			totalResponses: 0,
			answers: answersRange.map(i => {
				const { text } = (poll && poll.answers && poll.answers[i]) || {} as IPollAnswer;
				return { text, count: 0 };
			})
		});
	}

	private stripEmptyAnswers(poll: Poll): void {
		poll.answers = (poll.answers || []).filter(answer => answer.text);
	}
}
