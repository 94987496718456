<div layout flex="fill" [ngClass]="styles.openQuestionWrapper" (click)="showSubmitForm=true" [hidden]="showSubmitForm">
	<div type="text" name="name" [ngClass]="styles.input" [attr.aria-label]="'Event_Chat_MessageText' | translate " uiControl vbUiTextInput>
	</div>
	<button type="button" vbUiBtnPrimaryNgx [ngClass]="styles.submitBtn">
		<span class="glyphicons comments"></span> {{ 'UI_Submit' | translate }}
	</button>
</div>

<form #form="ngForm" [hidden]="!showSubmitForm" [ngClass]="styles.form" class="theme-primary-font-border-fade-50" (submit)="submitQuestion()">
	<textarea name="question" [ngClass]="styles.textArea" [attr.aria-label]="'Event_QA_AskAQuestion_Text' | translate" [(ngModel)]="questionText" required rows="2" [placeholder]="'Event_QA_AskAQuestion' | translate" autocomplete="off" autofocus vbUiTextArea>
	</textarea>
	<div class="padding-top-10">
		<vb-ui-checkbox [hidden]="webcast.questionOption === WebcastQuestionOption.IDENTIFIED" [ngClass]="styles.anonymousChkBox" name="postAnonymously" [(ngModel)]="isAnonymous" [disabled]="webcast.questionOption === WebcastQuestionOption.ANONYMOUS">
			{{ 'Event_Webcast_PostAnonymously' | translate }}
		</vb-ui-checkbox>
		<div class="box-inline-block float-right">
			<a class="theme-primary-txt" (click)="reset()">{{ 'Cancel' | translate }}</a>
			<button type="submit" vbUiBtnPrimaryNgx class="margin-left-15" [disabled]="form.invalid">
				<span class="glyphicons comments"></span> {{ 'UI_Submit' | translate }}
			</button>
		</div>
	</div>
</form>
