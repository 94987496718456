import { NgModule } from '@vbrick/angular-ts-decorators';
import UibModalModule from 'angular-ui-bootstrap/src/modal';

import CheckboxModule from 'rev-shared/ui/checkbox/Checkbox.Module';
import VBrickFormDirectivesModule from 'rev-shared/util/directives/form/FormDirectives.Module';

import { DialogProvider } from './Dialog.Provider';
import { DialogConfig } from './ConfirmationDialog.Config';
import { ConfirmationDialogComponent } from './ConfirmationDialog.Component';
import { UibResolveService } from './UibResolve.Service';
import { IDialogProvider } from './IDialog';
import { VbConfirmationDialogComponent } from './VbConfirmationDialog.Component';
import angular from 'angular';
import { downgradeInjectables } from 'rev-shared/util/AngularHybridUtils';
import { DialogService } from './Dialog.Service';

let nextId = 0;
const dialogProvider = angular.module('VBrick.UI.Dialog.Provider', [])
	//TODO, not portable, but angular-ts-decorators no longer exposes a way to register angularjs provider class.
	//For angular this would either be a multi-provider that any module can use by adding dialogs as providers.
	//Or, the ui router way is to expose a helper method that generates an angular module for a dialog cfg
	.provider('Dialog', () => new DialogProvider())
	.name;

const moduleId = 'Vbrick.UI.Dialog';

@NgModule({
	id: moduleId,
	imports: [
		UibModalModule,
		CheckboxModule,
		VBrickFormDirectivesModule,
		dialogProvider
	],
	declarations: [
		ConfirmationDialogComponent,
		VbConfirmationDialogComponent
	],
	providers: [
		UibResolveService
	]
})
export default class DialogModule {
	public static config(
		DialogProvider: IDialogProvider,
		$uibResolveProvider: any
	): void {
		'ngInject';

		$uibResolveProvider.setResolver('UibResolve');

		DialogConfig(DialogProvider);

	}

	/**
	 * Use forRoot once, in the root module imports. Everywhere else should use forChild
	 * Basic explanation of forRoot/forChild pattern:  https://angular.io/guide/ngmodule-faq#what-is-the-forroot-method
	 */
	public static forRoot(dialogConfigs?: any[]): string {
		return DialogModule.forChild(dialogConfigs);
	}

	/**
	 * Adds dialog configuration to the Dialog service. Returns a module to be imported.
	 */
	public static forChild(dialogConfigs?: any[]): string {
		const id = nextId++; //Needed to let the child module work as a lazy loaded.

		//TODO: return an anonymous child module, see ModuleWithProviders interface
		//When implemented, this should only configure the new dialogs,
		//but not import the rest of the module.
		return angular.module(`${moduleId}.${id}`, [moduleId])
			.config((DialogProvider: IDialogProvider): void => {
				'ngInject';
				if(dialogConfigs) {
					dialogConfigs.forEach(cfg => {
						DialogProvider.when(cfg.name, cfg);
					});
				}
			})
			.name;
	}
}

downgradeInjectables(DialogModule, [
	{ name: 'DialogService', injectable: DialogService }
]);
