import { Component, Input } from '@angular/core';

import styles from './vb-poll-trend.module.less';
import 'rev-less/graphs.less';

@Component({
	selector: 'vb-poll-trend',
	templateUrl: './VbPollTrend.Component.html',
	host: {
		class: 'poll-trends theme-accent-border'
	}
})

export class VbPollTrendComponent {
	@Input() public csvTitle: string;
	@Input() public polls: any[];
	@Input() public pollTitle: string;

	public readonly styles = styles;
}
