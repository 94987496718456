import {
	AfterViewInit,
	Component,
	DoCheck,
	Input,
	OnDestroy,
	OnInit,
	ViewChild
} from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import $ from 'jquery';
import { TranslateService } from '@ngx-translate/core';

import { StateService, TransitionService } from '@uirouter/angular';

// import { RxWindowLocationService } from 'rev-shared/uiRouterUtils/RxWindowLocation.Service';
import { ISidebarConfig } from 'rev-shared/ui/sidebarLayoutAngular/ISidebarConfig';
import { ISidebarToggleEvent } from 'rev-shared/ui/sidebarLayout/Sidebar.Component';
import { IUnsubscribe } from 'rev-shared/push/IUnsubscribe';
import { LoginRedirectService } from 'rev-shared/security/LoginRedirect.Service';
import { MediaQueryService } from 'rev-shared/ui/size/MediaQuery.Service';
import { MaxWidthSmallMediaQuery } from 'rev-shared/ui/size/Size.Constants';
import { PushBus } from 'rev-shared/push/PushBus.Service';
import { VbConfirmationDialogComponent } from 'rev-shared/ui/dialog/VbConfirmationDialogAngular.Component';
import { IConfirmationDialogActionData } from 'rev-shared/ui/dialog/IConfirmationDialogActionData';
import { isMobileSafariWithDialogBlocking } from 'rev-shared/util/UserAgentUtil';

import { INotifications } from 'rev-portal/scheduledEvents/webcast/Webcast.Contract';
import { MainHeaderPositionService } from 'rev-portal/navigation/mainHeader/MainHeaderPosition.Service';
import { WebcastModel } from 'rev-portal/scheduledEvents/webcast/model/WebcastModel';
import { WebcastService } from 'rev-portal/scheduledEvents/webcast/Webcast.Service';
import { WebcastVideoSource } from 'rev-portal/scheduledEvents/webcast/WebcastStatus';
import { WebcastSidebarNotification } from 'rev-portal/scheduledEvents/webcast/WebcastSidebarNotification';
import { WEBCAST_SIDEBAR_NOTIFICATION_EVENT } from 'rev-portal/scheduledEvents/webcast/WebcastSidebarNotification';

import { WebcastViewSidebarButton } from './WebcastViewSidebarButtonModel';
import { IWebcastViewContext } from './IWebcastViewContext';
import { WEBCAST_LANDING_STATE, WEBCAST_VIEW_STATE } from 'rev-portal/scheduledEvents/webcast/Constants';

import styles from './VbWebcastView.Component.module.less';
import 'rev-less/events/webcast-main.less';
import 'rev-less/video/video-main.less'; //refactored player related css later.

const NOTIFICATIONS_SIDEBARS = [
	WebcastViewSidebarButton.CHAT,
	WebcastViewSidebarButton.POLLS,
	WebcastViewSidebarButton.QA
];

@Component({
	selector: 'vb-webcast-view',
	templateUrl: './VbWebcastView.Component.html'
})
export class VbWebcastViewComponent implements OnInit, OnDestroy, DoCheck, AfterViewInit {
	@Input() public readonly accountId: string;
	@Input() public webcast: WebcastModel;
	@Input() public sidebarNotifications: INotifications;
	@Input() public webcastViewContext: IWebcastViewContext;

	public readonly styles = styles;
	public readonly WebcastViewSidebarButton = WebcastViewSidebarButton;

	public activeSidebarButtonId: string;
	public isMobile: boolean;
	public status: { [key: string]: boolean };
	public sidebarConfig: ISidebarConfig;
	public viewContext: IWebcastViewContext;
	public inlineEdit: boolean;

	private pushSubscription: Subscription;
	private webcastSubscription: Subscription;
	private mediaQuerySubscription: Subscription;
	private rxjsSubscriptions: Subscription[] = [];
	private locationChangeSub: Subscription;
	private transitionStartHandler: () => void;
	private vcFailed: boolean;

	constructor(
		private $state: StateService,
		private $transitions: TransitionService,
		private LoginRedirectService: LoginRedirectService,
		private PushBus: PushBus,
		private translateService: TranslateService,
		// private RxWindowLocation: RxWindowLocationService, //check its usage.
		private WebcastService: WebcastService,
		private MainHeaderPosition: MainHeaderPositionService,
		private MediaQueryService: MediaQueryService
	){}

	public ngOnInit(): void {
		this.initialize();
		this.addSidebarNotificationEventHandler();
		this.webcastSubscription = this.webcast.webcast$
			.subscribe(() => {
				this.assignSidebarModel();
			});

		this.mediaQuerySubscription = this.MediaQueryService.getObservable(MaxWidthSmallMediaQuery)
			.subscribe(isMobile => {
				this.isMobile = isMobile;
				this.assignSidebarModel();
			});

		this.rxjsSubscriptions.push(this.webcastSubscription, this.mediaQuerySubscription);
	}
	public ngAfterViewInit(): void {
		setTimeout(() => this.configureNavigationHandlers());
	}
	public ngOnDestroy(): void {
		this.removeNavigationHandlers();
		this.pushSubscription?.unsubscribe();
		this.removeSidebarNotificationEventHandler();
		this.rxjsSubscriptions.forEach(subs => subs?.unsubscribe());
	}

	public ngDoCheck(): void {
		const webcast = this.webcast;
		const vcStatus = webcast.vcStatus;

		if(!this.vcFailed &&
			webcast.currentUser.isEventAdmin &&
			(vcStatus.recordingFailed || vcStatus.connectionFailed) ) {

			this.vcFailed = true;
			this.onWebcastStopped();
		}
	}

	public leaveWebcast(): void {
		const msg = this.webcast.currentUser.canControlEventStatus ?
			this.translateService.instant('Event_EndWebcastWarning') : this.translateService.instant('Event_LeaveWebcastWarning');

		if(this.confirm(msg)) {
			this.leaveAndCloseWebcast();
		}
	}

	public closeRightSidebar(): void {
		this.webcast.layout.isRightSidebarOpen = false;
	}

	public onToggleSidebar(event: ISidebarToggleEvent): void {
		if (!event.activeSidebarButtonId) { //same, then toggle
			this.webcast.layout.isRightSidebarOpen = false;
		} else { //different, so always open
			this.webcast.layout.isRightSidebarOpen = true;

			//enable notifications on prior id
			const sidebar = this.getSidebarNotification(this.activeSidebarButtonId);
			if (sidebar) {
				sidebar.isEnabled = true;
			}
		}

		const notification = this.getSidebarNotification(event.activeSidebarButtonId || this.activeSidebarButtonId);
		if (notification) {
			notification.isEnabled = !this.webcast.layout.isRightSidebarOpen;
		}

		this.activeSidebarButtonId = event.activeSidebarButtonId;
	}

	private initialize(): void {
		this.pushSubscription = this.getPushSubscription().subscribe();
		this.viewContext = {
			showNavBar: true,
			showPresentationSlides: true,
			infoSidebar: {
				showSharingUrl: true,
				showPPDownload: true,
				showHostName: false
			},
			...this.webcastViewContext
		};

		this.status = { active: true };

	}

	public closeWebcast(): void {
		this.$state.go(WEBCAST_LANDING_STATE, { id: this.webcast.id, blockAutoJoin: true });
	}

	private leaveAndCloseWebcast(discard: boolean = false): void {
		this.status = { processing: true };
		this.WebcastService.leave(this.webcast, discard)
			.then(() => this.onWebcastStopped())
			.catch(() => this.status = { error: true });
	}

	private onWebcastStopped(): void {
		this.removeNavigationHandlers();
		this.closeWebcast();
	}

	private configureNavigationHandlers(): void {
		//Check later.
		// this.locationChangeSub = this.RxWindowLocation.locationChangeStart$.subscribe(data => {
		// 	if (this.canLeave('', data.newUrl)){
		// 		this.leave();
		// 	} else {
		// 		data.event.preventDefault();
		// 	}
		// });

		this.transitionStartHandler = this.$transitions.onStart({}, transition => {
			if (this.isChildState(transition.to().name)) {
				return;
			}

			if (this.canLeave(transition.to().name)) {
				this.leave();
			} else {
				return false;
			}
		}) as () => void;

		$(window).on('beforeunload', this.confirmUnload);
	}

	private readonly confirmUnload = () => {
		if(this.webcast.webcastStatus.isEventAdminConnected){
			return this.getNavMsg();
		}
	};

	private canLeave(toStateName: string, toHref: string = ''): boolean {
		return !this.webcast.webcastStatus.isEventAdminConnected ||
			this.webcast.currentUser.isRemovedFromWebcast ||
			this.LoginRedirectService.isLoginState(toStateName) ||
			this.LoginRedirectService.isLoginHref(toHref) ||
			confirm(this.getNavMsg());
	}

	private leave(): void {
		this.removeNavigationHandlers();
	}

	private removeNavigationHandlers(): void {
		this.locationChangeSub?.unsubscribe();
		this.transitionStartHandler?.();
		this.confirmUnload && $(window).off('beforeunload', this.confirmUnload);
	}

	private getNavMsg(): string {
		return this.translateService.instant(this.webcast.currentUser.isEventAdmin ? 'Event_EventAdminDisconnectWarning' : 'Event_LeaveWebcastWarning');
	}

	private getPushSubscription(): Observable<void> {
		return this.webcast.currentUser.isEventAdmin$.pipe(
			switchMap(isAdmin => {
				return new Observable<void>(() => isAdmin ? this.subscribeEventAdminPush() : this.subscribePush());
			})
		);
	}

	private subscribeEventAdminPush(): IUnsubscribe {
		return this.PushBus.composeUnsubscribeFn([
			this.subscribePush(),

			this.webcast.presentationProfileDeviceId &&
				this.PushBus.subscribe(this.webcast.presentationProfileDeviceId, 'Webcast.EventAdmin', {
					DeviceOffline: () => {
						if (this.webcast.startingVideoSource) {
							this.webcast.webcastStatus.isCancelled = true;
							this.onWebcastStopped();
						}
					}
				}),
		]);
	}

	private subscribePush(): IUnsubscribe {
		const onEnded = () => {
			if(this.webcast.currentRun.isPreProduction || this.webcast.currentUser.isEventAdminOrModerator) {
				this.onWebcastStopped();
			}
		};

		return this.PushBus.subscribe(this.webcast.id, '', {
			ScheduledEventStopped: onEnded,
			WebcastEnded: onEnded
		});
	}

	private isChildState(stateName: string): boolean {
		return stateName.startsWith(WEBCAST_VIEW_STATE);
	}

	private confirm(msg: string): boolean {
		const isConfirmAllowed = !isMobileSafariWithDialogBlocking();
		return !isConfirmAllowed || window.confirm(msg);
	}

	private assignSidebarModel(): void {
		this.sidebarConfig = this.getWebcastViewSidebarModel();
	}

	private getWebcastViewSidebarModel(): ISidebarConfig {
		return {
			buttons: [
				{
					id: WebcastViewSidebarButton.RTA,
					iconClass: 'vb-icon vb-icon-realtime-analytics',
					label: this.translateService.instant('Event_RealTimeAnalytics_Dashboard'),
					visible: !this.isMobile && this.webcast.currentUser.isEventAdminOrModerator,
					uiSref: 'webcast-analytics',
					uiParams: { webcastId: this.webcast.id }
				},
				{
					id: WebcastViewSidebarButton.ATTENDEES,
					iconClass: 'glyphicons user',
					label: this.translateService.instant('Event_Attendees'),
					visible: this.webcast.currentUser.canViewAttendees
				},
				{
					id: WebcastViewSidebarButton.CHAT,
					iconClass: 'chat',
					label: this.translateService.instant('Event_GeneralChat'),
					visible: this.webcast.isChatEnabled(),
					notification: {
						count: this.chatNotificationCount
					}
				},
				{
					id: WebcastViewSidebarButton.POLLS,
					iconClass: 'charts',
					visible: this.webcast.isPollsEnabled(),
					label: this.translateService.instant('Event_Polls'),
					notification: {
						enabled: this.isPollsNotificationEnabled
					}
				},
				{
					id: WebcastViewSidebarButton.QA,
					iconClass: 'vb-icon vb-icon-center vb-icon-q-and-a',
					visible: this.webcast.isQuestionAndAnswerEnabled(),
					label: this.translateService.instant('Event_QuestionsAndAnswers'),
					notification: {
						count: this.questionsNotificationCount
					}
				},
				{
					id: WebcastViewSidebarButton.INFO,
					iconClass: this.webcast.currentUser.canEdit ? 'cogwheel' : 'circle_info',
					label: this.translateService.instant('Event_Webcast_InformationPanelHeader')
				}
			],
			defaultClickedButtonId: this.isMobile ? undefined : WebcastViewSidebarButton.INFO
		};
	}

	private get isPollsNotificationEnabled(): boolean {
		return !this.webcast.currentUser.canManagePolls && this.sidebarNotifications?.polls?.isEnabled && this.sidebarNotifications?.polls?.notificationCount > 0;
	}

	private get chatNotificationCount(): number | string {
		return this.sidebarNotifications?.chat?.notificationCount <= 99 ? this.sidebarNotifications?.chat?.notificationCount : '99+';
	}
	private get questionsNotificationCount(): number | string {
		return this.sidebarNotifications?.questions?.notificationCount <= 99 ? this.sidebarNotifications?.questions?.notificationCount : '99+';
	}

	public get height(): string {
		// mobile height is not reliably conveyed by 100vh
		if (this.isMobile) {
			const mainHeaderHeight: number = this.MainHeaderPosition.height || 0;
			const calculatedHeight: number = window.innerHeight - mainHeaderHeight;

			return calculatedHeight + 'px';
		}

		return this.MainHeaderPosition.height ? `calc(100vh - ${this.MainHeaderPosition.height}px)` : '100vh';
	}

	private addSidebarNotificationEventHandler(): void {
		NOTIFICATIONS_SIDEBARS.forEach(button =>
			this.getSidebarNotification(button)?.on(WEBCAST_SIDEBAR_NOTIFICATION_EVENT, () => this.assignSidebarModel())
		);
	}

	private getSidebarNotification(buttonType: string): WebcastSidebarNotification {
		return this.sidebarNotifications[buttonType];
	}

	private removeSidebarNotificationEventHandler(): void {
		NOTIFICATIONS_SIDEBARS.forEach(button =>
			this.getSidebarNotification(button)?.removeAllListeners(WEBCAST_SIDEBAR_NOTIFICATION_EVENT)
		);
	}

}
