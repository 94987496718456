<div class="width-full">
	<header class="theme-accent-border">
		<h1 class="title-dark util-uppercase" [ngClass]="styles.headerTitle">{{ pollTitle }}</h1>
	</header>
	<section class="flex-row poll">
		<article class="poll-container result-view" *ngFor="let poll of polls">
			<header class="padding-left-10">
				<h4 class="poll-title">{{ poll.question }}</h4>
			</header>
			<div class="results-container" [ngClass]="styles.resultsContainer">
				<div *ngFor="let answer of poll.answers" class="main poll-responses">
					<p>{{ answer.text }}</p>
					<span>{{answer.count}}</span>
				</div>
			</div>
			<footer>
				<div class="footer-cell">
					<small>{{ 'Event_Responses' | translate }}:</small>
					<span class="footer-text-large">{{poll.totalResponses}}</span>
				</div>
				<div class="footer-cell">
					<small>{{ 'Event_MultipleChoice' | translate }}</small>
					<span class="footer-text-large" [hidden]="!(poll.multipleChoice )">{{ 'Yes' | translate }}</span>
					<span class="footer-text-large" [hidden]="poll.multipleChoice ">{{ 'No' | translate }}</span>
				</div>
			</footer>
		</article>
	</section>
</div>
