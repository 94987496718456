import { Injectable } from '@vbrick/angular-ts-decorators';
import {
	IInterpolateService,
	copy
} from 'angular';

import {
	NONE,
	UNCATEGORIZED
} from 'rev-shared/media/MediaConstants';

import { SearchQueryBuilder } from 'rev-portal/search/SearchQueryBuilder';

import {
	DateRangeFilter,
	Filter,
	MultiValueFilter,
	RangeFilter,
	TextFilter
} from './SearchFilterTypes';

class CategoryFilter extends MultiValueFilter {
	public addToQuery(queryBuilder: SearchQueryBuilder) {
		if (this.value && this.value.length) {
			if (this.value[0] === UNCATEGORIZED) {
				return queryBuilder.noValues(this.searchField, true);
			}
			queryBuilder.values(this.searchField, this.getQueryValue());
		}
	}
}

class TeamFilter extends MultiValueFilter {
	public addToQuery(queryBuilder: SearchQueryBuilder) {
		if (this.value && this.value.length) {
			if (this.value[0].name === NONE) {
				return queryBuilder.noValues(this.searchField, true);
			}
			queryBuilder.values(this.searchField, this.getQueryValue());
		}
	}
}

const staticFilters = {
	title: new TextFilter({ searchField: 'Title' }),

	description: new TextFilter({ searchField: 'IndexedDescription' }),

	uploaderUserId: new MultiValueFilter({
		searchField: 'UploaderUserId',
		queryProperty: 'id',
		formatter(user){
			return user.name;
		}
	}),

	userTags: new MultiValueFilter({
		searchField: 'UserTags',
		queryProperty: 'id',
		formatter(user){
			return user.name;
		}
	}),

	whenUploaded: new DateRangeFilter({
		searchField: 'WhenUploaded',
		formatterFactory($interpolate: IInterpolateService, filterTranslations: any) {
			'ngInject';

			const formatFrom = $interpolate(filterTranslations.dateRangeFrom);
			const formatTo = $interpolate(filterTranslations.dateRangeTo);

			return (value: any) => {
				const from = value.from ? formatFrom({ value }) : '';
				const to = value.to ? formatTo({ value }) : '';

				return [from, to].join(' ');
			};
		}
	}),

	is360: new Filter({
		searchField: 'Is360',
		formatterFactory(filterTranslations: any) {
			'ngInject';

			return (value: any) => {
				return value === 'true' ? filterTranslations.is360 : filterTranslations.not360;
			};
		}
	}),

	unlisted: new Filter({
		searchField: 'Unlisted',
		formatterFactory(filterTranslations: any) {
			'ngInject';

			return (value: any) => {
				return value === 'true' ? filterTranslations.unlisted : filterTranslations.notUnlisted;
			};
		}
	}),

	isLive: new Filter({
		searchField: 'IsLive',
		formatterFactory(filterTranslations: any) {
			'ngInject';

			return (value: any) => {
				return value ? filterTranslations.live : filterTranslations.vod;
			};
		}
	}),

	isActive: new Filter({
		searchField: 'IsActive',
		formatterFactory(filterTranslations) {
			'ngInject';

			return (value: any) => {
				return value ? filterTranslations.active : filterTranslations.inactive;
			};
		}
	}),

	approvalStatus: new Filter({ searchField: 'Approval.status' }),

	stepId: new MultiValueFilter({ searchField: 'Approval.stepId' }),

	categoryIds: new CategoryFilter({ searchField: 'CategoryIds' }),

	teamIds: new TeamFilter({
		searchField: 'TeamIds',
		queryProperty: 'teamId',

		formatter(team: any): any {
			return team.name;
		}
	}),

	expiryDateExists: new RangeFilter({ searchField: 'expiryDate' })
};

@Injectable('SearchFilterDefinitions')
export class SearchFilterDefinitionsService {
	public getStaticFilters(): { [key: string]: Filter } {
		return copy(staticFilters);
	}
}
