import { Component, OnInit, OnDestroy, Input } from '@vbrick/angular-ts-decorators';
import { Subscription } from 'rxjs';

import { ThemeService } from 'rev-portal/branding/Theme.Service';

import { SignalRHubsConnection, SignalRHubsConnectionState } from 'rev-shared/push/SignalRHubsConnection';
import { UtilService } from 'rev-shared/util/Util.Service';

@Component({
	selector: 'portal-app',
	template: `
		<vb-theme-styles [branding-settings]="$ctrl.ThemeService.brandingSettings">
		</vb-theme-styles>
		<vb-loading-spinner ng-hide="::$ctrl.pushConnectionInitialized" [block]="true" [size]="'large'"></vb-loading-spinner>
		<div ui-view>
		</div>
		<div class="alert alert-warning push-status-message-container ng-hide" ng-hide="$ctrl.pushConnectionHealthy || !$ctrl.pushConnectionInitialized">
			<span class="warning">
				{{$ctrl.connectionWarningMessage}}
			</span>
		</div>
	`
})
export class PortalAppComponent implements OnInit, OnDestroy {
	@Input() public connectionWarningMessage: string;
	private userContextChangeSubscription: Subscription;

	public pushConnectionHealthy: boolean;
	public pushConnectionInitialized: boolean;

	constructor(
		private SignalRHubsConnection: SignalRHubsConnection,
		private ThemeService: ThemeService,
		public Util: UtilService
	) {
		'ngInject';
	}

	public ngOnInit(): void {
		this.initializeSignalR();
	}

	public ngOnDestroy(): void {
		this.userContextChangeSubscription.unsubscribe();
	}

	private initializeSignalR(): void {
		const transport = this.Util.decodeQueryString().transport;
		const options = {
			waitForPageLoad: false,
			reconnectRetryAttempts: 360,
			reconnectRetryDelay: 5000,
			transport
		};
		this.SignalRHubsConnection.start(options);


		this.SignalRHubsConnection.on({
			stateChanged: event => {
				this.pushConnectionHealthy =
					!this.SignalRHubsConnection.started ||
					event.newState === SignalRHubsConnectionState.Connected;

				if(this.pushConnectionHealthy) {
					this.pushConnectionInitialized = true;
				}
			}
		});
	}
}
