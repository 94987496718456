import { NgModule } from '@vbrick/angular-ts-decorators';
import { StateProvider } from '@uirouter/angularjs';

import BrandingModule from 'rev-portal/branding/Branding.Module';

import { DownloadDetailsStateConfig } from './StateConfig';

import { DownloadDetailsComponent } from './DownloadDetails.Component';

@NgModule({
	id: 'VBrick.Portal.DownloadDetails',
	declarations: [
		DownloadDetailsComponent
	],
	imports: [
		BrandingModule
	]
})
export class DownloadDetailsModule {

	public static config($stateProvider: StateProvider): void {
		'ngInject';
		DownloadDetailsStateConfig($stateProvider);
	}
}
