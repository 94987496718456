import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject, Observable, Subscription, combineLatest } from 'rxjs';
import { map, tap, switchMap } from 'rxjs/operators';

import { PushBus } from 'rev-shared/push/PushBus.Service';
import { IUnsubscribe } from 'rev-shared/push/IUnsubscribe';
import { PushService } from 'rev-shared/push/PushService';
import { UserContextService } from 'rev-shared/security/UserContext.Service';

import { WebcastModel } from 'rev-portal/scheduledEvents/webcast/model/WebcastModel';
import { WebcastSidebarNotification } from 'rev-portal/scheduledEvents/webcast/WebcastSidebarNotification';

import { Question, QuestionStatus, QuestionAction, ReplyBy } from './Question';

@Injectable({
	providedIn: 'root'
})
export class WebcastViewQuestionsService {
	private readonly ignoreCommands: string[] = [];
	private attendeeMyQuestionsSubject$: BehaviorSubject<Question[]>;
	private attendeePublicQuestionsSubject$: BehaviorSubject<Question[]>;
	private moderatorQuestionsSubject$: BehaviorSubject<Question[]>;
	private subscriptions: Subscription[];
	private pushHandlerUnsubscribe: IUnsubscribe;

	public moderatorQuestions$: Observable<Question[]>;
	public attendeeMyQuestions$: Observable<Question[]>;
	public attendeePublicQuestions$: Observable<Question[]>;

	constructor(
		private http: HttpClient,
		private PushService: PushService,
		private PushBus: PushBus,
		private UserContext: UserContextService
	) {
		this.moderatorQuestionsSubject$ = new BehaviorSubject<Question[]>([]);
		this.attendeeMyQuestionsSubject$ = new BehaviorSubject<Question[]>([]);
		this.attendeePublicQuestionsSubject$ = new BehaviorSubject<Question[]>([]);

		this.moderatorQuestions$ = this.moderatorQuestionsSubject$.asObservable();
		this.attendeeMyQuestions$ = this.attendeeMyQuestionsSubject$.asObservable();
		this.attendeePublicQuestions$ = this.attendeePublicQuestionsSubject$.asObservable();
	}

	public subscribeModeratorHandlers(webcastId: string, runNumber: number): void {
		this.subscriptions = [
			this.getQuestions(webcastId, runNumber).subscribe()
		];
		this.pushHandlerUnsubscribe = this.applyModeratorPushHandlers(webcastId);
	}

	public subscribeAttendeeHandlers(webcast: WebcastModel, notification: WebcastSidebarNotification) {
		if (webcast.attendeeQuestionsSubscription) {
			return;
		}
		webcast.attendeeQuestionsSubscription = this.getAttendeesQuestions(webcast.id, webcast.currentRun.runNumber)
			.pipe(
				switchMap(result => new Observable(observer => observer.next(result)))
			)
			.subscribe((result: any) => {
				webcast.attendeeQuestionsSubscription.add(this.PushBus.composeUnsubscribeFn([
					this.applyAttendeePushHandlers(result.myQuestions, notification),
					this.applyPublicQuestionsPushHandlers(webcast, notification)
				]));
			});
	}

	public subscribeMyNewQuestionHandler(webcast: WebcastModel, unsubscribe: IUnsubscribe): void {
		webcast.attendeeQuestionsSubscription.add(unsubscribe);
	}

	public unsubscribeHandlers(): void {
		this.subscriptions?.forEach(sub => sub.unsubscribe());
		this.pushHandlerUnsubscribe?.();
	}

	public addAttendeeMyQuestion(question: Question): void {
		const questions = [...this.attendeeMyQuestionsSubject$.value] || [];
		questions.push(question);
		this.attendeeMyQuestionsSubject$.next(questions);
	}

	public askQuestion(webcast: WebcastModel, questionText: string, isAnonymous: boolean): Promise<Question> {
		const isModerator = webcast.currentUser.isEventModerator;

		return this.PushService.dispatchCommand('scheduledEvents:AskWebcastQuestion', {
			webcastId: webcast.id,
			runNumber: webcast.currentRun.runNumber,
			questionText,
			anonymous: isAnonymous,
			isModerator
		}, ['WebcastQuestionCreated'])
			.then(result => {
				const msg = result.message;
				return new Question({
					id: msg.questionId,
					questionNumber: msg.questionNumber,
					status: QuestionStatus.Inbox,
					anonymous: isAnonymous,
					isModerator,
					askedBy: msg.askedBy,
					questionText,
					whenAsked: msg.whenAsked,
					whenModified: msg.whenAsked
				});
			});
	}

	public getQuestions(webcastId: string, runNumber: number): Observable<any> {
		return this.http.get<any>(`/scheduled-events/${webcastId}/${runNumber}/questions`)
			.pipe(
				map(result => result.questionsAndAnswers?.map(question => new Question(question))),
				tap(questions => this.moderatorQuestionsSubject$.next(questions))
			);
	}

	public applyModeratorPushHandlers(webcastId: string): IUnsubscribe {
		const getQuestionIndex = questionId => (this.moderatorQuestionsSubject$.value || []).findIndex(q => q.id === questionId);

		const updateQuestionStatus = (message: any, question?: Question) => {
			question = question || this.getQuestion(message.questionId);

			if (question) {
				question.status = message.status;
				question.lastAction = message.lastAction;

				//some kind of move from the inbox (if it's in there), so unhide
				question.isHidden = false;
				question.whenModified = message.whenModified;
				this.updateQuestionsCollection(question, this.moderatorQuestionsSubject$);
			}
		};

		const webcastQuestionAdded = (message, userId) => {
			const question = new Question(message);

			//newly created questions are hidden at first to prevent unwanted flicker/scrolling. Moderator must opt to reveal them.
			//show questions asked by the moderator though.
			question.isHidden = question.askedBy.userId !== userId;
			question.whenModified = question.whenAsked;

			const questions = [...this.moderatorQuestionsSubject$.value];
			questions.push(question);
			this.moderatorQuestionsSubject$.next(questions);
		};

		return this.PushBus.subscribe(webcastId, 'Webcast.Moderator', {
			QuestionClosed: m => updateQuestionStatus(m),
			QuestionFlaggedForFollowUp: m => updateQuestionStatus(m),
			QuestionDeclined: m => updateQuestionStatus(m),
			QuestionAnswered: m => updateQuestionStatus(m),
			QuestionAnsweredApi: m => updateQuestionStatus(m),

			WebcastQuestionCreated: m => webcastQuestionAdded(m, this.UserContext.getUser().id),
			WebcastQuestionCreatedApi: m => webcastQuestionAdded(m, this.UserContext.getUser().id),

			QuestionAddedToQueue: message => {
				const index = getQuestionIndex(message.questionId);
				if (index >= 0) {
					const questions = [...this.moderatorQuestionsSubject$.value] as any[];
					const question = questions?.[index];
					questions.splice(index, 1);
					questions.push(question);
					this.moderatorQuestionsSubject$.next(questions);
					updateQuestionStatus(message, question);
				}
			},

			QuestionsReordered: message => {
				const questionIds: string[] = message.questionIds;

				//Prevents reprocessing a reorder event sent by this client
				const ignoreIndex = this.ignoreCommands.indexOf(message.commandId);
				if(ignoreIndex >= 0){
					this.ignoreCommands.splice(ignoreIndex, 1);
					return;
				}
				const questions = [...this.moderatorQuestionsSubject$.value];
				questions.forEach((q: any) => {
					const i = questionIds.findIndex(id => id === q.id);
					q.queueIndex = i < 0 ? Number.MAX_VALUE : i;
				});

				questions.sort((a: Question, b: Question): number => {
					return compare((a as any).queueIndex, (b as any).queueIndex) ||
						compare(a.whenModified, b.whenModified);
				});

				this.moderatorQuestionsSubject$.next(questions);

				function compare(a: any, b: any): number {
					return a < b ? -1 :
						a === b ? 0 : 1;
				}
			},
			ReplyingQuestionDirectly: message => {
				const question = this.getQuestion(message.questionId);
				question.isDirectReplyInProgress = true;
				question.directReplyInProgressBy = new ReplyBy(message.repliedBy);
				this.updateQuestionsCollection(question, this.moderatorQuestionsSubject$);
			},
			DirectReplyQuestionCancelled: message => {
				const question = this.getQuestion(message.questionId);
				question.isDirectReplyInProgress = message.repliedBy != null;
				question.directReplyInProgressBy = new ReplyBy(message.repliedBy);

				if(question.lastAction === QuestionAction.ReplyingDirectly){
					question.lastAction = null;
				}
				this.updateQuestionsCollection(question, this.moderatorQuestionsSubject$);
			},
			DirectReplyQuestionFinalized: message => {
				const question = this.getQuestion(message.questionId);

				Object.assign(question, {
					replyText: message.replyText,
					repliedBy: message.repliedBy,
					status: message.status,
					lastAction: message.lastAction,
					isPublic: message.isPublic,
					isDirectReplyInProgress: false,
					directReplyInProgressBy: null
				});
				this.updateQuestionsCollection(question, this.moderatorQuestionsSubject$);
			}

		});
	}

	public showModeratorHiddenQuestions(): void {
		const questions = [...this.moderatorQuestionsSubject$.value];

		questions.forEach(question => {
			question.isHidden = false;
		});
		this.moderatorQuestionsSubject$.next(questions);
	}

	public addQuestionToQueue(webcastId: string, question: Question): Promise<void> {
		return this.PushService.dispatchCommand('scheduledEvents:AddQuestionToQueue', {
			webcastId,
			questionId: question.id
		});
	}

	public closeQuestion(webcastId: string, question: Question): Promise<void> {
		return this.PushService.dispatchCommand('scheduledEvents:CloseQuestion', {
			webcastId,
			questionId: question.id
		}).then(() => {
			question.status = QuestionStatus.Closed;
		});
	}

	public followUpQuestion(webcastId: string, question: Question): Promise<void> {
		return this.PushService.dispatchCommand('scheduledEvents:FollowUpQuestion', {
			webcastId,
			questionId: question.id
		});
	}

	public answeredQuestion(webcastId: string, question: Question): Promise<void> {
		return this.PushService.dispatchCommand('scheduledEvents:AnswerQuestion', {
			webcastId,
			questionId: question.id
		});
	}

	public declineQuestion(webcastId: string, question: Question): Promise<void> {
		return this.PushService.dispatchCommand('scheduledEvents:DeclineQuestion', {
			webcastId,
			questionId: question.id
		});
	}

	public startDirectReplyQuestion(webcastId: string, question: Question): Promise<void> {
		return this.PushService.dispatchCommand('scheduledEvents:DirectReplyQuestion', {
			webcastId,
			questionId: question.id
		});
	}

	public cancelDirectReplyQuestion(webcastId: string, question: Question): Promise<void> {
		return this.PushService.dispatchCommand('scheduledEvents:CancelDirectReplyQuestion', {
			webcastId,
			questionId: question.id
		});
	}

	public finalizeDirectReplyQuestion(webcastId: string, question: Question, replyText: string, isPublic: boolean): Promise<void> {
		return this.PushService.dispatchCommand('scheduledEvents:FinalizeDirectReplyQuestion', {
			webcastId,
			questionId: question.id,
			replyText,
			isPublic
		});
	}

	public reorderQuestions(webcastId: string, fromItem: Question, toItem: Question): Promise<void> {
		const questions = [...this.moderatorQuestionsSubject$.value];

		const fromIndex = questions.findIndex(q => q.id === fromItem.id);
		const toIndex = questions.findIndex(q => q.id === toItem.id);

		questions.splice(fromIndex, 1);
		questions.splice(toIndex, 0, fromItem);

		this.moderatorQuestionsSubject$.next(questions);

		const dispatch = this.PushService.dispatchCommand('scheduledEvents:ReorderQuestions', {
			webcastId,
			questionIds: questions.map(q => q.id)
		});

		dispatch.$commandId.then(commandId => this.ignoreCommands.push(commandId));
		return dispatch;
	}

	public resetUnreadAttendeeMyQuestions(resetAll: boolean): void {
		const myQuestions = [...this.attendeeMyQuestionsSubject$.value];
		myQuestions.forEach(question => {
			if (resetAll || !question.isNew) {
				question.isUnread = false;
			}
		});
		this.attendeeMyQuestionsSubject$.next(myQuestions);
	}

	public resetMyQuestionsCounter(): void {
		const myQuestions = [...this.attendeeMyQuestionsSubject$.value];

		myQuestions.forEach(question => {
			question.isNew = false;
		});
		this.attendeeMyQuestionsSubject$.next(myQuestions);
	}

	public resetUnreadPublicQuestions(resetAll: boolean = true): void {
		const questions = [...this.attendeePublicQuestionsSubject$.value];

		questions.forEach(question => {
			if(resetAll || !question.isNew) {
				question.isUnread = false;
			}
		});
		this.attendeePublicQuestionsSubject$.next(questions);
	}

	public resetPublicQuestionsCounter(): void {
		const questions = [...this.attendeePublicQuestionsSubject$.value];

		questions.forEach(question => {
			question.isNew = false;
		});
		this.attendeePublicQuestionsSubject$.next(questions);
	}

	private getQuestion(id: string): Question {
		return (this.moderatorQuestionsSubject$.value || []).find(q => q.id === id);
	}

	private updateQuestionsCollection(question: Question, collection: BehaviorSubject<Question[]>): void {
		const questions = [...collection.value] ?? [];
		const index = questions.findIndex(q => q.id === question.id);
		questions[index] = question;

		collection.next(questions);
	}

	private getAttendeesQuestions(webcastId: string, runNumber: number): Observable<any> {
		return combineLatest([
			this.getCurrentUserQuestions(webcastId, runNumber),
			this.getCurrentPublicQuestions(webcastId, runNumber),
		])
			.pipe(
				map(([myQuestions, publicQuestions]) => {
					const groupQuestions = publicQuestions.filter(pq => myQuestions.every(mq => pq.id != mq.id));
					return {
						myQuestions,
						publicQuestions: groupQuestions
					};
				}),
				tap(result => {
					this.attendeeMyQuestionsSubject$.next(result.myQuestions);
					this.attendeePublicQuestionsSubject$.next(result.publicQuestions);
				})
			);
	}

	private getCurrentUserQuestions(webcastId: string, runNumber: number): Observable<Question[]> {
		const user = this.UserContext.getUser();

		return this.http.get<any>(`/scheduled-events/${webcastId}/${runNumber}/attendee-questions`)
			.pipe(
				map(result => {
					//end point does not populate the askedBy by design. Have to do this for the current user.
					return result.attendeeQuestionsAndAnswers.map(question => {
						if (!question.anonymous) {
							question.askedBy = {
								firstName: user.firstName,
								lastName: user.lastName
							};
						}
						return new Question(question);
					});
				})
			);
	}

	private getCurrentPublicQuestions(webcastId: string, runNumber: number): Observable<Question[]> {
		return this.http.get<any>(`/scheduled-events/${webcastId}/${runNumber}/public-questions`)
			.pipe(
				map(result => result.publicQuestionsAndAnswers.map(question => new Question(question)))
			);
	}

	public applyAttendeePushHandlers(questions: Question[], webcastSidebarNotification: WebcastSidebarNotification): IUnsubscribe {
		return this.PushBus.composeUnsubscribeFn(
			questions.map(question => {
				const updateHandler = message => this.updateAttendeeQuestion(question, message);
				const updateMyQuestions = message => {
					const question = updateHandler(message);
					this.updateQuestionsCollection(question, this.attendeeMyQuestionsSubject$);
				};

				return this.PushBus.subscribe(question.id, 'Webcast.Attendee', {
					QuestionClosed: updateMyQuestions,
					QuestionFlaggedForFollowUp: updateMyQuestions,
					DirectReplyQuestionFinalized: message => {
						updateHandler(message);
						webcastSidebarNotification.addNotification();
						question.isNew = true;
						question.isUnread = true;
						this.updateQuestionsCollection(question, this.attendeeMyQuestionsSubject$);
					},
					QuestionDeclined: updateMyQuestions,
					QuestionAnswered: message => {
						updateMyQuestions(message);
						webcastSidebarNotification.addNotification();
					},
					QuestionAnsweredApi: message => {
						updateMyQuestions(message);
						if (message.lastAction === 'RepliedDirectly' || message.lastAction === 'Answered') {
							webcastSidebarNotification.addNotification();
						}
					}
				});
			}));
	}

	private applyPublicQuestionsPushHandlers(webcast: WebcastModel, webcastSidebarNotification): IUnsubscribe {
		return this.PushBus.subscribe(webcast.id, 'Webcast.Attendee', {
			DirectReplyQuestionFinalized: message => {
				if (message.userId !== webcast.currentUser.id) {
					const publicQuestions = this.attendeePublicQuestionsSubject$.value || [];
					const existingPublicQuestionPos = publicQuestions.findIndex(q => q.questionNumber === message.questionNumber);

					if (existingPublicQuestionPos !== -1) { // question is already existing, moderator just updated it with new answer or unpublish
						if (!message.isPublic) { // moderator unpublish
							publicQuestions.splice(existingPublicQuestionPos, 1);
						}
						else { // new answer
							this.updateExistingPublicQuestion(publicQuestions[existingPublicQuestionPos], message, webcastSidebarNotification);
						}
					}
					else {// message is a new question coming in
						this.addNewPublicQuestion(message, webcastSidebarNotification);
					}
				}
			}
		});
	}

	private updateAttendeeQuestion(question: Question, message: any): Question {
		Object.assign(question, {
			status: message.status,
			lastAction: message.lastAction,
			isPublic: message.isPublic,
			whenModified: message.whenModified
		});

		if (message.replyText) {
			Object.assign(question, {
				replyText: message.replyText,
				repliedBy: message.repliedBy
			});
		}
		return question;
	}

	private updateExistingPublicQuestion(existingPublicQuestion, message, webcastSidebarNotification): void {
		const question = this.updateAttendeeQuestion(existingPublicQuestion, message);
		question.isNew = true;
		question.isUnread = true;
		this.updateQuestionsCollection(question, this.attendeePublicQuestionsSubject$);
		webcastSidebarNotification.addNotification();
	}

	private addNewPublicQuestion(message, webcastSidebarNotification): void {
		const question = new Question(message);
		question.isNew = true;
		question.isUnread = true;

		const questions = [...this.attendeePublicQuestionsSubject$.value];
		questions.push(question);
		this.attendeePublicQuestionsSubject$.next(questions);

		webcastSidebarNotification.addNotification();
	}
}
