import { NgModule } from '@vbrick/angular-ts-decorators';

import { FileUploadDirective } from './FileUpload.Directive';

import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widget';
import 'jquery-ui/themes/base/core.css';
import 'jquery-file-upload'; // way behind. version not published anymore. 5.26 but now on 9.12.5 (though not kept up publishing to npm for some reason). requires jquery-ui widget
import 'jquery-file-upload/jquery.iframe-transport';

@NgModule({
	id: 'VBrick.UI.FileUpload',
	declarations: [
		FileUploadDirective
	]
})
export default class FileUploadModule {}
