import {
	AfterViewInit,
	Component,
	Input,
	OnDestroy,
	OnInit
} from '@vbrick/angular-ts-decorators';

import {
	IDocumentService,
	IQService
} from 'angular';

import { StateService } from '@uirouter/angularjs';
import { partition } from 'underscore';
import { KEY_DELETE } from 'keycode-js';

import { PushBus } from 'rev-shared/push/PushBus.Service';

import { MediaStateService } from 'rev-portal/media/MediaState.Service';
import { PlaylistService } from 'rev-shared/media/Playlist.Service';

import {
	FEATURED_PLAYLIST_DETAIL_STATE_NAME,
	PLAYLIST_DETAIL_STATE_NAME
} from './Playlists.Module';

@Component({
	selector: 'browse-user-playlists',
	templateUrl(MediaStateService: MediaStateService): string {
		'ngInject';

		return MediaStateService.isTableViewMode ?
			'/partials/media/browse-user-playlists-table.html' :
			'/partials/media/browse-user-playlists-tiles.html';
	}
})
export class BrowseUserPlaylists implements OnInit, AfterViewInit, OnDestroy {
	@Input() public playlists: any[];
	@Input() public userId: string;

	protected all: boolean;
	protected ascending: boolean;
	protected deletePlaylistsConfirmation: any;
	protected selectedPlaylistIds: string[];
	protected sortProperty: string;
	protected translations: any;

	private onKeydownDocumentHandler: any;
	private playlistDeletedUnsubscribe: any;

	constructor(
		private $document: IDocumentService,
		private $q: IQService,
		private $state: StateService,
		private MediaStateService: MediaStateService,
		private PlaylistService: PlaylistService,
		private PushBus: PushBus) {
		'ngInject';
	}

	public ngOnInit(): void {
		Object.assign(this, {
			all: false,
			ascending: true,
			selectedPlaylistIds: [],
			sortProperty: 'name'
		});

		this.onKeydownDocumentHandler = (event: any) => this.onKeydown(event);
		this.$document.on('keydown', this.onKeydownDocumentHandler);
		this.MediaStateService.searchResultsState.getSelectedCount = () => this.selectedPlaylistIds?.length;

		this.subscribeToPush();
	}

	public ngAfterViewInit(): void {
		this.applyFeaturedPlaylistName();
	}

	public ngOnDestroy(): void {
		this.$document.off('keydown', this.onKeydownDocumentHandler);
		this.playlistDeletedUnsubscribe();
	}

	public toggleAll(): void {
		const userPlaylists: any[] = this.playlists.filter((p: any) => !p.featured);

		this.all = !this.all;

		this.selectedPlaylistIds = this.all ? userPlaylists.map((p: any) => p.playlistId) : [];
		userPlaylists.forEach((p: any) => p.selected = this.all);

	}

	public toggleSelection(playlist: any): void {
		const selectedIds: string[] = this.selectedPlaylistIds;

		if (playlist.selected) {
			const index: number = selectedIds.findIndex((id: string) => id === playlist.playlistId);

			selectedIds.splice(index, 1);
		} else {
			selectedIds.push(playlist.playlistId);
		}

		playlist.selected = !playlist.selected;
	}

	public goToEditPlaylist(playlist): void {
		playlist.featured ?
			this.$state.go(FEATURED_PLAYLIST_DETAIL_STATE_NAME) :
			this.$state.go(PLAYLIST_DETAIL_STATE_NAME, { playlistId: playlist.playlistId });
	}

	public deleteSelectedPlaylists(): any {
		if (!this.selectedPlaylistIds.length) {
			return;
		}

		this.deletePlaylistsConfirmation
			.open().result
			.then(() => {
				const playlistsToRemove = this.splitByPlaylistIds(this.playlists, this.selectedPlaylistIds);

				this.playlists = playlistsToRemove.rejections;
				this.selectedPlaylistIds = [];

				return this.deletePlaylists(playlistsToRemove.selections);
			});
	}

	private applyFeaturedPlaylistName(): void {
		const featuredPlaylist: any = this.playlists.find((playlist: any) => playlist.featured);

		if (featuredPlaylist) {
			featuredPlaylist.name = this.translations.featuredVideos;
		}
	}

	private deletePlaylists(playlists: any[]): any {
		return this.$q.all(playlists.map((playlist: any) =>
			this.PlaylistService.deletePlaylist(playlist.playlistId)
				.catch(() => {
					playlist.selected = false;
					playlist.error = true;
					this.playlists.push(playlist);
				})
		)
		);
	}

	private splitByPlaylistIds(playlists: any[], ids: string[]): any {
		const [selections, rejections] = partition(playlists, (p: any) => ids.includes(p.playlistId));

		return {
			rejections,
			selections
		};
	}

	protected onKeydown(event: any): void {
		if (event.which === KEY_DELETE) {
			this.deleteSelectedPlaylists();
		}
	}

	protected subscribeToPush(): void {
		this.playlistDeletedUnsubscribe = this.PushBus.subscribe(this.userId, {
			PlaylistDeleted: (data: any) => {
				const playlistIndex: number = this.playlists.findIndex((p: any) => p.playlistId === data.playlistId);

				if (playlistIndex !== -1) {
					this.playlists.splice(playlistIndex, 1);
				}
			}
		});
	}
}
