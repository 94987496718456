import { NgModule } from '@vbrick/angular-ts-decorators';
import 'ui-select';
import 'ui-select/dist/select.css';
import './ui-select.less';

import BrandingModule from 'rev-portal/branding/Branding.Module';

import { UISelectPropertyFilter } from './UISelectProperty.Filter';

import { VbUISelectWrapComponent } from './VbUISelectWrap.Component';

@NgModule({
	id: 'VBrick.UI.UISelect',
	declarations: [
		UISelectPropertyFilter,
		VbUISelectWrapComponent
	],
	imports: [
		'ui.select',
		'ngSanitize',
		BrandingModule
	]
})
export class UISelectModule {}
