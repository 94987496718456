import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { UIRouterModule } from '@uirouter/angular';
import { VbrickPlayerModule } from '@vbrick/vbrick-player';

import { ButtonsAngularModule } from 'rev-shared/ui/buttons/ButtonsAngular.Module';
import { DateAngularModule } from 'rev-shared/date/DateAngular.Module';
import { DialogAngularModule } from 'rev-shared/ui/dialog/DialogAngular.Module';
import { HlsFileUtilModule } from 'rev-shared/videoPlayer/hlsFileUtil/HlsFileUtil.Module';
import { PushModule } from 'rev-shared/push/Push.Module';
import { SecurityAngularModule } from 'rev-shared/security/SecurityAngular.Module';
import { UtilAngularModule } from 'rev-shared/util/UtilAngular.Module';
import { VbrickSharedMediaModule } from 'rev-shared/media/Media.Module';
import { VbWebcastViewModule } from 'rev-shared/webcast/webcastView/VbWebcastView.Module';
import { ng2StatesToHybridStates } from 'rev-shared/uiRouterUtils/HybridRouterUtils';

import { PresentationAngularModule } from '../presentations/PresentationAngular.Module';
import { RealTimeAnalyticsModule } from '../realTimeAnalytics/RealTimeAnalytics.Module';
import { WebcastAttendeesModule } from './attendees/WebcastAttendees.Module';
import { WebcastNavModule } from './nav/WebcastNav.Module';
import { WebcastReportModule } from '../report/WebcastReport.Module';
import { WebcastSharedComponentsModule } from './sharedComponents/WebcastSharedComponents.Module';
import { states } from './StateDeclarations';

const components = [
];

@NgModule({
	declarations: components,
	exports: components,
	imports: [
		ButtonsAngularModule,
		CommonModule,
		DateAngularModule,
		FormsModule,
		DialogAngularModule,
		HlsFileUtilModule,
		HttpClientModule,
		PresentationAngularModule,
		PushModule,
		RealTimeAnalyticsModule,
		SecurityAngularModule,
		TooltipModule,
		TranslateModule,
		UtilAngularModule,
		UIRouterModule.forChild({
			states: ng2StatesToHybridStates(states)
		}),
		VbrickPlayerModule,
		VbrickSharedMediaModule,
		VbWebcastViewModule,
		WebcastAttendeesModule,
		WebcastNavModule,
		WebcastReportModule,
		WebcastSharedComponentsModule
	]
})
export class WebcastAngularModule {}
