import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';

import { DateParsersService } from 'rev-shared/date/DateParsers.Service';

interface IAttendeesParams {
	scrollId?: string;
	searchFields?: string[];
	searchQuery?: string;
	size: number;
	sortBy?: string;
	sortDirection?: string;
}

@Injectable()
export class WebcastAttendeesService {
	constructor(
		private DateParsers: DateParsersService,
		private http: HttpClient
	) {}

	public fetchAttendees(eventId: string, runNumber: number, params?: IAttendeesParams): Observable<any> {
		return this.http.post<any>(`/analytics/events/${eventId}/${runNumber}/user-sessions`, {
			size: params.size,
			fields: params.searchFields,
			search: params.searchQuery,
			sortBy: params.sortBy,
			sortDirection: params.sortDirection,
			scrollId: params.scrollId
		}).pipe(
			map(result => {
				result.sessions = (result.sessions || []).map(session => {
					session.EndTime = this.DateParsers.parseUTCDate(session.EndTime);
					session.StartTime = this.DateParsers.parseUTCDate(session.StartTime);
					return session;
				});
				return result;
			})
		);
	}

}
