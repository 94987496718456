import { PipeTransform, Pipe } from '@vbrick/angular-ts-decorators';

import { CategoryService } from './Category.Service';

@Pipe({
	name: 'categoryParentPath'
})
export class CategoryParentPathPipe implements PipeTransform {
	constructor(
		private CategoryService: CategoryService
	) {
		'ngInject';
	}

	public transform(input: any): string {
		return this.CategoryService.getParentPath(input);
	}
}
