import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { VbPollTrendComponent } from './VbPollTrend.Component';
@NgModule({
	entryComponents: [
		VbPollTrendComponent
	],
	exports: [
		VbPollTrendComponent
	],
	declarations: [
		VbPollTrendComponent
	],
	imports: [
		CommonModule,
		TranslateModule
	]
})
export class VbPollTrendModule {}
