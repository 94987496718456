import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { UIRouterModule } from '@uirouter/angular';

import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TranslateModule } from '@ngx-translate/core';

import { AnalyticsModule } from 'rev-shared/analytics/Analytics.Module';
import { BarChartModule } from 'rev-shared/ui/dataVisualization/barChart/BarChart.Module';
import { ButtonsAngularModule } from 'rev-shared/ui/buttons/ButtonsAngular.Module';
import { DataGridModule } from 'rev-shared/ui/dataGrid/DataGrid.Module';
import { DateAngularModule } from 'rev-shared/date/DateAngular.Module';
import { DialogAngularModule } from 'rev-shared/ui/dialog/DialogAngular.Module';
import { DialogRegistrationService } from 'rev-shared/ui/dialog/DialogRegistration.Service';
import { HighlightModule } from 'rev-shared/pipes/highlight/Highlight.Module';
import { KpiModule } from 'rev-shared/ui/reports/kpi/Kpi.Module';
import { LineChartModule } from 'rev-shared/ui/dataVisualization/lineChart/LineChart.Module';
import { ReportCellModule } from 'rev-shared/ui/reports/reportCell/ReportCell.Module';
import { SearchInputModule } from 'rev-shared/ui/searchInputAngular/SearchInput.Module';
import { TabPanelModule } from 'rev-shared/ui/tabPanel/TabPanel.Module';
import { TextInputAngularModule } from 'rev-shared/ui/text-input/TextInputAngular.Module';
import { ng2StatesToHybridStates } from 'rev-shared/uiRouterUtils/HybridRouterUtils';

import { BrandingAngularModule } from 'rev-portal/branding/BrandingAngular.Module';
import { WebcastSharedComponentsModule } from '../webcast/sharedComponents/WebcastSharedComponents.Module';

import { RealTimeAnalyticsAttendeesTabComponent } from './RealTimeAnalyticsAttendeesTab.Component';
import { RealTimeAnalyticsComponent } from './RealTimeAnalytics.Component';
import { RealTimeAnalyticsDataGridWrapperComponent } from './RealTimeAnalyticsDataGridWrapper.Component';
import { RealTimeAnalyticsDeviceZoneRendererComponent } from './RealTimeAnalyticsDeviceZoneRenderer.component';
import { RealTimeAnalyticsDevicesTabComponent } from './RealTimeAnalyticsDevicesTab.Component';
import { RealTimeAnalyticsHeaderComponent } from './RealTimeAnalyticsHeader.Component';
import { RealTimeAnalyticsStatusHealthMetricsRendererComponent } from './RealTimeAnalyticsStatusHealthMetricsRenderer.Component';
import { RealTimeAnalyticsZonesTabComponent } from './RealTimeAnalyticsZonesTab.Component';
import { ZonesListDialogComponent } from './ZonesListDialog.Component';
import { realTimeAnalyticsStateDeclarations } from './StateDeclarations';

const components = [
	RealTimeAnalyticsAttendeesTabComponent,
	RealTimeAnalyticsComponent,
	RealTimeAnalyticsDataGridWrapperComponent,
	RealTimeAnalyticsDeviceZoneRendererComponent,
	RealTimeAnalyticsDevicesTabComponent,
	RealTimeAnalyticsHeaderComponent,
	RealTimeAnalyticsStatusHealthMetricsRendererComponent,
	RealTimeAnalyticsZonesTabComponent,
	ZonesListDialogComponent
];

@NgModule({
	declarations: components,
	entryComponents: [
		RealTimeAnalyticsComponent,
		RealTimeAnalyticsDeviceZoneRendererComponent,
		RealTimeAnalyticsStatusHealthMetricsRendererComponent,
		ZonesListDialogComponent
	],
	exports: [
		RealTimeAnalyticsComponent
	],
	imports: [
		AnalyticsModule,
		BarChartModule,
		BrandingAngularModule,
		BsDropdownModule,
		ButtonsAngularModule,
		CommonModule,
		DataGridModule,
		DateAngularModule,
		DialogAngularModule,
		FormsModule,
		HighlightModule,
		HttpClientModule,
		KpiModule,
		LineChartModule,
		ReportCellModule,
		SearchInputModule,
		TabPanelModule,
		TextInputAngularModule,
		TooltipModule,
		TranslateModule,
		UIRouterModule.forChild({
			states: ng2StatesToHybridStates(realTimeAnalyticsStateDeclarations)
		}),
		WebcastSharedComponentsModule
	]
})
export class RealTimeAnalyticsModule {
	constructor(dlg: DialogRegistrationService) {
		dlg.register('ZonesList', {
			component: ZonesListDialogComponent
		});
	}
}
