<!--Record button (primarily when broadcast is not yet started)-->
<button *ngIf="showSetRecording()" [disabled]="webcast.broadcast.isStopping || recording.isDeviceUnavailable || !webcast.isStartOrStopRecordingAllowed() || (webcast.isVideoConferencingType && webcast.webcastStatus.isStarting)" [ngClass]="recording.isSetToRecord ? styles.webcastRecordingBtnActive : styles.webcastRecordingBtn" (click)="toggleSetToRecord()" vbUiBtnSecondaryNgx>
	<span [hidden]="webcast.isVideoConferencingType && webcast.webcastStatus.isStarting">
		<span class="glyphicons record"></span>
		{{ (recording.isSetToRecord ? 'Event_SetToRecord' : 'Event_Record') | translate }}
	</span>
	<span [hidden]="!(webcast.isVideoConferencingType && webcast.webcastStatus.isStarting)">
		<span [hidden]="!webcast.vcStatus.isInitializing">
			{{ 'Media_Recording_Initializing' | translate }}
		</span>
		<span [hidden]="!webcast.vcStatus.isConnecting">
			{{ 'Media_Recording_Connecting' | translate }}
		</span>
		<span [hidden]="!webcast.vcStatus.isGenerating">
			{{ 'Media_Recording_Generating' | translate }}
		</span>
		<span [hidden]="!(webcast.vcStatus.waitingForStream || webcast.isWebexLiveStreamType)">
			{{ 'WaitingForStream' | translate }}
		</span>
		<span [hidden]="!webcast.vcStatus.isRecording">
			{{ 'Media_Recording_Recording' | translate }}
		</span>
	</span>
</button>

<!--Record button (when recording can be started)-->
<button *ngIf="showStartRecording()" class="btn-icon-left btn-record" [ngClass]="recording.isProcessingRequest || recording.isActive ? styles.webcastRecordingBtnActive : styles.webcastRecordingBtn" (click)="recording.isActive ? stopRecording() : startRecording()" [disabled]="recording.isProcessingRequest || recording.isDeviceUnavailable || webcast.isAutomated || !webcast.isStartOrStopRecordingAllowed()" vbUiBtnSecondaryNgx>

	<span class="glyphicons" [ngClass]="{
			'record': !recording.isActive || webcast.isAutomated,
			'stop': recording.isActive && !webcast.isAutomated
		}">
	</span>

	<span [hidden]="!recording.isProcessingRequest">
		{{ 'Event_Standby' | translate }}
	</span>

	<span [hidden]="recording.isProcessingRequest">
		<span [hidden]="!recording.isActive">
			{{ (webcast.isAutomated || !webcast.isStartOrStopRecordingAllowed() ? 'Event_Recording' : 'Event_StopRecording') | translate }}
		</span>

		<span [hidden]="recording.isActive">
			{{ 'Event_Record' | translate }}
		</span>
	</span>
</button>

<!-- checkback later -->
<div [ngClass]="styles.deviceUnavailableTooltipHost" [hidden]="!(recording.isDeviceUnavailable)" [tooltip]="'Event_RecordingDeviceUnavailable' | translate" [placement]="'top'" [triggers]="isMobile ? 'click' : 'mouseenter:click'">
</div>
