import {
	AfterViewInit,
	Directive,
	ElementRef,
	EventEmitter,
	Input,
	NgZone,
	Output
} from '@angular/core';

import { noop } from 'rev-shared/util';

import 'jquery-ui/themes/base/core.css';
import 'jquery-ui/themes/base/sortable.css';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widget';
import 'jquery-ui/ui/widgets/mouse';
import 'jquery-ui/ui/widgets/sortable';
import 'jquery-ui-touch-punch';

const dragClass = 'vb-sortable-dragging';

export interface ISortedEvent {
	item?: any;
	oldIndex: number;
	newIndex: number;
}

@Directive({
	selector: '[vbUiSortable]'
})
export class VbUiSortableDirective implements AfterViewInit {
	@Input() public sortableOptions: any;
	@Input() public sortableDisabled: boolean;
	@Output() public onSort: EventEmitter<ISortedEvent> = new EventEmitter<ISortedEvent>();

	private oldIndex: number;
	private $element: JQuery<HTMLElement>;

	constructor(
		element: ElementRef,
		private zone: NgZone
	) {
		this.$element = $(element.nativeElement);
	}

	public ngAfterViewInit(): void {
		if(this.sortableDisabled){
			return;
		}

		this.$element.sortable(Object.assign({
			placeholder: 'vb-sortable-placeholder',

			start: (_e: Event, ui: JQueryUI.SortableUIParams): void => {
				ui.helper.addClass(dragClass);

				this.oldIndex = ui.item.index();
			},

			stop: (_e: Event, ui: JQueryUI.SortableUIParams): void => {
				ui.item.removeClass(dragClass);

				this.onSort.emit({
					oldIndex: this.oldIndex,
					newIndex: ui.item.index()
				});

				this.zone.run(noop);
			}
		},
		this.sortableOptions || {}));
	}

}
