import { NgModule } from '@vbrick/angular-ts-decorators';

import DialogModule from 'rev-shared/ui/dialog/Dialog.Module';
import PushDowngradeModule from 'rev-shared/push/Push.downgrade.Module';
import TagsInputModule from 'rev-shared/ui/ngTagsInput/NgTagsInput.Module';
import VBrickFormDirectivesModule from 'rev-shared/util/directives/form/FormDirectives.Module';
import VBrickSharedSecurityModule from 'rev-shared/security/Security.Module';
import VBrickSharedUtilModule from 'rev-shared/util/Util.Module';
import VBrickUiSpinnerModule from 'rev-shared/ui/spinner/Spinner.Module';
import { downgradeInjectables } from 'rev-shared/util/AngularHybridUtils';

import { SparkService } from './Spark.Service';

import { SubscribeSparkCategoryComponent } from './SubscribeSparkCategory.Component';
import { VideoShareSparkComponent } from './VideoShareSpark.Component';

@NgModule({
	id: 'VBrick.Portal.Media.Spark',
	declarations: [
		SubscribeSparkCategoryComponent,
		VideoShareSparkComponent
	],
	imports: [
		DialogModule.forChild([{
			name: 'ShareVideoToSpark',
			component: 'videoShareSpark'
		}, {
			name: 'SubscribeToCategoryInSpark',
			component: 'subscribeSparkCategory'
		}]),
		PushDowngradeModule,
		TagsInputModule,
		VBrickFormDirectivesModule,
		VBrickSharedSecurityModule,
		VBrickSharedUtilModule,
		VBrickUiSpinnerModule
	]
})
export default class SparkModule {
}

downgradeInjectables(SparkModule, [{
	name: 'SparkService',
	injectable: SparkService
}]);
