import { Component, Input, OnInit } from '@vbrick/angular-ts-decorators';
import {
	IAugmentedJQuery,
	INgModelController
} from 'angular';

import styles from './VbGridList.module.less';

@Component({
	selector: 'vb-grid-list-selection-header',
	require: {
		ngModelController: 'ngModel'
	},
	template: `
		<vb-checkbox
			ng-change="$ctrl.syncModel()"
			ng-model="$ctrl.ngModel">
		</vb-checkbox>
	`
})

export class VbGridListSelectionHeaderComponent implements OnInit {
	@Input() public ngModel: any;
	@Input() public collection: any[];

	private ngModelController: INgModelController;

	constructor(
		private $element: IAugmentedJQuery
	) {
		'ngInject';
	}

	public ngOnInit(): void {
		this.$element.addClass(styles.selectionColumn);
	}

	public syncModel(): void {
		this.ngModelController.$setViewValue(this.ngModel);

		if(this.collection && this.collection.length > 0) {
			this.toggleSelectAll();
		}
	}

	public toggleSelectAll(): void {
		(this.collection || []).forEach(item => item.selected = this.ngModel);
	}
}
