import { VIDEO_SEARCH_VIEWS } from 'rev-portal/media/StateConfig';

import {
	StateProvider,
	StateService,
	Transition
} from '@uirouter/angularjs';

import {
	ILocationService,
	IQService
} from 'angular';

import { CategoryService } from 'rev-shared/media/Category.Service';
import { ExpiryRuleSvc } from 'rev-shared/media/ExpiryRule.Service';
import { IVBrickStateDeclaration } from 'rev-shared/ts-utils/IVBrickStateDeclaration';
import { MetadataService } from 'rev-shared/metadata/Metadata.Service';
import { StateChangeStatus } from 'rev-shared/util/StateChangeStatus.Service';
import { VideoService } from 'rev-shared/media/Video.Service';

import { MediaStateService, MediaViewMode } from 'rev-portal/media/MediaState.Service';
import { SearchFilterStateService } from 'rev-portal/media/search/SearchFilterState.Service';

import { BulkEditFormStateService } from './BulkEditFormState.Service';
import { VideoSelectionModelService } from './VideoSelectionModel.Service';

export function StateConfig($stateProvider: StateProvider): void {
	$stateProvider
		.state('portal.media.edit.bulk-edit-sidebar', {
			url: '/edit',
			views: {
				'right-sidebar-content@portal.media.edit': {
					component: 'bulkEditVideos'
				}
			}
		})

		.state('portal.media.edit', {
			url: '/edit?{q:any}',
			views: VIDEO_SEARCH_VIEWS,

			params: {
				categoryId: undefined,
				keepFilterOverrides: true
			},

			scrollToTop: true,

			redirectTo(transition: Transition) {
				//doesn't work as an entry point (example: page refresh) since it will lack input/context
				if (!transition.from().name.length) {
					return {
						state: 'portal.media'
					};
				}
			},

			resolve: {
				bulkEdit() {
					return true;
				},

				categoryId($transition$: Transition) {
					'ngInject';
					return $transition$.params().categoryId;
				},

				forceMediaView() {
					return MediaViewMode.TABLE;
				},

				bulkEditAccessCheck($q: IQService, userHasEditableVideos: boolean) {
					'ngInject';

					if (!userHasEditableVideos) {
						return $q.reject({ status: 401 });
					}

					return true;
				},

				searchParams($transition$: Transition, teamId: string) {
					'ngInject';
					return {
						query: $transition$.params().q,
						useEditAcl: true,
						teamId
					};
				},

				loadMetadataFields(MetadataService: MetadataService, accountId: string){
					'ngInject';
					return MetadataService.loadAccountVideoFields(accountId);
				},

				categories(CategoryService: CategoryService, accountId: string) {
					'ngInject';
					return CategoryService.getAssignableCategories();
				},

				expirationRules(ExpiryRuleService: ExpiryRuleSvc, accountId: string) {
					'ngInject';

					return ExpiryRuleService.getExpiryRules(accountId);
				},

				teams(VideoService: VideoService) {
					'ngInject';
					return VideoService.getAssignableTeams()
						.then(results => results.map(team => ({ teamId: team.teamId, name: team.name })));
				}
			},

			onEnter(
				$location: ILocationService,
				$state: StateService,
				BulkEditFormState: BulkEditFormStateService,
				MediaStateService: MediaStateService,
				StateChangeStatus: StateChangeStatus,
				VideoSelectionModel: VideoSelectionModelService
			){
				'ngInject';

				MediaStateService.setIsViewModeDisabled(true);
				MediaStateService.setIsBulkEditDisabled(true);

				if (!StateChangeStatus.isStateReloading) {
					MediaStateService.setBackLink($state.current.name ?
						'#' + $location.url() :
						$state.href('^', null, { relative: this }));

					MediaStateService.setColumns(Object.assign({}, MediaStateService.getColumns(), {
						selection: 0
					}));

					BulkEditFormState.init();
				}

				VideoSelectionModel.reset();
			},

			onExit(
				BulkEditFormState: BulkEditFormStateService,
				MediaStateService: MediaStateService,
				SearchFilterState: SearchFilterStateService,
				StateChangeStatus: StateChangeStatus,
				VideoSelectionModel: VideoSelectionModelService
			){
				'ngInject';

				if(!StateChangeStatus.isStateReloading){
					SearchFilterState.clearAllOverrides();
					MediaStateService.setIsViewModeDisabled(false);
					MediaStateService.setIsBulkEditDisabled(false);
					MediaStateService.setColumns(Object.assign({}, MediaStateService.getColumns(), {
						selection: -1
					}));

					if (StateChangeStatus.transition.toState.name !== 'portal.media.expirations') {
						MediaStateService.resetColumns();
					}

					MediaStateService.setBackLink();
					VideoSelectionModel.reset();
					BulkEditFormState.clear();
				}
			}
		} as IVBrickStateDeclaration);
}
