/**
 * Helps to ensure that the library styling and our overrides our output in the right sequence.
 * Plus simplifies the usage.
 */
import 'ng-tags-input';
import 'ng-tags-input/build/ng-tags-input.css';

import './tags-input.less';

import { NgModule } from '@vbrick/angular-ts-decorators';
import { VbUITagsInputAutoCompleteDirective } from './VbUITagsInputAutoComplete.Directive';
import { VbUITagsInputDirective } from './VbUITagsInput.Directive';

@NgModule({
	id: 'VBrick.UI.TagsInputImport',
	imports: [
		'ngTagsInput' //ng-tags-input
	],
	declarations: [
		VbUITagsInputAutoCompleteDirective,
		VbUITagsInputDirective
	]
})

export default class VBrickNgTagsInputModule {}
