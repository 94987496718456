import {
	Directive,
	OnInit
} from '@vbrick/angular-ts-decorators';
import {
	IAugmentedJQuery
} from 'angular';

import styles from './vb-ui-error-message.module.less';
@Directive({
	selector: '[vb-ui-error-message]'
})
export class VbUiErrorMessageDirective implements OnInit {

	constructor(
		private $element: IAugmentedJQuery
	) {
		'ngInject';
	}

	public ngOnInit(): void {
		this.$element.addClass(styles.errorMessageWrapper);
	}
}
