import { StateProvider, Transition } from '@uirouter/angularjs';

import { IVBrickStateDeclaration } from 'rev-shared/ts-utils/IVBrickStateDeclaration';
import { lazyLoadFromTransition } from 'rev-shared/util/AngularJsLazyLoadHelper.Service';
import TeamHandler from 'rev-shared/team/TeamHandler';
import { UserContextService } from 'rev-shared/security/UserContext.Service';
import { TeamService } from 'rev-shared/team/Team.Service';

import { TeamListingsService } from './TeamListings.Service';

export function StateConfig($stateProvider: StateProvider): void {
	$stateProvider
		.state('portal.team', {
			url: '/teams',
			component: 'teamsList',
			scrollToTop: true,
			resolve: {
				teams(TeamListingsService: TeamListingsService) {
					'ngInject';

					return TeamListingsService.listTeams(true);
				},
				isCreateTeamAccess(TeamService: TeamService): boolean {
					'ngInject';

					return TeamService.isCreateTeamAccess;
				}
			}
		} as IVBrickStateDeclaration)

		// This state is because:- user can go to team's landing page from media menu
		//and from landing page, user can edit team if it has the permission...
		.state('portal.team-edit', getEditStateFromMedia());
}

function getEditStateFromMedia(): IVBrickStateDeclaration {
	const sharedTeamStateConfig = TeamHandler.getSharedTeamState();

	return {
		...sharedTeamStateConfig,
		url: '/edit-team/:teamId',
		params: {
			teamId: null
		},
		lazyLoad: transition => {
			return lazyLoadFromTransition(
				transition,
				import(/* webpackChunkName: "color-selector" */ 'rev-shared/ui/color-selector/ColorSelector.Module')
			);
		},
		resolve: {
			...sharedTeamStateConfig.resolve,
			previousState($transition$: Transition) {
				'ngInject';
				return {
					name: $transition$.from().name,
					params: $transition$.params('from')
				};
			},

			accountId(UserContext: UserContextService) {
				'ngInject';

				return UserContext.getAccount().id;
			}
		}
	};
}
