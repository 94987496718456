<dashboard-carousel [items]="teams" [headerText]="'Dashboard_Teams' | translate" headerSref="portal.team">

		<div class="carousel-cell" [ngClass]="styles.carouselCellItem" *ngFor="let team of teams">

			<section [ngClass]="styles.carouselCell">

				<div [ngClass]="styles.imageWrapper">

					<div class="fixed-ratio-wrapper" [ngClass]="styles.fixedRatioWrapper" [style.background-color]="team.bgColor">
						<div class="fixed-ratio">
							<a class="fixed-aspect-item" uiSref="portal.media.all" [uiParams]="{team: team.id}">
								<img [alt]="'ThumbnailForAria' | translate : { '0': team.name }" class="preview-image anchored-thumbnail fill-thumbnail" style="opacity: .5" *ngIf="team.thumbnailUri" [attr.data-flickity-lazyload]="team.thumbnailUri">
							</a>
						</div>
					</div>

					<div [ngClass]="styles.infoContainer">
						<h4>
							<a [title]="team.name" uiSref="portal.media.all" [uiParams]="{team: team.id}">
								{{team.name}}
							</a>
						</h4>
						<h5>
							<span class="glyphicons facetime_video margin-right-5" [attr.aria-label]="'Dashboard_TeamsTotalVideos' | translate ">
							</span>
							{{ team.totalVideos }}
						</h5>
					</div>
				</div>
			</section>
		</div>
</dashboard-carousel>
