import { NgModule } from '@vbrick/angular-ts-decorators';

import { InsightModule } from '@vbrick/angular-insight';

import BrandingModule from 'rev-portal/branding/Branding.Module';
import { TranslationsModule } from 'rev-shared/ui/translations/Translations.Module';

import { VbInsightComponent } from './VbInsight.Component';
import { VbUiInsightUpgradeComponent } from './VbUiInsight.UpgradeComponent';

import './insight-rev.less';

@NgModule({
	id: 'VBrick.UI.Insight',
	declarations: [
		VbInsightComponent,
		VbUiInsightUpgradeComponent
	],
	imports: [
		BrandingModule,
		InsightModule,
		TranslationsModule
	]
})
export default class VBrickInsightModule {
}
