import { debounce } from 'underscore';

import {
	Component,
	Input,
	OnDestroy,
	OnInit
} from '@vbrick/angular-ts-decorators';

import {
	IAugmentedJQuery,
	ILogService
} from 'angular';

import { Subscription } from 'rxjs';

import { NONE } from 'rev-shared/media/MediaConstants';
import { MediaFeaturesService } from 'rev-shared/media/MediaFeatures.Service';
import { SearchConstants } from 'rev-portal/search/SearchConstants';
import { SearchService } from 'rev-portal/search/Search.Service';
import { InsightSearchHelper } from 'rev-portal/search/InsightSearchHelper.Service';

import { SearchFilterStateService } from './SearchFilterState.Service';

import './SearchFiltersSidebar.less';


@Component({
	selector: 'search-filters-sidebar',
	templateUrl: '/partials/media/search/search-filters-sidebar.html'
})
export class SearchFiltersSidebarComponent implements OnDestroy, OnInit {
	@Input() public accountId: string;
	@Input() public isGuestUser: boolean;
	@Input() public teams: any[];

	private readonly AUTO_SUBMIT_TIMER_MS: number = 2000;

	private canFilterTeams: boolean;
	private debounceCancelled: boolean;
	private filters: any;
	private isUnlistVideoEnabled: boolean;
	private noneOptionAvailable: boolean;
	private onChange: () => void;
	private searchFilterStateChangeSub: Subscription;
	private uploaderInsightOptions: any;
	private userTagsInsightOptions: any;

	constructor(
		private $element: IAugmentedJQuery,
		private $log: ILogService,
		private MediaFeatures: MediaFeaturesService,
		private SearchFilterState: SearchFilterStateService,
		private SearchService: SearchService,
		private InsightSearchHelper: InsightSearchHelper
	) {
		'ngInject';
	}

	public ngOnInit(): void {
		this.$element.addClass('box-block container-fluid filters-sidebar');

		this.canFilterTeams = true;
		this.filters = this.SearchFilterState.clone();
		this.debounceCancelled = false;
		this.noneOptionAvailable = true;
		this.onChange = debounce(() => this.onChangeInternal(), this.AUTO_SUBMIT_TIMER_MS);
		this.teams = this.shapeTeams(this.teams);
		this.searchFilterStateChangeSub = this.SearchFilterState.change$.subscribe(() => this.updateFilters());
		this.isUnlistVideoEnabled = this.MediaFeatures.accountFeatures.enableUnlisted;

		this.uploaderInsightOptions = this.InsightSearchHelper.buildAccessEntityInsightOptions({
			accountId: this.accountId,
			users: true
		});

		this.userTagsInsightOptions = this.InsightSearchHelper.buildAccessEntityInsightOptions({
			accountId: this.accountId,
			users: true
		});
	}

	public ngOnDestroy(): void {
		this.searchFilterStateChangeSub.unsubscribe();
	}

	private clear(): void {
		this.debounceCancelled = true;
		this.SearchFilterState.clear();
		this.canFilterTeams = true;
		this.noneOptionAvailable = true;
	}

	private filterTeams(query: string): any[] {
		if (this.canFilterTeams) {
			query = query.toLowerCase();
			return this.teams.filter(team =>
				team.name.toLowerCase().includes(query) &&
				(this.noneOptionAvailable || team.name !== NONE)
			);
		}

		return [];
	}

	private onChangeInternal(): void {
		if (!this.debounceCancelled){
			this.submit();
		}

		this.debounceCancelled = false;
	}

	private onTeamAdded(team: any): void {
		if (team.name === NONE) {
			this.canFilterTeams = false;
		} else {
			this.noneOptionAvailable = false;
		}

		this.onChange();
	}

	private onTeamRemoved(team: any): void {
		if (team.name === NONE) {
			this.canFilterTeams = true;
		}

		this.noneOptionAvailable = !this.filters.teamIds.value.length;

		this.onChange();
	}

	private shapeTeams(teams: any[]) {
		const teamOptions = [{ name: NONE, teamId: '' }];

		teams.forEach(team => teamOptions.push({ teamId: team.teamId, name: team.name }));

		return teamOptions;
	}

	private submit(): void {
		this.debounceCancelled = true;
		this.SearchFilterState.update(this.filters);
	}

	private updateFilters(): void {
		this.filters = this.SearchFilterState.clone();

		const selectedTeamsCount: number = (this.filters.teamIds.value || []).length;

		this.noneOptionAvailable = !selectedTeamsCount;
		if (selectedTeamsCount) {
			this.canFilterTeams =
				selectedTeamsCount !== 1 ||
				this.filters.teamIds.value[0].name !== NONE;
		} else {
			this.canFilterTeams = true;
		}
	}
}
