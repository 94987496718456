import { Component, Input } from '@vbrick/angular-ts-decorators';

import { IConfirmationDialogParams } from './IConfirmationDialogParams';
import { IModalServiceInstance } from './IDialog';

import styles from './ConfirmationDialog.module.less';

enum ConfirmationDialogAction {
	PRIMARY = 'primary',
	SECONDARY = 'secondary'
}

@Component({
	selector: 'confirmation-dialog',
	templateUrl: '/partials/shared/components/confirmation-dialog.html'
})
export class ConfirmationDialogComponent {
	@Input() public resolve: any;
	@Input() public modalInstance: IModalServiceInstance;

	public params: IConfirmationDialogParams;

	private readonly ConfirmationDialogAction = ConfirmationDialogAction;
	private remember: boolean = false;
	private readonly styles = styles;

	constructor() {
		this.params = this.resolve.dialogParams;
		this.params.close = this.modalInstance.close;
		this.params.dismiss = this.modalInstance.dismiss;
	}

	private onAction(action: ConfirmationDialogAction): void {
		this.modalInstance.close({
			action,
			remember: this.remember
		});
	}
}
