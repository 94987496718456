import {
	Component,
	DoCheck,
	Input,
	OnInit
} from '@vbrick/angular-ts-decorators';
import { StateService } from '@uirouter/angularjs';

import { LoginRedirectService } from 'rev-shared/security/LoginRedirect.Service';
import { ScheduledEventService } from 'rev-shared/scheduledEvents/ScheduledEvent.Service';

import {
	UserContextService,
	IUserContextUser,
	IUserContextAccount
} from 'rev-shared/security/UserContext.Service';
import { SecurityContextService } from 'rev-shared/security/SecurityContext.Service';

import {
	HeaderSettings,
	ThemeSettings
} from 'rev-portal/branding/BrandingSettings.Contract';
import { BrandingHeaderType } from 'rev-portal/branding/BrandingHeaderType';
import { MediaStateService, MediaViewMode } from 'rev-portal/media/MediaState.Service';
import { ThemeService } from 'rev-portal/branding/Theme.Service';

import { MainHeaderPositionService } from './MainHeaderPosition.Service';

import './main-header.less';

@Component({
	selector: 'main-header',
	template: `
		<style>
			main-header > header.theme-${BrandingHeaderType.TwoTier} {
				border-bottom-color: {{ $ctrl.headerSettings.backgroundColor }};
				border-bottom-width: 2px;
				border-bottom-style: solid;
			}
		</style>
		<header
			role="banner"
			ng-class="[
				'theme-' + $ctrl.currentTheme,
				{
					'no-border': $ctrl.$state.includes('portal.video'),
					'theme-accent-border': !$ctrl.$state.includes('portal.dashboard')
				}]"
			vb-offset-height-model="$ctrl.MainHeaderPosition.setHeight($event.offsetHeight)">
			<ng-include src="$ctrl.themeTemplateUri"></ng-include>
		</header>
	`
})

export class MainHeaderComponent implements OnInit, DoCheck {
	private accountId: string;
	private event: any;
	private queryTxt: string;

	constructor(
		private $state: StateService,
		private LoginRedirectService: LoginRedirectService,
		private MediaStateService: MediaStateService,
		private MainHeaderPosition: MainHeaderPositionService,
		private ScheduledEventService: ScheduledEventService,
		private SecurityContext: SecurityContextService,
		private ThemeService: ThemeService,
		private UserContext: UserContextService
	) {
		'ngInject';
	}

	public ngOnInit(): void {
		this.accountId = this.UserContext.getAccount().id;

		if (this.UserContext.isUserAuthenticated() && this.currentTheme === BrandingHeaderType.TwoTier) {
			this.loadUpcomingEvents();
		}
	}

	public ngDoCheck(): void {
		this.MainHeaderPosition.isTransparencyActive =
			this.MainHeaderPosition.isTransparencySupported &&
			this.ThemeService.brandingSettings.headerSettings.useTransparentHeader;
	}

	public loginRedirect(): void {
		this.LoginRedirectService.redirectToLogin();
	}

	private loadUpcomingEvents(): void {
		this.ScheduledEventService.getUpcomingEvents(this.accountId)
			.then(events => this.event = events[0]);
	}

	public get currentTheme(): string {
		return this.headerSettings.brandingHeaderType;
	}

	public get guestPortalRestricted(): boolean {
		return this.isRegisteredGuest || !this.SecurityContext.checkAuthorization('guest');
	}

	public get headerSettings(): HeaderSettings {
		return this.ThemeService.brandingSettings.headerSettings;
	}

	public get isRegisteredGuest(): boolean {
		return this.UserContext.isRegisteredGuest();
	}

	public get isUserAuthenticated(): boolean {
		return this.UserContext.isUserAuthenticated();
	}

	public get themeSettings(): ThemeSettings {
		return this.ThemeService.brandingSettings.themeSettings;
	}

	public get themeTemplateUri(): string {
		return `/partials/theme/${this.currentTheme}.html`;
	}

	public get user(): IUserContextUser {
		return this.UserContext.getUser();
	}

	public get account(): IUserContextAccount {
		return this.UserContext.getAccount();
	}

	public submitMediaSearch(): void {
		this.MediaStateService.setViewMode(MediaViewMode.TABLE);
		this.$state.go('portal.media.search', { q: this.queryTxt }, { inherit: false, reload: true });
		this.queryTxt = '';
	}
}
