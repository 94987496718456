import { Component } from '@vbrick/angular-ts-decorators';

import { VideoSearchResultsComponent } from './VideoSearchResults.Component';

@Component({
	selector: 'video-search-results-tiles',
	templateUrl: '/partials/media/search/video-search-results-tiles.html',
	require: {
		videoSearchResults: '^videoSearchResults'
	}
})
export class VideoSearchResultsTilesComponent {
	private videoSearchResults: VideoSearchResultsComponent;
}
