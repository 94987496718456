import {
	Component
} from '@vbrick/angular-ts-decorators';

import { MediaMenuDataManagerDirective } from './MediaMenuDataManager.Directive';

@Component({
	selector: 'my-teams-menu-items',
	templateUrl: '/partials/portal/navigation/my-teams-menu-items.html',
	require: {
		mediaMenuDataManager: '^mediaMenuDataManager'
	}
})
export class MyTeamsMenuItemsComponent {
	private mediaMenuDataManager: MediaMenuDataManagerDirective;
}
