import { NgModule } from '@vbrick/angular-ts-decorators';
import UtilModule from 'rev-shared/util/Util.Module';

import { FileSizePipe } from './FileSize.Pipe';
import { TrustAsHtmlPipe } from './TrustAsHtml.Pipe';

@NgModule({
	id: 'VBrick.Shared.Pipes',
	imports: [
		UtilModule
	],

	declarations: [
		FileSizePipe,
		TrustAsHtmlPipe
	]
})
export default class PipesModule {}
