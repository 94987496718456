import { HLS_AKAMAI_STREAM_NAME_SUFFIX, HLS_VBRICKCDN_STREAM_NAME_SUFFIX, isAkamaiStream, isVbrickCdnStream } from 'rev-shared/util/StreamNameUtil';

export function hasCdnPushedPlayback(playbacks: any[]): boolean {
	// For now, we only care about the first playback url.
	const playback = getFirstPlayback(playbacks);
	return playback && playback.videoFormat === 'HLS' && playback.name && (isAkamaiStream(playback.name) || isVbrickCdnStream(playback.name));
}

export function isAkamaiPushedPlaybackReady(playbacks: any[], deviceId: string, streamGroupName: string): boolean {
	// For now, we only care about the first playback url.
	const playback = getFirstPlayback(playbacks);
	return playback && playback.deviceId === deviceId && playback.name === (streamGroupName + HLS_AKAMAI_STREAM_NAME_SUFFIX);
}

export function isVbrickCdnPushedPlaybackReady(playbacks: any[], deviceId: string, streamGroupName: string): boolean {
	// For now, we only care about the first playback url.
	const playback = getFirstPlayback(playbacks);
	return playback && playback.deviceId === deviceId && playback.name === (streamGroupName + HLS_VBRICKCDN_STREAM_NAME_SUFFIX);
}

function getFirstPlayback(playbacks: any[]): any {
	return playbacks?.[0];
}
