import {
	Component,
	Input
} from '@vbrick/angular-ts-decorators';

import { BrandingHeaderType } from 'rev-portal/branding/BrandingHeaderType';

import { MediaMenuDataManagerDirective } from './MediaMenuDataManager.Directive';

import './media-menu-dropdown.less';

@Component({
	selector: 'media-menu-dropdown',
	templateUrl: '/partials/portal/navigation/media-menu-dropdown.html',
	require: {
		mediaMenuDataManager: 'mediaMenuDataManager'
	}
})

export class MediaMenuDropdownComponent {
	@Input() public currentTheme: string;

	private mediaMenuDataManager: MediaMenuDataManagerDirective;

	public onToggle(open: boolean): void {
		if (open) {
			return open && this.mediaMenuDataManager.fetchAllData();
		}
	}

	public get isTwoTierTheme() {
		return this.currentTheme === BrandingHeaderType.TwoTier;
	}
}
