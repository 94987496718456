<section class="webcast-questions-list" [ngClass]="{
		'can-manage': canModerate
	}" vbUiSortable [sortableOptions]="sortableOptions" [sortableDisabled]="!allowReordering" (onSort)="onSort($event)">
	<div class="animate-repeat card-holder theme-primary-font-border-fade-50" [ngClass]="{
			'draggable': allowReordering,
			'manage-card-holder margin-top-20': canModerate
		}" *ngFor="let question of questions; trackBy: trackByQuestionNumber">

		<vb-webcast-question-card class="theme-primary-bg" [webcastId]="webcast.id" [question]="question" [canModerate]="canModerate" [questionContext]="questionContext" [ngClass]="{
				'is-closed': question.isClosed,
				'is-queued': question.isQueued,
				'direct-reply-in-progress': question.isDirectReplyInProgress
			}">
		</vb-webcast-question-card>
	</div>
</section>

<div [hidden]="questions.length || canModerate" class="noQuestions">{{ 'Event_QA_NoQuestions' | translate }}</div>
