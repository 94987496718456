import { NgModule } from '@vbrick/angular-ts-decorators';

import FileUploadModule from 'rev-shared/ui/fileUpload/FileUpload.Module';
import FormDirectivesModule from 'rev-shared/util/directives/form/FormDirectives.Module';
import PushDowngradeModule from 'rev-shared/push/Push.downgrade.Module';
import SearchModule from 'rev-portal/search/Search.Module';
import SpinnerModule from 'rev-shared/ui/spinner/Spinner.Module';
import { downgradeInjectables } from 'rev-shared/util/AngularHybridUtils';

import { TeamService } from './Team.Service';
import { ManageTeamComponent } from './ManageTeam.Component';

@NgModule({
	id: 'Vbrick.Shared.Team',
	imports: [
		FileUploadModule,
		FormDirectivesModule,
		PushDowngradeModule,
		SearchModule,
		SpinnerModule
	],
	declarations: [
		ManageTeamComponent
	],
})
export default class SharedTeamModule {}


downgradeInjectables(SharedTeamModule, [{
	name: 'TeamService',
	injectable: TeamService
}]);
