import { IQService, IPromise } from 'angular';

import { ThemeService } from 'rev-portal/branding/Theme.Service';
import { MomentLocaleLoader } from 'rev-shared/date/MomentLocaleLoader.Provider';

export const PortalStartup = 'PortalStartup';

export interface IPortalStartup {
	$promise: IPromise<any>;
}

export function getPortalStartup(
	$q: IQService,
	SignalRStartup: IPromise<any>,
	SecurityStartup: IPromise<any>,
	ThemeService: ThemeService,
	MomentLocaleLoader: MomentLocaleLoader
): IPortalStartup {
	'ngInject';

	return {
		$promise: $q.all([MomentLocaleLoader.load(), SignalRStartup, SecurityStartup, ThemeService.initialize()])
	};
}
