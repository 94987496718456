import {
	AfterViewInit,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	NgZone,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild
} from '@angular/core';

import ResizeObserver from 'resize-observer-polyfill';

import Flickity from 'flickity'; // https://github.com/metafizzy/flickity
import 'flickity/dist/flickity.css';

import { FlickityExtended } from 'rev-shared/ui/flickityCarousel/FlickityExtendend';

import './video-playlist-carousel.less';

export interface IOnSelectionChangedEvent {
	videoId: string;
}

@Component({
	selector: 'video-playlist-carousel',
	templateUrl: './VideoPlaylistCarousel.Component.html'
})
export class VideoPlaylistCarousel implements OnInit, AfterViewInit, OnChanges, OnDestroy {
	@Input() public currentVideoId: string;
	@Input() public videos: any[];

	@Output() protected onSelectionChanged: EventEmitter<IOnSelectionChangedEvent> = new EventEmitter();

	@ViewChild('carouselContainer', { static: true }) private carouselContainer: ElementRef;

	protected carousel: FlickityExtended; // Flickity (typedef isn't up to date)
	protected resizeObserver: ResizeObserver;

	constructor(
		private el: ElementRef,
		private zone: NgZone
	){}

	public ngOnInit(): void {
		this.resizeObserver = new ResizeObserver(() => this.carousel.resize());
	}

	public ngAfterViewInit(): void {
		this.carousel = new Flickity(this.carouselContainer.nativeElement, {
			cellAlign: 'left',
			cellSelector: '.carousel-cell',
			contain: true,
			draggable: true,
			groupCells: true,
			pageDots: false,
			prevNextButtons: false,
			setGallerySize: false
		}) as FlickityExtended;

		this.zone.runOutsideAngular(() => this.resizeObserver.observe(this.el.nativeElement));
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.videos && this.videos) {
			this.updateCarousel();
		}

		if (changes.currentVideoId) {
			this.selectCarouselCell();
		}
	}

	public ngOnDestroy(): void {
		this.resizeObserver.disconnect();
		this.carousel.destroy();
	}

	public get isPrevButtonDisabled(): boolean {
		return !this.carousel || this.carousel.selectedIndex === 0;
	}

	public get isNextButtonDisabled(): boolean {
		return !this.carousel || this.carousel.selectedIndex === this.carousel.slides.length - 1;
	}

	public onPrevButtonClick(): void {
		this.carousel.previous(false, false);
	}

	public onNextButtonClick(): void {
		this.carousel.next(false, false);
	}

	protected updateCarousel(): void {
		if (this.carousel) {
			this.carousel.activate();
		}
	}

	protected selectCarouselCell(): void {
		if (!this.carousel) {
			return;
		}

		const currentVideoIndex: number = this.videos.findIndex((video: any) => video.id === this.currentVideoId);

		this.carousel.selectCell(currentVideoIndex, null, false);
	}

	public onVideoClick(videoId: string): void {
		this.onSelectionChanged.emit({
			videoId
		});
	}
}
