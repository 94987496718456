import { Component, Input, OnInit } from '@vbrick/angular-ts-decorators';
import { IFormController } from 'angular';
import { EditWebcastModel } from '../editWebcast/EditWebcastModel';

import styles from '../editWebcast/EditWebcast.module.less';
import { ZoomService } from './Zoom.Service';

@Component({
	selector: 'edit-webcast-zoom-input',
	templateUrl: '/partials/scheduled-events/zoom/edit-webcast-zoom-input.html',
})
export class EditWebcastZoomInputComponent implements OnInit {
	@Input() public form: IFormController;
	@Input() public webcast: EditWebcastModel;

	public readonly styles = styles;

	constructor(
		private ZoomService: ZoomService
	) {
		'ngInject';
	}

	public ngOnInit(): void {
		if(!this.ZoomService.meetings) {
			this.ZoomService.loadMeetings();
		}
	}
}
