import {
	Component,
	Input
} from '@vbrick/angular-ts-decorators';
import { INgModelController } from 'angular';

import { BrandingHeaderType } from 'rev-portal/branding/BrandingHeaderType';
import { HeaderSettings } from 'rev-portal/branding/BrandingSettings.Contract';
import { defaultHelpUri } from 'rev-portal/branding/Defaults.ts';

import { MobileSubMenu } from 'rev-portal/navigation/mobileMenu/MobileSubMenu.Constant';

import './help.less';
import { ThemeService } from 'rev-portal/branding/Theme.Service';

class BaseHelpMenuComponent {

	public get helpLinks(): HeaderSettings {
		return this.ThemeService.settings.headerSettings;
	}

	public get helpUri(): string {
		return this.helpLinks.helpUri || defaultHelpUri;
	}

	public get hasCustomHelpLinks(): boolean {
		return this.helpLinks.customHelpLinks.length > 0;
	}

	constructor(
		public ThemeService
	){
		'ngInject';
	}
}

@Component({
	selector: 'help-menu',
	templateUrl: '/partials/portal/navigation/help-menu.html'
})
export class HelpMenuComponent extends BaseHelpMenuComponent {
	@Input() public buttonMode: boolean;

	constructor(
		public ThemeService: ThemeService
	) {
		'ngInject';
		super(ThemeService);
	}

	public get isTwoTierTheme() {
		return this.ThemeService.brandingSettings.headerSettings.brandingHeaderType === BrandingHeaderType.TwoTier;
	}
}

@Component({
	selector: 'help-menu-mobile',
	require: {
		ngModelController: 'ngModel',
	},
	templateUrl: '/partials/portal/navigation/help-menu-mobile.html'
})
export class HelpMenuMobileComponent extends BaseHelpMenuComponent {
	@Input() private ngModel: string;
	@Input() private currentTheme: string;

	private ngModelController: INgModelController;

	private get showHelpSubMenu(): boolean {
		return this.ngModel === MobileSubMenu.Help;
	}

	private syncModelValue(): void {
		this.ngModel = this.ngModel === MobileSubMenu.Help ? undefined : MobileSubMenu.Help;
		this.ngModelController.$setViewValue(this.ngModel);
	}

	private get isNettrixTheme(): boolean {
		return this.currentTheme === BrandingHeaderType.Nettrix;
	}
}
