import { NgModule } from '@vbrick/angular-ts-decorators';

import { downgradeComponents } from 'rev-shared/util/AngularHybridUtils';
import { Video360IndicatorComponent } from './Video360Indicator.Component';

@NgModule({
	id: 'VBrick.Shared.Media.Video360Indicator',
})
export default class Video360IndicatorModule {}

downgradeComponents(Video360IndicatorModule, [{
	name: 'video360Indicator',
	component: Video360IndicatorComponent
}]);
