import { Component, Input } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { INotifications } from 'rev-portal/scheduledEvents/webcast/Webcast.Contract';
import { WebcastModel } from 'rev-portal/scheduledEvents/webcast/model/WebcastModel';
import { orderBy } from 'rev-shared/util/SortUtil';

import { WebcastViewQuestionsService } from './WebcastViewQuestions.Service';
import { Question } from './Question';
import { QuestionContext } from './VbWebcastQuestionCard.Component';

import styles from './VbWebcastAttendeeQuestionSidebar.Component.module.less';

const whenModified = 'whenModified';

@Component({
	selector: 'vb-webcast-attendee-question-sidebar',
	templateUrl: './VbWebcastAttendeeQuestionSidebar.Component.html',
	host: {
		'[class]': 'styles.root'
	}
})
export class VbWebcastAttendeeQuestionSidebarComponent {
	@Input() public webcast: WebcastModel;
	@Input() public sidebarNotifications: INotifications;

	private selectedTab: string;
	public readonly styles = styles;
	public readonly groupQuestionContext = QuestionContext.publicQuestions;
	public readonly myQuestionContext = QuestionContext.myQuestions;
	public attendeeQuestionsContext$: Observable<any>;

	constructor(
		private WebcastViewQuestionsService: WebcastViewQuestionsService
	) {}

	public ngOnInit(): void {
		this.ensureQuestionsContextLoaded();
	}

	public ngOnDestroy(): void {
		this.resetUnreadMyQuestions(false);
		this.resetUnreadPublicQuestions(false);

		this.WebcastViewQuestionsService.unsubscribeHandlers();
	}

	public ensureQuestionsContextLoaded(): void {
		this.WebcastViewQuestionsService.subscribeAttendeeHandlers(this.webcast, this.sidebarNotifications.questions);

		this.attendeeQuestionsContext$ = combineLatest([
			this.WebcastViewQuestionsService.attendeeMyQuestions$,
			this.WebcastViewQuestionsService.attendeePublicQuestions$
		])
			.pipe(
				map(([questions, publicQuestions]) => {
					const pQuestions = publicQuestions?.filter(q => !q.isNew);
					return {
						questions: orderBy(questions, q => q[whenModified], false),
						publicQuestions: orderBy(pQuestions, q => q[whenModified], false),
						groupQACount: publicQuestions.filter(question => question.isNew).length,
						myQACount: questions.filter(question => question.isNew).length,
					};
				})
			);
	}

	public onTabSelect(tabId: string): void {
		if (!this.selectedTab) {
			this.selectedTab = tabId;
			return;
		}
		this.selectedTab = tabId;
		if (tabId === this.myQuestionContext) {
			this.resetMyQACounter();
			this.resetUnreadPublicQuestions();
		} else if (tabId === this.groupQuestionContext) {
			this.resetGroupQACounter();
			this.resetUnreadMyQuestions(false);
		}
	}

	public resetGroupQACounter(): void {
		this.WebcastViewQuestionsService.resetPublicQuestionsCounter();
	}

	public resetUnreadPublicQuestions(resetAll: boolean = true): void {
		this.WebcastViewQuestionsService.resetUnreadPublicQuestions(resetAll);
	}

	public resetMyQACounter(): void {
		this.WebcastViewQuestionsService.resetMyQuestionsCounter();
	}
	public resetUnreadMyQuestions(resetAll: boolean): void {
		this.WebcastViewQuestionsService.resetUnreadAttendeeMyQuestions(resetAll);
	}

	public onQuestionAsked(question: Question): void {
		this.WebcastViewQuestionsService.addAttendeeMyQuestion(question);
		const unsub = this.WebcastViewQuestionsService.applyAttendeePushHandlers([question], this.sidebarNotifications.questions);
		this.WebcastViewQuestionsService.subscribeMyNewQuestionHandler(this.webcast, unsub);
	}
}
