import {
	Component,
	Output
} from '@vbrick/angular-ts-decorators';

import {
	IPromise,
	IQService,
	ITimeoutService
} from 'angular';

import { IModalInstanceService } from 'rev-shared/ui/dialog/IDialog';

import { ComponentCallback } from 'rev-shared/ts-utils/ComponentCallback';
import { IDialog, IDialogService } from 'rev-shared/ui/dialog/IDialog';

import { MediaStateService } from 'rev-portal/media/MediaState.Service';

import { BulkEditService } from './BulkEdit.Service';
import { VideoSelectionModelService } from './VideoSelectionModel.Service';

@Component({
	selector: 'vb-delete-videos-button',
	templateUrl: '/partials/media/bulk/delete-button.html'
})
export class VbDeleteVideosButtonComponent {
	@Output() public loadSearchResults: ComponentCallback<void, IPromise<void>>;

	private deleteConfirmation: IDialog;
	private errorDialogOpts: {[key: string]: string};
	private processingDialogInstance: IModalInstanceService;
	private processingDialog: IDialog;
	private processingDialogTimer: IPromise<any>;

	constructor(
		private $q: IQService,
		private $timeout: ITimeoutService,
		private BulkEditService: BulkEditService,
		private Dialog: IDialogService,
		private MediaStateService: MediaStateService,
		private VideoSelectionModel: VideoSelectionModelService
	) {
		'ngInject';
	}

	private get disabled(): boolean {
		return this.VideoSelectionModel.selectionCount < 1 ||
			this.VideoSelectionModel.selectionCount > this.VideoSelectionModel.limit;
	}

	private closeProcessingDialog(): void {
		this.$timeout.cancel(this.processingDialogTimer);

		if (this.processingDialogInstance) {
			this.processingDialogInstance.close();
		}

		this.processingDialogTimer = this.processingDialogInstance = null;
	}

	private deleteVideos(): void {
		this.openProcessingDialog();

		this.ensureSelectionLoaded()
			.then(() => this.BulkEditService.deleteVideos(this.VideoSelectionModel.selectedVideos.map(v => v.id)))
			.then(({ message }) => {
				this.Dialog.getDialog('bulkEditSubmissionDialog')
					.open({ legalHoldVideosCount: message.legalHoldVideosCount, isDeleting: true })
					.result
					.then(() => this.MediaStateService.navigateBack());
			})
			.catch(() => this.Dialog.getDialog('AlertDialog').open(this.errorDialogOpts))
			.finally(() => this.closeProcessingDialog());
	}

	private ensureSelectionLoaded(): IPromise<void> {
		if (this.VideoSelectionModel.isSelectAll) {
			return this.loadSearchResults();
		}

		return this.$q.when();
	}

	private openProcessingDialog(): void {
		this.processingDialogTimer = this.$timeout(() => this.processingDialogInstance = this.processingDialog.open(), 1000);
	}

	private submit(): void {
		this.deleteConfirmation.open().result
			.then(() => this.deleteVideos());
	}
}
