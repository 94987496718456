import {
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output
} from '@angular/core';

import type { Component as AmChartsComponent, Slice } from '@amcharts/amcharts4/core';
import type { PieChart } from '@amcharts/amcharts4/.internal/charts/types/PieChart';

import { IOnChartReadyData } from '../amChartsCore/VbUiAmChartsCore.Component';
import { getCommonLegendConfig } from '../amChartsCore/LegendConfig';

import { deepMergeObjects } from 'rev-shared/util/index';

import styles from './VbUiPieChart.Component.module.less';

export interface IOnSliceHitData {
	data: any;
}

const LEGEND_ITEM_PADDING: number = 2;

@Component({
	selector: 'vb-ui-pie-chart',
	host: {
		'[class]': 'styles.pieChart'
	},
	template: `
		<vb-ui-am-charts-core
			[chartConfig]="chartConfig"
			[chartType]="chartType"
			[data]="data"
			[updateEnabled]="updateEnabled"
			(onChartReady)="onChartReadyInternal($event)">
		</vb-ui-am-charts-core>
	`
})
export class VbUiPieChartComponent implements OnInit {
	@Input() public categoryField: string;
	@Input() public updateEnabled: boolean;
	@Input() public data: any;
	@Input() public valueField: string;

	@Output() public onChartReady = new EventEmitter<IOnChartReadyData>();
	@Output() public onSliceHit = new EventEmitter<IOnSliceHitData>();

	private chart: AmChartsComponent;
	public chartConfig: any;
	public chartType: typeof PieChart;
	public readonly styles = styles;

	public ngOnInit(): void {
		Promise.all([
			import(/* webpackChunkName: "amChartsPieChart" */ '@amcharts/amcharts4/.internal/charts/types/PieChart'),
			import(/* webpackChunkName: "amChartsNumberFormatter" */ '@amcharts/amcharts4/.internal/core/formatters/NumberFormatter')
		])
			.then(([pieChartExports]) => {
				this.chartType = (pieChartExports as any).PieChart; // TODO: TypeScript 3.7.3 workaround
				this.initChartConfig();
			});
	}

	private initChartConfig(): void {
		this.chartConfig = {
			innerRadius: '33.33%',
			legend: deepMergeObjects(getCommonLegendConfig(), {
				itemContainers: {
					paddingBottom: LEGEND_ITEM_PADDING,
					paddingTop: LEGEND_ITEM_PADDING,
				},
				markers: {
					children: [{
						dy: -1
					}]
				},
				contentAlign: 'center',
				labels: {
					text: `{name} ({value.percent.formatNumber('#.0')}%)`
				},
				valueLabels: {
					text: ''
				}
			}),
			series: [{
				type: 'PieSeries',
				dataFields: {
					category: this.categoryField,
					value: this.valueField
				},
				labels: {
					disabled: true
				},
				slices: {
					events: {
						hit: event => this.onSliceHitInternal(event)
					},
					togglable: false
				},
				ticks: {
					disabled: true
				}
			}]
		};
	}

	public onChartReadyInternal(event: IOnChartReadyData): void {
		this.chart = event.chart;

		this.onChartReady.emit({
			chart: this.chart
		});
	}

	private onSliceHitInternal(event: { target: Slice }): void {
		if (this.onSliceHit) {
			this.onSliceHit.emit({
				data: event.target.dataItem.dataContext
			});
		}
	}
}
