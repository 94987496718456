<div layout="row" [ngClass]="styles.kpiWrapper">
	<vb-ui-report-cell flex="1 1 0" [header]="'Reports_VideoPlayer_BitRate' | translate">
		<vb-ui-kpi [decimalPlaces]="1" [value]="data.avgBitRateKbps">
		</vb-ui-kpi>
	</vb-ui-report-cell>

	<vb-ui-report-cell flex="1 1 0" [header]="'Reports_Video_ExperiencedRebufferingPercentage' | translate">
		<vb-ui-kpi [suffix]="'%'" [decimalPlaces]="2" [value]="data.experiencedRebuffering">
		</vb-ui-kpi>
	</vb-ui-report-cell>

	<vb-ui-report-cell flex="1 1 0" [header]="'Reports_Video_AverageRebufferingDurationInSeconds' | translate">
		<vb-ui-kpi [suffix]="'s'" [decimalPlaces]="1" [value]="(data.avgExperiencedBufferingDuration | vbTimespanToSeconds)">
		</vb-ui-kpi>
	</vb-ui-report-cell>

	<vb-ui-report-cell flex="1 1 0" [header]="'Reports_video_ExperiencedErrorPerTotalAttendees' | translate">
		<vb-ui-kpi [decimalPlaces]="2" [value]="data.totalExperiencedErrorsPerAttendees">
		</vb-ui-kpi>
	</vb-ui-report-cell>

	<vb-ui-report-cell flex="1 1 0" [header]="'Reports_video_MulticastErrorPerTotalAttendees' | translate">
		<vb-ui-kpi [decimalPlaces]="2" [value]="data.totalMulticastErrorsPerAttendees">
		</vb-ui-kpi>
	</vb-ui-report-cell>
</div>