import { Component } from '@angular/core';
import { VbWebcastManageQuestionSidebarComponent } from './VbWebcastManageQuestionSidebar.Component';
import './VbWebcastManageQuestionFullscreen.Component.less';

@Component({
	selector: 'vb-webcast-manage-question-fullscreen',
	templateUrl: './VbWebcastManageQuestionFullscreen.Component.html',
	host: {
		'class': 'questionsFullscreenHost theme-primary'
	}
})
export class VbWebcastManageQuestionFullscreenComponent extends VbWebcastManageQuestionSidebarComponent {
}
