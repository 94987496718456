import { NgModule } from '@vbrick/angular-ts-decorators';
import {
	HelpMenuComponent,
	HelpMenuMobileComponent
} from './HelpMenu.Component';

@NgModule({
	id: 'VBrick.Portal.Navigation.Help',
	declarations: [
		HelpMenuComponent,
		HelpMenuMobileComponent
	],
})
export default class HelpModule {}
