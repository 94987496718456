import { Component, Input } from '@angular/core';

import styles from './WebcastReports.module.less';

@Component({
	selector: 'webcast-quality-experience-report',
	templateUrl: './WebcastQualityExperienceReport.Component.html'
})
export class WebcastQualityExperienceReportComponent {
	@Input() public performanceData: any;
	@Input() public videoPlaybackData: any;

	public readonly styles = styles;
}
