import { NgModule } from '@vbrick/angular-ts-decorators';

import { VBrickTextInputDirective } from './VbTextInput.Directive';

@NgModule({
	id: 'Vbrick.UI.TextInput',
	declarations: [
		VBrickTextInputDirective
	]
})

export default class VBrickTextInputModule {}
