import {
	Component,
	Input
} from '@vbrick/angular-ts-decorators';
import { IModalInstanceService } from 'rev-shared/ui/dialog/IDialog';

@Component({
	selector: 'bulk-edit-submission-dialog',
	templateUrl: '/partials/media/bulk/bulk-edit-submission-dialog.html'
})
export class BulkEditSubmissionDialogComponent {
	@Input() public resolve: { dialogParams: any };
	@Input() public modalInstance: IModalInstanceService;

	private get legalHoldVideoCount(): number {
		return this.resolve.dialogParams.legalHoldVideosCount;
	}

	private get isEditing(): boolean {
		return this.resolve.dialogParams.isEditing;
	}

	private get isDeleting(): boolean {
		return this.resolve.dialogParams.isDeleting;
	}
}
