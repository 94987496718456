import { Directive, OnInit } from '@vbrick/angular-ts-decorators';
import { StateService, TransitionService } from '@uirouter/angularjs';
import { IAugmentedJQuery, element } from 'angular';

import { WEBCAST_FULLSCREEN_STATE, WEBCAST_VIEW_STATE } from 'rev-portal/scheduledEvents/webcast/Constants';

const THEMED_STATES = [WEBCAST_VIEW_STATE, WEBCAST_FULLSCREEN_STATE];

@Directive({
	selector: '[vb-portal-styles]'
})
export class VbPortalStylesDirective implements OnInit {
	constructor(
		private $element: IAugmentedJQuery,
		private $state: StateService,
		private $transitions: TransitionService,
	) {
		'ngInject';
	}
	public ngOnInit(): void {
		this.$transitions.onSuccess({}, () => {
			this.applyCssClass();
		});
	}

	private applyCssClass(): void {
		const element = this.$element;
		element.toggleClass('admin admin-light', this.$state.includes('portal.admin'));
		element.toggleClass('system', !this.$state.includes('portal') && !this.isThemedState);
		//This is a temp for now.
		element.toggleClass('theme-primary', this.isThemedState);
	}

	private get isThemedState(): boolean {
		return THEMED_STATES.some( state => this.$state.includes(state));
	}
}
