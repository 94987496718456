import { Component, Input, OnInit } from '@vbrick/angular-ts-decorators';
import { IModalInstanceService } from 'rev-shared/ui/dialog/IDialog';

@Component({
	selector: 'alert-dialog',
	templateUrl: '/partials/alert-dialog.html'
})
export class AlertDialogComponent implements OnInit {
	@Input() public resolve: { dialogParams: any };
	@Input() public modalInstance: IModalInstanceService;

	public message: string;
	public okButtonText: string;
	public title: string;

	public ngOnInit(): void {
		this.okButtonText = this.resolve.dialogParams.okButtonText;
		this.message = this.resolve.dialogParams.message;
		this.title = this.resolve.dialogParams.title;
	}

	private cancel(): void {
		this.modalInstance.dismiss();
	}
}
