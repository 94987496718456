import { Component, Input, OnInit, NgZone } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { escape, debounce, noop } from 'underscore';


import styles from './RealTimeAnalyticsDevicesTab.module.less';

@Component({
	selector: 'zones-list-dialog',
	templateUrl: './ZonesListDialog.Component.html',
})
export class ZonesListDialogComponent implements OnInit {
	@Input() public deviceName: string;
	@Input() public zones: string[];

	public filterTxt: string = '';
	public filteredZones: string[];

	public readonly styles = styles;
	public readonly escape = escape;

	constructor(
		public bsModalRef: BsModalRef,
		public zone: NgZone
	){ }

	public ngOnInit(): void {
		this.updateFilter();
	}

	public onFilterChange = debounce(() => {
		this.updateFilter();
		this.zone.run(noop);
	}, 100);

	public updateFilter(): void {
		const filterTxt = this.filterTxt.toLowerCase();
		this.filteredZones = !filterTxt ? this.zones :
			this.zones.filter(z => z.toLowerCase().includes(filterTxt));
	}
}
