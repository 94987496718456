import { NgModule } from '@vbrick/angular-ts-decorators';

//downgrade section
import { downgradeComponents, downgradeInjectables } from 'rev-shared/util/AngularHybridUtils';
import { NotificationComponent } from './Notification.Component';
import { UserNotificationsService } from './UserNotifications.Service';

@NgModule({
	id: 'VBrick.Portal.Notifications',
})
export default class NotificationsModule {
}
downgradeInjectables(NotificationsModule, [
	{
		name: 'UserNotifications',
		injectable: UserNotificationsService
	}
]);

downgradeComponents(NotificationsModule, [
	{
		name: 'vbNotification',
		component: NotificationComponent
	}
]);
