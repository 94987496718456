import { NgModule } from '@vbrick/angular-ts-decorators';
import 'selection-model';

import VBrickUICheckboxModule from 'rev-shared/ui/checkbox/Checkbox.Module';

import { VbGridListComponent } from './VbGridList.Component';
import { VbGridListColumnComponent } from './VbGridListColumn.Component';
import { VbGridListReorderComponent } from './VbGridListReOrder.Component';
import { VbGridListRowComponent } from './VbGridListRow.Component';
import { VbGridListSelectionColumnComponent } from './VbGridListSelectionColumn.Component';
import { VbGridListSelectionHeaderComponent } from './VbGridListSelectionHeader.Component';

import { VbGridToolbarColumnDirective } from './VbGridToolbarColumn.Directive';

@NgModule({
	id: 'VBrick.UI.GridList',
	imports: [
		'selectionModel',

		VBrickUICheckboxModule
	],
	declarations: [
		VbGridListComponent,
		VbGridListColumnComponent,
		VbGridListReorderComponent,
		VbGridListRowComponent,
		VbGridListSelectionColumnComponent,
		VbGridListSelectionHeaderComponent,

		VbGridToolbarColumnDirective
	]
})
export class VbUiGridListModule {}
