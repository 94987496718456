import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { IMessageBatch } from '@vbrick/vbrick-logging-client/src';

import { noop } from 'rev-shared/util';
import { BootstrapContext } from 'rev-shared/bootstrap/BootstrapContext';
import { lastValueFrom } from 'rev-shared/rxjs/lastValueFrom';
import { UserContextService } from 'rev-shared/security/UserContext.Service';
import { UserLocalIPService } from 'rev-shared/security/UserLocalIP.Service';

import { WebcastPlayerLoggingClient } from './WebcastPlayerLoggingClient';

const HTTP_RESPONSE_STATUS_CODE_UNAUTHORIZED: number = 401;
const RESOURCE_TYPE_WEBCAST: string = 'webcast';
const TOKEN_RESOURCE_AUD: string = 'request_validator';

@Injectable({
	providedIn: 'root'
})
export class WebcastPlayerLoggingService {
	constructor(
		private http: HttpClient,
		private UserContext: UserContextService,
		private UserLocalIPService: UserLocalIPService
	) {}

	public getClient(webcastId: string, runNumber: number): WebcastPlayerLoggingClient {
		return new WebcastPlayerLoggingClient(
			this.UserContext,
			this.UserLocalIPService,
			webcastId,
			runNumber,
			{
				getToken: () => this.getToken(webcastId),
				sendMessages: (messages: IMessageBatch, token: string) => this.sendMessages(messages, token)
			}
		);
	}

	private getToken(webcastId: string): Promise<any> {
		return lastValueFrom(this.http.get<any>(`/auth/token`, {
			params: {
				aud: TOKEN_RESOURCE_AUD,
				resourceId: webcastId,
				resourceType: RESOURCE_TYPE_WEBCAST
			}
		})).then(result => result.token);
	}

	private sendMessages(messages: IMessageBatch, token: string): Promise<void> {
		return lastValueFrom(
			this.http.post(BootstrapContext.analyticsUrl, messages, {
				headers: {
					Authorization: `Bearer ${token}`
				}
			}))
			.then(noop)
			.catch((reason: HttpErrorResponse) => {
				(reason as any).invalidToken = reason.status === HTTP_RESPONSE_STATUS_CODE_UNAUTHORIZED;

				return Promise.reject(reason);
			});
	}
}
