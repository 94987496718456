import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { HlsUtilService } from 'vbrick-player-src/HlsUtil.Service';
import { getUrlPath } from 'rev-shared/util/Util.Service';
import { isPlaybackTokenAvailable, getPlaybackToken, PLAYBACK_TOKEN_END_POINT, useWithCredentials } from 'rev-shared/videoPlayer/PlaybackTokenUtility';
import { multicastXhrConfig, removeQueryStr } from '@vbrick/vbrick-player-hls-plugin/MulticastHlsUtil';
import { ResourceType } from 'rev-shared/videoPlayer/ResourceType';
import { SecondMs } from 'rev-shared/date/Time.Constant';

const tokenExpireTimeMs = 18 * SecondMs;

@Injectable()
export class HlsFileUtilService {
	private skipChildPlaylistCheck: boolean;
	private isPlaybackTokenAvailable: boolean;
	private useWithCredentials: boolean;
	private tokenStartTimeMs: number;

	constructor(
		private http: HttpClient,
		private HlsUtilService: HlsUtilService
	) {}

	public waitForLivePlaybacks(playbacks: any[], timeoutMs: number, checkIntervalMs: number, skipChildPlaylistCheck?: boolean): Promise<any[]>{
		const newPlaybacks = Array.from(playbacks);
		const url: string = playbacks[0].url;
		const isMulticast: boolean = this.HlsUtilService.isMulticast(url, '');

		this.skipChildPlaylistCheck = skipChildPlaylistCheck;
		this.isPlaybackTokenAvailable = isPlaybackTokenAvailable(url);
		this.useWithCredentials = useWithCredentials(url);
		this.tokenStartTimeMs = Date.now();

		return this.waitForLiveStream(url, timeoutMs, checkIntervalMs, isMulticast)
			.catch(err => {
				console.log(`Error ${err} ignored in waitForLiveStreamPlayback().`);
			})
			.then(refreshedUrl => {
				if (refreshedUrl) {
					newPlaybacks[0].url = refreshedUrl;
				}
				return newPlaybacks;
			});
	}

	private waitForLiveStream(url: string, timeoutMs: number, checkIntervalMs: number, isMulticast: boolean, startTimeMs: number = Date.now()): Promise<string> {
		if ((Date.now() - startTimeMs) > timeoutMs) {
			return Promise.reject(`Timed out for ${url}`);
		}

		return this.refreshToken(url)
			.then(refreshedUrl => this.checkLiveStream(refreshedUrl, timeoutMs, checkIntervalMs, isMulticast, startTimeMs))
			.then(refreshedUrl => {
				if (refreshedUrl) {
					console.log(`Stream ${url} is live after ${Date.now() - startTimeMs} ms.`);
					return refreshedUrl;
				}

				return this.waitAndCheckLiveStream(url, timeoutMs, checkIntervalMs, isMulticast, startTimeMs);
			})
			.catch(err => {
				console.error(`waitForStream error: ${err} for ${url}`);
				return undefined;
			});
	}

	private waitAndCheckLiveStream(url: string, timeoutMs: number, checkIntervalMs: number, isMulticast: boolean, startTimeMs: number): Promise<string> {
		return new Promise((resolve => window.setTimeout(resolve, checkIntervalMs)))
			.then(() => this.waitForLiveStream(url, timeoutMs, checkIntervalMs, isMulticast, startTimeMs));
	}

	private checkLiveStream(url: string, timeoutMs: number, checkIntervalMs: number, isMulticast: boolean, startTimeMs: number): Promise<string> {
		const sanitizedUrl: string = isMulticast ?
			removeQueryStr(url) :
			url;

		return this.http.get(sanitizedUrl, {
			headers: isMulticast ? multicastXhrConfig(url).additionalHeaders : undefined,
			responseType: 'text',
			withCredentials: this.isPlaybackTokenAvailable && this.useWithCredentials ? true : undefined
		})
			.toPromise()
			.then(playlist => {
				if (this.skipChildPlaylistCheck) {
					return url;
				}

				if (playlist.lastIndexOf('#EXT-X-ENDLIST') >= 0) {
					return undefined;
				} else if (playlist.includes('#EXT-X-STREAM-INF')){
					let childUrl = '';
					const parts = playlist.split('\n');

					for (let ii = 0; ii < parts.length; ii++) {
						if (parts[ii].includes('#EXT-X-STREAM-INF') && ii < parts.length - 1) {
							childUrl = this.getChildUrl(url, parts[ii + 1], isMulticast);
							break;
						}
					}

					if (childUrl === '') {
						return Promise.reject(`Invalid playlist %{url}`);
					}

					const result = this.checkLiveStream(childUrl, timeoutMs, checkIntervalMs, isMulticast, startTimeMs);
					if (result) {
						// Child url is good, return the original
						return url;
					}
					return result;
				}

				return url;
			})
			.catch(err => {
				if ((Date.now() - startTimeMs) > timeoutMs) {
					return Promise.reject(err);
				}

				return new Promise(resolve => window.setTimeout(resolve, checkIntervalMs))
					.then(() => this.refreshToken(url))
					.then(refreshedUrl => this.checkLiveStream(refreshedUrl, timeoutMs, checkIntervalMs, isMulticast, startTimeMs));
			});
	}

	private isAbsoluteUrl(url: string): boolean {
		return url.match('/^http[s]?:\/\//i') != null;
	}

	private getChildUrl(parentUrl: string, childUrlPart: string, isMulticast: boolean): string {
		if (this.isAbsoluteUrl(childUrlPart)) {
			return childUrlPart;
		}
		return this.constructChildUrl(parentUrl, childUrlPart, isMulticast);
	}

	private constructChildUrl(parentUrl: string, childUrlPart: string, isMulticast: boolean): string {
		let childUrl = '';
		let queryString = '';
		[childUrl, queryString] = parentUrl.split('?');

		const index = childUrl.lastIndexOf('/');
		if (index >= 0) {
			childUrl = childUrl.substr(0, index + 1);
		}

		childUrl += childUrlPart;
		if (isMulticast && queryString && queryString.length && !childUrl.includes('?')) {
			// VBMulticast playlist sometime contains child url part without any query string which is invalid in VBMulticast system.
			// We work around it by copying the query string from the parent.
			childUrl += `?${queryString}`;
		}
		return childUrl;
	}

	private refreshToken(url: string): Promise<string> {
		// We need to refresh CF token because it's short-lived.
		const tokenName = getPlaybackToken(url);
		const found = url.match(/\/\/[^\/]*(?<path>\/[^\/]*\/(?<webcastId>[^\/]*)\/\d+\/[^\/]*\/[^?]*)\?(?<queries>.*)/);	// CF URL pattern
		if (!found || !tokenName || (Date.now() - this.tokenStartTimeMs) < tokenExpireTimeMs) {
			return Promise.resolve(url);
		}

		const path = found[1];
		const resourceId = found[2];
		const queries = found[3];
		return this.http.get(PLAYBACK_TOKEN_END_POINT, {
			params: {
				resourceId: resourceId,
				resourceType: ResourceType.Webcast,
				tokenName,
				path: getUrlPath(path)
			},
			responseType: 'text'
		})
			.toPromise()
			.then(newQueries => {
				this.tokenStartTimeMs = Date.now();
				return url.replace(queries, newQueries);
			});
	}
}
