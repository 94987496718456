<div #carouselContainer class="carousel-container">
	<button [attr.aria-label]="'Carousel_Previous' | translate" class="btn-carousel btn-carousel-prev" type="button" [disabled]="isPrevButtonDisabled" (click)="onPrevButtonClick()">
		<span class="glyphicon glyphicon-chevron-left"></span>
	</button>

	<a class="carousel-cell" *ngFor="let playlistVideo of videos" [attr.aria-label]="playlistVideo.title" [ngClass]="{
			selected: playlistVideo.id === currentVideoId
		}" [tooltip]="playlistVideo.title" container="body" placement="top" (click)="onVideoClick(playlistVideo.id)">
		<img [alt]="'ThumbnailForAria' | translate : { '0': playlistVideo.title }" [src]="playlistVideo.thumbnailUri || (playlistVideo.hasAudioOnly ? '/shared/img/audio-thumbnail.png' : '/shared/img/default-thumbnail.png')">
		{{ playlistVideo.title }}
	</a>

	<button [attr.aria-label]="'Carousel_Next' | translate" class="btn-carousel btn-carousel-next" type="button" [disabled]="isNextButtonDisabled" (click)="onNextButtonClick()">
		<span class="glyphicon glyphicon-chevron-right"></span>
	</button>
</div>