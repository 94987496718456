<real-time-analytics-data-grid-wrapper [header]="'Event_RealTimeAnalytics_Tab_Zones' | translate" [gridId]="'zones'" [webcast]="webcast">
	<vb-ui-infinite-scroll-grid [getRows]="rtaZonesService.getRows" [clearScrollId]="AnalyticsService.clearEventScrollId">

		<!--Column Picker Groups-->
		<vb-ui-column-picker-group [headerName]="'Reports_Column_Group_Metrics' | translate" [id]="ZonesColumnPickerGroup.METRICS" [isExpanded]="true">
		</vb-ui-column-picker-group>

		<vb-ui-column-picker-group [headerName]="'Stream' | translate" [id]="ZonesColumnPickerGroup.STREAM" [isExpanded]="true">
		</vb-ui-column-picker-group>

		<vb-ui-column-picker-group [headerName]="'Zone' | translate" [id]="ZonesColumnPickerGroup.ZONE" [isExpanded]="true">
		</vb-ui-column-picker-group>

		<!--Default Columns-->
		<vb-ui-data-grid-column [columnPickerGroup]="ZonesColumnPickerGroup.ZONE" [field]="'ZoneName'" [headerName]="'ZoneName' | translate" [sortable]="true" [cellRenderer]="'highlightText'">
		</vb-ui-data-grid-column>

		<vb-ui-data-grid-column [columnPickerGroup]="ZonesColumnPickerGroup.ZONE" [field]="'Status'" [headerName]="'Status' | translate" [sortable]="true" [cellRendererFramework]="RealTimeAnalyticsZoneStatusRendererComponent" [cellRendererParams]="statusCellRendererParams" [tooltipField]="'StatusDescription'">
		</vb-ui-data-grid-column>

		<vb-ui-data-grid-column [columnPickerGroup]="ZonesColumnPickerGroup.METRICS" [field]="'AttendeeCount'" [headerName]="'Viewers' | translate" [sortable]="true" [sortingOrderReversed]="true" [filter]="false" [valueFormatter]="numberValueFormatter">
		</vb-ui-data-grid-column>
		<vb-ui-data-grid-column [columnPickerGroup]="ZonesColumnPickerGroup.METRICS" [field]="'AvgBitrateKbps'" [headerName]="'Reports_Zones_Column_AverageBitrate' | translate" [sortable]="true" [sortingOrderReversed]="true" [filter]="false" [valueFormatter]="numberValueFormatter">
		</vb-ui-data-grid-column>

		<vb-ui-data-grid-column [columnPickerGroup]="ZonesColumnPickerGroup.METRICS" [field]="'AvgExperiencedBufferingDuration'" [headerName]="'Event_RealTimeAnalytics_AvgRebufferDuration' | translate" [sortable]="true" [sortingOrderReversed]="true" [filter]="false" [valueFormatter]="twoDecimalValueFormatter">
		</vb-ui-data-grid-column>

		<vb-ui-data-grid-column [columnPickerGroup]="ZonesColumnPickerGroup.METRICS" [field]="'ExperiencedErrors'" [headerName]="'Event_RealTimeAnalytics_ExperiencedErrors' | translate" [sortable]="true" [sortingOrderReversed]="true" [filter]="false" [valueFormatter]="numberValueFormatter">
		</vb-ui-data-grid-column>

		<vb-ui-data-grid-column [columnPickerGroup]="ZonesColumnPickerGroup.METRICS" [field]="'MulticastFailovers'" [headerName]="'Event_RealTimeAnalytics_MulticastErrors' | translate" [sortable]="true" [sortingOrderReversed]="true" [filter]="false" [valueFormatter]="numberValueFormatter">
		</vb-ui-data-grid-column>

		<!--Hidden Columns-->
		<vb-ui-data-grid-column [columnPickerGroup]="ZonesColumnPickerGroup.STREAM" [field]="'UnicastConnectionsCount'" [headerName]="'Event_RealTimeAnalytics_UnicastConnections' | translate" [sortable]="true" [hide]="true" [filter]="false" [valueFormatter]="numberValueFormatter">
		</vb-ui-data-grid-column>

		<vb-ui-data-grid-column [columnPickerGroup]="ZonesColumnPickerGroup.STREAM" [field]="'MulticastConnectionsCount'" [headerName]="'Event_RealTimeAnalytics_MulticastConnections' | translate" [sortable]="true" [hide]="true" [filter]="false" [valueFormatter]="numberValueFormatter">
		</vb-ui-data-grid-column>

		<vb-ui-data-grid-column [columnPickerGroup]="ZonesColumnPickerGroup.STREAM" [field]="'RevConnectUserCount'" [headerName]="'Reports_Zones_Column_RevConnectViewers' | translate" [sortable]="true" [hide]="true" [filter]="false" [valueFormatter]="numberValueFormatter">
		</vb-ui-data-grid-column>

		<vb-ui-data-grid-column [columnPickerGroup]="ZonesColumnPickerGroup.STREAM" [field]="'MeshCount'" [headerName]="'Reports_Zones_Column_RevConnectMeshes' | translate" [sortable]="true" [hide]="true" [filter]="false" [valueFormatter]="numberValueFormatter">
		</vb-ui-data-grid-column>

	</vb-ui-infinite-scroll-grid>
</real-time-analytics-data-grid-wrapper>
