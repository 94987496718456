import {
	Component,
	Input,
	OnInit
} from '@vbrick/angular-ts-decorators';

import {
	IAugmentedJQuery,
	ITranscludeFunction
} from 'angular';

import { ComponentCallbackEvent } from 'rev-shared/ts-utils/ComponentCallbackEvent';

import { VbMediaTableComponent } from './VbMediaTable.Component';

@Component({
	selector: 'vb-media-table-column-header',
	require: {
		parent: '^^vbMediaTable'
	},
	template: `
		<a
			ng-class="{
				active: $ctrl.parent.sortField === $ctrl.field,
				up: $ctrl.parent.sortAscending,
				disabled: $ctrl.parent.isSortDisabled
			}"
			ng-click="$ctrl.onHeaderClick()"
			aria-label="{{ $ctrl.header }}">
			{{ $ctrl.header }}
			<span class="caret"></span>
		</a>`
})
export class VbMediaTableColumnHeaderComponent implements OnInit {
	@Input() public field: string;
	@Input() public header: string;
	@Input() public isDefaultDescending: boolean;
	@Input() public isMobileHidden: boolean;
	@Input() public mobileAlign: string;

	private parent: VbMediaTableComponent;

	constructor(
		private $element: IAugmentedJQuery,
		private $transclude: ITranscludeFunction
	) {
		'ngInject';
	}

	public ngOnInit(): void {
		this.$element
			.addClass('file-list-header-col')
			.toggleClass('ng-hide', !(this.parent.columns[this.field] >= 0)) //may be undefined
			.addClass('table-cell')
			.toggleClass('mobile-hidden', !!this.isMobileHidden)
			.css({
				order: this.parent.columns[this.field]
			});

		if (this.mobileAlign) {
			this.$element.addClass(`mobile-align-${this.mobileAlign}`);
		}

		if (this.$transclude) {
			this.$transclude(clone => this.$element.append(clone));
		}
	}

	private onHeaderClick(): void {
		this.parent.onColumnHeaderClick(new ComponentCallbackEvent({
			field: this.field,
			isDefaultDescending: this.isDefaultDescending === true
		}));
	}
}
