import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';

import { SpinnerAngularModule } from 'rev-shared/ui/spinner/SpinnerAngular.Module';
import { StatusSpinnersAngularModule } from 'rev-shared/statusSpinners/StatusSpinnersAngular.Module';

import { LiveStreamStatusComponent } from './LiveStreamStatus.Component';
import { WebcastPreProdBannerComponent } from './WebcastPreProdBanner.Component';

const components = [
	LiveStreamStatusComponent,
	WebcastPreProdBannerComponent
];

@NgModule({
	declarations: components,
	entryComponents: components,
	exports: components,
	imports: [
		CommonModule,
		SpinnerAngularModule,
		StatusSpinnersAngularModule,
		TranslateModule
	]
})
export class WebcastSharedComponentsModule {}
