import { Injectable } from '@angular/core';

import { IVbUiDataGridState } from 'rev-shared/ui/dataGrid/VbUiDataGrid.Component';

@Injectable()
export class RealTimeAnalyticsGridStateService {
	private gridStates: { [gridId: string]: IVbUiDataGridState } = {};

	public getState(gridId: string): IVbUiDataGridState {
		return this.gridStates[gridId];
	}

	public setState(gridId: string, state: IVbUiDataGridState): void {
		this.gridStates[gridId] = state;
	}
}
