import { NgModule } from '@vbrick/angular-ts-decorators';
import { IWindowService, IPromise } from 'angular';
import { StateProvider, StateService, Transition } from '@uirouter/angularjs';

import ShareEmbedModule from 'rev-shared/media/shareEmbed/ShareEmbed.Module';
import ShareLinkModule from 'rev-shared/media/shareLink/ShareLink.Module';
import SortableModule from 'rev-shared/ui/sortable/Sortable.Module';
import UiRouterUtilsModule from 'rev-shared/uiRouterUtils/UiRouterUtils.Module';
import VBrickSharedMediaDowngradeModule from 'rev-shared/media/Media.Module.downgrade';
import VBrickUiSpinnerModule from 'rev-shared/ui/spinner/Spinner.Module';
import Video360IndicatorModule from 'rev-shared/media/video360Indicator/Video360Indicator.Module';
import VideoTileModule from 'rev-shared/ui/videoTile/VideoTile.Module';
import { IVBrickStateDeclaration } from 'rev-shared/ts-utils/IVBrickStateDeclaration';
import { MediaFeaturesService } from 'rev-shared/media/MediaFeatures.Service';
import { PlaylistService } from 'rev-shared/media/Playlist.Service';
import { SecurityContextService } from 'rev-shared/security/SecurityContext.Service';
import { StateChangeStatus } from 'rev-shared/util/StateChangeStatus.Service';
import { UserContextService } from 'rev-shared/security/UserContext.Service';
import { VbrickUiInlineEditModule } from 'rev-shared/ui/inlineEdit/InlineEdit.Module';

import { MediaStateService, MediaViewMode, MediaType } from 'rev-portal/media/MediaState.Service';

import { BrowseUserPlaylists } from './BrowseUserPlaylists.Component';
import { PlaylistDetailComponent } from './PlaylistDetail.Component';
import { PlaylistSharing } from './PlaylistSharing.Component';
import { VIDEO_PLAYLIST_PLAYBACK_STATE_NAME } from '../videos/videoPlayback/Constants';

export const PLAYLIST_DETAIL_STATE_NAME: string = 'portal.media.playlist-detail';
export const FEATURED_PLAYLIST_DETAIL_STATE_NAME: string = 'portal.media.playlists-featured-playlist';

@NgModule({
	id: 'VBrick.Portal.Media.Playlists',
	imports: [
		ShareEmbedModule,
		ShareLinkModule,
		SortableModule,
		UiRouterUtilsModule,
		VBrickSharedMediaDowngradeModule,
		VBrickUiSpinnerModule,
		VbrickUiInlineEditModule,
		Video360IndicatorModule,
		VideoTileModule
	],
	declarations: [
		BrowseUserPlaylists,
		PlaylistDetailComponent,
		PlaylistSharing
	]
})
export default class PlaylistsModule {
	public static config($stateProvider: StateProvider): void {
		'ngInject';

		configureStates($stateProvider);
	}
}

function configureStates($stateProvider: StateProvider): void {
	const FEATURED_PLAYLIST_ID: string = 'featured';

	const playlistDetailResolve: any = {
		playlistId($transition$: Transition): string {
			'ngInject';

			return $transition$.params().playlistId;
		},

		playlist(PlaylistService: PlaylistService, playlistId: string) {
			'ngInject';

			return PlaylistService.getPlaylist(playlistId);
		},

		mediaFeatures(MediaFeatures: MediaFeaturesService): any {
			'ngInject';

			return MediaFeatures.getFeatures();
		},

		canEdit(playlist: any): boolean {
			'ngInject';

			return playlist.canEdit;
		},

		hasMediaEditAuth(SecurityContext: SecurityContextService): boolean {
			'ngInject';
			return !!SecurityContext.checkAuthorization('media.edit');
		},

		playlistPlaybackUrl($state: StateService, playlistId: string) {
			'ngInject';

			return $state.href(VIDEO_PLAYLIST_PLAYBACK_STATE_NAME, { playlistId }, { absolute: true, inherit: false });
		}
	};

	const playlistDetailOnEnter = (MediaStateService: MediaStateService, StateChangeStatus: StateChangeStatus, playlist: any): void => {
		'ngInject';

		if (!MediaStateService.isTableViewMode) {
			// reset the sort direction for tiles view (provides no means of toggling)
			MediaStateService.setIsSortDesc(false);
		}

		if (!StateChangeStatus.isStateReloading) {
			MediaStateService.setViewMode(MediaViewMode.TABLE);
		}

		MediaStateService.searchResultsState = {
			playlist,
			mediaCount: playlist.videos.length,
			mediaType: MediaType.VIDEO
		};
	};

	$stateProvider
		.state(FEATURED_PLAYLIST_DETAIL_STATE_NAME, {
			url: '/featured-playlist',
			views: {
				'content@portal.media': {
					component: 'playlistDetail'
				}
			},
			onEnter: playlistDetailOnEnter,
			scrollToTop: true,
			resolve: Object.assign({}, playlistDetailResolve, {
				playlistId() {
					return FEATURED_PLAYLIST_ID;
				},

				playlist(PlaylistService: PlaylistService, UserContext: UserContextService) {
					'ngInject';

					return PlaylistService.getFeaturedVideos(UserContext.getAccount().id)
						.then((playlist: any) => Object.assign(playlist, {
							id: 'featured',
							isFeatured: true
						}));
				},

				canEdit(SecurityContext: SecurityContextService): boolean {
					'ngInject';

					return SecurityContext.checkAuthorization('media.featuredPlaylist');
				}
			})
		} as IVBrickStateDeclaration)

		.state(PLAYLIST_DETAIL_STATE_NAME, {
			url: '/playlists/:playlistId',
			views: {
				'content@portal.media': {
					component: 'playlistDetail'
				}
			},
			onEnter: playlistDetailOnEnter,
			resolve: playlistDetailResolve,
			scrollToTop: true
		} as IVBrickStateDeclaration)

		.state('portal.media.my-playlists', {
			url: '/playlists',
			views: {
				'content@portal.media': {
					component: 'browseUserPlaylists'
				}
			},
			scrollToTop: true,
			onEnter(MediaStateService: MediaStateService, StateChangeStatus: StateChangeStatus, playlists: any[]): void {
				'ngInject';

				// filtering and sorting was never implemented here, so disable for the time being
				MediaStateService.setIsFilteringDisabled(true);
				MediaStateService.setIsSortingDisabled(true);
				MediaStateService.setIsBulkEditDisabled(true);

				if (!StateChangeStatus.isStateReloading) {
					MediaStateService.searchResultsState = {
						mediaCount: playlists.length,
						mediaType: MediaType.PLAYLIST
					};

					MediaStateService.setViewMode(MediaViewMode.TILES);

				}
			},
			onExit(MediaStateService: MediaStateService): void {
				'ngInject';

				MediaStateService.setIsFilteringDisabled(false);
				MediaStateService.setIsSortingDisabled(false);
				MediaStateService.setIsBulkEditDisabled(false);
			},
			resolve: {
				userId(UserContext: UserContextService): string {
					'ngInject';

					return UserContext.getUser().id;
				},

				userPlaylists(PlaylistService: PlaylistService, userId: string) {
					'ngInject';

					return PlaylistService
						.getUserPlaylists(userId)
						.then((playlists: any) => {
							playlists.forEach((p: any) => Object.assign(p, {
								featured: false,
								thumbnailUris: getFirstFourThumbnailUris(p.videoThumbnails.map((t: any) => t.thumbnailUri)),
								date: p.whenVideoAddedOrDeleted.when || p.createdBy.when
							}));

							return playlists;
						});
				},

				featuredPlaylist(PlaylistService: PlaylistService, accountId: string) {
					'ngInject';

					return PlaylistService
						.getFeaturedVideos(accountId)
						.then((featuredPlaylist: any) => {
							if (!featuredPlaylist.videos.length) {
								return null;
							}

							return {
								featured: true,
								name: null,
								date: featuredPlaylist.whenVideoAddedOrDeleted.when || featuredPlaylist.createdBy.when,
								thumbnailUris: getFirstFourThumbnailUris(featuredPlaylist.videos.map((video: any) => video.thumbnailUri)),
								videoThumbnails: featuredPlaylist.videos.map((video: any) => {
									return {
										id: video.id,
										thumbnailUri: video.thumbnailUri
									};
								})
							};
						});
				},

				playlists(featuredPlaylist, userPlaylists): any[] {
					'ngInject';

					if (featuredPlaylist) {
						userPlaylists.push(featuredPlaylist);
					}

					return userPlaylists;
				}
			}
		} as IVBrickStateDeclaration);

	[
		PLAYLIST_DETAIL_STATE_NAME,
		FEATURED_PLAYLIST_DETAIL_STATE_NAME
	].forEach((playlistDetailStateName: string) => {
		$stateProvider.state(`${playlistDetailStateName}.sharing-sidebar`, {
			url: '/sharing',
			views: {
				'right-sidebar-content': {
					component: 'playlistSharing'
				}
			},
			resolve: {
				embedsEnabled(MediaFeatures: MediaFeaturesService): IPromise<boolean> {
					'ngInject';

					return MediaFeatures.getFeatures()
						.then((features: any) => {
							return features.enableEmbeds;
						});
				},

				playlistEmbedUrl($window: IWindowService, playlistId: string): string {
					'ngInject';

					return `${$window.location.origin}/embed?playlist=${playlistId}`;
				},

				playlistName(playlist: any): string {
					'ngInject';

					return playlist.name;
				}
			}
		});
	});
}

function getFirstFourThumbnailUris(thumbnailUris: string[]): string[] {
	return thumbnailUris.filter(Boolean).slice(0, 4);
}
