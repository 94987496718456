import {
	AfterViewInit,
	Component,
	Input
} from '@vbrick/angular-ts-decorators';

import {
	IFormController,
	INgModelController,
	ITranscludeFunction
} from 'angular';

import styles from './vb-ui-form-group.module.less';
import { IAugmentedJQuery } from 'angular';
/**
 * This component will be used to layout the label/control/validation message in a form
 * This needs to be wrapped in a form.
 * <div ng-form name="form">
 * 		<vb-ui-form-group
 * 			control-name="'txtInput'"> <-- This is name of the control -->
 * 			<label>
 *				Text Input Label:
 *			</label>
 *			<ui-control>
 *				<input type="text"
 *					name="txtInput" <!-- name of the control -->
 *					ng-model="$ctrl.txtInput"
 *					required
 *					placeholder="Text Input Placeholder"
 *					vb-text-input />
 *			</ui-control>
 * 			<ui-validation> <!-- This is optional -->
 *				<div ng-messages="form.txtInput.$error"
 *					role="alert"
 *					ng-messages-multiple
 *					vb-ui-error-message>
 *					<div ng-message="required">
 *						Text Input Required
 *					</div>
 *				</div>
 *			</ui-validation>
 * 		</vb-ui-form-group>
 */
@Component({
	selector: 'vb-ui-form-group',
	transclude: {
		label: '?label',
		control: 'uiControl',
		validation: '?uiValidation'
	},
	require: {
		parentForm: '^form'
	},
	template: `
		<div
			ng-transclude
			ng-transclude-slot="label"
			ng-if="$ctrl.labelSlotPresent"
			class="${styles.label}"
			ng-class="[ $ctrl.labelClass, { '${styles.controlRequired}': $ctrl.isRequired }]">
		</div>

		<div
			ng-class="{ 'padding-top-10' : !$ctrl.labelSlotPresent,
				'${styles.controlRequired}': !$ctrl.labelSlotPresent &amp;&amp; $ctrl.isRequired
			}"
			ng-transclude
			ng-transclude-slot="control">
		</div>

		<div
			class="ng-hide"
			ng-show="$ctrl.isTouched"
			ng-transclude
			ng-transclude-slot="validation"
			class="${styles.validation}">
		</div>
	`
})
export class VbUiFormGroupComponent implements AfterViewInit {
	@Input() private controlName: string;
	@Input() private labelClass: string;

	private parentForm: IFormController;

	constructor(
		private $element: IAugmentedJQuery,
		private $transclude: ITranscludeFunction
	) {
		'ngInject';
	}

	public ngAfterViewInit(): void {
		if (!this.controlName) {
			throw new Error('vb-ui-form-group: missing control name parameter.');
		}

		this.$element.addClass(styles.root);
	}

	private get labelSlotPresent(): boolean {
		return this.$transclude.isSlotFilled('label');
	}

	private get controlNgModel(): INgModelController & { $$element: IAugmentedJQuery } {
		return this.parentForm[this.controlName];
	}

	private get isTouched(): boolean {
		return this.controlNgModel && this.controlNgModel.$touched;
	}

	private get isRequired(): boolean {
		const control = this.controlNgModel;
		if(!control) {
			return;
		}
		return control.$invalid &&
			!!control.$$element.attr('required');
	}
}
