<!--VC status spinners-->
<section *ngIf="webcast.vcSipAddress || webcast.vcMicrosoftTeamsMeetingUrl">
	<initializing-spinner *ngIf="webcast.vcStatus.isInitializing">
	</initializing-spinner>

	<connecting-spinner *ngIf="webcast.vcStatus.isConnecting && webcast.vcSipAddress" [destination]="webcast.vcSipAddress">
	</connecting-spinner>

	<connecting-spinner *ngIf="webcast.vcStatus.isConnecting && webcast.vcMicrosoftTeamsMeetingUrl" [destination]="'Microsoft_Teams' | translate">
		<div [ngClass]="styles.msTeamsConnecting">
			{{ 'Microsoft_Teams_ConnectingToMessage' | translate }}
		</div>
	</connecting-spinner>

	<vb-loading-spinner *ngIf="webcast.vcStatus.isGenerating || webcast.vcStatus.waitingForStream || webcast.vcStatus.isRecording" [block]="true" [iconClass]="'glyphicons wifi_alt'" [msg]="'Media_Recording_Generating' | translate" [msgDisplayBelowSpinner]="true" [size]="'large'">
	</vb-loading-spinner>
</section>

<!--Webex Live Stream-->
<section *ngIf="webcast.isWebexLiveStreamType || webcast.isRtmpStreamType || webcast.isEnrichedPresentationProfile">
	<initializing-spinner *ngIf="webcast.vcStatus.isInitializing; else notInitializing">
	</initializing-spinner>
	<ng-template #notInitializing>
		<vb-loading-spinner *ngIf="webcast.vcStatus.waitingForStream" [block]="true" [iconClass]="'vb-icon vb-icon-not-streaming'" [msg]="'Event_Webcast_WaitingForStream' | translate" [msgDisplayBelowSpinner]="true" [size]="'large'">
		</vb-loading-spinner>
	</ng-template>
</section>
