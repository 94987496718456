import { NgModule } from '@vbrick/angular-ts-decorators';

import NotificationsModule from 'rev-portal/notifications/Notifications.Module';
import { downgradeComponents } from 'rev-shared/util/AngularHybridUtils';
import { NotificationsMenuButtonComponent } from './NotificationsMenuButton.Component';

@NgModule({
	id: 'Vbrick.Portal.Navigation.NotificationsMenu',
	imports: [
		NotificationsModule
	]
})
export default class NotificationsMenuModule {

}
downgradeComponents(NotificationsMenuModule, [
	{
		name: 'notificationsMenuButton',
		component: NotificationsMenuButtonComponent
	}
]);
