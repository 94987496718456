import { NgModule } from '@vbrick/angular-ts-decorators';
import { ResizeWindowDirective } from './ResizeWindow.Directive';
import { OffsetHeightModelDirective } from './OffsetHeightModel.Directive';
import { MediaQueryService } from './MediaQuery.Service';

import { downgradeInjectables } from 'rev-shared/util/AngularHybridUtils';

@NgModule({
	id: 'VBrick.UI.Resize',
	declarations: [
		OffsetHeightModelDirective,
		ResizeWindowDirective
	]
})
export class WindowSizeModule {}

downgradeInjectables(WindowSizeModule, [
	{
		name: 'MediaQueryService',
		injectable: MediaQueryService
	}
]);
