import { NgModule } from '@vbrick/angular-ts-decorators';

import { downgradeComponents } from 'rev-shared/util/AngularHybridUtils';

import { VideoChapterRenderer } from './VideoChapterRenderer.Component';

@NgModule({
	id: 'VBrick.Shared.Media.Video.Chapter'
})
export default class VideoChapterModuleDowngrade {}

downgradeComponents(VideoChapterModuleDowngrade, [
	{
		component: VideoChapterRenderer,
		name: 'videoChapterRenderer'
	}
]);
