import {
	Component,
	Input,
	OnInit
} from '@angular/core';

import { UNCATEGORIZED } from 'rev-shared/media/MediaConstants';

import { CategoryService } from 'rev-shared/media/Category.Service';
import { ICancellableQueue, createPromiseQueue } from 'rev-shared/util/PromiseUtil';

import styles from './list-widget.module.less';

interface ICategoryWidget {
	id: string;
	isUncategorized: boolean;
	name: string;
}

@Component({
	selector: 'category-widget',
	templateUrl: './CategoryWidget.Component.html'
})
export class CategoryWidgetComponent implements OnInit {
	@Input() private accountId: string;

	private readonly maxCategoryCount: number = 14;
	public readonly styles = styles;

	public categories: ICategoryWidget[] = [];
	public isFetching: boolean = true;
	public uncategorizedCategory: ICategoryWidget;

	constructor(
		private CategoryService: CategoryService
	) { }

	public ngOnInit(): void {
		this.fetchData();
	}

	public get hasCategories(): boolean {
		return this.categories.length > 0 || !!this.uncategorizedCategory;
	}

	private fetchData(): void {
		Promise.all([
			this.loadRootCategories(),
			this.loadUncategorizedCategory()
		])
			.finally(() => {
				this.isFetching = false;
			});
	}

	private loadRootCategories(): Promise<any> {
		return this.CategoryService
			.getRootCategories()
			.then(result => this.addCategoriesToListItems(result.categories));
	}

	private loadUncategorizedCategory(): Promise<any> {
		return this.CategoryService
			.getUncategorizedActiveSearchContent(this.accountId)
			.then(result => {
				if (result.videoCount) {
					this.uncategorizedCategory = this.formatCategory({ id: undefined });
				}
			});
	}

	private addCategoriesToListItems(categories): void {
		const promiseQueue: ICancellableQueue<{videoCount}> = createPromiseQueue();
		categories.forEach(category => {
			promiseQueue
				.enqueue(() => Promise.resolve(this.CategoryService.getCategoryActiveSearchContent(this.accountId, category.id, true, 1)))
				.then(result => {
					if (this.categories.length === this.maxCategoryCount) {
						promiseQueue.cancel();
						return;
					}

					if (result.videoCount > 0) {
						this.categories.push(this.formatCategory(category));
					}
				});
		});
	}

	private formatCategory(category): ICategoryWidget {
		return {
			id: category.id || UNCATEGORIZED,
			isUncategorized: !category.id,
			name: category.name
		};
	}
}
