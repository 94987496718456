<dashboard-carousel [items]="videos" [headerText]="headerLabel" [headerSref]="headerSref" [headerSrefParams]="headerSrefParams">

		<div class="carousel-cell" [ngClass]="styles.carouselCellItem" *ngFor="let video of videos">

			<section [ngClass]="styles.carouselCell">

				<div [ngClass]="styles.imageWrapper">
					<div class="fixed-ratio-wrapper" [ngClass]="styles.fixedRatioWrapper">
						<div class="fixed-ratio">
							<a uiSref="portal.video" [uiParams]="{videoId: video.id}" class="fixed-aspect-item carousel-thumbnail-wrapper" flex-align="center" flex-justify="center" layout="row">
								<img [alt]="'ThumbnailForAria' | translate : {'0': video.title }" class="preview-image anchored-thumbnail" [attr.data-flickity-lazyload]="video.thumbnailUri || (video.hasAudioOnly ? '/shared/img/audio-thumbnail.png' : '/shared/img/default-thumbnail.png')">
							</a>
							<div class="video-overlay">
								<span class="video-duration-overlay" *ngIf="video.duration">
									{{ ((video.duration | vbTimespan) || 'NotAvailable' | translate) }}
								</span>
								<video-360-indicator *ngIf="video.is360">
								</video-360-indicator>
							</div>
						</div>
					</div>

					<div [ngClass]="styles.infoContainer">
						<h4>
							<a [title]="video.title" uiSref="portal.video" [uiParams]="{videoId: video.id}">
								{{video.title}}
							</a>
						</h4>
						<h5 [title]="video.whenUploaded | vbDateTimeMedium">
							{{video.whenUploaded | vbDateTimeMedium}}
						</h5>
					</div>

				</div>

			</section>

			<div [ngClass]="styles.progressBarWrap" *ngIf="video.session && video.duration">
				<div aria-valuemax="100" aria-valuemin="0" [attr.aria-valuenow]="(100 * video.session.time / video.duration) | number: 0 " [attr.aria-valuetext]="'Dashboard_ContinueWatching_Aria' | translate " [attr.aria-label]="'Dashboard_ContinueWatching_Aria' | translate " class="theme-accent-bg" role="progressbar" [ngClass]="styles.progressBar" [ngStyle]="{width: (100 * video.session.time / video.duration) + '%'}">
				</div>
			</div>

		</div>

</dashboard-carousel>
