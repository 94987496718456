import { Component } from '@vbrick/angular-ts-decorators';

import { MediaMenuDataManagerDirective } from './MediaMenuDataManager.Directive';

@Component({
	selector: 'my-videos-menu-items',
	templateUrl: '/partials/portal/navigation/my-videos-menu-items.html',
	require: {
		mediaMenuDataManager: '^mediaMenuDataManager'
	}
})
export class MyVideosMenuItemsComponent {
	private mediaMenuDataManager: MediaMenuDataManagerDirective;
}
