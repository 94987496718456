import { IDialog } from 'rev-shared/ui/dialog/IDialog';

enum TeamRoleType {
	TeamAdmin = 'TeamAdmin',
	TeamContributor = 'TeamContributor',
	TeamMember = 'TeamMember'
}

enum EntityType {
	User = 'User',
	Group = 'Group',
	Team = 'Team',
	Role = 'Role',
	Collection = 'Collection'
}

class Team {
	constructor(accountId: string, teamCreator: string = null) {

		this.bgColor = '#00a0f0';
		this.themeColor = '#000';
		this.accountId = accountId;
		this.accessEntities = [];

		if (teamCreator) {
			const defaultTeamAdminMember = new TeamMember();
			defaultTeamAdminMember.id = teamCreator;
			defaultTeamAdminMember.type = EntityType.User;
			defaultTeamAdminMember.isAdmin = true;
			defaultTeamAdminMember.roleTypes = [TeamRoleType.TeamAdmin];
			defaultTeamAdminMember.rolesState = Team.createRolesState(defaultTeamAdminMember.roleTypes);
			this.teamMembers = [defaultTeamAdminMember];
			this.accessEntities = [{
				id: defaultTeamAdminMember.id,
				type: defaultTeamAdminMember.type,
				rolesState: defaultTeamAdminMember.rolesState
			}];
		} else {
			this.teamMembers = [];
		}
	}

	public accessEntities?: any;
	public accountId: string;
	public bgColor?: string;
	public deleteConfirmation?: IDialog;
	public description?: string;
	public id?: string;
	public imageId?: string;
	public imageUrl?: string;
	public isNew?: boolean;
	public name: string;
	public linkId?: string;
	public teamMembers?: TeamMember[];
	public themeColor?: string;
	public thumbnailUri?: string;
	public totalVideos?: number;

	public static createRolesState(memberRoleTypes: string[]): { [key: string]: boolean }
	{
		return (memberRoleTypes || []).reduce((roles, role) => {
			roles[role] = true;
			return roles;
		}, {});
	}
}

class TeamMember {
	public id: string;
	public type: EntityType;
	public isAdmin: boolean;
	public roleTypes: string[];
	public rolesState: { [key: string]: boolean };
}

interface ITeamFilter {
	name: string;
}

class StatusHandler{
	private statusState: { [status: string]: boolean };

	public get status() {
		return this.statusState;
	}

	public setLoadingStatus(): void {
		this.statusState = { loading: true };
	}

	public setActiveStatus(): void {
		this.statusState = { active: true };
	}

	public setErrorStatus(): void {
		this.statusState = { error: true };
	}

}

export {
	ITeamFilter,
	StatusHandler,
	Team,
	TeamRoleType
};
