import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { ButtonsAngularModule } from 'rev-shared/ui/buttons/ButtonsAngular.Module';
import { DateAngularModule } from 'rev-shared/date/DateAngular.Module';
import { DialogAngularModule } from 'rev-shared/ui/dialog/DialogAngular.Module';

import { WebcastBroadcastButtonComponent } from './broadcastButton/WebcastBroadcastButton.Component';
import { WebcastRecordingButton } from './recordingButton/WebcastRecordingButton.Component';
import { WebcastRegionVisibilityToggleComponent } from './regionVisibilityToggle/WebcastRegionVisibilityToggle.Component';
import { WebcastNavComponent } from './WebcastNav.Component';

const components = [
	WebcastRecordingButton,
	WebcastBroadcastButtonComponent,
	WebcastRegionVisibilityToggleComponent,
	WebcastNavComponent
];

@NgModule({
	declarations: components,
	exports: [WebcastNavComponent],
	imports: [
		ButtonsAngularModule,
		CommonModule,
		DateAngularModule,
		FormsModule,
		DialogAngularModule,
		TooltipModule,
		TranslateModule
	]
})
export class WebcastNavModule {}
