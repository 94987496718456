import {
	Directive,
	Input,
	OnChanges,
	OnInit
} from '@vbrick/angular-ts-decorators';

import {
	IAugmentedJQuery,
	IOnChangesObject
} from 'angular';

import './vb-toggle-button.less';

@Directive({
	selector: '[vb-toggle-button]',
	transclude: true,
	template: `<div ng-transclude></div>`, // has a template, but @Component does not cooperate (attr selector creates Directive behind the scenes)
	bindToController: true
})
export class VbToggleButtonComponent implements OnInit, OnChanges {
	@Input() public ngModel: any;
	@Input() public uibBtnRadio: any;

	constructor(
		private $element: IAugmentedJQuery
	) {
		'ngInject';
	}

	public ngOnInit(): void {
		this.$element.addClass('btn btn-white');

		this.updateButtonClass();
	}

	public ngOnChanges(changes: IOnChangesObject): void {
		if (changes.ngModel) {
			this.updateButtonClass();
		}
	}

	private updateButtonClass(): void {
		this.$element.toggleClass('theme-accent', this.ngModel === this.uibBtnRadio);
	}
}
