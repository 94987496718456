import { NgModule } from '@vbrick/angular-ts-decorators';
import { StateProvider, UrlRouterProvider } from '@uirouter/angularjs';
import 'ng-infinite-scroll'; //used by browse views

import SecurityModule from 'rev-shared/security/Security.Module';
import SharedTeamModule from 'rev-shared/team/SharedTeam.Module';
import UiRouterUtilsModule from 'rev-shared/uiRouterUtils/UiRouterUtils.Module';
import VBrickSharedMediaDowngradeModule from 'rev-shared/media/Media.Module.downgrade';
import VbrickUiSpinnerModule from 'rev-shared/ui/spinner/Spinner.Module';
import { SignInBtnDowngradeModule } from 'rev-shared/ui/signInBtn/SignInBtnDowngrade.Module';
import { WindowSizeModule } from 'rev-shared/ui/size/WindowSize.Module';
import { downgradeComponents, downgradeInjectables } from 'rev-shared/util/AngularHybridUtils';

import SearchModule from 'rev-portal/media/search/Search.Module';

import PlaylistsModule from './playlists/Playlists.Module';
import SparkModule from './spark/Spark.Module';
import TeamsModule from './teams/Teams.Module';
import VbrickImportModule from './import/Import.Module';
import { MediaContainerComponent } from './MediaContainer.Component';
import { MediaStateService } from './MediaState.Service';
import { RecordingModule } from './recording/Recording.Module';
import { StateConfig } from './StateConfig';
import { VbMediaTableColumnComponent } from './VbMediaTableColumn.Component';
import { VbMediaTableColumnHeaderComponent } from './VbMediaTableColumnHeader.Component';
import { VbMediaTableComponent } from './VbMediaTable.Component';
import { VbMediaTableSelectColumnHeaderComponent } from './VbMediaTableSelectColumnHeader.Component';
import { VbMediaToolbarComponent } from './search/toolbar/VbMediaToolbar.Component';

import './media-layout.less';

@NgModule({
	id: 'VBrick.Portal.Media',
	declarations: [
		MediaContainerComponent,
		VbMediaTableComponent,
		VbMediaTableColumnComponent,
		VbMediaTableColumnHeaderComponent,
		VbMediaTableSelectColumnHeaderComponent
	],
	imports: [
		'infinite-scroll', //ng-infinite-scroll
		PlaylistsModule,
		RecordingModule,
		SearchModule,
		SecurityModule,
		SharedTeamModule,
		SignInBtnDowngradeModule,
		SparkModule,
		TeamsModule,
		UiRouterUtilsModule,
		VBrickSharedMediaDowngradeModule,
		VbrickImportModule,
		VbrickUiSpinnerModule,
		WindowSizeModule
	]
})
export default class VBrickMediaModule {
	public static config(
		$stateProvider: StateProvider,
		$urlRouterProvider: UrlRouterProvider
	): void {
		'ngInject';

		StateConfig($stateProvider, $urlRouterProvider);
	}
}

downgradeComponents(VBrickMediaModule, [{
	component: VbMediaToolbarComponent,
	name: 'vbMediaToolbar'
}]);

downgradeInjectables(VBrickMediaModule, [{
	injectable: MediaStateService,
	name: 'MediaStateService'
}]);
