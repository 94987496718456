import { Component, Input, Output, EventEmitter } from '@angular/core';

import type { GridReadyEvent } from '@ag-grid-community/core';

import { IIconCellRendererParams } from 'rev-shared/ui/dataGrid/cellRenderers/IconCellRenderer.Component';
import { ILinkCellRendererParams } from 'rev-shared/ui/dataGrid/cellRenderers/LinkCellRenderer.Component';
import { IVbUiDataGridValueFormatterParams } from 'rev-shared/ui/dataGrid/valueFormatters/IVbUiDataGridValueFormatterParams';
import { yesNoValueFormatter } from 'rev-shared/ui/dataGrid/valueFormatters/YesNoValueFormatter';

import { MEDIA_SETTINGS_WEBEX_SITE_STATE } from '../MediaSettingsStates.Config';

import styles from './MediaFeatures.module.less';
import { IWebexSiteSettings } from './IWebexSiteSettings';
const NON_CI_STATUS = '-';

@Component({
	selector: 'webex-sites-list-grid',
	templateUrl: './WebexSitesListGrid.Component.html'
})
export class WebexSitesListGridComponent {
	@Input() public data: IWebexSiteSettings[];
	@Input() public quickFilter: string;

	@Output() public gridReady = new EventEmitter<GridReadyEvent>();

	public readonly ciLinkStatusValueFormatter = ciLinkStatusValueFormatter;
	public readonly styles = styles;
	public readonly yesNoValueFormatter = yesNoValueFormatter;

	private readonly menuItems = [
		{
			action: 'edit',
			labelKey: 'Edit'
		},
		{
			action: 'delete',
			labelKey: 'Delete'
		},
		{
			action: 'duplicate',
			labelKey: 'Duplicate'
		},
		{
			action: 'autoIngestLog',
			labelKey: 'Media_Integrations_WebexMeetings_ViewAutoImportLog'
		}
	];

	public ciLinkedCellRendererParams: IIconCellRendererParams = {
		getIconClass(value: any, formattedValue: string): string {
			if (formattedValue === NON_CI_STATUS) {
				return;
			}
			const circleIcon: string = value ?
				'ok' :
				'remove';
			const color: string = value ?
				'success' :
				'warning';

			return `glyphicons circle_${circleIcon} color-${color}`;
		},
		showFormattedValue: true
	};

	public menuBtnCellRendererParams = {
		getMenuItems: rowData => {
			if (!rowData?.enableAutoIngest) {
				return this.menuItems.filter(item => item.action !== 'autoIngestLog');
			}
			return this.menuItems;
		},
		menuWrapperCssClass: styles.actionMenuWrapper
	};

	public nameLinkCellRendererParams: ILinkCellRendererParams<IWebexSiteSettings> = {
		getCellCfg: site => ({
			uiSref: MEDIA_SETTINGS_WEBEX_SITE_STATE,
			uiSrefParams: {
				webexSiteId: site.id
			}
		})
	};
}

function ciLinkStatusValueFormatter(params: IVbUiDataGridValueFormatterParams): string {
	const { data } = params;

	if(!data?.isCiEnabled) {
		return NON_CI_STATUS;
	}

	const translationKey: string = params.value ?
		'Features_WebEx_Linked' :
		'Features_WebEx_Unlinked';

	return params.context.getTranslation(translationKey);
}
