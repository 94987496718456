import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UIRouterModule } from '@uirouter/angular';

import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { ButtonsAngularModule } from 'rev-shared/ui/buttons/ButtonsAngular.Module';
import { CheckboxAngularModule } from 'rev-shared/ui/checkbox/CheckboxAngular.Module';
import { CssRulesAngularModule } from 'rev-shared/ui/css/Css.AngularModule';
import { DateAngularModule } from 'rev-shared/date/DateAngular.Module';
import { DialogAngularModule } from 'rev-shared/ui/dialog/DialogAngular.Module';
import { DialogRegistrationService } from 'rev-shared/ui/dialog/DialogRegistration.Service';
import { DirectivesAngularModule } from 'rev-shared/util/directives/DirectivesAngular.Module';
import { FormGroupAngularModule } from 'rev-shared/ui/formGroup/FormGroupAngular.Module';
import { PipesAngularModule } from 'rev-shared/pipes/Pipes.AngularModule';
import { SpinnerAngularModule } from 'rev-shared/ui/spinner/SpinnerAngular.Module';
import { TabPanelModule } from 'rev-shared/ui/tabPanel/TabPanel.Module';
import { TextInputAngularModule } from 'rev-shared/ui/text-input/TextInputAngular.Module';
import { ToolbarNgxModule } from 'rev-shared/ui/toolbar/ToolbarNgx.Module';
import { VbProfilePictureModule } from 'rev-shared/ui/profile/VbProfilePicture.Module';
import { VbTextAreaModule } from 'rev-shared/ui/textArea/VbTextArea.Module';
import { VbUiSortableModule } from 'rev-shared/ui/sortable/VbUiSortable.Module';
import { ng2StatesToHybridStates } from 'rev-shared/uiRouterUtils/HybridRouterUtils';

import { BrandingAngularModule } from 'rev-portal/branding/BrandingAngular.Module';

import { VbWebcastAskQuestionComponent } from './VbWebcastAskQuestion.Component';
import { VbWebcastAttendeeQuestionSidebarComponent } from './VbWebcastAttendeeQuestionSidebar.Component';
import { VbWebcastManageQuestionFullscreenComponent } from './VbWebcastManageQuestionFullscreen.Component';
import { VbWebcastManageQuestionSidebarComponent } from './VbWebcastManageQuestionSidebar.Component';
import { VbWebcastQuestionCardComponent } from './VbWebcastQuestionCard.Component';
import { VbWebcastQuestionDirectReplyComponent } from './VbWebcastQuestionDirectReply.Component';
import { VbWebcastQuestionsListComponent } from './VbWebcastQuestionsList.Component';
import { VbWebcastQuestionsSidebarComponent } from './VbWebcastQuestionsSidebar.Component';
import { states } from './StateDeclarations';

const components = [
	VbWebcastAskQuestionComponent,
	VbWebcastAttendeeQuestionSidebarComponent,
	VbWebcastManageQuestionFullscreenComponent,
	VbWebcastManageQuestionSidebarComponent,
	VbWebcastQuestionCardComponent,
	VbWebcastQuestionDirectReplyComponent,
	VbWebcastQuestionsListComponent,
	VbWebcastQuestionsSidebarComponent
];

@NgModule({
	declarations: components,
	exports: [
		VbWebcastQuestionsSidebarComponent
	],
	imports: [
		BrandingAngularModule,
		BsDropdownModule,
		ButtonsAngularModule,
		CheckboxAngularModule,
		CommonModule,
		CssRulesAngularModule,
		DateAngularModule,
		DialogAngularModule,
		DirectivesAngularModule,
		FormGroupAngularModule,
		FormsModule,
		PipesAngularModule,
		SpinnerAngularModule,
		TabPanelModule,
		TextInputAngularModule,
		ToolbarNgxModule,
		TooltipModule,
		TranslateModule,
		UIRouterModule.forChild({
			states: ng2StatesToHybridStates(states)
		}),
		VbProfilePictureModule,
		VbTextAreaModule,
		VbUiSortableModule
	]
})
export class VbWebcastQuestionsModule {
	constructor(dialog: DialogRegistrationService) {
		dialog.register('vbWebcastQuestionDirectReply', {
			component: VbWebcastQuestionDirectReplyComponent
		});
	}
}
