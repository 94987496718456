import { NgModule } from '@vbrick/angular-ts-decorators';
import { StateProvider } from '@uirouter/angularjs';
import 'ng-infinite-scroll';

import FormDirectivesModule from 'rev-shared/util/directives/form/FormDirectives.Module';
import PushDowngradeModule from 'rev-shared/push/Push.downgrade.Module';
import TagsInputModule from 'rev-shared/ui/ngTagsInput/NgTagsInput.Module';
import UnlistedVideoIndicatorModule from 'rev-shared/media/unlistedVideoIndicator/UnlistedVideoIndicator.Module';
import VBrickSharedDateModule from 'rev-shared/date/Date.Module';
import VBrickSharedMediaDowngradeModule from 'rev-shared/media/Media.Module.downgrade';
import VBrickSharedSecurityModule from 'rev-shared/security/Security.Module';
import Video360IndicatorModule from 'rev-shared/media/video360Indicator/Video360Indicator.Module';
import VideoTileModule from 'rev-shared/ui/videoTile/VideoTile.Module';
import { BulkEditModule } from 'rev-portal/media/bulkEdit/BulkEdit.Module';
import { CollapsableSectionModule } from 'rev-shared/ui/collapsableSection/CollapsableSection.Module';
import { IVBrickStateDeclaration } from 'rev-shared/ts-utils/IVBrickStateDeclaration';
import { TranslationsModule } from 'rev-shared/ui/translations/Translations.Module';
import { UserContextService } from 'rev-shared/security/UserContext.Service';
import { VideoService } from 'rev-shared/media/Video.Service';

import { VideoThumbnailStatusModule } from 'rev-portal/media/videos/videoThumbnailStatus/VideoThumbnailStatus.Module';
import { MEDIA_STATE_ALL, MEDIA_STATE_BROWSE } from 'rev-portal/media/StateConfig';

import { SearchFiltersSidebarComponent } from './SearchFiltersSidebar.Component';
import { SpeechSearchResultComponent } from './SpeechSearchResult.Component';
import { SpeechSearchResultsListComponent } from './SpeechSearchResultsList.Component';
import { VbSearchFilterListComponent } from './VbSearchFilterList.Component';

import { SearchFilterDefinitionsService } from './SearchFilterDefinitions.Service';
import { SearchFilterStateService } from './SearchFilterState.Service';
import { VideoSearchResultsComponent } from './VideoSearchResults.Component';
import { VideoSearchResultsTableComponent } from './VideoSearchResultsTable.Component';
import { VideoSearchResultsTilesComponent } from './VideoSearchResultsTiles.Component';
import { CategoryTileComponent } from './CategoryTile.Component';
import { downgradeComponents } from 'rev-shared/util/AngularHybridUtils';

@NgModule({
	id: 'VBrick.Portal.Media.Search',
	declarations: [
		SearchFiltersSidebarComponent,
		SpeechSearchResultComponent,
		SpeechSearchResultsListComponent,
		VbSearchFilterListComponent,
		VideoSearchResultsComponent,
		VideoSearchResultsTableComponent,
		VideoSearchResultsTilesComponent
	],
	imports: [
		'infinite-scroll', //ng-infinite-scroll
		BulkEditModule,
		CollapsableSectionModule,
		FormDirectivesModule,
		PushDowngradeModule,
		TagsInputModule,
		TranslationsModule,
		UnlistedVideoIndicatorModule,
		VBrickSharedDateModule,
		VBrickSharedMediaDowngradeModule,
		VBrickSharedSecurityModule,
		Video360IndicatorModule,
		VideoTileModule,
		VideoThumbnailStatusModule
	],
	providers: [
		SearchFilterDefinitionsService,
		SearchFilterStateService
	]
})
export default class SearchModule {
	public static config($stateProvider: StateProvider): void {
		'ngInject';

		[
			MEDIA_STATE_ALL,
			MEDIA_STATE_BROWSE,
			'portal.media.uploads',
			'portal.media.search',
			'portal.media.pending-videos',
			'portal.media.edit',
			'portal.media.category-detail',
			'portal.media.expirations'
		]
			.forEach(state =>
				$stateProvider.state(state + '.filters-sidebar', {
					url: '/filters',
					allowGuestAccess: true,
					views: {
						['right-sidebar-content@' + state]: {
							component: 'searchFiltersSidebar'
						}
					},
					resolve: {
						isGuestUser(UserContext: UserContextService): boolean {
							'ngInject';

							return UserContext.isGuest();
						},

						teams(isGuestUser: boolean, VideoService: VideoService) {
							'ngInject';

							if (isGuestUser) {
								return [];
							}

							return VideoService.getViewableTeams();
						}
					}
				} as IVBrickStateDeclaration));
	}
}

downgradeComponents(SearchModule, [{
	name: 'categoryTile',
	component: CategoryTileComponent
}]);
