import { AfterViewInit, Component, Output, Input, EventEmitter, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { Poll } from 'rev-portal/scheduledEvents/polls/Poll';
import { POLL_ANSWERS_MAX } from 'rev-portal/scheduledEvents/polls/Polls';

import styles from './VbWebcastPollsView.Component.module.less';

@Component({
	selector: 'vb-webcast-edit-poll-view',
	templateUrl: './VbWebcastEditPollView.Component.html',
	host: {
		'[class]': 'styles.editPollViewRoot'
	}
})
export class VbWebcastEditPollViewComponent implements AfterViewInit {
	@Input() public poll: Poll;

	@Output() public cancelled: EventEmitter<void> = new EventEmitter<void>();
	@Output() public saved: EventEmitter<{ poll: Poll }> = new EventEmitter<{ poll: Poll }>();

	public readonly styles = styles;
	public formValid$: Observable<boolean>;
	public readonly maxAnswerCount = POLL_ANSWERS_MAX;
	public minOneAnsProvided: boolean;
	@ViewChild('form') public form: NgForm;

	public addAnswer(): void {
		if (this.poll.answers.length < this.maxAnswerCount) {
			this.poll.answers.push({ text: '' });
		}
	}

	public ngAfterViewInit(): void {
		this.formValid$ = this.form.valueChanges
			.pipe(
				tap(poll => {
					for (let i = 1; i <= this.poll.answers.length; i++) {
						this.minOneAnsProvided = poll[`answer${i}`];
						if (this.minOneAnsProvided) {
							break;
						}
					}
				}),
				map(poll => poll.epPollQuestion && this.minOneAnsProvided)
			);
	}
}
