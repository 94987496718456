import { NgModule } from '@angular/core';
import { UIRouterModule } from '@uirouter/angular';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';

import { BrandingAngularModule } from 'rev-portal/branding/BrandingAngular.Module';
import { ButtonsAngularModule } from 'rev-shared/ui/buttons/ButtonsAngular.Module';
import { CheckboxAngularModule } from 'rev-shared/ui/checkbox/CheckboxAngular.Module';
import { DateAngularModule } from 'rev-shared/date/DateAngular.Module';
import { DialogAngularModule } from 'rev-shared/ui/dialog/DialogAngular.Module';
import { DirectivesAngularModule } from 'rev-shared/util/directives/DirectivesAngular.Module';
import { DialogRegistrationService } from 'rev-shared/ui/dialog/DialogRegistration.Service';
import { FormGroupAngularModule } from 'rev-shared/ui/formGroup/FormGroupAngular.Module';
import { PipesAngularModule } from 'rev-shared/pipes/Pipes.AngularModule';
import { SpinnerAngularModule } from 'rev-shared/ui/spinner/SpinnerAngular.Module';
import { ValidationAngularModule } from 'rev-shared/ui/validation/ValidationAngular.Module';
import { ValidationModule } from 'rev-shared/util/directives/form/validation/Validation.AngularModule';
import { VbUiToggleContentDisplayAngularModule } from 'rev-shared/ui/toggleContentDisplay/VbUiTogleContentDisplay.AngularModule';

import { EventRegistrationComponent } from './EventRegistration.Component';
import { EventRegistrationPreviewComponent } from './EventRegistrationPreview.Component';
import { ScheduledEventsCommonModule } from '../common/ScheduledEventsCommon.AngularModule';
import { EventRegistrationPreviewDialog } from './Dialog.Config';
import { states } from './State.Config';

const exports = [
	EventRegistrationComponent,
	EventRegistrationPreviewComponent
];

@NgModule({
	declarations: exports,
	exports,
	entryComponents: [
		EventRegistrationPreviewComponent
	],
	imports: [
		ButtonsAngularModule,
		BrandingAngularModule,
		CheckboxAngularModule,
		CommonModule,
		DateAngularModule,
		DialogAngularModule,
		DirectivesAngularModule,
		FormGroupAngularModule,
		FormsModule,
		NgxTrimDirectiveModule,
		PipesAngularModule,
		ScheduledEventsCommonModule,
		SpinnerAngularModule,
		TranslateModule,
		ValidationAngularModule,
		ValidationModule,
		VbUiToggleContentDisplayAngularModule,
		UIRouterModule.forChild({
			states
		})
	]
})
export class EventRegistrationModule {
	constructor(dlg: DialogRegistrationService) {
		dlg.register(EventRegistrationPreviewDialog, {
			component: EventRegistrationPreviewComponent
		});
	}
}
