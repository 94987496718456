<div flex-align="center" layout="row" layout-wrap="false">

	<div flex="fill" [ngClass]="styles.header">
		{{ header }}
	</div>

	<vb-ui-search-input #searchInputNgModel="ngModel" flex="1" [(ngModel)]="searchInputValue" [ngClass]="styles.search" [placeholder]="'Event_RealTimeAnalytics_Grid_Search' | translate">
	</vb-ui-search-input>

	<button type="button" vbUiBtnSecondary [ngClass]="[
			styles.pauseResumeBtn,
			grid.isPollingActive ? styles.pauseBtn : styles.resumeBtn
		]" (click)="grid.isPollingActive ? grid.pausePolling() : grid.resumePolling()">

		<span class="glyphicons" [ngClass]="[
				styles.pauseResumeIcon,
				grid.isPollingActive ? 'pause' : 'play'
			]">
		</span>

		{{ (grid.isPollingActive ? 'Event_RealTimeAnalytics_Grid_PauseUpdates' : 'Event_RealTimeAnalytics_Grid_ResumeUpdates') | translate }}
	</button>
</div>

<div class="text-align-right" [ngClass]="styles.dataAsOf" [style.visibility]="grid.dataAsOf ? 'visible' : 'hidden'">
	{{ 'Event_RealTimeAnalytics_Grid_DataAsOf' | translate : { '0': grid.dataAsOf | date : 'mediumTime' } }}
</div>

<ng-content></ng-content>