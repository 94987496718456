import { Directive, OnInit } from '@vbrick/angular-ts-decorators';
import {
	IAugmentedJQuery
} from 'angular';

import styles from './VbGridList.module.less';

@Directive({
	selector: '[vb-grid-toolbar-column]'
})

export class VbGridToolbarColumnDirective implements OnInit {

	constructor(
		private $element: IAugmentedJQuery
	) {
		'ngInject';
	}

	public ngOnInit(): void {
		this.$element.addClass(styles.toolbarColumn);
		this.$element.find('button, a').addClass('btn btn-sm btn-admin btn-white');
	}

}
