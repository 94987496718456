export enum QuestionStatus {
	Closed = 'Closed',
	Queued = 'Queued',
	Inbox = 'Inbox'
}
export enum QuestionAction {
	All = 'All',
	Answered = 'Answered',
	Closed = 'Closed',
	Declined = 'Declined',
	FollowUp = 'FollowUp',
	RepliedDirectly = 'RepliedDirectly',
	ReplyingDirectly = 'ReplyingDirectly'
}

export class ReplyBy {
	public firstName: string;
	public lastName: string;
	public fullName: string;

	constructor(data) {
		Object.assign(this, data);
		this.fullName = `${this.firstName ?? ''} ${this.lastName ?? ''}`;
	}
}

export class Question {
	public id: string;
	public questionNumber: number;
	public questionText: string;
	public replyText: string;
	public status: QuestionStatus;
	public anonymous: boolean;
	public isModerator: boolean;
	public askedBy: any;
	public askedByFullName: string;
	public whenAsked: string|Date;
	public whenModified: string|Date;
	public lastAction: QuestionAction;
	public isHidden: boolean;
	public isNew: boolean;
	public isUnread: boolean;
	public repliedBy: any;
	public isPublic: boolean;

	public currentReplyingUser: any;
	public replyingUserFullName: string;
	private _directReplyInProgressBy: any;
	private _isDirectReplyInProgress: boolean;

	constructor(questionData) {
		Object.assign(this, questionData, {
			isHidden: false, //used to hide newly created questions until the mod opts to show them
			_isDirectReplyInProgress: false,
			_directReplyInProgressBy: null
		});
		this.askedByFullName = `${this.askedBy?.firstName ?? ''} ${this.askedBy?.lastName ?? ''}`;
		this.replyingUserFullName = `${this.directReplyInProgressBy?.firstName ?? ''} ${this.directReplyInProgressBy?.lastName ?? ''}`;
	}

	//statuses
	public get isClosed(): boolean {
		return this.status === QuestionStatus.Closed;
	}

	public get isQueued(): boolean {
		return this.status === QuestionStatus.Queued;
	}

	public get isInbox(): boolean {
		return this.status === QuestionStatus.Inbox;
	}

	//last actions
	public get isAnswered(): boolean {
		return this.lastAction === QuestionAction.Answered;
	}

	public get isDirectReplied(): boolean {
		return this.lastAction === QuestionAction.RepliedDirectly;
	}

	public get isDeclined(): boolean {
		return this.lastAction === QuestionAction.Declined;
	}

	public get isFollowUp(): boolean {
		return this.lastAction === QuestionAction.FollowUp;
	}

	//attendee helpers
	public get isOpen(): boolean {
		return !this.isClosed || !(this.isAnswered || this.isDirectReplied);
	}

	public get isAnsweredOrReplied(): boolean {
		return this.isClosed && (this.isAnswered || this.isDirectReplied);
	}

	public get isDirectReplyInProgress(): boolean {
		return this._isDirectReplyInProgress || this.lastAction === QuestionAction.ReplyingDirectly;
	}

	public set isDirectReplyInProgress(isDirectReplyInProgress) {
		this._isDirectReplyInProgress = isDirectReplyInProgress;
	}

	public get directReplyInProgressBy(): ReplyBy {
		return this._directReplyInProgressBy || (this.isDirectReplyInProgress ? this.currentReplyingUser : null);
	}

	public set directReplyInProgressBy(value: ReplyBy) {
		this._directReplyInProgressBy = value;
	}
}
