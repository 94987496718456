import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DateParsersService } from 'rev-shared/date/DateParsers.Service';
import { buildUrl, parseUrl } from 'rev-shared/util/UrlUtil';

const rtmp = 'rtmp';
const rtmps = 'rtmps';
const rtmpPort = '1937';
const rtmpsPort = '1936';

@Injectable({
	providedIn: 'root'
})
export class RtmpVideoSourceDetailsService {
	constructor(
		private DateParsers: DateParsersService,
		private http: HttpClient
	) {}

	public getRtmpUrlDetails(isSecure: boolean): Observable<{ url: string; streamKey: string }> {
		return this.http.post<{ url: string; streamKey: string }>(`/scheduled-events/rtmp-url`, {
			isSecure: (isSecure || 'false').toString()
		});
	}

	public getRtmpVideoSourceSchedules(startDateInIso: string, endDateInIso: string, url: string): Observable<any> {
		return this.http.get<any>(`/scheduled-events/rtmp/schedule`, {
			params: {
				startDate: startDateInIso,
				endDate: endDateInIso,
				url
			}
		}).pipe(
			map(events => (events || []).map(event => {
				return {
					...event,
					startDate: this.DateParsers.parseUTCDate(event.startDate),
					endDate: this.DateParsers.parseUTCDate(event.endDate)
				};
			}))
		);
	}
}

export function updateRtmpUrl(url: string, isSecure: boolean): string {
	const { host, path } = parseUrl(url);
	return buildUrl(
		isSecure ? rtmps : rtmp,
		host,
		isSecure ? rtmpsPort : rtmpPort,
		path
	);
}
