import {
	Component,
	Input,
	OnDestroy,
	OnInit
} from '@angular/core';
import { distinctUntilChanged, switchMap, map } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

// import { IDialog } from 'rev-shared/ui/dialog/IDialog';
import { DialogService } from 'rev-shared/ui/dialog/Dialog.Service';
import { PushBus } from 'rev-shared/push/PushBus.Service';
import { IUnsubscribe } from 'rev-shared/push/IUnsubscribe';
import { isMobile } from 'rev-shared/util/UserAgentUtil';

import { PresentationFileStatus } from 'rev-portal/scheduledEvents/presentations/PresentationFileStatus';

import { RecordingStatus } from '../../model/RecordingStatus';
import styles from './WebcastRecordingButton.module.less';
import { WebcastModel } from '../../model/WebcastModel';

enum StatusType {
	MANUAL = 'manual'
}

@Component({
	selector: 'webcast-recording-button',
	templateUrl: './WebcastRecordingButton.Component.html',
	host: {
		class: 'box-block position-relative'
	}
})
export class WebcastRecordingButton implements OnInit, OnDestroy {
	@Input() public webcast: WebcastModel;

	public recording: RecordingStatus;
	public webcastStatus: any;
	public isMobile: boolean;

	public readonly styles = styles;

	private checkCanRecord: boolean;
	private status: { [key: string]: boolean } = {};
	private unsubscribe: IUnsubscribe;

	constructor(
		private Dialog: DialogService,
		private PushBus: PushBus,
		private TranslateService: TranslateService,
	){}

	public ngOnInit(): void {
		this.isMobile = isMobile();
		this.recording = this.webcast.recording;
		this.recording.initRecordingDeviceStatus();
		this.recording.setAutoStopRecordingHandler(stopRecording => this.handleAutoStopRecording(stopRecording));
		this.webcastStatus = this.webcast.webcastStatus;
		this.unsubscribe = this.subscribePushHandlers();
		this.recording.isSetToRecord = this.webcast.isRecordingMandatory() || (this.webcast.isStartOrStopRecordingAllowed() && !this.webcast.disableAutoRecording);
	}

	public ngOnDestroy(): void {
		this.unsubscribe?.();
	}

	public startRecording(): void {
		this.status = { [StatusType.MANUAL]: true };

		this.recording.startRecording()
			.catch(err =>
				this.handleStartRecordingFailed(err))
			.finally(() => this.status = { [StatusType.MANUAL]: false });
	}

	public stopRecording(): void {
		this.status = { [StatusType.MANUAL]: true };

		this.recording.stopRecording()
			.catch(err => this.onStopRecordingError())
			.finally(() => this.status = { [StatusType.MANUAL]: false });
	}

	private onStopRecordingError(): void {
		const key = this.webcast.isAutomated ? 'Event_AutomatedWebcastUnableToStopRecording' : 'Event_UnableToStopRecording';
		this.openDialog(key, 'Warning');
	}

	private handleAutoStopRecording(stopRecording: Promise<void>): Promise<void> {
		return Promise.resolve(stopRecording)
			.then(() => (this.recording.isSetToRecord = true) as any)
			.catch(() => this.onStopRecordingError());

	}

	public toggleSetToRecord(): void {
		this.recording.isSetToRecord = !this.recording.isSetToRecord;
	}

	public isPresentationProcessing(): boolean {
		return this.webcast.presentation.status === PresentationFileStatus.Uploading
			|| this.webcast.presentation.status === PresentationFileStatus.ProcessingImages;
	}

	public canStartRecording(): boolean {
		return this.webcast.broadcast.isActive && !this.webcast.broadcast.isStopping && !this.isPresentationProcessing();
	}

	public showSetRecording(): boolean {
		return !this.webcast.isAutomated && !this.canStartRecording() && this.webcast.isRecordingAllowed();
	}

	public showStartRecording(): boolean {
		return this.canStartRecording() && this.webcast.isRecordingAllowed();
	}

	private handleStartRecordingFailed(issue: string): void {
		switch (issue) {
			case 'RecordingDeviceInactive':
				this.openDialog('Event_RecordingDeviceInactive', 'Error');
				break;
			case 'RecordingDeviceOffline':
				this.openDialog('Event_RecordingDeviceOffline', 'Warning');
				break;
			case 'RecordingDeviceNotSpecified':
				this.openDialog('Event_RecordingDeviceNotSpecified', 'Error');
				break;
			case 'RecordingDeviceMaxRecordingsMet':
				this.openDialog('Event_RecordingDeviceSlotUnavailable', 'Error');
				break;
			case 'RecordingDeviceMaxStorageMet':
				this.openDialog('Event_RecordingDeviceMemoryUnavailable', 'Error');
				break;
			case 'RecordingDeviceUpgrading':
				this.openDialog('Event_RecordingDeviceUpgrading', 'Error');
				break;
			default:
				this.openDialog('Event_UnableToStartRecording', 'Error');
		}
	}

	public subscribePushHandlers(): IUnsubscribe {
		if (this.webcast.currentUser.isEventAdmin) {
			const unsubWebcastId = this.PushBus.subscribe(this.webcast.id, {

				StopRecordingFailed: () => {
					if (!this.status.manual) {
						this.onStopRecordingError();
					}
				},

				FailedRecordingStopped: () => {
					this.openDialog('Event_RecordingFailedError', 'Error');
				},

				StartRecordingFailed: (e: any) => {
					if(e.reason !== 'NoRtspStreams' || !this.status.manual) {
						this.openDialog('Event_UnableToStartRecording', 'Error');
					}
				}
			});

			const unsubs = [
				this.subscribeRecordingDevicePush(),
				unsubWebcastId,
				this.PushBus.subscribe(this.webcast.id, 'Webcast.EventAdmin', {
					RecordingDeviceStopped: () => {
						this.webcast.recording.isActive = false;
						this.openDialog('Event_RecordingDeviceStopped', 'Error');
					}
				})
			];

			return this.PushBus.composeUnsubscribeFn(unsubs);
		}
	}

	private subscribeRecordingDevicePush(): () => void {
		const subscription = this.webcast.webcast$.pipe(
			map(w => w.recordingDeviceId),
			distinctUntilChanged(),
			switchMap(id => !id ? EMPTY :
				this.PushBus.getObservable(id, 'Webcast.EventAdmin', {
					DeviceOffline: () => {
						this.openDialog('Event_RecordingDeviceOffline', 'Warning');
						return EMPTY;
					}
				})
			)
		)
			.subscribe();

		return () => subscription.unsubscribe();
	}

	private openDialog(messageKey: string, titleKey: string, actionKey: string = 'Ok'): void {
		const params = {
			message: this.TranslateService.instant(messageKey),
			title: this.TranslateService.instant(titleKey),
			actionText: this.TranslateService.instant(actionKey)
		};
		this.Dialog.openConfirmationDialog(params);
	}
}
