import {
	Component,
	Input,
	OnInit
} from '@vbrick/angular-ts-decorators';

import { formatUrlTimespan } from 'rev-shared/date/DateFormatters';

import './SpeechSearchResult.less';

@Component({
	selector: 'speech-search-result',
	templateUrl: '/partials/media/search/speech-search-results-list-item.html'
})
export class SpeechSearchResultComponent implements OnInit {
	@Input() public isOnVideoPage: boolean;
	@Input() public query: string;
	@Input() public result: any;
	@Input() public videoId: string;

	private startAtParam: string;

	public ngOnInit(): void {
		this.startAtParam = formatUrlTimespan(this.result.start * 1000);
	}
}
