import {
	MonoTypeOperatorFunction,
	Observable,
	TeardownLogic
} from 'rxjs';

//Adds teardown logic to an observable
export function addTeardown<T>(teardown: TeardownLogic): MonoTypeOperatorFunction<T> {
	return source$ => new Observable(observer =>
		source$
			.subscribe(observer)
			.add(teardown));
}
