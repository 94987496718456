import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AmChartsCoreModule } from 'rev-shared/ui/dataVisualization/amChartsCore/AmChartsCore.Module';

import { VbUiPieChartComponent } from './VbUiPieChart.Component';

const components = [
	VbUiPieChartComponent
];

@NgModule({
	declarations: components,
	entryComponents: components,
	exports: components,
	imports: [
		AmChartsCoreModule,
		CommonModule
	]
})
export class PieChartModule {}
