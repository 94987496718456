<vb-ui-infinite-scroll-grid [ngClass]="styles.grid" [getRows]="getRows" [clearScrollId]="AnalyticsService.clearEventScrollId" [pageSize]="pageSize" [quickFilterText]="filterText" (gridReady)="gridReady.emit($event)" vb-ui-stretch>
	<vb-ui-column-picker-group [headerName]="'Reports_Attendees_Column_Group_User' | translate" [id]="COLUMN_GROUP.USER" [isExpanded]="true">
	</vb-ui-column-picker-group>
	<vb-ui-column-picker-group [headerName]="'Reports_Column_Group_System' | translate" [id]="COLUMN_GROUP.SYSTEM">
	</vb-ui-column-picker-group>
	<vb-ui-column-picker-group [headerName]="'Reports_Column_Group_Session' | translate" [id]="COLUMN_GROUP.SESSION">
	</vb-ui-column-picker-group>
	<vb-ui-column-picker-group [headerName]="'Reports_Column_Group_Metrics' | translate" [id]="COLUMN_GROUP.METRICS">
	</vb-ui-column-picker-group>

	<vb-ui-data-grid-column [columnPickerGroup]="COLUMN_GROUP.USER" [field]="'UserType'" [headerName]="'Reports_Attendees_Column_UserType' | translate" [hide]="true" [sortable]="true" [cellRenderer]="'highlightText'">
	</vb-ui-data-grid-column>
	<vb-ui-data-grid-column [columnPickerGroup]="COLUMN_GROUP.USER" [field]="'Username'" [headerName]="'Reports_Attendees_Column_UserName' | translate" [sort]="'asc'" [sortable]="true" [cellRenderer]="'highlightText'" [tooltipField]="'Username'">
	</vb-ui-data-grid-column>
	<vb-ui-data-grid-column [columnPickerGroup]="COLUMN_GROUP.USER" [field]="'FullName'" [headerName]="'Reports_Attendees_Column_FullName' | translate" [hide]="true" [sortable]="true" [cellRenderer]="'highlightText'">
	</vb-ui-data-grid-column>
	<vb-ui-data-grid-column [columnPickerGroup]="COLUMN_GROUP.USER" [field]="'Email'" [headerName]="'Reports_Attendees_Column_Email' | translate" [hide]="true" [sortable]="true" [cellRenderer]="'highlightText'">
	</vb-ui-data-grid-column>
	<vb-ui-data-grid-column [columnPickerGroup]="COLUMN_GROUP.USER" [field]="'AttendeeType'" [headerName]="'Reports_Attendees_Column_AttendeeType' | translate" [hide]="true" [sortable]="true" [cellRenderer]="'highlightText'">
	</vb-ui-data-grid-column>
	<vb-ui-data-grid-column [columnPickerGroup]="COLUMN_GROUP.SYSTEM" [field]="'ZoneName'" [headerName]="'Reports_Column_Zone' | translate" [sortable]="true" [cellRenderer]="'highlightText'">
	</vb-ui-data-grid-column>
	<vb-ui-data-grid-column [columnPickerGroup]="COLUMN_GROUP.SYSTEM" [field]="'Browser'" [headerName]="'Reports_Attendees_Column_Browser' | translate" [sortable]="true" [cellRenderer]="'highlightText'">
	</vb-ui-data-grid-column>
	<vb-ui-data-grid-column [columnPickerGroup]="COLUMN_GROUP.SYSTEM" [field]="'PlayerDevice'" [headerName]="'Reports_Attendees_Column_DeviceType' | translate" [hide]="true" [sortable]="true" [cellRenderer]="'highlightText'">
	</vb-ui-data-grid-column>
	<vb-ui-data-grid-column [columnPickerGroup]="COLUMN_GROUP.SYSTEM" [field]="'OsFamily'" [headerName]="'Reports_Attendees_Column_Platform' | translate" [sortable]="true" [cellRenderer]="'highlightText'">
	</vb-ui-data-grid-column>
	<vb-ui-data-grid-column [columnPickerGroup]="COLUMN_GROUP.SYSTEM" [field]="'OsName'" [headerName]="'Reports_Attendees_Column_PlatformVersion' | translate" [hide]="true" [sortable]="true" [cellRenderer]="'highlightText'">
	</vb-ui-data-grid-column>
	<vb-ui-data-grid-column [columnPickerGroup]="COLUMN_GROUP.SYSTEM" [field]="'IpAddress'" [headerName]="'Reports_Attendees_Column_IpAddress' | translate" [hide]="true" [sortable]="true" [cellRenderer]="'highlightText'" [tooltipField]="'IpAddress'">
	</vb-ui-data-grid-column>
	<vb-ui-data-grid-column [columnPickerGroup]="COLUMN_GROUP.SESSION" [field]="'StartTime'" [headerName]="'Reports_Attendees_Column_SessionStartTime' | translate" [hide]="true" [sortable]="true" [filter]="false" [valueFormatter]="mediumDateTimeValueFormatter">
	</vb-ui-data-grid-column>
	<vb-ui-data-grid-column [columnPickerGroup]="COLUMN_GROUP.SESSION" [field]="'ViewingStartTime'" [headerName]="'Reports_Attendees_Column_ViewingStartTime' | translate" [hide]="true" [sortable]="true" [filter]="false" [valueFormatter]="mediumDateTimeValueFormatter">
	</vb-ui-data-grid-column>
	<vb-ui-data-grid-column [columnPickerGroup]="COLUMN_GROUP.SESSION" [field]="'EndTime'" [headerName]="'Reports_Attendees_Column_ExitTime' | translate" [hide]="true" [sortable]="true" [filter]="false" [valueFormatter]="mediumDateTimeValueFormatter">
	</vb-ui-data-grid-column>
	<vb-ui-data-grid-column [columnPickerGroup]="COLUMN_GROUP.SESSION" [field]="'ViewingTime'" [headerName]="'Reports_Attendees_Column_ViewingTime' | translate" [hide]="true" [filter]="false" [sortable]="true">
	</vb-ui-data-grid-column>
	<vb-ui-data-grid-column [columnPickerGroup]="COLUMN_GROUP.SESSION" [field]="'SessionTime'" [headerName]="'Reports_Attendees_Column_SessionTime' | translate" [hide]="true" [filter]="false" [sortable]="true">
	</vb-ui-data-grid-column>
	<vb-ui-data-grid-column [columnPickerGroup]="COLUMN_GROUP.SESSION" [field]="'Removed'" [headerName]="'Reports_Attendees_Column_Removed' | translate" [hide]="true" [filter]="false" [sortable]="true">
	</vb-ui-data-grid-column>
	<vb-ui-data-grid-column [columnPickerGroup]="COLUMN_GROUP.SESSION" [field]="'StreamDevice'" [headerName]="'Reports_Attendees_Column_LastDevice' | translate" [hide]="true" [sortable]="true" [cellRenderer]="'highlightText'" [tooltipField]="'StreamDevice'">
	</vb-ui-data-grid-column>
	<vb-ui-data-grid-column [columnPickerGroup]="COLUMN_GROUP.SESSION" [field]="'StreamAccessed'" [headerName]="'Reports_Attendees_Column_LastPlaybackUrl' | translate" [hide]="true" [sortable]="true" [cellRenderer]="'highlightText'" [tooltipField]="'StreamAccessed'">
	</vb-ui-data-grid-column>
	<vb-ui-data-grid-column [columnPickerGroup]="COLUMN_GROUP.METRICS" [field]="'ExperiencedBufferingCount'" [headerName]="'Reports_Attendees_Column_ExperiencedRebufferingCount' | translate" [hide]="true" [sortable]="true" [sortingOrderReversed]="true" [filter]="false" [valueFormatter]="numberValueFormatter">
	</vb-ui-data-grid-column>
	<vb-ui-data-grid-column [columnPickerGroup]="COLUMN_GROUP.METRICS" [field]="'ExperiencedBufferingDuration'" [headerName]="'Reports_Attendees_Column_ExperiencedRebufferingDuration' | translate" [hide]="true" [sortable]="true" [sortingOrderReversed]="true" [filter]="false" [valueFormatter]="twoDecimalValueFormatter">
	</vb-ui-data-grid-column>
	<vb-ui-data-grid-column [columnPickerGroup]="COLUMN_GROUP.METRICS" [field]="'ExperiencedErrors'" [headerName]="'Event_RealTimeAnalytics_ExperiencedErrors' | translate" [sortable]="true" [sortingOrderReversed]="true" [filter]="false" [valueFormatter]="numberValueFormatter">
	</vb-ui-data-grid-column>
	<vb-ui-data-grid-column [columnPickerGroup]="COLUMN_GROUP.METRICS" [field]="'MulticastErrors'" [headerName]="'Event_RealTimeAnalytics_MulticastErrors' | translate" [sortable]="true" [sortingOrderReversed]="true" [filter]="false" [valueFormatter]="numberValueFormatter">
	</vb-ui-data-grid-column>
	<vb-ui-data-grid-column *ngIf="revConnectUseStatus" [columnPickerGroup]="COLUMN_GROUP.METRICS" [field]="'UserPeerMeshEfficiency'" [headerName]="'Reports_Attendees_Column_UserPeerMeshEfficiency' | translate" [hide]="true" [headerTooltip]="('Reports_Video_Users_RevConnectPeerMeshEfficiency_HelpText' | translate)" [sortable]="true" [sortingOrderReversed]="true" [filter]="false" [valueFormatter]="twoDecimalValueFormatter">
	</vb-ui-data-grid-column>
</vb-ui-infinite-scroll-grid>
