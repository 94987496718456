import {
	Component,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	SimpleChanges
} from '@vbrick/angular-ts-decorators';

import { ISessionKeepalive, SessionService } from 'rev-shared/security/Session.Service';
import { VideoStatus } from 'rev-shared/media/VideoStatus';
import { VideoSource } from 'rev-shared/media/VideoSource';
import { VideoSourceType } from 'rev-shared/media/VideoSourceType';

import { VideoPlaybackService } from 'rev-portal/media/videos/videoPlayback/VideoPlayback.Service';

import { RecordingService } from './Recording.Service';

import styles from './RecordingProcess.Component.module.less';

const CONNECTING_STATUSES: VideoStatus[] = [
	VideoStatus.RECORDING_CONNECTING,
	VideoStatus.STREAMING_WAITING
];

const INITIALIZING_STATUSES: VideoStatus[] = [
	VideoStatus.NOT_UPLOADED
];

const RECORDING_FAILURE_STATUSES: VideoStatus[] = [
	VideoStatus.RECORDING_CONNECTION_FAILED,
	VideoStatus.RECORDING_FAILED,
	VideoStatus.STREAMING_FAILED
];

const RECORDING_STATUSES: VideoStatus[] = [
	VideoStatus.RECORDING,
	VideoStatus.STREAMING_RECORDING
];

export const SUPPORTED_VIDEO_STATUSES: VideoStatus[] = [
	...CONNECTING_STATUSES,
	...INITIALIZING_STATUSES,
	...RECORDING_FAILURE_STATUSES,
	...RECORDING_STATUSES
];

@Component({
	selector: 'recording-process',
	templateUrl: '/partials/media/recording/recording-process.html'
})
export class RecordingProcessComponent implements OnInit, OnChanges, OnDestroy {
	@Input() private video: any;

	public readonly VideoStatus = VideoStatus;
	public readonly styles = styles;
	public readonly VideoSourceType = VideoSourceType;

	private incomingMediaUnavailable: boolean;
	private invalidSipAddress: boolean;
	private recordingHoursNotAvailable: boolean;
	private sessionKeepAlive: ISessionKeepalive;

	// statuses
	private isConnecting: boolean;
	private isConnectionFailed: boolean;
	private isInitializing: boolean;
	private isRecording: boolean;

	constructor(
		private RecordingService: RecordingService,
		private Session: SessionService,
		private VideoPlaybackSvc: VideoPlaybackService
	) {
		'ngInject';
	}

	public ngOnInit(): void {
		this.sessionKeepAlive = this.Session.createKeepalive().beginWhenConnected();
	}

	public ngOnDestroy(): void {
		this.sessionKeepAlive?.end();
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.video && this.video) {
			if (this.video.status) {
				this.onVideoStatusChange();
			}

			if (this.video.videoConference?.reason) {
				this.onRecordingFailureReasonChange();
			}
		}
	}

	private onVideoStatusChange(): void {
		const { status } = this.video;

		this.isConnecting = CONNECTING_STATUSES.includes(status);
		this.isConnectionFailed = RECORDING_FAILURE_STATUSES.includes(status);
		this.isInitializing = INITIALIZING_STATUSES.includes(status);
		this.isRecording = RECORDING_STATUSES.includes(status);

		if (status === VideoStatus.RECORDING_FINISHED) {
			this.sessionKeepAlive?.end();
		}
	}

	private onRecordingFailureReasonChange(): void {
		this.invalidSipAddress = this.video.videoConference.reason === 'INVALID_SIP_ADDRESS';
		this.incomingMediaUnavailable = this.video.videoConference.reason === 'INCOMING_MEDIA_UNAVAILABLE';
	}

	private get isInteractiveRecordingDisplay(): boolean {
		return !this.isReadOnlyRecordingDisplay;
	}

	private get isReadOnlyRecordingDisplay(): boolean {
		return this.video?.source === VideoSource.SCHEDULED_EVENT;
	}

	private stopRecording(): void {
		this.RecordingService
			.stopVideoConferenceRecording(this.video);

		this.VideoPlaybackSvc.updateVideoStatus(VideoStatus.RECORDING_STOP_RECORDING);
	}

	private retryRecording(): void {
		this.RecordingService
			.reconnectVideoConferenceRecording(this.video.id)
			.then(() => {
				this.video.status = VideoStatus.RECORDING_CONNECTING;
			})
			.catch(error => {
				if (error.hasIssue('RecordingHoursNotAvailable')) {
					this.recordingHoursNotAvailable = true;
				}

				this.video.status = VideoStatus.RECORDING_CONNECTION_FAILED;
			})
			.finally(() => this.onVideoStatusChange());
	}
}
