import { NgModule } from '@vbrick/angular-ts-decorators';

import { VideoThumbnailStatusComponent } from './VideoThumbnailStatus.Component';

import { downgradeComponents } from 'rev-shared/util/AngularHybridUtils';

@NgModule({
	id: 'VBrick.Portal.Media.Video.VideoThumbnailStatus',
})
export class VideoThumbnailStatusModule {}

downgradeComponents(VideoThumbnailStatusModule, [{
	name: 'videoThumbnailStatus',
	component: VideoThumbnailStatusComponent
}]);
