<form #form="ngForm" [ngClass]="styles.editPollFormWrapper" autocomplete="off" (submit)="saved.emit({ poll: poll })">

	<ng-container>
		<div [ngClass]="styles.editPollHeader" class="theme-accent theme-accent-font-border">
			<h3 [hidden]="poll.isNew">{{ 'Event_EditPoll' | translate }}</h3>
			<h3 [hidden]="!poll.isNew">{{ 'Event_AddPoll' | translate }}</h3>
		</div>

		<vb-ui-form-group flex="fill" class="margin-top-15">
			<label for="epPollQuestion" #uiLabel class="type-bold">
				{{ 'Event_PollQuestionLabel' | translate }}
			</label>
			<input type="text" id="epPollQuestion" [placeholder]="'Event_PollQuestionPlaceHolder' | translate" [attr.aria-label]="'Event_PollQuestionPlaceHolder' | translate" name="epPollQuestion" #pQuestion="ngModel" [(ngModel)]="poll.question" autocomplete="off" required vbUiTextInput uiControl>
			<div *ngIf="pQuestion.invalid" role="alert" vbUiErrorMessage uiValidation>
				<div *ngIf="pQuestion.errors.required">
					{{ 'ThisFieldIsRequired' | translate }}
				</div>
			</div>
		</vb-ui-form-group>

		<div layout class="margin-top-20">
			<div class="type-bold padding-top-10">{{ 'Event_PollAnswersLabel' | translate }}</div>
			<div class="margin-left-auto">
				<span [ngClass]="styles.multiChoice">
					{{ 'Event_MultipleChoice' | translate }}
				</span>
				<div vbUiRadioBtnGroup [attr.aria-label]="'Event_MultipleChoice' | translate" role="radiogroup">
					<button type="button" name="tMultipleChoice" [(ngModel)]="poll.multipleChoice" [vbUiRadioBtn]="true" role="radio">
						{{ 'Yes' | translate }}
					</button>
					<button type="button" name="tMultipleChoice" [(ngModel)]="poll.multipleChoice" [vbUiRadioBtn]="false" role="radio">
						{{ 'No' | translate }}
					</button>
				</div>
			</div>
		</div>
		<vb-ui-form-group *ngFor="let answer of poll.answers; let $index = index" flex="fill" class="margin-top-15">
			<input type="text" [id]="'answer' + ($index + 1)" [placeholder]="'Event_PollAnswerPlaceHolder' | translate : { '0': $index + 1 }" [attr.aria-label]="'Event_PollAnswerPlaceHolder' | translate : { '0': $index + 1 }" [name]="'answer' + ($index + 1)" [(ngModel)]="answer.text" autocomplete="off" vbUiTextInput uiControl>
		</vb-ui-form-group>

		<div *ngIf="form.touched && !minOneAnsProvided" class="margin-bottom-20" role="alert" vbUiErrorMessage uiValidation>
			{{ 'Event_PollAnswersRequiredError' | translate }}
		</div>
		<div class="text-center">
			<button [disabled]="poll.answers.length >= maxAnswerCount" type="button" class="margin-bottom-10 margin-top-5" vbUiBtnPrimaryNgx (click)="addAnswer()">
				<span class="glyphicons plus"></span>
				{{ 'Event_AddPollResponse' | translate }}
			</button>
			<p>{{ 'MaximumOf' | translate: { '0': maxAnswerCount } }}</p>
		</div>

		<div layout [ngClass]="styles.pollSubFooter" class="theme-primary-font-border-fade-50">
			<div class="margin-top-10 margin-left-auto">
				<button type="button" vbUiBtnSecondaryNgx (click)="cancelled.emit()" class="margin-right-5">
					{{ 'Cancel' | translate }}
				</button>
				<button type="submit" vbUiBtnPrimaryNgx [disabled]="!(formValid$ | async)">
					{{ (poll.isNew ? 'Create' : 'Save') | translate }}
				</button>
			</div>
		</div>
	</ng-container>
</form>
