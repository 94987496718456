import { Component, Input, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { CdkVirtualScrollViewport, VIRTUAL_SCROLL_STRATEGY } from '@angular/cdk/scrolling';
import { Observable, EMPTY, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { WebcastModel } from 'rev-portal/scheduledEvents/webcast/model/WebcastModel';
import { WebcastService } from 'rev-portal/scheduledEvents/webcast/Webcast.Service';

import { DateParsersService } from 'rev-shared/date/DateParsers.Service';
import { PushBus } from 'rev-shared/push/PushBus.Service';
import { SimpleToolbar } from 'rev-shared/htmlEditor/Constants';
import { UserContextService } from 'rev-shared/security/UserContext.Service';

import { ChatDataSource } from './ChatDataSource';
import { IComment } from './Contract';
import { TailScrollStrategy } from './TailScrollStrategy';

import styles from './VbWebcastChatSidebar.Component.module.less';
@Component({
	selector: 'vb-webcast-chat-sidebar',
	templateUrl: './VbWebcastChatSidebar.Component.html',
	host: {
		'[class]': 'hostClass'
	},
	providers: [{ provide: VIRTUAL_SCROLL_STRATEGY, useFactory: () => new TailScrollStrategy() }]
})
export class VbWebcastChatSidebarComponent implements OnInit, OnDestroy {
	@Input() public webcast: WebcastModel;
	@Input() public isEventAdmin: boolean;
	@Input() public legacyStyle: boolean;

	@ViewChild(CdkVirtualScrollViewport) public scrollbox: CdkVirtualScrollViewport;

	public get hostClass(): string {
		return this.legacyStyle ? `${styles.root} ${styles.legacyStyle}` : styles.root;
	}

	private user = this.UserContext.getUser();
	private pendingCommands = {};
	public status: { [key: string]: boolean };
	private unsubscribePushHandler: Subscription;
	public readonly comments = new ChatDataSource();
	public readonly editorConfig = {
		toolbar: SimpleToolbar,
		height: 80
	};

	public readonly styles = styles;

	public commentText: string = '';

	constructor(
		private DateParsers: DateParsersService,
		private PushBus: PushBus,
		private UserContext: UserContextService,
		private WebcastService: WebcastService
	) {}

	public ngOnInit(): void {
		this.loadData();
	}

	public ngOnDestroy(): void {
		this.unsubscribePushHandler?.unsubscribe();
	}

	public postComment(): void {
		if(!this.commentText){
			return;
		}

		const comment: IComment = {
			userId: this.user.id,
			comment: this.commentText
		};

		this.WebcastService
			.addWebcastComment(this.webcast, comment)
			.then(commandId => this.pendingCommands[commandId] = comment);

		this.displayPendingComment(comment);
		this.commentText = '';
	}

	private loadData(): void {
		this.status = { loading: true };

		this.loadComments()
			.then(() => {
				setTimeout(() => {
					this.status = { active: true };
					//Virtual scroll was sometimes not updating after the initial data loads.
					setTimeout(() => window.dispatchEvent(new Event('resize')));
				}, 500);
			})
			.catch(() => this.status = { error: true });
	}

	private loadComments(): Promise<void> {
		return this.WebcastService.getWebcastComments(this.webcast)
			.then(comments => {
				comments.forEach(comment => this.updateUserName(comment));

				this.comments.setComments(comments);
				this.unsubscribePushHandler = this.initPushHandlers();
			});
	}

	private displayPendingComment(comment: IComment) {
		comment.name = this.user.fullName;
		comment.userProfileImageUri = this.user.profileImageUri;
		this.comments.append(comment);
	}

	public updateUserName(comment: IComment): void {
		comment.name = [comment.userFirstName, comment.userLastName].filter(Boolean).join(' ');
	}

	public addCommentToWebcast(msgData): void {
		if (!msgData) {
			return;
		}

		const date = this.DateParsers.parseUTCDate(msgData.date);
		const pendingComment = this.pendingCommands[msgData.commandId];

		if(pendingComment){
			pendingComment.date = date;
			delete this.pendingCommands[msgData.commandId];
		} else{
			const comment = msgData;
			this.updateUserName(comment);
			this.comments.append(comment);
		}
	}

	public initPushHandlers(): Subscription {

		const adminSub = this.webcast.currentUser.isEventAdmin$
			.pipe(
				switchMap(isAdmin => {
					return !isAdmin
						? EMPTY
						: new Observable(() =>
							this.PushBus.subscribe(this.user.id, {
								WebcastCommentAdded: (comment: IComment) => {
									if (this.webcast.id === comment.webcastId){
										this.addCommentToWebcast(comment);
									}
								}
							})
						);
				})
			).subscribe();

		const webcastPushSub = this.PushBus.subscribe(this.webcast.id, {
			WebcastCommentAdded: (comment: IComment) => this.addCommentToWebcast(comment)
		});

		return adminSub.add(webcastPushSub);
	}
}
