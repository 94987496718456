import { Pipe, PipeTransform } from '@vbrick/angular-ts-decorators';
import { ISCEService } from 'angular';

@Pipe({ name: 'vbTrustAsHtml' })
export class TrustAsHtmlPipe implements PipeTransform {

	constructor(private $sce: ISCEService) {
		'ngInject';
	}

	public transform(html: string): any {
		return this.$sce.trustAsHtml(html);
	}
}
