import { Component, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { distinctUntilChanged, filter, first, map, tap } from 'rxjs/operators';

import { DateUtil } from 'rev-shared/date/DateUtil';

import { IRtmpSettings } from './IRtmpSettings';
import { RtmpPushUrlConflict } from './EditWebcast.Service';
import { RtmpVideoSourceDetailsService, updateRtmpUrl } from './RtmpVideoSourceDeatils.Service';

const bootstrapFormStyles = {
	formGroup: 'form-group',
	label: 'control-label',
	topLabel: 'control-label col-sm-3',
	outerContainer: 'col-sm-9',
	row: 'row',
	innerContainer: 'col-md-6 col-sm-9',
	error: 'margin-left-16 col-md-6 col-sm-9',
	radioBtn: `btn btn-admin btn-white`
};

const ErrorDetails = {
	RTMP_URL_CONFLICT: 'RtmpPushUrlConflict'
};

@Component({
	selector: 'rtmp-video-source-details',
	templateUrl: './RtmpVideoSourceDetails.Component.html'
})
export class RtmpVideoSourceDetailsComponent {
	@Input() public readonly endDate: Date;
	@Input() public readonly issue: string;
	@Input() public readonly preProductionDurationMs: number;
	@Input() public readonly rtmpSettings: IRtmpSettings;
	@Input() public readonly startDate: Date;
	@Input() public readonly themed: boolean = false;
	@Input() public readonly webcastId: string;

	@Output() public rtmpSettingsChanged: EventEmitter<IRtmpSettings> = new EventEmitter<IRtmpSettings>();
	@Output() public validated: EventEmitter<ValidationErrors> = new EventEmitter();

	constructor(
		private RtmpVideoSourceDetailsService: RtmpVideoSourceDetailsService
	){}

	public styles: any = bootstrapFormStyles;
	public rtmpContext$: Observable<IRtmpSettings>;
	public errors: ValidationErrors = {};
	public readonly ErrorDetails = ErrorDetails;

	private rtmpSettingsSubject$: BehaviorSubject<IRtmpSettings> = new BehaviorSubject<IRtmpSettings>(undefined);

	public ngOnInit(): void {
		if (!this.rtmpSettings) {
			this.generateNewSettings(true);
		} else {
			this.setRtmpSettingsSubject(this.rtmpSettings);
		}

		this.rtmpContext$ = this.rtmpSettingsSubject$
			.asObservable()
			.pipe(
				distinctUntilChanged(),
				tap(settings => {
					this.validateVideoSourceSchedule();
					this.rtmpSettingsChanged.emit(settings);
				})
			);
	}

	public ngOnChanges({ startDate, endDate, preProductionDurationMs, issue }: SimpleChanges) {
		if(startDate || endDate || preProductionDurationMs ) {
			this.validateVideoSourceSchedule();
		}

		if (issue && this.issue) {
			this.onHandleError(this.issue);
		}
	}

	public onProtocolChange(isSecure: boolean): void {
		const settings = this.rtmpSettingsSubject$.value;

		if (!settings?.pushUrl) {
			return;
		}
		const newUrl = updateRtmpUrl(settings.pushUrl, isSecure);
		this.setRtmpSettingsSubject({ isSecure, pushUrl: newUrl, streamKey: settings.streamKey });
	}

	public generateNewSettings(isSecure: boolean): void {
		this.getNewRtmpSettings(isSecure)
			.pipe(
				first()
			).subscribe(settings => this.setRtmpSettingsSubject(settings));
	}

	private setRtmpSettingsSubject(settings: IRtmpSettings): void {
		this.rtmpSettingsSubject$.next(settings);
	}

	private getNewRtmpSettings(isSecure: boolean): Observable<IRtmpSettings> {
		return this.getRtmpUrlDetails(isSecure)
			.pipe(
				map(urlDetails => ({ isSecure, pushUrl: urlDetails.url, streamKey: urlDetails.streamKey }))
			);
	}

	private getRtmpUrlDetails(isSecure: boolean): Observable<{ url: string; streamKey: string }> {
		return this.RtmpVideoSourceDetailsService.getRtmpUrlDetails(isSecure);
	}

	private validateVideoSourceSchedule(): void {
		const { pushUrl } = this.rtmpSettingsSubject$?.value || {};
		if (!pushUrl || !(this.startDate instanceof Date) || !(this.endDate instanceof Date)) {
			 return;
		}
		const startDate = DateUtil.addDays(this.getStartDate(), -1);
		const endDate = DateUtil.addDays(this.endDate, 1);

		this.loadSchedule(startDate, endDate, pushUrl)
			.pipe(
				first(),
				map(events => {
					return (events || []).filter(event =>
						event.id !== this.webcastId &&
						event.endDate > this.getStartDate() &&
						event.startDate < this.endDate
					).length > 0;
				}),
				tap(invalid => {
					this.onHandleError(invalid ? ErrorDetails.RTMP_URL_CONFLICT : undefined);
				})
			).subscribe();

	}

	private loadSchedule(startDate: Date, endDate: Date, pushUrl: string): Observable<any> {
		 return this.RtmpVideoSourceDetailsService.getRtmpVideoSourceSchedules(startDate.toISOString(), endDate.toISOString(), pushUrl);
	}

	private getStartDate(): Date {
		return this.preProductionDurationMs > 0 ?
			new Date(this.startDate.getTime() - this.preProductionDurationMs) :
			this.startDate;
	}

	private onHandleError(issue: string): void {
		const errors = {
			[ErrorDetails.RTMP_URL_CONFLICT]: issue === RtmpPushUrlConflict
		};
		const isValid = !errors || !Object.values(errors).some(Boolean);
		this.errors = isValid ? undefined : errors;

		this.validated.emit(this.errors);
	}
}
