import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling/virtual-scroll-viewport';
import { FixedSizeVirtualScrollStrategy } from '@angular/cdk/scrolling';

const tailBottom = 100;

export class TailScrollStrategy extends FixedSizeVirtualScrollStrategy {
	private viewport: CdkVirtualScrollViewport;

	constructor() {
		super(44, 250, 500);
	}

	public attach(viewport): void {
		super.attach(viewport);
		this.viewport = viewport;
	}

	public onDataLengthChanged(): void {
		super.onDataLengthChanged();
		const scrollBottom = this.viewport.measureScrollOffset('bottom');

		if(scrollBottom < tailBottom) {
			setTimeout(() => {
				this.viewport.scrollTo({ bottom: 0 });
			}, 200);
		}
	}
}
