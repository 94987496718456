import { Directive, OnInit } from '@vbrick/angular-ts-decorators';
import { IAugmentedJQuery } from 'angular';

import styles from './VbUiBtnDanger.module.less';

@Directive({
	selector: '[vb-ui-btn-danger]',
})
export class VbUiBtnDangerDirective implements OnInit {
	constructor(
		private $element: IAugmentedJQuery
	) {
		'ngInject';
	}
	public ngOnInit(): void {
		this.$element.addClass(styles.vbUiBtnDanger);
	}
}
