import { NgModule } from '@vbrick/angular-ts-decorators';

import { ShareEmbed } from './ShareEmbed.Component';

@NgModule({
	id: 'VBrick.Shared.Media.ShareEmbed',
	declarations: [
		ShareEmbed
	],
	imports: [
	],
	providers: []
})
export default class ShareEmbedModule {}
