<quality-experience-report-kpis [data]="performanceData">
</quality-experience-report-kpis>

<div layout="row">
	<vb-ui-report-cell [ngClass]="styles.mediumChartWrapper" [header]="'Reports_VideoPlayer_BitRate' | translate" [noHeaderBottomBorder]="true" [size]="'large'">
		<vb-ui-duration-line-chart [ngClass]="styles.mediumChartSize" [durationField]="'key'" [data]="performanceData.bitRate" [valueField]="'value'" [xAxisLabel]="'Reports_Video_DurationTime' | translate" [yAxisLabel]="'Reports_Video_Kbps' | translate" [baseUnit]="'millisecond'">
		</vb-ui-duration-line-chart>
	</vb-ui-report-cell>
	<vb-ui-report-cell [ngClass]="styles.mediumChartWrapper" [header]="'Reports_VideoPlayer_Bandwidth' | translate" [noHeaderBottomBorder]="true" [size]="'large'">
		<vb-ui-duration-line-chart [ngClass]="styles.mediumChartSize" [durationField]="'key'" [data]="performanceData.bandwidth" [valueField]="'value'" [xAxisLabel]="'Reports_Video_DurationTime' | translate" [yAxisLabel]="'Reports_Video_Mbps' | translate" [baseUnit]="'millisecond'">
		</vb-ui-duration-line-chart>
	</vb-ui-report-cell>
</div>

<div layout="row">
	<vb-ui-report-cell [ngClass]="styles.mediumChartWrapper" [header]="'Reports_Video_ExperiencedRebufferingDurationPercentage' | translate" [noHeaderBottomBorder]="true" [size]="'large'">
		<vb-ui-bar-chart [ngClass]="styles.mediumChartSize" [categoryField]="'key'" [data]="performanceData.experiencedBufferingDuration" [valueField]="'value'" [categoryAxisTitleLabel]="'Reports_Video_Zones' | translate" [valueAxisTitleLabel]="'Reports_Video_ExperiencedRebufferingDurationPercentage' | translate" [legendLabel]="'Reports_Video_ExperiencedRebufferingDurationPercentage' | translate" [hideCategoryAxisLabel]="true">
		</vb-ui-bar-chart>
	</vb-ui-report-cell>
</div>

<div layout="row">
	<vb-ui-report-cell [ngClass]="styles.mediumChartWrapper" [header]="'Reports_VideoPlayer' | translate" [noHeaderBottomBorder]="true" [size]="'large'">
		<vb-ui-stacked-bar-chart [ngClass]="styles.mediumChartSize" [categoryField]="'key'" [data]="videoPlaybackData.videoPlayers" [categoryAxisTitleLabel]="'Reports_Video_Zones' | translate" [valueAxisTitleLabel]="'Reports_Video_NumberOfPlayers' | translate" [hideCategoryAxisLabel]="true">
		</vb-ui-stacked-bar-chart>
	</vb-ui-report-cell>
	<vb-ui-report-cell [ngClass]="styles.mediumChartWrapper" [header]="'Reports_VideoStream' | translate" [noHeaderBottomBorder]="true" [size]="'large'">
		<vb-ui-stacked-bar-chart [ngClass]="styles.mediumChartSize" [categoryField]="'key'" [data]="videoPlaybackData.videoFormats" [categoryAxisTitleLabel]="'Reports_Video_Zones' | translate" [valueAxisTitleLabel]="'Reports_Video_NumberOfStreams' | translate" [hideCategoryAxisLabel]="true">
		</vb-ui-stacked-bar-chart>
	</vb-ui-report-cell>
</div>

<div layout="row">
	<vb-ui-report-cell [ngClass]="styles.mediumChartWrapper" [header]="'Reports_video_ExperiencedErrorPerTotalAttendees' | translate" [noHeaderBottomBorder]="true" [size]="'large'">
		<vb-ui-bar-chart [ngClass]="styles.mediumChartSize" [categoryField]="'key'" [data]="performanceData.experiencedErrorsPerZone" [valueField]="'value'" [categoryAxisTitleLabel]="'Reports_Video_Zones' | translate" [valueAxisTitleLabel]="'Reports_video_ExperiencedErrors' | translate" [legendLabel]="'Reports_video_ExperiencedErrors' | translate" [hideCategoryAxisLabel]="true">
		</vb-ui-bar-chart>
	</vb-ui-report-cell>
</div>
