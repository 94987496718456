import { IVbNg2StateDeclaration } from 'rev-shared/ts-utils/IVbNg2StateDeclaration';

import { WEBCAST_FULLSCREEN_MANAGE_QUESTION_STATE } from 'rev-portal/scheduledEvents/webcast/Constants';

import { VbWebcastManageQuestionFullscreenComponent } from './VbWebcastManageQuestionFullscreen.Component';

export const states: IVbNg2StateDeclaration[] = [
	{
		name: WEBCAST_FULLSCREEN_MANAGE_QUESTION_STATE,
		url: '/manage-questions',
		component: VbWebcastManageQuestionFullscreenComponent
	}
];
