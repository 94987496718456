import {
	Component,
	Input,
	OnInit,
} from '@angular/core';

import { ScheduledEventService } from 'rev-shared/scheduledEvents/ScheduledEvent.Service';

import styles from './event-widget.module.less';

@Component({
	selector: 'event-widget',
	templateUrl: './EventWidget.Component.html'
})
export class EventWidgetComponent implements OnInit {
	@Input() public accountId: string;

	public readonly styles = styles;

	public event: any;
	public isFetching: boolean = true;

	constructor(
		private ScheduledEventService: ScheduledEventService
	) {}

	public ngOnInit(): void {
		this.loadUpcomingEvents();
	}

	private loadUpcomingEvents(): void {
		this.isFetching = true;
		this.ScheduledEventService.getUpcomingEvents(this.accountId)
			.then(events => this.event = events[0])
			.finally(() => this.isFetching = false);
	}
}
