import { Component } from '@vbrick/angular-ts-decorators';
import { ITimeoutService } from 'angular';
import { StateService } from '@uirouter/angularjs';

import { IDialog } from 'rev-shared/ui/dialog/IDialog';
import { SecurityContextService } from 'rev-shared/security/SecurityContext.Service';
import { UserContextService } from 'rev-shared/security/UserContext.Service';
import { VideoSelectionModelService } from 'rev-portal/media/bulkEdit/VideoSelectionModel.Service';
import { VideoService } from 'rev-shared/media/Video.Service';

import { MediaStateService } from 'rev-portal/media/MediaState.Service';

import './legal-hold.less';

const timeoutInMilliseconds: number = 2000;

@Component({
	selector: 'legal-hold-sidebar',
	templateUrl: '/partials/sidebar/legal-hold.html'
})

export default class LegalHoldBulkEditComponent {
	private applyHoldConfirmation: IDialog;
	private removeHoldConfirmation: IDialog;
	private applyBulkHoldConfirmation: IDialog;
	private removeBulkHoldConfirmation: IDialog;
	private videos: any[];
	private isAccountAdminUser: boolean;

	constructor(
		private $state: StateService,
		private $timeout: ITimeoutService,
		private MediaStateService: MediaStateService,
		private SecurityContext: SecurityContextService,
		private UserContext: UserContextService,
		private VideoSelectionModel: VideoSelectionModelService,
		private VideoService: VideoService
	) {
		'ngInject';

		this.isAccountAdminUser = !!this.SecurityContext.checkAuthorization('admin.accounts.edit');
	}
	public get hasVideosSelected(): number {
		return this.VideoSelectionModel.selectionCount;
	}

	public get isOnLegalHold(): boolean {
		if (this.VideoSelectionModel.totalVideos) {
			return this.VideoSelectionModel.selectedVideos.some(video => video.legalHold);
		}

		return false;
	}

	public get hasMixedLegalHoldValues(): boolean {
		if (this.VideoSelectionModel.selectionCount > 0) {
			return this.VideoSelectionModel.hasMixedLegalHoldSelected;
		}
	}

	public lockVideos(): void {
		this.videos = this.VideoSelectionModel.selectedVideos.map(video => video.id);
		this.applyBulkHoldConfirmation.open().result
			.then(() => {
				this.VideoService.lockVideos(this.UserContext.getAccount().id, this.videos)
					.then(videoIds => {
						videoIds.forEach(videoId => {
							const videoItem = this.VideoSelectionModel.selectedVideos.find(video => videoId === video.id);
							if (videoItem) {
								videoItem.legalHold = true;
								videoItem.isActive = false;
							}
						});
					});
			})
			.then(() => this.redirectToState());
	}

	public unLockVideos(): void {
		this.videos = this.VideoSelectionModel.selectedVideos.map(video => video.id);
		this.removeBulkHoldConfirmation.open().result
			.then(() => {
				this.VideoService.unlockVideos(this.UserContext.getAccount().id, this.videos)
					.then(videoIds => {
						videoIds.forEach(videoId => {
							const videoItem = this.VideoSelectionModel.selectedVideos.find(video => videoId === video.id);
							if (videoItem) {
								videoItem.legalHold = false;
								videoItem.isActive = true;
							}
						});
					});
			})
			.then(() => this.redirectToState());
	}

	private redirectToState(): void {
		this.$timeout(() => this.MediaStateService.navigateBack(), timeoutInMilliseconds);
	}
}
