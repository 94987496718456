import { NgModule } from '@vbrick/angular-ts-decorators';

import { InsightSearchHelper } from './InsightSearchHelper.Service';
import { SearchService } from './Search.Service';
import { downgradeInjectables } from 'rev-shared/util/AngularHybridUtils';

@NgModule({
	id: 'VBrick.Portal.Search',
})
export default class SearchModule {
}

downgradeInjectables(SearchModule, [
	{
		name: 'InsightSearchHelper',
		injectable: InsightSearchHelper
	}, {
		name: 'SearchService',
		injectable: SearchService
	}
]);
