
import {
	Component,
	Input
} from '@vbrick/angular-ts-decorators';

import './user-nav-menu.less';

@Component({
	selector: 'user-nav-menu',
	templateUrl: '/partials/portal/navigation/user-menu.html'
})
export class UserNavMenuComponent {
	@Input() private user: string;
	@Input() private readonlyUserProfile: boolean;
}
