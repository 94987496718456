import {
	Directive,
	Input,
	OnChanges,
	OnInit,
	SimpleChanges
} from '@angular/core';


import { HlsUtilService } from '@vbrick/vbrick-player/app/player/HlsUtil.Service';
import { VgStates } from '@vbrick/vbrick-player/app/player/videogular/VgStates';

import { IModernPlayback } from 'rev-shared/videoPlayer/IModernPlayback';

@Directive({
	selector: '[slideDelay]',
})
export class SlideDelayDirective implements OnInit, OnChanges {
	@Input() public activeSource: IModernPlayback;
	@Input() public canControlSlides: boolean;
	@Input() public currentState: string;
	@Input() public presentation: any; // TODO: refactor Presentation.Provider so that can get model as export
	@Input() public webcastPlaybacks: any[];

	private activeSourceSlideDelaySeconds: number;
	private isHls: boolean;
	private isPlaying: boolean;

	constructor(
		private HlsUtilService: HlsUtilService
	) {}

	public ngOnInit(): void {
		this.activeSourceSlideDelaySeconds = 0;
		this.isHls = false;
		this.isPlaying = false;
	}

	public ngOnChanges(changes: SimpleChanges): void {
		const needsUpdate = changes.activeSource || changes.canControlSlides || changes.currentState;

		if (!this.webcastPlaybacks) {
			return;
		}

		if (changes.activeSource) {
			this.onActiveSourceChange();
		}

		if (changes.currentState) {
			this.onCurrentStateChange();
		}

		if (needsUpdate) {
			this.updateSlideDelay();
		}
	}

	private onActiveSourceChange(): void {
		if (!this.activeSource) {
			return;
		}

		this.activeSourceSlideDelaySeconds = this.webcastPlaybacks.find(playback => playback.url === this.activeSource.originalSrc)?.slideDelaySeconds;
		this.isHls = this.HlsUtilService.isHLS(this.activeSource.src, this.activeSource.type);
	}

	private onCurrentStateChange(): void {
		this.isPlaying = this.currentState === VgStates.PLAY;
	}

	private updateSlideDelay(): void {
		this.presentation.slideDelayMilliseconds = this.isHls && this.isPlaying && !this.canControlSlides ?
			this.activeSourceSlideDelaySeconds * 1000 : // sec => ms
			0;
	}
}
