import { Transition } from '@uirouter/angular';
import { Subscription } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

import { AnalyticsService } from 'rev-shared/analytics/Analytics.Service';
import { IAnalyticsSettings } from 'rev-shared/analytics/IAnalyticsSettings';
import { IUnsubscribe } from 'rev-shared/push/IUnsubscribe';
import { IVbNg2StateDeclaration } from 'rev-shared/ts-utils/IVbNg2StateDeclaration';
import { MomentLocaleLoader } from 'rev-shared/date/MomentLocaleLoader.Provider';
import { PushService } from 'rev-shared/push/PushService';

import { ThemeService } from 'rev-portal/branding/Theme.Service';

import { WebcastService } from '../webcast/Webcast.Service';
import { WebcastModelService } from '../webcast/model/WebcastModel.Service';
import { WebcastModel } from '../webcast/model/WebcastModel';

import { RealTimeAnalyticsComponent } from './RealTimeAnalytics.Component';
import { initCurrentRunFullscreenNgxResolve, requireFeatureForPopupNgx, revConnectLicenseNgxResolve } from '../webcast/Webcast.StateConfig';

const ANALYTICS_SETTINGS_TOKEN = 'analyticsSettings';
const WEBCAST_TOKEN = 'webcast';
const WEBCAST_ID_TOKEN = 'webcastId';

export const realTimeAnalyticsStateDeclarations: IVbNg2StateDeclaration[] = [
	{
		name: 'webcast-analytics',
		url: '/webcast/analytics/{webcastId}',
		component: RealTimeAnalyticsComponent,
		resolve: [
			{
				token: 'init',
				deps: [
					MomentLocaleLoader,
					ThemeService
				],
				resolveFn(MomentLocaleLoader: MomentLocaleLoader, themeService: ThemeService) {
					return Promise.all([MomentLocaleLoader.load(), themeService.initialize()]);
				}
			},
			{
				token: WEBCAST_ID_TOKEN,
				deps: [Transition],
				resolveFn(transition: Transition) {
					return transition.params().webcastId;
				}
			},

			{
				token: WEBCAST_TOKEN,
				deps: [
					WebcastService,
					WEBCAST_ID_TOKEN
				],
				resolveFn(
					webcastService: WebcastService,
					webcastId: string
				) {
					return webcastService.getWebcast(webcastId);
				}
			},

			initCurrentRunFullscreenNgxResolve,

			{
				token: 'rtaViewInit',
				deps: [
					WEBCAST_TOKEN,
					PushService
				],
				resolveFn(webcast: WebcastModel, PushService: PushService) {
					return webcast.webcast$.pipe(
						filter(webcast => webcast.currentRun?.isStarted),
						switchMap(webcast => PushService.dispatchCommand('scheduledEvents:ViewWebcastRealtimeAnalytics',
							{
								webcastId: webcast.id,
								runNumber: webcast.currentRun.runNumber
							}
						))
					).subscribe();
				}
			},

			requireFeatureForPopupNgx('requireFeature', w => w.currentUser.isEventAdminOrModerator),

			{
				token: 'pushSubscription',
				deps: [
					WebcastModelService,
					WEBCAST_TOKEN
				],
				resolveFn(
					webcastModelService: WebcastModelService,
					webcast: WebcastModel
				) {
					return webcastModelService.registerWebcastPushHandlers(webcast);
				}
			},

			revConnectLicenseNgxResolve,

			{
				token: ANALYTICS_SETTINGS_TOKEN,
				deps: [
					AnalyticsService
				],
				resolveFn(analyticsService: AnalyticsService) {
					return analyticsService.getSettings();
				}
			},

			{
				token: 'kpiUpdateIntervalSecs',
				deps: [
					ANALYTICS_SETTINGS_TOKEN
				],
				resolveFn(analyticsSettings: IAnalyticsSettings) {
					return analyticsSettings.webcastRealtimeKpiUpdateInterval;
				}
			},

			{
				token: 'tableUpdateIntervalSecs',
				deps: [
					ANALYTICS_SETTINGS_TOKEN
				],
				resolveFn(analyticsSettings: IAnalyticsSettings) {
					return analyticsSettings.webcastRealtimeTableUpdateInterval;
				}
			}
		],
		onExit(transition: Transition) {
			const injector = transition.injector();

			Promise.all([
				injector.getAsync<Subscription>('requireFeature'),
				injector.getAsync<Subscription>('rtaViewInit'),
				injector.getAsync<IUnsubscribe>('pushSubscription')
			])
				.then(([requireFeature, rtaViewInit, pushSubscription]) => {
					requireFeature.unsubscribe();
					rtaViewInit.unsubscribe();
					pushSubscription();
				});
		}
	}
];
