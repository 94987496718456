<engagement-report-kpis [data]="eventSummary">
</engagement-report-kpis>

<div layout="row">
	<vb-ui-report-cell [ngClass]="styles.mediumChartWrapper" [header]="'Reports_AttendeeTrends' | translate" [noHeaderBottomBorder]="true" [size]="'large'">
		<vb-ui-duration-line-chart [ngClass]="styles.mediumChartSize" [durationField]="'key'" [data]="eventSummary.sessions" [integerValueAxis]="true" [valueField]="'value'">
		</vb-ui-duration-line-chart>
	</vb-ui-report-cell>
	<vb-ui-report-cell [ngClass]="styles.mediumChartWrapper" [header]="'Reports_Top15Zones' | translate" [noHeaderBottomBorder]="true" [size]="'large'">
		<vb-ui-bar-chart [ngClass]="styles.mediumChartSize" [categoryField]="'key'" [data]="eventSummary.zoneCounts" [horizontalBars]="true" [valueField]="'value'" [hideLegend]="true">
		</vb-ui-bar-chart>
	</vb-ui-report-cell>
</div>

<div layout="row">
	<vb-ui-report-cell [ngClass]="styles.mediumChartWrapper" [header]="'Reports_WebBrowsers' | translate" [noHeaderBottomBorder]="true" [size]="'large'">
		<vb-ui-pie-chart flex="fill" [ngClass]="styles.mediumChartSize" [categoryField]="'key'" [data]="eventSummary.browserCounts" [valueField]="'value'">
		</vb-ui-pie-chart>
	</vb-ui-report-cell>
	<vb-ui-report-cell [ngClass]="styles.mediumChartWrapper" [header]="'Reports_DeviceTypes' | translate" [noHeaderBottomBorder]="true" [size]="'large'">
		<vb-ui-pie-chart flex="fill" [ngClass]="styles.mediumChartSize" [categoryField]="'key'" [data]="eventSummary.deviceCounts" [valueField]="'value'">
		</vb-ui-pie-chart>
	</vb-ui-report-cell>
</div>

<div *ngIf="pollResults?.polls?.length" layout="row" class="graphs-box-container">
	<vb-poll-trend [pollTitle]="'Event_Polls' | translate" [csvTitle]="'.Reports_Csv' | translate" [polls]="pollResults.polls">
	</vb-poll-trend>
</div>
