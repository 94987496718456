import { formatNumber } from '@angular/common';

import { IVbUiDataGridValueFormatterParams } from './IVbUiDataGridValueFormatterParams';

export interface INumberValueFormatterParams extends IVbUiDataGridValueFormatterParams {
	maxFractionDigits?: number;
	minFractionDigits?: number;
	minIntegerDigits?: number;
}

export function numberValueFormatter(params: INumberValueFormatterParams): string {
	const digitsInfo: string = `${params.minIntegerDigits || 1}.${params.minFractionDigits || 0}-${params.maxFractionDigits || 0}`;

	if (!isFinite(params.value)) {
		return '';
	}

	return formatNumber(params.value, params.context.localeId, digitsInfo);
}

export const twoDecimalValueFormatter = params => numberValueFormatter({ ...params, minFractionDigits: 1, maxFractionDigits: 2 });
