import { Injectable } from '@vbrick/angular-ts-decorators';
import {
	IPromise,
	resource
} from 'angular';

export interface IExpiryRuleChanges {
	deletedRules: any[];
	newRules: any[];
	updatedRules: any[];
}

@Injectable('ExpiryRuleService')
export class ExpiryRuleSvc {
	private accountExpiryRuleResource: resource.IResourceClass<resource.IResource<any>>;

	constructor(
		$resource: resource.IResourceService
	) {
		'ngInject';

		this.accountExpiryRuleResource = $resource('/media/accounts/:accountId/expiry-rules');
	}

	public getExpiryRules(accountId: string): IPromise<any> {
		return this.accountExpiryRuleResource.get({ accountId }).$promise;
	}
}
