import {
	StateService,
	Transition
} from '@uirouter/angularjs';

import { IPortalStartup, PortalStartup } from 'rev-portal/PortalStartup.Provider';
import { LoginRedirectService } from 'rev-shared/security/LoginRedirect.Service';
import { SecurityContextService } from 'rev-shared/security/SecurityContext.Service';

import { WebcastService } from '../webcast/Webcast.Service';
import { WEBCAST_LANDING_STATE } from '../webcast/Constants';
import { IVbNg2StateDeclaration } from 'rev-shared/ts-utils/IVbNg2StateDeclaration';
import { ng2StatesToHybridStates } from 'rev-shared/uiRouterUtils/HybridRouterUtils';
import { EventRegistrationComponent } from './EventRegistration.Component';

export const states: IVbNg2StateDeclaration[] = ng2StatesToHybridStates([{
	name: 'eventRegistration',
	url: '/event-registration/:webcastId',
	component: EventRegistrationComponent,
	allowAllAccess: true,
	resolve: [{
		token: 'init',
		deps: [PortalStartup],
		resolveFn: (PortalStartup: IPortalStartup) => PortalStartup.$promise
	}, {
		token: 'webcastId',
		deps: [Transition],
		resolveFn: ($transition$: Transition) => $transition$.params().webcastId
	}, {
		token: 'webcastUrl',
		deps: [StateService, 'webcastId'],
		resolveFn: ($state: StateService, webcastId: string) =>
			$state.href(WEBCAST_LANDING_STATE, { webcastId }).slice(1) //drop the # from the beginning
	}, {
		token: 'webcastInfo',
		deps: [
			LoginRedirectService,
			SecurityContextService,
			WebcastService,
			'webcastId',
			'webcastUrl'
		],
		resolveFn: (
			LoginRedirectService: LoginRedirectService,
			SecurityContext: SecurityContextService,
			WebcastService: WebcastService,
			webcastId: string,
			webcastUrl: string
		) => WebcastService.getWebcastInfo(webcastId)
			.catch(err => {
				if(err.status === 401){
					return Promise.reject({ status:401, data: { reason:'AttendeeRemoved', noLoginRedirect: true } });
				}
				return Promise.reject({ status: 404 });
			})
			.then(webcastInfo => {
				//if not public, then redirect to login (login should take you into the event)
				//or the viewing hours license for the account are not longer available
				if (!webcastInfo.isPublic || !SecurityContext.checkAuthorization('publicWebcastAccess')) {
					window.setTimeout(() => {
						LoginRedirectService.redirectToLogin(webcastUrl);
					}, 100);
					return Promise.reject();
				}
				return webcastInfo;
			})
	}]
}]);
