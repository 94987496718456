import {
	Component,
	Input,
	OnDestroy,
	OnInit
} from '@vbrick/angular-ts-decorators';
import {
	INgModelController,
	IPromise
} from 'angular';

import { IUnsubscribe } from 'rev-shared/push/IUnsubscribe';
import { retryUntilSuccess } from 'rev-shared/util/PromiseUtil';
import { VbConfirmationDialogComponent } from 'rev-shared/ui/dialog/VbConfirmationDialog.Component';

import { SparkService } from 'rev-portal/media/spark/Spark.Service';

import styles from './webex-teams.module.less';

interface ITeamRoom {
	created: string;
	id: string;
	lastActivity: string;
	title: string;
}

interface ITeam {
	name: string;
	roomId: string;
	sipAddress: string;
}

@Component({
	selector: 'webex-teams',
	templateUrl: '/partials/scheduled-events/webex-teams-component.html',
	require: {
		ngModelController: 'ngModel'
	}
})
export class WebexTeamsComponent implements OnDestroy, OnInit {
	@Input() public ngModel: ITeam;

	public get status(): { [p: string]: boolean } {
		return this._status;
	}

	public set status(value: { [p: string]: boolean }) {
		this._status = value;
	}

	private readonly styles = styles;

	private ngModelController: INgModelController;
	private rooms: ITeamRoom[];
	public sipAddressErrorDialog: VbConfirmationDialogComponent;
	private sparkAuthenticationRequired: boolean;
	private sparkOnAuthenticateUnsubscribe: IUnsubscribe;
	private _status: { [propKey: string]: boolean };
	private teamName: string;

	constructor(
		private SparkService: SparkService
	) {
		'ngInject';
	}

	public ngOnInit(): void {
		this.loadRooms();
		this.ngModelController.$formatters.push((team: ITeam) => this.teamName = team ? team.name : null);
	}

	public ngOnDestroy(): void {
		if (this.sparkOnAuthenticateUnsubscribe) {
			this.sparkOnAuthenticateUnsubscribe();
		}
	}

	public onChange() {
		this.ngModelController.$setViewValue(null);
		this.ngModelController.$setDirty();
	}

	private loadRooms(isRetry: boolean = false): IPromise<any> {
		this._status = { processing: true };
		this.sparkAuthenticationRequired = false;

		return (isRetry ?
			retryUntilSuccess(() => Promise.resolve(this.SparkService.getRooms(true))) :
			Promise.resolve(this.SparkService.getRooms(true))
		)
			.then(rooms => {
				this._status = { active: true };
				this.rooms = rooms.sort((a, b) => {
					const titleA = a.title;
					const titleB = b.title;
					return titleA < titleB ? -1 : 1;
				});
			})
			.catch(err => {
				if (err.authenticationRequired && !isRetry) {
					this._status = { active: true };
					this.sparkAuthenticationRequired = true;

					this.sparkOnAuthenticateUnsubscribe = this.SparkService.onAuthenticate(() => this.loadRooms(true));
				} else {
					this._status = {
						error: true,
						loadRoomsError: true
					};
				}
			});
	}

	private selectTeam(team): void {
		this.SparkService.getWebexRoomDetails(team.id)
			.then(room => {
				this.ngModelController.$setViewValue({
					roomId: room.roomId,
					name: team.title, //cisco api not sending room name now.
					sipAddress: room.sipAddress
				});
				this.ngModelController.$setDirty();
			})
			.catch(() => this.sipAddressErrorDialog.open().result);
	}

}
