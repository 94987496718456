<event-registration [webcastInfo]="webcast" [isPreview]="true">

	<div [ngClass]="styles.previewBanner">
		{{ 'Event_Registration_PreviewTitle' | translate }}

		<button type="button" class="close pull-right btn btn-transparent" [attr.aria-label]="'Close' | translate " [ngClass]="styles.closeBtn" (click)="close()">
			<span class="glyphicons remove_2"></span>
		</button>
	</div>
</event-registration>
