import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { WebcastModel } from 'rev-portal/scheduledEvents/webcast/model/WebcastModel';

import { AnalyticsService } from 'rev-shared/analytics/Analytics.Service';
import { DeviceHealthStatus } from 'rev-shared/device/SharedDeviceContract';
import { numberValueFormatter, twoDecimalValueFormatter } from 'rev-shared/ui/dataGrid/valueFormatters/NumberValueFormatter';

import { RealTimeAnalyticsZonesService } from './RealTimeAnalyticsZones.Service';
import { RealTimeAnalyticsStatusHealthMetricsRendererComponent } from './RealTimeAnalyticsStatusHealthMetricsRenderer.Component';

enum ZonesColumnPickerGroup {
	METRICS,
	STREAM,
	ZONE
}

const deviceHealthStatusLabel= {
	[DeviceHealthStatus.Alert]: 'Event_RealTimeAnalytics_Zone_Devices_In_Alert',
	[DeviceHealthStatus.Caution]: 'Event_RealTimeAnalytics_Zone_Devices_In_Caution',
	[DeviceHealthStatus.Error]: 'Event_RealTimeAnalytics_Zone_Devices_In_Error',
	[DeviceHealthStatus.Normal]: 'Event_RealTimeAnalytics_Zone_Devices_In_Normal',
	[DeviceHealthStatus.Unavailable]: 'Event_RealTimeAnalytics_Zone_Devices_In_Unavailable',
};

@Component({
	selector: 'real-time-analytics-zones-tab',
	templateUrl: './RealTimeAnalyticsZonesTab.Component.html',
	providers: [
		RealTimeAnalyticsZonesService
	]
})
export class RealTimeAnalyticsZonesTabComponent {
	@Input() public webcast: WebcastModel;

	public readonly numberValueFormatter = numberValueFormatter;
	public readonly twoDecimalValueFormatter = twoDecimalValueFormatter;
	public readonly ZonesColumnPickerGroup = ZonesColumnPickerGroup;
	public readonly RealTimeAnalyticsZoneStatusRendererComponent = RealTimeAnalyticsStatusHealthMetricsRendererComponent;

	public readonly statusCellRendererParams = {
		getStatusDetails: data => ({
			overallStatus: data.Status,
			healthMetrics: this.getDeviceHealthMetrics(data.Devices)
		})
	};

	constructor(
		private readonly translate: TranslateService,
		public rtaZonesService: RealTimeAnalyticsZonesService,
		public AnalyticsService: AnalyticsService
	) {}

	private getDeviceHealthMetrics(devices: any): any[] {
		const totalCount = devices?.length;

		if(!totalCount) {
			return [];
		}

		return Object.values(DeviceHealthStatus)
			.map(value => {
				const deviceCount = devices.filter(device => device.Status === value).length;
				if (deviceCount) {
					return {
						label: this.translate.instant(deviceHealthStatusLabel[value], { 0: deviceCount, 1: totalCount })
					};
				}
			})
			.filter(Boolean);
	}
}
