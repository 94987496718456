import { Injectable } from '@angular/core';

import { isNumber } from 'rev-shared/util';
import { ApprovalProcessModel } from 'rev-shared/media/approvalProcess/ApprovalProcessModel';
import { ApprovalStepStatus } from 'rev-shared/media/MediaConstants';
import { ApproverTemplateInfo } from 'rev-shared/media/approvalProcess/ApproverTemplateInfo';
import { PushBus } from 'rev-shared/push/PushBus.Service';
import { IUnsubscribe } from 'rev-shared/push/IUnsubscribe';
import { SearchService } from 'rev-portal/search/Search.Service';
import { UserAccountService } from 'rev-shared/security/UserAccount.Service';
import { UserContextService } from 'rev-shared/security/UserContext.Service';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rev-shared/rxjs/lastValueFrom';

@Injectable({
	providedIn: 'root'
})
export class ApprovalProcessService {

	private approverTemplates: ApproverTemplateInfo[] = [];
	private userTemplates: ApprovalProcessModel[] = [];

	constructor(
		private http: HttpClient,
		private PushBus: PushBus,
		private SearchService: SearchService,
		private UserAccount: UserAccountService,
		private UserContext: UserContextService
	) { }

	public get userProcessTemplates(): ApprovalProcessModel[] {
		return this.userTemplates;
	}

	public get approverProcessTemplates(): ApproverTemplateInfo[] {
		return this.approverTemplates;
	}

	public fetchUserApprovalProcessTemplates(): Promise<void> {
		return lastValueFrom<any>(this.http.get(`/media/users/${this.UserContext.getUser().id}/approval-process-templates`))
			.then(data => {
				this.userTemplates = data.templates || [];
				this.approverTemplates = data.approverApprovalProcessTemplates || [];
			});
	}

	public findPendingVideosByApprovalProcessTemplateId(id, opts?): Promise<any> {
		const ids = Array.isArray(id) ? id : [id];
		opts = Object.assign({}, opts);
		return this.SearchService.getVideos({
			accountId: this.UserAccount.workingAccountId,
			pendingApproval: true,
			stepIds: ids,
			sortField: opts.sortField || 'title.sort',
			sortAscending: opts.sortAscending !== false,
			count: isNumber(opts.count) ? opts.count : this.SearchService.initialPageSize,
			scrollId: opts.scrollId
		});
	}

	public getVideoApprovalStatus(videoId: string): Promise<any> {
		return lastValueFrom<any>(this.http.get(`/media/videos/${videoId}/approval-status`));
	}

	public monitorPendingApproval(approverProcessTemplates: ApproverTemplateInfo[], callback: (count: number) => void): IUnsubscribe {
		const stepIds: string[] = approverProcessTemplates.map(template => template.stepId);
		let count: number = 0;

		this.findPendingVideosByApprovalProcessTemplateId(approverProcessTemplates.map(t => t.stepId), { count: 0 })
			.then(result => {
				update(result.totalHits);
			});

		return this.PushBus.composeUnsubscribeFn(
			stepIds.map(stepId =>
				this.PushBus.subscribe(stepId, {
					VideoApprovalStatusUpdated(data: any): void {
						switch (data.status) {
							case ApprovalStepStatus.PENDING:
								update(count + 1);
								break;

							case ApprovalStepStatus.APPROVED:
							case ApprovalStepStatus.REJECTED:
								update(count - 1);
								break;
						}
					}
				})
			));

		function update(newCount: number): void {
			if (newCount !== count) {
				count = newCount;
				callback(count);
			}
		}
	}
}
