import { NgModule } from '@vbrick/angular-ts-decorators';
import { ToolbarComponent } from './Toolbar.Component';

import { downgradeComponents } from 'rev-shared/util/AngularHybridUtils';

@NgModule({
	id: 'VBrick.UI.NewToolbar'
})
export class ToolbarModule {}

downgradeComponents(ToolbarModule, [
	{
		name: 'vbToolbar',
		component: ToolbarComponent
	}
]);
