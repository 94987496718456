import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { WebcastModel } from 'rev-portal/scheduledEvents/webcast/model/WebcastModel';

import { Question, QuestionAction, QuestionStatus } from './Question';
import { WebcastViewQuestionsService } from './WebcastViewQuestions.Service';

import styles from './VbWebcastManageQuestionSidebar.Component.module.less';
import { orderBy } from 'rev-shared/util/SortUtil';

enum Question_View {
	ALL = 'all',
	Inbox = 'inbox',
	Queue = 'queue',
	Closed = 'closed'
}

const questionNumber = 'questionNumber';
const whenModified = 'whenModified';

@Component({
	selector: 'vb-webcast-manage-question-sidebar',
	templateUrl: './VbWebcastManageQuestionSidebar.Component.html',
	host: {
		'[class]': 'styles.root'
	}
})
export class VbWebcastManageQuestionSidebarComponent implements OnDestroy, OnInit {
	@Input() public webcast: WebcastModel;

	public readonly styles = styles;
	public readonly Question_View = Question_View;
	public readonly QuestionAction = QuestionAction;

	public selectedView: Question_View;
	public qTextFilter: string = '';
	public closedQuestionsFilter: QuestionAction = QuestionAction.All;
	public closedQuestionsFilterLabel: string;
	public questions: Question[];
	public inboxQuestions: Question[];
	public queuedQuestions: Question[];
	public closedQuestions: Question[];
	public questionCounts: { [key: string]: number };

	private sub: Subscription;

	constructor(
		private WebcastViewQuestionsService: WebcastViewQuestionsService
	) {}

	public ngOnInit(): void {
		this.selectedView = Question_View.Inbox;
		this.WebcastViewQuestionsService.subscribeModeratorHandlers(this.webcast.id, this.webcast.currentRun.runNumber);
		this.sub = this.WebcastViewQuestionsService.moderatorQuestions$
			.subscribe( questions => {
				this.questions = questions;
				this.questionCounts = this.getQuestionCounts(questions);
				this.updateFilters();
			});
	}

	public ngOnDestroy(): void {
		this.WebcastViewQuestionsService.unsubscribeHandlers();
		this.sub?.unsubscribe();
	}

	private getQuestionCounts(questions: Question[]): { [key: string]: number } {
		const questionCount = {
			hidden: 0,
			[QuestionStatus.Inbox]: 0,
			[QuestionStatus.Queued]: 0,
			[QuestionStatus.Closed]: 0
		};

		questions.forEach(question => {
			if(question.isHidden){
				questionCount.hidden++;
			}
			questionCount[question.status]++;
		});
		return questionCount;
	}

	public showNewQuestions(): void {
		this.WebcastViewQuestionsService.showModeratorHiddenQuestions();
	}
	public onQTextFilterChange(): void {
		this.updateFilters();
	}

	public filterClosedQuestions(filter: QuestionAction, label: string): void {
		this.closedQuestionsFilter = filter;
		this.closedQuestionsFilterLabel = label;
		this.updateFilters();
	}

	private updateFilters(): void {
		const filterTxt = this.qTextFilter?.toLowerCase();
		const filter = (q: Question) =>
			q.questionText.toLowerCase().includes(filterTxt);

		this.inboxQuestions = this.questions.filter(q => q.status === QuestionStatus.Inbox &&
			!q.isHidden &&
			filter(q));
		this.inboxQuestions = orderBy(this.inboxQuestions, q => q[whenModified], true);

		this.queuedQuestions = this.questions.filter(q => q.status === QuestionStatus.Queued && filter(q));

		this.closedQuestions = this.questions.filter(q =>
			q.status === QuestionStatus.Closed &&
			(this.closedQuestionsFilter === QuestionAction.All ||
				this.closedQuestionsFilter === QuestionAction.Closed && !q.lastAction ||
				this.closedQuestionsFilter === q.lastAction) &&
			filter(q));
		this.closedQuestions = orderBy(this.closedQuestions, q => q[questionNumber], false);
	}
}
