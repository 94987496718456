import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
//Stores height and other layout info of main nav header to be used in layout calculations elsewhere.
export class MainHeaderPositionService {
	private heightSubject$ = new BehaviorSubject<number>(undefined);
	public readonly height$: Observable<number> = this.heightSubject$.asObservable();

	public isTransparencyActive: boolean;
	public isTransparencySupported: boolean;

	public get height(): number {
		return this.heightSubject$.getValue();
	}

	public get layoutHeight(): number {
		return this.isTransparencyActive ? 0 : this.height;
	}

	public setHeight(h: number): void {
		this.heightSubject$.next(h);
	}
}
