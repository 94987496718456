import {
	IPromise,
	copy,
	resource,
} from 'angular';
import { Injectable } from '@vbrick/angular-ts-decorators';
import { object as _object, sortBy } from 'underscore';

import { UserAccountService } from 'rev-shared/security/UserAccount.Service';
import { UserContextService } from 'rev-shared/security/UserContext.Service';

import { IVideoField, IRegistrationField } from './Metadata.Contract';

@Injectable('MetadataService')
export class MetadataService{
	private readonly videoFieldsResource: resource.IResourceClass<resource.IResource<any>>;
	private readonly registrationFieldsResource: resource.IResourceClass<resource.IResource<any>>;

	private readonly videoFields: { [ accountId: string]: IVideoField[] };
	private readonly registrationFields: { [ accountId: string]: IRegistrationField[] };

	constructor(
		$resource: resource.IResourceService,
		private UserAccount: UserAccountService,
		private UserContext: UserContextService
	) {
		'ngInject';

		this.videoFieldsResource = $resource('/media/accounts/:accountId/video-fields');
		this.registrationFieldsResource = $resource('/scheduled-events/accounts/:accountId/webcast-registration-fields');
		this.videoFields = {};
		this.registrationFields = {};
	}

	public get currentAccountVideoFields(): IVideoField[] {
		return this.videoFields[this.UserAccount.currentAdminAccount.id];
	}

	public get userContextVideoFields(): IVideoField[]{
		return copy(this.videoFields[this.UserContext.getAccount().id]);
	}

	public get currentAccountRegistrationFields(): IRegistrationField[] {
		return this.registrationFields[this.UserAccount.currentAdminAccount.id];
	}

	public getAccountVideoFields(accountId: string): IVideoField[] {
		return this.videoFields[accountId];
	}

	public getAccountRegistrationFields(accountId: string): IRegistrationField[] {
		return this.registrationFields[accountId];
	}

	public getVideoField(id: string, accountId?: string): IVideoField{
		if(!accountId){
			accountId = this.UserAccount.currentAdminAccount.id;
		}

		const field = (this.videoFields[accountId] || []).find(field => field.id === id);

		return copy(field);
	}

	public loadAccountVideoFields(accountId: string): IPromise<void>{
		return this.videoFieldsResource.get({ accountId }).$promise
			.then(result => {
				this.videoFields[accountId] = result.videoFields || [];
			});
	}

	public getRegistrationField(id: string, accountId: string): IRegistrationField{
		const field = (this.registrationFields[accountId] || []).find(field => field.id === id);

		return copy(field);
	}

	public loadAccountRegistrationFields(accountId: string): IPromise<void>{
		return this.registrationFieldsResource.get({ accountId }).$promise
			.then(result => {
				const fields = result.webcastRegistrationFields || [];
				this.registrationFields[accountId] = sortBy(fields, f => -f.includeInAllWebcasts);
			});
	}

	public unloadFields(accountId: string): void{
		delete this.registrationFields[accountId];
		delete this.videoFields[accountId];
	}
}
