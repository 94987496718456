import { Component } from '@vbrick/angular-ts-decorators';

import { ThemeService } from 'rev-portal/branding/Theme.Service';
import { TeamService } from 'rev-shared/team/Team.Service';

import { MediaMenuDataManagerDirective } from './MediaMenuDataManager.Directive';

import './media-menu.less';

@Component({
	selector: 'media-menu',
	templateUrl: '/partials/portal/navigation/media-menu.html',
	require: {
		mediaMenuDataManager: '^^mediaMenuDataManager'
	}
})
export class MediaMenuComponent {
	private mediaMenuDataManager: MediaMenuDataManagerDirective;

	constructor(
		private ThemeService: ThemeService,
		private TeamService: TeamService
	) {
		'ngInject';
	}

	public get themeAccentColor() {
		return this.ThemeService.brandingSettings.themeSettings.accentColor;
	}
}
