import { StateProvider, UrlRouterProvider } from '@uirouter/angularjs';

import { IPortalStartup } from './PortalStartup.Provider';
import { configurePortalBaseState } from './PortalBaseState.Config';

import { IVBrickStateDeclaration } from 'rev-shared/ts-utils/IVBrickStateDeclaration';
import { PasswordResetResolve } from 'rev-shared/security/components/PasswordReset.Component';
import { SecurityContextService } from 'rev-shared/security/SecurityContext.Service';
import { UserConfirmationResolve } from 'rev-shared/security/UserConfirmationResolve';
import { configureCommonSecurityStates } from 'rev-shared/security/components/SecurityComponentsState.Config';
import { PasswordResetComponent } from 'rev-shared/security/components/PasswordReset.Component';
import { UserConfirmationComponent } from 'rev-shared/security/components/UserConfirmation.Component';

export function configureStates($stateProvider: StateProvider, $urlRouterProvider: UrlRouterProvider) {

	const initializationResolve = {
		PortalStartupResolve(PortalStartup: IPortalStartup) {
			'ngInject';

			return PortalStartup.$promise;
		},
		SecurityContextStartup(SecurityContext: SecurityContextService){
			'ngInject';

			return SecurityContext.$promise;
		}
	};

	$urlRouterProvider.otherwise('/');

	configureCommonSecurityStates(
		initializationResolve,
		$stateProvider
	);

	configurePortalBaseState($stateProvider, 'portal', true, initializationResolve);

	$stateProvider
		.state('sso-invalid-credentials', {
			url: '/sso-invalid-credentials',
			templateUrl: '/partials/sso-invalid-credentials.html',
			resolve: initializationResolve,
			allowAllAccess: true
		} as IVBrickStateDeclaration)
		.state('sso-invalid-license', {
			url: '/sso-invalid-license',
			templateUrl: '/partials/sso-invalid-license.html',
			resolve: initializationResolve,
			allowAllAccess: true
		} as IVBrickStateDeclaration)
		.state('sso-invalid-login', {
			url: '/sso-invalid-login',
			templateUrl: '/partials/sso-invalid-login.html',
			resolve: initializationResolve,
			allowAllAccess: true
		} as IVBrickStateDeclaration)
		.state('user-confirmation', {
			url: '/user-confirmation/:token',
			resolve: Object.assign({}, initializationResolve, UserConfirmationResolve),
			component: UserConfirmationComponent as any,
			allowAllAccess: true
		} as IVBrickStateDeclaration)
		.state('reset-password', {
			url: '/reset-password/:token',
			component: PasswordResetComponent as any,
			resolve: Object.assign({}, initializationResolve, PasswordResetResolve),
			allowAllAccess: true

		} as IVBrickStateDeclaration);
}
