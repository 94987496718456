import {
	Directive,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	SimpleChanges
} from '@vbrick/angular-ts-decorators';
import {
	IAugmentedJQuery,
	IDocumentService,
	IWindowService
} from 'angular';

import { MainHeaderPositionService } from './MainHeaderPosition.Service';

@Directive({
	selector: '[transition-background]'
})
export class TransitionBackgroundDirective implements OnDestroy, OnChanges {
	@Input('<transitionBackground') public isEnabled: boolean;
	@Input('<transitionBackgroundClass') public bgClass: string;

	private handler: () => void;
	private isBackgroundApplied: boolean;

	constructor(
		private $element: IAugmentedJQuery,
		private $window: IWindowService,
		private MainHeaderPosition: MainHeaderPositionService
	) {
		'ngInject';
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if(!changes.isEnabled){
			return;
		}

		if(this.isEnabled){
			this.tryAddScrollHandler();
		}
		else{
			this.tryRemoveScrollHandler();
		}

		this.updateBgClass();
	}

	public ngOnDestroy(): void {
		this.tryRemoveScrollHandler();
	}

	public tryAddScrollHandler(): void {
		if(!this.handler){
			this.handler = () => this.updateBgClass();
			this.$window.addEventListener(
				'scroll',
				this.handler,
				true
			);
		}
	}

	public tryRemoveScrollHandler(): void {
		if(this.handler){
			this.$window.removeEventListener(
				'scroll',
				this.handler,
				true
			);
			this.handler = null;
		}
	}

	private updateBgClass(): void {
		const isScrolledDown = this.$window.pageYOffset > this.MainHeaderPosition.height;
		const applyBg = isScrolledDown || !this.isEnabled;
		if(this.isBackgroundApplied !== applyBg){
			this.isBackgroundApplied = applyBg;
			this.$element.toggleClass(this.bgClass, applyBg);
		}
	}

}
