<ng-template [vbCssRules]="themeStyleOverrides"></ng-template>

<div class="scrollable-area-wrapper padding-top-15" [ngClass]="{'has-items':unreadNotifications.length}">
	<ul class="media-list scrollable-area">

		<li class="media" (click)="markAsRead(notification.id)" *ngFor="let notification of unreadNotifications | slice:0:10">
			<vb-notification [notification]="notification"></vb-notification>
		</li>

		<li *ngIf="!unreadNotifications.length" class="no-notifications-msg">

			<h4>{{ 'Notifications_NoNotifications' | translate }}</h4>
			<a class="theme-accent-btn" uiSref="portal.user-notifications" (click)="contentClosed()">
				{{ 'Notifications_SeeAllNotifications' | translate }}
			</a>

		</li>
	</ul>
</div>

<div class="menu-footer group" [hidden]="!(unreadNotifications.length)">
	<a *ngIf="allNotifications.length !== 0" (click)="markAllAsRead()" class="theme-accent-txt pull-left">{{ 'Notifications_MarkAllAsRead' | translate }}</a>
	<span *ngIf="allNotifications.length === 0" class="theme-accent-txt pull-left">{{ 'Notifications_MarkAllAsRead' | translate }}</span>
	<a uiSref="portal.user-notifications" (click)="contentClosed()" class="theme-accent-txt pull-right">{{ 'Notifications_SeeAllNotifications' | translate }}</a>
</div>
