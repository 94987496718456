import { NgModule } from '@vbrick/angular-ts-decorators';

import { HlsUtilService } from 'vbrick-player-src/HlsUtil.Service';
import { SubtitleService } from 'vbrick-player-src/Subtitle.Service';
import { SupportedPlaybacksService } from 'vbrick-player-src/SupportedPlaybacks.Service';
import { VBrickPlayerComponent } from 'vbrick-player-src/VbrickPlayer.Component';

import { downgradeInjectables, downgradeComponents } from 'rev-shared/util/AngularHybridUtils';

import { VideoPlayerAdapterService } from './VideoPlayerAdapter.Service';
import { VBrickPlayerWmvComponent } from './VbrickPlayerWmv.Component';

@NgModule({
	id: 'VBrick.VideoPlayer'
})
export default class VBrickSharedVideoPlayerModule {}

downgradeComponents(VBrickSharedVideoPlayerModule, [
	{
		name: 'vbrickPlayer',
		component: VBrickPlayerComponent
	},
	{
		name: 'vbrickPlayerWmv',
		component: VBrickPlayerWmvComponent
	}
]);

downgradeInjectables(VBrickSharedVideoPlayerModule, [
	{
		name: 'HlsUtilService',
		injectable: HlsUtilService
	},
	{
		name: 'SubtitleService',
		injectable: SubtitleService
	},
	{
		name: 'SupportedPlaybacks',
		injectable: SupportedPlaybacksService
	},
	{
		name: 'VideoPlayerAdapter',
		injectable: VideoPlayerAdapterService
	}
]);
