import {
	Component,
	Input,
	ContentChildren,
	QueryList,
	ElementRef,
	AfterViewInit,
	AfterContentInit
} from '@angular/core';

import styles from './vb-ui-toggle-content-display.module.less';

/**
 * This component is used to show "show more/show less" button with toggling of content display.
 * It can be configured in two ways.
 * -------------------------------------------------------
 * To limit by number of items:
 * <vb-ui-toggle-content-display
			min-items="4"
			<div *ngFor="..."
				vbUiToggleItem>
				...
			</div>
 *	</vb-ui-toggle-content-display>
 *-----------------------------------------------------------
 * 2. to limit size by height:
 * <vb-ui-toggle-content-display
		[minHeight]="200">
		<div vbUiToggleItem>
			...
		</div>
	</vb-ui-toggle-content-display>
 */

@Component({
	selector: 'vb-ui-toggle-content-display',
	template: `
		<div [ngClass]="styles.contentWrapper"
			[style.maxHeight]="heightStyle"
			[style.overflow]="overflowStyle">
			<ng-content></ng-content>
		</div>

		<div
			[ngClass]="styles.toggleBtnWapper"
			*ngIf="showToggle">
			<button type="button"
				[ngClass]="styles.toggleBtn"
				(click)="onToggleClick()"
				vbUiBtnPrimary>
				<span>
					{{ (isExpanded ? 'ShowLess' : 'ShowMore') | translate }}
					<span class="glyphicons"
						[ngClass]="isExpanded ? 'up_arrow' : 'down_arrow'">
					</span>
				</span>
			</button>
		</div>
	`
})
export class VbUiToggleContentDisplayComponent implements AfterContentInit {
	@Input() private minHeight: number;
	@Input() private minItems: number;
	@Input() private totalItems: number;

	public readonly styles = styles;

	public isExpanded: boolean = false;
	public overflowStyle: string = 'visible';
	public heightStyle: string = 'auto';

	@ContentChildren('vbUiToggleItem')
	public contentItems: QueryList<ElementRef>;

	public ngAfterContentInit(): void {
		this.updateDisplay();
	}

	public onToggleClick(): void {
		this.isExpanded = !this.isExpanded;
		this.updateDisplay();
	}

	private updateDisplay(): void {
		if(this.minItems) {
			this.contentItems
				.map(i => i.nativeElement as HTMLElement)
				.forEach((el, i) => el.classList.toggle('ng-hide', this.isExpanded && i < this.minItems));
		}
		else if(this.isExpanded){
			this.overflowStyle = 'visible';
			this.heightStyle = 'none';
		}
		else {
			this.overflowStyle = 'hidden';
			this.heightStyle = this.minHeight + 'px';
		}
	}

	public get showToggle(): boolean {
		const el = this.contentItems.first?.nativeElement;

		return this.minHeight
			? el?.scrollHeight > this.minHeight
			: this.totalItems > this.minItems;
	}
}
