import {
	Component,
	Input
} from '@angular/core';

import { UserNotificationsService } from 'rev-portal/notifications/UserNotifications.Service';

import './NotificationsMenuButton.less';

@Component({
	selector: 'notifications-menu-button',
	templateUrl: './NotificationsMenuButton.Component.html'
})
export class NotificationsMenuButtonComponent {
	@Input() public alwaysShowIcon: boolean;
	@Input() public dropdownCssClass: string;
	@Input() public glyphiconCssClass: string;
	@Input() public showRemove: boolean;

	constructor(
		private UserNotifications: UserNotificationsService
	) {
	}

	public get isHigh(): boolean {
		return this.notificationLevel > 0 && this.unreadCount > 0;
	}

	public get notificationCount(): number | string {
		return this.unreadCount < 1000 ? this.unreadCount : '999+';
	}

	public get notificationLevel(): number {
		const n = this.UserNotifications.unreadNotifications[0];
		return n ? n.priorityNumber : null;
	}

	public get unreadCount(): number {
		return this.UserNotifications.unreadNotifications.length;
	}
}
