import { Component, Input } from '@vbrick/angular-ts-decorators';
import {
	IQService,
	IPromise,
	IFormController
} from 'angular';
import { StateService, TransitionPromise } from '@uirouter/angularjs';

import { FileUtil } from 'rev-shared/util/FileUtil';
import { TeamRoleType, Team, StatusHandler } from './Team.Contract';
import { TeamService } from './Team.Service';

import './manage-team.less';
import { InsightSearchHelper } from '../../portal/search/InsightSearchHelper.Service';
import { IUserContextUser, UserContextService } from 'rev-shared/security/UserContext.Service';


@Component({
	selector: 'manage-team',
	templateUrl: '/partials/admin/user-access/teams/manage-team.html'
})
export class ManageTeamComponent {
	@Input() public accountId: string;
	@Input() public previousState: { name: string; params: any };
	@Input() public portalTeamAdminAccess: boolean;
	@Input() public teamId: string;

	public addingImage: boolean;
	public image: any;
	public imageUrl: string;
	public statusHandler: StatusHandler;
	public team: Team;
	public teamForm: IFormController;
	public teamInsightOptions: any;

	private isCreateMode: boolean;
	private readonly primaryThemeColor: string = '#00a0f0';
	private imageFileExtensions = FileUtil.imageFileExtensions;

	constructor(
		private $q: IQService,
		private $state: StateService,
		private TeamService: TeamService,
		private InsightSearchHelper: InsightSearchHelper,
		public UserContext: UserContextService,
	) {
		'ngInject';

		this.team = this.tryGetTeam(this.teamId);
		this.isCreateMode = !this.teamId;
		this.portalTeamAdminAccess = !this.previousState ? true : this.portalTeamAdminAccess;
		this.setInsightOptions();
		this.loadData();
		this.statusHandler = new StatusHandler();
		this.statusHandler.setActiveStatus();
	}

	private tryGetTeam(teamId: string): Team {
		if (!teamId) {
			return new Team(this.accountId,
				this.TeamService.isSetToDefaultAsTeamAdmin() ? this.UserContext.getUser().id : null);
		}

		return this.TeamService.teams[teamId];
	}

	public get defaultColor(): string {
		return this.primaryThemeColor;
	}

	public createThenReset(): void {
		this.submitTeam().then(() => {
			this.image = null;
			this.imageUrl = null;
			this.statusHandler.setActiveStatus();
			this.team = new Team(this.accountId,
				this.TeamService.isSetToDefaultAsTeamAdmin() ? this.UserContext.getUser().id : null);
			this.teamForm.name.$setPristine();
		});
	}

	public setLogoImageFile({ file }): IPromise<any> {
		this.image = {
			name: file.name,
			progress: 0,
			status: { uploading: true },
			file
		};

		this.teamForm.logoImageFileUpload.$setViewValue(file.name); //force dirty flag to be set.
		this.addingImage = true;

		return file.getImageUrl()
			.then(result => this.imageUrl = result )
			.finally(() => {
				this.addingImage = false;
				this.validateLogo(file);
			});
	}

	public removeLogoImage(): void {
		Object.assign(this.team, {
			thumbnailUri: null,
			imageId: null
		});

		this.image = null;
		this.imageUrl = null;
	}

	public submit(): void {
		this.submitTeam().then(() => this.redirectToPreviousState());
	}

	public cancel(): void {
		this.redirectToPreviousState();
	}

	private submitTeam(): IPromise<any> {
		this.statusHandler.setLoadingStatus();

		return this.tryToUploadImage()
			.then(imgId => this.initiateTeamSave(imgId));
	}

	private initiateTeamSave(imgId: string): IPromise<any> {
		this.team.imageId = imgId;
		this.team.teamMembers = this.pluckTeamMembersFromBinding();
		return (this.isCreateMode ?
			this.TeamService.createTeam(this.team) :
			this.TeamService.saveTeam(this.team))
			.catch(err => this.handleError(err));
	}

	public onDeleteTeam(): void {
		this.team.deleteConfirmation.open().result
			.then(() => {
				this.statusHandler.setLoadingStatus();
				this.TeamService.removeTeam(this.accountId, this.team.id)
					.then(() => {
						if (this.previousState) {
							return this.$state.go('portal.team');
						}
						return this.$state.go('portal.admin.user-access.teams');
					})
					.catch(err => this.statusHandler.setErrorStatus())
					.finally(() => this.statusHandler.setActiveStatus());
			});
	}

	private loadData(): void {
		if(this.isCreateMode) {
			return;
		}

		this.team.accessEntities = (this.team.teamMembers || []).map(member => ({
			id: member.id,
			type: member.type,
			rolesState: Team.createRolesState(member.roleTypes)
		}));

		this.imageUrl = this.team.thumbnailUri;
	}

	private tryToUploadImage(): IPromise<any> {
		if (!this.image) {
			return this.$q.resolve(this.team.imageId);
		}
		return this.TeamService.uploadLogo(this.accountId, this.team.id, this.image.file);
	}

	private pluckTeamMembersFromBinding(): any[] {
		return (this.team.accessEntities.map(x => ({
			id: x.id,
			type: x.type,
			rolesState: x.rolesState
		})).filter(Boolean));
	}

	private setInsightOptions(): void {
		this.teamInsightOptions = this.InsightSearchHelper.buildAccessEntityInsightOptions({
			accountId: this.accountId,
			groups: true,
			users: true,
			getEntityIds: () => (this.team.teamMembers || []).map(e => e.id),
			mapQueryPage: page => ({
				items: page.items.map(
					entity => this.assignDefaultRoleType(entity)
				),
				count: page.count
			})
		});
		this.teamInsightOptions.teamRoleTypes = TeamRoleType;
	}

	private assignDefaultRoleType(accessEntity: any): any {
		if (this.team.accessEntities.some(user => user.id === accessEntity.id)) {
			return accessEntity;
		}

		return {
			...accessEntity,
			rolesState: Team.createRolesState([TeamRoleType.TeamMember])
		};
	}

	private redirectToPreviousState(): TransitionPromise {
		if (this.previousState) {
			return this.$state.transitionTo(this.previousState.name, this.previousState.params);
		}
		return this.$state.go('portal.admin.user-access.teams', { team: this.teamId });
	}

	private validateLogo(file): void {
		this.teamForm.logoImageFileUpload.$setValidity('fileType', !file || file.isImageFile);
	}

	private handleError(err: any): IPromise<never> {
		if(err.hasIssue('TeamNameInUse')) {
			this.statusHandler.setActiveStatus();
			this.teamForm.name.$setValidity('nameConflict', false);
		}
		return this.$q.reject(err);
	}
}
