import { NgModule } from '@vbrick/angular-ts-decorators';

import LegalHoldBulkEditComponent from './LegalHoldBulkEdit.Component';

@NgModule({
	id: 'VBrick.Portal.Navigation.Sidebar.LegalHold',
	declarations: [LegalHoldBulkEditComponent]
})

export default class LegalHoldBulkEditModule {}
