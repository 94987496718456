import { Directive, AfterViewInit, HostListener } from '@vbrick/angular-ts-decorators';
import { INgModelController } from 'angular';
import { parseMeetingId, validateMeetingIdFormat, formatMeetingId } from './ZoomMeetingId';

@Directive({
	selector: '[vb-zoom-meeting-id]',
	bindToController: true,
	require: {
		ngModel: 'ngModel'
	}
})
export class VbZoomMeetingIdDirective implements AfterViewInit {
	private ngModel: INgModelController;

	public ngAfterViewInit(): void {
		this.ngModel.$parsers.push(parseMeetingId);
		this.ngModel.$validators.vbZoomMeetingId = validateMeetingIdFormat;
		this.ngModel.$formatters.push(formatMeetingId);
	}

	@HostListener('blur')
	public onBlur(): void {
		if(this.ngModel.$valid) {
			this.ngModel.$viewValue = formatMeetingId(this.ngModel.$modelValue);
			this.ngModel.$render();
		}
	}
}
