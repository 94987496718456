import { Injectable } from '@vbrick/angular-ts-decorators';

import { VIDEO_SELECTION_LIMIT } from '../MediaState.Service';
/**
 * Tracks selection for dynamically loaded videos
 *
 * Lazily evaluates selection, working as new videos load in.
 */
@Injectable('VideoSelectionModel')
export class VideoSelectionModelService {
	private readonly selectionLimit: number = VIDEO_SELECTION_LIMIT;

	private _totalVideos: number;
	private _isSelectAll: boolean;
	private numSelected: number;
	private numUnselected: number;
	private selections: {[videoId: string]: boolean};
	private totalLegalHoldVideos: number;
	private unselections: {[videoId: string]: boolean};
	private videos: any[];

	public get allSelectedVideoInLegalHold(): boolean {
		if (this._isSelectAll) {
			return this._totalVideos === this.totalLegalHoldVideos;
		}

		if (this.selectionCount > 0) {
			return this.selectedVideos.every(video => video.legalHold);
		}

		return false;
	}

	public get limit(): number {
		return this.selectionLimit;
	}

	public get hasMixedLegalHoldSelected(): boolean {
		const selectionCount = this.selectionCount;

		if (this._isSelectAll) {
			const selectedLegalHoldCount = this.totalLegalHoldVideos - this.unselectedLegalHoldCount;
			const selectedNonLegalHoldCount = selectionCount - selectedLegalHoldCount;

			return selectionCount > 0
				&& selectedLegalHoldCount > 0
				&& selectedNonLegalHoldCount > 0;
		}

		return selectionCount > 0
			&& this.selectedVideos.some(video => video.legalHold)
			&& this.selectedVideos.some(video => !video.legalHold);
	}

	private get unselectedLegalHoldCount(): number {
		return (Object.entries(this.unselections) || [])
			.filter(item => item[1])
			.filter(item => this.videos.find(video => video.id === item[0] && video.legalHold))
			.length;
	}

	public get isSelectAll(): boolean {
		return this._isSelectAll;
	}

	public get selectedVideos(): any[] {
		if (this._isSelectAll) {
			return this.videos.filter(video => !this.unselections[video.id]);
		}

		return this.videos.filter(video => this.selections[video.id]);
	}

	public get selectionCount(): number {
		return this._isSelectAll ?
			this._totalVideos - this.numUnselected :
			this.numSelected;
	}

	public get selectionCountValid(): boolean {
		return this.selectionCount <= this.selectionLimit;
	}

	public get totalVideos(): number {
		return this._totalVideos;
	}

	public deselectAll(): void {
		this._isSelectAll = false;
		this.resetSelections();
	}

	public isSelected(video: any) {
		return this._isSelectAll ?
			!this.unselections[video.id] :
			this.selections[video.id];
	}

	public reset(): void {
		this._isSelectAll = false;
		this.setVideos();
		this.resetSelections();
	}

	public setLegalHoldVideos(totalVideos: number): void {
		this.totalLegalHoldVideos = totalVideos;
	}

	public setVideos(videos?: any[], totalVideos?: number): void {
		this.videos = videos;
		this._totalVideos = totalVideos;
	}

	public toggleSelectAll(): void {
		this._isSelectAll = !this._isSelectAll;
		this.resetSelections();
	}

	public toggleSelection(video: any): void {
		if (this._isSelectAll) {
			const unselected: boolean = this.unselections[video.id] = !this.unselections[video.id];
			this.numUnselected += unselected ? 1 : -1;

		} else{
			const selected: boolean = this.selections[video.id] = !this.selections[video.id];
			this.numSelected += selected ? 1 : -1;
		}
	}

	private resetSelections(): void {
		this.selections = {};
		this.numSelected = 0;
		this.unselections = {};
		this.numUnselected = 0;
	}
}
