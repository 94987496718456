import { EventEmitter } from 'events';

export const WEBCAST_SIDEBAR_NOTIFICATION_EVENT = 'webcastNotificationChanged';

export class WebcastSidebarNotification extends EventEmitter {
	private _isEnabled = true;
	private _notificationCount = 0;

	public get isEnabled(): boolean {
		return this._isEnabled;
	}

	public set isEnabled(isEnabled: boolean) {
		this._isEnabled = !!isEnabled;

		if (!this._isEnabled) {
			this.clear();
		}
	}

	public get notificationCount(): number {
		return this._notificationCount;
	}

	public clear(): void {
		this._notificationCount = 0;
		this.emit(WEBCAST_SIDEBAR_NOTIFICATION_EVENT);
	}

	public addNotification(): void {
		if (this.isEnabled) {
			++this._notificationCount;
			this.emit(WEBCAST_SIDEBAR_NOTIFICATION_EVENT);
		}
	}
}
