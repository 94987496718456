import { Injectable } from '@vbrick/angular-ts-decorators';
import { equals } from 'angular';
import { Observable, Subject } from 'rxjs';

import { SearchQueryBuilder } from 'rev-portal/search/SearchQueryBuilder';

import { Filter } from './SearchFilterTypes';
import { SearchFilterDefinitionsService } from './SearchFilterDefinitions.Service';

export interface ISearchFilters {
	[key: string]: Filter;
}

@Injectable('SearchFilterState')
export class SearchFilterStateService {
	public change$: Observable<ISearchFilters>;
	public filters: ISearchFilters;

	private readonly changeSubject$: Subject<ISearchFilters> = new Subject<ISearchFilters>();

	constructor(
		private SearchFilterDefinitions: SearchFilterDefinitionsService
	) {
		'ngInject';

		this.change$ = this.changeSubject$.asObservable();
	}

	public buildQuery(queryBuilder?: SearchQueryBuilder): string {
		queryBuilder = queryBuilder || new SearchQueryBuilder();

		this.forEachFilter(filter => filter.addToQuery(queryBuilder));

		return queryBuilder.buildQuery();
	}

	public clear(notify: boolean = true): void {
		this.forEachFilter(filter => filter.clear());

		if (notify) {
			this.changeSubject$.next(this.filters);
		}
	}

	public clearAllOverrides(): void {
		this.forEachFilter(filter => filter.clearValueOverride());
	}

	public clone(): ISearchFilters {
		const clone = {};

		this.forEachFilter((field, key) => clone[key] = field.clone());

		return clone;
	}

	public initialize(): void {
		this.filters = this.SearchFilterDefinitions.getStaticFilters();
	}

	public update(filterValues?: any): void {
		if (filterValues) {
			this.forEachFilter((filter, key) => {
				const filterValue = filterValues[key];

				if (!equals(filterValue.value, filter.value)) {
					filter.update(filterValues[key]);
				}
			});
		}

		this.changeSubject$.next(this.filters);
	}

	private forEachFilter(fn: (field: Filter, key: string) => any): void {
		if (this.filters) {
			Object.keys(this.filters)
				.forEach(key => fn(this.filters[key], key));
		}
	}
}
