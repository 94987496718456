import { Component, Input } from '@angular/core';

import { SpinnerState } from 'rev-shared/ui/spinner/VbLoadingSpinner.Component';

import { WebcastModel } from '../model/WebcastModel';
import styles from './LiveStreamStatus.Component.module.less';

@Component({
	selector: 'live-stream-status',
	templateUrl: './LiveStreamStatus.Component.html',
	host: {
		'[class]': 'styles.root'
	}

})
export class LiveStreamStatusComponent {
	@Input() public webcast: WebcastModel;

	public SpinnerState = SpinnerState;

	public readonly styles = styles;
}
