const LEGEND_ITEM_SIZE: number = 12;
const LEGEND_MARKER_CORNER_RADIUS: number = 12;

export function getCommonLegendConfig() {
	return {
		position: 'top',
		contentAlign: 'right',
		fontSize: '.75rem',
		useDefaultMarker: true,
		markers: {
			width: LEGEND_ITEM_SIZE,
			height: LEGEND_ITEM_SIZE,
			children: [{
				cornerRadiusTopLeft: LEGEND_MARKER_CORNER_RADIUS,
				cornerRadiusTopRight: LEGEND_MARKER_CORNER_RADIUS,
				cornerRadiusBottomLeft: LEGEND_MARKER_CORNER_RADIUS,
				cornerRadiusBottomRight: LEGEND_MARKER_CORNER_RADIUS
			}]
		}
	};
}

