import { NgModule } from '@vbrick/angular-ts-decorators';

import { MainHeaderComponent } from './MainHeader.Component';
import { UploadMenuComponent } from '../uploadMenu/UploadMenu.Component';

import { TransitionBackgroundDirective } from './TransitionBackground.Directive';

import HelpModule from 'rev-portal/navigation/help/Help.Module';
import MobileMenuModule from 'rev-portal/navigation/mobileMenu/MobileMenu.Module';
import SearchHeaderModule from 'rev-portal/navigation/searchHeader/SearchHeader.Module';
import { ProfileModule } from 'rev-shared/ui/profile/Profile.Module';
import { SharedScheduledEventsModule } from 'rev-shared/scheduledEvents/ScheduledEvents.Module';
import { downgradeComponents, downgradeInjectables } from 'rev-shared/util/AngularHybridUtils';

import { MainHeaderPositionService } from './MainHeaderPosition.Service';

@NgModule({
	id: 'VBrick.Portal.Navigation.Header',
	declarations: [
		MainHeaderComponent,
		TransitionBackgroundDirective
	],
	imports: [
		HelpModule,
		MobileMenuModule,
		ProfileModule,
		SearchHeaderModule,
		SharedScheduledEventsModule
	]
})

export default class MainHeaderModule {}

downgradeInjectables(MainHeaderModule, [{
	name: 'MainHeaderPosition',
	injectable: MainHeaderPositionService
}]);

downgradeComponents(MainHeaderModule, [{
	name: 'uploadMenu',
	component: UploadMenuComponent
}]);
