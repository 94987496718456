import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';

import { FeaturedVideosModule } from 'rev-portal/dashboard/featuredVideos/FeaturedVideos.Module';
import { CarouselsModule } from './carousels/Carousels.Module';
import { PortalDashboardContentComponent } from './PortalDashboardContent.Component';
import { SearchAngularModule } from 'rev-portal/search/Search.AngularModule';
import { ng2StatesToHybridStates } from 'rev-shared/uiRouterUtils/HybridRouterUtils';

import { PortalDashboardComponent } from './PortalDashboard.Component';
import { PortalDashboardSidebarComponent } from './PortalDashboardSidebar.Component';
import { WidgetModule } from './sidebarWidgets/Widget.Module';

import { states } from './Dashboard.StateConfig';


@NgModule({
	imports: [
		CommonModule,
		FeaturedVideosModule,
		CarouselsModule,
		SearchAngularModule,
		UIRouterModule.forChild({
			states: ng2StatesToHybridStates(states)
		}),
		WidgetModule
	],
	declarations: [
		PortalDashboardContentComponent,
		PortalDashboardSidebarComponent,
		PortalDashboardComponent
	],

	entryComponents: [
		PortalDashboardComponent
	]
})
export class DashboardModule {
}
