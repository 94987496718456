import { NgModule } from '@vbrick/angular-ts-decorators';

import { VbCenteredSpinnerComponent } from './VbCenteredSpinner.Component';
import { VbLoadingSpinnerComponent } from './VbLoadingSpinner.Component';

import { downgradeComponents } from 'rev-shared/util/AngularHybridUtils';

@NgModule({
	id: 'VBrick.UI.Spinner'
})
export default class VBrickUiSpinnerModule {}

downgradeComponents(VBrickUiSpinnerModule, [
	{
		component: VbLoadingSpinnerComponent,
		name: 'vbLoadingSpinner'
	},
	{
		component: VbCenteredSpinnerComponent,
		name: 'vbCenteredSpinner'
	}
]);
