import {
	Component,
	Input,
	Output
} from '@vbrick/angular-ts-decorators';
import { IInsightOptions } from '@vbrick/angular-insight/src/Insight.Contract';

@Component({
	selector: 'vb-ui-insight',
	template: `
		<vb-insight
			insight-options="$ctrl.insightOptions"
			templateUrl="$ctrl.templateUrl"
			ng-model="$ctrl.ngModel"
			ng-change="$ctrl.valueChanged($ctrl.ngModel)"
			option-template-url="$ctrl.optionTemplateUrl"
			assigned-option-template-url="$ctrl.assignedOptionTemplateUrl">
		</vb-insight>
	`
})

export class VbUiInsightUpgradeComponent {
	@Input() public assignedOptionTemplateUrl: string;
	@Input() public insightOptions: IInsightOptions;
	@Input() public optionTemplateUrl: string;
	@Input() public templateUrl: string;
	@Input() public ngModel: any[];

	@Output() public valueChanged: any;
}
