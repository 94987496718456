<widget-wrapper class="text-align-center" [headerText]="'Dashboard_Widget_Events' | translate" [headerLink]="'portal.scheduledEvents'">

	<vb-loading-spinner [block]="true" [size]="'large'" *ngIf="isFetching; else ready">
	</vb-loading-spinner>

	<ng-template #ready>
		<section class="text-align-center">
			<div class="glyphicons calendar" [ngClass]="styles.eventIcon">
			</div>
		</section>
		<section *ngIf="event; else noEvent">
			<div [ngClass]="styles.eventTitle">
				<a uiSref="portal.scheduledEvents.webcast" [uiParams]="{webcastId: event.id}" [title]="event.title" [ngClass]="styles.eventTitleLink">
					{{ event.title }}
				</a>
			</div>
			<div [ngClass]="styles.eventDate">
				{{event.startDate | vbDateTimeMedium}}
			</div>
			<div [ngClass]="styles.eventDescription" [hidden]="!(event.description)" [innerHTML]="event.description">
			</div>
			<div class="padding-top-10 text-align-right">
				<a uiSref="portal.scheduledEvents.webcast" [uiParams]="{webcastId: event.id}" vbUiBtnPrimary>
					{{ 'MoreInfo' | translate }}
				</a>
			</div>
		</section>

		<ng-template #noEvent>
			<span class="control-label padding-top-15">
				{{ 'Dashboard_No_Events' | translate }}
			</span>
		</ng-template>
	</ng-template>
</widget-wrapper>
