import { NgModule } from '@angular/core';
import { UIRouterModule, Transition, StateService } from '@uirouter/angular';
import { once } from 'underscore';

import { ng2StatesToHybridStates } from 'rev-shared/uiRouterUtils/HybridRouterUtils';
import { buildLoaderStateDeclaration } from 'rev-shared/uiRouterUtils/LazyLoaderStateUtil';
import { SpinnerAngularModule } from 'rev-shared/ui/spinner/SpinnerAngular.Module';
import { lazyLoadFromTransition } from 'rev-shared/util/AngularJsLazyLoadHelper.Service';
import { WEBCAST_SHORTCUT_STATE, WEBCAST_ROOT_STATE, WEBCAST_FULLSCREEN_STATE } from './webcast/Constants';

const lazyLoad = once((transition: Transition): Promise<any> =>
	lazyLoadFromTransition(
		transition,
		import(/* webpackChunkName: 'scheduled-events' */ './ScheduledEvents.Module'),
		'ScheduledEventsModule'
	));

@NgModule({
	imports: [
		SpinnerAngularModule,
		UIRouterModule.forChild({
			states: ng2StatesToHybridStates([{
				name: `${WEBCAST_ROOT_STATE}.**`,
				url: '/events',
				lazyLoad
			}, {
				name: `${WEBCAST_FULLSCREEN_STATE}.**`,
				url: '/webcast:webcastId',
				lazyLoad
			},
			buildLoaderStateDeclaration('portal.scheduledEvents', {
				name: 'portal.loadEvents',
				url: '/load-events'
			}), {
				name: WEBCAST_SHORTCUT_STATE,
				url: '/webcasts/:shortcutName',
				redirectTo(transition: Transition) {
					const shortcutName = transition.params().shortcutName as string;
					const $state = transition.injector().get<StateService>('$state');
					return $state.target(
						WEBCAST_ROOT_STATE,
						{ shortcutName },
						{ location: false });
				}
			}])

		})
	]
})

export class ScheduledEventsLoaderModule {
}
