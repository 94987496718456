import {
	Component,
	Input
} from '@vbrick/angular-ts-decorators';
import { IAugmentedJQuery } from 'angular';

import styles from './CollapsableSection.module.less';

@Component({
	selector: 'collapsable-section',
	transclude: true,
	templateUrl: '/partials/ui/collapsable-section.html'
})
export class CollapsableSectionComponent {
	@Input() public expanded: boolean;
	@Input() public headerTitle: string;

	private readonly styles = styles;

	constructor(
		private $element: IAugmentedJQuery
	) {
		'ngInject';

		this.$element.addClass(styles.collapsableSection);
	}

	private toggleExpanded(): void {
		this.expanded = !this.expanded;
	}
}
