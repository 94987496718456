import {
	Component,
	Input,
	OnInit
} from '@vbrick/angular-ts-decorators';

import { INgModelController } from 'angular';

import { ThemeService } from 'rev-portal/branding/Theme.Service';

import './vb-checkbox.less';

@Component({
	selector: 'vb-checkbox',
	require: {
		ngModelController: 'ngModel'
	},
	transclude: true,
	template: `
		<style>
			vb-checkbox button.active {
				border: 1px solid {{ ::$ctrl.accentColor }};
			}
		</style>
		<label class="labelWrapper">
			<button
				role="checkbox"
				aria-checked="{{ !!$ctrl.ngModel }}"
				ng-attr-aria-label="{{ ::$ctrl.accessibilityLabel }}"
				class="theme-accent-bg theme-accent-txt"
				type="button"
				ng-model="$ctrl.ngModel"
				ng-class="{
					active: $ctrl.ngModel
				}"
				ng-change="$ctrl.onChangeInternal()"
				ng-disabled="$ctrl.ngDisabled"
				uib-btn-checkbox>
				<span class="glyphicons ok_2"></span>
			</button>

			<span
				class="vb-checkbox-label"
				ng-transclude>
			</span>
		</label>
	`
})
export class VbCheckboxComponent implements OnInit {
	@Input() public accessibilityLabel: string;
	@Input() public ngDisabled: boolean;
	@Input() public ngModel: boolean;

	private ngModelController: INgModelController;

	constructor(
		private ThemeService: ThemeService
	) {
		'ngInject';
	}

	public ngOnInit(): void {
		// copied from ng checkbox directive. Allows this to play nice with form validation directives
		this.ngModelController.$isEmpty = (val: boolean) => !val;
	}

	private onChangeInternal(): void {
		this.ngModelController.$setViewValue(this.ngModel);
	}

	private get accentColor(): string {
		return this.ThemeService.brandingSettings.themeSettings.accentColor;
	}
}
