import {
	Directive,
	OnInit
} from '@vbrick/angular-ts-decorators';

import { IAugmentedJQuery } from 'angular';

import styles from './vb-ui-btn-primary.module.less';

@Directive({
	selector: '[vb-ui-btn-primary]',
})
export class VbUiBtnPrimaryDirective implements OnInit {
	constructor(
		private $element: IAugmentedJQuery
	) {
		'ngInject';
	}
	public ngOnInit(): void {
		this.$element
			.addClass(`${styles.vbBtnPrimary} theme-accent theme-accent-border`);
	}
}
