<ng-container *ngIf="questions">
	<vb-webcast-ask-question class="width-full" [ngClass]="styles.askQuestionWrapper" [webcast]="webcast">
	</vb-webcast-ask-question>

	<section layout class="margin-top-20 padding-left-16 padding-right-16">
		<div vbUiRadioBtnGroup class="margin-bottom-10">
			<button type="button" [(ngModel)]="selectedView" [vbUiRadioBtn]="Question_View.Inbox">
				{{ 'Event_QA_List_Inbox' | translate }}
			</button>
			<button type="button" [(ngModel)]="selectedView" [vbUiRadioBtn]="Question_View.Queue">
				{{ 'Event_QA_List_Queue' | translate }}
			</button>
			<button type="button" [(ngModel)]="selectedView" [vbUiRadioBtn]="Question_View.Closed">
				{{ 'Event_QA_List_Closed' | translate }}
			</button>
		</div>
		<div *ngIf="selectedView === Question_View.Inbox && questionCounts.hidden" [ngClass]="styles.rightToolSection">
			<!--New Questions button for the Inbox (click shows them)-->
			<button type="button" vbUiBtnPrimaryNgx (click)="showNewQuestions()">
				<span class="glyphicons refresh"></span>
				{{ questionCounts.hidden }}

				<span [ngPlural]="questionCounts.hidden">
					<ng-template ngPluralCase="1">{{ 'Event_QA_IncomingQuestionsCountSingular' | translate }}</ng-template>
					<ng-template ngPluralCase="other">{{ 'Event_QA_IncomingQuestionsCountPlural' | translate }}</ng-template>
				</span>
			</button>
		</div>
		<div *ngIf="selectedView == Question_View.Closed" dropdown [ngClass]="styles.rightToolSection" class="btn-group">
			<button type="button" id="closedQuestionsFilterBtn" vbUiBtnSecondaryNgx dropdownToggle aria-controls="closedQuestionsFilterMenu" class="dropdown-toggle">
				{{ (closedQuestionsFilterLabel || 'All') | translate }}
				<span class="caret"></span>
			</button>

			<ul *dropdownMenu class="dropdown-menu" [ngClass]="styles.closedQuestionsFilterMenu" id="closedQuestionsFilterMenu" role="menu" aria-labelledby="closedQuestionsFilterBtn">
				<li><a (click)="filterClosedQuestions(QuestionAction.All, $event.target.innerText)">{{ 'All' | translate }}</a></li>
				<li><a (click)="filterClosedQuestions(QuestionAction.Answered, $event.target.innerText)">{{ 'Event_QA_Answered' | translate }}</a></li>
				<li><a (click)="filterClosedQuestions(QuestionAction.Declined, $event.target.innerText)">{{ 'Event_QA_Declined' | translate }}</a></li>
				<li><a (click)="filterClosedQuestions(QuestionAction.RepliedDirectly, $event.target.innerText)">{{ 'Event_QA_Closed_Replied' | translate }}</a></li>
				<li><a (click)="filterClosedQuestions(QuestionAction.FollowUp, $event.target.innerText)">{{ 'Event_QA_FollowUp' | translate }}</a></li>
				<li><a (click)="filterClosedQuestions(QuestionAction.Closed, $event.target.innerText)">{{ 'Event_QA_Closed' | translate }}</a></li>
			</ul>
		</div>
	</section>
	<div [ngclass]="styles.questionListWrap">
		<vb-webcast-questions-list *ngIf="selectedView === Question_View.Inbox" [webcast]="webcast" [questions]="inboxQuestions">
		</vb-webcast-questions-list>

		<vb-webcast-questions-list *ngIf="selectedView === Question_View.Queue" [webcast]="webcast" [questions]="queuedQuestions" [allowReordering]="true">
		</vb-webcast-questions-list>

		<vb-webcast-questions-list *ngIf="selectedView === Question_View.Closed" [webcast]="webcast" [questions]="closedQuestions">
		</vb-webcast-questions-list>
	</div>
</ng-container>
