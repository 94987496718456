/**
 * Adds the theme-accent color to each of the tag list items
 * 	Example:
 * 		<tags-input vb-ui-tags-input ...></tags-input>
 */

import {
	AfterViewInit,
	Directive,
	OnInit
} from '@vbrick/angular-ts-decorators';
import { IAugmentedJQuery } from 'angular';

const CSS_FOCUS_CLASS: string = 'theme-accent-border-important';

@Directive({
	selector: '[vb-ui-tags-input]'
})
export class VbUITagsInputDirective implements OnInit, AfterViewInit {
	constructor(
		private $element: IAugmentedJQuery
	) {
		'ngInject';
	}

	public ngOnInit(): void {
		(this.$element.isolateScope() as any).tagClass = () => 'theme-accent';
		this.$element.append(`
			<span class="glyphicons circle_plus theme-accent-inverted tags-icon-plus"></span>
		`);
	}

	public ngAfterViewInit(): void {
		const $tagsContainer = this.$element.find('.tags');

		this.$element
			.focusin(() => $tagsContainer.toggleClass(CSS_FOCUS_CLASS, true))
			.focusout(() => $tagsContainer.toggleClass(CSS_FOCUS_CLASS, false));
		this.applyAriaLabelToInputControl();
	}

	private applyAriaLabelToInputControl(): void {
		const ariaLabel = this.$element.attr('placeholder');
		if (ariaLabel) {
			this.$element.find('input').attr('aria-label', ariaLabel);
		}
	}
}
