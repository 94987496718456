import { Component, Input } from '@angular/core';

import { WebcastModel } from '../webcast/model/WebcastModel';

import styles from './RealTimeAnalyticsHeader.Component.module.less';

@Component({
	selector: 'real-time-analytics-header',
	templateUrl: './RealTimeAnalyticsHeader.Component.html',
	host: {
		'[class]': 'hostClass',
		'flex-align': 'center',
		layout: 'row',
		'layout-wrap': 'false'
	}
})
export class RealTimeAnalyticsHeaderComponent {
	@Input() public webcast: WebcastModel;

	public readonly styles = styles;
	public readonly hostClass: string = `theme-header ${this.styles.root}`;
}
