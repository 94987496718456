import { NgModule } from '@vbrick/angular-ts-decorators';
import 'angular-clipboard';
import TooltipModule from 'angular-ui-bootstrap/src/tooltip';

import { ShareLink } from './ShareLink.Component';

@NgModule({
	id: 'VBrick.Shared.Media.ShareLink',
	declarations: [
		ShareLink
	],
	imports: [
		'angular-clipboard',
		TooltipModule
	],
	providers: []
})
export default class ShareLinkModule {}
