<ng-container *ngIf="(rtmpContext$ | async) as rtmpContext">
	<ng-container *ngIf="rtmpContext">
		<div [ngClass]="styles.formGroup">
			<label id="ewRtmpSourceProtocol" [ngClass]="styles.topLabel">{{ 'Protocol' | translate }}</label>
			<div [ngClass]="styles.outerContainer">
				<div [ngClass]="styles.row">
					<div [ngClass]="styles.innerContainer">
						<div vbUiRadioBtnGroup>
							<button type="button" class="util-uppercase" [ngClass]="styles.radioBtn" [(ngModel)]="rtmpContext.isSecure" [vbUiRadioBtn]="false" [notThemed]="!themed" [activeClass]="themed ? '' : 'active'" (ngModelChange)="onProtocolChange(rtmpContext.isSecure)">
								{{ 'Rtmp' | translate }}
							</button>
							<button type="button" class="util-uppercase" [ngClass]="styles.radioBtn" [(ngModel)]="rtmpContext.isSecure" [vbUiRadioBtn]="true" [notThemed]="!themed" [activeClass]="themed ? '' : 'active'" (ngModelChange)="onProtocolChange(rtmpContext.isSecure)">
								{{ 'Rtmps' | translate }}
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div [ngClass]="styles.formGroup">
			<label id="ewRtmpSourcePushUrl" [ngClass]="styles.topLabel">{{ 'Event_Webcast_Rtmp_PushUrl' | translate }}</label>
			<div [ngClass]="styles.outerContainer">
				<div [ngClass]="styles.row">
					<div [ngClass]="styles.innerContainer">
						{{ rtmpContext.pushUrl }}
						<button type="button" class="margin-left-10" [attr.aria-label]="'Event_Webcast_Rtmp_PushUrlCopy' | translate" [title]="'Event_Webcast_Rtmp_PushUrlCopy' | translate" ngxClipboard [cbContent]="rtmpContext.pushUrl" vbUiBtnSecondary>
							<span class="glyphicon glyphicon-copy"></span>
						</button>
					</div>
					<div *ngIf="errors && errors[ErrorDetails.RTMP_URL_CONFLICT]" [ngClass]="styles.error" role="alert" vbUiErrorMessage>
						{{ 'Event_Webcast_Rtmp_PushUrlConflictError' | translate }}
					</div>
				</div>
			</div>
		</div>
		<div [ngClass]="styles.formGroup">
			<label id="ewRtmpSourceStreamKey" [ngClass]="styles.topLabel">{{ 'StreamKey' | translate }}</label>
			<div [ngClass]="styles.outerContainer">
				<div [ngClass]="styles.row">
					<div [ngClass]="styles.innerContainer">
						{{ rtmpContext.streamKey }}
						<button type="button" class="margin-left-10" [attr.aria-label]="'Event_Webcast_Rtmp_StreamCopy' | translate" [title]="'Event_Webcast_Rtmp_StreamCopy' | translate" ngxClipboard [cbContent]="rtmpContext.streamKey" vbUiBtnSecondary>
							<span class="glyphicon glyphicon-copy"></span>
						</button>
					</div>
				</div>
			</div>
		</div>
		<div [ngClass]="styles.formGroup">
			<label [ngClass]="styles.topLabel"></label>
			<div [ngClass]="styles.outerContainer">
				<div [ngClass]="styles.row" class="padding-left-16">
					<div class="margin-bottom-10">
						{{ 'Event_Webcast_Rtmp_Url_Info' | translate }}
					</div>
					<button type="button" (click)="generateNewSettings(rtmpContext.isSecure)" vbUiBtnSecondary>
						{{ 'Event_Webcast_Rtmp_RegenerateUrlAndKey' | translate }}
					</button>
				</div>
			</div>
		</div>
	</ng-container>
</ng-container>
