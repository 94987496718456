<button class="btn-transparent" type="button" disabled="disabled" [ngClass]="styles.webcastNavBtn" [hidden]="!(isStarting || broadcast.isStopping)" vbUiBtnPrimaryNgx>
	{{ 'Event_Standby' | translate }}
</button>

<button type="button" [ngClass]="styles.webcastNavBtn" [hidden]="broadcast.isStopping || !broadcast.isActive" (click)="stopBroadcast()" vbUiBtnPrimaryNgx>
	{{ 'Event_PauseBroadcast' | translate }}
</button>

<button type="button" [ngClass]="styles.webcastNavBtn" [hidden]="isStarting || broadcast.isActive" (click)="startBroadcast()" [disabled]="!webcastStatus.isInProgress" vbUiBtnPrimaryNgx>
	{{ broadcast.hasBeenActive ? ('Event_ResumeBroadcast' | translate) : ('Event_Broadcast' | translate) }}
</button>
