import { NgModule } from '@vbrick/angular-ts-decorators';
import VBrickUiSpinnerModule from 'rev-shared/ui/spinner/Spinner.Module.ts';

import { HtmlEditor } from './HtmlEditor.Component';

@NgModule({
	id: 'VBrick.UI.HtmlEditor',
	imports:[
		VBrickUiSpinnerModule
	],
	declarations: [
		HtmlEditor
	]
})
export default class HtmlEditorModule{}
