import { Component, Input, OnInit } from '@vbrick/angular-ts-decorators';
import {
	IAugmentedJQuery,
	INgModelController
} from 'angular';

import styles from './VbGridList.module.less';

@Component({
	selector: 'vb-grid-list-selection-column',
	require: {
		ngModelController: 'ngModel'
	},
	template: `
		<vb-checkbox
			ng-change="$ctrl.syncModel()"
			ng-model="$ctrl.ngModel">
		</vb-checkbox>
	`
})

export class VbGridListSelectionColumnComponent implements OnInit {
	@Input() public ngModel: any;

	private ngModelController: INgModelController;

	constructor(
		private $element: IAugmentedJQuery
	) {
		'ngInject';
	}

	public ngOnInit(): void {
		this.$element.addClass(styles.selectionColumn);
		this.$element.addClass(styles.gridColumn);
	}

	public syncModel(): void {
		this.ngModelController.$setViewValue(this.ngModel);
	}
}
