/**
 * Cosmetic component to wrap around the UI Select module
 * example:
 * <vb-ui-select-wrap>
 * <ui-select></ui-select>
 * </vb-ui-select-wrap>
 */

import {
	Component,
	OnInit
} from '@vbrick/angular-ts-decorators';

import {
	IAugmentedJQuery,
	IFormController,
	INgModelController
} from 'angular';

import { ThemeService } from 'rev-portal/branding/Theme.Service';

import './ui-select.less';

@Component({
	selector: 'vb-ui-select-wrap',
	template: `
		<style>
			vb-ui-select-wrap .ui-select-container .ui-select-toggle:before {
				background: {{ $ctrl.accentBgColor }};
			}
			vb-ui-select-wrap .ui-select-container .ui-select-toggle .caret {
				border-top: 10px solid {{ $ctrl.accentFontColor }};
			}
			vb-ui-select-wrap .ui-select-bootstrap .ui-select-choices-row.active > span {
				background-color: {{ $ctrl.accentBgColor }} !important;
				color: {{ $ctrl.accentFontColor }}
			}
		</style>
		<div ng-transclude></div>
	`,
	transclude: true,
	require: {
		form: '^form'
	}
})

export class VbUISelectWrapComponent implements OnInit {
	private form: IFormController;

	constructor(
		private $element: IAugmentedJQuery,
		private ThemeService: ThemeService
	) {
		'ngInject';
	}

	public ngOnInit(): void {
		this.$element[0].addEventListener('blur', () => this.onBlur(), true);
		this.$element
			.focusin(() => this.toggleFocusBorder(true))
			.focusout(() => this.toggleFocusBorder(false));
	}

	private get accentFontColor(): string {
		return this.ThemeService.brandingSettings.themeSettings.accentFontColor;
	}

	private get accentBgColor(): string {
		return this.ThemeService.brandingSettings.themeSettings.accentColor;
	}

	private onBlur(): void {
		const fieldName: string = this.$element.find('[ng-model][name]').attr('name');

		if (!fieldName) {
			return;
		}

		(this.form[fieldName] as INgModelController).$setTouched(); // workaround the ui-select implementation neglecting keyboard nav in its touched bheavior
	}

	private toggleFocusBorder(isActive: boolean): void {
		this.$element
			.find('.ui-select-toggle')
			.toggleClass('theme-accent-border', isActive)
			.toggleClass('theme-accent-border-hover', isActive);
	}
}
