import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { UIRouterModule } from '@uirouter/angular';

import { ButtonsAngularModule } from 'rev-shared/ui/buttons/ButtonsAngular.Module';
import { DialogAngularModule } from 'rev-shared/ui/dialog/DialogAngular.Module';
import { DirectivesAngularModule } from 'rev-shared/util/directives/DirectivesAngular.Module';
import { FileUploadAngularModule } from 'rev-shared/ui/fileUpload/FileUpload.AngularModule';
import { ForgivingImageModule } from 'rev-shared/ui/forgivingImage/ForgivingImage.Module';
import { SpinnerAngularModule } from 'rev-shared/ui/spinner/SpinnerAngular.Module';

import { ScheduledEventsCommonModule } from 'rev-portal/scheduledEvents/common/ScheduledEventsCommon.AngularModule';
import { WebcastSharedComponentsModule } from 'rev-portal/scheduledEvents/webcast/sharedComponents/WebcastSharedComponents.Module';

import { SlideDelayDirective } from './SlideDelay.Directive';
import { WebcastPresentationComponent } from './WebcastPresentation.Component';
import { WebcastPresentationSlidesComponent } from './WebcastPresentationSlides.Component';

const components = [
	WebcastPresentationComponent,
	WebcastPresentationSlidesComponent,
	SlideDelayDirective
];

@NgModule({
	declarations: components,
	exports: components,
	imports: [
		ButtonsAngularModule,
		CommonModule,
		DialogAngularModule,
		DirectivesAngularModule,
		FileUploadAngularModule,
		ForgivingImageModule,
		ScheduledEventsCommonModule,
		SpinnerAngularModule,
		TranslateModule,
		UIRouterModule,
		WebcastSharedComponentsModule
	]
})
export class PresentationAngularModule {
}
