<ng-template [vbCssRules]="cssRules$ | async"></ng-template>

<article layout layout-padding="100 025" layout-padding-no-edges flex-align="start">

	<div class="drag-handle">
		<span class="glyphicons show_lines"></span>
	</div>

	<div [hidden]="!(!canModerate && questionContext === QuestionContextType.publicQuestions && question.isUnread)" class="new-message">
		<span class="glyphicons bell bell-middle"></span>
	</div>
	<div [hidden]="!(!canModerate && questionContext === QuestionContextType.myQuestions && question.isUnread && !question.isNew)" class="new-message">
		<span class="glyphicons bell bell-middle"></span>
	</div>
	<aside class="aside-icon" flex [ngClass]="{'icon-queued': question.status === 'Queued'}">
		<!--Only indicate when a question has been answered (directly or by speaker). Otherwise, appears "open" to the attendee.-->
		<span [hidden]="!(!canModerate && questionContext !== QuestionContextType.publicQuestions)" class="question-status circle icon-block theme-accent theme-accent-font-border" [ngClass]="{
				'glyphicons ok_2 status-replied': question.isAnsweredOrReplied,
				'open-question status-dark': question.isOpen
			}" [title]="(question.isAnsweredOrReplied ? 'Event_QA_Answered'  : 'Event_QA_OpenQuestion') | translate">
		</span>

		<span *ngIf="canModerate && question.isClosed && questionContext !== QuestionContextType.publicQuestions" class="question-status circle icon-block theme-accent theme-accent-font-border" [ngClass]="{'status-dark': !question.lastAction || question.isDeclined || question.isFollowUp,
							'status-replied': question.isAnswered || question.isDirectReplied}">
			<span class="glyphicons remove_2 theme-accent" [title]="'Event_QA_Closed' | translate" [hidden]="question.lastAction"></span>
			<span class="glyphicons ok_2 theme-accent" [title]="'Event_QA_Answered' | translate" [hidden]="!question.isAnswered"></span>
			<span class="glyphicons share theme-accent" [title]="'Event_QA_Closed_Replied' | translate" [hidden]="!question.isDirectReplied"></span>
			<span class="glyphicons remove_2 theme-accent" [title]="'Event_QA_Declined' | translate" [hidden]="!question.isDeclined"></span>
			<span class="glyphicons flag theme-accent" [title]="'Event_QA_FollowUp' | translate" [hidden]="!question.isFollowUp"></span>
		</span>
		<span [hidden]="!(question.isPublic && questionContext !== QuestionContextType.publicQuestions)" class="glyphicons group theme-primary-txt"></span>
	</aside>
	<div class="width-full" [attr.flex]="canModerate && !question.isClosed ? 'webcast-qa-card-moderator' : 'webcast-qa-card-viewer'" layout="column">
		<div>
			<h5 class="question-qtext"><span [hidden]="!canModerate" class="question-number">{{question.questionNumber}}</span> {{question.questionText}}</h5>
		</div>
		<div flex class="question-info">
			<!--Basic question info-->
			<p class="question-from" [hidden]="question.anonymous || !canModerate">
				<vb-profile-picture [profileUrl]="question.askedBy?.profileImageUri">
				</vb-profile-picture>
				{{ 'Event_QA_QuestionFrom' | translate : { '0': question.askedByFullName } }}
				<span [hidden]="!question.isModerator">{{ 'Event_QA_ModeratorLabel' | translate }}</span>
			</p>
			<p class="question-from" [hidden]="!(question.anonymous && canModerate)">
				{{ 'Event_QA_QuestionFrom' | translate : { '0': 'Event_Webcast_Anonymous' | translate } }}
			</p>

			<span class="question-reply" *ngIf="question.replyText && question.repliedBy" [hidden]="!question.replyText && question.isDirectReplyInProgress">
					<span class="comment-body">{{question.replyText}}</span>

					<div class="question-reply-by">
						<vb-profile-picture [profileUrl]="question.repliedBy.profileImageUri">
						</vb-profile-picture>
						{{question.repliedBy.firstName}} {{question.repliedBy.lastName}}
					</div>
			</span>

			<span class="question-reply question-reply-inprogress" *ngIf="canModerate && question.isDirectReplyInProgress">
				<span [hidden]="question.directReplyInProgressBy">
					{{ 'Event_QA_ReplyInProgressStatus' | translate }}
				</span>
				<span [hidden]="!question.directReplyInProgressBy">
					{{ 'Event_QA_ReplyInProgressStatusWithName' | translate : { '0': question.directReplyInProgressBy.fullName } }}
				</span>
			</span>

			<span class="question-answered-by-speaker" [hidden]="!(question.isClosed && question.isAnswered)">
				{{ 'Event_QA_AnsweredBySpeaker' | translate }}
			</span>
		</div>
	</div>
</article>

<footer *ngIf="canModerate && (question.isInbox || question.isQueued)" flex="100" class="options no-padding theme-accent-bg">
	<!--Actions buttons (Inbox)-->
	<div layout layout-padding-no-edges flex-wrap="false" class="question-mod-buttons">

		<div flex="25">
			<button type="button" class="theme-accent-bg" [title]="'Event_QA_Action_Followup' | translate" (click)="followUp()">
				<span class="glyphicons flag theme-accent-txt"></span>
				<span class="label theme-accent-txt">{{ 'Event_QA_Action_Followup' | translate }}</span>
			</button>
		</div>
		<div flex="25">
			<button type="button" class="theme-accent-bg" [title]="'Event_QA_Action_Reply' | translate" (click)="directReply()">
				<span class="glyphicons share theme-accent-txt"></span>
				<span class="label theme-accent-txt">{{ 'Event_QA_Action_Reply' | translate }}</span>
			</button>
		</div>
		<div flex="25" [hidden]="!question.isQueued">
			<button type="button" class="theme-accent-bg" [title]="'Event_QA_Action_MarkAsAnswered' | translate" (click)="answered()">
				<span class="glyphicon glyphicon-ok theme-accent-txt"></span>
				<span class="label theme-accent-txt">{{ 'Event_QA_Action_MarkAsAnswered' | translate }}</span>
			</button>
		</div>
		<div flex="25" [hidden]="!question.isInbox">
			<button type="button" class="theme-accent-bg" [title]="'Event_QA_Action_Queue' | translate" (click)="addToQueue()">
				<span class="glyphicons plus theme-accent-txt"></span>
				<span class="label theme-accent-txt">{{ 'Event_QA_Action_Queue' | translate }}</span>
			</button>
		</div>
		<div flex="25" [hidden]="!question.isQueued">
			<button type="button" class="theme-accent-bg" [title]="'Event_QA_Action_Decline' | translate" (click)="decline()">
				<span class="glyphicons remove_2 theme-accent-txt"></span>
				<span class="label theme-accent-txt">{{ 'Event_QA_Action_Decline' | translate }}</span>
			</button>
		</div>
		<div flex="25" [hidden]="!question.isInbox">
			<button type="button" class="theme-accent-bg" [title]="'Event_QA_Action_Close' | translate" (click)="close()">
				<span class="glyphicons remove_2 theme-accent-txt"></span>
				<span class="label theme-accent-txt">{{ 'Event_QA_Action_Close' | translate }}</span>
			</button>
		</div>
	</div>
</footer>

<div *ngIf="canModerate && question.isClosed" dropdown class="btn-group closedQueueStatusDropdown">
	<button vbUiBtnPrimaryNgx dropdownToggle id="closedQuestionsStatusChangeMenuBtn" aria-controls="closedQuestionsStatusChangeMenu" class="dropdown-toggle" [attr.aria-label]="'Event_QA_ClosedMenu' | translate">
		<span class="more-icon">
			<span class="circle theme-accent-inverted"></span>
			<span class="circle theme-accent-inverted"></span>
			<span class="circle theme-accent-inverted"></span>
		</span>
	</button>
	<ul id="closedQuestionsStatusChangeMenu" role="menu" [attr.aria-labelledby]="closedQuestionsStatusChangeMenuBtn" *dropdownMenu class="dropdown-menu">
		<li>
			<a (click)="addToQueue()"><span class="glyphicons plus"></span>{{ 'Event_QA_Action_Queue' | translate }}</a>
		</li>
		<li [hidden]="question.isAnswered">
			<a (click)="answered()"><span class="glyphicon glyphicon-ok"></span>{{ 'Event_QA_Action_MarkAsAnswered' | translate }}</a>
		</li>
		<li [hidden]="question.isFollowUp">
			<a (click)="followUp()"><span class="glyphicons flag"></span>{{ 'Event_QA_Action_Followup' | translate }}</a>
		</li>
		<li>
			<a (click)="directReply()"><span class="glyphicons share"></span>{{ 'Event_QA_Action_Reply' | translate }}</a>
		</li>
		<li *ngIf="question.isQueued">
			<a (click)="decline()"><span class="glyphicons remove_2"></span>{{ 'Event_QA_Action_Decline' | translate }}</a>
		</li>
	</ul>
</div>

<vb-confirmation-dialog [title]="'Event_QA_ReplyConflictingAction_Title' | translate" [message]="'Event_QA_ReplyConflictingAction_Message' | translate" [cancelText]="'No' | translate" [actionText]="'Yes' | translate" [themed]="true">
</vb-confirmation-dialog>
