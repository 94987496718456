import { IScope } from 'angular';
import { IModalInstanceService } from 'rev-shared/ui/dialog/IDialog';

/**
 * A simple function that exposes
 * the dialog params object and other dialog functionality
 * to the scope.
 */
export function SimpleDialogController(
	$scope: IScope,
	$uibModalInstance: IModalInstanceService,
	dialogParams: any
) {
	'ngInject';
	Object.assign($scope, {
		close: $uibModalInstance.close,
		dismiss: $uibModalInstance.dismiss
	},
	dialogParams);
}
