import {
	Component,
	Input,
	OnInit
} from '@vbrick/angular-ts-decorators';

import styles from './DownloadDetails.module.less';

@Component({
	selector: 'download-details',
	templateUrl: '/partials/portal/downloads/download-details.html'

})
export class DownloadDetailsComponent implements OnInit {
	@Input() private accountId: string;
	@Input() private fileId: string;

	public readonly styles = styles;
	public downloadUrl: string;

	public ngOnInit(): void {
		this.downloadUrl = `/network/accounts/${this.accountId}/downloads/${this.fileId}`;
	}
}
