import {
	Component,
	OnInit
} from '@angular/core';

import { TeamService } from 'rev-shared/team/Team.Service';

import { TeamListingsService } from 'rev-portal/media/teams/TeamListings.Service';

import styles from './list-widget.module.less';

const limit = 15;

@Component({
	selector: 'team-widget',
	templateUrl: './TeamWidget.Component.html'
})

export class TeamWidgetComponent implements OnInit {
	public isFetching: boolean = false;
	private readonly styles = styles;
	private teams: any[];

	constructor(
		private TeamListingsService: TeamListingsService,
		public TeamService: TeamService
	) { }

	public ngOnInit(): void {
		this.fetchData();
	}

	public get hasTeams(): boolean {
		return this.TeamListingsService.teamData.length > 0;
	}

	private fetchData(): void {
		this.isFetching = true;
		this.TeamListingsService.listTeams()
			.then(teams => this.teams = teams.slice(0, limit))
			.finally(() => {
				this.isFetching = false;
			});
	}
}
