
<span [ngClass]="overallStatus.class">
	<span [ngClass]="[overallStatus.icon, styles.iconPosition]"></span>
	<span class="margin-5" [innerHTML]="overallStatus.label | highlight : highlightText : styles.highlight"></span>
</span>

<span class="dropdown" *ngIf="healthMetrics" dropdown #dropdown="bs-dropdown" container="body">
	<a dropdownToggle role="button" [attr.aria-label]="'Event_RealTimeAnalytics_HealthStatus_Open' | translate">
		<span class="glyphicons chevron-right" [ngClass]="[
				styles.iconPosition,
				dropdown.isOpen ? styles.openChevron : '']">
		</span>
	</a>
	<ul [ngClass]="styles.statusDropdown" *dropdownMenu>
		<li *ngFor="let metric of healthMetrics" [ngClass]="styles.statusMetric">
			<span [ngClass]="[metric.class || '', metric.icon || '']"></span>
			{{ metric.label }}

			<span class="pull-right">
				{{ metric.value }}
			</span>
		</li>
	</ul>
</span>
