import { NgModule } from '@vbrick/angular-ts-decorators';

import { UserNavMenuComponent } from './UserNavMenu.Component';

@NgModule({
	id: 'VBrick.Portal.Navigation.UserNavMenu',
	declarations: [
		UserNavMenuComponent
	]
})

export default class UserNavMenuModule {}
