import { AfterViewInit, Component, Input, OnDestroy, Output } from '@vbrick/angular-ts-decorators';
import { IAngularEvent, IAugmentedJQuery, IWindowService } from 'angular';
import { TransitionService, Transition } from '@uirouter/angularjs';
import { Subscription } from 'rxjs';

import { ComponentCallback } from 'rev-shared/ts-utils/ComponentCallback';
import { ComponentCallbackEvent } from 'rev-shared/ts-utils/ComponentCallbackEvent';
import { isMobileSafariWithDialogBlocking } from 'rev-shared/util/UserAgentUtil';

import { RxWindowLocationService } from './RxWindowLocation.Service';
import { StateContext } from './StateContext';

/**
 * Utility component to show a confirmation dialog if the user attempts to close the browser,
 * navigate to anther page in rev, or another site.
 */
@Component({
	selector: 'vb-navigation-handler'
})
export class VbNavigationHandlerComponent implements AfterViewInit, OnDestroy {
	@Input('@') public locationChangeMessage: string; // Confirmation message. Shown before a state change, or url change.
	@Input('@') public unloadMessage: string; // Confirmation message. Shown before the page unloads.

	@Output() public onNavigate: ComponentCallback<any, boolean>; // Return false to show a confirmation message and block navigation.

	private readonly transitionDebounceTime = 500;
	private contextStateName: string;
	private isConfirmAllowed: boolean;
	private lastTransitionTime: number;
	private locationChangeStartSub: Subscription;
	private onBeforeUnloadHandler: () => void;
	private removeTransitionOnStartHandler: () => void;

	constructor(
		private $element: IAugmentedJQuery,
		private $transitions: TransitionService,
		private $window: IWindowService,
		private RxWindowLocation: RxWindowLocationService
	) {
		'ngInject';
	}

	public ngAfterViewInit(): void {
		this.contextStateName = StateContext(this.$element).name;
		this.isConfirmAllowed = !isMobileSafariWithDialogBlocking();
		this.onBeforeUnloadHandler = () => this.onBeforeUnload();

		this.locationChangeStartSub = this.RxWindowLocation.locationChangeStart$.subscribe(data => this.onLocationChangeStart(data.event));
		this.removeTransitionOnStartHandler = this.$transitions.onStart({}, transition => this.onTransitionStart(transition)) as () => any;
		this.$window.addEventListener('beforeunload', this.onBeforeUnloadHandler);
	}

	public ngOnDestroy(): void {
		this.$window.removeEventListener('beforeunload', this.onBeforeUnloadHandler);
		this.locationChangeStartSub.unsubscribe();
		this.removeTransitionOnStartHandler();
	}

	private confirmLocationChange(): boolean {
		return !this.isConfirmAllowed || this.$window.confirm(this.locationChangeMessage);
	}

	private ignoreLocationChangeStart(): boolean {
		return Date.now() - this.lastTransitionTime < this.transitionDebounceTime;
	}

	private isChildState(stateName: string): boolean {
		return stateName.startsWith(this.contextStateName);
	}

	private onBeforeUnload(): string {
		if (this.onNavigateInternal() === false) {
			return this.unloadMessage;
		}
	}

	private onLocationChangeStart(event: IAngularEvent): void {
		if (this.ignoreLocationChangeStart()) {
			return;
		}

		if(this.onNavigateInternal() === false && !this.confirmLocationChange()) {
			event.preventDefault();
		}
	}

	private onNavigateInternal(): boolean {
		return this.onNavigate(new ComponentCallbackEvent({}));
	}

	private onTransitionStart(transition: Transition): boolean {
		this.lastTransitionTime = Date.now();

		if (this.isChildState(transition.to().name)) {
			return;
		}

		if (this.onNavigateInternal() === false && !this.confirmLocationChange()) {
			return false;
		}
	}
}
