import { Input, OnChanges, SimpleChanges, Directive, HostBinding, OnInit, OnDestroy } from '@angular/core';

import { IRules } from 'rev-shared/ui/css/CssRules.Contract';
import { appendStyles, applyStyles, removeStyle } from 'rev-shared/ui/css/CssRules';
import { isArray } from 'rev-shared/util';

import styles from './WebcastBackground.module.less';

export const WebcastBackgroundScaleSizes = {
	EXTRA_SMALL: 'ExtraSmall',
	SMALL: 'Small',
	MEDIUM: 'Medium',
	LARGE: 'Large',
	ORIGINAL: 'Original'
};

export interface IBackgroundImageCfg {
	scaleSize: string;
	link: string;
}

@Directive({
	selector: '[webcastBackground]',
	host: {
		'[class]': 'hostClass'
	}
})
export class WebcastBackgroundAngularDirective implements OnInit, OnChanges, OnDestroy {
	@Input() public webcastBackground: IBackgroundImageCfg[];

	@Input()
	public isBackgroundFill: boolean;

	@Input()
	public isBackgroundFixed: boolean;

	public readonly styles = styles;
	public hostClass: {[key: string]: boolean};

	private style: HTMLStyleElement;

	public ngOnInit(): void {
		this.style = appendStyles('webcast-background');
		this.updateStyles();
		this.assignHostClass();
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if(changes.webcastBackground) {
			this.updateStyles();
			this.assignHostClass();
		}
		if (changes.isBackgroundFill) {
			this.assignHostClass();
		}
	}

	public ngOnDestroy(): void {
		if(this.style) {
			removeStyle(this.style);
		}
	}

	private assignHostClass(): void {
		this.hostClass = {
			[this.styles.webcastBackground]: true,
			[this.styles.webcastBackgroundFill]: this.isBackgroundFill,
			[this.styles.fixed]: this.isBackgroundFixed,
		};
	}

	private updateStyles(): void {
		let rules: IRules;
		if(isArray(this.webcastBackground)) {
			const xs = this.getRule(WebcastBackgroundScaleSizes.EXTRA_SMALL, 0);

			rules = !xs ?
				this.getRule(WebcastBackgroundScaleSizes.ORIGINAL, 0) :
				{
					...xs,
					...this.getRule(WebcastBackgroundScaleSizes.SMALL, 768),
					...this.getRule(WebcastBackgroundScaleSizes.MEDIUM, 992),
					...this.getRule(WebcastBackgroundScaleSizes.LARGE, 1200)
				};
		}

		applyStyles(this.style, rules);
	}

	private getRule(scaleSize: string, width: number): IRules {
		const cfg = this.webcastBackground.find(cfg => cfg.scaleSize === scaleSize);

		return !cfg ?
			{} : this.getBackgroundRule(cfg.link, width);
	}

	private getBackgroundRule(link: string, width: number): IRules {
		link = link.startsWith('/') ? link : `/${link}`;
		return {
			[`@media (min-width: ${width}px)`]: {
				[`.${styles.webcastBackground}:before`]: {
					'background-image': `url(${window.location.origin}${link})`
				}
			}
		};
	}
}
