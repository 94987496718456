import { NgModule } from '@vbrick/angular-ts-decorators';

import { VbUiFormGroupComponent } from './VbUiFormGroup.Component';

@NgModule({
	id: 'VBrick.UI.FormGroup',
	declarations: [
		VbUiFormGroupComponent
	]
})
export default class FormGroupModule {}
