import { Component, Input, OnInit, AfterViewInit } from '@vbrick/angular-ts-decorators';
import { EmbedSize } from 'rev-shared/media/shareEmbed/ShareEmbed.Component';

import './playlist-sharing.less';

@Component({
	selector: 'playlist-sharing',
	templateUrl: '/partials/media/playlist-sharing.html'
})
export class PlaylistSharing implements OnInit, AfterViewInit {
	@Input() public embedsEnabled: boolean;
	@Input() public playlistId: string;
	@Input() public playlistName: string;
	@Input() public playlistEmbedUrl: string;
	@Input() public playlistPlaybackUrl: string;

	protected currentShareView: string;
	protected embedPresetSizes: EmbedSize[];
	protected PlaylistSharingViews: PlaylistSharingViews;
	protected playlistSharingEmailBody: string;
	protected playlistSharingEmailSubject: string;
	protected translations: any;

	public ngOnInit(): void {
		Object.assign(this, {
			PlaylistSharingViews,
			currentShareView: PlaylistSharingViews.LINK
		});
	}

	public ngAfterViewInit(): void {
		Object.assign(this, {
			embedPresetSizes: this.getEmbedPresetSizes(),
			playlistSharingEmailBody: this.getEmailBody()
		});
	}

	protected getEmailBody(): string {
		// mailTo does not work with html body well. So, getting the plain text rather than html...

		return `${this.translations.emailBodyInterested}\n\n${this.playlistName}\n${this.playlistPlaybackUrl}`;
	}

	protected getEmbedPresetSizes(): EmbedSize[] {
		return [
			new EmbedSize(560, 315, 'small', this.translations.sizeSmall),
			new EmbedSize(640, 360, 'medium', this.translations.sizeMedium),
			new EmbedSize(853, 480, 'large', this.translations.sizeLarge)
		];
	}
}

export class PlaylistSharingViews {
	public static readonly LINK: string = 'Link';
	public static readonly EMBED: string = 'Embed';
}
