import { NgModule } from '@angular/core';

import { VbUiSortableDirective } from './VbUiSortable.Directive';

const components = [
	VbUiSortableDirective
];

@NgModule({
	declarations: components,
	exports: components
})
export class VbUiSortableModule {}
