import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { TranslateModule } from '@ngx-translate/core';

import { BarChartModule } from 'rev-shared/ui/dataVisualization/barChart/BarChart.Module';
import { DateAngularModule } from 'rev-shared/date/DateAngular.Module';
import { KpiModule } from 'rev-shared/ui/reports/kpi/Kpi.Module';
import { LineChartModule } from 'rev-shared/ui/dataVisualization/lineChart/LineChart.Module';
import { PieChartModule } from 'rev-shared/ui/dataVisualization/pieChart/PieChart.Module';
import { PushModule } from 'rev-shared/push/Push.Module';
import { ReportCellModule } from 'rev-shared/ui/reports/reportCell/ReportCell.Module';
import { ReportsServiceDownMsgModule } from 'rev-shared/ui/reports/reportsDown/ReportsServiceDownMsg.Module.ts';
import { SecurityAngularModule } from 'rev-shared/security/SecurityAngular.Module';
import { TabPanelModule } from 'rev-shared/ui/tabPanel/TabPanel.Module';
import { VbDropdownMenuModule } from 'rev-shared/ui/dropdown-menu/VbDropdownMenu.Module';
import { VbPollTrendModule } from 'rev-shared/ui/reports/pollTrend/VbPollTrend.Module.ts';
import { WebcastAttendeesGridModule } from 'rev-shared/webcast/attendeesGrid/WebcastAttendeesGrid.Module';

import { EngagementReportKpisComponent } from './EngagementReportKpis.Component';
import { QualityExperienceReportKpisComponent } from './QualityExperienceReportKpis.Component';
import { ReportsMenuComponent } from './ReportsMenu.Component';
import { WebcastEngagementReportComponent } from './WebcastEngagementReport.Component';
import { WebcastQualityExperienceReportComponent } from './WebcastQualityExperienceReport.Component';
import { WebcastReportParentComponent } from './WebcastReportParent.Component';
import { WebcastReportsService } from './WebcastReports.Service';

const components = [
	EngagementReportKpisComponent,
	QualityExperienceReportKpisComponent,
	ReportsMenuComponent,
	WebcastEngagementReportComponent,
	WebcastQualityExperienceReportComponent,
	WebcastReportParentComponent
];

@NgModule({
	declarations: components,
	entryComponents: components,
	exports: components,
	imports: [
		BarChartModule,
		DateAngularModule,
		CommonModule,
		HttpClientModule,
		KpiModule,
		LineChartModule,
		PieChartModule,
		PushModule,
		ReportCellModule,
		ReportsServiceDownMsgModule,
		SecurityAngularModule,
		TabPanelModule,
		TranslateModule,
		VbDropdownMenuModule,
		VbPollTrendModule,
		WebcastAttendeesGridModule
	],
	providers: [
		WebcastReportsService
	]
})
export class WebcastReportModule {}
