import { NgModule } from '@vbrick/angular-ts-decorators';

import { VbCaretComponent } from './VbCaret.Component';

@NgModule({
	id: 'VBrick.UI.Caret',
	declarations: [
		VbCaretComponent
	]
})
export default class CaretModule {}
