import { default as angular } from 'angular'; // AngularJS
import { DoBootstrap, NgModule, NgZone } from '@angular/core';
import { UpgradeModule, setAngularJSGlobal } from '@angular/upgrade/static';
import { TranslateService } from '@ngx-translate/core';

import { UIRouterUpgradeModule, upgradeModule } from '@uirouter/angular-hybrid';
import { default as AngularJsUiRouterModule, UrlService as AngularJsUrlService } from '@uirouter/angularjs';

import { downgradeInjectables } from 'rev-shared/util/AngularHybridUtils';

import { AngularAppBootstrapDepsModule } from 'rev-shared/bootstrap/AngularAppBootstrapDeps.Module';

import PortalAngularJsModule from './Portal.Module';
import { PortalAngularModule } from './PortalAngular.Module';

@NgModule({
	imports: [
		AngularAppBootstrapDepsModule,
		UpgradeModule,
		PortalAngularModule,
		UIRouterUpgradeModule.forRoot()
	]
})
export class PortalHybridRootModule implements DoBootstrap {
	constructor(
		private upgrade: UpgradeModule
	) {}

	public ngDoBootstrap(): void {
		// Give Angular a reference to AngularJS
		setAngularJSGlobal(angular);

		const angularJsBootstrapModuleName: string = 'Vbrick.Bootstrap';

		angular.module(angularJsBootstrapModuleName, [
			AngularJsUiRouterModule
		])
			.config(($urlServiceProvider: AngularJsUrlService) => {
				'ngInject';

				$urlServiceProvider.deferIntercept();
			});

		downgradeInjectables(PortalAngularJsModule, [
			{
				name: 'ngZone',
				injectable: NgZone
			},
			{
				name: 'TranslateService',
				injectable: TranslateService
			}
		]);

		this.upgrade.bootstrap(
			document.documentElement,
			[
				angularJsBootstrapModuleName,
				upgradeModule.name,
				(PortalAngularJsModule as any).module.name
			],
			{ strictDi: true }
		);
	}
}
