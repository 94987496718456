import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@vbrick/angular-ts-decorators';
import {
	IAttributes,
	IAugmentedJQuery,
	INgModelController,
	IFilterFilter
} from 'angular';

import {
	sortBy as _sortBy,
	difference as _difference
} from 'underscore';

import { isArray } from 'rev-shared/util';

import './admin-multiselect.less';
import './admin-multiselect-compact.less';
import './admin-movable-item.less';
import './multi-select.less';

const SORT_FIELD_DEFAULT: string = 'name';

/**
 * Multiselect form control. This can be used as component..
 * Example:
 * 	<vb-multiselect
 * 		options="users"  //array of user objects that can be selected.
 * 		repeat-item="user" //the name used within ng-repeat
 * 		selector="id"  //defines the expression to select the value from each option.  In this case, ng-model will contain an array of user.id's
 * 						//If this is missing, then the whole item is selected. Must be unique.
 * 		ng-model="group.userIds"
 * 		name="userIds"
 * 		selected-items-heading="Assigned Roles"
 * 		available-items-heading="Available Roles"
 * 		search-query
 * 		search-help-text="Find Roles"
 * 		selected-filter-property="roleName" this is always required
 * 		available-filter-property="roleName" this is always required
 * 		status //optional object hash. States are loading, active, and error.
 * 		//other angular form directives can be used
 * 		required
 * 		ng-required
 *   	ng-change>
 * 	</vb-multiselect>
 *  Validation:
 * 	If an invalid value is present in ng-model, the field will be marked as invalid with the key: vbMultiselect
 */

@Component({
	selector: 'vb-multiselect',
	transclude: true,
	require: {
		ngModel: 'ngModel'
	},
	templateUrl($attrs: IAttributes): string {
		'ngInject';
		return $attrs.templateUrl || '/partials/shared/vb-multiselect/admin-multiselect.html';
	}
})
export class MultiselectComponent implements OnInit, OnChanges {
	@Input() public availableFilterProperty: string;
	@Input() public availableItemsHeading: string;
	@Input() public isCompact: boolean;
	@Input() public itemClasses: { [className: string]: (itemModel: any) => boolean };
	@Input() public labelProperty: string;
	@Input() public options: any[];
	@Input() public searchHelpText: string;
	@Input() public selectedFilterProperty: string;
	@Input() public selectedItemsHeading: string;
	@Input() public selector: string;
	@Input() public sortField: string = SORT_FIELD_DEFAULT;

	public selectedFilterTxt: any = {};
	public availableFilterTxt: any = {};
	public selectedOptions: any[] = [];
	public availableOptions: any[] = [];

	private ngModel: INgModelController;

	constructor(
		private filterFilter: IFilterFilter
	) {
		'ngInject';
	}

	public ngOnInit(): void {
		this.ngModel.$render = () => this.updateOptions();
		this.ngModel.$isEmpty = value => !value || !value.length;
	}

	public ngOnChanges(changes: SimpleChanges<this>): void {
		if (changes.options) {
			this.updateOptions();
		}
	}

	public addAll(): void {
		this.selectedOptions = this.selectedOptions.concat(this.filterFilter(this.availableOptions, this.availableFilterTxt));
		this.availableOptions = _difference(this.availableOptions, this.selectedOptions);
		this.selectedOptions = this.sortList(this.selectedOptions);
		this.updateModelValue();

		//workaround for vs-repeat issue. Was not rendering whole list when length changes
		setTimeout(() => window.dispatchEvent(new Event('resize')));
	}

	public removeAll(): void {
		this.availableOptions = this.availableOptions.concat(this.filterFilter(this.selectedOptions, this.selectedFilterTxt));
		this.selectedOptions = _difference(this.selectedOptions, this.availableOptions);
		this.availableOptions = this.sortList(this.availableOptions);
		this.updateModelValue();

		setTimeout(() => window.dispatchEvent(new Event('resize')));
	}

	public add(item: any): void {
		const index: number = this.availableOptions.indexOf(item);

		if(index >= 0) {
			this.availableOptions.splice(index, 1);
			this.selectedOptions.push(item);
			this.selectedOptions = this.sortList(this.selectedOptions);
			this.updateModelValue();
		}
	}

	public remove(item: any): void {
		const index: number = this.selectedOptions.indexOf(item);

		if(index >= 0) {
			this.selectedOptions.splice(index, 1);
			this.availableOptions.push(item);
			this.availableOptions = this.sortList(this.availableOptions);
			this.updateModelValue();
		}
	}

	private getItemClasses(item: any): { [key: string]: boolean } {
		if (!item) {
			return {};
		}

		return Object.entries(this.itemClasses || {})
			.reduce(
				(out, [currentClass, currentClassTestFunc]) => {
					out[currentClass] = currentClassTestFunc(item);

					return out;
				},
				{}
			);
	}

	private getKey(option: any): any {
		return this.selector ?
			option[this.selector] :
			option;
	}

	private updateModelValue(): void {
		const values: any[] = (this.selectedOptions || []).map(option => this.getKey(option));

		this.ngModel.$setViewValue(values);
	}

	private updateOptions(): void {
		const mv = this.ngModel.$modelValue;
		const values = isArray(mv) ? mv.slice() : [];

		this.availableOptions = [];
		this.selectedOptions = [];

		(this.options || []).forEach(option => {
			const key: any = this.getKey(option);
			const index: number = values.indexOf(key);

			if(index < 0) {
				this.availableOptions.push(option);
			} else {
				values.splice(index, 1); //track any selected items that were not found in options
				this.selectedOptions.push(option);
			}
		});

		this.availableOptions = this.sortList(this.availableOptions);
		this.selectedOptions = this.sortList(this.selectedOptions);

		//workaround for vs-repeat issue. Was not rendering whole list when length changes
		setTimeout(() => window.dispatchEvent(new Event('resize')));
	}

	private sortList(options: any[]): any[] {
		return _sortBy(options, this.sortField);
	}
}
