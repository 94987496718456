import { StateProvider, Transition } from '@uirouter/angularjs';

import { IVBrickStateDeclaration } from 'rev-shared/ts-utils/IVBrickStateDeclaration';
import { Unauthorized401Component } from 'rev-shared/security/components/Unauthorized401.Component';
import { UserContextService } from 'rev-shared/security/UserContext.Service';

import { ThemeService } from 'rev-portal/branding/Theme.Service';

export const PORTAL_BASE_STATE = 'portal';

const params401 = {
	reason: {}
};

export function configurePortalBaseState(
	$stateProvider: StateProvider,
	componentName?: string,
	isAbstract?: boolean,
	initResolve?: any): void {
	$stateProvider
		.state(PORTAL_BASE_STATE, {
			'abstract': isAbstract,
			resolve: Object.assign({
				accountId(UserContext: UserContextService) {
					'ngInject';

					return UserContext.getAccount().id;
				},
				initTheme(ThemeService: ThemeService) {
					'ngInject';
					if (!ThemeService.accentFontColor) {
						ThemeService.initialize();
					}
				}
			}, initResolve),
			component: componentName,
			allowGuestAccess: true,
			allowPublicVideoAccess: true,
			allowRegisteredGuestAccess: true
		} as IVBrickStateDeclaration)

		.state('401', { //standalone 401
			url: '/401',
			component: Unauthorized401Component as any,
			resolve: Object.assign({
				reason($transition$: Transition) {
					'ngInject';
					return $transition$.params().reason;
				}
			}, initResolve),
			allowAllAccess: true,
			params: params401
		} as IVBrickStateDeclaration)

		.state('portal.401', { //401 framed within the portal
			url: '/401-unauthorized',
			resolve: {
				reason($transition$: Transition) {
					'ngInject';
					return $transition$.params().reason;
				}
			},
			component: Unauthorized401Component as any,
			allowAllAccess: true,
			params: params401
		} as IVBrickStateDeclaration)

		.state('portal.404', {
			url: '/404',
			templateUrl: '/partials/portal/404.html',
			allowAllAccess: true
		} as IVBrickStateDeclaration);
}
