import {
	IQService,
	IPromise
} from 'angular';

import {
	OnDestroy,
	OnInit,
	Directive
} from '@vbrick/angular-ts-decorators';
import { Subscription } from 'rxjs';

import { MediaFeaturesService } from 'rev-shared/media/MediaFeatures.Service';
import { TeamListingsService } from 'rev-portal/media/teams/TeamListings.Service';

@Directive({
	selector: '[media-menu-data-manager]',
	bindToController: true
})
export class MediaMenuDataManagerDirective implements OnDestroy, OnInit {
	public firstFetchComplete: boolean;
	public isCategoriesEnabled: boolean;
	public isFetching: boolean;
	public sub: Subscription;

	public readonly maxVisibleTeams: number = 12;

	constructor(
		private $q: IQService,
		private MediaFeatures: MediaFeaturesService,
		public TeamListingsService: TeamListingsService
	) {
		'ngInject';
	}

	public ngOnInit(): void {
		this.firstFetchComplete = false;
		this.isFetching = false;

		this.sub = this.TeamListingsService.dirty$.subscribe(() => {
			this.firstFetchComplete = false;
			this.isFetching = false;
		});
	}

	public ngOnDestroy(): void {
		this.sub?.unsubscribe();
	}

	public get hasTeams(): boolean {
		return this.TeamListingsService.teamData.length > 0;
	}

	public fetchAllData(): void {
		if (!this.firstFetchComplete && !this.isFetching) {
			this.isFetching = true;
			this.$q.all([
				this.fetchMyTeams(),
				this.fetchMediaFeatures()
			]).then(() => {
				this.firstFetchComplete = true;
				this.isFetching = false;
			});
		}
	}

	public fetchMediaFeatures(): IPromise<any> {
		return this.MediaFeatures.getFeatures()
			.then(feature => this.isCategoriesEnabled = feature.enableCategories);
	}

	public fetchMyTeams(): IPromise<any> {
		return this.TeamListingsService.listTeams();
	}
}
