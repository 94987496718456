import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
	chain,
	range
} from 'underscore';

import { DateParsersService } from 'rev-shared/date/DateParsers.Service';
import { isUndefined } from 'rev-shared/util';

export interface IUserSessionByPageQuery {
	eventId: string;
	runNumber: number;
	scrollId: string;
	searchTerm: string;
	sortBy: string;
	sortDirection: string;
}

@Injectable()
export class WebcastReportsService {
	constructor(
		private DateParsers: DateParsersService,
		private http: HttpClient
	) {
	}

	public getUserSessionByPage(queryData: IUserSessionByPageQuery): Promise<any> {
		let params = new HttpParams();
		params = queryData.sortBy ? params.append('sortBy', queryData.sortBy) : params;
		params = queryData.sortDirection ? params.append('sortDirection', queryData.sortDirection) : params;
		params = queryData.scrollId ? params.append('scrollId', queryData.scrollId) : params;
		params = queryData.searchTerm ? params.append('searchTerm', queryData.searchTerm) : params;

		return this.http.get<any>(`/analytics/events/${queryData.eventId}/${queryData.runNumber}/user-sessions`,
			{
				params: params.append('timeStamp', new Date().getTime().toString())
			})
			.toPromise();
	}

	public getVideoPerformance(eventId: string, runNumber: number): Promise<any> {
		return this.http.get<any>(`/analytics/events/${eventId}/${runNumber}/performance`)
			.toPromise();
	}

	public getRevConnentUseStatus(eventId: string, runNumber: number): Promise<any> {
		return this.http.get<any>(`/analytics/events/${eventId}/${runNumber}/rev-connect`)
			.toPromise();
	}

	public getVideoReport(eventId: string, runNumber: number): Promise<any> {
		return this.http.get<any>(`/analytics/events/${eventId}/${runNumber}/video-report`)
			.toPromise()
			.then(videoReport => {
				return {
					videoPlayers: this.shapeVideoReportData(videoReport.zones, 'videoPlayers'),
					videoFormats: this.shapeVideoReportData(videoReport.zones, 'videoFormats')
				};
			});
	}

	public getEventActivitySummary(eventId: string, runNumber: number): Promise<any> {
		return this.http.get<any>(`/analytics/events/${eventId}/${runNumber}/summary`)
			.toPromise()
			.then(activity => {

				Object.assign(activity, {
					averageTime: this.DateParsers.parseTimespan(activity.averageTime),
					attendeesDuration: this.DateParsers.parseTimespan(activity.attendeesDuration),
					internalAttendeesDuration: this.DateParsers.parseTimespan(activity.internalAttendeesDuration),
					guestAttendeesDuration: this.DateParsers.parseTimespan(activity.guestAttendeesDuration),
					sessions: (activity.sessions || []).map(session => {
						return {
							key: this.DateParsers.parseTimespan(session.key),
							value: session.value
						};
					})
				});

				//TODO - visit later
				const numZones = 15;
				const zonePoints = activity.zoneCounts;

				activity.zoneCounts = chain(zonePoints)
					.sortBy(count => -count[1])
					.concat(
						range(numZones - zonePoints.length)
							.map(i => ({ key: Array(i + 1).join(' '), value: 0 }))
					)
					.first(numZones)
					.value();

				return activity;
			});
	}

	public getEventPollResults(eventId: string, runNumber: number): Promise<any> {
		return this.http.get<any>(`/scheduled-events/${eventId}/${runNumber}/poll-results`)
			.toPromise();
	}

	private shapeVideoReportData(videoReport: any, fieldName: string): any[] {
		return (videoReport || []).map(zone => (
			Object.assign({ key: zone.key },
				...zone.value[fieldName].map(item => ({ [item.key]: item.value }))
			)
		));
	}
}
