import { Component } from '@angular/core';

import { BaseReportKpiComponent } from 'rev-shared/ui/reports/BaseReportKpi.Component';

@Component({
	selector: 'engagement-report-kpis',
	templateUrl: './EngagementReportKpis.Component.html'
})
export class EngagementReportKpisComponent extends BaseReportKpiComponent {
}
