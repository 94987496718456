import { StateProvider, Transition } from '@uirouter/angularjs';

import { IPortalStartup } from 'rev-portal/PortalStartup.Provider';
import { IVBrickStateDeclaration } from 'rev-shared/ts-utils/IVBrickStateDeclaration';
import { UserContextService } from 'rev-shared/security/UserContext.Service';

export function DownloadDetailsStateConfig($stateProvider: StateProvider) {
	$stateProvider
		.state('download-details', {
			url: '/downloads/:id',
			component: 'downloadDetails',
			authorizationKey: 'admin',
			resolve: {
				init(PortalStartup: IPortalStartup) {
					'ngInject';
					return PortalStartup.$promise;
				},
				accountId(UserContext: UserContextService) {
					'ngInject';
					return UserContext.getAccount().id;
				},
				fileId($transition$: Transition) {
					'ngInject';

					return $transition$.params().id;
				}
			}
		} as IVBrickStateDeclaration);
}
