<div class="viewport-pane host-view">
	<div [ngClass]="styles.pollAuthoringTools">
		<div>
			<button type="button" (click)="poll.open()" [hidden]="!poll.isClosed()" vbUiBtnPrimaryNgx>
				{{ 'Event_OpenPoll' | translate }}
			</button>

			<button type="button" (click)="poll.close()" [hidden]="!poll.isOpen()" vbUiBtnPrimaryNgx>
				{{ 'Event_ClosePoll' | translate }}
			</button>
		</div>
	</div>

	<div [ngClass]="styles.managePollResponseWrapper">
		<h4>{{ poll.question }}</h4>

		<div class="poll-responses" *ngFor="let answer of poll.answers">
			<label class="padding-left-0">{{ answer.text }}</label>
			<div class="vote-bar theme-accent-bg" [ngClass]="answer.count ? 'hasVoteCount theme-accent-font-border' : ''" [ngStyle]="{'width': 80 * answer.count / poll.totalResponses  + '%', 'margin-right':(answer.count > 0) ? '3px' : '0'}">
			</div>
			<span>{{answer.count}}</span>
		</div>
	</div>

</div>
<div [ngClass]="styles.pollSubFooter" class="theme-primary-font-border-fade-50">
	{{ 'Event_Responses' | translate }}: <span class="type-bold">{{poll.totalResponses}}</span>
	<span class="pull-right">{{ 'Event_MultipleChoice' | translate }}
		<span class="type-bold" [hidden]="!poll.multipleChoice">{{ 'Yes' | translate }}</span>
		<span class="type-bold" [hidden]="poll.multipleChoice">{{ 'No' | translate }}</span>
	</span>
	<div layout class="margin-top-10">
		<div>
			<button type="button" [hidden]="!poll.isClosed()" (click)="poll.publish()" vbUiBtnPrimaryNgx>
				{{ 'Event_PublishResults' | translate }}
			</button>
			<button type="button" (click)="poll.unpublish()" [hidden]="!poll.isPublished()" vbUiBtnPrimaryNgx>
				{{ 'Event_HideResults' | translate }}
			</button>
		</div>
		<div class="margin-left-auto">
			<button class="margin-right-10" [hidden]="!poll.canBeEdited" (click)="onEditPoll()" vbUiBtnPrimaryNgx>
					{{ 'Edit' | translate }}
			</button>
			<button [hidden]="!poll.canBeDeleted" (click)="pollDeleted.emit()" vbUiBtnSecondaryNgx>
					{{ 'Delete' | translate }}
			</button>
		</div>
	</div>
</div>
