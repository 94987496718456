<div class="webcast-status-msg" *ngIf="webcast.currentRun.isPreProduction">

	<span class="event-message pre-production" [hidden]="!webcast.currentUser.isEventAdmin">
		<span class="util-uppercase type-bold">{{ 'Event_PreProductionBanner' | translate }}:</span>
		{{ 'Event_PreProductionBanner_AdminMessage' | translate }}
	</span>

	<span class="event-message pre-production util-uppercase" [hidden]="webcast.currentUser.isEventAdmin">
		{{ 'Event_PreProductionBanner' | translate }}
	</span>
</div>
