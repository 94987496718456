import {
	AfterViewInit,
	Directive,
	Input,
	Output
} from '@vbrick/angular-ts-decorators';

import {
	IAugmentedJQuery,
	ITimeoutService
} from 'angular';

import 'jquery-ui/themes/base/core.css';
import 'jquery-ui/themes/base/sortable.css';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widget';
import 'jquery-ui/ui/widgets/mouse';
import 'jquery-ui/ui/widgets/sortable';
import 'jquery-ui-touch-punch';

import { ComponentCallback } from 'rev-shared/ts-utils/ComponentCallback';
import { ComponentCallbackEvent } from 'rev-shared/ts-utils/ComponentCallbackEvent';

const dragClass = 'vb-sortable-dragging';

export interface ISortedEvent {
	item: any;
	oldIndex: number;
	newIndex: number;
}

@Directive({
	selector: '[vb-sortable]'
})
export class SortableDirective implements AfterViewInit {
	@Input() public sortableOptions: any;
	@Input() public sortableDisabled: boolean;
	@Output() public onSort: ComponentCallback;

	private oldIndex: number;

	constructor(
		private $element: IAugmentedJQuery,
		private $timeout: ITimeoutService
	) {
		'ngInject';
	}

	public ngAfterViewInit(): void {
		if(this.sortableDisabled){
			return;
		}

		this.$element.sortable(Object.assign({
			placeholder: 'vb-sortable-placeholder',

			start: (_e: Event, ui: JQueryUI.SortableUIParams): void => {
				ui.helper.addClass(dragClass);

				this.oldIndex = ui.item.index();
			},

			stop: (_e: Event, ui: JQueryUI.SortableUIParams): void => {
				ui.item.removeClass(dragClass);

				this.onSort(new ComponentCallbackEvent({
					oldIndex: this.oldIndex,
					newIndex: ui.item.index()
				} as ISortedEvent));

				this.$timeout();
			}
		},
		this.sortableOptions || {}));
	}

}
