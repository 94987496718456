import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ClipboardModule } from 'ngx-clipboard';
import { UpgradeModule } from '@angular/upgrade/static';

import { ButtonsAngularModule } from 'rev-shared/ui/buttons/ButtonsAngular.Module';
import { ValidationAngularModule } from 'rev-shared/ui/validation/ValidationAngular.Module';

import { LiveSubtitlesModule } from 'rev-shared/ui/liveSubtitles/LiveSubtitles.Module';
import { TranscriptionModule } from 'rev-shared/media/transcription/Transcription.module';

import { EditWebcastNgxComponent } from './EditWebcastNgx.Component';
import { RtmpVideoSourceDetailsComponent } from './RtmpVideoSourceDetails.Component';

const declarations = [
	RtmpVideoSourceDetailsComponent,
	EditWebcastNgxComponent
];

@NgModule({
	declarations,
	exports: [
		EditWebcastNgxComponent
	],
	imports: [
		ButtonsAngularModule,
		ClipboardModule,
		CommonModule,
		FormsModule,
		LiveSubtitlesModule,
		TranscriptionModule,
		TranslateModule,
		UpgradeModule,
		ValidationAngularModule
	]
})
export class EditWebcastAngularModule {}
