import {
	Component, Input, OnInit
} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import styles from './EventRegistration.module.less';

export const EventRegistrationPreviewWindowClass = styles.previewWindow;

@Component({
	selector: 'event-registration-preview',
	templateUrl: './EventRegistrationPreview.Component.html'
})
export class EventRegistrationPreviewComponent implements OnInit {
	@Input() public webcast: any;

	public readonly styles = styles;

	constructor(
		public bsModalRef: BsModalRef
	) { }

	public ngOnInit(): void {
		this.webcast = {
			...this.webcast,
			backgroundImages: null,
			isPasswordRequired: !!this.webcast.password
		};
	}

	public close(): void {
		this.bsModalRef.hide();
	}
}
