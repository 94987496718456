import type { NgZone } from '@angular/core';
import { ILogService, IPromise, IQService, ITimeoutService } from 'angular';
import { IStateService } from 'angular-ui-router';
import { StateService, StateProvider, Transition } from '@uirouter/angularjs';
import { ResolveTypes, StateService as StateServiceAngular } from '@uirouter/angular';
import { Subscription } from 'rxjs';
import { first, tap } from 'rxjs/operators';

import { AnalyticsService } from 'rev-shared/analytics/Analytics.Service';
import { IMediaFeatures } from 'rev-shared/media/IMediaFeatures';
import { IUnsubscribe } from 'rev-shared/push/IUnsubscribe';
import { IVBrickStateDeclaration } from 'rev-shared/ts-utils/IVBrickStateDeclaration';
import { MediaFeaturesService } from 'rev-shared/media/MediaFeatures.Service';
import { PushBus } from 'rev-shared/push/PushBus.Service';
import { ThemeService } from 'rev-portal/branding/Theme.Service';
import { SecurityContextService } from 'rev-shared/security/SecurityContext.Service';
import { SecurityRedirectHelperService } from 'rev-shared/security/SecurityRedirectHelper.Service';
import { SessionService, ISessionKeepalive } from 'rev-shared/security/Session.Service';
import { UserAccountService } from 'rev-shared/security/UserAccount.Service';
import { UserContextService } from 'rev-shared/security/UserContext.Service';
import { retryUntilSuccess } from 'rev-shared/util/PromiseUtil';

import { IPortalStartup } from 'rev-portal/PortalStartup.Provider';
import { VIDEO_PLAYBACK_STATE_NAME } from 'rev-portal/media/videos/videoPlayback/Constants';
import { VC_STATUS } from 'rev-portal/scheduledEvents/webcast/model/VcStatus';

import { INotifications } from './Webcast.Contract';
import { ViewingHoursNotAvailable, LIVE_SUBTITLES_ERRORS } from '../editWebcast/EditWebcast.Service';
import { ShortcutService, IWebcastInfo } from './Shortcut.Service';
import { initializeWebcastTheme } from './WebcastBranding';
import { WebcastModel } from './model/WebcastModel';
import { WebcastModelService } from './model/WebcastModel.Service';
import { WebcastService } from './Webcast.Service';
import { WebcastSidebarNotificationsService } from './WebcastSidebarNotificationsService';
import { WEBCAST_ROOT_STATE, WEBCAST_LANDING_STATE, CALENDAR_STATE_NAME, WEBCAST_VIEW_STATE, INFO_STATE } from './Constants';

type IIsFeatureEnabled = (WebcastModel: WebcastModel) => boolean;

const SHOW_POPUP_ERRORS = [
	ViewingHoursNotAvailable,
	...LIVE_SUBTITLES_ERRORS
];

export function configureStates($stateProvider: StateProvider): void {

	$stateProvider

		.state(WEBCAST_ROOT_STATE, {
			url: '/events',
			template: `<ui-view>
				<vb-centered-spinner></vb-centered-spinner>
			</ui-view>`,
			params: {
				shortcutName: null,
			},
			allowRegisteredGuestAccess: true,
			resolve: {
				eventAuthorization(SecurityContext: SecurityContextService, $q: IQService) {
					'ngInject';
					return $q.when(SecurityContext.$promise).then(() => {
						return {
							add: SecurityContext.checkAuthorization('events.add'),
							view: SecurityContext.checkAuthorization('events.view')
						};
					});
				},

				shortcutName($transition$: Transition): string {
					'ngInject';
					return $transition$.params().shortcutName;
				},

				shortcutEvents(
					$q: IQService,
					shortcutName: string,
					ShortcutService: ShortcutService
				): IPromise<IWebcastInfo[]> {
					'ngInject';
					if(shortcutName) {
						return ShortcutService.getWebcasts(shortcutName)
							.then(events => {
								if(!events.length) {
									$q.reject({ status: 404 });
								}

								events = events.filter(e => e.canJoin);
								return events.length ?
									events :
									$q.reject({ status: 401 });
							});
					}
				},

				defaultShortcutEvent(shortcutEvents: IWebcastInfo[], ShortcutService: ShortcutService) {
					'ngInject';
					if(shortcutEvents) {
						return ShortcutService.selectDefaultEvent(shortcutEvents);
					}
				}
			},

			redirectTo(transition: Transition): any {
				const webcastId = transition.params().webcastId as string;

				if(!webcastId) {
					return transition.injector().getAsync<IWebcastInfo>('defaultShortcutEvent')
						.then(event => event ?
							transition.targetState()
								.withState(WEBCAST_LANDING_STATE)
								.withParams({ webcastId: event.id })
								.withOptions({ location: false }) :
							CALENDAR_STATE_NAME);
				}
			}

		} as IVBrickStateDeclaration)

		.state(WEBCAST_LANDING_STATE, {
			url: '/:webcastId?defaultStartDate&issue',
			component: 'webcastLandingPage',
			params: {
				webcastId: null,
				defaultStartDate: { value: null },
				issue: null,
				awaitWebcastCreate: null,
				cloneWebcastId: null,
				blockAutoJoin: { value: false, type: 'bool', dynamic: true }
			},
			allowRegisteredGuestAccess: true,
			scrollToTop: true,
			redirectTo(transition: Transition) {
				const injector = transition.injector();
				const UserContext = injector.get<UserContextService>('UserContext');
				const $q = injector.get<IQService>('$q');
				const webcastId = transition.params().webcastId as string;
				const blockAutoJoin = transition.params().blockAutoJoin as boolean;
				if(!webcastId) {
					return;
				}
				//workaround ui-router issue in chrome with hitting the back button while under
				//portal.scheduledEvents.webcast.view, which would mistakenly match to this state
				if (webcastId === 'all') {
					return {
						state: 'portal.scheduledEvents'
					};
				}
				return $q.all([
					injector.getAsync<WebcastModel>('webcast'),
					injector.getAsync('shortcutEvents'),
					injector.getAsync('webcastLinkedVideo')
				]).then(([webcast, shortcuts]) => {
					const { preProductionRun, productionRun, webcastStatus } = webcast;
					const hasShortcuts = shortcuts && shortcuts.length > 1;

					if(
						!hasShortcuts &&
						!webcast.currentUser.canEdit &&
						webcastStatus.isCompleted &&
						webcast.linkedVideo &&
						webcast.linkedVideo.isActive &&
						webcast.redirectVod
					) {
						return {
							state: VIDEO_PLAYBACK_STATE_NAME,
							params: { videoId: webcast.linkedVideo.id }
						};
					}

					if (!UserContext.isUserLoggedIn()) {
						const $state = injector.get<StateService>('$state');
						return $state.target('eventRegistration', { webcastId }, { location: true });
					}

					if(blockAutoJoin) {
						return;
					}

					//Event admins are only autojoined if the webcast is in progress or automated
					if(!webcast.webcastStatus.isStarted &&
						webcast.currentUser.canEdit &&
						!(webcast.automatedWebcast && webcast.isReadyToStart)
					) {
						return;
					}

					const { canJoin, canJoinPreProduction } = webcast.currentUser;
					const shouldAutoJoinPreProduction = preProductionRun && preProductionRun.isInProgress || !canJoin;
					const shouldAutoJoin = !productionRun.isEnded && (
						productionRun.isStarted ||
						!canJoinPreProduction
					);

					if(canJoinPreProduction && shouldAutoJoinPreProduction) {
						return {
							state: WEBCAST_VIEW_STATE,
							params: { webcastId, preProd: true }
						};
					}
					if(canJoin && shouldAutoJoin) {
						return WEBCAST_VIEW_STATE;
					}
				});

			},
			resolve: {
				webcastId($transition$: Transition) {
					'ngInject';

					return $transition$.params().webcastId;
				},
				defaultStartDate($transition$: Transition) {
					'ngInject';
					const date = $transition$.params().defaultStartDate as string;
					return date && new Date(+date);
				},
				cloneWebcastId($transition$: Transition) {
					'ngInject';
					return $transition$.params().cloneWebcastId;
				},
				issue($transition$: Transition) {
					'ngInject';
					return $transition$.params().issue;
				},
				clearGuestToken(UserContext: UserContextService, webcastId: string) {
					'ngInject';
					if(UserContext.isRegisteredGuest() && webcastId !== UserContext.getUser().webcastId) {
						return UserContext.logOutUser(false);
					}
				},
				webcast(
					$q: IQService,
					$log: ILogService,
					$transition$: Transition,
					WebcastService: WebcastService,
					eventAuthorization: { add: boolean; view: boolean },
					webcastId: string,
					clearGuestToken: void
				) {
					'ngInject';
					if (!webcastId) {
						return eventAuthorization.add ?
							undefined :
							$q.reject({ status: 401 });
					}

					const tryLoadWebcast = () => Promise.resolve(WebcastService.getWebcast(webcastId, true));
					const loadWebcastResult = $transition$.params().awaitWebcastCreate ?
						retryUntilSuccess(tryLoadWebcast) :
						tryLoadWebcast();

					return loadWebcastResult
						.catch(e => {
							$log.error(e);
							if(e.status === 401) {
								return $q.reject({ status: 401, reason: 'event' });
							}
							throw new Error('Error loading webcast');
						});
				},

				unsubscribeWebcastPush(WebcastModelService: WebcastModelService, webcast: WebcastModel) {
					'ngInject';
					return webcast && WebcastModelService.registerWebcastPushHandlers(webcast);
				},

				selectedShortcutSubscription(
					$state: IStateService,
					shortcutName: string,
					shortcutEvents: IWebcastInfo[],
					webcast: WebcastModel) {
					'ngInject';

					const webcastShortcutInfo = shortcutEvents && shortcutEvents.find(e => e.id === webcast.id);

					return webcastShortcutInfo &&
						webcast.webcast$
							.subscribe(() => {
								if(webcast.shortcutName !== shortcutName) {
									$state.go(WEBCAST_LANDING_STATE, { shortcutName: null });
								}

								Object.assign(webcastShortcutInfo, {
									title: webcast.title,
									startDate: webcast.startDate,
									endDate: webcast.endDate,
									lobbyTimeMinutes: webcast.lobbyTimeMinutes,
									status: webcast.status
								});
							});
				},
				mediaFeatures(MediaFeatures: MediaFeaturesService) {
					'ngInject';
					return MediaFeatures.getFeatures();
				},

				initFeatureSettings(mediaFeatures: IMediaFeatures, webcast: WebcastModel) {
					'ngInject';
					if(webcast) {
						webcast.initFeatures(mediaFeatures);
					}
				},
				webcastLinkedVideo(webcast: WebcastModel, WebcastModelService: WebcastModelService, $log: ILogService) {
					'ngInject';
					if(webcast) {
						return WebcastModelService.updateLinkedVideo(webcast)
							.catch(e => {
								$log.error('Error loading recording vod: ', e);
							});
					}
				}
			},
			onExit(UserContext: UserContextService, unsubscribeWebcastPush: IUnsubscribe, selectedShortcutSubscription: Subscription) {
				'ngInject';
				if (UserContext.isRegisteredGuest()) {
					UserContext.logOutUser(false);
				}
				if (unsubscribeWebcastPush) {
					unsubscribeWebcastPush();
				}
				if(selectedShortcutSubscription){
					selectedShortcutSubscription.unsubscribe();
				}
			}
		} as IVBrickStateDeclaration)

		.state(WEBCAST_VIEW_STATE, {
			url: '/view?{preProd:bool}',
			allowRegisteredGuestAccess: true,
			views: {
				'': {
					component: 'webcast'
				}
			},
			resolve: {
				isPreProd($transition$: Transition): boolean {
					'ngInject';
					return $transition$.params().preProd;
				},
				themeInitialize(webcast: WebcastModel, ThemeService: ThemeService, ngZone: NgZone) {
					'ngInject';
					return initializeWebcastTheme(webcast, ThemeService, ngZone);
				},
				realTimeAnalyticsSettings(webcast: WebcastModel, AnalyticsService: AnalyticsService) {
					'ngInject';
					if (!webcast.currentUser.isEventAdminOrModerator) {
						return;
					}
					return AnalyticsService.getSettings();
				},
				webcastConnection(
					isPreProd: boolean,
					WebcastModelService: WebcastModelService,
					webcast: WebcastModel,
					$timeout: ITimeoutService,
					$state: StateService,
					$q: IQService,
					$transition$: Transition) {
					'ngInject';

					if(isPreProd ?
						!webcast.currentUser.canJoinPreProduction :
						!webcast.currentUser.canJoin) {
						return $q.reject({ status: 401 });
					}

					webcast.assignCurrentRun(isPreProd);

					return WebcastModelService.connect(webcast)
						.catch(err => {
							if(err.hasIssue) {
								if(err.hasIssue('AttendeeRemoved')){
									return $q.reject({ status:401, data: { reason:'AttendeeRemoved', noLoginRedirect: true } });
								}

								const issue = SHOW_POPUP_ERRORS.find(error => err.hasIssue(error));

								if (issue) {
									openWebcastWithError($state, webcast.id, issue);
								} else if (err.hasIssue('VcStreamingDeviceNotFound')
									|| err.hasIssue('VcStreamingDeviceNotFoundForTheAccount')
									|| err.hasIssue('VcStreamingDeviceExceedsCapacity')) {

									webcast.vcStatus.update(VC_STATUS.Starting, false);

								}
							}

							return $q.reject(err);
						});
				},
				sessionKeepAlive(Session: SessionService) {
					'ngInject';
					return Session.createKeepalive().beginWhenConnected();
				},
				pollsPushSubscription(webcast: WebcastModel): Subscription {
					'ngInject';
					return webcast.polls.subscribePush().subscribe();
				},
				sidebarNotifications(WebcastSidebarNotificationsService: WebcastSidebarNotificationsService, webcast: WebcastModel) {
					'ngInject';
					return WebcastSidebarNotificationsService.getSidebarNotifications(webcast);
				}
			},

			onExit(
				webcast: WebcastModel,
				sessionKeepAlive: ISessionKeepalive,
				webcastConnection: Subscription,
				sidebarNotifications: INotifications,
				pollsPushSubscription: Subscription,
				themeInitialize: Subscription,
				ThemeService: ThemeService
			) {
				'ngInject';

				sessionKeepAlive.end();

				sidebarNotifications.subscription.unsubscribe();

				if(webcastConnection) {
					webcastConnection.unsubscribe();
				}

				webcast.attendeeQuestionsSubscription?.unsubscribe();

				if(pollsPushSubscription){
					pollsPushSubscription.unsubscribe();
				}

				themeInitialize?.unsubscribe();
				if (ThemeService.isCustomThemeActive) {
					ThemeService.endCustomThemeMode();
				}

				webcast.assignCurrentRun(undefined);
			}
		} as IVBrickStateDeclaration)

		.state(INFO_STATE, {
			url: '/info',
			views: {
				[`right-sidebar-content@${WEBCAST_VIEW_STATE}`]: {
					component: 'webcastInfoSidebar'
				}
			},
			allowRegisteredGuestAccess: true,
			resolve: {
				eventUrl(ShortcutService: ShortcutService, webcast: WebcastModel) {
					'ngInject';
					return ShortcutService.getWebcastUrl(webcast);
				}
			}
		} as IVBrickStateDeclaration)

		.state(`${INFO_STATE}.edit`, {
			url: '/edit',
			views: {
				[`content@${INFO_STATE}`]: {
					component: 'editWebcast'
				}
			},
			resolve: {
				isSidebar: () => true,

				onSaved($state) {
					'ngInject';
					return () => {
						$state.go(INFO_STATE, null, { location: false });
					};
				},

				requireFeature: requireFeatureForSidebar(w => w.currentUser.canEdit)
			},

			onExit: unsubscribeRequiredFeature
		});
}

export function requireFeatureForSidebar(isFeatureEnabled: (WebcastModel: WebcastModel) => boolean): any {
	return (webcast: WebcastModel, $state: StateService) => {
		'ngInject';
		return webcast.webcast$.pipe(
			first(w => !isFeatureEnabled(w)),
			tap(() => {
				$state.go(WEBCAST_VIEW_STATE);
				webcast.layout.isRightSidebarOpen = false;
			})
		)
			.subscribe();
	};
}

function requireFeatureForPopupInternal(
	isFeatureEnabled: IIsFeatureEnabled,
	webcast: WebcastModel,
	$state: StateService | StateServiceAngular,
	SecurityRedirectHelper: SecurityRedirectHelperService
) {
	return webcast.webcast$.pipe(
		first(w => !isFeatureEnabled(w)),
		tap(() => {
			SecurityRedirectHelper.onError({ status: 401 }, $state.$current.name);
		})
	)
		.subscribe();
}

export function requireFeatureForPopup(isFeatureEnabled: IIsFeatureEnabled): any {
	return (webcast: WebcastModel, $state: StateService, SecurityRedirectHelper: SecurityRedirectHelperService) => {
		'ngInject';
		requireFeatureForPopupInternal(isFeatureEnabled, webcast, $state, SecurityRedirectHelper);
	};
}

export function requireFeatureForPopupNgx(token: string, isFeatureEnabled: IIsFeatureEnabled): ResolveTypes {
	return {
		token,
		deps: [
			'webcast',
			SecurityRedirectHelperService,
			StateServiceAngular
		],
		resolveFn(
			webcast: WebcastModel,
			securityRedirectHelper: SecurityRedirectHelperService,
			$state: StateServiceAngular
		) {
			requireFeatureForPopupInternal(isFeatureEnabled, webcast, $state, securityRedirectHelper);
		}
	};
}

export function unsubscribeRequiredFeature(requireFeature: Subscription) {
	'ngInject';
	requireFeature.unsubscribe();
}

function initCurrentRunFullscreenInternal(webcast: WebcastModel) {
	const { preProductionRun, productionRun } = webcast;
	const isPreProd = preProductionRun &&
		preProductionRun.isStarted &&
		webcast.currentUser.canJoinPreProduction;
	const isProd = productionRun &&
		productionRun.isStarted &&
		webcast.currentUser.canJoin;

	if(!isPreProd && !isProd) {
		return Promise.reject({ status: 404 });
	}

	webcast.assignCurrentRun(isPreProd);
}

function openWebcastWithError($state:any, webcastId: string, issue: string) {
	setTimeout(() => {
		$state.go('portal.scheduledEvents.webcast', {
			webcastId,
			issue
		}, {
			reload: true
		});
	});
}

export function initCurrentRunFullscreen(webcast: WebcastModel): Promise<void> {
	'ngInject';

	return initCurrentRunFullscreenInternal(webcast);
}

export const initCurrentRunFullscreenNgxResolve: ResolveTypes = {
	token: 'initCurrentRunFullscreen',
	deps: ['webcast'],
	resolveFn(webcast: WebcastModel) {
		return initCurrentRunFullscreenInternal(webcast);
	}
};

function revConnectLicenseInternal(UserAccount: UserAccountService, webcast: WebcastModel) {
	return UserAccount.fetchAccountDetail(webcast.accountId)
		.then(({ revConnectLicense }) => revConnectLicense);
}

export function revConnectLicense(UserAccount: UserAccountService, webcast: WebcastModel): IPromise<any> {
	'ngInject';

	return revConnectLicenseInternal(UserAccount, webcast);
}

export const revConnectLicenseNgxResolve: ResolveTypes = {
	token: 'revConnectLicense',
	deps: [
		UserAccountService,
		'webcast'
	],
	resolveFn(userAccountService: UserAccountService, webcast: WebcastModel) {
		return revConnectLicenseInternal(userAccountService, webcast);
	}
};
