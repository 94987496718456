<vb-confirmation-dialog [title]="'Error' | translate" [message]="'Event_UnableToControlAutomatedWebcastManually' | translate" [actionText]="'Ok' | translate">
</vb-confirmation-dialog>

<div flex-align="center" flex-justify="end" layout-wrap="false" layout="row" [ngClass]="styles.wrapper">
	<h2 flex="fill" [ngClass]="styles.title" [hidden]="webcast.currentUser.isEventAdmin" title="{{webcast.title }}">
		{{ webcast.title }}
	</h2>

	<div [ngClass]="styles.webcastButtons">
		<!--Toggle Visibility of Regions-->
		<webcast-region-visibility-toggle [ngClass]="styles.webcastRegionVisibilityToggle" [hidden]="!webcast.layout.displayLayoutControls" [webcast]="webcast">
		</webcast-region-visibility-toggle>

		<!--Toggle View-->
		<div [hidden]="!webcast.layout.canToggleView" class="btn-group">
			<button type="button" [ngClass]="[
					styles.webcastNavBtn,
					styles.webcastNavToggleViewBtn
				]" [disabled]="!webcast.layout.isVideoVisible || !webcast.layout.isPresentationVisible" (click)="toggleLayout()" vbUiBtnSecondaryNgx [inactive]="!webcast.layout.isVertical">
				{{ 'Event_ToggleLayout' | translate }}
			</button>
		</div>

		<!-- Record -->
		<div *ngIf="webcast.isRecordingAllowed() && webcast.currentUser.isEventAdmin" class="btn-group">
			<webcast-recording-button [webcast]="webcast">
			</webcast-recording-button>
		</div>

		<!-- Webcast State Controls -->
		<div [ngClass]="styles.webcastStateControls" class="btn-group" *ngIf="webcast.currentUser.isEventAdmin">

			<!--End-->
			<button *ngIf="webcast.currentUser.canControlEventStatus && !webcast.webcastStatus.isEnded" [disabled]="webcast.broadcast.isStopping || webcast.broadcast.isStarting" [ngClass]="styles.webcastNavBtn" type="button" vbUiBtnSecondaryNgx (click)="endEvent()">

				<span [hidden]="!webcast.currentRun.isMainEvent">
					{{ 'Event_EndEvent' | translate }}
				</span>
				<span [hidden]="!webcast.currentRun.isPreProduction">
					{{ 'Event_EndPreProduction' | translate }}
				</span>
			</button>

			<!--Broadcast controls -->
			<webcast-broadcast-button [webcast]="webcast" [hidden]="webcast.isAutomated">
			</webcast-broadcast-button>

			<!-- Manual Control (automated webcast) -->
			<button *ngIf="webcast.isAutomated && webcast.currentRun.isMainEvent && webcast.currentRun.isStarted" [ngClass]="styles.webcastNavBtn" type="button" vbUiBtnSecondaryNgx (click)="cancelAutomatedWebcast()">
				{{ 'Event_ManualControlAutomatedWebcast' | translate }}
			</button>

		</div>
	</div>
</div>
