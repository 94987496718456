<header class="right-sidebar-content-header">
	{{ 'Event_NumberOfAttendees' | translate }}&nbsp;&nbsp;{{ attendeeCount | number:0 }}
</header>

<div class="attendee-box">
	<!--Search form-->
	<form *ngIf="isGridReady" class="searchGroup" (submit)="searchInput = searchTxt.value" [ngClass]="{active: searchInput}">
		<div class="input-group" role="group" [attr.aria-label]="'Event_AttendeesSearch' | translate">
			<input type="text" #searchTxt class="form-control" name="txtSearch" [attr.aria-label]="'Event_AttendeesSearchInput' | translate">
			<span class="input-group-btn">
				<button vbUiBtnPrimaryNgx [attr.aria-label]="'Search' | translate" class="search-btn" type="submit"><span class="glyphicons search"></span></button>
			</span>
		</div>
		<button type="button" vbUiBtnSecondaryNgx class="margin-left-16" [attr.aria-label]="(grid.isPollingActive ? 'Event_AttendeesList_PauseUpdates' : 'Event_AttendeesList_ResumeUpdates') | translate" [title]="(grid.isPollingActive ? 'Event_AttendeesList_PauseUpdates' : 'Event_AttendeesList_ResumeUpdates') | translate" (click)="grid.isPollingActive ? grid.pausePolling() : grid.resumePolling()">
			<span class="glyphicons" [ngClass]="grid.isPollingActive ? 'pause' : 'repeat'">
			</span>
		</button>
	</form>

	<vb-ui-infinite-scroll-grid [getRows]="AttendeeService.getRows" [clearScrollId]="AnalyticsService.clearEventScrollId" [quickFilterText]="searchInput" (gridReady)="onGridReady()" [themed]="true" class="list-container">


		<vb-ui-data-grid-column [filter]="true" [field]="'FullName'">
		</vb-ui-data-grid-column>

	</vb-ui-infinite-scroll-grid>
</div>
