import { Injectable } from '@vbrick/angular-ts-decorators';
import { IRootScopeService, IAngularEvent } from 'angular';
import { Subject, Observable } from 'rxjs';

interface ILocationChangeStartData {
	event: IAngularEvent;
	oldUrl: string;
	newUrl: string;
}

/**
 * There's no alternative to this $rootScope usage to access the $locationChangeStart framework event,
 * so this service encapsulates it as an Observable to better ease the transition int Angular.
 */
@Injectable('RxWindowLocation')
export class RxWindowLocationService {
	private readonly locationChangeStartSubject$: Subject<ILocationChangeStartData> = new Subject<ILocationChangeStartData>();

	public locationChangeStart$: Observable<ILocationChangeStartData> = this.locationChangeStartSubject$.asObservable();

	constructor(
		$rootScope: IRootScopeService
	) {
		'ngInject';

		$rootScope.$on('$locationChangeStart', (event: IAngularEvent, newUrl: string, oldUrl: string) =>
			this.locationChangeStartSubject$.next({
				event,
				newUrl,
				oldUrl
			})
		);
	}
}
