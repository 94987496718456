import {
	Component,
	Input,
	ViewChild
} from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import { VbConfirmationDialogComponent } from 'rev-shared/ui/dialog/VbConfirmationDialogAngular.Component';
import { FileWrapper } from 'rev-shared/ui/fileUpload/FileWrapper';

import { PresentationFileStatus } from './PresentationFileStatus';
import { RecordingStatus } from '../webcast/model/RecordingStatus';
import { WebcastModel } from '../webcast/model/WebcastModel';
import { WebcastPresentationService } from './WebcastPresentation.Service';

import './webcast-presentation-slides.less';

@Component({
	selector: 'webcast-presentation-slides',
	templateUrl: './WebcastPresentationSlides.Component.html'
})
export class WebcastPresentationSlidesComponent {
	@Input() public webcast: WebcastModel;

	@ViewChild(VbConfirmationDialogComponent)
	public removePresentationConfirmation: VbConfirmationDialogComponent;

	public context$: Observable<any>;

	constructor(
		private WebcastPresentationService: WebcastPresentationService
	){}

	public ngOnInit(): void {
		this.context$ = this.webcast.webcast$
			.pipe(
				map(webcast => {
					return {
						canEditSlides: this.canEditSlides(webcast)
					};
				})
			);
	}

	public uploadPresentation(file: FileWrapper): void {
		this.WebcastPresentationService.uploadPresentationFile(file, this.webcast.id);
	}

	public removePresentation(): void {
		this.removePresentationConfirmation.open().result
			.then(() => {
				this.WebcastPresentationService.removePresentation(this.webcast.id);
			});
	}

	public canEditSlides(webcast: WebcastModel): boolean {
		const recording: RecordingStatus = webcast.recording;
		const presentationStatus: PresentationFileStatus = webcast.presentation.status;

		return !recording.isActive &&
			!recording.isProcessingRequest &&
			!webcast.vcSipAddress &&
			!webcast.vcMicrosoftTeamsMeetingUrl &&
			presentationStatus !== PresentationFileStatus.Uploading &&
			presentationStatus !== PresentationFileStatus.ProcessingImages;
	}
}
