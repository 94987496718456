import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

import { ButtonsAngularModule } from 'rev-shared/ui/buttons/ButtonsAngular.Module';
import { DateAngularModule } from 'rev-shared/date/DateAngular.Module';
import { DirectivesAngularModule } from 'rev-shared/util/directives/DirectivesAngular.Module';
import { PipesAngularModule } from 'rev-shared/pipes/Pipes.AngularModule';

import { BrandingAngularModule } from 'rev-portal/branding/BrandingAngular.Module';

import { WebcastStartAndEndMessageComponent } from './WebcastStartAndEndMessage.Component';


const components = [
	WebcastStartAndEndMessageComponent,
];

@NgModule({
	declarations: components,
	exports: components,
	imports: [
		BrandingAngularModule,
		ButtonsAngularModule,
		CommonModule,
		DateAngularModule,
		DirectivesAngularModule,
		FormsModule,
		PipesAngularModule,
		TranslateModule
	]
})
export class WebcastStartAndEndMessageModule {}
