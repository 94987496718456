
<ng-template [vbCssRules]="themeStyleOverrides"></ng-template>
<vb-loading-spinner *ngIf="status.isLoading" [block]="true" [size]="'medium'">
</vb-loading-spinner>

<section *ngIf="view === POLLS_VIEW.List" class="height-full">
	<div class="text-align-center" [hidden]="!(!polls.length)">
		<h3>{{ 'Event_NoPollsMessage' | translate }}</h3>
		<button [hidden]="!(webcast.currentUser.canManagePolls)" vbUiBtnPrimaryNgx (click)="setCreateView()">
			{{ 'Event_AddPoll' | translate }}
		</button>
	</div>
	<div *ngIf="status.loaded" [hidden]="!polls.length" [ngClass]="[
			styles.pollInfoWrapper,
			webcast.currentUser.canManagePolls ? 'edit' : ''
		]" class="height-full">
		<vb-flickity-carousel class="margin-top-10 height-full" [ngClass]="styles.flickityCarousel" [flickityConfig]="flickityConfig" (onReady)="onFlickityReady($event.carousel)" (onSelect)="onSelectFlickity()">

			<div class="carousel-cell" [ngClass]="styles.carouselEntry" *ngFor="let poll of polls.$polls">
				<vb-webcast-polls-manage-view *ngIf="webcast.currentUser.canManagePolls; else attendeeView;" [poll]="poll" (pollEdited)="$event?.isNew ? setCreateView() : setEditView(poll)" (pollDeleted)="deletePoll(poll)">
				</vb-webcast-polls-manage-view>
				<ng-template #attendeeView>
					<vb-webcast-polls-attendee-view [poll]="poll">
					</vb-webcast-polls-attendee-view>
				</ng-template>
			</div>
		</vb-flickity-carousel>
		<div flex-justify="center" layout full-width>
			<button *ngIf="webcast.currentUser.canManagePolls" vbUiBtnPrimaryNgx (click)="setCreateView()" class="margin-top-5">
				<span class="glyphicons plus"></span>
				{{ 'Event_CreatePoll' | translate }}
			</button>
		</div>
	</div>
</section>

<vb-webcast-edit-poll-view *ngIf="view === POLLS_VIEW.Edit" [poll]="pollToEdit" (saved)="savePollForm($event.poll)" (cancelled)="setListView()">
</vb-webcast-edit-poll-view>

