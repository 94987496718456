import { Pipe, PipeTransform } from '@vbrick/angular-ts-decorators';
import { isArray } from 'angular';

/**
 * This function filters a collection of objects and
 * the filer criteria can be part of more than one property.
 * The primary usage of this filter is with ui-select control.
 */
@Pipe({ name: 'propertyFilter' })
export class UISelectPropertyFilter implements PipeTransform {

	public transform(items: any, props: any): any[] {
		let output: any[] = [];

		if (isArray(items)) {
			const keys: string[] = Object.keys(props);
			output = items.filter(item => this.matchItem(item, keys, props));
		} else {
			// Let the output be the input untouched
			output = items;
		}
		return output;
	}

	private matchItem(item: any, keys: string[], props: any): boolean {
		let isMatch: boolean = false;
		for (const key of keys) {
			const text: string = props[key].toLowerCase();
			if ((item[key] || '').toString().toLowerCase().includes(text)) {
				isMatch = true;
				break;
			}
		}
		return isMatch;
	}

}
