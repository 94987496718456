import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { TranslateModule } from '@ngx-translate/core';
import { VbrickPlayerModule } from '@vbrick/vbrick-player';

import { VideoPlayerAngularModule } from 'rev-shared/videoPlayer/VideoPlayerAngular.Module';

import { VbWebcastPlayerComponent } from './VbWebcastPlayer.Component';

const components = [
	VbWebcastPlayerComponent
];

@NgModule({
	declarations: components,
	entryComponents: components,
	exports: components,
	imports: [
		CommonModule,
		HttpClientModule,
		TranslateModule,
		VbrickPlayerModule,
		VideoPlayerAngularModule
	]
})
export class VbWebcastPlayerModule {}
