import { NgModule } from '@vbrick/angular-ts-decorators';
import BootstrapTypeaheadModule from 'angular-ui-bootstrap/src/typeahead';

import ButtonsModule from 'rev-shared/ui/buttons/Buttons.Module';
import VbTextInputModule from 'rev-shared/ui/text-input/VbTextInput.Module';

import BrandingModule from 'rev-portal/branding/Branding.Module';

import { VbUiInlineTextEditComponent } from './VbUiInlineTextEdit.Component';
import { VbUiInlineEditBooleanComponent } from './VbUiInlineEditBoolean.Component';
import { VbUiTypeaheadComponent } from './VbUiTypeahead.Component';
import { downgradeComponents } from 'rev-shared/util/AngularHybridUtils';

@NgModule({
	id: 'Vbrick.UI.InlineEdit',
	declarations: [
		VbUiInlineEditBooleanComponent,
		VbUiTypeaheadComponent
	],
	imports: [
		BootstrapTypeaheadModule,
		BrandingModule,
		ButtonsModule,
		VbTextInputModule
	]
})
export class VbrickUiInlineEditModule {}

downgradeComponents(VbrickUiInlineEditModule, [
	{
		component: VbUiInlineTextEditComponent,
		name: 'vbUiInlineTextEdit'
	}
]);
