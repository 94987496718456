import { NgModule } from '@vbrick/angular-ts-decorators';
import BootstrapTypeaheadModule from 'angular-ui-bootstrap/src/typeahead';

import ButtonsModule from 'rev-shared/ui/buttons/Buttons.Module';
import FormGroupModule from 'rev-shared/ui/formGroup/FormGroup.Module';
import PushDowngradeModule from 'rev-shared/push/Push.downgrade.Module';
import SecurityModule from 'rev-shared/security/Security.Module';
import StatusSpinnersModule from 'rev-shared/statusSpinners/StatusSpinners.Module';
import VBrickFormDirectivesModule from 'rev-shared/util/directives/form/FormDirectives.Module';
import VBrickSharedMediaDowngradeModule from 'rev-shared/media/Media.Module.downgrade';
import VBrickUIButtonsModule from 'rev-shared/ui/buttons/Buttons.Module';
import VBrickUiSpinnerModule from 'rev-shared/ui/spinner/Spinner.Module';
import { downgradeInjectables } from 'rev-shared/util/AngularHybridUtils';

import { WebexTeamsModule } from 'rev-portal/scheduledEvents/webex-teams/WebexTeams.Module';
import { ZoomModule } from 'rev-portal/scheduledEvents/zoom/Zoom.Module';

import { RecordingProcessComponent } from './RecordingProcess.Component';
import { RecordingTimerComponent } from './RecordingTimer.Component';
import { RecordingService } from './Recording.Service';

@NgModule({
	id: 'VBrick.Portal.Media.Recording',
	declarations: [
		RecordingProcessComponent,
		RecordingTimerComponent
	],
	imports: [
		BootstrapTypeaheadModule,
		ButtonsModule,
		FormGroupModule,
		PushDowngradeModule,
		SecurityModule,
		StatusSpinnersModule,
		VBrickFormDirectivesModule,
		VBrickSharedMediaDowngradeModule,
		VBrickUIButtonsModule,
		VBrickUiSpinnerModule,
		WebexTeamsModule,
		ZoomModule
	]
})
export class RecordingModule {}

downgradeInjectables(RecordingModule, [
	{
		name: 'RecordingService',
		injectable: RecordingService
	}
]);
