import { NgModule } from '@vbrick/angular-ts-decorators';
import BootstrapTypeaheadModule from 'angular-ui-bootstrap/src/typeahead';

import PushDowngradeModule from 'rev-shared/push/Push.downgrade.Module';
import VBrickFormDirectivesModule from 'rev-shared/util/directives/form/FormDirectives.Module';
import VBrickSharedSecurityModule from 'rev-shared/security/Security.Module';
import VBrickSharedUtilModule from 'rev-shared/util/Util.Module';
import VBrickUtilDirectivesModule from 'rev-shared/util/directives/Directives.Module';

import SparkModule from 'rev-portal/media/spark/Spark.Module';

import { WebexTeamsComponent } from './WebexTeams.Component';

@NgModule({
	id: 'VBrick.Portal.ScheduledEvents.WebexTeams',
	declarations: [
		WebexTeamsComponent
	],
	imports: [
		BootstrapTypeaheadModule,
		PushDowngradeModule,
		SparkModule,
		VBrickFormDirectivesModule,
		VBrickSharedSecurityModule,
		VBrickSharedUtilModule,
		VBrickUtilDirectivesModule
	]
})
export class WebexTeamsModule {}
