import {
	Component,
	Input,
	OnInit
} from '@vbrick/angular-ts-decorators';

import {
	IAugmentedJQuery,
	ITranscludeFunction
} from 'angular';

import styles from './VbGridList.module.less';

/**
* Generates a flex-based grid based on a supplied configuration.
* Features include:
* - configurable field types (plain text, icon, thumbnail)
* - multiple selection through a checkbox column (row is clickable)
* - optional header
*
* Features coming soon:
* - Interactive column sorting
* - Filtering
* - Single item selection
* - Dynamic field visibility
* - Tile view
* - Infinite scroll
* - Item action menu
*
* <vb-grid-list
* 		empty-msg = a message to display when the items model is empty.
* >
*		<vb-grid-list-row ng-repeat="item in items">
*
*			<header>
*				<div> header content</div>
*				<vb-grid-list-selection-header
					ng-model="$ctrl.isSelectAll"
					collection="$ctrl.webExRecordings" //collection to toggle item selection
					ng-change="<<method>>">  //fires when ng-model changes..
				</vb-grid-list-selection-header>
* 			</header>
*
*			<vb-grid-list-column
*				flex="1" // flex setting for the column
*				align="center" //align setting
*			>
*				{{content...}}
*			</vb-grid-column>
*
*
* 			<vb-grid-list-reorder // will show the arrow mark to move row up and down..
				flex="40px"
				on-item-move="$ctrl.onItemReorder($event.isUp, $index)"> //fires when up or down icon clicks
			</vb-grid-list-reorder>
*
* 			<vb-grid-list-selection-column
				ng-model="recording.selected">
			</vb-grid-list-selection-column>
*
*			<vb-grid-column	vb-grid-toolbar-column> //toolbar column
*				<a>link</a>
*				<button></button>
*			</vb-grid-column>
*
*		</columns>
* </vb-grid-list>
*/

@Component({
	selector: 'vb-grid-list',
	transclude: {
		header: '?header',
		row: 'vbGridRowCollection'
	},
	template: `
		<section ng-transclude ng-transclude-slot="header">
		</section>
		<section
			ng-show="$ctrl.isRowExists"
			ng-transclude ng-transclude-slot="row">
		</section>
		<div ng-hide="$ctrl.isRowExists"
			ng-class="::$ctrl.styles.emptyMsg">
			{{:: $ctrl.emptyMsg}}
		</div>
	`
})
export class VbGridListComponent implements OnInit {
	@Input() private emptyMsg: string;

	private readonly styles = styles;

	public isSelectAll: boolean;

	constructor(
		private $element: IAugmentedJQuery,
		private $transclude: ITranscludeFunction
	) {
		'ngInject';
	}

	public ngOnInit(): void {
		this.$element.addClass(this.styles.gridList);
	}

	public get isRowExists(): boolean {
		return !!this.$element.find('vb-grid-list-row[ng-repeat]').length;
	}
}
