import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges
} from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { IRevConnectLoggingInfo } from '@vbrick/vbrick-player-hls-plugin/IRevConnectInfo';
import { IVbrickPlayerPlaybackChangeEvent } from 'vbrick-player-src/VbrickPlayer.Component';
import { MessageType } from '@vbrick/vbrick-logging-client/src';

import { IVbrickPlayerConfig } from 'vbrick-player-src/IVbrickPlayerConfig';
import { userAgentUtil } from 'vbrick-player-src/UserAgentUtil';

import { BootstrapContext } from 'rev-shared/bootstrap/BootstrapContext';
import { DualPlaybackLayoutOptions } from 'rev-shared/videoPlayer/DualPlaybackLayoutOptions';
import { IMediaFeatures } from 'rev-shared/media/IMediaFeatures';
import { IModernPlayback } from 'rev-shared/videoPlayer/IModernPlayback';
import { MediaFeaturesService } from 'rev-shared/media/MediaFeatures.Service';
import { PlaybackUpdatedSubType } from 'rev-shared/videoPlayer/analytics/PlaybackUpdatedSubType';
import { ResourceType } from 'rev-shared/videoPlayer/ResourceType';
import { SpinnerType } from 'rev-shared/videoPlayer/analytics/SpinnerType';
import { UserContextService } from 'rev-shared/security/UserContext.Service';
import { VideoHeartbeatErrorSubType } from 'rev-shared/videoPlayer/analytics/VideoHeartbeatErrorSubType';
import { VideoHeartbeatPlayerType } from 'rev-shared/videoPlayer/analytics/VideoHeartbeatPlayerType';
import { VideoPlayerAdapterService } from 'rev-shared/videoPlayer/VideoPlayerAdapter.Service';
import { VideoSourceType } from 'rev-shared/media/VideoSourceType';
import { collectionShallowEqual } from 'rev-shared/util';
import { lastValueFrom } from 'rev-shared/rxjs/lastValueFrom';

import { WebcastPlayerLoggingClient, IPlaybackUpdatedData } from 'rev-portal/scheduledEvents/webcast/WebcastPlayerLoggingClient';
import { WebcastPlayerLoggingService } from 'rev-portal/scheduledEvents/webcast/WebcastPlayerLogging.Service';
import { WebcastService } from 'rev-portal/scheduledEvents/webcast/Webcast.Service';

import 'rev-less/video/video-main.less'; //refactored player related css later.

const RESOURCE_TYPE_WEBCAST = 'webcast';
const TOKEN_RESOURCE_AUD = 'request_validator';
const LEGACY_VBRICK_PLAYER = 'Vbrick';

@Component({
	selector: '[vb-webcast-player]',
	host: {
		class: 'vb-player'
	},
	template: `
		<vbrick-player-wmv
			*ngIf="legacyPlaybackUrl"
			auto-play="true"
			can-show-thumbnail="false"
			live="true"
			playback-options="playbacks"
			video-url="legacyPlaybackUrl"
			on-fullscreen-toggled="onWmvFullscreenToggledInternal($event.isFullscreen)"
			on-player-ready="onPlayerReadyInternal($event.vgAPI)">
		</vbrick-player-wmv>
		<vbrick-player
			*ngIf="!!modernPlaybacks"
			[autoPlay]="autoPlay"
			[config]="playerConfig"
			[experiencedBufferingThresholdInMs]="mediaFeatures.bufferingThreshold"
			[flashDisabled]="!mediaFeatures.enableFlashPlayback"
			[heartbeatInterval]="heartbeatIntervalSecs"
			[isCaptionsAvailable]="isCaptionsAvailable"
			[isLive]="true"
			[muteBtnOnLeft]="muteBtnOnLeft"
			[playbackOptions]="modernPlaybacks"
			[translations]="vbrickPlayerTranslations"

			(onBufferingStart)="onBufferingStart($event.isInitial)"
			(onBufferingStop)="onBufferingStop($event.isInitial, $event.duration)"
			(onBufferingSpinner)="onBufferingSpinner($event.type, $event.duration)"
			(onCrossOriginError)="onCrossOriginError()"
			(onFailover)="onFailover($event.failoverDetails)"
			(onHeartbeat)="onHeartbeat($event.data)"
			(onInitialPlayback)="onInitialPlayback($event.data)"
			(onManualStreamSwitch)="onManualStreamSwitch($event.data)"
			(onMulticastError)="onMulticastError($event.data)"
			(onPlay)="onPlay($event.isInitial)"
			(onPlaybackError)="onPlaybackError($event.data)"
			(onPlaybackStarted)="onPlaybackStarted($event)"
			(onPlayerReady)="onPlayerReadyInternal($event.vgAPI)"
			(onStop)="onStop($event.src)">
		</vbrick-player>`
})
export class VbWebcastPlayerComponent implements OnInit, OnChanges {
	@Input() public autoPlay: boolean;
	@Input() public heartbeatIntervalSecs: number;
	@Input() public isCaptionsAvailable: boolean;
	@Input() public isVcSource: boolean;
	@Input() public muteBtnOnLeft: boolean;
	@Input() public playbacks: any[];
	@Input() public runNumber: number;
	@Input() public webcastId: string;
	@Input() public webcastSessionId: string;

	@Output() public onPlayerReady: EventEmitter<any> = new EventEmitter();
	@Output() public onWmvFullscreenToggled: EventEmitter<any> = new EventEmitter();

	public readonly DualPlaybackLayoutOptions = DualPlaybackLayoutOptions;

	private loggingClient: WebcastPlayerLoggingClient;
	private vgAPI: any;
	private playback: any;
	public mediaFeatures: IMediaFeatures;
	public legacyPlaybackUrl: string;
	public modernPlaybacks: IModernPlayback[];
	public playerConfig: IVbrickPlayerConfig;
	public vbrickPlayerTranslations: any;

	constructor(
		private http: HttpClient,
		private MediaFeatures: MediaFeaturesService,
		private UserContext: UserContextService,
		private VideoPlayerAdapter: VideoPlayerAdapterService,
		private WebcastPlayerLogging: WebcastPlayerLoggingService,
		private WebcastService: WebcastService
	) {}

	public ngOnInit(): void {
		this.loggingClient = this.WebcastPlayerLogging.getClient(this.webcastId, this.runNumber);
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.playbacks) {
			this.onPlaybacksChange(this.playbacks, changes.playbacks.previousValue);
		}
	}

	public ngOnDestroy(): void {
		if (this.loggingClient) {
			this.loggingClient.destroy();
		}
	}

	public onPlayerReadyInternal(vgAPI: any): void {
		this.vgAPI = vgAPI;
		this.onPlayerReady.emit({ vgAPI } );
	}

	public onBufferingStart(isInitial: boolean): void {
		if (!this.webcastSessionId) { return; }

		this.loggingClient.onBufferingStart(isInitial);
	}

	public onBufferingStop(isInitial: boolean, durationMs: number): void {
		if (!this.webcastSessionId) { return; }

		this.loggingClient.onBufferingStop(isInitial, durationMs);
	}

	public onBufferingSpinner(type: SpinnerType, durationMs: number): void {
		if (!this.webcastSessionId) { return; }

		this.loggingClient.onBufferingSpinner(type, durationMs);
	}

	public onCrossOriginError(): void {
		if (!this.webcastSessionId) { return; }

		this.loggingClient.onError({
			isFatal: false, // presently fired to indicate a retry with Flash
			subType: VideoHeartbeatErrorSubType.CROSS_ORIGIN
		});
	}

	public onFailover({ fromUrl, isToFlash, playback, toUrl }) {
		this.updatePlayback(PlaybackUpdatedSubType.AUTO_SWITCH, toUrl, playback, isToFlash, fromUrl);
	}

	public onPlay(isInitial: boolean): void {
		if (!this.webcastSessionId) { return; }

		this.loggingClient.onPlay(isInitial);
	}

	public onStop(): void {
		if (!this.webcastSessionId) { return; }

		this.loggingClient.onStop();
	}

	public onHeartbeat(data): void {
		if (!this.webcastSessionId) { return; }

		this.loggingClient.onHeartbeat(data);
	}

	public onInitialPlayback(data: any): void {
		this.updatePlayback(PlaybackUpdatedSubType.INITIAL, data.src, data.playback, data.isFlash);
	}

	public onManualStreamSwitch(data: any): void {
		this.updatePlayback(PlaybackUpdatedSubType.MANUAL_SWITCH, data.src, data.playback, data.isFlash);
	}

	public onMulticastError(data: any): void {
		if (!this.webcastSessionId) { return; }

		this.loggingClient.onError({
			isFatal: data.isFatal,
			subType: VideoHeartbeatErrorSubType.MULTICAST,
			zoneId: this.playback.zoneId
		});
	}

	public onPlaybackError(data: any): void {
		if (!this.webcastSessionId) { return; }

		this.loggingClient.onError({
			isFatal: data.isFatal,
			subType: VideoHeartbeatErrorSubType.PLAYBACK,
			zoneId: this.playback.zoneId
		});
	}

	public onPlaybackStarted({ data }: IVbrickPlayerPlaybackChangeEvent): void {
		if (!this.webcastSessionId) { return; }

		this.getPlaybackPayload(data.src, data.playback, data.isFlash)
			.then(payload => this.loggingClient.onPlaybackStarted(payload));
	}

	public updatePlayback(subType: PlaybackUpdatedSubType, toUrl: string, toPlayback: any, isToFlash: boolean, fromUrl?: string): void {
		if (!this.webcastSessionId) { return; }

		this.getPlaybackPayload(toUrl, toPlayback, isToFlash, fromUrl, subType)
			.then(payload => this.loggingClient.onPlaybackUpdated(payload));
	}

	private destroyElementIfExists(): void {
		//check later on do we need to destroy element after conversion to angular.
	}

	private getPlaybackPayload(toUrl: string, toPlayback: any, isToFlash: boolean, fromUrl?: string, subType?: PlaybackUpdatedSubType): Promise<IPlaybackUpdatedData> {
		const isRevConnect: boolean = !!toPlayback.revConnectInfo;

		return this.WebcastService.getWebcast(this.webcastId)
			.then(webcast => {
				this.playback = this.VideoPlayerAdapter.getPlaybackForUrl(toUrl, this.modernPlaybacks, this.playbacks, isRevConnect);

				return {
					attendeeType: webcast.currentUser.attendeeType,
					deviceId: this.playback.deviceId,
					deviceName: this.playback.deviceName,
					failoverFromUrl: fromUrl,
					revConnect: isRevConnect,
					sessionId: this.webcastSessionId,
					streamAccessed: toUrl,
					subType,
					videoFormat: this.playback.streamType,
					videoPlayer: isToFlash ? VideoHeartbeatPlayerType.FLASH : VideoHeartbeatPlayerType.HTML5,
					zoneId: this.playback.zoneId,
					zoneName: this.playback.zoneName
				};
			});
	}

	private onPlaybacksChange(playbacks: any[], oldPlaybacks: any[]): void {
		if (playbacks && playbacks.length) {
			if (collectionShallowEqual(playbacks, oldPlaybacks, obj => ({ ...obj, url: obj?.url?.split('?')[0] }))) {
				return;
			}

			const playback = playbacks[0];

			if (playback) {
				this.renderPlayer(playback);
			}
		}
	}

	private renderPlayer(playback: any, options?: any): void {
		let playbackPlayer = playback.player;

		// this.destroyElementIfExists(); do we need it once change to component?

		this.MediaFeatures.getFeatures(this.UserContext.getAccount().id)
			.then(mediaFeaturesResult => {
				this.mediaFeatures = mediaFeaturesResult;
				this.playerConfig = this.MediaFeatures.playerConfig;

				const isFlashDisabled = !this.mediaFeatures.enableFlashPlayback;
				const isWin7IE = userAgentUtil.isWindows7 && userAgentUtil.isIe;
				const legacyPlayback = this.playbacks.find(playback => playback.player === LEGACY_VBRICK_PLAYER);

				// Handle the edge case where you're on Win7 IE AND Flash is disabled AND there's a legacy playback present.
				// There's no live playback that the modern player can handle, so this allows for a fallback to the legacy player.
				if (isFlashDisabled && isWin7IE && legacyPlayback) {
					playbackPlayer = LEGACY_VBRICK_PLAYER;
					playback = legacyPlayback;
				}

				switch (playbackPlayer) {
					case LEGACY_VBRICK_PLAYER:
						this.legacyPlaybackUrl = playback.url;
						break;

					default:
						this.renderModernPlayer();
				}
			});
	}

	public onWmvFullscreenToggledInternal(isFullscreen: boolean): void {
		this.onWmvFullscreenToggled.emit({ isFullscreen });
	}

	private renderModernPlayer(): Promise<any> {
		const revConnectLoggingInfo: IRevConnectLoggingInfo = {
			remoteLoggingConfig: {
				accountId: this.UserContext.getAccount().id,
				context: {
					id: this.webcastId,
					runNumber: this.runNumber,
					sessionId: this.webcastSessionId,
					userId: this.UserContext.getUser().id
				},
				hostname: BootstrapContext.account.hostname,
				getToken: () => this.getRevConnectLoggingToken(),
				loggingUrl: BootstrapContext.analyticsUrl,
				resourceId: this.webcastId,
				resourceType: RESOURCE_TYPE_WEBCAST
			}
		};

		return Promise.all([
			this.VideoPlayerAdapter.getPlayerTranslations(),
			this.VideoPlayerAdapter.convertToModernPlayerPlaybacks(this.webcastId, ResourceType.Webcast, this.playbacks, true, this.isVcSource ? VideoSourceType.VIDEO_CONFERENCE : null, false, revConnectLoggingInfo)
		])
			.then(([vbrickPlayerTranslations, modernPlaybacks]) => {
				this.modernPlaybacks = modernPlaybacks;
				this.vbrickPlayerTranslations = vbrickPlayerTranslations;
			});
	}

	private getRevConnectLoggingToken(): Promise<any> {
		return lastValueFrom(this.http.get<any>(`/auth/token`, {
			params: {
				aud: TOKEN_RESOURCE_AUD,
				messageType: MessageType.LOGS,
				resourceId: this.webcastId,
				resourceType: RESOURCE_TYPE_WEBCAST
			}
		})).then(result => result.token);
	}
}
