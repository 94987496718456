import { NgModule } from '@vbrick/angular-ts-decorators';

import HelpModule from './help/Help.Module';
import MainHeaderModule from './mainHeader/MainHeader.Module';
import MediaMenuModule from './mediaMenu/MediaMenu.Module';
import NotificationsMenuModule from './notificationsMenu/NotificationsMenu.Module';
import UserNavMenuModule from './userNavMenu/UserNavMenu.Module';
import VBrickUiFileUploadModule from 'rev-shared/ui/fileUpload/FileUpload.Module';
import VBrickUiSpinnerModule from 'rev-shared/ui/spinner/Spinner.Module';

@NgModule({
	id: 'VBrick.Portal.Navigation',
	imports: [
		HelpModule,
		MainHeaderModule,
		MediaMenuModule,
		NotificationsMenuModule,
		UserNavMenuModule,
		VBrickUiFileUploadModule,
		VBrickUiSpinnerModule
	]
})

export default class NavigationModule {}
